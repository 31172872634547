import axios from 'axios'

import { appConfig } from 'services/config'
import { OnboardingData } from '@/lib/hooks/useOnboarding'
import { getLanguage } from '@/utils/getLanguage'

export const getOnboarding = async (): Promise<OnboardingData> =>
  axios
    .get(`${appConfig.api}/onboardingV2`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': getLanguage(), // 'vi' | 'vi-VN' (create a util mapper)
      },
    })
    .then((resp) => resp.data)
    .catch((error) => {
      console.error(error)
      return { id: '' }
    })

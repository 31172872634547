import { FC } from 'react'
import { IonBadge } from '@ionic/react'

import {
  Notifications,
  NotificationsOutline,
} from '@/components/atoms/assets/Notifications'

interface ActivityNavigationProps {
  isActive: boolean
  unseen?: number
}

export const ActivityNavigation: FC<ActivityNavigationProps> = ({
  isActive,
  unseen,
}) => {
  return (
    <>
      <div className="text-3xl md:text-2xl block mx-auto relative">
        {!isActive ? <Notifications /> : <NotificationsOutline />}

        {unseen > 0 && (
          <IonBadge
            className="block w-2.5 h-2.5 p-0 absolute top-0 right-0"
            color="danger"
          />
        )}
      </div>
    </>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonItem } from '@ionic/react'

import { Avatar } from '@components/atoms/Avatar'

import { appConfig } from '@/services/config'
import { formatDistance } from '@utils/date'

import { ActivityUserGreeting } from '@/types/activity/user'

interface IActivityGreetingItemProps {
  item: ActivityUserGreeting
}

export const ActivityUserGreetingItem: FC<IActivityGreetingItemProps> = ({
  item,
}) => {
  const { t } = useTranslation('common')
  const { time } = item

  return (
    <IonItem className="py-4 border-solid border-b-[0.0625rem] border-zinc-800">
      <span className="pr-4">
        <Avatar userId={appConfig.republikUserId} clickable isAmbassador />
      </span>
      <div className="w-full flex flex-col justify-between">
        <div className="text-sm">{t('activity.item.greeting.title')}</div>
        <div className="text-xs text-zinc-600">{formatDistance(time)}</div>
      </div>
    </IonItem>
  )
}

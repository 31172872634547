import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons } from '@ionic/react'

import { ReportButton } from '@components/molecules/ReportButton'
import { UserActivityButton } from '@components/molecules/UserActivityButton'
import { Avatar } from '@/components/atoms/Avatar'
import { ExclusiveButton } from '@/components/atoms/ExclusiveButton'
import { OriginChip } from '@/components/atoms/OriginChip'
import { UserName } from '@/components/atoms/UserName'
import { IActivityItem } from '@/components/pages/Activity/interfaces'

import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Link, useNavigate } from '@/lib/routing'
import { safeCastUnknownToType } from '@/utils/utils'

import { Badge, ReportButtonType } from '@enums'

export type IPostFeedHeaderProps = IActivityItem & {
  showExclusiveButton?: boolean
}

export const PostFeedHeader: FC<IPostFeedHeaderProps> = ({
  actor,
  origin,
  object,
  id,
  showExclusiveButton,
  isSubscriptionTeaser,
}) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { data: authUser } = useUserInfo()
  const actorHasExclusiveContent = actor?.data?.hasExclusiveContent

  const onUserActivityButtonSuccess = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <header className="flex justify-start items-center pt-[0.375rem] pb-3 relative !z-0">
      <Link to={`/@${actor?.data?.name}`} className="pl-3 flex-none">
        <Avatar
          username={actor?.data?.name as string}
          userId={actor?.id}
          isModerator={safeCastUnknownToType<boolean>(actor?.data?.isModerator)}
          isAmbassador={safeCastUnknownToType<boolean>(
            actor?.data?.isAmbassador
          )}
          badge={safeCastUnknownToType<Badge>(actor?.data?.badge)}
        />
      </Link>
      <div className="font-bold ml-2.5 flex justify-start items-center grow">
        <Link to={`/@${actor?.data?.name}`} className="cursor-pointer">
          <UserName name={safeCastUnknownToType<string>(actor?.data?.name)} />
          <OriginChip origin={origin} />
        </Link>
      </div>
      <div className="flex justify-end items-center">
        {showExclusiveButton &&
          actorHasExclusiveContent &&
          !isSubscriptionTeaser && (
            <ExclusiveButton username={actor?.data?.name as string} />
          )}
        <IonButtons>
          {actor?.id !== authUser?.id ? (
            <ReportButton
              type={ReportButtonType.Post}
              confirmationLabel={t('okay')}
              contestId={null}
              activityId={id}
              userId={actor?.id}
              isDisabled={actor?.id === authUser?.id}
            />
          ) : (
            <UserActivityButton
              type={ReportButtonType.Post}
              activityId={id}
              userId={authUser?.id}
              isDeletePostActive={typeof object !== 'string'}
              id={object?.data?.id}
              onSuccess={onUserActivityButtonSuccess}
            />
          )}
        </IonButtons>
      </div>
    </header>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonIcon, IonSpinner } from '@ionic/react'
import { checkmarkOutline } from 'ionicons/icons'

import { Button } from '@components/atoms/Button'

import { FollowStatus } from '@enums'

export interface IFollowButtonBaseProps {
  status: FollowStatus
  onFollow: () => void
  onBlock: () => void
  size?: 'small' | 'default'
  className?: string
  color?: string
  fill?: 'outline' | 'clear'
}

export const FollowButtonBase: FC<IFollowButtonBaseProps> = ({
  status,
  size,
  onFollow,
  onBlock,
  className = '',
  color = 'primary',
  fill = 'outline',
}) => {
  const { t } = useTranslation('common')

  const isSmall = size === 'small'

  switch (status) {
    case FollowStatus.Loading:
      return (
        <Button
          isDisabled
          size={size}
          type="secondary"
          className={`${
            isSmall ? 'capitalize min-w-[5.5rem]' : 'uppercase'
          } ${className}`}
          expand={isSmall ? 'block' : 'full'}
        >
          <IonSpinner
            name="dots"
            slot="icon-only"
            className={'text-quaternary'}
          />
        </Button>
      )

    case FollowStatus.Following:
      return (
        <Button
          expand={isSmall ? 'block' : 'full'}
          onClick={onFollow}
          type="secondary"
          className={`${isSmall ? 'min-w-[5.5rem]' : ''} ${className}`}
          size={size}
        >
          {!isSmall && <IonIcon icon={checkmarkOutline} slot="start" />}
          {t('followStatus.following')}
        </Button>
      )

    case FollowStatus.Followed:
    case FollowStatus.FollowBack:
      return (
        <Button
          expand={isSmall ? 'block' : 'full'}
          onClick={onFollow}
          fill={fill}
          color={color}
          size={size}
          className={`${isSmall ? 'min-w-[5.5rem]' : ''} ${className}`}
        >
          {t('followStatus.followBack')}
        </Button>
      )

    case FollowStatus.Friends:
      return (
        <Button
          expand={isSmall ? 'block' : 'full'}
          onClick={onFollow}
          type="secondary"
          className={`${isSmall ? 'min-w-[5.5rem]' : ''} ${className}`}
          size={size}
        >
          {!isSmall && <IonIcon icon={checkmarkOutline} slot="start" />}
          {t('followStatus.friends')}
        </Button>
      )

    case FollowStatus.Blocking:
      return (
        <Button
          expand={isSmall ? 'block' : 'full'}
          onClick={onBlock}
          type="danger"
          className={`${isSmall ? 'min-w-[5.5rem]' : ''} ${className}`}
        >
          {t('followStatus.unblock')}
        </Button>
      )

    case FollowStatus.Blocked:
      return null

    default:
      return (
        <Button
          expand={isSmall ? 'block' : 'full'}
          onClick={onFollow}
          fill={fill}
          color={color}
          size={size}
          className={`${isSmall ? 'min-w-[5.5rem]' : ''} ${className}`}
        >
          {t('followStatus.follow')}
        </Button>
      )
  }
}

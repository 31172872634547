import { FC, useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { IonLabel } from '@ionic/react'
import debounce from 'lodash/debounce'

import { Button } from '@components/atoms/Button'
import { InputField } from '@components/atoms/InputField'
import { Title } from '@components/atoms/Title'
import { Login } from '@components/templates/login'

import { checkUsernameUniqueness } from '@services/updateProfile'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { multiplePeriodsPattern, usernamePattern } from '@utils/validation'
import { validateNameWithoutBlacklistedWords } from '@/utils/validateNameWithoutBlacklistedWords'

const CreateProfileAvatar: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      username: '',
      displayName: '',
    },
    mode: 'onChange',
  })

  const validationMessages = useMemo(
    () => ({
      required: t('required'),
      minLength: t('minLength', { length: 2 }),
      maxLength: t('maxLength', { length: 24 }),
      isUsernamePatternValid: t('pattern'),
      isUsernameUnique: t('usernameIsTaken'),
      validateNameWithoutBlacklistedWords: t('nameWithoutBlacklistedWords'),
    }),
    [t]
  )

  const isUsernameUnique = useCallback(async (username: string) => {
    // If the user name contains rpk or republik, there is no need to call the interface.
    if (!validateNameWithoutBlacklistedWords(username)) return false
    if (username.length < 2 || username.length > 24) return false

    try {
      const result = await checkUsernameUniqueness(username)

      return !result.usernames.includes(username.toLowerCase())
    } catch (e) {
      console.log(e)
    }
  }, [])

  const isUsernamePatternValid = useCallback(async (value: string) => {
    return usernamePattern.test(value) && !multiplePeriodsPattern.test(value)
  }, [])

  const onSubmit = useCallback(
    async (data) => {
      if (!isDirty || !isValid) return
      navigate(Routes.AUTH_CREATE_PROFILE_AVATAR, {
        state: data,
      })
    },
    [navigate, isDirty, isValid]
  )

  return (
    <Login toolbarClassName="h-0">
      <Title level={1}>
        <Trans i18nKey="registration.profile.title">
          {t('registration.profile.title')}
        </Trans>
      </Title>
      <form
        className="px-14 py-14 rounded-t-3xl relative -mx-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-9 mb-11 relative">
          <Controller
            control={control}
            name="username"
            rules={{
              minLength: 2,
              maxLength: 24,
              required: true,
              validate: {
                isUsernamePatternValid,
                isUsernameUnique,
              },
            }}
            render={({ field }) => (
              <InputField
                className="username"
                label={t('registration.profile.userName')}
                placeholder="Required"
                value={field.value}
                isValid={!errors?.username}
                onChange={debounce((e) => field.onChange(e.value), 500)}
              />
            )}
          />
          {errors?.username && (
            <IonLabel
              color="danger"
              className="mt-1 absolute top-full left-0 right-0"
            >
              {validationMessages[errors?.username?.type] ??
                errors?.username?.message}
            </IonLabel>
          )}
        </div>
        <div className="mb-11 relative">
          <Controller
            control={control}
            rules={{
              maxLength: 24,
              minLength: 2,
              validate: {
                validateNameWithoutBlacklistedWords,
              },
            }}
            name="displayName"
            render={({ field }) => (
              <InputField
                isValid={!errors?.displayName}
                label={t('registration.profile.displayName')}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                autocomplete="on"
              />
            )}
          />
          {errors?.displayName && (
            <IonLabel
              color="danger"
              className="mt-1 absolute top-full left-0 right-0"
            >
              {validationMessages[errors?.displayName?.type] ??
                errors?.displayName?.message}
            </IonLabel>
          )}
        </div>
        <Button
          size="large"
          isDisabled={!isDirty || !isValid}
          className="mt-10 flex mx-auto w-lg"
          onClick={handleSubmit(onSubmit)}
        >
          {t('registration.profile.next')}
        </Button>
      </form>
    </Login>
  )
}

export default CreateProfileAvatar

import { FC, memo } from 'react'

export interface CurrencyComponentProps {
  className?: string
}

const CurrencyComponent: FC<CurrencyComponentProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#CCFF00"
      />
      <path
        d="M12 1.08971C14.9147 1.08971 17.654 2.22467 19.7147 4.28531C21.7753 6.34595 22.9103 9.08531 22.9103 12C22.9103 14.9147 21.7753 17.654 19.7147 19.7147C17.654 21.7753 14.9147 22.9103 12 22.9103C9.08531 22.9103 6.34595 21.7753 4.28531 19.7147C2.22467 17.654 1.08971 14.9147 1.08971 12C1.08971 9.08531 2.22467 6.34595 4.28531 4.28531C6.34595 2.22467 9.08531 1.08971 12 1.08971ZM12 0C5.37313 0 0 5.37313 0 12C0 18.6269 5.37313 24 12 24C18.6269 24 24 18.6269 24 12C24 5.37313 18.6269 0 12 0Z"
        fill="black"
      />
      <path
        d="M4.78297 6.45149V15.6216L14.6922 15.5915C15.4425 15.5915 16.0138 15.7744 16.4625 16.4116C16.4794 16.4342 16.5021 16.4531 16.519 16.4738C17.1563 17.2751 17.9651 17.6069 18.9285 17.5409C19.1415 17.5258 19.215 17.4391 19.2113 17.2185V15.7725C19.2207 15.4953 19.1321 15.3841 18.8681 15.3897L16.8131 15.4067C16.8131 15.4067 15.6216 15.2785 15.0598 14.6224L14.8298 14.3736C14.2774 13.7571 13.6062 13.4573 12.8163 13.4592C11.9773 13.4592 11.1384 13.4592 10.2994 13.4592C9.02681 13.4592 7.59963 13.4535 6.32704 13.4648V12.0452C8.1671 12.0509 10.1278 12.0509 11.9679 12.049C12.2714 12.049 12.5297 12.1414 12.7428 12.3563C12.9407 12.5561 13.1519 12.7465 13.3536 12.9445C13.8777 13.4573 14.5131 13.742 15.2578 13.7514C16.4757 13.7684 17.6936 13.7533 18.9096 13.759C19.1358 13.759 19.2131 13.6647 19.2075 13.4592C19.1999 13.052 19.2056 12.6447 19.2037 12.2375C19.2037 11.8755 19.1981 11.8699 18.8285 11.8699C17.1054 11.8699 15.3841 11.8699 13.6609 11.8699C12.4128 11.8699 12.5599 11.9509 11.6851 11.0837C11.0384 10.4427 10.2805 10.1222 9.34165 10.1505C8.3858 10.1769 7.27912 10.1542 6.32139 10.1599V8.60829H8.8609C9.16255 8.60263 9.40575 8.71952 9.61314 8.94199C9.85823 9.20216 10.0939 9.47553 10.3428 9.73005C10.8122 10.2108 11.3646 10.5162 12.015 10.52C14.3151 10.5313 16.6152 10.5256 18.9153 10.5313C19.1264 10.5313 19.2075 10.4446 19.2037 10.2183C19.1962 9.75644 19.2018 9.29266 19.1999 8.83076C19.1999 8.39148 19.1905 8.38017 18.7927 8.38017C16.0779 8.38017 13.363 8.38582 10.6482 8.37828C9.15689 8.37451 9.50379 8.54984 8.44613 7.38849C7.86734 6.75314 7.13395 6.44772 6.3195 6.44772H4.76978L4.78297 6.45149Z"
        fill="black"
      />
    </svg>
  )
}

export const CurrencyIcon = memo(CurrencyComponent)

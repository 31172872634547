import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Popover } from '@/components/molecules/Popover'

interface IBlockUserAlertProps {
  isOpen: boolean
  onDismiss?: () => void
  onConfirm?: () => void
  username?: string
}

export const LogoutAlert: FC<IBlockUserAlertProps> = ({
  isOpen,
  onDismiss,
  onConfirm,
  username,
}) => {
  const { t } = useTranslation('common')
  return (
    <Popover
      hasIcon
      isOpen={isOpen}
      onDismiss={onDismiss}
      onConfirm={onConfirm}
      onConfirmClassName="normal-case text-red-500 font-bold order-first"
      onDismissClassName="normal-case"
      actionText={t('no')}
      confirmText={t('yes')}
    >
      {t('profile.logoutAlert', { username })}
    </Popover>
  )
}

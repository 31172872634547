import { memo } from 'react'

const MultipleImagesComponent = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5233 4.41919H7.47666C6.66112 4.41919 6 5.20269 6 6.16919V16.6692C6 17.6357 6.66112 18.4192 7.47666 18.4192H18.5233C19.3389 18.4192 20 17.6357 20 16.6692V6.16919C20 5.20269 19.3389 4.41919 18.5233 4.41919Z"
      fill="white"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0415 20.4999C8.27954 21.9188 9.51353 22.9999 11 22.9999H21C22.6569 22.9999 24 21.6568 24 19.9999V10.9999C24 9.69373 23.1652 8.58249 22 8.17065V9.8819C22.3069 10.1566 22.5 10.5557 22.5 10.9999V19.9999C22.5 20.8284 21.8285 21.4999 21 21.4999H11C10.3469 21.4999 9.7913 21.0825 9.58538 20.4999H8.0415Z"
      fill="white"
    />
  </svg>
)

export const MultipleImages = memo(MultipleImagesComponent)

import { FC, memo, MouseEventHandler, ReactNode } from 'react'
import { IonButton, IonFabButton } from '@ionic/react'

export interface ButtonProps {
  id?: string
  type?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'danger'
  isRounded?: boolean
  onClick?: MouseEventHandler<HTMLIonFabButtonElement | HTMLIonButtonElement>
  isDisabled?: boolean
  className?: string
  size?: 'small' | 'default' | 'large'
  hasFullWidth?: boolean
  expand?: 'block' | 'full'
  color?: string
  fill?: 'outline' | 'clear'
  routerLink?: string
  children: string | ReactNode
  mode?: 'ios' | 'md'
}

const ButtonComponent: FC<ButtonProps> = ({
  type = 'primary',
  children,
  isRounded = false,
  isDisabled = false,
  className = '',
  size = 'default',
  fill = 'clear',
  expand,
  onClick,
  color,
  id,
  ...props
}) => {
  const setClassBySize = () => {
    switch (size) {
      case 'small':
        return 'text-xs font-bold h-[1.8125rem]'
      case 'default':
        return 'text-xs font-bold h-[2rem]'
      case 'large':
        return 'text-lg font-semibold h-[2.5rem]'
      default:
        return 'text-xs font-bold h-[2rem]'
    }
  }

  const setClassByType = () => {
    switch (type) {
      case 'secondary':
        return 'secondary'
      case 'tertiary':
        return 'tertiary'
      case 'quaternary':
        return 'quaternary'
      case 'danger':
        return 'danger'
      default:
        return ''
    }
  }

  return isRounded ? (
    <IonFabButton
      id={id}
      color={color}
      onClick={onClick}
      size={size as 'small' | undefined}
      className={`${setClassByType()} ${setClassBySize()} ${className} normal-case tracking-normal`}
      disabled={isDisabled}
    >
      {children}
    </IonFabButton>
  ) : (
    <IonButton
      id={id}
      color={color}
      onClick={onClick}
      size={size}
      className={`m-0 ${setClassByType()}  ${setClassBySize()} ${className} normal-case tracking-normal`}
      disabled={isDisabled}
      expand={expand}
      fill={fill}
      {...props}
    >
      {children}
    </IonButton>
  )
}

export const Button = memo(ButtonComponent)

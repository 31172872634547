import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ProgressBar } from '@/components/atoms/ProgressBar/ProgressBar'

import { replaceMentionsWithUsernames } from '@/utils/replaceMentionsWithUsernames'

interface UploadProgressBarProps {
  progress: number
  description?: string
}

export const UploadProgressBar: FC<UploadProgressBarProps> = ({
  progress = 50,
  description,
}) => {
  const { t } = useTranslation('common')

  const text = useMemo(
    () => replaceMentionsWithUsernames(description),
    [description]
  )

  return (
    <div className="my-1">
      <div className="bg-cool-gray-800 text-cool-gray-400 px-3 py-2 text-xs border-t-cool-gray-700 border-t-2 text-ellipsis truncate">
        {t('common.uploading', { text })}
      </div>
      <ProgressBar progress={progress} />
    </div>
  )
}

import { FC, MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'

import { InfiniteScroll } from '@/components/molecules/InfiniteScroll'
import { CommentsListItem } from '@/components/organisms/CommentsListItem'

import { UserInfo } from 'services/user'
import { useActivityDetails } from '@/lib/hooks/useActivityDetails'
import { useCommentsSection } from '@/lib/hooks/useCommentsSection'
import { useLocation } from '@/lib/routing'

export type CommentsSectionType = {
  userInfo: UserInfo
  activityId: UUID
  commentsRef: MutableRefObject<HTMLSpanElement>
}

const CommentsSection: FC<CommentsSectionType> = ({
  userInfo,
  activityId,
  commentsRef,
}) => {
  const location = useLocation()
  const { search } = location
  const searchParams = new URLSearchParams(search)
  const replyObjectId = searchParams.get('replyObjectId')
  const createdAt = searchParams.get('createdAt')

  const { t } = useTranslation('common')

  const { activity } = useActivityDetails({
    activityId,
    replyObjectId,
    createdAt,
  })

  const {
    comments,
    fetchNextPage,
    onDeleteComment,
    hasCommentsError,
    fetchedCommentsCount,
  } = useCommentsSection({
    activityId,
    replyObjectId,
    createdAt,
  })

  if (hasCommentsError)
    return (
      <div className="text-center my-8">
        {t('timeline.errors.commentSection')}
      </div>
    )

  const totalCommentsCount = activity?.reaction_counts?.COMMENT
  return (
    <>
      <span ref={commentsRef} />
      {comments.map((item) => (
        <CommentsListItem
          key={item.id}
          item={item}
          userId={userInfo.id}
          contestUserId={activity?.actor?.id}
          onDelete={onDeleteComment}
        />
      ))}
      <InfiniteScroll
        threshold="10%"
        disabled={fetchedCommentsCount === totalCommentsCount}
        onRequest={() => fetchNextPage()}
      />
    </>
  )
}

export default CommentsSection

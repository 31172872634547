import { FC } from 'react'
import cx from 'classnames'

import { MarkdownEllipsis } from '@/components/atoms/MarkdownEllipsis'
import { UserRelationIndicator } from '@/components/atoms/UserRelationIndicator/UserRelationIndicator'

import { replaceUsernamesWithMentions } from '@/utils/replaceUsernamesWithMentions'
import { actorMarkdown } from '@/utils/utils'

import { UserRelationIndicatorType } from '@/enums'
import { IUserWithoutMetadata } from '@/interfaces'
import { IConversation, IReply } from '@/interfaces'

interface CaptionProps {
  actor: IUserWithoutMetadata
  object: { data: IConversation }
  subCaption?: IReply
}

export const Caption: FC<CaptionProps> = ({ actor, object, subCaption }) => {
  const captionWithMarkdown = replaceUsernamesWithMentions(
    object.data.caption,
    object.data?.mentions
  )
  const subCaptionWithMarkdown =
    subCaption &&
    replaceUsernamesWithMentions(subCaption.caption, subCaption.mentions)

  return (
    <div className="text-sm font-normal text-cool-gray-200">
      <div>
        <div
          className={cx('relative wordWrap-break', {
            'ml-8 pb-3': !!subCaption,
          })}
        >
          {!!subCaption && (
            <UserRelationIndicator
              elementType={UserRelationIndicatorType.verticalLine}
            />
          )}
          <MarkdownEllipsis
            text={`${actorMarkdown({
              url: `/@${actor?.data?.name}`,
              name: actor?.data?.name,
              className: subCaption ? '-ml-8' : '',
              isAmbassador: actor?.data?.isAmbassador,
              isModerator: actor?.data?.isModerator,
            })} ${captionWithMarkdown}`}
            overflowHidden={false}
          />
        </div>
        {!!subCaption && (
          <div className="flex">
            <UserRelationIndicator
              elementType={UserRelationIndicatorType.curvedRight}
            />
            <div className="wordWrap-break">
              <MarkdownEllipsis
                text={`${actorMarkdown({
                  url: `/@${subCaption.username}`,
                  name: subCaption.username,
                  isAmbassador: subCaption?.isAmbassador,
                  isModerator: subCaption?.isModerator,
                })} ${subCaptionWithMarkdown}`}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

import { Control, Controller, FieldValues, useWatch } from 'react-hook-form'
import { UseFormHandleSubmit } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { IonLabel, IonSpinner, IonText } from '@ionic/react'

import { Button } from '@components/atoms/Button'
import { InputField } from '@components/atoms/InputField'
import { GeeCaptcha } from '@/components/atoms/GeeCaptcha'

import { appConfig } from '@/services/config'

interface SetPhoneNumberProps {
  control?: Control<PhoneNumberFieldValues>
  submit?: ReturnType<UseFormHandleSubmit<FieldValues>>
  isLoading: boolean
  isErrored: {
    telNumber: boolean
    geeCaptchaToken: boolean
    blockedPhoneNumber: boolean
    disabledUser?: boolean
    tryLater?: boolean
    userNotFound?: boolean
  }
}

export interface PhoneNumberFieldValues {
  phoneNumber: string
  phoneNumberCountryPrefix: string
  telNumber: {
    code: string
    value: string
  }
  geeCaptchaToken: string
}

const SetPhoneNumber = ({
  control,
  submit,
  isLoading,
  isErrored,
}: SetPhoneNumberProps) => {
  const { t } = useTranslation('common')
  const { telNumber, geeCaptchaToken } = useWatch({
    control,
  })

  return (
    <>
      <Controller
        control={control}
        name="telNumber"
        render={({ field }) => (
          <InputField
            type="tel"
            isPhoneSelect
            value={field.value?.value}
            onChange={(e) => {
              field.onChange(e)
            }}
            autocomplete="on"
          />
        )}
        rules={{ required: true, minLength: 5 }}
      />
      {isErrored['telNumber'] && (
        <div className="flex justify-center">
          <IonLabel color="danger" className="text-center mt-2">
            {t('registration.phoneNumber.error.invalidPhoneFormat')}
          </IonLabel>
        </div>
      )}
      {isErrored['blockedPhoneNumber'] && (
        <div className="flex justify-center">
          <IonLabel color="danger" className="text-center mt-2">
            {t('registration.phoneNumber.error.blockedPhoneNumber')}
          </IonLabel>
        </div>
      )}
      {isErrored['tryLater'] && (
        <div className="flex justify-center">
          <IonLabel color="danger" className="text-center mt-2">
            {t('registration.phoneNumber.error.tryLater')}
          </IonLabel>
        </div>
      )}
      {isErrored['userNotFound'] && (
        <div className="flex justify-center">
          <IonLabel color="danger" className="text-center mt-2">
            {t('registration.phoneNumber.error.userNotFound')}
          </IonLabel>
        </div>
      )}
      {isErrored['disabledUser'] && (
        <div className="flex justify-center">
          <IonLabel color="danger" className="text-center text-sm mt-2">
            <Trans
              i18nKey="registration.profile.deleted"
              components={{
                supportLink: <a href="mailto:support@republik.io" />,
              }}
            >
              {t('registration.profile.deleted')}
            </Trans>
          </IonLabel>
        </div>
      )}
      <IonText className="text-gray-500 text-center pt-4 block">
        {t('registration.phoneNumber.smsToPhoneInfo')}
      </IonText>
      {appConfig.geeTestSignUpId && (
        <>
          <br />
          <Controller
            control={control}
            name="geeCaptchaToken"
            render={({ field }) => (
              <GeeCaptcha
                validate={(e) => {
                  field.onChange(e)
                }}
              />
            )}
            rules={{ required: true }}
          />
          {isErrored['geeCaptchaToken'] && (
            <div className="flex justify-center">
              <IonLabel color="danger" className="text-center mt-2">
                {t('registration.phoneNumber.error.verificationError')}
              </IonLabel>
            </div>
          )}
        </>
      )}
      <Button
        className="mt-8 flex mx-auto w-lg"
        isDisabled={
          !telNumber.value || (appConfig.geeTestSignUpId && !geeCaptchaToken)
        }
        onClick={() => submit?.()}
        size="large"
      >
        {isLoading ? (
          <IonSpinner color="light" />
        ) : (
          t('registration.phoneNumber.getCode')
        )}
      </Button>
    </>
  )
}

export default SetPhoneNumber

import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Section } from '../UserProfileSections/types'

type SectionComponentProps = {
  section: Section
}

export const SectionComponent: FC<SectionComponentProps> = ({ section }) => {
  const { t } = useTranslation('common')

  return (
    <div className="relative w-[100vw] max-w-[40rem] h-full">
      <picture>
        <source
          srcSet={`/user_profile_sections/bg_${section.imageName}.png 358w, /user_profile_sections/bg_${section.imageName}@2x.png 716w, /user_profile_sections/bg_${section.imageName}@3x.png 1074w`}
        />
        <img
          src={`/user_profile_sections/bg_${section.imageName}.png`}
          alt="user-profile-section-background"
        />
      </picture>
      <div className="absolute w-[48%] flex flex-col items-start top-[16%] left-[44%] text-left font-bold">
        <span className="mb-4 px-2 bg-primary text-sm sm:text-base text-cool-gray-900">
          {t(`profile.sections.${section.name}.title`)}
        </span>
        <span className="mb-4 text-2xl sm:text-3xl text-white">
          <Trans>{t(`profile.sections.${section.name}.subtitle`)}</Trans>
        </span>
        <span className="text-sm sm:text-base text-white">
          <Trans components={{ primary: <span className="text-primary" /> }}>
            {t(`profile.sections.${section.name}.description`)}
          </Trans>
        </span>
      </div>
    </div>
  )
}

import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from '@/lib/routing'
import { WALLET_SEARCH_XP } from '@/utils/route'
import { compactNumbers } from '@/utils/utils'

import { Badge } from '@/enums'

interface XPButtonProps {
  value: number
  badge: Badge
  isViewingOwnProfile: boolean
  onClick?: () => void | Promise<void>
}

const XPButtonComponent: FC<XPButtonProps> = ({
  badge,
  value,
  isViewingOwnProfile,
  onClick,
}) => {
  const { t } = useTranslation('common')
  const compactedValue = useMemo(
    () => compactNumbers(Math.floor(Number(value))),
    [value]
  )

  const XPBadge = () => {
    switch (badge) {
      case Badge.BronzeXPMilestoneBadge:
        return <img src="/xp_badges/bronze.png" className="w-5 h-auto" />
      case Badge.SilverXPMilestoneBadge:
        return <img src="/xp_badges/silver.png" className="w-6 h-auto" />
      case Badge.GoldXPMilestoneBadge:
        return <img src="/xp_badges/golden.png" className="w-6 h-auto" />
      case Badge.DiamondXPMilestoneBadge:
        return <img src="/xp_badges/diamond.png" className="w-5 h-auto" />
      default:
        return <img src="/xp_badges/default.png" className="w-5 h-auto" />
    }
  }

  return (
    <Link
      className="flex items-center"
      to={isViewingOwnProfile ? WALLET_SEARCH_XP : undefined}
      onClick={isViewingOwnProfile && onClick}
    >
      <span className="flex flex-row text-white font-bold items-center">
        <XPBadge />
        <span className="ml-2 text-sm sm:text-base">{compactedValue}</span>
        <span className="ml-1 text-xs sm:text-sm">{t('profile.xp')}</span>
      </span>
    </Link>
  )
}

export const XPButton = memo(XPButtonComponent)

import { useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'

import { getTopSupporters, TopSupporter } from '@/services/top-supporters'

export const useGetTopSupporters = (userId: string) => {
  const { data, isLoading, fetchNextPage, hasNextPage, refetch, isSuccess } =
    useInfiniteQuery({
      queryKey: ['getTopSupporters', userId],
      queryFn: () => getTopSupporters({ userId }),
      getNextPageParam: () => undefined,
      initialPageParam: '',
      enabled: !!userId,
    })

  const normalizedData = useMemo(() => {
    try {
      return {
        supporters:
          data?.pages.reduce((acc, page) => {
            return [...acc, ...page.supporters]
          }, <readonly TopSupporter[]>[]) ?? <readonly TopSupporter[]>[],
        // eslint-disable-next-line republik/no-rpk-variable
        entryAmount: data?.pages?.[0].entryAmountRPK,
      }
    } catch (error) {
      return {
        supporters: [],
        entryAmount: undefined,
      }
    }
  }, [data])

  return {
    refetch,
    isLoading,
    isSuccess,
    data: normalizedData,
    fetchNextPage,
    hasNextPage,
  }
}

import { FC, memo } from 'react'

export interface ShieldIconProps {
  className?: string
  color?: string
}

const ShieldIconComponent: FC<ShieldIconProps> = ({
  className = '',
  color = '#CCFF00',
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={color}
        d="m0.86621 2c0-1.1046 0.89543-2 2-2h18.4c1.1046 0 2 0.89543 2 2v14.516c0 0.7217-0.3889 1.3875-1.0176 1.7421l-9.2 5.1879c-0.6098 0.3439-1.355 0.3439-1.9648 0l-9.2-5.1879c-0.6287-0.3546-1.0176-1.0204-1.0176-1.7421v-14.516z"
      />
      <g>
        <path
          d="m4.0663 3.2v11.898l10.986-0.0385c0.8308 0 1.4643 0.2371 1.9624 1.0641 0.0179 0.03 0.0439 0.0527 0.0634 0.081 0.7074 1.0386 1.6027 1.4705 2.6718 1.3839 0.2353-0.0192 0.3178-0.1313 0.3143-0.4177l0.0015-1.8764c8e-3 -0.3589-0.0894-0.5044-0.3812-0.4976l-2.2787 0.0215s-1.3204-0.1669-1.9439-1.0166l-0.2553-0.3226c-0.612-0.7992-1.3574-1.1876-2.2312-1.1853-0.9297 0.0023-1.8594 6e-4 -2.7892 0.0011-1.4098 0-2.9931-0.0067-4.4029 0.0068v-1.8407c2.0393 0.0085 4.2136 0.0062 6.2534 0.0056 0.3367 0 0.6235 0.1189 0.8593 0.398 0.2203 0.2603 0.4536 0.5066 0.678 0.7636 0.5805 0.6645 1.2849 1.0341 2.1107 1.0466 1.3494 0.0209 2.6993 0.0028 4.0488 0.0107 0.2507 0.0011 0.3357-0.1217 0.3312-0.3877-0.0085-0.5281-0.0025-1.0568-0.0035-1.5855-1e-3 -0.4698-0.0065-0.4766-0.4152-0.4766-1.9094 0-3.8189-0.0011-5.7288 0-1.3829 0.0011-1.22 0.1058-2.1902-1.0194-0.7165-0.83095-1.5578-1.2464-2.5969-1.2119-1.0586 0.03509-2.2871 0.00396-3.3473 0.01302v-2.0128h2.8147c0.33473-0.00623 0.60351 0.14547 0.83432 0.43188 0.27228 0.33793 0.53257 0.6917 0.80988 1.0228 0.521 0.62264 1.133 1.0211 1.8545 1.0257 2.5494 0.01585 5.0988 0.00622 7.6482 0.01415 0.2334 5.6e-4 0.3243-0.11321 0.3203-0.40642-8e-3 -0.6-3e-3 -1.2-0.0035-1.8 0-0.56943-0.011-0.58415-0.4506-0.58415-3.0091 0-6.0187 0.00793-9.0277-0.00283-1.6522-0.00566-1.2695 0.22245-2.4415-1.2849-0.64098-0.82528-1.4538-1.2198-2.3571-1.2198l-1.7176-0.00113z"
          fill="#131820"
        />
      </g>
    </svg>
  )
}

export const ShieldIcon = memo(ShieldIconComponent)

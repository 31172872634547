import { FC, memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Popover } from '@/components/molecules/Popover'

interface IDisconnectAccountButtonComponentProps {
  accountType: 'instagram' | 'twitter' | 'tiktok'
  isOpen: boolean
  onDismiss?: () => void
  onConfirm?: () => void
}

export const DisconnectAccountPopoverComponent: FC<
  IDisconnectAccountButtonComponentProps
> = ({ accountType, isOpen, onDismiss, onConfirm }) => {
  const { t } = useTranslation('common')

  return (
    <>
      <Popover
        hasIcon
        isOpen={isOpen}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
        onConfirmClassName="normal-case text-sm text-red-500 order-first"
        onDismissClassName="normal-case text-sm"
        actionText={t(`registration.profile.${accountType}Popover.no`)}
        confirmText={t(`registration.profile.${accountType}Popover.yes`)}
      >
        <strong>
          <Trans>
            {t(`registration.profile.${accountType}Popover.disconnectAccount`)}
          </Trans>
        </strong>
      </Popover>
    </>
  )
}

export const DisconnectAccountPopover = memo(DisconnectAccountPopoverComponent)

import { FC, useEffect } from 'react'
import OtpInput from 'react-otp-input'
import { isPlatform } from '@ionic/react'

type CodeInputProps = {
  codeLength: number
  value: string
  onChange: (value: string) => void
}

export const CodeInput: FC<CodeInputProps> = ({
  codeLength,
  value,
  onChange,
}) => {
  useEffect(() => {
    if (!isPlatform('ios') || !(isPlatform('mobileweb') || isPlatform('pwa')))
      return

    if (window.scrollY > 0) window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <OtpInput
      value={value}
      onChange={onChange}
      inputType="number"
      numInputs={codeLength}
      renderSeparator={() => <span className="w-[0.75rem] md:w-[1rem]" />}
      renderInput={(props) => <input {...props} />}
      inputStyle={{
        width: '1.875rem',
        fontSize: '18px',
        height: '2.125rem',
        backgroundColor: 'transparent',
        color: 'white',
        border: '1px solid #9EADC7',
        borderRadius: '5px',
      }}
    />
  )
}

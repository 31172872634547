import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonItem, IonLabel } from '@ionic/react'

import { InfoSection } from '@/components/molecules/InfoSection'

import { appConfig } from '@/services/config'

type TextComponentType = {
  amountToBurn: number
}

export const TextComponent: FC<TextComponentType> = ({ amountToBurn }) => {
  const { t } = useTranslation()

  const { currency, creatorToken } = appConfig

  return (
    <InfoSection
      title={t('tokenLaunch.textTitle')}
      subTitle={t('tokenLaunch.toCoverFees', {
        amount: amountToBurn,
        currency,
        creatorToken,
      })}
    >
      <IonItem>
        <IonLabel className="ion-text-wrap">
          <span className="text-cool-gray-200 text-sm sm:text-base">
            {t('tokenLaunch.ifInsufficientCurrency', { currency })}
          </span>
        </IonLabel>
      </IonItem>
    </InfoSection>
  )
}

import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { IonIcon, IonSpinner } from '@ionic/react'
import cx from 'classnames'
import { videocam, volumeHighOutline, volumeMuteOutline } from 'ionicons/icons'

import { appConfig } from '@/services/config'
import { useVideo } from '@/lib/hooks/useVideo'
import { Context } from '@/lib/store'
import { videoUrl } from '@/utils/url'

import { VideoPlayer } from '../VideoPlayer/VideoPlayer'

interface VideoContainerComponentProps {
  video: string
  videoPreview: string
  isPlaying: boolean
  hasReplies?: boolean
  isVideoReset?: boolean
  isProgressBarVisible?: boolean
  className?: string
  onClick?: (e) => void
  videoContainerHeight?: number
  videoAllowedToLoad?: boolean
  onVideoLoadedMilliseconds?: (milliseconds: number) => void
}

const VideoContainerComponent: FC<VideoContainerComponentProps> = ({
  video,
  videoPreview,
  isPlaying,
  hasReplies = false,
  isVideoReset = false,
  isProgressBarVisible = false,
  className = '',
  onClick,
  videoContainerHeight,
  videoAllowedToLoad = false,
  onVideoLoadedMilliseconds,
}: VideoContainerComponentProps) => {
  const [hasError, setHasError] = useState(false)
  const { state } = useContext(Context)
  const {
    isVideoStartedPlaying,
    muteVideo,
    progressHandler,
    toggleMuteVideo,
    videoLoadedMilliseconds,
  } = useVideo()

  useEffect(() => {
    if (onVideoLoadedMilliseconds)
      onVideoLoadedMilliseconds(videoLoadedMilliseconds)
  }, [videoLoadedMilliseconds, onVideoLoadedMilliseconds])

  const getImage = useCallback((originImage) => {
    if (originImage?.includes('http')) return originImage
    return `${appConfig.cdn}/${originImage}/x2.jpeg`
  }, [])

  const videoContainerHeightStyle = useMemo(() => {
    return {
      height: videoContainerHeight,
    }
  }, [videoContainerHeight])

  const videoPlayerStyle = useMemo(() => {
    return {
      opacity: isVideoStartedPlaying ? 1 : 0,
    }
  }, [isVideoStartedPlaying])

  const muteButtonStyle = useMemo(() => {
    return {
      WebkitTransform: 'translate3d(0,0,0)',
    }
  }, [])

  const videoPreviewStyle = useMemo(() => {
    return {
      width: 'inherit',
      height: 'inherit',
    }
  }, [])

  return (
    <div
      className={`${className} relative flex items-center justify-center bg-gradient-to-b from-cool-gray-800 from-0% via-black via-50% to-cool-gray-800 to-100%`}
      style={videoContainerHeightStyle}
    >
      <img
        className="relative flex items-center justify-center transition-opacity delay-150 transition-duration-500 object-contain"
        src={videoAllowedToLoad ? getImage(videoPreview) : ''}
        onClick={onClick}
        alt=""
        style={videoPreviewStyle}
      />
      {hasError ? (
        <IonIcon icon={videocam} className="xxl" />
      ) : (
        <VideoPlayer
          playing={state?.isVideoPlaybackAllowed && isPlaying}
          onClick={onClick}
          muted={muteVideo}
          className="absolute !w-full !h-full left-0 top-0 transition-all -mb-[2px]"
          url={videoAllowedToLoad ? videoUrl(video) : ''}
          progressHandler={progressHandler}
          isProgressBarVisible={isProgressBarVisible}
          isVideoReset={isVideoReset}
          style={videoPlayerStyle}
          videoContainerHeight={videoContainerHeight}
          onError={() => setHasError(true)}
        />
      )}
      {isPlaying && !isVideoStartedPlaying && (
        <div className="absolute transform left-1/2 top-1/2 -translate-x-2/4 -translate-y-2/4 animate-fadeIn z-20">
          <IonSpinner color="main" />
        </div>
      )}
      {isPlaying && (
        <div
          className={cx(
            `absolute right-5 z-20 w-10 h-10 bg-zinc-500/70 opacity-70 rounded-full flex items-center animate-immediateFadeIn justify-center`,
            { 'top-16': hasReplies, 'top-4': !hasReplies }
          )}
          style={muteButtonStyle}
          onClick={(e) => toggleMuteVideo(e)}
        >
          <IonIcon icon={muteVideo ? volumeMuteOutline : volumeHighOutline} />
        </div>
      )}
    </div>
  )
}

export const VideoContainer = memo(VideoContainerComponent)

import { FC, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonSpinner } from '@ionic/react'
import cx from 'classnames'

import { Currency } from '@/components/atoms/Currency'

import { UserInfo } from '@/services/user'
import { useUnlockChatFee } from '@/lib/hooks/chat/useUnlockChatFee'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useWallet } from '@/lib/hooks/wallet'

import { PaymentFormButton } from '../../PaymentFormButton'

type UnlockChatProps = {
  unlockChat: () => Promise<void>
  onBuyMoreCurrency: () => void
  isLoadingUnlockChatData: boolean
  userInfo: UserInfo
  amount: number
}

export const UnlockChat: FC<UnlockChatProps> = ({
  unlockChat,
  onBuyMoreCurrency,
  isLoadingUnlockChatData,
  amount,
  userInfo,
}) => {
  const { data: authUser } = useUserInfo()
  const { t } = useTranslation('common')

  const { wallet, isFetchingWallet } = useWallet({
    userInfo: authUser,
  })

  const {
    feeData,
    getFee,
    isLoadingFee,
    error: transactionFeeError,
    resetFeeData,
  } = useUnlockChatFee()

  const fee = feeData?.fee ? <Currency value={feeData.fee} /> : undefined
  const total = feeData?.total ? <Currency value={feeData.total} /> : undefined
  const balance = useMemo(() => {
    if (feeData?.newBalance) {
      return <Currency value={feeData.newBalance} hasSign />
    }

    if (wallet?.balance) {
      return <Currency value={wallet.balance} />
    }
  }, [feeData?.newBalance, wallet?.balance])

  const executable = feeData?.executable

  const errorMapping = {
    INVALID_TRANSACTION_TYPE: t('transaction.errors.invalidTransactionType'),
    SAME_SOURCE_DESTINATION: t('transaction.errors.sameSourceDestination'),
    NON_POSITIVE_AMOUNT: t('transaction.errors.nonPositiveAmount'),
    SOURCE_USER_NOT_FOUND: t('transaction.errors.sourceUserNotFound'),
    SOURCE_USER_DEACTIVATED: t('transaction.errors.sourceUserDeactivated'),
    DESTINATION_USER_NOT_FOUND: t('transaction.errors.destinationUserNotFound'),
    DESTINATION_USER_DEACTIVATED: t(
      'transaction.errors.destinationUserDeactivated'
    ),
    SOURCE_WALLET_NOT_FOUND: t('transaction.errors.sourceWalletNotFound'),
    SOURCE_WALLET_INSUFFICIENT_FOUNDS: t(
      'transaction.errors.insufficientFunds'
    ),
  }

  const parseError = (error, transactionFeeError) => {
    if (error) {
      return t('tip.errors.somethingWentWrong')
    }
    if (transactionFeeError) {
      return errorMapping[transactionFeeError]
    }

    return
  }

  const error = parseError(transactionFeeError, feeData?.errorMessage)

  useEffect(() => {
    return () => resetFeeData()
  }, [resetFeeData])

  useEffect(() => {
    getFee(String(amount))
  }, [amount, getFee])

  return (
    <div className="w-full">
      <div className="flex flex-col text-white mb-3">
        <div className="flex flex-col items-start">
          <span className="text-2xl font-bold">
            {t('unlockChat.unlockChat')}
          </span>
          <span className="text-base">
            <Trans>
              {t('unlockChat.withUsername', { username: userInfo.username })}
            </Trans>
          </span>
        </div>
        <div className="flex flex-row justify-between items-end mt-2">
          <span className="text-lg font-bold">
            {t('unlockChat.labels.price')}
          </span>
          <span className="text-2xl font-bold">
            <Currency value={amount} />
          </span>
        </div>
        <div className="flex flex-row justify-between mt-5 text-sm">
          <span>{t('unlockChat.labels.republikFee')}</span>
          {isLoadingFee ? (
            <IonSpinner className="h-5" color="medium" name="dots" />
          ) : fee ? (
            <span>{fee}</span>
          ) : (
            '-'
          )}
        </div>
        <div className="flex flex-row justify-between mt-2 text-primary font-bold">
          <span>{t('unlockChat.labels.total')}</span>
          {isLoadingFee ? (
            <IonSpinner className="h-5" color="main" name="dots" />
          ) : total ? (
            <span>{total}</span>
          ) : (
            '-'
          )}
        </div>
        <div className="flex flex-row justify-between mt-4 text-sm">
          <span>{t('unlockChat.labels.yourWalletBalance')}</span>
          {isFetchingWallet || isLoadingFee ? (
            <IonSpinner className="h-5" color="medium" name="dots" />
          ) : balance ? (
            <span
              className={cx({
                'text-color-danger': !!error,
              })}
            >
              {balance}
            </span>
          ) : (
            '-'
          )}
        </div>
      </div>
      {error && (
        <div className="flex flex-col items-center my-3 text-color-danger">
          {error}
        </div>
      )}
      <div className="flex flex-col items-center mt-7">
        <PaymentFormButton
          onClick={unlockChat}
          isLoading={isLoadingFee || isLoadingUnlockChatData}
          isDisabled={isLoadingUnlockChatData || !executable}
          translation={t('unlockChat.actions.pay')}
          error={feeData?.errorMessage}
          onBuyMoreCurrency={onBuyMoreCurrency}
        />
        <span className="text-xs my-3 font-medium text-cool-gray-200">
          {t('unlockChat.info')}
        </span>
      </div>
    </div>
  )
}

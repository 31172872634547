import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonIcon } from '@ionic/react'
import { chevronForward } from 'ionicons/icons'

import { Button } from '@/components/atoms/Button'
import { InputField } from '@/components/atoms/InputField'
import { DisconnectAccountPopover } from '@/components/molecules/DisconnectAccountPopover'

import { useUserInfo } from '@/lib/hooks/useUserInfo'
interface DisconnectProps {
  userName: string
  handleDisconnection: () => void
}
export const Disconnect: FC<DisconnectProps> = ({
  userName,
  handleDisconnection,
}) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const { t } = useTranslation('common')
  const { data: authUser } = useUserInfo()

  return (
    <form autoComplete="false">
      <div className="grid grid-cols-1 max-w-md mx-8 sm:mx-auto py-8">
        <div>
          <InputField
            label={t('registration.profile.instagramUserName')}
            isReadOnly
            role="username"
            autocomplete="off"
            value={userName}
          />
        </div>
        <div className="pt-9">
          <DisconnectAccountPopover
            accountType="instagram"
            isOpen={isPopoverVisible}
            onDismiss={() => setIsPopoverVisible(false)}
            onConfirm={() => {
              handleDisconnection()
              setIsPopoverVisible(false)
            }}
          />
          {authUser?.creatorTokenId == null && (
            <Button
              onClick={() => setIsPopoverVisible(true)}
              className="plain !text-sm"
              fill="clear"
              type="danger"
            >
              {t('registration.profile.instagramPopover.disconnect')}
              <IonIcon icon={chevronForward} />
            </Button>
          )}
        </div>
      </div>
    </form>
  )
}

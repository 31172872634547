import { FC, ReactNode, useContext } from 'react'
import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react'
import cx from 'classnames'
import { isFunction } from 'lodash'

import { Refresher } from '@components/atoms/Refresher'
import { OfflineContainer } from '@/components/atoms/OfflineContainer'
import { OfflineIndicator } from '@/components/atoms/OfflineIndicator'
import { UploadProgressBar } from '@/components/atoms/UploadProgressBar'
import { NavBar } from '@/components/molecules/NavBar'

import { useNetwork } from '@/lib/hooks/useNetwork'
import { Context } from '@/lib/store'

import { IRefresher } from 'interfaces'

import { useHeadTitle } from '../useHeadTitle'

export interface IEntryTemplateProps extends IRefresher {
  children: ReactNode
  header?: ReactNode
  subHeader?: ReactNode
  headTitle?: string
  isRefetchActive?: boolean
  onActiveNavIconPress?: () => void | Promise<void>
  hasData?: boolean
  contentClassName?: string
  isPaddingEnabled?: boolean
}

const EntryWithRouter: FC<IEntryTemplateProps> = ({
  children,
  header,
  subHeader,
  refetch,
  headTitle,
  isRefetchActive = true,
  onActiveNavIconPress,
  hasData = false,
  contentClassName,
  isPaddingEnabled = true,
}) => {
  const { state } = useContext(Context)
  const { isOnline } = useNetwork()
  useHeadTitle(headTitle)

  return (
    <IonPage>
      <IonHeader className="mx-auto max-w-[40rem]" role="none">
        <IonToolbar mode="ios">{header}</IonToolbar>
        <OfflineIndicator />
        {subHeader}
        {Object.entries(state.uploadProgress).map(
          ([key, { progress, description }]) => (
            <UploadProgressBar
              key={key}
              description={description}
              progress={progress}
            />
          )
        )}
      </IonHeader>
      <IonContent className="max-w-[40rem] self-center">
        {!hasData && !isOnline ? (
          <OfflineContainer />
        ) : (
          <>
            {isFunction(refetch) && isOnline && (
              <Refresher
                disabled={!isRefetchActive}
                refetch={refetch}
                className="bg-cool-gray-900"
              />
            )}
            <section
              className={cx(`h-full ${contentClassName}`, {
                'px-0 md:px-8': isPaddingEnabled,
              })}
            >
              {children}
            </section>
          </>
        )}
      </IonContent>
      <NavBar onActiveNavIconPress={onActiveNavIconPress} />
    </IonPage>
  )
}

export const Entry = EntryWithRouter

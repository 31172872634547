import { FC } from 'react'
import { IonButton, IonButtons, IonIcon, IonTitle } from '@ionic/react'

import { BackButton } from '@/components/atoms/BackButton'
import { Title } from '@/components/atoms/Title'

interface WalletHeaderProps {
  title: string
  backButtonDefaultHref: string
}

export const WalletHeader: FC<WalletHeaderProps> = ({
  title,
  backButtonDefaultHref,
}) => {
  return (
    <>
      <IonButtons slot="start">
        <BackButton
          className="text-white"
          defaultHref={backButtonDefaultHref}
        />
      </IonButtons>
      <IonTitle className="px-2">
        <Title level={2}>{title}</Title>
      </IonTitle>
      <IonButtons slot="end">
        <IonButton>
          <IonIcon slot="icon-only" />
        </IonButton>
      </IonButtons>
    </>
  )
}

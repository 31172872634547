import { FC, memo } from 'react'

import {
  BronzeBadgeLarge,
  DefaultBadgeLarge,
  DiamondBadgeLarge,
  GoldenBadgeLarge,
  SilverBadgeLarge,
} from '@/components/atoms/assets/XPBadges'

import { Badge } from '@/enums'

interface XPBadgeProps {
  xp: number
  badge?: Badge
}

interface BadgeMapping {
  container: string
  component: FC<{ xp: number; className?: string }>
  className?: string
}

const badges: Record<Badge, BadgeMapping> = {
  [Badge.DefaultMilestoneBadge]: {
    container: 'w-[42vw] max-w-[18rem]',
    component: DefaultBadgeLarge,
  },
  [Badge.BronzeXPMilestoneBadge]: {
    container: 'w-[42vw] max-w-[18rem]',
    component: BronzeBadgeLarge,
  },
  [Badge.SilverXPMilestoneBadge]: {
    container: 'w-[45vw] max-w-[19rem]',
    component: SilverBadgeLarge,
  },
  [Badge.GoldXPMilestoneBadge]: {
    container: 'w-[52vw] max-w-[21rem]',
    component: GoldenBadgeLarge,
    className: '-mb-[2vw]',
  },
  [Badge.DiamondXPMilestoneBadge]: {
    container: 'w-[50vw] max-w-[21rem]',
    component: DiamondBadgeLarge,
  },
}

export const XPBadgeComponent: FC<XPBadgeProps> = ({ xp, badge }) => {
  const {
    container,
    component: BadgeComponent,
    className,
  } = badges[badge] || badges[Badge.DefaultMilestoneBadge]

  return (
    <div className="w-[50%] flex items-center justify-center relative">
      <div
        className={`absolute flex items-center z-20 ml-10 sm:ml-20 ${container}`}
      >
        <BadgeComponent xp={xp} className={className} />
      </div>
    </div>
  )
}

export const XPBadge = memo(XPBadgeComponent)

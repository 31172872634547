import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonSpinner, IonTitle, IonToggle } from '@ionic/react'
import cx from 'classnames'

import { BackButton } from '@/components/atoms/BackButton'
import { Button } from '@/components/atoms/Button'
import { CurrencyInput } from '@/components/atoms/CurrencyInput'
import { Loading } from '@/components/atoms/Loading'
import { Title } from '@/components/atoms/Title'
import { Main } from '@/components/templates/main'

import { ApiError } from '@/services/awsAmplify/api'
import { appConfig } from '@/services/config'
import { useChatPaywall, useUpdateChatPaywall } from '@/lib/hooks/chat'
import { useShowErrorToast } from '@/lib/hooks/useShowErrorToast'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'
import { numberFormat } from '@/utils/currency'

import { QueryKeys } from '@/enums'

const minPrice = 1

export const ConfigureChatPaywall = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const currency = appConfig.currency
  const { showErrorToast } = useShowErrorToast()
  const { data: authUser } = useUserInfo()
  const [minRule, setMinRule] = useState<number>()
  const [maxRule, setMaxRule] = useState<number>()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    trigger,
    register,
    setValue,
    reset: resetForm,
  } = useForm<{ enabled: boolean; price: string }>({
    mode: 'onChange',
  })
  const enabled = watch('enabled')

  const {
    data: chatPaywall,
    isLoading: isLoadingChatPaywall,
    error: chatPaywallError,
  } = useChatPaywall(authUser.id)

  const { mutate: updateChatPaywall, isPending: isUpdatingChatPaywall } =
    useUpdateChatPaywall({
      onSuccess: ({ enabled, price }) => {
        resetForm({ enabled, price: String(price || minPrice) })
        queryClient.setQueryData([QueryKeys.ChatPaywall, authUser.id], {
          enabled,
          price,
        })
      },
      onError: (error: ApiError) => {
        const data = error?.response.data

        if (data === 'PRICE_TOO_LOW') {
          setMinRule(error?.response.metadata.min)
          register('price', { min: error?.response.metadata.min })
        } else if (data === 'PRICE_TOO_HIGH') {
          setMaxRule(error?.response.metadata.max)
          register('price', { max: error?.response.metadata.max })
        } else {
          showErrorToast({
            header: t('configureChatPaywall.errors.updateChatPaywall'),
            message: t('configureChatPaywall.errors.contactSupport'),
          })
        }

        trigger('price')
      },
    })

  useEffect(() => {
    if (!chatPaywallError) return

    showErrorToast({
      header: t('configureChatPaywall.errors.getChatPaywall'),
      message: t('configureChatPaywall.errors.contactSupport'),
    })
    navigate(-1)

    return () =>
      chatPaywallError &&
      queryClient.removeQueries({
        queryKey: [QueryKeys.ChatPaywall, authUser.id],
      })
  }, [authUser.id, chatPaywallError, navigate, showErrorToast, t])

  useEffect(() => {
    if (!chatPaywall) return

    resetForm({
      enabled: chatPaywall.enabled,
      price: String(chatPaywall.price || minPrice),
    })
  }, [chatPaywall, resetForm, setValue])

  const onSubmit = useCallback(
    ({ enabled, price }) => {
      const newConfig = {
        enabled,
        ...(enabled ? { price: Number(price) } : {}),
      }

      updateChatPaywall(newConfig)
    },
    [updateChatPaywall]
  )

  const errorMapping = useMemo(() => {
    return {
      min: t('configureChatPaywall.errors.min', {
        amount: numberFormat(minRule),
        currency,
      }),
      max: t('configureChatPaywall.errors.max', {
        amount: numberFormat(maxRule),
        currency,
      }),
    }
  }, [currency, maxRule, minRule, t])

  const header = useMemo(
    () => (
      <div className="flex justify-between items-center">
        <IonButtons slot="secondary">
          <BackButton
            className="text-white"
            defaultHref={`/@${authUser?.username}`}
          />
        </IonButtons>
        <IonTitle>
          <Title level={3} className="text-ellipsis overflow-hidden font-bold">
            {t('profile.configureChatPaywall')}
          </Title>
        </IonTitle>
        <Button
          className="w-16 mr-2"
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isDirty || !isValid || isUpdatingChatPaywall}
          size="small"
          type={isDirty && isValid ? 'primary' : 'secondary'}
        >
          {isUpdatingChatPaywall ? (
            <IonSpinner className="w-5" color="light" />
          ) : (
            t('save')
          )}
        </Button>
      </div>
    ),
    [
      handleSubmit,
      isDirty,
      isUpdatingChatPaywall,
      isValid,
      onSubmit,
      t,
      authUser,
    ]
  )

  return (
    <Main header={header} isPaddingEnabled={false}>
      {isLoadingChatPaywall ? (
        <Loading />
      ) : (
        <div className="flex flex-col px-6 py-4 text-white">
          <span className="text-2xl font-bold mb-3">
            {t('configureChatPaywall.title')}
          </span>
          <span className="text-sm mb-8">
            {t('configureChatPaywall.description', { currency })}
          </span>
          <div className="flex flex-row items-center text-sm font-bold">
            <span className={cx({ 'text-cool-gray-600': enabled })}>
              {t('configureChatPaywall.free')}
            </span>
            <Controller
              name="enabled"
              control={control}
              render={({ field }) => (
                <IonToggle
                  className="chat-paywall-toggle mx-2"
                  checked={field.value}
                  mode="ios"
                  onIonChange={(value) => field.onChange(value.detail.checked)}
                />
              )}
            />
            <span className={cx({ 'text-cool-gray-600': !enabled })}>
              {t('configureChatPaywall.paid')}
            </span>
          </div>
          {enabled && (
            <div>
              <div className="w-full flex flex-row items-center mt-7 text-sm">
                <span>{t('configureChatPaywall.payForChatPrice')}</span>
                <Controller
                  name="price"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <CurrencyInput
                      className="w-32 focus:outline-none bg-transparent mx-2 text-right text-2xl font-bold text-primary placeholder-primary border-b rounded-none pr-1"
                      onChange={(e) => field.onChange(e.target.value)}
                      value={String(field.value ?? minPrice)}
                    />
                  )}
                />
                <span className="font-bold">{appConfig.currency}</span>
              </div>
              {errors?.price && (
                <div className="text-sm pt-3 text-danger">
                  {errorMapping[errors?.price.type]}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Main>
  )
}

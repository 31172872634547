import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ChevronRight } from '@/components/atoms/assets/ChevronRight'
import { Currency } from '@/components/atoms/Currency'

import { appConfig } from '@/services/config'

type PriceBoosterButtonProps = {
  value?: number | string
}

export const PriceBoosterButton: FC<PriceBoosterButtonProps> = ({ value }) => {
  const { t } = useTranslation('common')

  const { creatorToken } = appConfig

  return (
    <div className="w-full flex justify-between items-center text-[3.75vw] sm:text-[1.5rem] font-bold cursor-pointer">
      <span className="text-cool-gray-200">
        {t('wallet.stake.yourPriceBooster', { creatorToken })}
      </span>
      <span className="flex items-center">
        <Currency value={value} />
        <ChevronRight className="w-[3vw] sm:w-[1.25rem] h-[3vw] sm:h-[1.25rem] ml-1" />
      </span>
    </div>
  )
}

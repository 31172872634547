import { FC, ForwardedRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { Loading } from '@/components/atoms/Loading'
import { SubscriptionButtonWithTip } from '@/components/organisms/SubscriptionButtonWithTip'

import { ApiError } from '@/services/awsAmplify/api'
import { SubscriptionData } from '@/services/subscription'
import { UserInfo } from '@/services/user'
import { queryClient } from '@/lib/queryClient'

import { QueryKeys } from '@/enums'

import { Section } from '../../../types'
import { SlidePlaceholder } from '../../SlidePlaceholder'
import { RenderPostsSlide } from '../RenderPostsSlide'
import { EmptySubscription } from './EmptySubscription'
import { MonetizeSubscription } from './MonetizeSubscription'

type RenderSubscriptionSlideProps = {
  activities: Record<string, unknown>[]
  isAuthUser: boolean
  forwardedRef: ForwardedRef<HTMLDivElement>
  isFetching: boolean
  isFetchingNextPage: boolean
  userInfo: UserInfo
  assignedIndex: number
  section: Section
  sectionIndex: number
  subscriptionFeedError: unknown | AxiosError | null
}

export const RenderSubscriptionSlide: FC<RenderSubscriptionSlideProps> = ({
  section,
  activities,
  isAuthUser,
  forwardedRef,
  assignedIndex,
  sectionIndex,
  isFetching,
  isFetchingNextPage,
  subscriptionFeedError,
  userInfo,
  ...props
}) => {
  const { t } = useTranslation('common')

  const authUser = queryClient.getQueryData<UserInfo>(['user'])
  const subscriptionData = queryClient.getQueryData<SubscriptionData>([
    QueryKeys.Subscription,
    authUser?.id,
  ])

  const response = (subscriptionFeedError as unknown as ApiError)?.response

  if (!activities?.length && isFetching && !isFetchingNextPage) {
    return <Loading />
  }

  if (response?.status === 402) {
    return (
      <div className="px-12">
        <div className="pb-8 pt-12 px-12">
          <span className="text-lg font-bold">
            {t('profile.subscription.subscribeTitle')}
          </span>
        </div>
        <SubscriptionButtonWithTip
          amount={response?.metadata?.price}
          userInfo={userInfo}
        />
      </div>
    )
  }

  if (activities?.length === 0 && !isAuthUser) {
    return (
      <div className="flex w-full h-[21.875rem] flex-col text-center justify-center items-center">
        <div className="text-[5rem]">🦥</div>
        <p>{t('profile.empty')}</p>
      </div>
    )
  }

  if (activities?.length > 0) {
    return (
      <RenderPostsSlide
        section={section}
        activities={activities}
        isAuthUser={isAuthUser}
        forwardedRef={forwardedRef}
        assignedIndex={assignedIndex}
        sectionIndex={sectionIndex}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        {...props}
      />
    )
  }

  return (
    <SlidePlaceholder>
      {subscriptionData?.price ? (
        <EmptySubscription />
      ) : (
        <MonetizeSubscription />
      )}
    </SlidePlaceholder>
  )
}

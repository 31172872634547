import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getCurrentUser } from 'aws-amplify/auth'
import { AxiosError } from 'axios'

import { Login } from '@components/templates/login'

import { sendOTP } from '@services/auth'
import { AuthUser } from '@services/auth'
import { errorBoundary } from '@/services/errorBoundary'
import { setWebPushNotificationsExternalUserId } from '@/services/webPushNotifications'
import { useAmplitudeIdentifyUser } from '@/lib/hooks/useAmplitudeIdentifyUser'
import { useGtm } from '@/lib/hooks/useGtm'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { queryClient } from '@/lib/queryClient'
import { Navigate, useLocation, useNavigate } from '@/lib/routing'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { getStreamSetUserInfo } from '@/lib/tracking/platform/getstream'
import { Routes } from '@/router/routes'

import { EnterCode, EnterFieldValue } from './EnterCode'

export const Confirm: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { trackEvent } = useGtm()
  const { amplitudeIdentifyUser } = useAmplitudeIdentifyUser()
  const { data: captcha } = useQuery<AuthUser>({
    queryKey: ['captchaResponse'],
  })

  if (!captcha) {
    navigate(Routes.AUTH_SIGN_UP, {
      replace: true,
    })
  }

  const { captchaResponse, authType } = captcha || {}

  const [error, setError] = useState<string>('')
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm<EnterFieldValue>()
  const { t } = useTranslation('common')

  const trackSignInCompleted = () => {
    const { state } = location
    const { referrer: authMethod = '' } = state as { referrer: string }
    if (authMethod) {
      Tracking.triggerEvent(TrackingEvent.SigninCompleted, {
        authMethod,
      })
    }
  }

  const { data: user, refetch: getUserData } = useUserInfo({
    options: {
      retry: 5,
      enabled: false,
    },
  })

  const { isPending, mutate } = useMutation({
    mutationKey: ['sendOTP'],
    mutationFn: sendOTP,
    onSuccess: async (data) => {
      const { isSignedIn } = data || {}
      if (isSignedIn) {
        trackSignInCompleted()
        const { username: userId } = await getCurrentUser()
        await trackEvent({
          event: TrackingEvent.SignIn,
          value: {
            authType,
            userId,
          },
        })

        setWebPushNotificationsExternalUserId(userId)

        await getUserData()
      } else {
        setError(t('registration.confirm.invalidCode'))
      }
    },
    onError: (error: AxiosError) => {
      errorBoundary(error)
      if (error.toString().includes('NotAuthorizedException')) {
        setError(t('registration.confirm.noMoreAttempts'))
      } else {
        setError(t('registration.confirm.invalidCode'))
      }
    },
  })

  const onSubmit = async ({ code }) => {
    const data: { refUsername?: string; redirectUrl?: string } =
      queryClient.getQueryData(['referral'])

    mutate({
      code,
      refUsername: data?.refUsername,
      redirectUrl: data?.redirectUrl,
    })
    Tracking.triggerEvent(TrackingEvent.ReferralSignupSubmitted, {
      refUsername: data?.refUsername,
    })
  }

  const title = (
    <Trans i18nKey="registration.confirm.title">
      {t('registration.confirm.title')}
    </Trans>
  )

  useEffect(() => {
    if (user) {
      reset()
      amplitudeIdentifyUser(user)

      if (user.username) {
        if (location.pathname !== Routes.LOGIN) {
          navigate(Routes.LOGIN, {
            replace: true,
          })
        }
        getStreamSetUserInfo(user.id, user.username)
      } else {
        if (location.pathname !== Routes.AUTH_CONSENT) {
          navigate(Routes.AUTH_CONSENT, {
            replace: true,
            state: { direction: 'none' },
          })
        }
      }
    }
  }, [user, reset, amplitudeIdentifyUser, location.pathname, navigate])

  if (!captchaResponse) {
    return <Navigate to={Routes.ROOT} />
  }

  if (
    user?.username &&
    !user?.deactivated &&
    location.pathname.includes(Routes.AUTH_CONFIRM)
  ) {
    return <Navigate to={Routes.FEED} />
  }

  return (
    <Login title={title} backButton>
      <form className="px-4" onSubmit={handleSubmit(onSubmit)}>
        <EnterCode
          key={user?.username}
          control={control}
          submit={handleSubmit(onSubmit)}
          error={error}
          authType={authType}
          isErrored={{
            code: error?.toString().includes('NotAuthorizedException') ?? false,
            geeCaptchaToken:
              error?.toString().includes('ReCaptchaTokenNotValid') ?? false,
          }}
          setError={setError}
          isLoading={isPending}
          isDirty={isDirty}
          captchaResponse={captchaResponse}
        />
      </form>
    </Login>
  )
}

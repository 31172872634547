import { isEmpty } from 'lodash'
import queryString from 'query-string'

import { appConfig } from '@services/config'

export const videoUrl = (url: string): string => {
  if (!url) return ''
  if (url.includes('http')) return url
  return `${appConfig.cdn}/${url}`
}

export const getThumbnailImage = (originImage: string) =>
  `${appConfig.cdn}/${originImage}/x1.jpeg`

export const getSliderImage = (originImage: string) =>
  `${appConfig.cdn}/${originImage}/x2.jpeg`

interface IUrlWithQueryParams {
  url: string
  params: Record<string, unknown>
}

export const hasQueryParams = (url: string) => {
  try {
    const urlObject = new URL(url)
    // Check if the URL has search parameters (query parameters)
    return (
      urlObject.searchParams &&
      urlObject.searchParams.keys().next().done === false
    )
  } catch (error) {
    // Handle invalid URLs or other errors
    console.error('Invalid URL:', url)
    return false
  }
}

export const urlWithQueryParams = ({ url, params }: IUrlWithQueryParams) => {
  const serialisedParams =
    isEmpty(params) || hasQueryParams(url)
      ? ''
      : `?${queryString.stringify(params)}`

  return url + serialisedParams
}

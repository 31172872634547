import { FC } from 'react'

interface ISlideProps {
  onClick?: () => void
}

export const Slide: FC<ISlideProps> = ({ children, onClick }) => (
  <div className="h-full" onClick={onClick}>
    {children}
  </div>
)

import {
  Control,
  Controller,
  FieldErrorsImpl,
  FieldValues,
  useWatch,
} from 'react-hook-form'
import { UseFormHandleSubmit } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { IonLabel, IonSpinner, IonText } from '@ionic/react'

import { Button } from '@components/atoms/Button'
import { InputField } from '@components/atoms/InputField'
import { GeeCaptcha } from '@/components/atoms/GeeCaptcha'

import { appConfig } from '@/services/config'
import { emailPattern } from '@utils/validation'

interface SetEmailProps {
  control?: Control<EmailFieldValues>
  submit?: ReturnType<UseFormHandleSubmit<FieldValues>>
  isLoading: boolean
  formState: {
    errors: Partial<
      FieldErrorsImpl<{
        email: string
        geeCaptchaToken: string
      }>
    >
  }
  isErrored: {
    email: boolean
    geeCaptchaToken: boolean
    signInBlockedDomain: boolean
    signUpBlockedDomain: boolean
    invalidCharacters: boolean
    signUpNotAvailable: boolean
    disabledUser?: boolean
  }
}

export interface EmailFieldValues {
  email: string
  geeCaptchaToken: string
}

const SetEmail = ({
  control,
  submit,
  isLoading,
  formState,
  isErrored,
}: SetEmailProps) => {
  const { t } = useTranslation('common')
  const { errors } = formState
  const { email, geeCaptchaToken } = useWatch({
    control,
  })

  const validationMessages = {
    pattern: t('emailPattern'),
  }

  return (
    <>
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <InputField
            type="email"
            value={field?.value}
            onChange={(e) => field.onChange(e.value)}
            autocomplete="on"
          />
        )}
        rules={{ required: true, pattern: emailPattern }}
      />
      {errors.email && (
        <div className="flex justify-center">
          <IonLabel color="danger" className="text-center mt-2">
            {validationMessages[errors.email.type]}
          </IonLabel>
        </div>
      )}
      {isErrored['email'] && (
        <div className="flex justify-center">
          <IonLabel color="danger" className="text-center mt-2">
            {t('registration.email.error.invalidEmailFormat')}
          </IonLabel>
        </div>
      )}
      {isErrored['signUpBlockedDomain'] && (
        <div className="flex justify-center">
          <IonLabel color="danger" className="text-center mt-2">
            {t('registration.email.error.signUpBlockedDomain')}
          </IonLabel>
        </div>
      )}
      {isErrored['signInBlockedDomain'] && (
        <div className="flex justify-center">
          <IonLabel color="danger" className="text-center mt-2">
            {t('registration.email.error.signInBlockedDomain')}
          </IonLabel>
        </div>
      )}
      {isErrored['invalidCharacters'] && (
        <div className="flex justify-center">
          <IonLabel color="danger" className="text-center mt-2">
            {t('registration.email.error.invalidCharacters')}
          </IonLabel>
        </div>
      )}
      {isErrored['signUpNotAvailable'] && (
        <div className="flex justify-center">
          <IonLabel color="danger" className="text-center mt-2">
            {t('registration.email.error.signUpNotAvailable')}
          </IonLabel>
        </div>
      )}
      {isErrored['disabledUser'] && (
        <div className="flex justify-center">
          <IonLabel color="danger" className="text-center text-sm mt-2">
            <Trans
              i18nKey="registration.profile.deleted"
              components={{
                supportLink: <a href="mailto:support@republik.io" />,
              }}
            >
              {t('registration.profile.deleted')}
            </Trans>
          </IonLabel>
        </div>
      )}
      <IonText className="text-gray-500 text-center pt-4 block">
        {t('registration.email.info')}
      </IonText>
      {appConfig.geeTestSignUpId && (
        <>
          <br />
          <Controller
            control={control}
            name="geeCaptchaToken"
            render={({ field }) => (
              <GeeCaptcha
                validate={(e) => {
                  field.onChange(e)
                }}
              />
            )}
            rules={{ required: true }}
          />
          {isErrored['geeCaptchaToken'] && (
            <div className="flex justify-center">
              <IonLabel color="danger" className="text-center mt-2">
                {t('registration.invalidToken')}
              </IonLabel>
            </div>
          )}
        </>
      )}
      <Button
        className="mt-8 flex mx-auto w-lg"
        isDisabled={
          !email ||
          !!errors.email ||
          (appConfig.geeTestSignUpId && !geeCaptchaToken) ||
          isLoading
        }
        onClick={() => submit?.()}
        size="large"
      >
        {isLoading ? (
          <IonSpinner color="light" />
        ) : (
          t('registration.email.getCode')
        )}
      </Button>
    </>
  )
}

export default SetEmail

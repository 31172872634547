import { FC } from 'react'

import { useUserInfo } from '@/lib/hooks/useUserInfo'

import { NextAirdropSchedule } from './NextAirdropSchedule'
import { VerifyAccount } from './VerifyAccount'

export const AirdropBanner: FC = () => {
  const { data: authUser } = useUserInfo()

  if (authUser?.completedFaceLivenessCheck) return <NextAirdropSchedule />

  return <VerifyAccount />
}

import { useInfiniteQuery } from '@tanstack/react-query'
import flatten from 'lodash/flatten'

import {
  getStakeTransactions,
  IStakeVaultTransactions,
} from '@/services/stake/getStakeTransactions'
import { UserInfo } from '@/services/user'
import { getTransactions } from '@/services/wallet'
import { ITransactions } from '@/services/wallet/getTransactions'
import { useNetwork } from '@/lib/hooks/useNetwork'
import { queryClient } from '@/lib/queryClient'

import { QueryKeys } from '@/enums'
import { TransactionsType } from '@/enums'

import { useUserInfo } from '../../useUserInfo'

export const useTransactions = ({
  userInfo,
  enabled,
  transactionsType = TransactionsType.All,
}: {
  userInfo: UserInfo
  enabled?: boolean
  transactionsType?: TransactionsType
}) => {
  const { data: authUser } = useUserInfo()
  const { isOnline } = useNetwork()

  const {
    data: transactions,
    isLoading: isGetTransactionsLoading,
    isFetching: isGetTransactionsFetching,
    fetchNextPage: fetchTransactionsNextPage,
    hasNextPage: hasGetTransactionsNextPage,
    refetch: refetchTransactions,
    isError: hasTransactionsError,
    isSuccess,
  } = useInfiniteQuery({
    queryKey: [QueryKeys.Transactions, transactionsType, userInfo?.id],
    queryFn: ({
      pageParam,
    }): Promise<ITransactions | IStakeVaultTransactions> => {
      switch (transactionsType) {
        case TransactionsType.Stake:
          return getStakeTransactions({
            pageParam,
          })
        default:
          return getTransactions({
            userId: userInfo?.id,
            pageParam,
          })
      }
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.lastKey ? { startKey: lastPage?.lastKey } : undefined
    },
    enabled: enabled && userInfo?.id === authUser?.id,
    initialPageParam: '',
  })

  const refetchFirstTransactionsPage = async () => {
    queryClient.setQueryData<{
      pages: unknown[]
      pageParams: (string | undefined)[]
    }>([QueryKeys.Transactions, transactionsType, userInfo?.id], (data) => ({
      pages: data?.pages?.slice(0, 1) || [],
      pageParams: data?.pageParams?.slice(0, 1) || [],
    }))
    await refetchTransactions()
  }

  return {
    transactions: transactions
      ? flatten(
          transactions?.pages?.map(
            (group: IStakeVaultTransactions) => group.items
          )
        )
      : null,
    isGetTransactionsLoading: isGetTransactionsLoading && isOnline,
    isGetTransactionsFetching,
    fetchTransactionsNextPage,
    hasGetTransactionsNextPage,
    refetchFirstTransactionsPage,
    hasTransactionsError: hasTransactionsError && isOnline,
    isSuccess,
  }
}

import { useParams } from '@/lib/routing'

import { NotFound } from '../pages/NotFound'
import { PublicUserProfile } from '../pages/PublicUserProfile'
import { UserProfile } from '../pages/UserProfile'

const ConditionalProfile = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean
}) => {
  const { userId } = useParams()

  const isUserProfile = /^@/.test(userId)

  return isUserProfile ? (
    isAuthenticated ? (
      <UserProfile />
    ) : (
      <PublicUserProfile />
    )
  ) : (
    <NotFound />
  )
}

export default ConditionalProfile

import ReactMarkdown from 'react-markdown'
import { ReactMarkdownProps } from 'react-markdown/lib/ast-to-react'

import { Avatar } from '@components/atoms/Avatar'
import { UserName } from '@/components/atoms/UserName'
import { CommentsActions } from '@/components/molecules/CommentsActions'
import { IActivityItem } from '@/components/pages/Activity/interfaces'

import { IReaction } from '@/lib/hooks/useCommentsSection'
import { Link } from '@/lib/routing'
import { matchUsernamesToUserIds } from '@/utils/matchUsernamesToMentionIds'
import { parseLink, safeCastUnknownToType } from '@/utils/utils'
import { formatDistanceToNowDate } from 'utils/date'

interface CommentsListProps {
  item?: IReaction
  activity?: IActivityItem
  hideActions?: boolean
  className?: string
  userId?: string
  contestUserId?: string
  onDelete?: (id: UUID) => void
}

export const CommentsListItem = ({
  item,
  activity,
  hideActions = false,
  className = '',
  userId,
  contestUserId,
  onDelete,
}: CommentsListProps) => {
  const text =
    activity?.object?.data?.description ||
    safeCastUnknownToType<string>(item?.data?.text)
  const mentions =
    activity?.object?.data?.mentions ||
    safeCastUnknownToType<string[]>(item?.data?.mentions)
  const comment = matchUsernamesToUserIds(text, mentions)

  if (!comment?.trim() || !item?.user) return null

  const user = activity?.object
    ? {
        id: activity?.object.data.userId,
        data: {
          name: activity?.object.data.creatorName,
          profileImage: activity?.object.data.creatorAvatar,
          badge: activity?.object?.data?.badge,
          isModerator: activity?.object?.data?.isModerator,
          isAmbassador: activity?.object?.data?.isAmbassador,
        },
      }
    : item?.user

  const isActivityCreator = user?.id === userId
  const isContestCreator = userId === contestUserId

  const renderers = {
    a: (props: ReactMarkdownProps) => {
      return (
        <Link
          to={parseLink(
            safeCastUnknownToType<string>(`/${props?.children?.[0]}`)
          )}
          className="relative text-primary"
        >
          {props?.children[0]}
        </Link>
      )
    },
  }

  const areCommentsActionsShown =
    (isActivityCreator || isContestCreator) && !hideActions

  return (
    <article className={`px-4 my-5 flex align-top ${className}`}>
      <Avatar
        className="mr-3"
        userId={user?.id}
        isModerator={user?.data?.isModerator}
        isAmbassador={user?.data?.isAmbassador}
        badge={user?.data?.badge}
        clickable
        username={user?.data?.name}
      />
      <div className="w-[85vw]">
        <header className="-mt-1 flex justify-between items-center">
          <div className="flex items-center">
            <UserName
              className="mr-2 text-sm font-bold"
              name={user?.data?.name}
              userId={user?.id}
            />
            <span className="text-xs opacity-50">
              {formatDistanceToNowDate({
                timestamp:
                  activity?.object?.data?.createdAt || item?.created_at,
                addSuffix: true,
                useUTC: false,
              })}
            </span>
          </div>
          {areCommentsActionsShown && (
            <CommentsActions onDelete={onDelete} commentId={item.id} />
          )}
        </header>
        <div className="wordWrap-break mt-1 text-sm">
          <ReactMarkdown
            className="whitespace-pre-line pr-4"
            components={renderers}
          >
            {comment}
          </ReactMarkdown>
        </div>
      </div>
    </article>
  )
}

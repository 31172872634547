import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { createSession } from '@/services/faceliveness'

export const useLiveness = () => {
  const [present] = useIonToast()
  const { t } = useTranslation('common')
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['sessionId'],
    queryFn: async () => {
      const sessionId = await createSession()

      return sessionId
    },
    gcTime: 0,
  })

  if (isError) {
    present({
      message:
        (error as AxiosError<{ message?: string }>)?.response.data?.message ||
        t('tip.errors.somethingWentWrong'),
      duration: 3000,
      position: 'top',
    })
  }

  return { isLoading, sessionId: data, isError, error }
}

import { FC, memo } from 'react'

interface CategoryNameProps {
  name: string
}

const CategoryNameComponent: FC<CategoryNameProps> = ({ name }) => (
  <span className="text-xxs leading-none lowercase border border-white px-1.5 py-0.5 rounded-full mt-1">{`#${name}`}</span>
)

export const CategoryName = memo(CategoryNameComponent)

import { useEffect } from 'react'
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { errorBoundary } from '@/services/errorBoundary'
import { getUserInfo, UserInfo } from '@/services/user'

interface UseUserInfo {
  (params?: {
    userId?: string
    options?: Omit<UseQueryOptions<UserInfo, unknown>, 'queryKey'>
  }): UseQueryResult<UserInfo, unknown>
}

// If we don't pass the 'userId' option then, we
// use the userInfo for the currently logged in user
export const useUserInfo: UseUserInfo = (params) => {
  const userId = params?.userId
  const options = params?.options

  const result = useQuery({
    queryKey: ['user', userId].filter(Boolean),
    queryFn: getUserInfo,
    ...options,
  })

  useEffect(() => {
    if (result.isError) {
      errorBoundary(result.error)
    }
  }, [result.isError, result.error])

  return result
}

import { get, post } from '../awsAmplify'

export const launchCreatorToken = async () => {
  return await post({
    path: '/fireblocks/creator-token',
  })
}

export const getCreatorTokenConfig = async () => {
  return await get({
    path: '/fireblocks/creator-token-config',
  })
}

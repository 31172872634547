import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonSpinner } from '@ionic/react'
import cx from 'classnames'

import { Plus } from '@/components/atoms/assets/Plus'

import {
  ContentType,
  MediaType,
  ugcComposer,
  UgcComposerResult,
} from '@/plugins/ugc-composer'

export interface IAddImageButtonProps {
  onFileChange: (result?: UgcComposerResult) => void
  loadedImgCount?: number
  isLoading?: boolean
  contentType?: ContentType
  mediaType?: MediaType
  title?: string
}

export const AddImageButton: FC<IAddImageButtonProps> = ({
  onFileChange,
  loadedImgCount = 0,
  isLoading,
  contentType,
  mediaType,
  title,
}) => {
  const { t } = useTranslation('common')

  const selectFile = async () => {
    const result = await ugcComposer({ contentType, title, mediaType })

    if (result?.media?.url) {
      onFileChange(result)
    }
  }

  const getConversationLabel = () => {
    switch (mediaType) {
      case 'image':
        return t('common.addImage')
      case 'video':
        return t('common.addVideo')
      default:
        return t('common.addImageOrVideo')
    }
  }

  const renderLabel = () => {
    switch (contentType) {
      case 'conversation':
      case 'reply':
      case 'post':
        return getConversationLabel()
      default:
        return t('common.addImage')
    }
  }

  return (
    <>
      <button
        onClick={selectFile}
        disabled={loadedImgCount >= 3 || isLoading}
        className={cx(
          'flex justify-center items-center rounded-md bg-cool-gray-800',
          {
            'min-h-[15rem]': !loadedImgCount,
            'min-h-[12rem]': loadedImgCount,
          }
        )}
      >
        {!isLoading ? (
          <div>
            <Plus className="block mb-2 mx-auto" />
            <b className="block text-primary text-xs">{renderLabel()}</b>
          </div>
        ) : (
          <IonSpinner color="main" />
        )}
      </button>
    </>
  )
}

import { FC, useMemo } from 'react'
import { IonButtons } from '@ionic/react'
import { motion } from 'framer-motion'

import { ChatButton } from '@/components/molecules/ChatButton'
import { CurrencyBalanceButton } from '@/components/molecules/CurrencyBalanceButton'
import { BuyCurrency } from './components/BuyCurrency'
import { EarnMoreModal } from './components/EarnMore/EarnMoreModal'

import { useAccountInfo } from '@/services/account'
import { IWallet } from '@/services/wallet/getWallet'
import { tokenToString } from '@/utils/token'

import { Pages } from '@/enums'

interface IHeader {
  wallet: IWallet
  scrollToTop: () => void
}

export const Header: FC<IHeader> = ({ scrollToTop, wallet }) => {
  const { data: accountInfo } = useAccountInfo()

  const maxStakeRequirement = useMemo(
    () =>
      accountInfo?.stakedRpkForXpMultiplier.reduce(
        (max, current) => (current.staked > max ? current.staked : max),
        0
      ),
    [accountInfo?.stakedRpkForXpMultiplier]
  )
  const currentlyStaked = useMemo(
    () => Number(tokenToString(wallet?.stakedBalance ?? '0')),
    [wallet?.stakedBalance]
  )
  const isMaxStaked =
    currentlyStaked && maxStakeRequirement
      ? currentlyStaked >= maxStakeRequirement
      : false

  return (
    <div className="flex flex-row justify-between py-2.5 pl-3">
      <img src="/logo-icon.svg" alt="republik" onClick={() => scrollToTop()} />
      <IonButtons slot="end">
        <div
          style={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            height: '26px',
            overflow: 'hidden',
            width: '100px',
          }}
        >
          <motion.div
            style={{
              height: '26px',
              position: 'absolute',
              top: 0,
            }}
            animate={{
              y: [26, 0, 0, 0, -26],
            }}
            transition={{
              duration: 3,
              ease: 'easeInOut',
              times: [0, 0.1, 0.5, 0.9, 1],
              repeat: Infinity,
              repeatDelay: 3,
            }}
          >
            <CurrencyBalanceButton wallet={wallet} />
          </motion.div>
          <motion.div
            style={{
              height: '26px',
              position: 'absolute',
              top: 0,
            }}
            animate={{
              y: [26, -1, -1, -1, -26],
            }}
            transition={{
              delay: 3,
              duration: 3,
              ease: 'easeInOut',
              times: [0, 0.1, 0.5, 0.9, 1],
              repeat: Infinity,
              repeatDelay: 3,
            }}
          >
            {isMaxStaked ? <BuyCurrency /> : <EarnMoreModal />}
          </motion.div>
        </div>
        <span className="border-l ml-[1px] pr-[1px] border-cool-gray-700 h-5" />
        <ChatButton className="ml-2 mr-2" page={Pages.Feed} />
      </IonButtons>
    </div>
  )
}

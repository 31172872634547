import { FC } from 'react'
import { IonItem } from '@ionic/react'

import { Avatar } from '@/components/atoms/Avatar'

import { IUser } from '@services/findUsers'

export interface IListProps {
  item: IUser
  index?: number
  isOrdered?: boolean
  children?: React.ReactNode
  className?: string
  onClick?: () => void
  overflowClass?: string
}

export const ListItem: FC<IListProps> = ({
  item,
  index = 0,
  isOrdered = false,
  children,
  className,
  onClick,
  overflowClass = 'overflow-visible',
}) => {
  return (
    <IonItem
      key={item.id}
      className={`py-4 ${overflowClass} ${className || ''}`}
      data-test="explore-users-list-item"
    >
      {isOrdered && <span className={'pr-4'}>{index + 1}</span>}
      <span onClick={onClick} className="pr-4">
        <Avatar
          userId={item.userId || item.id}
          isModerator={item?.isModerator}
          isAmbassador={item?.isAmbassador}
          badge={item?.badge}
          username={item?.username}
          clickable
        />
      </span>
      {children}
    </IonItem>
  )
}

import { memo, useEffect, useMemo, useState } from 'react'
import { IonItem } from '@ionic/react'
import queryString from 'query-string'
import { Channel } from 'stream-chat'
import { DefaultGenerics } from 'stream-chat'
import {
  ChannelPreviewUIComponentProps,
  MessageDeliveryStatus,
  StreamMessage,
  useChatContext,
} from 'stream-chat-react'

import { Avatar } from '@components/atoms/Avatar'
import { UserName } from '@components/atoms/UserName'
import { getIcon } from '@/components/pages/UGC/Messaging/Messages/MessageStatus'

import { UserInfo } from '@/services/user'
import { useLocation, useNavigate } from '@/lib/routing'
import { formatDate, isDateToday } from '@/utils/date'

import { Badge, DateFormat } from '@/enums'

interface ChannelListItemProps
  extends Pick<ChannelPreviewUIComponentProps, 'latestMessage'> {
  channel: Channel<DefaultGenerics>
  authUser: UserInfo
  lastMessage: StreamMessage<DefaultGenerics>
  messageDeliveryStatus: MessageDeliveryStatus
}

const ChannelListItemComponent = ({
  channel,
  authUser,
  lastMessage,
  latestMessage,
  messageDeliveryStatus,
}: ChannelListItemProps) => {
  const [isPresent, setIsPresent] = useState(false)
  const navigate = useNavigate()
  const { client } = useChatContext()
  const { search } = useLocation()
  const { page = '' } = queryString.parse(search) as { page: string }

  const userId = useMemo(() => {
    return Object.keys(channel?.state?.members)?.filter(
      (member) => member !== authUser.id
    )?.[0]
  }, [channel?.state, authUser])

  useEffect(() => {
    setIsPresent(() => channel?.state?.members[userId].user?.online)

    const handler = client.on((event) => {
      if (event.type === 'user.presence.changed' && event.user.id === userId) {
        setIsPresent(event?.user?.online)
      }
    })
    return () => {
      handler.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const data = useMemo(() => {
    const deliveryStatus = getIcon({
      sending: false,
      deliveredAndRead: messageDeliveryStatus === 'read',
      delivered: messageDeliveryStatus === 'delivered',
    })

    return {
      lastMessageTimestamp: channel?.state.last_message_at
        ? formatDate(
            channel?.state.last_message_at,
            isDateToday(channel?.state.last_message_at)
              ? DateFormat.LocalizedTime
              : DateFormat.LocalizedDate
          )
        : null,
      username: channel?.state?.members?.[userId].user.name,
      isModerator: channel?.state?.members?.[userId].user
        ?.isModerator as boolean,
      isAmbassador: channel?.state?.members?.[userId].user
        ?.isAmbassador as boolean,
      badge: channel?.state?.members?.[userId].user?.badge as Badge,
      deliveryStatus,
    }
  }, [channel?.state, userId, messageDeliveryStatus])

  return (
    <IonItem
      className="py-3.5 channel-list-item cursor-pointer"
      onClick={() => navigate(`/messages/${userId}?page=${page}`)}
    >
      <span className="pr-4">
        <Avatar
          userId={userId}
          isModerator={data?.isModerator}
          isAmbassador={data?.isAmbassador}
          badge={data?.badge}
          username={data?.username}
          lazy={false}
          isPresent={isPresent}
        />
      </span>
      <div className="min-w-[5.5rem]">
        <div className="text-base">
          <UserName className="block font-bold" name={data.username} />
        </div>
        <div className="block truncate text-ellipsis break-large text-base channel-list-message">
          {lastMessage?.text || latestMessage}
        </div>
      </div>
      <div
        className="pt-5 text-right text-xs font-normal channel-list-timestamp grow-0 flex justify-center items-center"
        slot="end"
      >
        <span className="text-tertiary-951 text-base flex justify-center items-center pr-1">
          {channel?.state?.unreadCount ? (
            <div className="w-2 h-2 bg-red-500 rounded-full" />
          ) : (
            data?.deliveryStatus
          )}
        </span>
        {data?.lastMessageTimestamp}
      </div>
    </IonItem>
  )
}

export const ChannelListItem = memo(ChannelListItemComponent)

import { FC, memo } from 'react'

import { AnimateCounter } from '@/components/atoms/AnimateCounter'

export interface SilverBadgeLargeComponentProps {
  className?: string
  xp: number
  label?: string
}

const SilverBadgeLargeComponent: FC<SilverBadgeLargeComponentProps> = ({
  className = '',
  xp = 0,
  label = 'Silver',
}) => {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 197 219"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.9204 152.298L56.6873 201.316L67.5808 193.315L67.7249 155.386L56.9204 152.298Z"
        fill="#B5B5B5"
      />
      <path
        d="M57.3682 152.534L57.0768 200.412L67.1146 192.883L67.3477 155.239L57.3682 152.534Z"
        fill="url(#paint0_linear_1149_6082)"
      />
      <path
        d="M139.854 150.65L140.087 199.668L129.197 191.667L129.049 153.738L139.854 150.65Z"
        fill="#B5B5B5"
      />
      <path
        d="M139.406 150.886L139.7 198.767L129.663 191.238L129.43 153.591L139.406 150.886Z"
        fill="url(#paint1_linear_1149_6082)"
      />
      <path
        d="M86.3529 206.217L77.7351 198.844L77.7198 168.052L86.4879 168.062L86.3529 206.217Z"
        fill="#B5B5B5"
      />
      <path
        d="M85.9788 205.221L78.2013 198.587L78.1859 168.974L86.0953 169.002L85.9788 205.221Z"
        fill="url(#paint2_linear_1149_6082)"
      />
      <path
        d="M112.752 206.257L121.37 198.884L121.385 168.092L112.617 168.101L112.752 206.257Z"
        fill="#B5B5B5"
      />
      <path
        d="M113.126 205.261L120.904 198.626L120.919 169.011L113.01 169.042L113.126 205.261Z"
        fill="url(#paint3_linear_1149_6082)"
      />
      <path
        d="M86.4878 168.062L86.3529 206.217L99.482 219L112.752 206.257L112.617 168.101L86.4878 168.062Z"
        fill="#B5B5B5"
      />
      <path
        d="M86.5952 167.587L86.4603 205.522L99.5158 218.231L112.709 205.562L112.574 167.627L86.5952 167.587Z"
        fill="url(#paint4_linear_1149_6082)"
      />
      <path
        d="M69.3258 155.907L69.3319 191.057L99.4053 170.73L128.117 191.042L128.077 155.867L69.3258 155.907Z"
        fill="#B5B5B5"
      />
      <path
        d="M69.4178 156.014L69.4055 190.907L99.4636 170.445L127.961 190.736L128.004 155.937L69.4178 156.014Z"
        fill="url(#paint5_linear_1149_6082)"
      />
      <path
        d="M132.562 51.5526L196.5 39.5C196.5 39.5 189.332 101.945 167.431 111.198C145.531 120.451 117.182 132.348 117.182 132.348L132.562 51.5526Z"
        fill="url(#paint6_linear_1149_6082)"
      />
      <path
        d="M119.29 56.5897L192.5 43C192.5 43 186.17 99.6759 166.521 109.626C147.157 119.415 115.557 130.633 115.557 130.633L119.273 56.5897H119.29Z"
        fill="url(#paint7_linear_1149_6082)"
      />
      <path
        d="M64.4947 51.5526L0.556946 39.5C0.556946 39.5 7.72529 101.945 29.6256 111.198C51.526 120.451 79.8749 132.348 79.8749 132.348L64.4947 51.5526Z"
        fill="url(#paint8_linear_1149_6082)"
      />
      <path
        d="M77.7666 56.5897L4.55695 43C4.55695 43 10.8866 99.6759 30.5362 109.626C49.8999 119.415 81.5 130.633 81.5 130.633L77.7844 56.5897H77.7666Z"
        fill="url(#paint9_linear_1149_6082)"
      />
      <path
        d="M91.0035 181.099L91.003 181.099L27.5738 143.97L27.5735 143.969C22.7406 141.141 19.7333 135.892 19.7333 130.29V55.7073C19.7333 50.105 22.7406 44.8556 27.5735 42.0275L27.5738 42.0273L91.0023 4.89856C95.9382 2.01144 102.07 2.01192 107.001 4.89805C107.001 4.89808 107.001 4.8981 107.001 4.89812L170.426 42.0272L170.426 42.0274C175.259 44.8556 178.267 50.105 178.267 55.7073V130.29C178.267 135.895 175.256 141.141 170.426 143.97L107.001 181.099L107.001 181.099C104.534 182.544 101.769 183.267 99.0021 183.267C96.2351 183.267 93.4704 182.544 91.0035 181.099Z"
        fill="url(#paint10_radial_1149_6082)"
        stroke="#CACACA"
        strokeWidth="5.46667"
      />
      <path
        d="M90.9499 176.326L90.9497 176.326L31.3881 141.592L31.3879 141.592C26.5219 138.755 23.5 133.497 23.5 127.884V58.1132C23.5 52.5003 26.5219 47.2418 31.3879 44.405L31.3881 44.4049L90.9492 9.67139C95.9179 6.77612 102.09 6.77638 107.054 9.67111C107.054 9.67112 107.054 9.67114 107.054 9.67115L166.612 44.4049L166.612 44.405C171.478 47.2418 174.5 52.5003 174.5 58.1132V127.884C174.5 133.5 171.475 138.755 166.612 141.592C166.612 141.592 166.612 141.592 166.612 141.592L107.054 176.326L107.054 176.326C104.571 177.775 101.787 178.5 99.002 178.5C96.2169 178.5 93.4334 177.775 90.9499 176.326Z"
        stroke="#ECECEC"
        strokeWidth="3"
      />
      <mask
        id="mask0_1149_6082"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="26"
        y="12"
        width="146"
        height="164"
      >
        <path
          d="M99.0019 176C96.1149 176 93.2279 175.253 90.6515 173.758L34.184 141.021C29.1346 138.094 26 132.673 26 126.879V61.1182C26 55.3238 29.1346 49.9029 34.184 46.9764L90.6515 14.2388C95.8044 11.2537 102.203 11.2537 107.352 14.2388L163.816 46.9764C168.865 49.9029 172 55.3238 172 61.1182V126.879C172 132.677 168.862 138.094 163.816 141.021L107.352 173.758C104.776 175.253 101.889 176 99.0019 176Z"
          fill="#844B07"
        />
      </mask>
      <g mask="url(#mask0_1149_6082)">
        <g filter="url(#filter0_i_1149_6082)">
          <rect y="143" width="200" height="143" rx="71.5" fill="#717070" />
        </g>
      </g>
      <mask
        id="mask1_1149_6082"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="26"
        y="11"
        width="144"
        height="163"
      >
        <path
          d="M98.0018 174C95.1544 174 92.307 173.257 89.7659 171.772L34.0719 139.234C29.0917 136.325 26 130.938 26 125.179V59.8187C26 54.0596 29.0917 48.6718 34.0719 45.7631L89.7659 13.2252C94.8482 10.2583 101.159 10.2583 106.238 13.2252L161.928 45.7631C166.908 48.6718 170 54.0596 170 59.8187V125.179C170 130.941 166.905 136.325 161.928 139.234L106.238 171.772C103.697 173.257 100.849 174 98.0018 174Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask1_1149_6082)">
        <path
          opacity="0.8"
          d="M-0.548194 78.5459L2.23712 14.972C3.18993 -6.77569 20.9723 -23.9875 42.7395 -24.2308L142.01 -25.3406C170.588 -25.66 188.207 1.80003 170.532 24.2584C157.978 40.2096 141.407 57.9469 120.275 75.3347C88.3217 101.626 55.6349 111.256 30.9612 113.469C11.6947 115.197 -1.39488 97.8712 -0.548194 78.5459Z"
          fill="url(#paint11_linear_1149_6082)"
        />
      </g>
      <path
        d="M83.9119 32.0485V51.3694L105.06 51.3069C106.66 51.3069 107.879 51.6921 108.838 53.035C108.873 53.0837 108.923 53.1205 108.96 53.1664C110.322 54.8531 112.046 55.5544 114.104 55.4138C114.557 55.3825 114.716 55.2005 114.709 54.7354L114.712 51.6884C114.727 51.1056 114.54 50.8694 113.978 50.8804L109.591 50.9154C109.591 50.9154 107.049 50.6442 105.849 49.2645L105.358 48.7406C104.179 47.4427 102.744 46.8122 101.062 46.8159C99.2724 46.8195 97.4826 46.8168 95.6927 46.8177C92.9786 46.8177 89.9307 46.8067 87.2166 46.8287V43.8396C91.1426 43.8534 95.3282 43.8497 99.2551 43.8488C99.9034 43.8488 100.455 44.0418 100.909 44.495C101.334 44.9178 101.783 45.3176 102.215 45.7349C103.332 46.814 104.688 47.4142 106.278 47.4345C108.876 47.4685 111.474 47.4391 114.072 47.4519C114.555 47.4538 114.719 47.2543 114.71 46.8223C114.694 45.9647 114.705 45.1062 114.703 44.2477C114.701 43.4848 114.691 43.4738 113.904 43.4738C110.228 43.4738 106.552 43.4719 102.875 43.4738C100.213 43.4756 100.527 43.6456 98.6588 41.8183C97.2796 40.469 95.66 39.7943 93.6595 39.8504C91.6215 39.9074 89.2565 39.8568 87.2156 39.8715V36.603H92.6343C93.2787 36.5928 93.7961 36.8392 94.2404 37.3043C94.7646 37.853 95.2657 38.4275 95.7995 38.9652C96.8026 39.9763 97.9808 40.6234 99.3696 40.6308C104.278 40.6565 109.185 40.6409 114.093 40.6537C114.543 40.6547 114.718 40.4699 114.71 39.9938C114.695 39.0195 114.704 38.0451 114.703 37.0708C114.703 36.1461 114.682 36.1222 113.836 36.1222C108.043 36.1222 102.249 36.1351 96.4564 36.1176C93.2758 36.1084 94.0125 36.4789 91.7562 34.0311C90.5222 32.691 88.9574 32.0503 87.2185 32.0503L83.9119 32.0485Z"
        fill="#888888"
      />
      <text
        x="98"
        y="160"
        fontSize="13"
        fontWeight="bold"
        textAnchor="middle"
        fill="white"
      >
        {label}
      </text>
      <text
        x="98"
        y="105"
        fontSize={xp > 1e5 ? 32 : 36}
        fontWeight="bold"
        textAnchor="middle"
        fill="#404040"
      >
        <AnimateCounter start={0} end={xp} duration={700} />
      </text>
      <path
        d="M87.7982 131.351H90.6634L93.4319 126.904H93.5198L96.306 131.351H99.3294L95.2249 125.014V124.979L99.3821 118.669H96.4202L93.7396 123.239H93.6429L90.9446 118.669H87.8245L91.8323 124.935V124.962L87.7982 131.351ZM100.815 131.351H103.469V127.352H105.912C108.584 127.352 110.368 125.63 110.368 123.019V123.002C110.368 120.391 108.584 118.669 105.912 118.669H100.815V131.351ZM105.262 120.769C106.783 120.769 107.679 121.569 107.679 123.01V123.028C107.679 124.469 106.783 125.278 105.262 125.278H103.469V120.769H105.262Z"
        fill="#404040"
      />
      <defs>
        <filter
          id="filter0_i_1149_6082"
          x="0"
          y="143"
          width="210"
          height="145"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="15" dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1149_6082"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1149_6082"
          x1="62.5542"
          y1="157.056"
          x2="61.8811"
          y2="190.653"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.38" stopColor="#BFBFBF" />
          <stop offset="1" stopColor="#8D8D8D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1149_6082"
          x1="134.223"
          y1="155.407"
          x2="134.893"
          y2="189.005"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.38" stopColor="#9A9A9A" />
          <stop offset="1" stopColor="#D1D1D1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1149_6082"
          x1="82.1391"
          y1="168.974"
          x2="82.1391"
          y2="205.221"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.38" stopColor="#999999" />
          <stop offset="1" stopColor="#8E8E8E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1149_6082"
          x1="116.963"
          y1="169.011"
          x2="116.963"
          y2="205.261"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.38" stopColor="#AAAAAA" />
          <stop offset="1" stopColor="#848484" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1149_6082"
          x1="99.5863"
          y1="167.587"
          x2="99.5863"
          y2="218.231"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stopColor="#969696" />
          <stop offset="0.64" stopColor="#CECECE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1149_6082"
          x1="98.703"
          y1="155.937"
          x2="98.703"
          y2="190.907"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.38" stopColor="#8F8F8F" />
          <stop offset="1" stopColor="#B3B3B3" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1149_6082"
          x1="195.706"
          y1="90.1908"
          x2="117.182"
          y2="90.1908"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C1C1C1" />
          <stop offset="0.45" stopColor="#9D9D9D" />
          <stop offset="0.82" stopColor="#8C8C8C" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1149_6082"
          x1="190.359"
          y1="79.7601"
          x2="113.038"
          y2="90.4102"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8C8C8C" />
          <stop offset="0.45" stopColor="#A2A2A2" />
          <stop offset="0.82" stopColor="#969696" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1149_6082"
          x1="1.35055"
          y1="90.1908"
          x2="79.8749"
          y2="90.1908"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C1C1C1" />
          <stop offset="0.45" stopColor="#9D9D9D" />
          <stop offset="0.82" stopColor="#8C8C8C" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1149_6082"
          x1="6.69812"
          y1="79.7601"
          x2="84.0189"
          y2="90.4102"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8C8C8C" />
          <stop offset="0.45" stopColor="#A2A2A2" />
          <stop offset="0.82" stopColor="#969696" />
        </linearGradient>
        <radialGradient
          id="paint10_radial_1149_6082"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(99 93) rotate(126.455) scale(138.004 121.732)"
        >
          <stop stopColor="#F4F4F4" />
          <stop offset="1" stopColor="#656565" />
        </radialGradient>
        <linearGradient
          id="paint11_linear_1149_6082"
          x1="126.804"
          y1="156.292"
          x2="100.076"
          y2="18.9058"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF8F8" stopOpacity="0.46" />
          <stop offset="1" stopColor="#FFEBEB" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const SilverBadgeLarge = memo(SilverBadgeLargeComponent)

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  IonButtons,
  IonIcon,
  IonItem,
  IonList,
  IonSpinner,
  IonTitle,
  IonToggle,
  useIonToast,
} from '@ionic/react'
import cx from 'classnames'
import { closeOutline } from 'ionicons/icons'
import debounce from 'lodash/debounce'
import pick from 'lodash/fp/pick'
import { useResizeObserver } from 'usehooks-ts'

import { Avatar } from '@/components/atoms/Avatar'
import { BackButton } from '@/components/atoms/BackButton'
import { Button } from '@/components/atoms/Button'
import { CharacterCount } from '@/components/atoms/CharacterCount'
import { Title } from '@/components/atoms/Title'
import { UserName } from '@/components/atoms/UserName'
import { AddImageButton } from '@/components/molecules/AddImageButton'
import { MentionsInput } from '@/components/molecules/MentionsInput'
import { MentionsSuggestionsBox } from '@/components/molecules/MentionsSuggestionsBox'
import { Popover } from '@/components/molecules/Popover'
import { VideoThumbnailSelector } from '@/components/organisms/VideoThumbnailSelector'
import { Main } from '@/components/templates/main'

import { appConfig } from '@/services/config'
import { SubscriptionData } from '@/services/subscription'
import { useSubscription } from '@/lib/hooks/subscription'
import { useMentions } from '@/lib/hooks/useMentions'
import { usePost } from '@/lib/hooks/usePost'
import { useShowErrorToast } from '@/lib/hooks/useShowErrorToast'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useVisualViewport } from '@/lib/hooks/useVisualViewport'
import { queryClient } from '@/lib/queryClient'
import { Link, useLocation, useNavigate } from '@/lib/routing'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { Routes } from '@/router/routes'
import {
  validateMarkdownMaxLength,
  validatePreventBlankString,
} from '@/utils/formValidators'
import { getCachedMedia } from '@/utils/getCachedMedia'
import { getUserIdsFromMentions, isIosWebView } from '@/utils/utils'

import { QueryKeys } from '@/enums'

import { UgcComposerWebResult } from '@/plugins/ugc-composer'

type VideoMediaDescriptor = {
  type: 'video'
  url: string
}

type ImageMediaDescriptor = {
  type: 'image'
  url: string
}

type MediaDescriptor = VideoMediaDescriptor | ImageMediaDescriptor

export const PostComposer = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [mediaDescriptors, setMediaDescriptors] = useState<MediaDescriptor[]>(
    []
  )
  const [editedImgPreview, setEditedImgPreview] = useState('')
  const inputRef = useRef<HTMLDivElement>()
  const [isDimensionsPopoverOpen, setIsDimensionsPopoverOpen] = useState(false)
  const location = useLocation()
  const state = location?.state as UgcComposerWebResult

  const { t } = useTranslation('common')
  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      post: '',
      isSubscriptionContent: false,
    },
  })

  const isSubscriptionContent = watch('isSubscriptionContent')

  const { data: user } = useUserInfo()
  const [presentToast] = useIonToast()
  const { showErrorToast } = useShowErrorToast()
  const subscriptionData = queryClient.getQueryData<SubscriptionData>([
    QueryKeys.Subscription,
    user?.id,
  ])
  const { subscription } = useSubscription()

  const isSubscriptionSwitchShown = useMemo(() => {
    return subscriptionData?.price || subscription?.price
  }, [subscriptionData, subscription])

  const onFileUploadComplete = useCallback(() => {
    presentToast({
      header: t('UGC.post.toastHeader'),
      message: t('UGC.post.toastMessage'),
      position: 'top',
      duration: 3000,
    })
  }, [presentToast, t])

  const { uploadMediaFiles, onCreatePost } = usePost({
    onFileUploadComplete,
  })

  const {
    mentionsContainerRef,
    fetchMentions,
    mentions,
    isLoadingMentions,
    mentionsCount,
    setMentionsCount,
    onBlur,
  } = useMentions()
  const shouldHaveBorder = mentions.length || isLoadingMentions

  useEffect(() => {
    if (window.location.search.includes('shared-media')) {
      const asyncWrapper = async () => {
        const cachedMedia = await getCachedMedia()
        if (!cachedMedia) return

        cachedMedia.forEach((media) => {
          onFileChange({
            contentType: 'post',
            media,
          } as UgcComposerWebResult)
        })
      }

      asyncWrapper()
    }
  }, [])

  useEffect(() => {
    const subscription = watch(
      debounce(
        (value) =>
          setMentionsCount(getUserIdsFromMentions(value.comment).length),
        500
      )
    )
    return () => subscription.unsubscribe()
  }, [watch, setMentionsCount])

  const postValue = watch('post')
  const MAX_POST_LENGTH = 2_200

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollTo({ left: 9999 })
    }
  }, [mediaDescriptors.length])

  const onSubmit = async ({ post, isSubscriptionContent }) => {
    setIsLoading(true)
    onCreatePost(
      {
        text: post,
        media: mediaDescriptors.map(pick(['type'])),
        isSubscriptionContent,
      },
      {
        onSuccess: (postResponse) => {
          uploadMediaFiles(postResponse.id, mediaDescriptors, post)
          if (postResponse?.id) {
            Tracking.triggerEvent(TrackingEvent.AddPostCompleted, {
              postId: postResponse?.id,
            })
          }

          navigate(Routes.FEED, { replace: true })
        },
        onError: (err: Error) => {
          if (JSON.stringify(err).includes('CAPACITY_LIMIT_REACHED')) {
            showErrorToast({
              message: t('UGC.post.errors.capacityLimitReached'),
            })
          } else {
            showErrorToast({
              message: t('errors.default'),
            })
          }
        },
      }
    )
  }

  const onFileChange = async ({ media }: UgcComposerWebResult) => {
    if (
      media?.ratio < appConfig.aspectRatioLimit.min ||
      media?.ratio > appConfig.aspectRatioLimit.max
    ) {
      setIsDimensionsPopoverOpen(true)
      return
    }

    setMediaDescriptors((items) => [
      ...(media?.isExternalMedia ? [] : items),
      {
        type: media.mediaType,
        url: media.url,
      },
    ])
  }

  const deleteImage = (e, fileUrl: string) => {
    e.preventDefault()
    setMediaDescriptors([
      ...mediaDescriptors.filter((image) => image.url !== fileUrl),
    ])
  }

  const [visualViewportHeight, setVisualViewportHeight] = useState(0)
  const {
    visualViewport: { height },
  } = useVisualViewport()
  const squareRef = useRef<HTMLDivElement>(null)
  const { height: commentHeight } = useResizeObserver({
    ref: squareRef,
  })

  const viewportStyles = isIosWebView()
    ? {
        height: visualViewportHeight ? `${visualViewportHeight}px` : '100%',
      }
    : null

  const isFitsInViewport = visualViewportHeight >= commentHeight

  useEffect(() => {
    if (isIosWebView()) {
      setVisualViewportHeight(height)
    }
  }, [height])

  const header = (
    <div className="flex justify-between items-center">
      <IonButtons slot="secondary">
        <BackButton defaultHref={Routes.FEED} />
      </IonButtons>
      <IonTitle>
        <Title level={3}>{t('UGC.post.title')}</Title>
      </IonTitle>
      <Button
        size="small"
        className="min-w-[5.5rem]"
        isDisabled={mediaDescriptors.length === 0 || !isValid || isLoading}
        onClick={handleSubmit(onSubmit)}
      >
        {isLoading ? <IonSpinner color="light" /> : t('common.post')}
      </Button>
    </div>
  )

  useEffect(() => {
    if (state?.media.url) {
      onFileChange(state)
    }
    if (state?.media?.caption) {
      setValue('post', state?.media?.caption)
      trigger('post')
    }
  }, [state?.media?.caption, state?.media.url, setValue, trigger, state])

  return (
    <Main
      header={header}
      style={viewportStyles}
      headerClassName="touch-none"
      contentClassName={`h-full overflow-auto flex ${
        isFitsInViewport && isIosWebView() ? 'touch-none' : 'touch-auto'
      }`}
      isPaddingEnabled={false}
    >
      <div ref={squareRef}>
        <Popover
          type="danger"
          isOpen={isDimensionsPopoverOpen}
          onDismiss={() => {
            setIsDimensionsPopoverOpen(false)
          }}
          confirmText={t('ok')}
        >
          {t('common.error.dimensions')}
        </Popover>
        <IonList className="grow-1 composer">
          <IonItem>
            <span className="pr-2">
              <Avatar
                username={user?.username}
                userId={user?.id}
                isModerator={user?.isModerator}
                isAmbassador={user?.isAmbassador}
                badge={user?.badge}
              />
            </span>
            <Link to={`/@${user?.username}`}>
              <UserName className="text-sm" name={user?.username} />
            </Link>
          </IonItem>
          <IonItem>
            <div className="w-full ml-[3rem]">
              <Controller
                control={control}
                name="post"
                rules={{
                  required: true,
                  validate: {
                    pattern: validatePreventBlankString(),
                    maxLength: validateMarkdownMaxLength(MAX_POST_LENGTH),
                  },
                }}
                render={({ field }) => (
                  <MentionsInput
                    field={field}
                    mentionsContainerRef={mentionsContainerRef}
                    fetchMentions={fetchMentions}
                    mentionsCount={mentionsCount}
                    onBlur={onBlur}
                    placeholder={t('UGC.post.form.description')}
                  />
                )}
              />
              <CharacterCount
                currentInput={postValue}
                maxLength={MAX_POST_LENGTH}
              />
            </div>
          </IonItem>
          <MentionsSuggestionsBox
            className={shouldHaveBorder && `border-y-2 border-y-cool-gray-700`}
            suggestionsPortalHostRef={mentionsContainerRef}
            isLoadingMentions={isLoadingMentions}
          />
          <IonItem>
            {!editedImgPreview ? (
              <div
                ref={inputRef}
                className="pl-[3rem] pt-5 mr-2.5 overflow-x-scroll grid gap-4 grid-flow-col auto-cols-[minmax(8.75rem,_10.875rem)]"
              >
                {mediaDescriptors.map((media) => (
                  <div key={media.url} className="relative">
                    {media.type === 'image' ? (
                      <img src={media.url} />
                    ) : (
                      <div className="flex-1 relative -z-10">
                        <VideoThumbnailSelector
                          videoUrl={media.url}
                          muted
                          thumbnailUrl={null}
                        />
                      </div>
                    )}
                    {!state?.media?.isExternalMedia && (
                      <button
                        onClick={(e) => deleteImage(e, media.url)}
                        className="z-20 -top-2 -right-2 bg-white rounded-full w-5 h-5 text-black flex justify-center items-center float-right absolute"
                      >
                        <IonIcon icon={closeOutline} className="w-4 h-4" />
                      </button>
                    )}
                  </div>
                ))}
                {mediaDescriptors.length < 3 &&
                  !state?.media?.isExternalMedia && (
                    <AddImageButton
                      onFileChange={onFileChange}
                      loadedImgCount={mediaDescriptors.length}
                      isLoading={false}
                      contentType="post"
                      title={t('UGC.post.title')}
                    />
                  )}
              </div>
            ) : (
              <div className="pl-[3rem] pt-5 relative">
                <button
                  onClick={() => setEditedImgPreview(null)}
                  className="z-1 top-3 -right-2 bg-white rounded-full w-5 h-5 text-black flex justify-center items-center float-right absolute"
                >
                  <IonIcon icon={closeOutline} className="w-4 h-4" />
                </button>
                <img width="174" src={editedImgPreview} />
              </div>
            )}
          </IonItem>
          {isSubscriptionSwitchShown && (
            <div className="flex flex-row items-center text-sm font-bold mt-4 ml-[4rem]">
              <span
                className={cx({ 'text-cool-gray-600': isSubscriptionContent })}
              >
                {t('UGC.post.form.free')}
              </span>
              <Controller
                name="isSubscriptionContent"
                control={control}
                render={({ field }) => (
                  <IonToggle
                    className="chat-paywall-toggle mx-2 max-w-[4rem]"
                    checked={Boolean(field.value)}
                    mode="ios"
                    onIonChange={(value) =>
                      field.onChange(value.detail.checked)
                    }
                  />
                )}
              />
              <span
                className={cx({ 'text-cool-gray-600': !isSubscriptionContent })}
              >
                {t('UGC.post.form.paid')}
              </span>
            </div>
          )}
        </IonList>
      </div>
    </Main>
  )
}

import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { IonItem, IonLabel } from '@ionic/react'

import { Loading } from '@/components/atoms/Loading'
import { GroupedVirtualList } from '@/components/organisms/GroupedVirtualList'
import { TimelineError } from '@/components/organisms/TimelineError'

import { ITransactionItem } from '@/services/wallet/getTransactions'
import { useTokenDetails } from '@/lib/hooks/useTokenDetails'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useTransactions } from '@/lib/hooks/wallet'
import { formatDate } from '@/utils/date'

import { AirdropBanner } from './AirdropBanner'
import { HistoryListItem } from './HistoryListItem'
import { TransactionHistoryHeader } from './TransactionHistoryHeader'

interface TransactionHistoryProps {
  setIsRefetchActive: Dispatch<SetStateAction<boolean>>
  transactions?: ITransactionItem[]
  balance?: string
  accumulativeRevenueShareBalance?: string
  stakedBalance?: string
  isGetTransactionsLoading: boolean
  isGetTransactionsFetching: boolean
}

export const TransactionHistory: FC<TransactionHistoryProps> = ({
  setIsRefetchActive,
  transactions,
  balance,
  accumulativeRevenueShareBalance,
  stakedBalance,
  isGetTransactionsLoading,
  isGetTransactionsFetching,
}) => {
  const { data: authUser } = useUserInfo()
  const {
    hasGetTransactionsNextPage,
    fetchTransactionsNextPage,
    hasTransactionsError,
  } = useTransactions({ userInfo: authUser })
  const { data: tokenDetails, isLoading: isLoadingTokenDetails } =
    useTokenDetails(authUser?.creatorTokenId)

  const [endReachedOffset, setEndReachedOffset] = useState(0)
  const { t } = useTranslation('common')
  const groupedHistory = useMemo(
    () => groupByDate(transactions),
    [transactions]
  )

  const onEndReached = useCallback((e) => {
    return setTimeout(() => {
      setEndReachedOffset(e)
    }, 200)
  }, [])

  useEffect(() => {
    if (hasGetTransactionsNextPage && endReachedOffset !== 0)
      fetchTransactionsNextPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endReachedOffset, hasGetTransactionsNextPage])

  const { items, groups, groupCounts } = groupedHistory

  const GroupedVirtualListHeader = useCallback(() => {
    return (
      <>
        <AirdropBanner />
        <div className="px-4 text-lg font-bold">{t('wallet.history')}</div>
      </>
    )
  }, [t])

  if (
    isGetTransactionsLoading ||
    isLoadingTokenDetails ||
    (isGetTransactionsFetching && !items.length)
  )
    return <Loading />

  return (
    <div className="flex flex-col flex-1">
      <TransactionHistoryHeader
        stakedBalance={stakedBalance}
        balance={balance}
        totalBoost={tokenDetails?.totalBoost}
        accumulativeRevenueShareBalance={accumulativeRevenueShareBalance}
      />
      {groupCounts.length ? (
        <GroupedVirtualList
          components={{
            Header: GroupedVirtualListHeader,
          }}
          className="w-full"
          groupCounts={groupCounts}
          groupContent={(index) => {
            return (
              <span className="text-xs font-bold text-cool-gray-200 px-4 py-2 flex full-w bg-cool-gray-900">
                {groups[index]}
              </span>
            )
          }}
          itemContent={(index) => {
            return (
              <div className="px-4 w-full flex flex-col">
                <HistoryListItem
                  key={items[index].createdAt}
                  item={items[index]}
                />
              </div>
            )
          }}
          atTopStateChange={(isAtTop: boolean) => {
            setIsRefetchActive(isAtTop)
            setEndReachedOffset(0)
          }}
          endReached={onEndReached}
        />
      ) : !groupCounts?.length && hasTransactionsError ? (
        <TimelineError translation={'timeline.errors.transaction'} />
      ) : (
        <>
          <AirdropBanner />
          {authUser?.completedFaceLivenessCheck && (
            <IonItem className="mb-5">
              <IonLabel className="ion-text-wrap">
                <div className="flex flex-col justify-center items-center">
                  <div>
                    <img
                      src="/coin.png"
                      srcSet="/coin@2x.png 2x"
                      className="py-6"
                    />
                  </div>
                  <div className="text-white text-[1rem] mb-2 w-[11rem] font-bold text-center">
                    {t('wallet.noTransactions')}
                  </div>
                </div>
              </IonLabel>
            </IonItem>
          )}
        </>
      )}
    </div>
  )
}

export interface TransactionHistoryGroup {
  items: ITransactionItem[]
  groups: string[]
  groupCounts: number[]
}

function groupByDate(
  transactions: ITransactionItem[]
): TransactionHistoryGroup {
  if (!transactions || transactions.length === 0) {
    return { items: [], groups: [], groupCounts: [] }
  }

  const groups: Record<string, { date: string; items: ITransactionItem[] }> = {}

  transactions.forEach((transaction) => {
    const date = formatDate(+new Date(transaction.createdAt))

    if (!groups[date]) {
      groups[date] = {
        date,
        items: [transaction],
      }
    } else {
      groups[date].items.push(transaction)
    }
  })

  const formattedDateString = (date = '') => date.split('/').reverse().join('-')

  const sortedGroups = Object.values(groups).sort(
    (a, b) =>
      new Date(formattedDateString(b.date)).valueOf() -
      new Date(formattedDateString(a.date)).valueOf()
  )

  const flattenedItems = sortedGroups.flatMap((item) => item.items)

  return {
    items: flattenedItems,
    groups: sortedGroups.map((item) => item.date),
    groupCounts: sortedGroups.map((item) => item.items.length),
  }
}

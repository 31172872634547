import { FC, memo } from 'react'
import cx from 'classnames'

type TiktokIconProps = {
  className?: string
  isActive?: boolean
  isBlack?: boolean
}

const TiktokIconComponent: FC<TiktokIconProps> = ({
  className = '',
  isActive = false,
  isBlack = false,
}) => {
  const fileName = isBlack
    ? 'TikTok-black'
    : isActive
    ? 'TikTok-color'
    : 'TikTok-white'

  return (
    <picture>
      <source
        srcSet={`/social_icons/${fileName}.png 28w, /social_icons/${fileName}@2x.png 56w, /social_icons/${fileName}@3x.png 84w`}
      />
      <img
        className={cx(`w-6 h-6 ${className}`)}
        src={`/social_icons/${fileName}.png`}
        alt={fileName}
      />
    </picture>
  )
}

export const TiktokIcon = memo(TiktokIconComponent)

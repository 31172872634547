import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'

import { useShowErrorToast } from '../useShowErrorToast'

export const useHandleErrors = ({ error, setError, register, trigger }) => {
  const { t } = useTranslation('common')
  const [minRule, setMinRule] = useState<number>()
  const [maxRule, setMaxRule] = useState<number>()
  const [presentToast] = useIonToast()
  const { showErrorToast } = useShowErrorToast()

  useEffect(() => {
    if (error) {
      const data = error?.response.data

      if (data === 'PRICE_TOO_LOW') {
        setMinRule(error?.response.metadata.min)
        register('price', { min: error?.response.metadata.min })
      } else if (data === 'PRICE_TOO_HIGH') {
        setMaxRule(error?.response.metadata.max)
        register('price', { max: error?.response.metadata.max })
      } else {
        showErrorToast({
          header: t('configureSubscription.errors.updateSubscription'),
          message: t('configureSubscription.errors.contactSupport'),
        })
      }
    }

    trigger('price')
  }, [error, setError, t, presentToast, showErrorToast, trigger, register])

  return { minRule, maxRule }
}

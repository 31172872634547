import { fetchAuthSession } from 'aws-amplify/auth'

import { post } from '../awsAmplify'

export interface IWithdrawEstimation {
  amount: string
  createdAt: string
  estimationId: UUID
  errorMessage: WithdrawErrorMessage
  executable: boolean
  expiresInSeconds: number
  externalWalletId: string
  minAmount: string
  maxAmount: string
  maxDailyAmount: string
  maxDailyCount: number
  networkFee: string
  newBalance: string
  platformFee: string
  totalAmount: string
  totalFee: string
  walletId: UUID
}

export enum WithdrawErrorMessage {
  // TransactionErrorCode
  INVALID_TRANSACTION_TYPE = 'invalidTransactionType',
  SAME_SOURCE_DESTINATION = 'sameSourceDestination',
  NON_POSITIVE_AMOUNT = 'nonPositiveAmount',
  SOURCE_USER_NOT_FOUND = 'sourceUserNotFound',
  SOURCE_USER_DEACTIVATED = 'sourceUserDeactivated',
  DESTINATION_USER_NOT_FOUND = 'destinationUserNotFound',
  DESTINATION_USER_DEACTIVATED = 'destinationUserDeactivated',
  SOURCE_WALLET_NOT_FOUND = 'sourceWalletNotFound',
  SOURCE_WALLET_INSUFFICIENT_FOUNDS = 'sourceWalletInsufficientFounds',
  INVALID_MIN_AMOUNT = 'invalidMinAmount',
  INVALID_MAX_AMOUNT = 'invalidMaxAmount',
  DAILY_LIMIT_MAX_AMOUNT_REACHED = 'dailyLimitMaxAmountReached',
  DAILY_LIMIT_MAX_COUNT_REACHED = 'dailyLimitMaxCountReached',
}

export enum WithdrawNetworkError {
  // WithdrawErrorCode
  ESTIMATION_NOT_FOUND = 'estimationNotFound',
  ESTIMATION_EXPIRED = 'estimationExpired',
  INVALID_EXTERNAL_ETH_ADDRESS = 'invalidExternalEthAddress',
  MFA_CODE_VALIDATION_FAILED = 'mfaCodeValidationFailed',
  GLOBALLY_DEACTIVATED = 'globallyDeactivated',
  // WalletErrorCode
  WALLET_NOT_FOUND = 'walletNotFound',
  USER_NOT_FOUND = 'userNotFound',
  USER_DEACTIVATED = 'userDeactivated',
  USER_NOT_KYC_VERIFIED = 'userNotKYCVerified',
  USER_NOT_ALLOWED_TO_WITHDRAW = 'userNotAllowedToWithdraw',
}

export const estimateWithdraw = async ({
  externalWalletId,
  amount,
  selectedNetwork,
}: {
  externalWalletId: string
  amount: number
  selectedNetwork: string
}): Promise<IWithdrawEstimation> => {
  return (await post({
    path: '/fireblocks/withdraw-estimate',
    params: {
      amount: amount.toString(),
      externalWalletId,
      network: selectedNetwork,
    },
  })) as unknown as IWithdrawEstimation
}

export const executeWithdraw = async ({
  estimationId,
  authenticatorCode,
}: {
  estimationId: string
  authenticatorCode: string
}): Promise<void> => {
  const { accessToken } = (await fetchAuthSession()).tokens ?? {}

  return (await post({
    path: '/fireblocks/withdraw-execute',
    params: {
      estimationId,
      code: authenticatorCode,
      accessToken: accessToken?.toString(),
    },
  })) as unknown as Promise<void>
}

import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonIcon, IonTitle } from '@ionic/react'
import { useQuery } from '@tanstack/react-query'

import { BackButton } from '@/components/atoms/BackButton'
import { Title } from '@/components/atoms/Title'
import { Entry } from '@/components/templates/entry'
import { BalanceComponent } from './components/BalanceComponent'
import { LaunchButton } from './components/LaunchButton'
import { TextComponent } from './components/TextComponent'

import { useCreatorTokenConfig } from '@/lib/hooks/useLaunchToken'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useWallet } from '@/lib/hooks/wallet'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { DECIMALS } from '@/utils/token'

import { QueryKeys } from '@/enums'

export const TokenLaunch: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { data } = useCreatorTokenConfig()

  // eslint-disable-next-line republik/no-rpk-variable
  const amountToBurn = data?.tokenIssueFeeAmountInRPK

  const { data: userInfo } = useUserInfo()
  const { wallet, refetchWallet } = useWallet({
    userInfo,
  })

  const { data: isLaunchTokenSuccess } = useQuery({
    queryKey: [QueryKeys.LaunchTokenSuccess],
  })

  useEffect(() => {
    if (isLaunchTokenSuccess || userInfo?.creatorTokenId != null) {
      navigate(Routes.FEED)
    }
  }, [userInfo?.creatorTokenId]) // eslint-disable-line react-hooks/exhaustive-deps

  const bigintBalance = BigInt(wallet?.balance ?? '0')
  const walletRawBalance = Number((bigintBalance * 100n) / DECIMALS) * 0.01

  const header = (
    <>
      <IonButtons slot="start">
        <BackButton className="text-white" />
      </IonButtons>
      <IonTitle className="px-0">
        <Title level={2}>{t('common.head.title.tokenLaunch')}</Title>
      </IonTitle>
      <IonButtons slot="end">
        <IonIcon slot="icon-only" />
      </IonButtons>
    </>
  )

  const bgStyles = 'absolute -z-10 w-full h-1/2 bg-center bg-cover bg-no-repeat'

  return (
    <Entry
      headTitle={t('common.head.title.tokenLaunch')}
      header={header}
      className="h-full"
      refetch={() => refetchWallet()}
      contentClassName={`bg-[url('/trading_app/background.png')] md:bg-[url('/trading_app/desktop-background.png')] ${bgStyles}`}
    >
      <BalanceComponent balance={wallet?.balance ?? '0'} />
      <TextComponent amountToBurn={amountToBurn} />
      <LaunchButton isDisabled={walletRawBalance < amountToBurn} />
    </Entry>
  )
}

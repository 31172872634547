import ReactMarkdown from 'react-markdown'
import { ReactMarkdownProps } from 'react-markdown/lib/ast-to-react'

import { Avatar } from '@components/atoms/Avatar'
import { UserName } from '@/components/atoms/UserName'
import { IActivityItem } from '@/components/pages/Activity/interfaces'

import { Link } from '@/lib/routing'
import { parseLink, safeCastUnknownToType } from '@/utils/utils'
import { formatDistanceToNowDate } from 'utils/date'

import { Badge } from '@/enums'

interface CommentsListParticipantProps {
  activity?: IActivityItem
  className?: string
}

export const CommentsListParticipantItem = ({
  activity,
  className = '',
}: CommentsListParticipantProps) => {
  const comment = activity?.description?.trim()

  const user = {
    id: activity?.actor.id,
    data: {
      name: safeCastUnknownToType<string>(activity?.actor.data.name),
      profileImage: safeCastUnknownToType<string>(
        activity?.actor.data.profileImage
      ),
      isModerator: safeCastUnknownToType<boolean>(
        activity?.actor?.data?.isModerator
      ),
      isAmbassador: safeCastUnknownToType<boolean>(
        activity?.actor?.data?.isAmbassador
      ),
      badge: safeCastUnknownToType<Badge>(activity?.actor?.data?.badge),
    },
  }

  const renderers = {
    a: (props: ReactMarkdownProps) => (
      <Link
        to={parseLink(
          safeCastUnknownToType<string>(`/${props?.children?.[0]}`)
        )}
        className="relative text-primary"
      >
        {props?.children[0]}
      </Link>
    ),
  }

  return (
    <article className={`pl-4 mb-4 flex align-top ${className}`}>
      <Avatar
        className="mr-3 my-1"
        userId={user?.id}
        isModerator={user?.data?.isModerator}
        isAmbassador={user?.data?.isAmbassador}
        badge={user?.data?.badge}
        clickable
        username={user?.data?.name}
      />
      <div className={`w-full flex ${!!comment && 'flex-col'}`}>
        <header className="-mt-1 flex justify-between items-center">
          <div className="flex items-center">
            <UserName
              className="mr-2 text-sm font-bold"
              name={user?.data?.name}
              userId={user?.id}
            />
            <span className="text-xs opacity-50">
              {formatDistanceToNowDate({
                timestamp: String(activity?.time),
                addSuffix: false,
                useUTC: false,
              })}
            </span>
          </div>
        </header>
        <ReactMarkdown
          className="whitespace-pre-line pr-4 break-words"
          components={renderers}
        >
          {comment}
        </ReactMarkdown>
      </div>
    </article>
  )
}

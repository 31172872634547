import { useCallback, useEffect, useState } from 'react'

import { appConfig } from '@services/config'
import { useToken } from '@services/token'
import { connect, CustomStreamClient } from '@/services/customStreamClient'

export const useGetstreamClient = () => {
  const [client, setClient] = useState<CustomStreamClient | null>(null)
  const { data: tokens } = useToken()
  const streamClient = useCallback(() => {
    const apiKey = appConfig.getstreamApiKey
    const appId = appConfig.getstreamAppId

    const client = connect(apiKey, tokens?.getStreamToken, appId, {
      location: 'singapore',
    })
    setClient(client)
  }, [tokens?.getStreamToken])

  useEffect(() => {
    if (tokens?.getStreamToken) streamClient()
  }, [tokens?.getStreamToken, streamClient])

  return {
    client,
  }
}

import { queryClient } from '@/lib/queryClient'

import { QueryKeys } from '@/enums'

import { useUserInfo } from '../useUserInfo'

export const useInvalidateQueries = () => {
  const { data: authUser } = useUserInfo()
  const queries = [
    [QueryKeys.TimelineFeed],
    [QueryKeys.UserFeed, authUser?.id],
    [QueryKeys.CategoryFeed],
  ]

  const invalidateQueries = () =>
    queries.forEach((queryKey) =>
      queryClient.invalidateQueries({
        queryKey,
        exact: true,
        refetchType: 'all',
      })
    )

  const invalidateQueriesTimeout = () => {
    setTimeout(() => {
      invalidateQueries()
    }, 2000)
  }

  return {
    invalidateQueries,
    invalidateQueriesTimeout,
  }
}

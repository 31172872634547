export interface ILinkParams {
  analytics: {
    feature?: string
    [key: string]: unknown
  }
  properties: {
    $deeplink_path: string
    refUsername?: string
  }
}

export async function generateDeepLink(params: ILinkParams) {
  return await new Promise<string>((resolve) => {
    return resolve(params?.properties?.$deeplink_path)
  })
}

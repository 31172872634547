import {
  addHours,
  format,
  formatDistance as formatDistanceFNS,
  formatDistanceToNowStrict,
  formatRelative,
  isBefore,
  isToday,
  isValid,
} from 'date-fns'
import { enIN, ru, vi } from 'date-fns/locale'
import { zonedTimeToUtc } from 'date-fns-tz'
import padStart from 'lodash/padStart'

import { getLanguage } from '@/utils/getLanguage'

import { DateFormat } from '@/enums'

const localeMapper = {
  en: enIN,
  vi: vi,
  ru: ru,
}

export const formatDate = (
  date: number | Date,
  dateFormat = DateFormat.LocalizedDate
) => {
  if (isNaN(Number(date))) return ''

  return format(date, dateFormat, {
    locale: localeMapper[getLanguage()] || enIN,
  })
}

export const hoursToMilliseconds = (hour: number | string) =>
  Math.floor(Number(hour) * 60 * 60 * 1000)

interface IFormatDistanceToNowDate {
  timestamp: number | string
  isBeforeCheck?: boolean
  addSuffix?: boolean
  useUTC?: boolean
}

export const formatDistanceToNowDate = ({
  timestamp,
  isBeforeCheck = false,
  addSuffix = true,
  useUTC = true,
}: IFormatDistanceToNowDate): string | null => {
  if (
    !isValid(new Date(timestamp)) ||
    (isBeforeCheck && isBefore(new Date(timestamp), new Date()))
  )
    return null

  return formatDistanceToNowStrict(
    useUTC ? zonedTimeToUtc(new Date(timestamp), 'UTC') : new Date(timestamp),
    {
      addSuffix,
      locale: localeMapper[getLanguage()] || enIN,
    }
  )
}

export const formatRemainingTime = (timestamp: number | string) => {
  if (!timestamp) return ''
  const add = addHours(new Date(timestamp), 24)

  return formatDistanceFNS(
    zonedTimeToUtc(add, 'UTC'),
    zonedTimeToUtc(new Date(), 'UTC'),
    {
      addSuffix: true,
      locale: localeMapper[getLanguage()] || enIN,
    }
  )
}

export const formatDistance = (date: string | number | Date) => {
  return formatRelative(new Date(date), new Date(), {
    locale: localeMapper[getLanguage()] || enIN,
  })
}

export const formatDayMonth = (date: string | number | Date) => {
  return format(zonedTimeToUtc(new Date(date), 'UTC'), 'LLL dd', {
    locale: localeMapper[getLanguage()] || enIN,
  })
}

export const isDateToday = (timestamp: number | Date): boolean =>
  isToday(new Date(timestamp))

export const formatGetstreamTime = (date: Date | number): string => {
  if (typeof date === 'number') {
    date = new Date(date)
  }

  const Y = date.getUTCFullYear()
  const M = padStart((date.getUTCMonth() + 1).toString(), 2, '0') // because it returns 0-11
  const D = padStart(date.getUTCDate().toString(), 2, '0')
  const h = padStart(date.getUTCHours().toString(), 2, '0')
  const m = padStart(date.getUTCMinutes().toString(), 2, '0')
  const s = padStart(date.getUTCSeconds().toString(), 2, '0')
  const ms = padStart(date.getUTCMilliseconds().toString(), 3, '0')

  return `${Y}-${M}-${D}T${h}:${m}:${s}.${ms}000`
}

export const formatISOString = (date: string): string => {
  return date?.replace('T', ' ').replace('Z', '').split('.')[0]
}

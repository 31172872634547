import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IonIcon } from '@ionic/react'
import { checkmarkOutline } from 'ionicons/icons'

import { Button } from '@/components/atoms/Button'
import { Currency } from '@/components/atoms/Currency'

import { StakedRpkForXpMultiplierItem } from '@/services/account'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useWallet } from '@/lib/hooks/wallet'
import { useNavigate } from '@/lib/routing'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { Routes } from '@/router/routes'
import { tokenToString } from '@/utils/token'

interface XPMultiplierListProps {
  multipliers: StakedRpkForXpMultiplierItem[]
  onStakeButtonClicked?: () => void
}

export const XPMultiplierList: FC<XPMultiplierListProps> = ({
  multipliers,
  onStakeButtonClicked,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { data: userInfo } = useUserInfo()
  const { wallet } = useWallet({ userInfo })

  const currentlyStaked = useMemo(
    () => Number(tokenToString(wallet?.stakedBalance ?? '0')),
    [wallet?.stakedBalance]
  )

  if (!multipliers) return null

  return (
    <div className="w-full flex flex-col bg-cool-gray-800 rounded-lg px-3 md:px-6 py-5 text-white">
      <div className="flex flex-row gap-[0%] sm:gap-[10%] justify-between text-cool-gray-200 text-[3vw] xs:text-xs font-normal pb-3">
        <span className="flex flex-1">
          <span className="border-b border-cool-gray-600 pb-1 pr-1 text-left">
            {t('XPWallet.xpMultiplierList.xpRewardMultiplier')}
          </span>
        </span>
        <span className="flex flex-1">
          <span className="border-b border-cool-gray-600 pb-1 pr-1 text-left">
            {t('XPWallet.xpMultiplierList.staked')}
          </span>
        </span>
        <span className="flex flex-1" />
      </div>
      {multipliers?.map((multiplier) => (
        <div
          key={multiplier.xpMultiplier}
          className="flex flex-row gap-[0%] sm:gap-[10%] justify-between items-center mb-4"
        >
          <span className="flex flex-1 text-[6vw] xs:text-2xl font-bold">
            {Math.floor(multiplier.xpMultiplier / 100)}x
          </span>
          <span className="flex flex-1 text-[4vw] xs:text-sm font-bold">
            <Currency value={multiplier.staked} />
          </span>
          <span className="flex flex-1 justify-center items-center">
            {currentlyStaked >= multiplier.staked ? (
              <IonIcon
                icon={checkmarkOutline}
                className="text-2xl text-primary font-bold"
              />
            ) : (
              <Button
                className="text-white"
                type="primary"
                fill="outline"
                onClick={() => {
                  onStakeButtonClicked?.()
                  Tracking.triggerEvent(TrackingEvent.StakeNowCTAClicked, {
                    xpMultiplier: multiplier.xpMultiplier,
                  })
                  navigate(`${Routes.STAKE}?amount=${multiplier.staked}`)
                }}
              >
                {t('XPWallet.stakeNow')}
              </Button>
            )}
          </span>
        </div>
      ))}
    </div>
  )
}

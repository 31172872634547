import { FC, memo, MouseEventHandler, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { Avatar } from '@components/atoms/Avatar'
import { Diamond } from '@/components/atoms/assets/Diamond'
import { FollowButton } from '@/components/molecules/FollowButton'
import { MarkdownUrlHighlighter } from '@/components/molecules/MarkdownUrlHighlighter'
import { SocialsList } from '@/components/molecules/SocialsList'
import { SubscribeButton } from '@/components/molecules/SubscribeButton'
import { TopSupportersButton } from '@/components/molecules/TopSupportersButton/TopSupportersButton'

import { AccountInfo } from '@/services/account'
import { SubscriptionFeedError } from '@/services/subscription'
import { TopSupporter } from '@/services/top-supporters'
import { UserInfo } from '@/services/user'
import { IWallet } from '@/services/wallet/getWallet'
import { useChat } from '@/lib/hooks/chat/useChat'
import { useVisualViewport } from '@/lib/hooks/useVisualViewport'
import { useNavigate } from '@/lib/routing'
import { EventName, Tracking, TrackingEvent } from '@/lib/tracking'
import { Routes } from '@/router/routes'

import { FollowStatus } from '@/enums'

import {
  CurrencyButton,
  FollowersFollowingButtons,
  SendTipButton,
  XPButton,
} from '../'
import { ChatButton } from '../ChatButton'

type HeaderProps = {
  userId: UUID
  isAuthUser: boolean
  userInfo: UserInfo
  accountInfoData: AccountInfo
  wallet: IWallet
  topSupporters: readonly TopSupporter[]
  subscriptionFeedError: unknown | AxiosError | null
  onSubscribe: MouseEventHandler<HTMLButtonElement>
  isSubscriptionFeedFetching: boolean
  isSubscriptionFetchedAfterMount: boolean
}

const UserProfileHeaderComponent: FC<HeaderProps> = ({
  userId,
  isAuthUser,
  userInfo,
  accountInfoData,
  wallet,
  topSupporters,
  subscriptionFeedError,
  onSubscribe,
  isSubscriptionFeedFetching,
  isSubscriptionFetchedAfterMount,
}) => {
  const { t } = useTranslation('common')
  const {
    data: chatData,
    isError: isChatDataError,
    isLoading: isChatDataLoading,
  } = useChat({
    userId: userInfo?.id,
  })
  const navigate = useNavigate()
  const {
    visualViewport: { width: viewportWidth },
  } = useVisualViewport()

  const trackFollowStatus = (eventName: EventName) => {
    Tracking.triggerEvent(eventName, {
      profileUserId: userInfo?.id,
    })
  }

  const handleOnFollow = (followStatus: FollowStatus) => {
    // click event on 'follow' or on 'follow back' button of profile page of another user
    if (
      [
        followStatus === FollowStatus.Follow,
        followStatus === FollowStatus.FollowBack,
      ].includes(true)
    ) {
      trackFollowStatus(TrackingEvent.ProfileFollowed)
    }

    // click event on 'following' or on 'friends' button of profile page of another user
    if (
      [
        followStatus === FollowStatus.Following,
        followStatus === FollowStatus.Friends,
      ].includes(true)
    ) {
      trackFollowStatus(TrackingEvent.ProfileUnfollowed)
    }
  }

  const subscriptionPrice = useMemo(
    () =>
      (subscriptionFeedError as unknown as SubscriptionFeedError)?.response
        ?.metadata?.price,
    [subscriptionFeedError]
  )

  const hasSubscription = useMemo(
    () => !subscriptionFeedError,
    [subscriptionFeedError]
  )

  const creatorHasSubscription = useMemo(
    () => !!subscriptionPrice || hasSubscription,
    [subscriptionPrice, hasSubscription]
  )

  const topSupportersToDisplay = topSupporters?.slice(0, 3)?.reverse()
  const handleAvatarClick = useCallback(() => {
    if (isAuthUser) navigate(Routes.PROFILE_EDIT)
  }, [navigate, isAuthUser])

  if (!userInfo) return
  return (
    <div className="w-full max-w-[40rem] mx-auto bg-cool-gray-900">
      <div className="flex flex-col justify-between p-3 px-4">
        <div className="flex items-end pb-3">
          <div onClick={handleAvatarClick}>
            <Avatar
              username={userInfo?.username}
              userId={userInfo?.id}
              size={viewportWidth < 640 ? 'xl' : 'xxl'}
              isModerator={userInfo?.isModerator}
              isAmbassador={userInfo?.isAmbassador}
              badge={userInfo?.badge}
            />
          </div>
          <div className="flex flex-col items-start ml-4">
            <div className="ml-4">
              <FollowersFollowingButtons
                userId={userInfo?.id}
                followingCount={userInfo?.followingCount}
                followerCount={userInfo?.followerCount}
              />
            </div>
            <div className="flex justify-center bg-cool-gray-800 rounded-full py-2 pl-3 pr-2 mb-1 mt-3">
              <CurrencyButton
                value={wallet?.totalBalance ?? '0'}
                isViewingOwnProfile={isAuthUser}
              />
              <div className="ml-5 mr-4">
                <XPButton
                  badge={userInfo?.badge}
                  value={Math.floor(accountInfoData?.xp || 0)}
                  isViewingOwnProfile={isAuthUser}
                />
              </div>
            </div>
          </div>
        </div>

        {!!topSupportersToDisplay?.length && (
          <TopSupportersButton
            topSupporters={topSupportersToDisplay}
            userId={userId || userInfo?.id}
          />
        )}

        <div
          className={`flex items-center ${
            userInfo?.displayName ? 'place-content-between' : 'justify-end'
          }`}
        >
          {!!userInfo?.displayName && (
            <span className="text-lg sm:text-xl font-bold py-2">
              {userInfo?.displayName}
            </span>
          )}
          <SocialsList isAuthUser={isAuthUser} userId={userId} />
        </div>

        {!!userInfo?.bio && (
          <MarkdownUrlHighlighter
            className="text-sm break-words"
            text={userInfo?.bio}
          />
        )}
        {!isAuthUser && (
          <div className="flex flex-row justify-start items-center pt-6">
            {!userInfo?.deactivated ? (
              <>
                {creatorHasSubscription &&
                  !isSubscriptionFeedFetching &&
                  isSubscriptionFetchedAfterMount && (
                    <SubscribeButton
                      onClick={onSubscribe}
                      isDisabled={hasSubscription}
                      type="gradient"
                      className="mr-2 ml-0"
                    >
                      <Diamond className="w-4 h-4 mr-2" />
                      <p>
                        {hasSubscription
                          ? t('discover.subscribed')
                          : t('discover.subscribe')}
                      </p>
                    </SubscribeButton>
                  )}

                {!isSubscriptionFeedFetching &&
                  isSubscriptionFetchedAfterMount && (
                    <FollowButton
                      size="small"
                      userId={userId}
                      followStatus={userInfo?.followStatus}
                      username={userInfo?.username}
                      onFollow={handleOnFollow}
                      color={creatorHasSubscription ? 'primary' : ''}
                      fill={creatorHasSubscription ? 'outline' : 'clear'}
                    />
                  )}

                <SendTipButton className="ml-2" userId={userInfo?.id} />
                <div className="ml-2">
                  <ChatButton
                    userInfo={userInfo}
                    isLoading={isChatDataLoading}
                    chatData={chatData}
                    isError={isChatDataError}
                  />
                </div>
              </>
            ) : (
              <span className="text-danger">
                {t('profile.accountDeactivated')}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export const UserProfileHeader = memo(UserProfileHeaderComponent)

import { FC } from 'react'
import cx from 'classnames'

interface ProgressBarProps {
  progress: number
}

export const ProgressBar: FC<ProgressBarProps> = ({ progress = 0 }) => {
  return (
    <div className="h-[0.125rem] w-full bg-cool-gray-700 relative">
      {progress > 0 && (
        <div
          className={cx(
            'bg-cool-gray-300 h-[0.125rem] animate-immediateFadeIn',
            {
              'transition-[width] duration-[0.3s]': progress < 98,
            }
          )}
          style={{ width: `${progress < 98 ? progress.toFixed(0) : '100'}%` }}
        ></div>
      )}
    </div>
  )
}

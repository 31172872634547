import { lazy, Suspense, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'

import { App } from '@components/app'

import { persister, queryClient } from '@lib/queryClient'
import Store from '@lib/store'
import '@/lib/translation/i18n'

import reportWebVitals from './reportWebVitals'

import './wdyr'

const container = document.getElementById('root')
const root = createRoot(container)

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
)

const RenderRoot = () => {
  const [showDevtools, setShowDevtools] = useState(false)

  useEffect(() => {
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  return (
    <Store>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
        onSuccess={async () => {
          await queryClient.resumePausedMutations()
          queryClient.invalidateQueries()
        }}
      >
        <App />
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
        {showDevtools && (
          <Suspense fallback={null}>
            <ReactQueryDevtoolsProduction buttonPosition="bottom-left" />
          </Suspense>
        )}
      </PersistQueryClientProvider>
    </Store>
  )
}

root.render(<RenderRoot />)

reportWebVitals()

import { useTranslation } from 'react-i18next'
import { IonPage } from '@ionic/react'

import { Link } from '@lib/routing'
import { Routes } from '@/router/routes'

import { ErrorCodes } from '@enums'

export const NotFound = () => {
  const { t } = useTranslation('common')
  return (
    <IonPage>
      <div className="mx-auto text-center pt-[15vh] font-bold text-white">
        <img src="/404.svg" alt="404" className="mb-5" />
        <h1 className="text-7xl font-bold mb-6">{ErrorCodes.NotFound}</h1>
        <p className="text-sm mb-7">{t('pageNotFound.notFound')}</p>
        <div className="flex justify-center gap-1.5">
          <p>{t('pageNotFound.goTo')}</p>
          <Link to={Routes.FEED} className="text-primary">
            {t('pageNotFound.homeFeed')}
          </Link>
        </div>
      </div>
    </IonPage>
  )
}

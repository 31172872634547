import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { uniqueId } from 'lodash'

import { UserName } from '@components/atoms/UserName'
import { FollowButton } from '@components/molecules/FollowButton'
import { ListItem } from '@components/molecules/ListItem'

import { IUser } from '@services/findUsers'
import { appConfig } from '@/services/config'
import { UserInfo } from '@/services/user'
import { ChatStatus } from '@/lib/hooks/chat/useChat'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useWallet } from '@/lib/hooks/wallet'
import { queryClient } from '@/lib/queryClient'
import { Link, useNavigate } from '@/lib/routing'

import { QueryKeys } from '@/enums'

import { DepositCurrency, DepositCurrencyRef } from '../DepositCurrency'
import { UnlockChatModal } from '../UnlockChat'

export interface IUsersListItemProps {
  item: IUser
  linkToMessages?: boolean
}
export const UsersListItem: FC<IUsersListItemProps> = ({
  item,
  linkToMessages,
}) => {
  const { t } = useTranslation('common')
  const currency = appConfig.currency
  const depositCurrencyRef = useRef<DepositCurrencyRef>()
  const { data: authUser } = useUserInfo()
  const { isZeroBalance } = useWallet({
    userInfo: authUser,
  })
  const uuid = useMemo(() => uniqueId(), [])
  const [modalId, setModalId] = useState('')

  const chatData: { status: ChatStatus } = queryClient.getQueryData([
    QueryKeys.ChatStatus,
    item.id,
  ])

  useEffect(() => {
    if (
      !isZeroBalance &&
      chatData?.status !== 'UNLOCKED' &&
      item?.chat?.status === 'LOCKED'
    ) {
      setModalId(`send-chat-modal-${uuid}`)
    } else {
      setModalId('')
    }
  }, [chatData?.status, isZeroBalance, item?.chat?.status, uuid])

  const navigate = useNavigate()
  if (linkToMessages) {
    const onClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      if (isZeroBalance && item?.chat?.status === 'LOCKED') {
        depositCurrencyRef.current.openModal()
        return
      }

      if (chatData?.status !== 'UNLOCKED' && item?.chat?.status === 'LOCKED') {
        setModalId(`send-chat-modal-${uuid}`)
        return
      }
      navigate(`/messages/${item.id}`)
    }

    return (
      <>
        <ListItem key={item.id} item={item}>
          <div
            id={modalId}
            className="w-full h-full flex cursor-pointer justify-center"
            onClick={onClick}
          >
            <div className="w-full h-full flex flex-wrap items-center justify-between pr-2">
              <UserName
                className="block font-medium text-sm"
                name={item.username}
              />
              {chatData?.status === 'UNLOCKED' ? (
                <img src="/unlocked-icon-white.svg" alt="unlocked" />
              ) : item?.chat?.status === 'LOCKED' ? (
                <img src="/locked-icon-green.svg" alt="locked" />
              ) : (
                item?.chat?.status === 'UNLOCKED' && (
                  <img src="/unlocked-icon-white.svg" alt="unlocked" />
                )
              )}
            </div>
          </div>
        </ListItem>
        <DepositCurrency
          title={t('depositBottomSheet.topUpCurrencyToChat', {
            currency,
          })}
          ref={depositCurrencyRef}
        />
        <UnlockChatModal
          modalId={modalId}
          amount={item?.chat?.price}
          userInfo={
            {
              id: item.id,
              username: item.username,
            } as UserInfo
          }
        />
      </>
    )
  }

  return (
    <ListItem key={item.id} item={item}>
      <div className="w-full flex flex-wrap items-center justify-between pr-2">
        <Link className="max-w-[60%]" to={`/@${item.username}`}>
          <UserName
            className="block font-medium text-sm"
            name={item.username}
          />
          <span className="text-xs">{item.displayName}</span>
        </Link>
        <FollowButton
          userId={item.id}
          followStatus={item.followStatus}
          size="small"
          username={item.username}
        />
      </div>
    </ListItem>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/Button'
import { Title } from '@/components/atoms/Title'

interface IFailedProps {
  authUrl: string
}

export const Failed: FC<IFailedProps> = ({ authUrl }) => {
  const { t } = useTranslation('common')
  return (
    <div className="flex flex-col justify-center items-center">
      <img src="/cross-in-circle.svg" alt="Error icon" className="mx-auto" />
      <Title className="text-center my-7" level={1}>
        {t('registration.profile.tiktokVerificationFailed.message')}
      </Title>
      <Button
        className="w-28 my-7 mx-auto"
        onClick={() => window.location.assign(authUrl)}
      >
        {t('registration.profile.tiktokVerificationFailed.tryAgain')}
      </Button>
    </div>
  )
}

import { FC, memo, useMemo } from 'react'

import { CurrencyIcon } from '@components/atoms/assets/CurrencyIcon'

import { appConfig } from '@/services/config'
import { Link } from '@/lib/routing'
import { WALLET } from '@/utils/route'
import { tokenToString, toTokenCentsString } from '@/utils/token'
import { compactNumbers } from '@/utils/utils'

interface CurrencyButtonProps {
  value: string
  isViewingOwnProfile: boolean
  onClick?: () => void | Promise<void>
}

const CurrencyButtonComponent: FC<CurrencyButtonProps> = ({
  value,
  isViewingOwnProfile,
  onClick,
}) => {
  const compactedValue = useMemo(() => {
    const tokenValue = Number(tokenToString(value))

    return compactNumbers(Math.floor(tokenValue))
  }, [value])

  return (
    <Link
      className="flex items-center"
      to={isViewingOwnProfile ? WALLET : undefined}
      onClick={onClick}
    >
      <span className="flex flex-row text-white font-bold items-center">
        <CurrencyIcon className="h-5" />
        <span
          className="ml-1 text-sm sm:text-base text-ellipsis overflow-hidden max-w-[6.25rem]"
          title={toTokenCentsString(value)}
        >
          {compactedValue}
        </span>
        <span className="ml-1 text-xs sm:text-sm">{appConfig.currency}</span>
      </span>
    </Link>
  )
}

export const CurrencyButton = memo(CurrencyButtonComponent)

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useNavigate } from '@/lib/routing'

import { ProfileHashTabs } from '@/enums'

type ExclusiveButtonProps = {
  className?: string
  username: string
}

export const ExclusiveButton: FC<ExclusiveButtonProps> = ({
  className = '',
  username,
}) => {
  const navigate = useNavigate()

  const { t } = useTranslation('common')

  const handleClick = () => {
    navigate(`/@${username}${ProfileHashTabs.SUBSCRIPTION}`)
  }

  return (
    <span
      className={`bg-gradient-to-r from-[#7000F0] to-[#9603F1] text-white text-xs font-semibold px-3 py-1 rounded-full cursor-pointer ${className}`}
      onClick={handleClick}
    >
      {t('seeExclusive')}
    </span>
  )
}

import { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { IonIcon } from '@ionic/react'
import { closeCircleOutline } from 'ionicons/icons'

import { Button } from '@/components/atoms/Button'

import { CreateTransactionData } from '@/services/wallet/createTransaction'

type SummaryProps = {
  unlockPaymentData: CreateTransactionData
  unlockPaymentError: unknown
  handleDismissModal: () => void
  handleStart: () => void
  translation: Record<string, string>
}

export const Summary: FC<SummaryProps> = ({
  unlockPaymentData,
  unlockPaymentError,
  handleDismissModal,
  handleStart,
  translation,
}) => {
  const username = unlockPaymentData?.destination?.username || ''

  const SuccessSummary = useCallback(
    () => (
      <>
        <div className="flex flex-col mt-5 mx-2">
          <span className="text-[1.75rem] font-bold">
            {translation?.paymentSuccessful}
          </span>
          <span className="mt-4">
            <Trans>
              {translation?.paymentUnlockedWith?.replace(
                '{{username}}',
                username
              )}
            </Trans>
          </span>
        </div>
        <div className="mt-9 mb-5">
          <Button type="primary" size="small" onClick={handleStart}>
            {translation?.startToAction}
          </Button>
        </div>
      </>
    ),
    [
      handleStart,
      translation?.paymentSuccessful,
      translation?.paymentUnlockedWith,
      translation?.startToAction,
      username,
    ]
  )

  const ErrorSummary = useCallback(
    () => (
      <>
        <IonIcon
          className="h-12 w-12 text-danger my-5"
          icon={closeCircleOutline}
        />
        <div className="flex flex-col font-bold">
          <span className="text-xl">{translation?.errorSummaryTitle}</span>
          <span className="text-primary">
            {translation?.errorSummaryDetails}
          </span>
        </div>
        <div className="mt-7 mb-7">
          <Button
            type="primary"
            className="w-[9.25rem]"
            size="small"
            onClick={handleDismissModal}
          >
            {translation?.close}
          </Button>
        </div>
      </>
    ),
    [
      handleDismissModal,
      translation?.close,
      translation?.errorSummaryDetails,
      translation?.errorSummaryTitle,
    ]
  )

  return (
    <div className="flex flex-col items-center text-white">
      {unlockPaymentData && <SuccessSummary />}
      {unlockPaymentError && <ErrorSummary />}
    </div>
  )
}

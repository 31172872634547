import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Deposit } from '@/components/atoms/assets/Deposit'
import { Button } from '@/components/atoms/Button'

import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

type DepositButtonProps = {
  hasIcon?: boolean
}

const DepositButtonComponent: FC<DepositButtonProps> = ({ hasIcon }) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  return (
    <>
      <Button onClick={() => navigate(Routes.DEPOSIT)} fill="outline">
        {hasIcon && <Deposit className="h-3 xs:h-4 mr-1" />}
        <span className="text-xxs xs:text-xs">{t('wallet.deposit')}</span>
      </Button>
    </>
  )
}

export const DepositButton = memo(DepositButtonComponent)

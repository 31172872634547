import { FC, memo } from 'react'
const OfflineContainerComponent: FC = () => {
  return (
    <div className="flex h-full w-full m-auto text-center items-center justify-center">
      <img
        className="w-full"
        src="/disconnected.png"
        srcSet="/disconnected@2x.png 2x"
        alt="disconnected"
      />
    </div>
  )
}

export const OfflineContainer = memo(OfflineContainerComponent)

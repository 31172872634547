import { CSSProperties, FC, memo } from 'react'

import { UserRelationIndicatorType } from '@/enums'

interface UserRelationIndicatorProps {
  elementType?: UserRelationIndicatorType
  className?: string
  color?: 'cool-gray-500' | 'white'
  hasOpacity?: boolean
  style?: CSSProperties
}

const UserRelationIndicatorComponent: FC<UserRelationIndicatorProps> = ({
  elementType = UserRelationIndicatorType.verticalLine,
  className,
  color = 'cool-gray-500',
  hasOpacity,
  style,
}) => {
  switch (elementType) {
    case 'verticalLine':
      return (
        <div className="w-8 flex absolute h-[100%] -ml-8">
          <div
            style={style}
            className={`mt-6 -mb-1 border-l-[0.0625rem] border-solid border-${color} ${
              hasOpacity && 'border-opacity-50'
            } ml-[0.375rem] mr-2 pr-4 ${className}`}
          ></div>
        </div>
      )
    case 'curvedRight':
      return (
        <div
          className={`border-b-[0.0625rem] border-l-[0.0625rem] border-solid border-${color} ${
            hasOpacity && 'border-opacity-50'
          } ml-[0.375rem] mr-2 pr-4 h-3 rounded-bl-md ${className}`}
        ></div>
      )
  }
}

export const UserRelationIndicator = memo(UserRelationIndicatorComponent)

import { useCallback } from 'react'
import { useIonToast } from '@ionic/react'
import { alertCircle } from 'ionicons/icons'

export const useShowErrorToast = () => {
  const [presentToast] = useIonToast()

  const showErrorToast = useCallback(
    ({ header, message }: { header?: string; message?: string }) => {
      presentToast({
        position: 'top',
        color: 'primary',
        cssClass: 'error-toast',
        header,
        message,
        duration: 3000,
        icon: alertCircle,
      })
    },
    [presentToast]
  )

  return { showErrorToast }
}

import { FC, memo } from 'react'
import { IonSpinner } from '@ionic/react'

import { Button } from '@/components/atoms/Button'

interface ActionButtonProps {
  isPending: boolean
  handleClick: () => void
  buttonText: string
  fill?: 'outline' | 'clear'
  className?: string
  color?: string
  isDisabled?: boolean
}

const ActionButtonComponent: FC<ActionButtonProps> = ({
  isPending,
  handleClick,
  buttonText,
  fill,
  className = '',
  color,
  isDisabled = false,
}) => {
  return (
    <Button
      className={`${className} relative`}
      color={color}
      fill={fill}
      onClick={handleClick}
      isDisabled={isDisabled}
    >
      <span className={`${isPending ? 'visible' : 'invisible'} `}>
        <IonSpinner
          name="dots"
          slot="icon-only"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      </span>
      <span
        className={`${isPending ? 'invisible' : 'visible'} text-xxs xs:text-xs`}
      >
        {buttonText}
      </span>
    </Button>
  )
}

export const ActionButton = memo(ActionButtonComponent)

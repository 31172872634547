import { memo } from 'react'

interface XPScoreBgProps {
  className?: string
}

const XPScoreBgComponent = ({ className }: XPScoreBgProps) => {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 358 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1149_6468"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="358"
        height="158"
      >
        <rect
          opacity="0.1"
          x="0.5"
          y="0.5"
          width="357"
          height="157"
          rx="9.5"
          fill="#252F41"
          stroke="#384761"
        />
      </mask>
      <g mask="url(#mask0_1149_6468)">
        <path
          d="M313.25 -1.64694C303.878 -1.64694 295.329 0.703851 290.739 1.96442C286.583 3.11142 277.937 5.50385 268.5 5.50385C259.063 5.50385 250.417 3.11521 246.261 1.96442C241.668 0.703851 233.122 -1.64694 223.75 -1.64694C214.378 -1.64694 205.832 0.703851 201.239 1.96442C197.083 3.11142 188.437 5.50385 179 5.50385C169.563 5.50385 160.917 3.11521 156.761 1.96442C152.168 0.703851 143.622 -1.64694 134.25 -1.64694C124.878 -1.64694 116.332 0.703851 111.739 1.96442C107.583 3.11142 98.9369 5.50385 89.5 5.50385C80.0631 5.50385 71.4174 3.11521 67.261 1.96442C62.6679 0.703851 54.1224 -1.64694 44.75 -1.64694C35.3776 -1.64694 26.8321 0.703851 22.239 1.96442C18.0826 3.11142 9.43688 5.50385 0 5.50385V6.6395C9.5765 6.6395 18.3117 4.22435 22.511 3.06599C27.0576 1.81678 35.5172 -0.507506 44.75 -0.507506C53.9828 -0.507506 62.1273 1.72971 66.989 3.06599C71.1883 4.22814 79.9235 6.6395 89.5 6.6395C99.0765 6.6395 107.812 4.22435 112.011 3.06599C116.558 1.81678 125.017 -0.507506 134.25 -0.507506C143.483 -0.507506 151.946 1.81678 156.489 3.06599C160.688 4.22814 169.423 6.6395 179 6.6395C188.576 6.6395 197.312 4.22435 201.511 3.06599C206.058 1.81678 214.517 -0.507506 223.75 -0.507506C232.983 -0.507506 241.127 1.72971 245.989 3.06599C250.188 4.22814 258.923 6.6395 268.5 6.6395C278.076 6.6395 286.812 4.22435 291.011 3.06599C295.558 1.81678 304.017 -0.507506 313.254 -0.507506C322.49 -0.507506 330.631 1.72971 335.493 3.06599C339.692 4.22814 348.427 6.6395 358.004 6.6395V5.50385C348.567 5.50385 339.921 3.11521 335.765 1.96442C331.171 0.703851 322.626 -1.64694 313.254 -1.64694H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 5.7764C303.878 5.7764 295.329 8.12719 290.739 9.38776C286.583 10.5348 277.937 12.9272 268.5 12.9272C259.063 12.9272 250.417 10.5385 246.261 9.38776C241.668 8.12719 233.122 5.7764 223.75 5.7764C214.378 5.7764 205.832 8.12719 201.239 9.38776C197.083 10.5348 188.437 12.9272 179 12.9272C169.563 12.9272 160.917 10.5385 156.761 9.38776C152.168 8.12719 143.622 5.7764 134.25 5.7764C124.878 5.7764 116.332 8.12719 111.739 9.38776C107.583 10.5348 98.9369 12.9272 89.5 12.9272C80.0631 12.9272 71.4174 10.5385 67.261 9.38776C62.6679 8.12719 54.1224 5.7764 44.75 5.7764C35.3776 5.7764 26.8321 8.12719 22.239 9.38776C18.0826 10.5348 9.43688 12.9272 0 12.9272V14.0628C9.5765 14.0628 18.3117 11.6477 22.511 10.4893C27.0576 9.24012 35.5172 6.91583 44.75 6.91583C53.9828 6.91583 62.1273 9.15305 66.989 10.4893C71.1883 11.6515 79.9235 14.0628 89.5 14.0628C99.0765 14.0628 107.812 11.6477 112.011 10.4893C116.558 9.24012 125.017 6.91583 134.25 6.91583C143.483 6.91583 151.946 9.24012 156.489 10.4893C160.688 11.6515 169.423 14.0628 179 14.0628C188.576 14.0628 197.312 11.6477 201.511 10.4893C206.058 9.24012 214.517 6.91583 223.75 6.91583C232.983 6.91583 241.127 9.15305 245.989 10.4893C250.188 11.6515 258.923 14.0628 268.5 14.0628C278.076 14.0628 286.812 11.6477 291.011 10.4893C295.558 9.24012 304.017 6.91583 313.254 6.91583C322.49 6.91583 330.631 9.15305 335.493 10.4893C339.692 11.6515 348.427 14.0628 358.004 14.0628V12.9272C348.567 12.9272 339.921 10.5385 335.765 9.38776C331.171 8.12719 322.626 5.7764 313.254 5.7764H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 13.1997C303.878 13.1997 295.329 15.5505 290.739 16.8111C286.583 17.9581 277.937 20.3505 268.5 20.3505C259.063 20.3505 250.417 17.9619 246.261 16.8111C241.668 15.5505 233.122 13.1997 223.75 13.1997C214.378 13.1997 205.832 15.5505 201.239 16.8111C197.083 17.9581 188.437 20.3505 179 20.3505C169.563 20.3505 160.917 17.9619 156.761 16.8111C152.168 15.5505 143.622 13.1997 134.25 13.1997C124.878 13.1997 116.332 15.5505 111.739 16.8111C107.583 17.9581 98.9369 20.3505 89.5 20.3505C80.0631 20.3505 71.4174 17.9619 67.261 16.8111C62.6679 15.5505 54.1224 13.1997 44.75 13.1997C35.3776 13.1997 26.8321 15.5505 22.239 16.8111C18.0826 17.9581 9.43688 20.3505 0 20.3505V21.4862C9.5765 21.4862 18.3117 19.071 22.511 17.9127C27.0576 16.6635 35.5172 14.3392 44.75 14.3392C53.9828 14.3392 62.1273 16.5764 66.989 17.9127C71.1883 19.0748 79.9235 21.4862 89.5 21.4862C99.0765 21.4862 107.812 19.071 112.011 17.9127C116.558 16.6635 125.017 14.3392 134.25 14.3392C143.483 14.3392 151.946 16.6635 156.489 17.9127C160.688 19.0748 169.423 21.4862 179 21.4862C188.576 21.4862 197.312 19.071 201.511 17.9127C206.058 16.6635 214.517 14.3392 223.75 14.3392C232.983 14.3392 241.127 16.5764 245.989 17.9127C250.188 19.0748 258.923 21.4862 268.5 21.4862C278.076 21.4862 286.812 19.071 291.011 17.9127C295.558 16.6635 304.017 14.3392 313.254 14.3392C322.49 14.3392 330.631 16.5764 335.493 17.9127C339.692 19.0748 348.427 21.4862 358.004 21.4862V20.3505C348.567 20.3505 339.921 17.9619 335.765 16.8111C331.171 15.5505 322.626 13.1997 313.254 13.1997H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 20.6193C303.878 20.6193 295.329 22.9701 290.739 24.2307C286.583 25.3777 277.937 27.7701 268.5 27.7701C259.063 27.7701 250.417 25.3815 246.261 24.2307C241.668 22.9701 233.122 20.6193 223.75 20.6193C214.378 20.6193 205.832 22.9701 201.239 24.2307C197.083 25.3777 188.437 27.7701 179 27.7701C169.563 27.7701 160.917 25.3815 156.761 24.2307C152.168 22.9701 143.622 20.6193 134.25 20.6193C124.878 20.6193 116.332 22.9701 111.739 24.2307C107.583 25.3777 98.9369 27.7701 89.5 27.7701C80.0631 27.7701 71.4174 25.3815 67.261 24.2307C62.6679 22.9701 54.1224 20.6193 44.75 20.6193C35.3776 20.6193 26.8321 22.9701 22.239 24.2307C18.0826 25.3777 9.43688 27.7701 0 27.7701V28.9058C9.5765 28.9058 18.3117 26.4906 22.511 25.3323C27.0576 24.083 35.5172 21.7588 44.75 21.7588C53.9828 21.7588 62.1273 23.996 66.989 25.3323C71.1883 26.4944 79.9235 28.9058 89.5 28.9058C99.0765 28.9058 107.812 26.4906 112.011 25.3323C116.558 24.083 125.017 21.7588 134.25 21.7588C143.483 21.7588 151.946 24.083 156.489 25.3323C160.688 26.4944 169.423 28.9058 179 28.9058C188.576 28.9058 197.312 26.4906 201.511 25.3323C206.058 24.083 214.517 21.7588 223.75 21.7588C232.983 21.7588 241.127 23.996 245.989 25.3323C250.188 26.4944 258.923 28.9058 268.5 28.9058C278.076 28.9058 286.812 26.4906 291.011 25.3323C295.558 24.083 304.017 21.7588 313.254 21.7588C322.49 21.7588 330.631 23.996 335.493 25.3323C339.692 26.4944 348.427 28.9058 358.004 28.9058V27.7701C348.567 27.7701 339.921 25.3815 335.765 24.2307C331.171 22.9701 322.626 20.6193 313.254 20.6193H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 28.0427C303.881 28.0427 295.332 30.3935 290.739 31.654C286.586 32.801 277.94 35.1935 268.5 35.1935C259.06 35.1935 250.417 32.8048 246.261 31.654C241.668 30.3935 233.119 28.0427 223.75 28.0427C214.381 28.0427 205.832 30.3935 201.239 31.654C197.086 32.801 188.44 35.1935 179 35.1935C169.56 35.1935 160.917 32.8048 156.761 31.654C152.168 30.3935 143.619 28.0427 134.25 28.0427C124.881 28.0427 116.332 30.3935 111.739 31.654C107.583 32.801 98.9405 35.1935 89.5 35.1935C80.0595 35.1935 71.4174 32.8048 67.261 31.654C62.6679 30.3935 54.1189 28.0427 44.75 28.0427C35.3811 28.0427 26.8321 30.3935 22.239 31.654C18.0826 32.801 9.44046 35.1935 0 35.1935V36.3291C9.58008 36.3291 18.3153 33.914 22.511 32.7556C27.0576 31.5064 35.5208 29.1821 44.75 29.1821C53.9792 29.1821 62.1237 31.4193 66.989 32.7556C71.1883 33.9177 79.9235 36.3291 89.5 36.3291C99.0765 36.3291 107.812 33.914 112.011 32.7556C116.558 31.5064 125.021 29.1821 134.25 29.1821C143.479 29.1821 151.627 31.4193 156.489 32.7556C160.688 33.9177 169.423 36.3291 179 36.3291C188.576 36.3291 197.312 33.914 201.511 32.7556C206.058 31.5064 214.521 29.1821 223.75 29.1821C232.979 29.1821 241.124 31.4193 245.989 32.7556C250.188 33.9177 258.923 36.3291 268.5 36.3291C278.076 36.3291 286.812 33.914 291.011 32.7556C295.558 31.5064 304.021 29.1821 313.254 29.1821C322.486 29.1821 330.627 31.4193 335.493 32.7556C339.692 33.9177 348.427 36.3291 358.004 36.3291V35.1935C348.563 35.1935 339.917 32.8048 335.765 31.654C331.171 30.3935 322.622 28.0427 313.254 28.0427H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 35.466C303.878 35.466 295.329 37.8168 290.739 39.0774C286.583 40.2244 277.937 42.6168 268.5 42.6168C259.063 42.6168 250.417 40.2281 246.261 39.0774C241.668 37.8168 233.122 35.466 223.75 35.466C214.378 35.466 205.832 37.8168 201.239 39.0774C197.083 40.2244 188.437 42.6168 179 42.6168C169.563 42.6168 160.917 40.2281 156.761 39.0774C152.168 37.8168 143.622 35.466 134.25 35.466C124.878 35.466 116.332 37.8168 111.739 39.0774C107.583 40.2244 98.9369 42.6168 89.5 42.6168C80.0631 42.6168 71.4174 40.2281 67.261 39.0774C62.6679 37.8168 54.1224 35.466 44.75 35.466C35.3776 35.466 26.8321 37.8168 22.239 39.0774C18.0826 40.2244 9.43688 42.6168 0 42.6168V43.7524C9.5765 43.7524 18.3117 41.3373 22.511 40.1789C27.0576 38.9297 35.5172 36.6054 44.75 36.6054C53.9828 36.6054 62.1273 38.8427 66.989 40.1789C71.1883 41.3411 79.9235 43.7524 89.5 43.7524C99.0765 43.7524 107.812 41.3373 112.011 40.1789C116.558 38.9297 125.017 36.6054 134.25 36.6054C143.483 36.6054 151.946 38.9297 156.489 40.1789C160.688 41.3411 169.423 43.7524 179 43.7524C188.576 43.7524 197.312 41.3373 201.511 40.1789C206.058 38.9297 214.517 36.6054 223.75 36.6054C232.983 36.6054 241.127 38.8427 245.989 40.1789C250.188 41.3411 258.923 43.7524 268.5 43.7524C278.076 43.7524 286.812 41.3373 291.011 40.1789C295.558 38.9297 304.017 36.6054 313.254 36.6054C322.49 36.6054 330.631 38.8427 335.493 40.1789C339.692 41.3411 348.427 43.7524 358.004 43.7524V42.6168C348.567 42.6168 339.921 40.2281 335.765 39.0774C331.171 37.8168 322.626 35.466 313.254 35.466H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 42.8893C303.878 42.8893 295.332 45.2401 290.739 46.5007C286.583 47.6477 277.937 50.0363 268.5 50.0363C259.063 50.0363 250.417 47.6477 246.261 46.5007C241.668 45.2401 233.122 42.8893 223.75 42.8893C214.378 42.8893 205.832 45.2401 201.239 46.5007C197.083 47.6477 188.437 50.0363 179 50.0363C169.563 50.0363 160.917 47.6477 156.761 46.5007C152.168 45.2401 143.622 42.8893 134.25 42.8893C124.878 42.8893 116.332 45.2401 111.739 46.5007C107.583 47.6477 98.9369 50.0363 89.5 50.0363C80.0631 50.0363 71.4174 47.6477 67.261 46.5007C62.6679 45.2401 54.1224 42.8893 44.75 42.8893C35.3776 42.8893 26.8321 45.2401 22.239 46.5007C18.0826 47.6477 9.43688 50.0363 0 50.0363V51.172C9.57292 51.172 18.3117 48.7568 22.511 47.5985C27.0576 46.3493 35.5172 44.025 44.75 44.025C53.9828 44.025 62.1237 46.2622 66.989 47.5985C71.1883 48.7568 79.9271 51.172 89.5 51.172C99.0729 51.172 107.812 48.7568 112.011 47.5985C116.558 46.3493 125.017 44.025 134.25 44.025C143.483 44.025 151.946 46.3493 156.489 47.5985C160.688 48.7568 169.427 51.172 179 51.172C188.573 51.172 197.312 48.7568 201.511 47.5985C206.058 46.3493 214.517 44.025 223.75 44.025C232.983 44.025 241.124 46.2622 245.989 47.5985C250.188 48.7568 258.927 51.172 268.5 51.172C278.073 51.172 286.812 48.7568 291.011 47.5985C295.558 46.3493 304.017 44.025 313.254 44.025C322.49 44.025 330.627 46.2622 335.493 47.5985C339.692 48.7568 348.431 51.172 358.004 51.172V50.0363C348.567 50.0363 339.921 47.6477 335.765 46.5007C331.171 45.2401 322.626 42.8893 313.254 42.8893H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 50.3127C303.878 50.3127 295.329 52.6635 290.739 53.924C286.583 55.071 277.937 57.4635 268.5 57.4635C259.063 57.4635 250.417 55.0748 246.261 53.924C241.668 52.6635 233.122 50.3127 223.75 50.3127C214.378 50.3127 205.832 52.6635 201.239 53.924C197.083 55.071 188.437 57.4635 179 57.4635C169.563 57.4635 160.917 55.0748 156.761 53.924C152.168 52.6635 143.622 50.3127 134.25 50.3127C124.878 50.3127 116.332 52.6635 111.739 53.924C107.583 55.071 98.9369 57.4635 89.5 57.4635C80.0631 57.4635 71.4174 55.0748 67.261 53.924C62.6679 52.6635 54.1224 50.3127 44.75 50.3127C35.3776 50.3127 26.8321 52.6635 22.239 53.924C18.0826 55.071 9.43688 57.4635 0 57.4635V58.5991C9.5765 58.5991 18.3117 56.184 22.511 55.0256C27.0576 53.7764 35.5172 51.4521 44.75 51.4521C53.9828 51.4521 62.1273 53.6893 66.989 55.0256C71.1883 56.1878 79.9235 58.5991 89.5 58.5991C99.0765 58.5991 107.812 56.184 112.011 55.0256C116.558 53.7764 125.017 51.4521 134.25 51.4521C143.483 51.4521 151.946 53.7764 156.489 55.0256C160.688 56.1878 169.423 58.5991 179 58.5991C188.576 58.5991 197.312 56.184 201.511 55.0256C206.058 53.7764 214.517 51.4521 223.75 51.4521C232.983 51.4521 241.127 53.6893 245.989 55.0256C250.188 56.1878 258.923 58.5991 268.5 58.5991C278.076 58.5991 286.812 56.184 291.011 55.0256C295.558 53.7764 304.017 51.4521 313.254 51.4521C322.49 51.4521 330.631 53.6893 335.493 55.0256C339.692 56.1878 348.427 58.5991 358.004 58.5991V57.4635C348.567 57.4635 339.921 55.0748 335.765 53.924C331.171 52.6635 322.626 50.3127 313.254 50.3127H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 57.7323C303.878 57.7323 295.329 60.0831 290.739 61.3436C286.586 62.4906 277.94 64.8831 268.5 64.8831C259.06 64.8831 250.414 62.4944 246.261 61.3436C241.668 60.0831 233.122 57.7323 223.75 57.7323C214.378 57.7323 205.832 60.0831 201.239 61.3436C197.086 62.4906 188.44 64.8831 179 64.8831C169.56 64.8831 160.914 62.4944 156.761 61.3436C152.168 60.0831 143.622 57.7323 134.25 57.7323C124.878 57.7323 116.332 60.0831 111.739 61.3436C107.586 62.4906 98.9405 64.8831 89.5 64.8831C80.0595 64.8831 71.4138 62.4944 67.261 61.3436C62.6679 60.0831 54.1224 57.7323 44.75 57.7323C35.3776 57.7323 26.8321 60.0831 22.239 61.3436C18.0862 62.4906 9.44046 64.8831 0 64.8831V66.0187C9.58008 66.0187 18.3153 63.6036 22.511 62.4452C27.0576 61.196 35.5172 58.8717 44.75 58.8717C53.9828 58.8717 62.1273 61.1089 66.989 62.4452C71.1847 63.6073 79.9199 66.0187 89.5 66.0187C99.0801 66.0187 107.812 63.6036 112.011 62.4452C116.558 61.196 125.017 58.8717 134.25 58.8717C143.483 58.8717 151.946 61.196 156.489 62.4452C160.688 63.6073 169.42 66.0187 179 66.0187C188.58 66.0187 197.312 63.6036 201.511 62.4452C206.058 61.196 214.517 58.8717 223.75 58.8717C232.983 58.8717 241.127 61.1089 245.989 62.4452C250.188 63.6073 258.92 66.0187 268.5 66.0187C278.08 66.0187 286.812 63.6036 291.011 62.4452C295.558 61.196 304.017 58.8717 313.254 58.8717C322.49 58.8717 330.631 61.1089 335.493 62.4452C339.688 63.6073 348.423 66.0187 358.004 66.0187V64.8831C348.563 64.8831 339.917 62.4944 335.765 61.3436C331.171 60.0831 322.626 57.7323 313.254 57.7323H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 65.1556C303.878 65.1556 295.329 67.5064 290.739 68.767C286.583 69.914 277.937 72.3064 268.5 72.3064C259.063 72.3064 250.417 69.9178 246.261 68.767C241.668 67.5064 233.122 65.1556 223.75 65.1556C214.378 65.1556 205.832 67.5064 201.239 68.767C197.083 69.914 188.437 72.3064 179 72.3064C169.563 72.3064 160.917 69.9178 156.761 68.767C152.168 67.5064 143.622 65.1556 134.25 65.1556C124.878 65.1556 116.332 67.5064 111.739 68.767C107.583 69.914 98.9369 72.3064 89.5 72.3064C80.0631 72.3064 71.4174 69.9178 67.261 68.767C62.6679 67.5064 54.1224 65.1556 44.75 65.1556C35.3776 65.1556 26.8321 67.5064 22.239 68.767C18.0826 69.914 9.43688 72.3064 0 72.3064V73.4421C9.5765 73.4421 18.3117 71.0269 22.511 69.8686C27.0576 68.6193 35.5172 66.295 44.75 66.295C53.9828 66.295 62.1273 68.5323 66.989 69.8686C71.1883 71.0307 79.9235 73.4421 89.5 73.4421C99.0765 73.4421 107.812 71.0269 112.011 69.8686C116.558 68.6193 125.017 66.295 134.25 66.295C143.483 66.295 151.946 68.6193 156.489 69.8686C160.688 71.0307 169.423 73.4421 179 73.4421C188.576 73.4421 197.312 71.0269 201.511 69.8686C206.058 68.6193 214.517 66.295 223.75 66.295C232.983 66.295 241.127 68.5323 245.989 69.8686C250.188 71.0307 258.923 73.4421 268.5 73.4421C278.076 73.4421 286.812 71.0269 291.011 69.8686C295.558 68.6193 304.017 66.295 313.254 66.295C322.49 66.295 330.631 68.5323 335.493 69.8686C339.692 71.0307 348.427 73.4421 358.004 73.4421V72.3064C348.567 72.3064 339.921 69.9178 335.765 68.767C331.171 67.5064 322.626 65.1556 313.254 65.1556H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 72.579C303.878 72.579 295.329 74.9298 290.739 76.1903C286.583 77.3373 277.937 79.7298 268.5 79.7298C259.063 79.7298 250.417 77.3411 246.261 76.1903C241.668 74.9298 233.122 72.579 223.75 72.579C214.378 72.579 205.832 74.9298 201.239 76.1903C197.083 77.3373 188.437 79.7298 179 79.7298C169.563 79.7298 160.917 77.3411 156.761 76.1903C152.168 74.9298 143.622 72.579 134.25 72.579C124.878 72.579 116.332 74.9298 111.739 76.1903C107.583 77.3373 98.9369 79.7298 89.5 79.7298C80.0631 79.7298 71.4174 77.3411 67.261 76.1903C62.6679 74.9298 54.1224 72.579 44.75 72.579C35.3776 72.579 26.8321 74.9298 22.239 76.1903C18.0826 77.3373 9.43688 79.7298 0 79.7298V80.8654C9.5765 80.8654 18.3117 78.4503 22.511 77.2919C27.0576 76.0427 35.5172 73.7184 44.75 73.7184C53.9828 73.7184 62.1273 75.9556 66.989 77.2919C71.1883 78.4541 79.9235 80.8654 89.5 80.8654C99.0765 80.8654 107.812 78.4503 112.011 77.2919C116.558 76.0427 125.017 73.7184 134.25 73.7184C143.483 73.7184 151.946 76.0427 156.489 77.2919C160.688 78.4541 169.423 80.8654 179 80.8654C188.576 80.8654 197.312 78.4503 201.511 77.2919C206.058 76.0427 214.517 73.7184 223.75 73.7184C232.983 73.7184 241.127 75.9556 245.989 77.2919C250.188 78.4541 258.923 80.8654 268.5 80.8654C278.076 80.8654 286.812 78.4503 291.011 77.2919C295.558 76.0427 304.017 73.7184 313.254 73.7184C322.49 73.7184 330.631 75.9556 335.493 77.2919C339.692 78.4541 348.427 80.8654 358.004 80.8654V79.7298C348.567 79.7298 339.921 77.3411 335.765 76.1903C331.171 74.9298 322.626 72.579 313.254 72.579H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 80.0023C303.874 80.0023 295.329 82.3531 290.739 83.6137C286.583 84.7607 277.937 87.1531 268.5 87.1531C259.063 87.1531 250.417 84.7645 246.261 83.6137C241.671 82.3531 233.126 80.0023 223.75 80.0023C214.374 80.0023 205.832 82.3531 201.239 83.6137C197.083 84.7607 188.437 87.1531 179 87.1531C169.563 87.1531 160.917 84.7645 156.761 83.6137C152.171 82.3531 143.626 80.0023 134.25 80.0023C124.874 80.0023 116.332 82.3531 111.739 83.6137C107.583 84.7607 98.9369 87.1531 89.5 87.1531C80.0631 87.1531 71.4174 84.7645 67.261 83.6137C62.6715 82.3531 54.126 80.0023 44.75 80.0023C35.374 80.0023 26.8321 82.3531 22.239 83.6137C18.0826 84.7607 9.43688 87.1531 0 87.1531V88.2888C9.5765 88.2888 18.3117 85.8736 22.511 84.7153C27.0541 83.466 35.5136 81.1418 44.75 81.1418C53.9864 81.1418 62.4459 83.466 66.989 84.7153C71.1883 85.8774 79.9235 88.2888 89.5 88.2888C99.0765 88.2888 107.812 85.8736 112.011 84.7153C116.554 83.466 125.014 81.1418 134.25 81.1418C143.486 81.1418 151.946 83.466 156.489 84.7153C160.688 85.8774 169.423 88.2888 179 88.2888C188.576 88.2888 197.312 85.8736 201.511 84.7153C206.054 83.466 214.514 81.1418 223.75 81.1418C232.986 81.1418 241.446 83.466 245.989 84.7153C250.188 85.8774 258.923 88.2888 268.5 88.2888C278.076 88.2888 286.812 85.8736 291.011 84.7153C295.554 83.466 304.014 81.1418 313.254 81.1418C322.494 81.1418 330.95 83.466 335.493 84.7153C339.692 85.8774 348.427 88.2888 358.004 88.2888V87.1531C348.567 87.1531 339.921 84.7645 335.765 83.6137C331.175 82.3531 322.63 80.0023 313.254 80.0023H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 87.4257C303.878 87.4257 295.329 89.7765 290.739 91.037C286.583 92.184 277.937 94.5765 268.5 94.5765C259.063 94.5765 250.417 92.1878 246.261 91.037C241.668 89.7765 233.122 87.4257 223.75 87.4257C214.378 87.4257 205.832 89.7765 201.239 91.037C197.083 92.184 188.437 94.5765 179 94.5765C169.563 94.5765 160.917 92.1878 156.761 91.037C152.168 89.7765 143.622 87.4257 134.25 87.4257C124.878 87.4257 116.332 89.7765 111.739 91.037C107.583 92.184 98.9369 94.5765 89.5 94.5765C80.0631 94.5765 71.4174 92.1878 67.261 91.037C62.6679 89.7765 54.1224 87.4257 44.75 87.4257C35.3776 87.4257 26.8321 89.7765 22.239 91.037C18.0826 92.184 9.43688 94.5765 0 94.5765V95.7121C9.5765 95.7121 18.3117 93.297 22.511 92.1386C27.0576 90.8894 35.5172 88.5651 44.75 88.5651C53.9828 88.5651 62.1273 90.8023 66.989 92.1386C71.1883 93.3007 79.9235 95.7121 89.5 95.7121C99.0765 95.7121 107.812 93.297 112.011 92.1386C116.558 90.8894 125.017 88.5651 134.25 88.5651C143.483 88.5651 151.946 90.8894 156.489 92.1386C160.688 93.3007 169.423 95.7121 179 95.7121C188.576 95.7121 197.312 93.297 201.511 92.1386C206.058 90.8894 214.517 88.5651 223.75 88.5651C232.983 88.5651 241.127 90.8023 245.989 92.1386C250.188 93.3007 258.923 95.7121 268.5 95.7121C278.076 95.7121 286.812 93.297 291.011 92.1386C295.558 90.8894 304.017 88.5651 313.254 88.5651C322.49 88.5651 330.631 90.8023 335.493 92.1386C339.692 93.3007 348.427 95.7121 358.004 95.7121V94.5765C348.567 94.5765 339.921 92.1878 335.765 91.037C331.171 89.7765 322.626 87.4257 313.254 87.4257H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 94.8452C303.874 94.8452 295.329 97.196 290.739 98.4566C286.583 99.6036 277.937 101.996 268.5 101.996C259.063 101.996 250.417 99.6074 246.261 98.4566C241.671 97.196 233.126 94.8452 223.75 94.8452C214.374 94.8452 205.832 97.196 201.239 98.4566C197.083 99.6036 188.437 101.996 179 101.996C169.563 101.996 160.917 99.6074 156.761 98.4566C152.171 97.196 143.626 94.8452 134.25 94.8452C124.874 94.8452 116.332 97.196 111.739 98.4566C107.583 99.6036 98.9369 101.996 89.5 101.996C80.0631 101.996 71.4174 99.6074 67.261 98.4566C62.6715 97.196 54.126 94.8452 44.75 94.8452C35.374 94.8452 26.8321 97.196 22.239 98.4566C18.0826 99.6036 9.43688 101.996 0 101.996V103.132C9.5765 103.132 18.3117 100.717 22.511 99.5582C27.0541 98.3089 35.5136 95.9847 44.75 95.9847C53.9864 95.9847 62.4459 98.3089 66.989 99.5582C71.1883 100.72 79.9235 103.132 89.5 103.132C99.0765 103.132 107.812 100.717 112.011 99.5582C116.554 98.3089 125.014 95.9847 134.25 95.9847C143.486 95.9847 151.946 98.3089 156.489 99.5582C160.688 100.72 169.423 103.132 179 103.132C188.576 103.132 197.312 100.717 201.511 99.5582C206.054 98.3089 214.514 95.9847 223.75 95.9847C232.986 95.9847 241.446 98.3089 245.989 99.5582C250.188 100.72 258.923 103.132 268.5 103.132C278.076 103.132 286.812 100.717 291.011 99.5582C295.554 98.3089 304.014 95.9847 313.254 95.9847C322.494 95.9847 330.95 98.3089 335.493 99.5582C339.692 100.72 348.427 103.132 358.004 103.132V101.996C348.567 101.996 339.921 99.6074 335.765 98.4566C331.175 97.196 322.63 94.8452 313.254 94.8452H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 102.269C303.878 102.269 295.329 104.619 290.739 105.88C286.583 107.027 277.937 109.419 268.5 109.419C259.063 109.419 250.417 107.031 246.261 105.88C241.668 104.619 233.122 102.269 223.75 102.269C214.378 102.269 205.832 104.619 201.239 105.88C197.083 107.027 188.437 109.419 179 109.419C169.563 109.419 160.917 107.031 156.761 105.88C152.168 104.619 143.622 102.269 134.25 102.269C124.878 102.269 116.332 104.619 111.739 105.88C107.583 107.027 98.9369 109.419 89.5 109.419C80.0631 109.419 71.4174 107.031 67.261 105.88C62.6679 104.619 54.1224 102.269 44.75 102.269C35.3776 102.269 26.8321 104.619 22.239 105.88C18.0826 107.027 9.43688 109.419 0 109.419V110.555C9.5765 110.555 18.3117 108.14 22.511 106.981C27.0576 105.732 35.5172 103.408 44.75 103.408C53.9828 103.408 62.1273 105.645 66.989 106.981C71.1883 108.144 79.9235 110.555 89.5 110.555C99.0765 110.555 107.812 108.14 112.011 106.981C116.558 105.732 125.017 103.408 134.25 103.408C143.483 103.408 151.946 105.732 156.489 106.981C160.688 108.144 169.423 110.555 179 110.555C188.576 110.555 197.312 108.14 201.511 106.981C206.058 105.732 214.517 103.408 223.75 103.408C232.983 103.408 241.127 105.645 245.989 106.981C250.188 108.144 258.923 110.555 268.5 110.555C278.076 110.555 286.812 108.14 291.011 106.981C295.558 105.732 304.017 103.408 313.254 103.408C322.49 103.408 330.631 105.645 335.493 106.981C339.692 108.144 348.427 110.555 358.004 110.555V109.419C348.567 109.419 339.921 107.031 335.765 105.88C331.171 104.619 322.626 102.269 313.254 102.269H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 109.692C303.874 109.692 295.329 112.043 290.739 113.303C286.583 114.45 277.937 116.839 268.5 116.839C259.063 116.839 250.417 114.45 246.261 113.303C241.671 112.043 233.126 109.692 223.75 109.692C214.374 109.692 205.832 112.043 201.239 113.303C197.083 114.45 188.437 116.839 179 116.839C169.563 116.839 160.917 114.45 156.761 113.303C152.171 112.043 143.626 109.692 134.25 109.692C124.874 109.692 116.329 112.043 111.739 113.303C107.583 114.45 98.9369 116.839 89.5 116.839C80.0631 116.839 71.4174 114.45 67.261 113.303C62.6715 112.043 54.126 109.692 44.75 109.692C35.374 109.692 26.8321 112.043 22.239 113.303C18.0826 114.45 9.43688 116.839 0 116.839V117.975C9.57292 117.975 18.3117 115.559 22.511 114.401C27.0541 113.152 35.5136 110.828 44.75 110.828C53.9864 110.828 62.4459 113.152 66.989 114.401C71.1883 115.563 79.9271 117.975 89.5 117.975C99.0729 117.975 107.812 115.559 112.011 114.401C116.554 113.152 125.014 110.828 134.25 110.828C143.486 110.828 151.946 113.152 156.489 114.401C160.688 115.563 169.427 117.975 179 117.975C188.573 117.975 197.312 115.559 201.511 114.401C206.054 113.152 214.514 110.828 223.75 110.828C232.986 110.828 241.446 113.152 245.989 114.401C250.188 115.563 258.927 117.975 268.5 117.975C278.073 117.975 286.812 115.559 291.011 114.401C295.554 113.152 304.014 110.828 313.254 110.828C322.494 110.828 330.95 113.152 335.493 114.401C339.692 115.563 348.431 117.975 358.004 117.975V116.839C348.567 116.839 339.921 114.45 335.765 113.303C331.175 112.043 322.63 109.692 313.254 109.692H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 117.115C303.878 117.115 295.329 119.466 290.739 120.727C286.583 121.874 277.937 124.266 268.5 124.266C259.063 124.266 250.417 121.877 246.261 120.727C241.668 119.466 233.122 117.115 223.75 117.115C214.378 117.115 205.832 119.466 201.239 120.727C197.083 121.874 188.437 124.266 179 124.266C169.563 124.266 160.917 121.877 156.761 120.727C152.168 119.466 143.622 117.115 134.25 117.115C124.878 117.115 116.332 119.466 111.739 120.727C107.583 121.874 98.9369 124.266 89.5 124.266C80.0631 124.266 71.4174 121.877 67.261 120.727C62.6679 119.466 54.1224 117.115 44.75 117.115C35.3776 117.115 26.8321 119.466 22.239 120.727C18.0826 121.874 9.43688 124.266 0 124.266V125.402C9.5765 125.402 18.3117 122.987 22.511 121.828C27.0576 120.579 35.5172 118.255 44.75 118.255C53.9828 118.255 62.1273 120.492 66.989 121.828C71.1883 122.99 79.9235 125.402 89.5 125.402C99.0765 125.402 107.812 122.987 112.011 121.828C116.558 120.579 125.017 118.255 134.25 118.255C143.483 118.255 151.946 120.579 156.489 121.828C160.688 122.99 169.423 125.402 179 125.402C188.576 125.402 197.312 122.987 201.511 121.828C206.058 120.579 214.517 118.255 223.75 118.255C232.983 118.255 241.127 120.492 245.989 121.828C250.188 122.99 258.923 125.402 268.5 125.402C278.076 125.402 286.812 122.987 291.011 121.828C295.558 120.579 304.017 118.255 313.254 118.255C322.49 118.255 330.631 120.492 335.493 121.828C339.692 122.99 348.427 125.402 358.004 125.402V124.266C348.567 124.266 339.921 121.877 335.765 120.727C331.171 119.466 322.626 117.115 313.254 117.115H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 124.535C303.878 124.535 295.329 126.886 290.739 128.146C286.583 129.293 277.937 131.686 268.5 131.686C259.063 131.686 250.417 129.297 246.261 128.146C241.668 126.886 233.122 124.535 223.75 124.535C214.378 124.535 205.832 126.886 201.239 128.146C197.083 129.293 188.437 131.686 179 131.686C169.563 131.686 160.917 129.297 156.761 128.146C152.168 126.886 143.622 124.535 134.25 124.535C124.878 124.535 116.332 126.886 111.739 128.146C107.583 129.293 98.9369 131.686 89.5 131.686C80.0631 131.686 71.4174 129.297 67.261 128.146C62.6679 126.886 54.1224 124.535 44.75 124.535C35.3776 124.535 26.8321 126.886 22.239 128.146C18.0826 129.293 9.43688 131.686 0 131.686V132.821C9.5765 132.821 18.3117 130.406 22.511 129.248C27.0576 127.999 35.5172 125.674 44.75 125.674C53.9828 125.674 62.1273 127.912 66.989 129.248C71.1883 130.41 79.9235 132.821 89.5 132.821C99.0765 132.821 107.812 130.406 112.011 129.248C116.558 127.999 125.017 125.674 134.25 125.674C143.483 125.674 151.946 127.999 156.489 129.248C160.688 130.41 169.423 132.821 179 132.821C188.576 132.821 197.312 130.406 201.511 129.248C206.058 127.999 214.517 125.674 223.75 125.674C232.983 125.674 241.127 127.912 245.989 129.248C250.188 130.41 258.923 132.821 268.5 132.821C278.076 132.821 286.812 130.406 291.011 129.248C295.558 127.999 304.017 125.674 313.254 125.674C322.49 125.674 330.631 127.912 335.493 129.248C339.692 130.41 348.427 132.821 358.004 132.821V131.686C348.567 131.686 339.921 129.297 335.765 128.146C331.171 126.886 322.626 124.535 313.254 124.535H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 131.958C303.878 131.958 295.332 134.309 290.739 135.57C286.586 136.717 277.94 139.109 268.5 139.109C259.06 139.109 250.417 136.72 246.261 135.57C241.668 134.309 233.122 131.958 223.75 131.958C214.378 131.958 205.832 134.309 201.239 135.57C197.086 136.717 188.44 139.109 179 139.109C169.56 139.109 160.917 136.72 156.761 135.57C152.168 134.309 143.622 131.958 134.25 131.958C124.878 131.958 116.332 134.309 111.739 135.57C107.583 136.717 98.9405 139.109 89.5 139.109C80.0595 139.109 71.4174 136.72 67.261 135.57C62.6679 134.309 54.1224 131.958 44.75 131.958C35.3776 131.958 26.8321 134.309 22.239 135.57C18.0826 136.717 9.44046 139.109 0 139.109V140.245C9.58008 140.245 18.3153 137.829 22.511 136.671C27.0576 135.422 35.5172 133.098 44.75 133.098C53.9828 133.098 62.1237 135.335 66.989 136.671C71.1883 137.833 79.9235 140.245 89.5 140.245C99.0765 140.245 107.812 137.829 112.011 136.671C116.558 135.422 125.017 133.098 134.25 133.098C143.483 133.098 151.627 135.335 156.489 136.671C160.688 137.833 169.423 140.245 179 140.245C188.576 140.245 197.312 137.829 201.511 136.671C206.058 135.422 214.517 133.098 223.75 133.098C232.983 133.098 241.124 135.335 245.989 136.671C250.188 137.833 258.923 140.245 268.5 140.245C278.076 140.245 286.812 137.829 291.011 136.671C295.558 135.422 304.017 133.098 313.254 133.098C322.49 133.098 330.627 135.335 335.493 136.671C339.692 137.833 348.427 140.245 358.004 140.245V139.109C348.563 139.109 339.917 136.72 335.765 135.57C331.171 134.309 322.626 131.958 313.254 131.958H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 139.382C303.878 139.382 295.329 141.732 290.739 142.993C286.583 144.14 277.937 146.532 268.5 146.532C259.063 146.532 250.417 144.144 246.261 142.993C241.668 141.732 233.122 139.382 223.75 139.382C214.378 139.382 205.832 141.732 201.239 142.993C197.083 144.14 188.437 146.532 179 146.532C169.563 146.532 160.917 144.144 156.761 142.993C152.168 141.732 143.622 139.382 134.25 139.382C124.878 139.382 116.332 141.732 111.739 142.993C107.583 144.14 98.9369 146.532 89.5 146.532C80.0631 146.532 71.4174 144.144 67.261 142.993C62.6679 141.732 54.1224 139.382 44.75 139.382C35.3776 139.382 26.8321 141.732 22.239 142.993C18.0826 144.14 9.43688 146.532 0 146.532V147.668C9.5765 147.668 18.3117 145.253 22.511 144.094C27.0576 142.845 35.5172 140.521 44.75 140.521C53.9828 140.521 62.1273 142.758 66.989 144.094C71.1883 145.257 79.9235 147.668 89.5 147.668C99.0765 147.668 107.812 145.253 112.011 144.094C116.558 142.845 125.017 140.521 134.25 140.521C143.483 140.521 151.946 142.845 156.489 144.094C160.688 145.257 169.423 147.668 179 147.668C188.576 147.668 197.312 145.253 201.511 144.094C206.058 142.845 214.517 140.521 223.75 140.521C232.983 140.521 241.127 142.758 245.989 144.094C250.188 145.257 258.923 147.668 268.5 147.668C278.076 147.668 286.812 145.253 291.011 144.094C295.558 142.845 304.017 140.521 313.254 140.521C322.49 140.521 330.631 142.758 335.493 144.094C339.692 145.257 348.427 147.668 358.004 147.668V146.532C348.567 146.532 339.921 144.144 335.765 142.993C331.171 141.732 322.626 139.382 313.254 139.382H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 146.805C303.874 146.805 295.329 149.156 290.739 150.416C286.583 151.563 277.933 153.956 268.5 153.956C259.067 153.956 250.417 151.567 246.261 150.416C241.671 149.156 233.126 146.805 223.75 146.805C214.374 146.805 205.832 149.156 201.239 150.416C197.083 151.563 188.433 153.956 179 153.956C169.567 153.956 160.917 151.567 156.761 150.416C152.171 149.156 143.626 146.805 134.25 146.805C124.874 146.805 116.329 149.156 111.739 150.416C107.583 151.563 98.9333 153.956 89.5 153.956C80.0667 153.956 71.4174 151.567 67.261 150.416C62.6715 149.156 54.126 146.805 44.75 146.805C35.374 146.805 26.8321 149.156 22.239 150.416C18.0826 151.563 9.4333 153.956 0 153.956V155.091C9.57292 155.091 18.3117 152.676 22.511 151.518C27.0541 150.269 35.5136 147.944 44.75 147.944C53.9864 147.944 62.4459 150.269 66.989 151.518C71.1883 152.68 79.9271 155.091 89.5 155.091C99.0729 155.091 107.812 152.676 112.011 151.518C116.554 150.269 125.014 147.944 134.25 147.944C143.486 147.944 151.946 150.269 156.489 151.518C160.688 152.68 169.427 155.091 179 155.091C188.573 155.091 197.312 152.676 201.511 151.518C206.054 150.269 214.514 147.944 223.75 147.944C232.986 147.944 241.446 150.269 245.989 151.518C250.188 152.68 258.927 155.091 268.5 155.091C278.073 155.091 286.812 152.676 291.011 151.518C295.554 150.269 304.014 147.944 313.254 147.944C322.494 147.944 330.95 150.269 335.493 151.518C339.692 152.68 348.431 155.091 358.004 155.091V153.956C348.57 153.956 339.921 151.567 335.765 150.416C331.175 149.156 322.63 146.805 313.254 146.805H313.25Z"
          fill="#BCBEC0"
        />
        <path
          d="M313.25 154.228C303.878 154.228 295.329 156.579 290.739 157.84C286.583 158.987 277.937 161.379 268.5 161.379C259.063 161.379 250.417 158.99 246.261 157.84C241.668 156.579 233.122 154.228 223.75 154.228C214.378 154.228 205.832 156.579 201.239 157.84C197.083 158.987 188.437 161.379 179 161.379C169.563 161.379 160.917 158.99 156.761 157.84C152.168 156.579 143.622 154.228 134.25 154.228C124.878 154.228 116.332 156.579 111.739 157.84C107.583 158.987 98.9369 161.379 89.5 161.379C80.0631 161.379 71.4174 158.99 67.261 157.84C62.6679 156.579 54.1224 154.228 44.75 154.228C35.3776 154.228 26.8321 156.579 22.239 157.84C18.0826 158.987 9.43688 161.379 0 161.379V162.515C9.5765 162.515 18.3117 160.1 22.511 158.941C27.0576 157.692 35.5172 155.368 44.75 155.368C53.9828 155.368 62.1273 157.605 66.989 158.941C71.1883 160.103 79.9235 162.515 89.5 162.515C99.0765 162.515 107.812 160.1 112.011 158.941C116.558 157.692 125.017 155.368 134.25 155.368C143.483 155.368 151.946 157.692 156.489 158.941C160.688 160.103 169.423 162.515 179 162.515C188.576 162.515 197.312 160.1 201.511 158.941C206.058 157.692 214.517 155.368 223.75 155.368C232.983 155.368 241.127 157.605 245.989 158.941C250.188 160.103 258.923 162.515 268.5 162.515C278.076 162.515 286.812 160.1 291.011 158.941C295.558 157.692 304.017 155.368 313.254 155.368C322.49 155.368 330.631 157.605 335.493 158.941C339.692 160.103 348.427 162.515 358.004 162.515V161.379C348.567 161.379 339.921 158.99 335.765 157.84C331.171 156.579 322.626 154.228 313.254 154.228H313.25Z"
          fill="#BCBEC0"
        />
      </g>
    </svg>
  )
}

export const XPScoreBg = memo(XPScoreBgComponent)

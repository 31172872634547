import { Activity } from 'getstream'

import { get, put } from '@/services/awsAmplify'

import { IContestDiscoveryFeedActivityType } from './contestDiscoveryFeed'

export type Category = {
  id: UUID
  name: string
}

export type ExploreCategory = {
  id: string
  name: string
  activities: Activity[]
}
export type ExploreCategoriesResult = {
  items: ExploreCategory[]
  next: UUID
}

export interface ICategorySearchResult {
  results: IContestDiscoveryFeedActivityType[]
  next: string | null
}

export interface ISubscribedCategoriesResult {
  subscriptions: UUID[]
}

export const getCategories = async (isSurvey = false) => {
  const params = isSurvey ? { survey: true } : {}

  return <Promise<Category[]>>(
    get({ path: '/explore/categories', params, endpoint: 'cached' })
  )
}

export const getExploreCategories = async (params: { next?: string }) => {
  return <Promise<ExploreCategoriesResult>>get({
    path: '/explore/enriched-categories',
    params,
    endpoint: 'cached',
  })
}

export const getCategorySearch = async (params: {
  q?: string | null
  categoryId?: string
  limit?: number
  page?: number
}) => <Promise<ICategorySearchResult>>get({
    path: '/explore',
    params,
  })

export const getSubscribedCategories = async () =>
  <Promise<ISubscribedCategoriesResult>>get({ path: '/explore/subscriptions' })

export const subscribeCategory = async (
  categories: UUID[]
): Promise<ISubscribedCategoriesResult> =>
  (await put({
    path: '/explore/subscriptions',
    params: {
      categories,
    },
  })) as unknown as Promise<ISubscribedCategoriesResult>

export const unsubscribeCategory = async (
  categoryId: UUID
): Promise<ISubscribedCategoriesResult> =>
  (await put({
    path: `/explore/subscriptions/${categoryId}`,
  })) as unknown as Promise<ISubscribedCategoriesResult>

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { claimSurpriseBox } from '@/services/openAppStreak'

export const useClaimSurpriseBox = (options: UseMutationOptions = {}) =>
  useMutation({
    mutationKey: ['claim-surprise-box'],
    mutationFn: claimSurpriseBox,
    ...options,
  })

import { FC, useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonSpinner, IonTitle, useIonToast } from '@ionic/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { BackButton } from '@components/atoms/BackButton'
import { Button } from '@components/atoms/Button'
import { Title } from '@components/atoms/Title'
import { Loading } from '@/components/atoms/Loading'
import { AuthenticatorCode } from '@/components/molecules/AuthenticatorCode'
import { Main } from '@/components/templates/main'
import { CopySecretKey } from './components/CopySecretKey'

import {
  get2FASecretKey as _get2FASecretKey,
  Get2FASecretKeyResult,
  setup2FA as _setup2FA,
} from '@/services/user'
import { useErrorBoundary } from '@/lib/hooks/useErrorBoundary'
import { queryClient } from '@/lib/queryClient'
import { useLocation, useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

import { IUser } from '@/interfaces'

export const Setup2FA: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const [presentToast] = useIonToast()
  const { handleError } = useErrorBoundary()
  const location = useLocation()
  const redirectBackTo = location?.state?.redirectBackTo

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      authenticatorCode: '',
    },
    mode: 'all',
  })

  const {
    data,
    isLoading: isLoading2FACode,
    error,
  } = useQuery<Get2FASecretKeyResult, AxiosError>({
    queryKey: ['2FASecretCode'],
    queryFn: _get2FASecretKey,
    staleTime: 0,
    gcTime: 0,
  })

  const setUserCompletedSetup2FA = (): void => {
    queryClient.setQueryData(['user'], (authUserData: IUser) => ({
      ...authUserData,
      setup2FA: true,
    }))
    queryClient.invalidateQueries({ queryKey: ['user'] })
  }

  useEffect(() => {
    if (error) {
      const isRateLimitExceeded = error.response.status === 429

      handleError(error, () => {
        if (!isRateLimitExceeded) {
          presentToast({
            header: t('setup2FA.errors.get2FASecretKeyError.header'),
            message: t('setup2FA.errors.get2FASecretKeyError.message'),
            position: 'top',
            duration: 3000,
          })
        }
        navigate(Routes.SECURITY_SETTINGS, { replace: true })
      })
    }
  }, [error, handleError, navigate, presentToast, t])

  const { mutate: setup2FA, isPending: isPendingSetup } = useMutation({
    mutationFn: _setup2FA,
    onSuccess: () => {
      setUserCompletedSetup2FA()
      presentToast({
        header: t('setup2FA.setup2FASuccess.header'),
        message: t('setup2FA.setup2FASuccess.message'),
        position: 'top',
        duration: 3000,
      })

      if (redirectBackTo) {
        navigate(redirectBackTo, { replace: true })
        return
      }

      navigate(Routes.SECURITY_SETTINGS, { replace: true })
    },
    onError: (e: AxiosError) =>
      handleError(e, () => {
        if (e?.response?.status === 401) {
          setError('authenticatorCode', { message: t('setup2FA.invalidCode') })
        } else if (e?.response?.data === 'ALREADY_CONFIGURED') {
          setUserCompletedSetup2FA()
          presentToast({
            message: t('setup2FA.errors.alreadyConfigured'),
            position: 'top',
            duration: 3000,
          })
          navigate(Routes.SECURITY_SETTINGS, { replace: true })
        }
      }),
  })

  const header = useMemo(
    () => (
      <>
        <IonButtons slot="start">
          <BackButton defaultHref={Routes.PROFILE} />
        </IonButtons>
        <IonTitle className="px-2">
          <Title className="text-center my-0" level={3}>
            {t('setup2FA.header')}
          </Title>
        </IonTitle>
      </>
    ),
    [t]
  )

  const onSubmit = useCallback(() => {
    const { authenticatorCode } = getValues()

    setup2FA({ code: authenticatorCode })
  }, [getValues, setup2FA])

  return (
    <Main header={header} contentClassName="px-8 pb-6" isPaddingEnabled={false}>
      {isLoading2FACode ? (
        <Loading />
      ) : (
        <div className="text-white mt-7">
          <div className="flex flex-col mb-9">
            <span className="text-sm text-primary font-bold">
              {t('setup2FA.step1.title')}
            </span>
            <span>{t('setup2FA.step1.description')}</span>
          </div>
          <div className="flex flex-col mb-4">
            <span className="text-sm text-primary font-bold">
              {t('setup2FA.step2.title')}
            </span>
            <span>{t('setup2FA.step2.description')}</span>
          </div>
          <CopySecretKey secretCode={data?.secretKey} qrCode={data?.qrCode} />
          <div className="flex flex-col mb-4">
            <span className="text-sm text-primary font-bold">
              {t('setup2FA.step3.title')}
            </span>
            <span>{t('setup2FA.step3.description')}</span>
          </div>
          <Controller
            control={control}
            name="authenticatorCode"
            rules={{
              required: true,
              minLength: 6,
              maxLength: 6,
            }}
            render={({ field }) => (
              <AuthenticatorCode
                codeLength={6}
                code={field.value}
                setCode={field.onChange}
                error={errors.authenticatorCode?.message}
              />
            )}
          />
          <Button
            isDisabled={!isValid || isPendingSetup}
            onClick={handleSubmit(onSubmit)}
            className="mt-8 flex mx-auto w-lg"
            size="large"
          >
            {isPendingSetup ? (
              <IonSpinner color="light" />
            ) : (
              t('setup2FA.submit')
            )}
          </Button>
        </div>
      )}
    </Main>
  )
}

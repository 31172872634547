import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'

import { getLanguage } from '@/utils/getLanguage'

export const localeMapper = {
  en: 'en-US',
  vi: 'vi-VN',
  ru: 'ru',
}

export default i18n
  .use(
    resourcesToBackend(
      (language: string) => import(`./locales/${localeMapper[language]}.json`)
    )
  )
  .use(initReactI18next)
  .init({
    partialBundledLanguages: true,
    fallbackLng: 'en',
    lng: getLanguage(), // will return 'en' as a fallback if language is not already supported
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })

import { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { uniqueId } from 'lodash'

import { Button } from '@/components/atoms/Button'
import {
  DepositCurrency,
  DepositCurrencyRef,
} from '@/components/organisms/DepositCurrency'
import { TippingModal } from '@/components/pages/UserProfile/components/TippingModal'

import { appConfig } from '@/services/config'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useWallet } from '@/lib/hooks/wallet'

interface TopSupportersTipProps {
  entryCurrencyAmount: number
  username: string
  userId: string
  text: string
}

export default function TopSupportersTip({
  entryCurrencyAmount,
  username,
  userId,
  text,
}: TopSupportersTipProps) {
  const currency = appConfig.currency
  const depositCurrencyRef = useRef<DepositCurrencyRef>()
  const { t } = useTranslation()
  const uuid = useMemo(() => uniqueId(), [])
  const [modalId, setModalId] = useState('')

  const { data: authUser } = useUserInfo()
  const { isZeroBalance } = useWallet({
    userInfo: authUser,
  })

  useEffect(() => {
    if (!isZeroBalance) {
      setModalId(`send-tip-modal-${uuid}`)
    }
  }, [isZeroBalance, uuid])

  return (
    <>
      <div className="text-center px-[15%] mt-8">
        <p className="text-sm">
          <Trans
            i18nKey={text}
            components={{
              amount: <span className="font-bold text-primary" />,
            }}
            values={{
              username,
              currency: appConfig.currency,
              currencyAmount: entryCurrencyAmount,
            }}
          />
        </p>

        <Button
          onClick={() => {
            if (isZeroBalance) {
              depositCurrencyRef.current.openModal()
              return
            }
          }}
          id={modalId}
          className="mt-4"
        >
          {t('tip.actions.sendTip')}
        </Button>

        <TippingModal
          userId={userId}
          initialAmount={entryCurrencyAmount}
          modalId={modalId}
        />
      </div>
      <DepositCurrency
        title={t('depositBottomSheet.topUpCurrencyToTip', {
          currency,
        })}
        ref={depositCurrencyRef}
      />
    </>
  )
}

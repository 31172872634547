import { useCallback, useMemo } from 'react'
import { formatISO } from 'date-fns'

import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { calculateContainerHeight, getMaxViewportWidth } from '@/utils/utils'

export const useConversationActivity = ({
  object,
  conversationReplyId,
  activityId,
  replyIndex,
}) => {
  const { data: authUser } = useUserInfo()

  const findSliderIndex = useCallback(
    (conversationReplyId) =>
      object.data?.replies.findIndex((item) => {
        return item.id === conversationReplyId
      }) + 1,
    [object.data?.replies]
  )

  const getSliderIndexById = useCallback(
    (conversationReplyId) => findSliderIndex(conversationReplyId) || 0,
    [findSliderIndex]
  )

  const initialSlide = useMemo(() => {
    return getSliderIndexById(conversationReplyId)
  }, [getSliderIndexById, conversationReplyId])

  const containerHeight = useMemo(
    () =>
      calculateContainerHeight(
        object?.data?.imageAspectRatio,
        getMaxViewportWidth()
      ),
    [object?.data?.imageAspectRatio]
  )

  const handleShareClick = useCallback(() => {
    Tracking.triggerEvent(TrackingEvent.ConversationShared, {
      activityId,
      contentId: (object?.data?.id || object?.id) ?? '',
      replyId:
        getSliderIndexById(conversationReplyId) > 0 ? conversationReplyId : '',
      contentType:
        getSliderIndexById(conversationReplyId) > 0 ? 'reply' : 'conversation',
      contentOwnerId: object?.data?.userId,
      userId: authUser?.id,
      timestamp: formatISO(new Date()),
    })
  }, [
    activityId,
    object?.data?.id,
    object?.data?.userId,
    object?.id,
    getSliderIndexById,
    conversationReplyId,
    authUser?.id,
  ])

  const isCurrentSlide = useCallback(
    (index: number) => (replyIndex || 0) === index,
    [replyIndex]
  )

  return {
    initialSlide,
    containerHeight,
    findSliderIndex,
    getSliderIndexById,
    handleShareClick,
    isCurrentSlide,
  }
}

import type { ReactNode } from 'react'
import { FC, memo } from 'react'

export interface IBadgesListProps {
  level: number
  children: ReactNode
  className?: string
  onClick?: () => void | Promise<void>
}

const TitleComponent: FC<IBadgesListProps> = ({
  level,
  children,
  className,
  onClick,
}) => {
  const CustomTag = `h${level}` as keyof JSX.IntrinsicElements

  return (
    <CustomTag className={className} onClick={onClick}>
      {children}
    </CustomTag>
  )
}

export const Title = memo(TitleComponent)

import {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { isPlatform } from '@ionic/react'

import { ChevronRight } from '@/components/atoms/assets/ChevronRight'
import { Dollar } from '@/components/atoms/assets/Dollar'
import { Logo } from '@/components/atoms/assets/Logo'
import { Modal, ModalRef } from '@/components/molecules/Modal'

import { appConfig } from '@/services/config'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { isMobileWeb } from '@/utils/utils'

interface DepositModalProps {
  onBuyCurrency: () => void
  title: string
}

export interface DepositModalRef {
  closeModal: () => void
  openModal: () => void
}

export const DepositModal = forwardRef(
  ({ onBuyCurrency, title }: DepositModalProps, ref) => {
    const { t } = useTranslation()
    const currency = appConfig.currency

    const navigate = useNavigate()

    const modalRef = useRef<ModalRef>()

    const buyCurrency = useCallback(() => {
      modalRef?.current?.closeModal()
      onBuyCurrency()
    }, [onBuyCurrency])

    const receiveCurrency = useCallback(() => {
      modalRef?.current?.closeModal()
      navigate(Routes.DEPOSIT)
    }, [navigate])

    useImperativeHandle(ref, () => ({
      closeModal: () => modalRef.current.closeModal(),
      openModal: () => modalRef.current.openModal(),
    }))

    return (
      <Modal ref={modalRef}>
        <div className="w-full">
          <p className="font-bold text-lg mt-2">{title}</p>
          <div className="my-12">
            <ReceiveModalButton
              title={t('depositBottomSheet.buyCurrency', { currency })}
              description={t('depositBottomSheet.currencyViaBanxa')}
              icon={<Dollar className="fill-white w-6 h-6" />}
              onClick={buyCurrency}
            />
            <ReceiveModalButton
              title={t('depositBottomSheet.receiveCurrency', { currency })}
              description={t('depositBottomSheet.fromHotWalletOrExchange')}
              icon={<Logo className="fill-white w-6 h-6" />}
              onClick={receiveCurrency}
            />
          </div>
        </div>
      </Modal>
    )
  }
)

DepositModal.displayName = 'DepositModal'

interface ReceiveModalButtonProps {
  title: string
  description: string
  icon?: ReactNode
  onClick: () => void
}

function ReceiveModalButton({
  title,
  description,
  icon,
  onClick,
}: ReceiveModalButtonProps) {
  return (
    <div
      className={`w-full text-left flex-row flex justify-between items-center my-10 ${
        isPlatform('ios') && isMobileWeb() ? '' : 'hover:opacity-60'
      } cursor-pointer`}
      onClick={onClick}
    >
      <div className="flex flex-row w-full">
        {icon && (
          <div>
            <div className="mr-6 border rounded-full p-2 border-2">{icon}</div>
          </div>
        )}

        <div className="flex flex-col grow">
          <div className="flex flex-row justify-between items-center">
            <p className="text-primary font-bold text-3xl">{title}</p>
            <ChevronRight className="w-6 h-6" />
          </div>

          <p className="text-sm text-cool-gray-200">{description}</p>
        </div>
      </div>
    </div>
  )
}

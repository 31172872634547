import { FC } from 'react'

import {
  IStakeTransactionItem,
  ITransactionItem,
} from '@/services/wallet/getTransactions'

import { TransactionsType } from '@/enums'

import { HistoryListDefaultItem } from './HistoryListDefaultItem'
import { HistoryListStakeItem } from './HistoryListStakeItem'

interface HistoryListItemProps {
  item: ITransactionItem | IStakeTransactionItem
}

export const HistoryListItem: FC<HistoryListItemProps> = ({ item }) => {
  switch (item?.type) {
    case TransactionsType.Stake:
    case TransactionsType.StakeRedeem:
      return <HistoryListStakeItem item={item} />
    default:
      return <HistoryListDefaultItem item={item as ITransactionItem} />
  }
}

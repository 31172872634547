import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonTitle } from '@ionic/react'

import { Button } from '@components/atoms/Button'
import { Title } from '@components/atoms/Title'
import { BackButton } from '@/components/atoms/BackButton'
import { Main } from '@/components/templates/main'

import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { WALLET } from '@/utils/route'

export const LivenessFailed: FC = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const header = (
    <>
      <IonButtons slot="start">
        <BackButton defaultHref={WALLET} />
      </IonButtons>
      <IonTitle>
        <Title className="text-center my-0" level={3}>
          {t('livenessCheck.header')}
        </Title>
      </IonTitle>
    </>
  )

  return (
    <Main header={header} isPaddingEnabled={false}>
      <img src="/cross-in-circle.svg" alt="Error icon" className="mx-auto" />
      <Title className="text-center my-7" level={1}>
        {t('livenessCheck.verificationFailed.title')}
      </Title>
      <div className="text-center">
        {t('livenessCheck.verificationFailed.message')}
      </div>
      <Button
        className="w-28 my-7 mx-auto"
        onClick={() => {
          navigate(Routes.FACE_LIVENESS_INSTRUCTIONS)
        }}
      >
        {t('livenessCheck.verificationFailed.tryAgain')}
      </Button>
    </Main>
  )
}

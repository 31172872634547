export const escapeMarkdownTags = (text: string) => {
  if (!text) return null
  return (
    text
      // Replace '# ', '## ', and '### ' with '\\# ', '\\## ', and '\\### ' respectively (h1 to h6 markdown)
      .replace(/(#{1,6}) /g, '\\$1 ')
      // Replace '==', '--' with '\\==', '\\--', respectively (h1 and h2 alternative markdown)
      .replace(/(={2})/g, '\\$1')
      .replace(/(-{2})/g, '\\$1')
      .replace(/\n+/g, '  \n')
  )
}

import { memo } from 'react'

const GridIconComponent = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill-cool-gray-400"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.0625 0.4375H6.25V6.25H0.4375V1.0625C0.4375 0.717322 0.717322 0.4375 1.0625 0.4375ZM0.4375 7.75V12.9375C0.4375 13.2827 0.717322 13.5625 1.0625 13.5625H6.25V7.75H0.4375ZM7.75 7.75V13.5625H12.9375C13.2827 13.5625 13.5625 13.2827 13.5625 12.9375V7.75H7.75ZM13.5625 6.25V1.0625C13.5625 0.717322 13.2827 0.4375 12.9375 0.4375H7.75V6.25H13.5625Z"
      />
    </svg>
  )
}

export const GridIcon = memo(GridIconComponent)

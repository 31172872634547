import { FC, ReactNode, useState } from 'react'

import { Heart } from '@/components/atoms/assets/Heart'

import { useRunDoubleClick } from '@/lib/hooks/useRunDoubleClick'

interface TapToLikeWrapperProps {
  children: ReactNode
  isLikeActive: boolean
  handleDoubleClick: () => void
  handleSingleClick?: () => void | Promise<void>
}

export const TapToLikeWrapper: FC<TapToLikeWrapperProps> = ({
  isLikeActive,
  handleDoubleClick,
  handleSingleClick,
  children,
}) => {
  const [isHeartVisible, setIsHeartVisible] = useState(false)

  async function handleAddReaction() {
    if (isLikeActive) return

    handleDoubleClick()
    setIsHeartVisible(true)
    setTimeout(() => setIsHeartVisible(false), 1000)
  }

  const { onClickCapture } = useRunDoubleClick(
    handleAddReaction,
    handleSingleClick
  )

  return (
    <div className="h-full w-full" onClickCapture={onClickCapture}>
      {children}
      {isHeartVisible && (
        <div
          className={`absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center animate-heartbeat`}
        >
          <Heart />
        </div>
      )}
    </div>
  )
}

import { FC, useCallback, useEffect, useState } from 'react'

import { InfiniteScrollLoader } from '@/components/atoms/InfiniteScrollLoader'
import { Loading } from '@/components/atoms/Loading'
import { Refresher } from '@/components/atoms/Refresher'
import { NoResults } from '@/components/molecules/NoResults'
import { VirtualList } from '@/components/organisms/VirtualList'

import { Category } from '@/services/categories'
import { useActivity } from '@/lib/hooks/useActivity'
import { useFeed } from '@/lib/hooks/useFeed'
import { useHandleVirtuosoScrollToTop } from '@/lib/hooks/useHandleVirtuosoScrollToTop'

import { QueryKeys } from '@/enums'

interface CategoryFeedProps {
  category: Category
}

export const CategoryFeed: FC<CategoryFeedProps> = ({ category }) => {
  const [isFeedEnabled, setIsFeedEnabled] = useState(true)
  const [isAtTop, setIsAtTop] = useState(true)

  const { virtuosoRef } = useHandleVirtuosoScrollToTop()

  const {
    feedData: categoryFeed,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    refetchFirstPage,
    isFetching,
  } = useFeed({
    feedGroup: 'category',
    feedId: category?.id,
    cacheKey: [QueryKeys.CategoryFeed, category?.id],
    ranking: 'popularity',
  })

  const handleRefetchAll = async () => {
    await refetchFirstPage()
  }

  const {
    activeActivityId,
    handleScroll,
    renderActivity,
    scrollSeekConfigurationExit,
    scrollSeekConfigurationEnter,
  } = useActivity({
    feed: categoryFeed,
    isAtTop,
    isFeedFetching: isFetching,
    isFeedEnabled,
  })

  const onEndReached = useCallback(() => {
    hasNextPage && fetchNextPage()
  }, [hasNextPage, fetchNextPage])

  useEffect(() => {
    setIsFeedEnabled(true)

    return () => {
      setIsFeedEnabled(false)
    }
  }, [])
  useEffect(() => setIsFeedEnabled(true), [])

  const CategoryFeedFooter = useCallback(
    () => <InfiniteScrollLoader hasNext={isFetchingNextPage} />,
    [isFetchingNextPage]
  )

  const CategoryFeedItemContent = useCallback(
    (index, items) => renderActivity(items, activeActivityId, index),
    [activeActivityId, renderActivity]
  )

  if (isLoading) return <Loading />

  return (
    <>
      <Refresher disabled={!isAtTop} refetch={handleRefetchAll} />
      {!categoryFeed?.length && !isLoading && <NoResults />}
      <VirtualList
        atTopStateChange={(isAtTop: boolean) => {
          setIsAtTop(isAtTop)
        }}
        className="h-full mx-auto max-w-[40rem] ion-content-scroll-host"
        data={categoryFeed}
        endReached={onEndReached}
        increaseViewportBy={300}
        onScroll={handleScroll}
        components={{
          Footer: CategoryFeedFooter,
        }}
        itemContent={CategoryFeedItemContent}
        virtualListRef={virtuosoRef}
        scrollSeekConfiguration={{
          enter: scrollSeekConfigurationEnter,
          exit: scrollSeekConfigurationExit,
        }}
      />
    </>
  )
}

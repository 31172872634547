import { atUsernamePattern } from '@/utils/validation'

export const matchUsernamesToUserIds = (text = '', mentionIds: UUID[] = []) => {
  let replacedText = text

  mentionIds.forEach((mentionId) => {
    const username = atUsernamePattern.exec(replacedText)?.[1]

    if (!username) return

    replacedText = replacedText.replace(
      atUsernamePattern,
      ` [${username}](user:${mentionId})`
    )
  })

  return replacedText
}

import { FC, memo } from 'react'
import { IonButton, IonIcon, isPlatform } from '@ionic/react'
import cx from 'classnames'
import { chatbubbleEllipsesOutline } from 'ionicons/icons'

import { useNavigate } from '@/lib/routing'
import { compactNumbers } from '@/utils/utils'

interface ICommentsButtonProps {
  activityId: string
  color?: string
  isDisabled?: boolean
  commentsNumber?: number
  commentsNumberPosition?: 'right' | 'bottom'
  className?: string
  onClick?: () => void
  iconSlot?: string
  channel?: string
  page?: string
  participantId?: string
}

const CommentsButtonComponent: FC<ICommentsButtonProps> = ({
  activityId,
  isDisabled = false,
  color = 'success',
  commentsNumber,
  commentsNumberPosition = 'bottom',
  className,
  onClick,
  iconSlot = 'icon-only',
  channel = '',
  page = '',
  participantId = '',
}) => {
  const navigate = useNavigate()

  const handleClick = (e) => {
    e.stopPropagation()
    const search = `?channel=${channel}&page=${page}&participantId=${participantId}`
    navigate(
      `/comments/${activityId}${
        activityId.includes('?') ? search.replace('?', '&') : search
      }`
    )
  }

  return (
    <div
      className={cx('text-center', {
        'items-center flex': commentsNumberPosition === 'right',
      })}
    >
      <IonButton
        className={cx(className, 'flex items-center plain', {
          'flex-col': commentsNumberPosition === 'bottom',
        })}
        disabled={isDisabled}
        onClick={onClick || handleClick}
      >
        <IonIcon
          className="items-center justify-center"
          color={color}
          slot={
            commentsNumber && commentsNumberPosition === 'right'
              ? iconSlot
              : 'icon-only'
          }
          icon={chatbubbleEllipsesOutline}
        ></IonIcon>
      </IonButton>
      {!!commentsNumber && (
        <p
          className={cx('text-xs text-cool-gray-200', {
            '-ml-2': commentsNumberPosition === 'right',
            '-mt-2 mb-2 block': commentsNumberPosition === 'bottom',
            '!-ml-1': isPlatform('ios') && commentsNumberPosition === 'right',
            'ml-0 mt-0':
              isPlatform('ios') && commentsNumberPosition === 'bottom',
          })}
        >
          {compactNumbers(commentsNumber)}
        </p>
      )}
    </div>
  )
}

export const CommentsButton = memo(CommentsButtonComponent)

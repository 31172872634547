import { useState } from 'react'
import reactImageSize from 'react-image-size'

export const useImageSize = () => {
  const [ratio, setRatio] = useState(0)
  const [isLandscape, setIsLandscape] = useState(false)

  const landscapeVideo = async (videoUrl: string) => {
    if (!videoUrl) return
    try {
      const { width, height } = await reactImageSize(videoUrl)
      setIsLandscape(width > height)
      setRatio(width / height)
    } catch (e) {
      console.log('Image is not loaded')
    }
  }

  const getRatio = async (imageUrl: string) => {
    if (!imageUrl) return
    try {
      const { width, height } = await reactImageSize(imageUrl)
      setRatio(width / height)
    } catch (e) {
      console.log('Image is not loaded')
    }
  }

  return {
    ratio,
    getRatio,
    isLandscape,
    landscapeVideo,
  }
}

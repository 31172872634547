import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { App } from '@capacitor/app'

import { MainTitle } from '@components/molecules/MainTitle'
import { InfiniteScrollLoader } from '@/components/atoms/InfiniteScrollLoader'
import { Loading } from '@/components/atoms/Loading'
import {
  ActivityCommentItem,
  ActivityCustomRewardItem,
  ActivityFollowAggregatedItem,
  ActivityFollowItem,
  ActivityLikeItem,
  ActivityMentionItem,
  ActivityReferralItem,
  ActivityRewardedItem,
  ActivityRewardFollowerMilestoneItem,
  ActivityUserGreetingItem,
  ActivityVotesRefilledItem,
  ActivityXPBadgeReachedItem,
} from '@/components/molecules/ActivityNotifications'
import { ActivityRewardReferralMilestoneRpkItem } from '@/components/molecules/ActivityNotifications/ActivityRewardReferralMilestoneRpkItem'
import { ActivitySubscriptionCancelledInsufficientFunds } from '@/components/molecules/ActivityNotifications/ActivitySubscriptionCancelledInsufficientFunds'
import { VirtualList } from '@/components/organisms/VirtualList'
import { Entry } from '@/components/templates/entry'

import { useActivityNotificationsFeed } from '@/lib/hooks/useActivityNotificationsFeed'
import { useHandleVirtuosoScrollToTop } from '@/lib/hooks/useHandleVirtuosoScrollToTop'
import { useLocation } from '@/lib/routing'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { neverReturn } from '@/utils/utils'

import { Pages } from '@enums'

import { ActivityTypeEnum } from '@/types/activity'

App.addListener('resume', () => {
  const pathname = window.location.pathname
  pathname === `/${Pages.Activity}` &&
    Tracking.triggerEvent(TrackingEvent.Activity)
})

export const Activity: FC = () => {
  const location = useLocation()
  const { pathname = '' } = location
  useEffect(() => {
    pathname === `/${Pages.Activity}` &&
      Tracking.triggerEvent(TrackingEvent.Activity)
  }, [pathname])
  const [isRefetchActive, setRefetchActive] = useState(true)
  const { t } = useTranslation('common')
  const { virtuosoRef, scrollToTop } = useHandleVirtuosoScrollToTop()

  const {
    notifications,
    isFetching,
    isLoading: isFeedLoading,
    fetchNextPage,
    refetchFirstPage,
    hasNextPage,
  } = useActivityNotificationsFeed()

  /*
   * This function is used to render the activity notification item
   * based on the verb of the notification.
   *
   * Make sure, you add the verb to the allowedActivityNotificationVerbs array, other ways
   * the notification will not be rendered and virtuoso will throw an error:
   * "react-virtuoso: Zero-sized element, this should not happen {child: div}"
   *
   * Note: returning null, '' or <><> will result in the same error
   */

  const renderActivityNotification = useCallback((item): JSX.Element => {
    switch (item.type) {
      case ActivityTypeEnum.UserGreeting:
        return <ActivityUserGreetingItem item={item} key={item.id} />
      case ActivityTypeEnum.RewardAccountEmailVerified:
      case ActivityTypeEnum.RewardAccountPhoneVerified:
      case ActivityTypeEnum.RewardGoalVotesPerDayReached:
      case ActivityTypeEnum.RewardGoalLikesReceived:
      case ActivityTypeEnum.RewardGoalLikesGiven:
      case ActivityTypeEnum.RewardGoalAppOpenedDaily:
      case ActivityTypeEnum.RewardContestContestantJoined:
      case ActivityTypeEnum.RewardGoalAppOpenedStreak:
      case ActivityTypeEnum.RewardCommunityContribution:
      case ActivityTypeEnum.RewardCommunityModeration:
        return <ActivityRewardedItem item={item} key={item.id} />
      case ActivityTypeEnum.RewardMilestoneBadgeReached:
        return <ActivityXPBadgeReachedItem item={item} key={item.id} />
      case ActivityTypeEnum.RewardCustom:
        return <ActivityCustomRewardItem item={item} key={item.id} />
      case ActivityTypeEnum.RewardMilestoneReferralRpkReached:
        return (
          <ActivityRewardReferralMilestoneRpkItem item={item} key={item.id} />
        )
      case ActivityTypeEnum.RewardMilestoneFollowerCount:
        return <ActivityRewardFollowerMilestoneItem item={item} key={item.id} />
      case ActivityTypeEnum.RewardGoalVotesPerDayRefilled:
        return <ActivityVotesRefilledItem key={item.id} />
      case ActivityTypeEnum.RewardReferralSuccessful:
      case ActivityTypeEnum.RewardInviteAccepted:
        return <ActivityReferralItem item={item} key={item.id} />
      case ActivityTypeEnum.UserFollowedAggregated:
        return <ActivityFollowAggregatedItem key={item.id} item={item} />
      case ActivityTypeEnum.MentionedInComment:
      case ActivityTypeEnum.MentionedInPost:
      case ActivityTypeEnum.MentionedInConversation:
        return <ActivityMentionItem item={item} key={item.id} />
      case ActivityTypeEnum.ReactionLikeConversation:
      case ActivityTypeEnum.ReactionLikePost:
        return <ActivityLikeItem item={item} key={item.id} />
      case ActivityTypeEnum.ReactionCommentPost:
      case ActivityTypeEnum.ReactionCommentConversation:
        return <ActivityCommentItem item={item} key={item.id} />
      case ActivityTypeEnum.UserFollow:
        return <ActivityFollowItem item={item} key={item.id} />
      case ActivityTypeEnum.SubscriptionCancelledInsufficientFunds:
        return (
          <ActivitySubscriptionCancelledInsufficientFunds
            item={item}
            key={item.id}
          />
        )
      default:
        neverReturn()
    }
  }, [])

  return (
    <Entry
      header={
        <MainTitle onClick={scrollToTop}>{t('activity.title')}</MainTitle>
      }
      refetch={refetchFirstPage}
      isRefetchActive={isRefetchActive}
      onActiveNavIconPress={scrollToTop}
      hasData={!!notifications?.length}
      isPaddingEnabled={false}
    >
      {isFetching && !notifications?.length && <Loading />}
      {!isFeedLoading ? (
        <VirtualList
          atTopStateChange={(isAtTop: boolean) => {
            setRefetchActive(isAtTop)
          }}
          className="h-full mx-auto max-w-[40rem] ion-content-scroll-host"
          data={notifications}
          endReached={() => fetchNextPage()}
          components={{
            Footer: () => <InfiniteScrollLoader hasNext={hasNextPage} />,
          }}
          itemContent={(index) =>
            renderActivityNotification(notifications[index])
          }
          virtualListRef={virtuosoRef}
        />
      ) : (
        <Loading />
      )}
    </Entry>
  )
}

import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActionSheetButton,
  IonActionSheet,
  IonIcon,
  isPlatform,
} from '@ionic/react'
import { ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons'

import { Popover } from '@/components/molecules/Popover'

import { CommentActionType } from '@/enums'

interface ICommentsActionsProps {
  onDelete?: (id: UUID) => void
  commentId: UUID
}

export const CommentsActions: FC<ICommentsActionsProps> = ({
  onDelete,
  commentId,
}) => {
  const [showReportActionSheet, setShowReportActionSheet] = useState(false)
  const [popover, setPopover] = useState({
    isVisible: false,
    message: null,
    type: undefined,
  })
  const { t } = useTranslation('common')

  const togglePopover = (message: string, type: CommentActionType) => {
    setPopover({ isVisible: true, message, type })
  }

  const handlePopoverAction = async () => {
    try {
      switch (popover.type) {
        case CommentActionType.Delete:
          onDelete(commentId)
      }

      setPopover({ isVisible: false, message: null, type: undefined })
    } catch (error) {
      console.log(error)
    }
  }

  const actionSheetButtons = (): ActionSheetButton[] => {
    return [
      {
        text: t('report.deleteComment.buttonLabel'),
        role: 'destructive',
        data: {
          type: 'delete',
        },
        handler: () =>
          togglePopover(
            t('report.deleteComment.confirmationMessage'),
            CommentActionType.Delete
          ),
      },
      {
        text: t('report.cancel'),
        role: 'cancel',
      },
    ]
  }

  return (
    <>
      <Popover
        type="danger"
        isOpen={popover.isVisible}
        onDismiss={() => {
          setPopover({ isVisible: false, message: null, type: undefined })
        }}
        onDismissClassName="normal-case order-2"
        onConfirmClassName="normal-case text-red-500 order-1"
        actionText={t('no')}
        confirmText={t('yes')}
        onConfirm={handlePopoverAction}
      >
        {popover.message}
      </Popover>
      <IonIcon
        className={isPlatform('android') ? 'text-md' : 'text-xl'}
        color="primary"
        slot="icon-only"
        icon={isPlatform('android') ? ellipsisVertical : ellipsisHorizontal}
        onClick={(e) => {
          e.stopPropagation()
          setShowReportActionSheet(true)
        }}
      />
      <IonActionSheet
        isOpen={showReportActionSheet}
        mode={isPlatform('ios') ? 'ios' : 'md'}
        onDidDismiss={() => setShowReportActionSheet(false)}
        buttons={actionSheetButtons()}
      />
    </>
  )
}

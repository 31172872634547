import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { queryClient } from '@/lib/queryClient'

import { QueryKeys } from '@/enums'

export const useHandleCheckTokenStatus = (userId: UUID) => {
  const { refetch } = useUserInfo({ userId })

  const { data: isLaunchTokenSuccess } = useQuery({
    queryKey: [QueryKeys.LaunchTokenSuccess],
  })

  useEffect(() => {
    let interval: NodeJS.Timeout

    const asyncWrapper = async () => {
      const result = await refetch()

      if (result.data.creatorTokenId) {
        clearInterval(interval)
        queryClient.removeQueries({ queryKey: [QueryKeys.LaunchTokenSuccess] })
      }
    }

    if (isLaunchTokenSuccess) {
      interval = setInterval(asyncWrapper, 10000)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [isLaunchTokenSuccess, refetch])
}

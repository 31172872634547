import { useEffect, useState } from 'react'

const getViewport = () => ({
  visualViewport: {
    width: window.visualViewport.width,
    height: window.visualViewport.height,
  },
})

export const useVisualViewport = () => {
  const [state, setState] = useState(getViewport)

  useEffect(() => {
    const handleResize = () => setState(getViewport)

    window.visualViewport.addEventListener('resize', handleResize)

    return () =>
      window.visualViewport.removeEventListener('resize', handleResize)
  }, [])

  return state
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  ISubscribedCategoriesResult,
  subscribeCategory,
} from '@/services/categories'

export const useSubscribeCategory = (
  options?: UseMutationOptions<ISubscribedCategoriesResult, unknown, UUID[]>
) => useMutation({ mutationFn: subscribeCategory, ...options })

import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from '@/components/atoms/Avatar'
import { AvatarGroup } from '@/components/atoms/AvatarGroup'
import { Button } from '@/components/atoms/Button'

import { TopSupporter } from '@/services/top-supporters'
import { useNavigate } from '@/lib/routing'

const getTopSupportersButtonPadding = (
  supportersNumber: number
): 'ml-8' | 'ml-10' | 'ml-12' => {
  if (supportersNumber === 1) return 'ml-8'
  if (supportersNumber === 2) return 'ml-10'
  return 'ml-12'
}

interface TopSupportersButtonProps {
  topSupporters: TopSupporter[]
  userId: string
}

export const TopSupportersButton = memo(
  ({ topSupporters, userId }: TopSupportersButtonProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
      <div className="my-2">
        <div className="absolute z-30">
          <AvatarGroup>
            {topSupporters.map((supporter) => (
              <div key={supporter.id} className="w-10">
                <Avatar
                  userId={supporter.id}
                  isAmbassador={supporter.isAmbassador}
                  linkTo={`/top-supporters/${userId}`}
                  clickable
                />
              </div>
            ))}
          </AvatarGroup>
        </div>
        <Button
          className="ml-2 !h-[2.36rem]"
          fill="outline"
          color="primary"
          onClick={(e) => {
            e.stopPropagation()
            navigate(`/top-supporters/${userId}`)
          }}
        >
          <p className={getTopSupportersButtonPadding(topSupporters.length)}>
            {t('topSupporters.title')}
          </p>
        </Button>
      </div>
    )
  }
)

TopSupportersButton.displayName = 'TopSupportersButton'

import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonIcon } from '@ionic/react'
import { alertCircle } from 'ionicons/icons'

import { appConfig } from '@/services/config'
import { toTokenString } from '@/utils/token'

type ImportantWarningProps = {
  minDepositAmount: string
}

export const ImportantWarning: FC<ImportantWarningProps> = ({
  minDepositAmount,
}) => {
  const { t } = useTranslation('common')

  return (
    <div className="mt-10">
      <div className="flex flex-row mb-3">
        <IonIcon
          icon={alertCircle}
          color="danger"
          style={{ fontSize: '22px' }}
        />
        <span className="ml-2 font-bold">{t('deposit.warningTitle')}</span>
      </div>
      <Trans>
        <span className="text-sm">
          {t('deposit.warningDescription', {
            currency: appConfig.currency,
            minDepositAmount: toTokenString(minDepositAmount),
          })}
        </span>
      </Trans>
    </div>
  )
}

import { Trans, useTranslation } from 'react-i18next'

export const EmptySubscription = () => {
  const { t } = useTranslation('common')

  return (
    <div className="px-2">
      <h1 className="pb-4 text-[1.675rem]">
        <Trans i18nKey="profile.subscription.subscriptionReadyTitle">
          {t('profile.subscriptionReadyTitle')}
        </Trans>
      </h1>
      <p className="text-[1rem] text-sm">
        <Trans i18nKey="profile.subscription.subscriptionReadyBody">
          {t('profile.subscription.subscriptionReadyBody')}
        </Trans>
      </p>
      <p className="text-primary text-sm pt-6">
        {t('profile.subscription.subscriptionReadyTip')}
      </p>
    </div>
  )
}

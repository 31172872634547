import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { XPMultiplierList } from '@/components/pages/XPWallet/components/XPMultiplierList'

import { useAccountInfo } from '@/services/account'
import { appConfig } from '@/services/config'

export const XPMultipliers: FC = () => {
  const { t } = useTranslation('common')
  const { data: accountInfo } = useAccountInfo()

  return (
    <div className="flex flex-col bg-cool-gray-800 m-2 p-7 rounded-lg text-white">
      <div className="flex flex-col px-3 md:pl-6 md:pr-8">
        <span className="text-[6vw] xs:text-2xl font-bold mb-3">
          {t('earnMoreModal.title')}
        </span>
        <span className="mb-3 text-[4vw] xs:text-base">
          <Trans
            i18nKey="wallet.stakeVault.xpMultipliers.description"
            components={{
              highlight: <span className="font-bold italic" />,
            }}
          >
            {t('earnMoreModal.description', {
              currency: appConfig.currency,
            })}
          </Trans>
        </span>
      </div>
      <XPMultiplierList multipliers={accountInfo?.stakedRpkForXpMultiplier} />
    </div>
  )
}

import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButton, IonButtons, IonIcon, IonTitle } from '@ionic/react'

import { Main } from '@components/templates/main'
import { BackButton } from '@/components/atoms/BackButton'
import { InfiniteScrollLoader } from '@/components/atoms/InfiniteScrollLoader'
import { Loading } from '@/components/atoms/Loading'
import { Title } from '@/components/atoms/Title'
import { UserName } from '@/components/atoms/UserName'
import { ListItem } from '@/components/molecules/ListItem'
import { NoResults } from '@/components/molecules/NoResults'
import { VirtualList } from '@/components/organisms/VirtualList'
import TopSupportersTip from './components/TopSupportersTip'

import { appConfig } from '@/services/config'
import { TopSupporter } from '@/services/top-supporters'
import { useGetTopSupporters } from '@/lib/hooks/useGetTopSupporters'
import { useHandleVirtuosoScrollToTop } from '@/lib/hooks/useHandleVirtuosoScrollToTop'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Link, useParams } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { numberFormat } from '@/utils/currency'

export const TopSupportersPage: FC = () => {
  const { t } = useTranslation('common')
  const [isRefetchActive, setRefetchActive] = useState(true)
  const { userId } = useParams<{ userId: UUID }>()

  const { data: authUser, isLoading: isLoadingUserInfo } = useUserInfo()
  const { data: user } = useUserInfo({ userId })

  const isAuthUser = useMemo(() => {
    return userId === authUser?.id
  }, [userId, authUser])

  const {
    data: usersData,
    hasNextPage: hasUsersDataNextPage,
    fetchNextPage: fetchUsersDataNextPage,
    isLoading: isUsersListLoading,
    refetch: refetchUsersData,
  } = useGetTopSupporters(userId)

  const { virtuosoRef } = useHandleVirtuosoScrollToTop()
  const isAuthUserTopSupporter = useMemo(
    () => !!usersData.supporters.find((item) => item.id === authUser.id),
    [usersData, authUser.id]
  )

  const header = (
    <>
      <IonButtons slot="start">
        <BackButton className="text-white" defaultHref={Routes.FEED} />
      </IonButtons>
      <IonTitle>
        <Title level={2}>{t('common.head.title.topSupporters')}</Title>
      </IonTitle>
      <IonButtons slot="end">
        <IonButton>
          <IonIcon slot="icon-only" />
        </IonButton>
      </IonButtons>
    </>
  )

  return (
    <Main
      headTitle={t('topSupporters.title')}
      header={header}
      contentClassName="h-full"
      refetch={refetchUsersData}
      isRefetchActive={isRefetchActive}
      isPaddingEnabled={false}
    >
      {isUsersListLoading || isLoadingUserInfo ? (
        <Loading />
      ) : (
        <>
          {!usersData?.supporters?.length && !isUsersListLoading && (
            <div className="mx-auto max-w-[40rem]">
              <NoResults />
            </div>
          )}

          <VirtualList
            atTopStateChange={setRefetchActive}
            className="h-full max-w-[40rem] ion-content-scroll-host"
            data={usersData?.supporters}
            endReached={() => fetchUsersDataNextPage()}
            components={{
              Header: () => (
                <div className="flex py-3">
                  <p className="w-[70px] flex-shrink-0 uppercase text-center text-cool-gray-200">
                    {t('place')}
                  </p>
                  <p className="flex-grow uppercase text-cool-gray-200 ml-2">
                    {t('username')}
                  </p>
                  {isAuthUser && (
                    <p className="uppercase text-cool-gray-200 ml-2 text-right pr-3">
                      {t('income')}
                    </p>
                  )}
                </div>
              ),
              Footer: () => (
                <div>
                  {!isAuthUser && (
                    <TopSupportersTip
                      userId={user?.id}
                      username={user?.username}
                      text={
                        isAuthUserTopSupporter
                          ? 'topSupporters.alreadyAmongTopSupporters'
                          : 'topSupporters.tipDescription'
                      }
                      entryCurrencyAmount={
                        isAuthUserTopSupporter ? 1 : usersData?.entryAmount
                      }
                    />
                  )}
                  <InfiniteScrollLoader hasNext={hasUsersDataNextPage} />
                </div>
              ),
            }}
            itemContent={(index) => {
              const item = usersData.supporters[index]

              return (
                <div className="flex items-center justify-left mr-3 w-full">
                  <p className="border-r border-cool-gray-700 text-center py-3 w-[60px] flex-shrink-0 text-lg font-bold">
                    {index + 1}
                  </p>

                  <ListItem
                    key={item.id}
                    item={item}
                    overflowClass="overflow-hidden"
                    className="flex-grow"
                  >
                    <div className="flex flex-wrap items-center justify-between pr-2 overflow-hidden">
                      <Link
                        className="max-w-[100%] overflow-hidden"
                        to={`/@${item.username}`}
                      >
                        <UserName
                          className="block font-medium text-sm"
                          name={item.username}
                        />

                        <span className="text-xs text-ellipsis overflow-hidden block">
                          {item.displayName}
                        </span>
                      </Link>
                    </div>
                  </ListItem>

                  {isAuthUser && (
                    <p className="text-center py-3 flex-shrink-0 text-sm text-right font-bold pr-3 text-nowrap">
                      {formatTippedAmount(item)}
                    </p>
                  )}
                </div>
              )
            }}
            virtualListRef={virtuosoRef}
          />
        </>
      )}
    </Main>
  )
}

const formatTippedAmount = (item: TopSupporter) =>
  // eslint-disable-next-line republik/no-rpk-variable
  `${numberFormat(item.totalRPK)} ${appConfig.currency}`

import { Dispatch, FC, memo, SetStateAction, useMemo, useState } from 'react'
import LazyLoad from 'react-lazy-load'
import { IonIcon } from '@ionic/react'
import { imageOutline } from 'ionicons/icons'

import { calculateImageSpace } from '@/utils/utils'

import { Zoomable } from '../Zoomable'

interface LazyImgProps {
  src: string
  imageAspectRatio?: number
  isLazy?: boolean
  setIsZooming?: Dispatch<SetStateAction<boolean>>
}

const LazyImgComponent: FC<LazyImgProps> = ({
  src,
  imageAspectRatio = 1,
  isLazy = true,
  setIsZooming,
}) => {
  const CustomTag = isLazy ? LazyLoad : 'div'
  const [hasError, setHasError] = useState(false)

  const style = useMemo(
    () => ({
      paddingBottom: calculateImageSpace(imageAspectRatio),
    }),
    [imageAspectRatio]
  )

  if (hasError) {
    return (
      <CustomTag style={style}>
        <span className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <IonIcon icon={imageOutline} className="xxl" />
        </span>
      </CustomTag>
    )
  }

  return (
    <Zoomable setIsZooming={setIsZooming}>
      <CustomTag style={style}>
        <img
          className="select-none pointer-events-none absolute left-1/2 top-1/2 !w-full h-auto object-contain -translate-y-1/2 -translate-x-1/2"
          src={src}
          onError={() => setHasError(true)}
        />
      </CustomTag>
    </Zoomable>
  )
}

export const LazyImg = memo(LazyImgComponent)

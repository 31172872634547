import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButton, IonButtons, IonIcon, IonTitle } from '@ionic/react'
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query'

import { BackButton } from '@components/atoms/BackButton'
import { Title } from '@components/atoms/Title'
import { UserRelationsList } from '@components/organisms/UserRelationsList'
import { Entry } from '@components/templates/entry'

import { useFindUserRelations, UserRelationsData } from '@/services/relations'
import { useRouterQuery } from '@lib/hooks/useRouterQuery'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useNavigate, useParams } from '@/lib/routing'

import { UserRelations as UserRelationsEnum } from '@enums'

export const UserRelations: FC = () => {
  const { userId } = useParams<{ userId: string }>()

  const query = useRouterQuery()
  const navigate = useNavigate()

  const { t } = useTranslation('common')
  const [isRefetchActive, setRefetchActive] = useState(true)

  const computedTabIndex = query.get(UserRelationsEnum.Following) ? 0 : 1

  const [tabIndex, setTabIndex] = useState(computedTabIndex)

  const { data: userInfo } = useUserInfo({ userId })

  const {
    data: usersData,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetchFirstPage,
  } = useFindUserRelations(userId, {
    q: '',
    lastKey: '',
    followers: tabIndex === 1,
  })

  const onSegmentClick = (e) => {
    setTabIndex(Number(e.detail.value))
    navigate(
      `?${
        tabIndex === 1
          ? UserRelationsEnum.Following
          : UserRelationsEnum.Followers
      }=true`,
      {
        replace: true,
      }
    )
  }

  const header = (
    <>
      <IonButtons slot="start">
        <BackButton
          className="text-white"
          defaultHref={`/@${userInfo?.username}`}
        />
      </IonButtons>
      <IonTitle>
        <Title level={2}>
          {userInfo?.displayName ||
            (userInfo?.username ? `@${userInfo?.username}` : '')}
        </Title>
      </IonTitle>
      <IonButtons slot="end">
        <IonButton>
          <IonIcon slot="icon-only" />
        </IonButton>
      </IonButtons>
    </>
  )

  return (
    <Entry
      header={header}
      headTitle={t('common.head.title.relations')}
      isRefetchActive={isRefetchActive}
      refetch={refetchFirstPage}
      hasData={!!usersData?.pages?.[0]?.length}
      isPaddingEnabled={false}
    >
      <UserRelationsList
        followers={tabIndex === 1}
        setRefetchActive={setRefetchActive}
        usersData={usersData}
        isLoading={isLoading}
        fetchNextPage={
          fetchNextPage as (
            options?: FetchNextPageOptions
          ) => Promise<InfiniteQueryObserverResult<UserRelationsData, unknown>>
        }
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        tabIndex={tabIndex}
        onSegmentClick={onSegmentClick}
      />
    </Entry>
  )
}

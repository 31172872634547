import { GridIcon } from '@/components/atoms/assets/GridIcon'
import { SliderIcon } from '@/components/atoms/assets/SliderIcon'

type buttonType = 'grid' | 'slider'

export const ViewSwitchButton = ({
  onClick,
  type,
}: {
  onClick: () => void
  type: buttonType
}) => {
  return (
    <button
      className="w-7 h-7 rounded-full bg-white flex items-center justify-center"
      disabled={false}
      color="light"
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      {type === 'grid' ? <SliderIcon /> : <GridIcon />}
    </button>
  )
}

import { FC, memo } from 'react'

import { PresentBox } from '@/components/atoms/PresentBox'
import { StreakProgressCircle } from '@/components/atoms/StreakProgressCircle'

type StreakProgressProps = {
  className?: string
  streakDay: number
  streakDuration: number
}

const StreakProgressComponent: FC<StreakProgressProps> = ({
  className = '',
  streakDay = 1,
  streakDuration = 7,
}) => (
  <div
    className={`my-4 w-full text-center grid grid-cols-7 gap-[3%] items-end ${className}`}
  >
    {[...Array(streakDuration - 1).keys()].map((index) => (
      <StreakProgressCircle
        key={index}
        number={index + 1}
        isChecked={index + 1 <= streakDay}
      />
    ))}
    <PresentBox isChecked={streakDay >= streakDuration} />
  </div>
)

export const StreakProgress = memo(StreakProgressComponent)

import { getThumbnailImage } from '@/utils/url'

import { ActivityFeedVerb } from '@/enums'
import { IReply } from '@/interfaces'

const MAX_GRID_ITEM_HEIGHT = 284

export const getActivityThumbnail = (activity) => {
  const verb = activity?.object?.verb || activity?.verb
  if (!verb) return

  let image: string
  switch (verb) {
    case ActivityFeedVerb.ContestCreate:
    case ActivityFeedVerb.ContestParticipate:
      image = activity.videoThumbnail
      break
    case ActivityFeedVerb.Post:
      image = (
        activity.object.data?.media?.[0]?.imageUrl ||
        activity.object.attachments?.images?.[0] ||
        ''
      ).replace('x2.jpeg', 'x1.jpeg')
      break
    case ActivityFeedVerb.StartedConversation:
    case ActivityFeedVerb.RepliedToConversation: {
      const replyId = activity?.replyId

      const replies: IReply[] = activity.object.data?.replies || []
      const imageUrl =
        replies.find((reply) => reply.id === replyId)?.imageUrl ||
        activity.object.data?.imageUrl

      image = imageUrl && getThumbnailImage(imageUrl)
      break
    }
  }

  if (!image && typeof activity.object === 'string') {
    const oldTypeObject: string = activity.object

    if (oldTypeObject?.startsWith('http')) image = oldTypeObject
    if (oldTypeObject?.startsWith('{')) {
      const parsedObject = JSON.parse(oldTypeObject)
      image = parsedObject.url
    }
  }

  // Some old activities have images come  straight from the GetStream in big
  // size. Here we're replacing the default image size with the size we need.
  if (image?.includes('fit=clip&h=')) {
    const regex = /(&fit=clip&h=)(\d+)(&w=)(\d+)$/
    image = image.replace(
      regex,
      '$1' + MAX_GRID_ITEM_HEIGHT + '$3' + MAX_GRID_ITEM_HEIGHT
    )
  }

  return image
}

import { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonItem } from '@ionic/react'

import { Avatar } from '@components/atoms/Avatar'
import { UserName } from '@/components/atoms/UserName'

import { appConfig } from '@/services/config'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useNavigate } from '@/lib/routing'
import { formatDistance } from '@utils/date'
import { WALLET_SEARCH_XP } from '@/utils/route'

import { Badge } from '@/enums'

import { ActivityRewardBadgeMilestoneReached } from '@/types/activity/reward'

interface IActivityXPBadgeReachedItemProps {
  item: ActivityRewardBadgeMilestoneReached
}

export const ActivityXPBadgeReachedItem: FC<
  IActivityXPBadgeReachedItemProps
> = ({ item }) => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { data: authUser } = useUserInfo()

  const handleOnClick = () => navigate(WALLET_SEARCH_XP)
  const {
    data: { badge, milestone },
  } = item

  const badgeType = useMemo(() => {
    switch (badge) {
      case Badge.BronzeXPMilestoneBadge:
        return 'bronze'
      case Badge.SilverXPMilestoneBadge:
        return 'silver'
      case Badge.GoldXPMilestoneBadge:
        return 'gold'
      case Badge.DiamondXPMilestoneBadge:
        return 'diamond'
    }
  }, [badge])

  const XPBadge = useCallback(() => {
    const badgeMap = {
      [Badge.BronzeXPMilestoneBadge]: {
        src: '/xp_badges/bronze.png',
        className: 'w-[2.625rem] h-auto',
      },
      [Badge.SilverXPMilestoneBadge]: {
        src: '/xp_badges/silver.png',
        className: 'w-[2.875rem] h-auto',
      },
      [Badge.GoldXPMilestoneBadge]: {
        src: '/xp_badges/golden.png',
        className: 'w-[3.375rem] h-auto',
      },
      [Badge.DiamondXPMilestoneBadge]: {
        src: '/xp_badges/diamond.png',
        className: 'w-[3.25rem] h-auto',
      },
    }
    const badgeData = badgeMap[badge]

    if (!badgeData) return null

    return (
      <img src={badgeData.src} className={`h-auto ${badgeData.className}`} />
    )
  }, [badge])

  return (
    <IonItem className="py-4 border-solid border-b-[0.0625rem] border-zinc-800">
      <span className="pr-4">
        <Avatar userId={appConfig.republikUserId} clickable isAmbassador />
      </span>
      <div
        className="w-full flex justify-between items-center cursor-pointer"
        onClick={handleOnClick}
      >
        <div className="flex flex-col justify-between mr-4">
          <div className="text-sm">
            <Trans
              i18nKey="activity.item.xpBadgeReached.message"
              components={{
                userLink: (
                  <UserName
                    className="inline font-bold"
                    userId={authUser.id}
                    name={authUser.username}
                  />
                ),
              }}
              values={{ milestone, badgeType }}
            >
              {t('activity.item.xpBadgeReached.message', {
                milestone,
                badgeType,
              })}
            </Trans>
          </div>
          <div className="text-xs text-zinc-600">
            {formatDistance(item.time)}
          </div>
        </div>
        <XPBadge />
      </div>
    </IonItem>
  )
}

import { post } from '@/services/awsAmplify'

import { StakeStatus } from '@/enums'

export interface IReStake {
  id: UUID
  ruleId: string
  userId: UUID
  username: string
  amount: string
  apyInPercentage: number
  durationInDays: number
  stakeStatus: StakeStatus.Locked
  stakeDate: string
  releaseDate: string
  parentStakeId: string
}

export const reStake = ({ stakeId }: { stakeId: string }): Promise<IReStake> =>
  post({
    path: `/stakes/${stakeId}/restake`,
  }) as unknown as Promise<IReStake>

import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react'

import { MarkdownUrlHighlighter } from '@/components/molecules/MarkdownUrlHighlighter'

import { getLanguage } from '@/utils/getLanguage'

interface TermsAndConditionsProps {
  onDismiss: (role?: 'cancel' | 'confirm') => void
}

export const TermsAndConditions: FC<TermsAndConditionsProps> = ({
  onDismiss,
}) => {
  const contentRef = useRef<HTMLIonContentElement>()
  const markdownRef = useRef<HTMLDivElement>()
  const [content, setContent] = useState(null)
  const { t } = useTranslation('common')
  const [confirmButtonActive, setConfirmButtonActive] = useState(false)

  useEffect(() => {
    import(`./content/${getLanguage()}.json`)
      .then((res) => setContent(res.default))
      .catch(() => {
        import(`./content/en.json`).then((res) => setContent(res.default))
      })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (
        contentRef.current.clientHeight >= markdownRef.current.clientHeight &&
        markdownRef.current.clientHeight !== 0
      ) {
        setConfirmButtonActive(true)
      }
    }, 100)
  }, [])

  return (
    <>
      <IonHeader>
        <IonToolbar color="dark">
          <IonTitle className="font-bold">
            {t('common.acceptableUsePolicy')}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonFooter className="border-t border-cool-gray-800">
        <IonToolbar color="dark">
          <IonButtons slot="start">
            <IonButton onClick={() => onDismiss('cancel')} color="danger">
              {t('common.cancel')}
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              disabled={!confirmButtonActive}
              strong={true}
              color="success"
              onClick={() => onDismiss('confirm')}
            >
              {t('common.agree')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
      <IonContent
        fullscreen
        className="ion-padding p-10"
        scrollEvents
        onIonScrollEnd={() => setConfirmButtonActive(true)}
        ref={contentRef}
      >
        <div ref={markdownRef}>
          <MarkdownUrlHighlighter
            text={content?.text}
            className="terms-and-conditions-modal-content"
          />
        </div>
      </IonContent>
    </>
  )
}

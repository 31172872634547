import { FC, memo } from 'react'

export interface ReceivedIconComponentProps {
  className?: string
}

const ReceivedIconComponent: FC<ReceivedIconComponentProps> = ({
  className,
}) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse className="fill-primary" cx="14" cy="14" rx="14" ry="14" />
      <path
        className="fill-black"
        d="M20 9C20 8.44771 19.5523 8 19 8L10 8C9.44772 8 9 8.44771 9 9C9 9.55228 9.44772 10 10 10L18 10L18 18C18 18.5523 18.4477 19 19 19C19.5523 19 20 18.5523 20 18L20 9ZM8.70711 20.7071L19.7071 9.70711L18.2929 8.29289L7.29289 19.2929L8.70711 20.7071Z"
      />
    </svg>
  )
}

export const ReceivedIcon = memo(ReceivedIconComponent)

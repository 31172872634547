import { QueryClient } from '@tanstack/react-query'
import { compress } from 'lz-string'

import { customDehydrate } from './customDehydrate'

export const serialize = (queryClient: QueryClient, data) => {
  const replacer = (key: string, value) => {
    if ((key === 'gcTime' || key === 'staleTime') && value === Infinity) {
      return 'Infinity'
    }

    return value
  }
  const dehydratedState = customDehydrate(queryClient)
  const clientData = {
    ...data,
    clientState: dehydratedState,
  }

  return compress(JSON.stringify(clientData, replacer))
}

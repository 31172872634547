import { FC, memo, useMemo } from 'react'

import { useNavigate } from '@/lib/routing'

import { ShieldIcon } from '../assets/ShieldIcon'

const MaxNameLength = 24

interface UserNameProps {
  name?: string
  userId?: string
  small?: boolean
  medium?: boolean
  className?: string
  isAmbassador?: boolean
  isModerator?: boolean
}

const UserNameComponent: FC<UserNameProps> = ({
  name = '',
  small = false,
  medium,
  className = '',
  userId,
  isAmbassador = false,
  isModerator = false,
}) => {
  const navigate = useNavigate()
  const truncatedName = useMemo(
    () =>
      name && name.length > MaxNameLength
        ? `${name.slice(0, MaxNameLength)}...`
        : name,
    [name]
  )

  const shieldBadge = () => {
    return (
      (isAmbassador || isModerator) && (
        <ShieldIcon
          className="pl-1 w-5 h-5 float-right"
          {...(isModerator ? { color: '#FFFFFF' } : {})}
        />
      )
    )
  }

  return userId ? (
    <div
      className={`block text-ellipsis overflow-hidden text-white ${
        (small && 'text-xs') || (medium && 'text-base')
      } ${userId ? 'cursor-pointer' : ''} ${className}`}
      onClick={(event) => {
        event.stopPropagation()
        navigate(`/@${name}`)
      }}
    >
      {!!name && `@${truncatedName}`} {shieldBadge()}
    </div>
  ) : (
    <span
      className={`block text-ellipsis overflow-hidden text-white ${
        (small && 'text-xs') || (medium && 'text-base')
      } ${userId ? 'cursor-pointer' : ''} ${className}`}
    >
      {!!name && `@${truncatedName}`} {shieldBadge()}
    </span>
  )
}

export const UserName = memo(
  UserNameComponent,
  (prev, next) =>
    !(
      (prev.small !== next.small && prev.medium !== next.medium) ||
      prev.name !== next.name ||
      prev.userId !== next.userId ||
      prev.isAmbassador !== next.isAmbassador
    )
)

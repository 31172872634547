import { FC, memo } from 'react'

type InfoIconProps = {
  className?: string
}

const InfoIconComponent: FC<InfoIconProps> = ({ className = '' }) => (
  <svg
    className={`w-8 ${className}`}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1041_2082)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.745117 10.9077C0.745117 5.39374 5.23117 0.907684 10.7451 0.907684C16.2591 0.907684 20.7451 5.39374 20.7451 10.9077C20.7451 16.4216 16.2591 20.9077 10.7451 20.9077C5.23117 20.9077 0.745117 16.4216 0.745117 10.9077ZM9.52637 6.18112C9.52637 6.82956 10.0576 7.36862 10.7451 7.36862C11.4326 7.36862 11.9639 6.82956 11.9639 6.18112C11.9639 5.53268 11.4326 4.99362 10.7451 4.99362C10.0576 4.99362 9.52637 5.53268 9.52637 6.18112ZM9.60449 8.44675V16.8217H11.8857V8.44675L9.60449 8.44675Z"
        fill="#CCFF00"
      />
    </g>
    <defs>
      <clipPath id="clip0_1041_2082">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.745117 0.907684)"
        />
      </clipPath>
    </defs>
  </svg>
)

export const InfoIcon = memo(InfoIconComponent)

export enum Platform {
  amplitude = 'amplitude',
}

export enum TrackingEvent {
  Activity = 'activityViewed',
  AddReplyCompleted = 'addReplyCompleted',
  AddReplyStarted = 'addReplyStarted',
  AddContentStarted = 'addContentStarted',
  AddPostCompleted = 'addPostCompleted',
  AppOpened = 'appOpened',
  ChallengeCommented = 'challengeCommented',
  ChallengeCreatorVideoCommented = 'challengeCreatorVideoCommented',
  ChallengeCreatorVideoShared = 'challengeCreatorVideoShared',
  ChallengeCreatorVideoViewed = 'challengeCreatorVideoViewed',
  ChallengeJoinCompleted = 'challengeJoinCompleted',
  ChallengeJoinStarted = 'challengeJoinStarted',
  ChallengeShared = 'challengeShared',
  ChallengeViewed = 'challengeViewed',
  ConsentConfirmed = 'consentConfirmed',
  ConversationLiked = 'conversationLiked',
  ConversationShared = 'conversationShared',
  ConversationCommented = 'conversationCommented',
  Discover = 'exploreViewed',
  Feed = 'homeViewed',
  LogInViewed = 'logInViewed',
  MessageSent = 'messageSent',
  OtherProfileViewed = 'otherProfileViewed',
  PostCommented = 'postCommented',
  PostLiked = 'postLiked',
  PostShared = 'postShared',
  PostViewed = 'postViewed',
  Profile = 'profileViewed',
  ProfileCreated = 'profileCreated',
  ProfileFollowed = 'profileFollowed',
  ProfileUnfollowed = 'profileUnfollowed',
  ReferralLinkClicked = 'referralLinkClicked',
  ReferralSignupSubmitted = 'referralSignupSubmitted',
  SignIn = 'signIn',
  SigninCompleted = 'signinCompleted',
  SigninMethodSelected = 'signinMethodSelected',
  SigninViewed = 'signinViewed',
  SignUp = 'signUp',
  SignUpStarted = 'signUpStarted',
  SignUpViewed = 'signUpViewed',
  StartPageViewed = 'startPageViewed',
  SubmissionCommented = 'submissionCommented',
  SubmissionShared = 'submissionShared',
  SubmissionViewed = 'submissionViewed',
  SubmissionVoted = 'submissionVoted',
  XPViewed = 'xpViewed',
  FeedItemViewed = 'feedItemViewed',
  ExploreCategorySelected = 'exploreCategorySelected',
  LikeDeleted = 'likeDeleted',
  PersonalizeLike = 'like',
  PersonalizeView = 'view',
  PersonalizeLikedView = 'likedview',
  EarnMoreClicked = 'earnMoreClicked',
  StakeNowCTAClicked = 'stakeNowCTAClicked',
  BuyButtonClicked = 'buyButtonClicked',
}

import { FC, MutableRefObject } from 'react'
import {
  DataFunc,
  Mention,
  MentionsInput,
  OnChangeHandlerFunc,
} from 'react-mentions'
import { isPlatform } from '@ionic/react'
import cx from 'classnames'

import { Avatar } from '@/components/atoms/Avatar'

import { appConfig } from '@/services/config'

interface CustomMentionsInputProps {
  field: {
    value: string
    onChange: OnChangeHandlerFunc
  }
  maxLength?: number
  mentionsCount: number
  mentionsContainerRef: MutableRefObject<HTMLDivElement>
  placeholder?: string
  fetchMentions: DataFunc
  onBlur: () => void
}

export const CustomMentionsInput: FC<CustomMentionsInputProps> = ({
  field,
  maxLength,
  mentionsCount,
  mentionsContainerRef,
  placeholder,
  fetchMentions,
  onBlur,
}) => {
  const renderSuggestion = (suggestion, search, highlightedDisplay) => (
    <div className="py-2 px-4 text-white flex flex-row items-center h-[3rem]">
      <Avatar
        username={suggestion?.username}
        size="small"
        className="mr-3 my-1"
        userId={suggestion.id}
        isModerator={suggestion?.isModerator}
        isAmbassador={suggestion?.isAmbassador}
        badge={suggestion?.badge}
      />
      {highlightedDisplay}
    </div>
  )

  const customSuggestionsContainer = (children) => (
    <div className="divide-y divide-cool-gray-700 bg-cool-gray-900">
      {children}
    </div>
  )

  return (
    <MentionsInput
      maxLength={maxLength}
      className="mention-input"
      value={field.value}
      onChange={field.onChange}
      suggestionsPortalHost={mentionsContainerRef.current}
      customSuggestionsContainer={customSuggestionsContainer}
      onBlur={onBlur}
      placeholder={placeholder}
      style={{
        suggestions: {
          position: 'relative',
          top: 0,
          left: 0,
          margin: 0,
        },
        '&multiLine': {
          input: {
            border: '1px solid transparent',
          },
        },
      }}
    >
      <Mention
        className={cx('mention', {
          'mention-ios': isPlatform('ios'),
        })}
        appendSpaceOnAdd
        trigger="@"
        markup="[__display__](user:__id__)"
        data={
          mentionsCount < appConfig.mentionsLimit ? fetchMentions : () => null
        }
        renderSuggestion={renderSuggestion}
      />
    </MentionsInput>
  )
}

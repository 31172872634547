import { useCallback, useState } from 'react'

import { get } from '@/services/awsAmplify'
import { useDebouncedFunction } from '@/lib/hooks/useDebouncedFunction'

import { useGetAvatar } from '../useGetAvatar'

export interface MentionUser {
  avatar: string
  display: string
  id: string
}

export const useFetchMentions = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [mentions, setMentions] = useState<MentionUser[]>([])
  const getAvatar = useGetAvatar()

  const fetchMentions = useDebouncedFunction(async (query, callback) => {
    if (!query || query.length < 1) return

    try {
      setIsLoading(true)
      const result = await get({
        path: '/profile/mentions-autocomplete',
        params: { q: query },
      })

      const usersList: MentionUser[] = result?.users.map((user) => {
        const avatar = getAvatar(user.id)

        return {
          avatar,
          display: '@' + user.username,
          id: user.id,
        }
      })
      setMentions(usersList)

      return await callback(usersList)
    } catch (e) {
      return callback
    } finally {
      setIsLoading(false)
    }
  }, 500)

  const onBlur = useCallback(() => {
    setMentions([])
  }, [setMentions])

  return {
    fetchMentions,
    mentions,
    isLoading,
    onBlur,
  }
}

import { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonItem } from '@ionic/react'

import { Avatar } from '@components/atoms/Avatar'
import { UserName } from '@components/atoms/UserName'
import { AvatarGroup } from '@/components/atoms/AvatarGroup'

import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Link } from '@/lib/routing'
import { formatDistance } from '@utils/date'

import { ActivityFollowsAggregated } from '@/types/activity'
interface IActivityFollowAggregatedItemProps {
  item: ActivityFollowsAggregated
}

export const ActivityFollowAggregatedItem: FC<
  IActivityFollowAggregatedItemProps
> = ({ item }) => {
  const { data: userInfo } = useUserInfo()
  const { t } = useTranslation('common')

  const lastActors = useMemo(() => {
    return [...item.data.lastActors].reverse()
  }, [item.data.lastActors])

  return (
    <IonItem className="py-4 border-solid border-b-[0.0625rem] border-zinc-800">
      <span className="pr-2 mr-1">
        <AvatarGroup>
          {lastActors.map((actor) => (
            <div key={actor.id} className="w-10 pt-3">
              <Avatar
                userId={actor.id}
                username={actor.username}
                isModerator={actor?.isModerator}
                isAmbassador={actor.isAmbassador}
                badge={actor.badge}
                clickable
              />
            </div>
          ))}
        </AvatarGroup>
      </span>
      <div className="flex justify-between items-center">
        <div className="flex flex-col justify-between">
          <div className="text-sm">
            <UserName
              className="font-bold inline"
              userId={lastActors[lastActors.length - 1].id}
              name={lastActors[lastActors.length - 1].username}
            />
            <Link to={`relations/${userInfo.id}?followers=true`}>
              <Trans
                i18nKey="activity.item.followMany"
                values={{ count: Number(item.data.count - 1) }}
              >
                {t('activity.item.followMany', {
                  count: Number(item.data.count - 1),
                })}
              </Trans>
            </Link>
          </div>
          <div className="text-xs text-zinc-600">
            {formatDistance(item.time)}
          </div>
        </div>
      </div>
    </IonItem>
  )
}

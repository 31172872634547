import { FC, memo } from 'react'

type OpenAppStreakComponentBackgroundProps = {
  isCompleted: boolean
}

const OpenAppStreakComponentBackgroundComponent: FC<
  OpenAppStreakComponentBackgroundProps
> = ({ isCompleted }) => {
  return !isCompleted ? (
    <div className="absolute -z-10 bg-cool-gray-800 w-full h-full rounded-[0.625rem] border border-cool-gray-700" />
  ) : (
    <picture className="absolute -z-10">
      <source srcSet="bonus_streak/bg_streak_completed.png 358w, bonus_streak/bg_streak_completed@2x.png 716w, bonus_streak/bg_streak_completed@3x.png 1074w" />
      <img
        src="bonus_streak/bg_streak_completed.png"
        alt="bonus-streak-background"
      />
    </picture>
  )
}

export const OpenAppStreakComponentBackground = memo(
  OpenAppStreakComponentBackgroundComponent
)

import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteSubscription,
  getSubscriptionsList,
  SubscriptionsListData,
} from '@/services/subscription'
import { queryClient } from '@/lib/queryClient'

import { QueryKeys, SubscriptionStatus } from '@/enums'

export const useSubscriptionsList = () => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.SubscriptionsList],
    queryFn: getSubscriptionsList,
    enabled: true,
  })

  const { mutate: mutateDeleteSubscription, isPending: isDeleteLoading } =
    useMutation({
      mutationFn: deleteSubscription,
      onSuccess: (response, params) => {
        queryClient.setQueryData(
          [QueryKeys.SubscriptionsList],
          (subscriptionsListData: SubscriptionsListData) => ({
            subscriptions: subscriptionsListData.subscriptions.map((sub) => {
              return params.userId === sub.id
                ? { ...sub, status: SubscriptionStatus.Canceled }
                : sub
            }),
          })
        )
      },
    })

  return {
    subscriptions: data?.subscriptions ?? [],
    refetch,
    isLoading,
    mutateDeleteSubscription,
    isDeleteLoading,
  }
}

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonLabel, IonSpinner } from '@ionic/react'
import FacebookLogin, {
  ProfileSuccessResponse,
  SuccessResponse,
} from '@greatsumini/react-facebook-login'
import { useQuery } from '@tanstack/react-query'
import cx from 'classnames'

import { appConfig } from '@/services/config'
import { errorBoundary } from '@/services/errorBoundary'
import { ssoSignIn } from '@/services/sso-signin'
import { getUserInfo } from '@/services/user'
import { setWebPushNotificationsExternalUserId } from '@/services/webPushNotifications'
import { useAmplitudeIdentifyUser } from '@/lib/hooks/useAmplitudeIdentifyUser'
import { useShowErrorToast } from '@/lib/hooks/useShowErrorToast'
import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'
import { getStreamSetUserInfo } from '@/lib/tracking/platform/getstream'
import { Routes } from '@/router/routes'

import { SignInMethods } from '@/enums'

import {
  trackingSigninCompleted,
  trackingSigninMethodSelected,
  trackingSignupSubmitted,
} from './TrackingSSO'

const SignInWithFacebook: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const successResponseRef = useRef<SuccessResponse>(null)
  const [isPending, setIsPending] = useState(false)
  const { showErrorToast } = useShowErrorToast()

  const { amplitudeIdentifyUser } = useAmplitudeIdentifyUser()

  const {
    refetch: getUserData,
    error,
    isError,
  } = useQuery({
    queryKey: ['user'],
    queryFn: getUserInfo,
    retry: 5,
    enabled: false,
  })

  const setupPushNotification = (userId: string) => {
    setWebPushNotificationsExternalUserId(userId)
  }

  async function signInWithFacebook(profile: ProfileSuccessResponse) {
    const data: { refUsername?: string; redirectUrl?: string } =
      queryClient.getQueryData(['referral'])

    return ssoSignIn(
      'facebook',
      {
        username: profile.email ? profile.email.toLowerCase() : profile.id,
        authToken: successResponseRef.current?.accessToken,
      },
      {
        userId: profile.id,
        refUsername: data?.refUsername,
        email: profile.email?.toLowerCase(),
      }
    )
  }

  const onSuccess = useCallback(
    (user) => {
      amplitudeIdentifyUser(user)
      setupPushNotification(user.id)
      if (user.username) {
        navigate(Routes.FEED, { replace: true })
        getStreamSetUserInfo(user.id, user.username)
      } else {
        navigate(Routes.AUTH_CONSENT, {
          replace: true,
          state: { direction: 'none' },
        })
      }
    },
    [amplitudeIdentifyUser, navigate]
  )

  useEffect(() => {
    if (isError) errorBoundary(error)
  }, [error, isError])

  return (
    <FacebookLogin
      appId={appConfig.facebookAppId}
      initParams={{
        version: 'v16.0',
        xfbml: false,
      }}
      onSuccess={(response) => {
        successResponseRef.current = response
      }}
      onFail={(e) => {
        setIsPending(false)
        if (e.status === 'loginCancelled') return

        showErrorToast({
          message: t('registration.sso.error.facebookLoginFailed'),
        })
        console.error(`Error signing in with Facebook`, e)
      }}
      onProfileSuccess={async (profile) => {
        try {
          const ssoSignInResponse = await signInWithFacebook(profile)

          trackingSigninCompleted(SignInMethods.Facebook)
          const user = await getUserData()
          if (ssoSignInResponse?._tag === 'NewRegistration') {
            await trackingSignupSubmitted(SignInMethods.Facebook)
          }
          onSuccess(user?.data)
        } catch (e) {
          errorBoundary(e)
          showErrorToast({
            message: t('registration.sso.error.facebookLoginFailed'),
          })
          console.error(`Error signing in with Facebook`, e)
        } finally {
          setIsPending(false)
        }
      }}
      render={({ onClick }) => (
        <IonLabel
          onClick={
            !isPending
              ? () => {
                  setIsPending(true)
                  trackingSigninMethodSelected(SignInMethods.Facebook)
                  onClick()
                }
              : undefined
          }
          className={cx(
            'w-full z-10 mt-4 cursor-pointer flex flex-row justify-center text-sm bg-[#1877F2] text-white font-medium py-2 px-4 rounded-full focus:outline-none',
            { 'opacity-50 !cursor-default': isPending }
          )}
        >
          {isPending ? (
            <IonSpinner color="light" className="h-5" />
          ) : (
            <>
              <img
                src="/f-logo.png"
                alt="Facebook logo"
                className="mr-[8px] h-[18px]"
              />
              <span>{t(`registration.signUpOptions.signUpWithFacebook`)}</span>
            </>
          )}
        </IonLabel>
      )}
    />
  )
}

export default SignInWithFacebook

import { FC, memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActionSheetButton,
  IonActionSheet,
  IonButton,
  isPlatform,
} from '@ionic/react'
import { useMutation } from '@tanstack/react-query'

import { Ellipsis } from '@/components/atoms/assets/Ellipsis'
import { Popover } from '@/components/molecules/Popover'

import { reportPost, reportUser, reportVideo } from '@services/report'
import { unfollow } from '@services/user'
import { block } from '@/services/user'
import { useInvalidateQueries } from '@/lib/hooks/useInvalidateQueries'
import { useReporting } from '@/lib/hooks/useReporting'

import { ReportButtonType } from '@/enums'

interface IReportBaseProps {
  confirmationLabel?: string
  contestId?: UUID
  isDisabled?: boolean
  userId: UUID
}
interface IReportWithoutActivityIdProps extends IReportBaseProps {
  type: Extract<`${ReportButtonType}`, 'messaging'>
}

interface IReportWithActivityIdProps extends IReportBaseProps {
  type: Exclude<`${ReportButtonType}`, 'messaging'>
  activityId: UUID
}

type IReportButtonProps =
  | IReportWithoutActivityIdProps
  | IReportWithActivityIdProps

export const ReportButtonComponent: FC<IReportButtonProps> = (props) => {
  const {
    confirmationLabel = '',
    contestId,
    isDisabled = true,
    type,
    userId,
  } = props
  const activityId: string | undefined =
    props.type === 'messaging' ? undefined : props.activityId

  const { t } = useTranslation('common')

  const { invalidateQueriesTimeout } = useInvalidateQueries()

  const {
    removeActivityFromTimelineFeed,
    removeContestFromContestDetail,
    removeUserFromFeeds,
  } = useReporting()

  const [showReportActionSheet, setShowReportActionSheet] = useState(false)
  const [popover, setPopover] = useState({
    isVisible: false,
    message: null,
  })
  const [isDeleteActivityPopoverVisible, setDeleteActivityPopoverVisible] =
    useState(false)

  const onReportActivity = async () => {
    setDeleteActivityPopoverVisible(true)
  }

  const onReportVideo = async (message: string) => {
    try {
      await reportVideo({
        contestId,
        userId,
        activityId,
      })

      removeContestFromContestDetail({
        activityId,
        contestId,
        userId,
      })
      setPopover({ isVisible: true, message })
    } catch (e) {
      console.log(e)
    }
  }

  const { mutate: doReportPost } = useMutation({
    mutationKey: ['reportPost', userId, activityId],
    mutationFn: () => reportPost({ activityId }),
  })

  const onReportUser = async (message: string) => {
    try {
      await reportUser({
        userId,
      })

      removeUserFromFeeds(userId)

      setPopover({ isVisible: true, message })
    } catch (e) {
      console.log(e)
    }
  }

  const onBlockUser = async (message: string) => {
    try {
      await block({
        userId,
      })

      removeUserFromFeeds(userId)

      setPopover({ isVisible: true, message })
    } catch (e) {
      console.log(e)
    }
  }

  const onUnfollowUser = async (message: string) => {
    try {
      await unfollow({ userId })
      invalidateQueriesTimeout()
      setPopover({ isVisible: true, message })
    } catch (e) {
      console.log(e)
    }
  }

  const reportVideoMenuItem: ActionSheetButton = Object.freeze({
    text: t('report.contestVideo.buttonLabel'),
    role: 'destructive',
    data: {
      type: 'delete',
    },
    handler: () => onReportVideo(t('report.contestVideo.confirmationMessage')),
  })

  const reportUserMenuItem: ActionSheetButton = Object.freeze({
    text: t('report.reportUser.buttonLabel'),
    role: 'destructive',
    data: {
      type: 'delete',
    },
    handler: () => onReportUser(t('report.reportUser.confirmationMessage')),
  })

  const blockUserMenuItem: ActionSheetButton = Object.freeze({
    text: t('report.blockUser.buttonLabel'),
    role: 'destructive',
    data: {
      type: 'delete',
    },
    handler: () => onBlockUser(t('report.blockUser.confirmationMessage')),
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const unfollowMenuItem: ActionSheetButton = Object.freeze({
    text: t('report.unFollowUser.buttonLabel'),
    role: 'destructive',
    data: {
      type: 'delete',
    },
    handler: () => onUnfollowUser(t('report.unFollowUser.confirmationMessage')),
  })

  const deleteActivityMenuItem: ActionSheetButton = Object.freeze({
    text: t('report.deleteActivity.buttonLabel'),
    role: 'destructive',
    data: {
      type: 'delete',
    },
    handler: () => onReportActivity(),
  })

  const actionSheetButtonsHandler = useCallback(() => {
    switch (type) {
      case ReportButtonType.Video:
        return [reportVideoMenuItem, reportUserMenuItem, blockUserMenuItem]
      case ReportButtonType.Conversation:
      case ReportButtonType.ConversationReply:
      case ReportButtonType.Post:
        return [deleteActivityMenuItem, reportUserMenuItem, blockUserMenuItem]
      case ReportButtonType.Messaging:
        return [reportUserMenuItem, blockUserMenuItem]
      default:
        return []
    }
  }, [
    type,
    deleteActivityMenuItem,
    reportVideoMenuItem,
    reportUserMenuItem,
    blockUserMenuItem,
  ])

  const actionSheetButtons = (): ActionSheetButton[] => [
    ...actionSheetButtonsHandler(),
    {
      text: t('report.cancel'),
      role: 'cancel',
    },
  ]

  return (
    <>
      <Popover
        hasIcon={false}
        isOpen={popover.isVisible}
        onDismiss={() => {
          setPopover({ isVisible: false, message: null })
        }}
        actionText={confirmationLabel || t('ok')}
      >
        {popover.message}
      </Popover>
      <Popover
        hasIcon={false}
        isOpen={isDeleteActivityPopoverVisible}
        onDismiss={() => setDeleteActivityPopoverVisible(false)}
        onConfirm={() => {
          setDeleteActivityPopoverVisible(false)

          //TODO: here we need to unsubscribe reporter from users activity

          removeActivityFromTimelineFeed(activityId)

          if (type === ReportButtonType.Post) {
            doReportPost()
          }
        }}
        actionText={t('cancel')}
        confirmText={t('confirm')}
      >
        {t('report.deleteActivity.confirmationMessage')}
      </Popover>
      <IonButton
        mode="md"
        disabled={isDisabled}
        color="plain"
        onClick={(e) => {
          e.stopPropagation()
          setShowReportActionSheet(true)
        }}
      >
        <Ellipsis />
      </IonButton>
      <IonActionSheet
        isOpen={showReportActionSheet}
        header={t('report.title')}
        mode={isPlatform('ios') ? 'ios' : 'md'}
        onDidDismiss={() => setShowReportActionSheet(false)}
        buttons={actionSheetButtons()}
      />
    </>
  )
}

export const ReportButton = memo(ReportButtonComponent)

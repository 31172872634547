import { Dispatch, SetStateAction, useEffect } from 'react'
import { isPlatform } from '@ionic/react'

import { Category } from '@/services/categories'

import { Pages } from '@/enums'

type UseHandleHardwareBackPressProps = {
  selectedCategory: Category
  setSelectedCategory: Dispatch<SetStateAction<Category>>
}

export const useHandleHardwareBackPress = ({
  selectedCategory,
  setSelectedCategory,
}: UseHandleHardwareBackPressProps) => {
  const isDiscoverPage = window.location.pathname === `/${Pages.Discover}`

  useEffect(() => {
    if (isPlatform('ios')) return

    const handleBackButton = (ev) => {
      ev.detail.register(101, (processNextHandler) => {
        if (isDiscoverPage && selectedCategory) {
          setSelectedCategory(null)
        } else {
          processNextHandler()
        }
      })
    }

    if (selectedCategory) {
      document.addEventListener('ionBackButton', handleBackButton)
    }

    return () => {
      document.removeEventListener('ionBackButton', handleBackButton)
    }
  }, [isDiscoverPage, selectedCategory, setSelectedCategory])
}

import { FC, memo } from 'react'

import { AnimateCounter } from '@/components/atoms/AnimateCounter'

export interface DefaultBadgeLargeComponentProps {
  className?: string
  xp: number
}

const DefaultBadgeLargeComponent: FC<DefaultBadgeLargeComponentProps> = ({
  className = '',
  xp = 0,
}) => {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 164 186"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.0035 181.099L74.003 181.099L10.5738 143.97L10.5735 143.969C5.74059 141.141 2.73333 135.892 2.73333 130.29V55.7073C2.73333 50.105 5.74059 44.8556 10.5735 42.0275L10.5738 42.0273L74.0023 4.89856C78.9382 2.01144 85.0702 2.01192 90.001 4.89805C90.0011 4.89808 90.0011 4.8981 90.0012 4.89812L153.426 42.0272L153.426 42.0274C158.259 44.8556 161.267 50.105 161.267 55.7073V130.29C161.267 135.895 158.256 141.141 153.426 143.97L90.001 181.099L90.0007 181.099C87.5338 182.544 84.7691 183.267 82.0021 183.267C79.2351 183.267 76.4704 182.544 74.0035 181.099Z"
        fill="url(#paint0_radial_1149_6761)"
        stroke="#C0B1E7"
        strokeWidth="5.46667"
      />
      <path
        d="M66.9119 32.0485V51.3694L88.0603 51.3069C89.6598 51.3069 90.8793 51.6921 91.8382 53.035C91.8728 53.0837 91.9228 53.1205 91.9603 53.1664C93.3222 54.8531 95.0457 55.5544 97.1039 55.4138C97.5569 55.3825 97.7156 55.2005 97.7089 54.7354L97.7118 51.6884C97.7271 51.1056 97.5396 50.8694 96.9779 50.8804L92.5913 50.9154C92.5913 50.9154 90.0493 50.6442 88.849 49.2645L88.3575 48.7406C87.1794 47.4427 85.7444 46.8122 84.0622 46.8159C82.2724 46.8195 80.4825 46.8168 78.6927 46.8177C75.9785 46.8177 72.9307 46.8067 70.2165 46.8287V43.8396C74.1425 43.8534 78.3281 43.8497 82.2551 43.8488C82.9033 43.8488 83.4554 44.0418 83.9093 44.495C84.3335 44.9178 84.7826 45.3176 85.2145 45.7349C86.332 46.814 87.6881 47.4142 89.278 47.4345C91.8757 47.4685 94.4744 47.4391 97.0722 47.4519C97.555 47.4538 97.7185 47.2543 97.7098 46.8223C97.6935 45.9647 97.705 45.1062 97.7031 44.2477C97.7012 43.4848 97.6906 43.4738 96.9039 43.4738C93.228 43.4738 89.5521 43.4719 85.8752 43.4738C83.213 43.4756 83.5265 43.6456 81.6588 41.8183C80.2796 40.469 78.66 39.7943 76.6595 39.8504C74.6215 39.9074 72.2565 39.8568 70.2156 39.8715V36.603H75.6342C76.2786 36.5928 76.796 36.8392 77.2404 37.3043C77.7645 37.853 78.2656 38.4275 78.7994 38.9652C79.8025 39.9763 80.9807 40.6234 82.3695 40.6308C87.2775 40.6565 92.1854 40.6409 97.0933 40.6537C97.5425 40.6547 97.7175 40.4699 97.7098 39.9938C97.6945 39.0195 97.7041 38.0451 97.7031 37.0708C97.7031 36.1461 97.6819 36.1222 96.8356 36.1222C91.0428 36.1222 85.2491 36.1351 79.4563 36.1176C76.2757 36.1084 77.0124 36.4789 74.7561 34.0311C73.5221 32.691 71.9573 32.0503 70.2184 32.0503L66.9119 32.0485Z"
        fill="#CCFF00"
      />
      <text
        x="84"
        y="110"
        fontSize={xp > 1e5 ? 32 : 36}
        fontWeight="bold"
        textAnchor="middle"
        fill="white"
      >
        <AnimateCounter start={0} end={xp} duration={1500} />
      </text>
      <path
        d="M70.798 147.351H73.6632L76.4318 142.904H76.5197L79.3058 147.351H82.3292L78.2247 141.014V140.979L82.382 134.669H79.42L76.7394 139.239H76.6427L73.9445 134.669H70.8243L74.8322 140.935V140.962L70.798 147.351ZM83.8146 147.351H86.4689V143.352H88.9122C91.5841 143.352 93.3683 141.63 93.3683 139.019V139.002C93.3683 136.391 91.5841 134.669 88.9122 134.669H83.8146V147.351ZM88.2618 136.769C89.7823 136.769 90.6788 137.569 90.6788 139.01V139.028C90.6788 140.469 89.7823 141.278 88.2618 141.278H86.4689V136.769H88.2618Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1149_6761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(82 93) rotate(122.923) scale(166.506 146.874)"
        >
          <stop stopColor="#6332E0" />
          <stop offset="1" stopColor="#8162D0" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export const DefaultBadgeLarge = memo(DefaultBadgeLargeComponent)

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonTitle } from '@ionic/react'

import { BackButton } from '@/components/atoms/BackButton'
import { Loading } from '@/components/atoms/Loading'
import { Title } from '@/components/atoms/Title'
import { Main } from '@/components/templates/main'
import { Connect } from './components/Connect'
import { Disconnect } from './components/Disconnect'
import { Failed } from './components/Failed'

import { appConfig } from '@/services/config'
import { useRouterQuery } from '@/lib/hooks/useRouterQuery'
import { queryClient } from '@/lib/queryClient'

import { QueryKeys } from '@/enums'

import { useInstagram } from './hooks/useInstagram'

export const InstagramPage = () => {
  const INSTAGRAM_CLIENT_ID = appConfig.instagramAppId
  const REDIRECT_URI = `${appConfig.origin}/socials/instagram`
  const INSTAGRAM_AUTHORIZE_URL = `https://api.instagram.com/oauth/authorize?client_id=${INSTAGRAM_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=user_profile&response_type=code`

  const referrer = queryClient.getQueryData<string>([QueryKeys.SocialBackUrl])

  const { t } = useTranslation('common')
  const query = useRouterQuery()
  const code = query.get('code')
  const {
    connectionInfo,
    isConnectPending,
    isSocialsListLoading,
    connectError,
    mutateDisconnect,
  } = useInstagram({
    code,
  })

  const header = useCallback(
    () => (
      <>
        <IonButtons slot="start">
          <BackButton className="text-white" defaultHref={referrer} />
        </IonButtons>
        <IonTitle>
          <Title level={2}>{t('registration.profile.instagram')}</Title>
        </IonTitle>
      </>
    ),
    [referrer, t]
  )

  const Component = () => {
    if (isSocialsListLoading || isConnectPending) {
      return <Loading />
    }

    if (connectionInfo?.providerUserName) {
      return (
        <Disconnect
          userName={connectionInfo.providerUserName}
          handleDisconnection={mutateDisconnect}
        />
      )
    }

    if (connectError) {
      return <Failed authUrl={INSTAGRAM_AUTHORIZE_URL} />
    }

    return <Connect authUrl={INSTAGRAM_AUTHORIZE_URL} />
  }

  return (
    <Main header={header()} isPaddingEnabled={false}>
      {Component()}
    </Main>
  )
}

import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonItem } from '@ionic/react'

import { Avatar, OwnAvatar } from '@components/atoms/Avatar'
import { UserName } from '@/components/atoms/UserName'

import { formatDistance } from '@utils/date'

import { ActivityTypeEnum } from '@/types/activity'
import {
  ActivityRewardInviteAccepted,
  ActivityRewardReferralSuccessful,
} from '@/types/activity/reward'

interface IActivityReferralItemProps {
  item: ActivityRewardReferralSuccessful | ActivityRewardInviteAccepted
}

const isActivityRewardReferralSuccessful = (
  item: ActivityRewardReferralSuccessful | ActivityRewardInviteAccepted
): item is ActivityRewardReferralSuccessful => {
  return 'referral' in item.data
}

export const ActivityReferralItem: FC<IActivityReferralItemProps> = ({
  item,
}) => {
  const { t } = useTranslation('common')
  const typeMappings = {
    [ActivityTypeEnum.RewardReferralSuccessful]: 'invite_accepted_referral',
    [ActivityTypeEnum.RewardInviteAccepted]: 'invite_accepted_user',
  }

  const translationKey = typeMappings[item.type]

  return (
    <IonItem className="py-4 border-solid border-b-[0.0625rem] border-zinc-800">
      <span className="pr-4">
        {isActivityRewardReferralSuccessful(item) ? (
          <Avatar
            userId={item.data?.referral?.id}
            username={item.data?.referral?.username}
            clickable
          />
        ) : (
          <OwnAvatar />
        )}
      </span>
      <div className="w-full flex flex-col justify-between">
        <div className="text-sm">
          <Trans
            i18nKey={`activity.item.referral.${translationKey}`}
            count={item.data.xpBonus}
            components={{
              userLink: (
                <UserName
                  className="inline font-bold"
                  name={
                    isActivityRewardReferralSuccessful(item)
                      ? item.data.referral?.username
                      : undefined
                  }
                />
              ),
            }}
            values={{
              xp: item.data.xp,
              bonus: item.data.xpBonus,
            }}
          >
            {t(`activity.item.referral.${translationKey}`, {
              xp: item.data.xp,
              count: item.data.xpBonus,
              bonus: item.data.xpBonus,
            })}
          </Trans>
        </div>
        <div className="text-xs text-zinc-600">{formatDistance(item.time)}</div>
      </div>
    </IonItem>
  )
}

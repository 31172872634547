import { post } from '@/services/awsAmplify'

import { StakeStatus } from '@/enums'

export interface IRedeem {
  id: UUID
  ruleId: string
  userId: UUID
  username: string
  amount: string
  rewardAmount: string
  apyInPercentage: number
  durationInDays: number
  redeemTransactionId: string
  rewardTransactionId: string
  stakeStatus: StakeStatus.Redeemed
  stakeDate: string
  releaseDate: string
}

export const redeem = ({ stakeId }: { stakeId: string }): Promise<IRedeem> =>
  post({
    path: `/stakes/${stakeId}/redeem`,
  }) as unknown as Promise<IRedeem>

import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { IonAvatar } from '@ionic/react'
import cx from 'classnames'

import { useCachedAvatar } from '@/lib/hooks/useCachedAvatar'
import { useGetAvatar } from '@/lib/hooks/useGetAvatar'
import { Link } from '@/lib/routing'

import { Badge } from '@enums'

import { EmptyAvatarBackground } from '../assets/EmptyAvatarBackground'
import { ShieldIcon } from '../assets/ShieldIcon'

export interface AvatarProps {
  size?: 'small' | 'medium' | 'large' | 'xl' | 'xxl'
  className?: string
  userId?: string
  lazy?: boolean
  clickable?: boolean
  badge?: Badge
  isModerator?: boolean
  isAmbassador?: boolean
  isPresent?: boolean
  username?: string
  linkTo?: string
}

const AvatarComponent: FC<AvatarProps> = ({
  className,
  userId = '',
  lazy = true,
  size = 'medium',
  clickable = false,
  badge = null,
  isModerator = false,
  isAmbassador = false,
  isPresent,
  username = '',
  linkTo,
}) => {
  const getAvatar = useGetAvatar()
  const [imageSrc, setImageSrc] = useState(getAvatar(userId))
  const cachedAvatar = useCachedAvatar()
  const [avatarStyle, setAvatarStyle] = useState(null)

  const getSize = useCallback(() => {
    switch (size) {
      case 'small':
        return 28
      case 'medium':
        return 38
      case 'large':
        return 54
      case 'xl':
        return 100
      case 'xxl':
        return 118
    }
  }, [size])

  const getAvatarStyle = useCallback(() => {
    const borderSizeClass = `${getSize() > 54 ? 'border-4' : 'border-2'}`
    if (isModerator) {
      return `${borderSizeClass} border-white`
    }

    if (isAmbassador) {
      return `${borderSizeClass} border-primary`
    }

    switch (badge) {
      case Badge.BronzeXPMilestoneBadge:
        return `${borderSizeClass} border-bronze`
      case Badge.SilverXPMilestoneBadge:
        return `${borderSizeClass} border-silver`
      case Badge.GoldXPMilestoneBadge:
        return `${borderSizeClass} border-gold`
      case Badge.DiamondXPMilestoneBadge:
        return `${borderSizeClass} border-diamond`
      default:
        return 'border border-cool-gray-400'
    }
  }, [badge, isAmbassador, isModerator, getSize])

  useEffect(() => {
    setAvatarStyle(getAvatarStyle())
    setImageSrc(getAvatar(userId))
  }, [
    userId,
    cachedAvatar,
    badge,
    isModerator,
    isAmbassador,
    getSize,
    getAvatarStyle,
    getAvatar,
  ])

  const handleImageError = () => {
    setImageSrc('undefined')
  }

  const normalizeSize = useCallback(() => {
    const REM = 16
    return getSize() / REM + 'rem'
  }, [getSize])

  const link = useMemo(() => {
    if (!clickable) return null
    if (!linkTo) return username ? `/@${username}` : `/profile/${userId}`
    return linkTo
  }, [linkTo, clickable, username, userId])

  const getProfileImage = useCallback(() => {
    return imageSrc && !imageSrc.includes('undefined') ? (
      <img
        src={imageSrc}
        className="min-w-full h-full"
        alt="Avatar image"
        onError={handleImageError}
        {...(lazy ? { loading: 'lazy' } : {})}
      />
    ) : (
      <EmptyAvatarBackground width="100%" height="100%" />
    )
  }, [imageSrc, lazy])

  return (
    <div className="flex justify-center items-start">
      <div className={`relative m-0 p-0 cursor-pointer ${className}`}>
        {clickable && userId ? (
          <Link title="Go to profile" to={link} className="relative block">
            <IonAvatar
              title="avatar"
              className={`flex w-full h-full ${avatarStyle}`}
              style={{
                width: normalizeSize(),
                height: normalizeSize(),
              }}
            >
              {getProfileImage()}
            </IonAvatar>
          </Link>
        ) : (
          <>
            <IonAvatar
              title="avatar"
              className={`flex w-full h-full ${avatarStyle}`}
              style={{
                width: normalizeSize(),
                height: normalizeSize(),
              }}
            >
              {getProfileImage()}
            </IonAvatar>
          </>
        )}
        {(isModerator || isAmbassador) && (
          <div
            className={cx('absolute', {
              'bottom-[0.5rem] right-[0.125rem]': getSize() > 54,
              'bottom-[0.0625rem] right-[0.0625rem]': getSize() == 54,
              '-bottom-[0.25rem] -right-[0.0625rem]': getSize() == 38,
              '-bottom-[0.375rem] -right-[0.0625rem]': getSize() == 28,
            })}
          >
            <ShieldIcon
              className={cx('w-3', {
                'w-auto': getSize() > 54,
                'w-4': getSize() == 54,
                'w-3': getSize() == 38,
                'w-2': getSize() == 28,
              })}
              {...(isModerator ? { color: '#FFFFFF' } : {})}
            />
          </div>
        )}
        {isPresent && size == 'medium' && (
          <span className="top-0 start-7 absolute w-3 h-3 bg-primary border-2 border-cool-gray-900 rounded-full"></span>
        )}
      </div>
    </div>
  )
}

export const Avatar = memo(AvatarComponent)

import { AxiosError, AxiosResponse } from 'axios'

type HttpResponseWithError = AxiosResponse & { error: boolean }

// https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/noticeerror/
const newrelicNoticeError = ({
  error = {},
  customAttributes = {},
}: {
  error:
    | Record<string, unknown>
    | HttpResponseWithError
    | string
    | AxiosError
    | Error
  customAttributes?: Record<string, unknown>
}) => {
  if (window?.NREUM && error) {
    window.NREUM.noticeError(error, customAttributes)
  }
}

// https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/setuserid/
const obtainAwsCtUserId = () => {
  try {
    const awsCtKey = `aws-amplify-cache_awsct_uid.${window.location.host}`
    const awsCt = localStorage.getItem(awsCtKey)
    return awsCt ? JSON.parse(awsCt)?.data : null
  } catch (error) {
    console.error(
      'Error parsing localStorage item in obtainAwsCtUserId:',
      error
    )
    return null
  }
}

if (window?.NREUM) {
  const tag = window?.applicationVersion?.tag
  window.NREUM?.addRelease('v' + tag, tag)
  window.NREUM?.setApplicationVersion(tag)
  const userId = obtainAwsCtUserId()
  userId && window.NREUM?.setUserId(userId)

  const exactMessages = ['ua', 'Script error.', '网络错误']
  const includedPhrases = [
    'ServiceWorker',
    'Cannot redefine property: ethereum',
    'isMetaMask',
    'Talisman extension',
  ]

  // If the error handler returns 'true' then, the error is ignored and not sent to NewRelic
  window.NREUM?.setErrorHandler((error) => {
    if (exactMessages.some((message) => error.message === message)) {
      return true
    }

    if (includedPhrases.some((phrase) => error?.message?.includes(phrase))) {
      return true
    }

    if (error?.stack && error.stack.includes('chrome-extension://')) {
      return true
    }

    return false
  })
}

export { newrelicNoticeError }

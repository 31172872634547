import { useTranslation } from 'react-i18next'

import { Button } from '@components/atoms/Button'

import { appConfig } from '@/services/config'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

export const VerifyAccount = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')

  return (
    <div className="relative">
      <picture>
        <source srcSet="/vault/verify-liveness.png 388w, /vault/verify-liveness@2x.png 776w, /vault/verify-liveness@3x.png 1164w" />
        <img src="/vault/verify-liveness.png" alt="verify" />
      </picture>
      <div className="absolute top-[52%] left-[42%] sm:left-[32%] -translate-y-1/2 -translate-x-1/2 flex flex-col items-start gap-2 sm:gap-5">
        <span className="text-2xl font-bold w-[17rem]">
          {t('wallet.verifyAccount.header')}
        </span>

        <span className="text-[3vw] sm:text-base leading-tight w-[51vw] max-w-[12rem] sm:max-w-[16rem]">
          {t('wallet.verifyAccount.para1', { currency: appConfig.currency })}
        </span>
        <span className="text-[3vw] sm:text-base leading-tight w-[51vw] max-w-[12rem] sm:max-w-[16rem]">
          {t('wallet.verifyAccount.para2', { currency: appConfig.currency })}
        </span>

        <Button
          className="w-28 mt-3"
          onClick={() => navigate(Routes.FACE_LIVENESS_INSTRUCTIONS)}
        >
          {t('wallet.verifyAccount.button')}
        </Button>
      </div>
    </div>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type WalletAddressProps = {
  walletAddress: string
}

export const WalletAddress: FC<WalletAddressProps> = ({ walletAddress }) => {
  const { t } = useTranslation('common')

  return (
    <div className="flex flex-col text-sm mb-4">
      <span className="text-cool-gray-300 mb-1">
        {t('withdraw.walletAddress')}
      </span>
      <span className="break-words">{walletAddress}</span>
    </div>
  )
}

import { memo } from 'react'

export interface BanxaLogoProps {
  className?: string
}

const BanxaLogoComponent = ({ className }: BanxaLogoProps) => {
  return (
    <svg
      width="150"
      height="24"
      className={className}
      viewBox="0 0 150 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.0216 23.9759H30.675C30.2794 23.9788 29.8901 23.8768 29.5467 23.6803C29.2033 23.4838 28.9182 23.1998 28.7202 22.8573C28.5223 22.5147 28.4187 22.1258 28.4199 21.7302C28.4212 21.3346 28.5272 20.9463 28.7273 20.605L39.7642 1.48959C40.0259 1.03667 40.4021 0.66059 40.8551 0.399119C41.3082 0.137648 41.822 0 42.3451 0C42.8682 0 43.3821 0.137648 43.8351 0.399119C44.2881 0.66059 44.6644 1.03667 44.926 1.48959L55.9629 20.605C56.163 20.9463 56.2691 21.3346 56.2703 21.7302C56.2716 22.1258 56.1679 22.5147 55.97 22.8573C55.7721 23.1998 55.4869 23.4838 55.1435 23.6803C54.8002 23.8768 54.4108 23.9788 54.0152 23.9759H54.0216ZM34.5736 19.4825H50.1295L42.3515 6.0086L34.5736 19.4825Z"
        fill="url(#paint0_linear_458_596)"
      />
      <path
        d="M147.528 23.9759H124.181C123.786 23.9782 123.397 23.8758 123.054 23.6791C122.711 23.4823 122.426 23.1983 122.229 22.8559C122.031 22.5134 121.928 22.1247 121.929 21.7294C121.931 21.334 122.037 20.9461 122.237 20.605L133.27 1.48959C133.532 1.03667 133.908 0.66059 134.361 0.399119C134.814 0.137648 135.328 0 135.851 0C136.374 0 136.888 0.137648 137.341 0.399119C137.794 0.66059 138.17 1.03667 138.432 1.48959L149.469 20.605C149.669 20.9463 149.775 21.3346 149.776 21.7302C149.778 22.1258 149.674 22.5147 149.476 22.8573C149.278 23.1998 148.993 23.4838 148.65 23.6803C148.306 23.8768 147.917 23.9788 147.521 23.9759H147.528ZM128.083 19.4825H143.639L135.858 6.0086L128.083 19.4825Z"
        fill="url(#paint0_linear_458_596)"
      />
      <path
        d="M85.2272 0.466553H82.3904C81.7732 0.466553 81.4662 0.786369 81.4662 1.426V16.6429L67.8388 0.821549C67.7481 0.711502 67.6344 0.622682 67.5056 0.561347C67.3769 0.500012 67.2362 0.467654 67.0936 0.466553H63.5756C62.9616 0.466553 62.6514 0.786369 62.6514 1.426V23.0296C62.6514 23.6692 62.9712 23.9891 63.5756 23.9891H66.4124C67.0297 23.9891 67.3367 23.6692 67.3367 23.0296V7.47693L81.335 23.6788C81.4137 23.7822 81.5161 23.8652 81.6335 23.9209C81.7509 23.9765 81.88 24.0032 82.0099 23.9987H85.208C85.8253 23.9987 86.1355 23.6788 86.1355 23.0392V1.426C86.1451 0.786369 85.8349 0.466553 85.2272 0.466553Z"
        fill="url(#paint0_linear_458_596)"
      />
      <path
        d="M116.617 22.4636L107.614 11.6793L115.494 2.03367C115.63 1.8483 115.703 1.62403 115.702 1.39404C115.72 1.26855 115.708 1.14071 115.668 1.02057C115.627 0.900426 115.56 0.791262 115.47 0.701669C115.381 0.612077 115.271 0.544502 115.151 0.50426C115.031 0.464019 114.903 0.452211 114.778 0.469766H110.876C110.733 0.46588 110.59 0.496021 110.461 0.557726C110.331 0.61943 110.218 0.710942 110.131 0.824763L104.598 7.88311L99.0621 0.84715C98.975 0.722054 98.858 0.620792 98.7216 0.552629C98.5853 0.484465 98.4341 0.451577 98.2817 0.456973H94.3736C94.2482 0.439285 94.1204 0.451028 94.0004 0.491272C93.8804 0.531517 93.7713 0.599157 93.682 0.688837C93.5926 0.778518 93.5253 0.887776 93.4855 1.00796C93.4457 1.12814 93.4344 1.25594 93.4525 1.38124C93.4504 1.61138 93.5234 1.83593 93.6604 2.02088L101.537 11.6825L92.5666 22.4284C92.4064 22.6027 92.3184 22.8313 92.3203 23.068C92.3203 23.6821 92.6402 23.9891 93.2798 23.9891H97.3798C97.5315 23.9903 97.6813 23.9556 97.8169 23.8878C97.9526 23.82 98.0702 23.721 98.1602 23.5989L104.582 15.5459L111.036 23.6725C111.129 23.7769 111.244 23.8597 111.373 23.915C111.502 23.9702 111.641 23.9966 111.781 23.9923H115.897C116.022 24.0098 116.15 23.9981 116.269 23.9581C116.389 23.918 116.498 23.8506 116.587 23.7613C116.677 23.672 116.744 23.5632 116.784 23.4435C116.824 23.3237 116.836 23.1963 116.818 23.0712C116.829 22.8507 116.757 22.6341 116.617 22.4636Z"
        fill="url(#paint0_linear_458_596)"
      />
      <path
        d="M20.5153 11.6405C21.4665 10.4401 21.9788 8.95054 21.9672 7.41895C21.98 6.50108 21.8021 5.59055 21.4449 4.74497C21.0876 3.8994 20.5587 3.13719 19.8916 2.50657C19.2418 1.84769 18.4654 1.32723 17.609 0.97655C16.7526 0.625872 15.8341 0.452245 14.9089 0.466137H0.968068C0.837715 0.447405 0.704791 0.459308 0.579841 0.5009C0.45489 0.542493 0.341351 0.612631 0.248231 0.705751C0.155112 0.798871 0.0849732 0.912409 0.0433807 1.03736C0.0017881 1.16231 -0.0101144 1.29523 0.00861787 1.42559V23.0292C0.00861787 23.6688 0.328434 23.9886 0.968068 23.9886H16.1146C17.0416 24.0071 17.9628 23.8375 18.8225 23.4901C19.6822 23.1427 20.4625 22.6247 21.1165 21.9674C21.7813 21.3383 22.3082 20.5779 22.6639 19.7345C23.0195 18.8911 23.1961 17.983 23.1825 17.0678C23.2079 16.0152 22.9786 14.9721 22.5142 14.0272C22.0498 13.0823 21.3641 12.2635 20.5153 11.6405ZM4.69073 5.61518C4.67319 5.48928 4.6851 5.36103 4.7255 5.24051C4.76591 5.11999 4.83372 5.01048 4.92361 4.92059C5.01349 4.83071 5.123 4.7629 5.24353 4.72249C5.36405 4.68208 5.4923 4.67018 5.6182 4.68771H14.8321C15.1488 4.68811 15.4614 4.7589 15.7474 4.89496C16.0334 5.03103 16.2855 5.22897 16.4856 5.47446C16.934 6.01335 17.1796 6.69229 17.1796 7.39336C17.1796 8.09444 16.934 8.77337 16.4856 9.31226C16.2882 9.5619 16.0369 9.76374 15.7506 9.90273C15.4644 10.0417 15.1504 10.1143 14.8321 10.115H5.6182C5.49217 10.1332 5.36363 10.1218 5.24279 10.0816C5.12195 10.0415 5.01215 9.97367 4.92211 9.88363C4.83206 9.79359 4.76426 9.68378 4.7241 9.56294C4.68393 9.44211 4.67251 9.31356 4.69073 9.18753V5.61518ZM17.6913 18.9675C17.4929 19.2156 17.2414 19.416 16.9552 19.5538C16.669 19.6917 16.3555 19.7635 16.0378 19.7639H5.6182C5.00095 19.7639 4.69073 19.4441 4.69073 18.8044V15.2864C4.69073 14.6468 5.01055 14.327 5.6182 14.327H16.0378C16.3557 14.3282 16.6692 14.4007 16.9553 14.5391C17.2414 14.6774 17.4929 14.8782 17.6913 15.1265C18.1526 15.6579 18.4 16.3419 18.3853 17.0454C18.4055 17.7509 18.1575 18.4377 17.6913 18.9675Z"
        fill="url(#paint0_linear_458_596)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_458_596"
          x1="32.9009"
          y1="27.1229"
          x2="51.8053"
          y2="8.21854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0073D1" />
          <stop offset="0.06" stopColor="#0082CE" />
          <stop offset="0.22" stopColor="#00A0C9" />
          <stop offset="0.39" stopColor="#00B8C4" />
          <stop offset="0.57" stopColor="#00C9C1" />
          <stop offset="0.76" stopColor="#00D3C0" />
          <stop offset="1" stopColor="#00D6BF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const BanxaLogo = memo(BanxaLogoComponent)

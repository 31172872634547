import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonSpinner, IonTitle } from '@ionic/react'

import { BackButton } from '@/components/atoms/BackButton'
import { Button } from '@/components/atoms/Button'
import { CurrencyInput } from '@/components/atoms/CurrencyInput'
import { IconWithTooltip } from '@/components/atoms/IconWithTooltip'
import { Loading } from '@/components/atoms/Loading'
import { Title } from '@/components/atoms/Title'
import { Main } from '@/components/templates/main'

import { appConfig } from '@/services/config'
import { useSubscription } from '@/lib/hooks/subscription'
import { useHandleErrors } from '@/lib/hooks/subscription/useHandleErrors'
import { Routes } from '@/router/routes'
import { numberFormat } from '@/utils/currency'

const minPrice = 0

export const ConfigureSubscription = () => {
  const { t } = useTranslation('common')

  const currency = appConfig.currency

  const {
    control,
    handleSubmit,
    reset: resetForm,
    setError,
    register,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm<{ price: string }>({
    mode: 'onChange',
  })

  const {
    subscription,
    isLoadingSubscription,
    mutateSubscription,
    isUpdateSubscription,
    updateSubscriptionError: error,
  } = useSubscription()

  const { minRule, maxRule } = useHandleErrors({
    error,
    setError,
    register,
    trigger,
  })

  const errorMapping = useMemo(() => {
    return {
      min: t('configureSubscription.errors.min', {
        amount: numberFormat(minRule),
        currency,
      }),
      max: t('configureSubscription.errors.max', {
        amount: numberFormat(maxRule),
        currency,
      }),
    }
  }, [currency, maxRule, minRule, t])

  useEffect(() => {
    if (!subscription) return

    resetForm({
      price: String(subscription?.price ?? minPrice),
    })
  }, [subscription, resetForm, trigger])

  const onSubmit = useCallback(
    ({ price }) => {
      mutateSubscription({ price: Number(price) })
    },
    [mutateSubscription]
  )

  const header = useMemo(
    () => (
      <div className="flex justify-between items-center">
        <IonButtons slot="secondary">
          <BackButton className="text-white" defaultHref={Routes.PROFILE} />
        </IonButtons>
        <IonTitle>
          <Title level={3} className="text-ellipsis overflow-hidden font-bold">
            {t('profile.subscriptionSettings')}
          </Title>
        </IonTitle>
        <Button
          className="w-16 mr-2"
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isDirty || !isValid || isUpdateSubscription}
          size="small"
          type={isDirty && isValid ? 'primary' : 'secondary'}
        >
          {isUpdateSubscription ? (
            <IonSpinner className="w-5" color="light" />
          ) : (
            t('save')
          )}
        </Button>
      </div>
    ),
    [t, handleSubmit, onSubmit, isDirty, isValid, isUpdateSubscription]
  )

  return (
    <Main header={header} isPaddingEnabled={false}>
      {isLoadingSubscription ? (
        <Loading />
      ) : (
        <div className="flex flex-col px-6 py-4 text-white">
          <span className="text-2xl font-bold mb-3">
            {t('configureSubscription.title')}
          </span>
          <span className="text-sm mb-2 font-bold">
            {t('configureSubscription.price')}
            <IconWithTooltip
              className="subscription-info-popover"
              tooltipText={t('configureSubscription.priceTooltip')}
            />
          </span>
          <span className="text-sm text-cool-gray-200">
            {t('configureSubscription.description')}
          </span>
          <div className="w-full flex flex-row items-center mt-8 text-sm">
            <span>{t('configureSubscription.payForSubscriptionPrice')}</span>
            <Controller
              name="price"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <CurrencyInput
                  className="w-28 focus:outline-none bg-transparent mx-2 text-right text-2xl font-bold text-primary placeholder-primary border-b rounded-none pr-1"
                  onChange={(e) => field.onChange(e.target.value)}
                  value={String(field.value ?? minPrice)}
                />
              )}
            />
            <span className="font-bold">{appConfig.currency}</span>
          </div>
          {errors?.price && (
            <div className="text-sm pt-3 text-danger">
              {errorMapping[errors?.price.type]}
            </div>
          )}
        </div>
      )}
    </Main>
  )
}

export const Routes = {
  ROOT: '/',
  ACTIVITY: '/activity',
  AUTH_CONFIRM: '/auth/confirm',
  AUTH_CONSENT: '/auth/consent',
  AUTH_CREATE_INTERESTS_SURVEY: '/auth/create/interests-survey',
  AUTH_CREATE_PROFILE: '/auth/create/profile',
  AUTH_CREATE_PROFILE_AVATAR: '/auth/create/profile/avatar',
  AUTH_EMAIL: '/auth/email',
  AUTH_LOGIN: '/auth/log-in-option',
  AUTH_PHONE: '/auth/phone',
  AUTH_SIGN_UP: '/auth/sign-up-option',
  COMMENTS: '/comments/:activityId',
  DISCOVER: '/discover',
  FEED: '/feed',
  LIKES: '/likes/:activityId',
  TOP_SUPPORTERS: '/top-supporters/:userId',
  LOGIN: '/login',
  LOGOUT: '/logout',
  MESSAGES: '/messages',
  MESSAGES_USER: '/messages/:userId',
  MESSAGES_FIND_USERS: '/messages/find-users',
  MY_SUBSCRIBERS: '/my-subscribers',
  MY_SUBSCRIPTIONS: '/my-subscriptions',
  POST: '/post',
  PROFILE: '/profile',
  PROFILE_EDIT: '/edit-profile',
  SECURITY_SETTINGS: '/security-settings',
  SETUP_2FA: '/setup-2fa',
  STAKE: '/stake',
  TOKEN_LAUNCH: '/token/launch',
  PRICE_BOOSTER: '/token/price-booster',
  REMOVE_2FA: '/remove-2fa',
  CONFIGURE_CHAT_PAYWALL: '/configure-chat-paywall',
  CONFIGURE_SUBSCRIPTION: '/configure-subscription',
  PROFILE_USER: '/profile/:userId',
  RELATIONS_USER: '/relations/:userId',
  VALIDATE_EMAIL: '/validate-email',
  VAULT: '/vault',
  FACE_LIVENESS_CHECK: '/liveness/check',
  FACE_LIVENESS_INSTRUCTIONS: '/liveness/instructions',
  FACE_LIVENESS_FAILED: '/liveness/failed',
  WITHDRAW: '/withdraw',
  DEPOSIT: '/deposit',
  STAKE_VAULT: '/stake-vault',
  SOCIALS_CONNECT_INSTAGRAM: '/socials/instagram',
  SHARE_TARGET: '/share',
  SOCIALS_CONNECT_TWITTER: '/socials/twitter',
  SOCIALS_CONNECT_TIKTOK: '/socials/tiktok',
  USER_SEGMENT: '/:userId',
  TOKEN_SOCIALS_CONNECT: '/token/socials/connect',
} as const

import { FC, memo } from 'react'

type IPresentBoxProps = {
  className?: string
  isChecked: boolean
}

const PresentBoxComponent: FC<IPresentBoxProps> = ({
  className = '',
  isChecked,
}) => {
  const fillColor = isChecked ? 'white' : '#BEC8DA'

  return (
    <svg
      className={`w-full h-auto ${className}`}
      viewBox="0 0 37 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.8661 9H1.42287C0.637583 9 0 9.28286 0 9.63171V12.3683C0 12.7171 0.636666 13 1.42287 13H35.867C36.4927 13 37 12.7171 37 12.3683V9.63171C37 9.28286 36.4927 9 35.867 9H35.8661Z"
        fill={fillColor}
      />
      <path
        d="M21.2406 15H2V39.4992C2 40.3285 2.6056 41 3.35254 41H33.9451C34.5283 41 35 40.3275 35 39.4992V15H21.2416H21.2406Z"
        fill={fillColor}
      />
      <rect x="16" y="35" width="5" height="6" fill="#252F41" />
      <path
        d="M27.5253 2.06375C26.3123 0.232174 24.3941 -0.73604 21.8148 0.672047C19.4829 1.94498 18.5853 4.91187 18.5853 4.91187C18.5853 4.91187 17.6707 1.9458 15.2973 0.672047C12.6713 -0.73604 10.7183 0.232174 9.48247 2.06375C9.09086 2.64452 8.86709 3.33341 9.08578 4.08946C9.08578 4.08946 9.71049 7.12271 17.1909 8H19.955C27.3024 7.12271 27.9161 4.08946 27.9161 4.08946C28.1305 3.33341 27.9101 2.6437 27.5261 2.06375H27.5253ZM11.9635 4.93071C10.9218 4.0354 11.7363 2.79278 11.7363 2.79278C12.801 1.56982 14.2589 2.23577 14.5115 2.33488C14.5624 2.35454 14.6132 2.37175 14.6641 2.38977C17.1527 3.26542 17.8342 7.03261 17.8342 7.03261C13.9521 6.64925 11.9635 4.93071 11.9635 4.93071ZM25.0883 4.93071C25.0883 4.93071 23.1354 6.64843 19.3227 7.03261C19.3227 7.03261 19.9915 3.26542 22.4361 2.38977C22.4861 2.37175 22.5369 2.35454 22.5861 2.33488C22.8345 2.23659 24.2661 1.56982 25.3113 2.79278C25.3113 2.79278 26.1114 4.0354 25.0883 4.93071Z"
        fill={fillColor}
      />
      <rect x="16" y="9" width="5" height="5" fill="#252F41" />
      {isChecked ? (
        <path
          d="M26.25 20.75L15.75 31.25L10.5 26"
          stroke="#0E1117"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <path
          d="M15.9791 31L21.0807 21.0078V20.9766H15.0104V19.7266H22.4713V21.0312L17.4557 31H15.9791Z"
          fill="#131820"
        />
      )}
    </svg>
  )
}

export const PresentBox = memo(PresentBoxComponent)

import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/Button'

import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

import { QueryKeys } from '@/enums'

interface SocialsItemProps {
  Icon: ReactNode
  providerUserName: string
  followerCount: string
  redirectLink: string
}

export const SocialsItem: FC<SocialsItemProps> = ({
  Icon,
  providerUserName,
  followerCount,
  redirectLink,
}) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  return (
    <div className="flex items-center justify-center py-6 px-12">
      <div className="flex justify-center flex-1">{Icon}</div>
      <div className="flex-1">
        {providerUserName ? (
          <div className="flex flex-col text-sm">
            <span>{providerUserName}</span>
            <span className="text-base">
              <span className="font-bold mr-1">{followerCount}</span>
              {t('tokenSocialsConnect.followers')}
            </span>
          </div>
        ) : (
          <Button
            onClick={() => {
              navigate(redirectLink)
              queryClient.setQueryData(
                [QueryKeys.SocialBackUrl],
                Routes.TOKEN_SOCIALS_CONNECT
              )
            }}
            fill="outline"
          >
            <span className="text-xxs xs:text-xs">
              {t('tokenSocialsConnect.connectVerify')}
            </span>
          </Button>
        )}
      </div>
    </div>
  )
}

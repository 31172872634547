import { FC, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '@/components/atoms/Loading'
import { Main } from '@/components/templates/main'
import { Error } from './components/Error'
import { Header } from './components/Header'
import { PostsGrid } from './components/PostsGrid'
import { ProfileHeader } from './components/ProfileHeader'

import { useParams } from '@/lib/routing'
import { Context } from '@/lib/store'
import { ActionType } from '@/lib/store/reducer'

import { usePublicUserProfile } from './hooks/usePublicUserProfile'

export const PublicUserProfile: FC = () => {
  const { t } = useTranslation('common')
  const { dispatch } = useContext(Context)

  const { userId } = useParams<{ userId: UUID }>()

  const { userInfo, wallet, isLoading, isError } = usePublicUserProfile({
    userId,
  })

  useEffect(() => {
    dispatch({
      type: ActionType.SIGN_IN_REDIRECT,
      payload: window.location.pathname + window.location.search,
    })
  }, [dispatch])

  if (isError) return <Error />

  return (
    <Main
      header={<Header userId={userId} />}
      headTitle={t('common.head.title.profile')}
      isPaddingEnabled={false}
    >
      {!isLoading ? (
        <>
          <ProfileHeader userInfo={userInfo} wallet={wallet} />
          <PostsGrid
            username={userId}
            posts={userInfo?.posts}
            creatorTokenId={userInfo?.creatorTokenId}
            hasSubscription={userInfo?.hasSubscription}
          />
        </>
      ) : (
        <Loading />
      )}
    </Main>
  )
}

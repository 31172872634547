import { confirmSignIn } from 'aws-amplify/auth'
import { getCurrentUser } from 'aws-amplify/auth'

import { ICaptchaResult } from '@/components/atoms/GeeCaptcha'

import {
  authSignIn,
  get,
  post,
  recordPageView,
  recordStartSession,
} from '@/services/awsAmplify'
import { queryClient } from '@/lib/queryClient'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { getLanguage } from '@/utils/getLanguage'

import { AuthError, QueryKeys } from '@/enums'

import { appConfig } from './config'
import { UserInfo } from './user'

export type AUTH = 'EMAIL' | 'PHONE_NUMBER'

export enum AUTH_TYPE {
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL = 'EMAIL',
}

export enum AUTH_STEP {
  CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE = 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE',
  CAPTCHA = 'CAPTCHA',
}

export const signUp = async ({ username, token }) => {
  let signUpResponse
  try {
    signUpResponse = await post({
      endpoint: 'notAuthenticated',
      path: '/users',
      params: {
        email: username,
        metadata: {
          captchaId: token?.captcha_id,
          genTime: token?.gen_time,
          lotNumber: token?.lot_number,
          passToken: token?.pass_token,
          captchaOutput: token?.captcha_output,
        },
      },
      headers: {
        Authorization: `Bearer empty`,
      },
    })
  } catch (error) {
    if (error.message.includes('Email is blocked')) {
      throw new Error(AuthError.SignUpBlockedDomain)
    }

    throw error
  }

  return signUpResponse
}

type SignInResponseType = {
  isSignedIn: boolean
  nextStep: Record<string, unknown> & {
    userAttributes?: {
      email?: string
      phone_number?: string
    }
  }
}

const sendCaptchaCustomChallenge = async (
  signInResponse: SignInResponseType,
  token: ICaptchaResult,
  authType: AUTH
) => {
  try {
    const challengeResponse = JSON.stringify({
      genTime: token?.gen_time,
      lotNumber: token?.lot_number,
      passToken: token?.pass_token,
      captchaOutput: token?.captcha_output,
      captchaId: token?.captcha_id,
    })

    const bcp47LanguageTag = getLanguage()

    const result = await confirmSignIn({
      challengeResponse: challengeResponse,
      options: {
        userAttributes: {
          email: signInResponse?.nextStep?.userAttributes?.email,
          phone_number: signInResponse?.nextStep?.userAttributes?.phone_number,
        },
        clientMetadata: {
          authType,
          bcp47LanguageTag,
        },
      },
    })

    const additionalInfo =
      result && 'additionalInfo' in result.nextStep
        ? result.nextStep?.additionalInfo
        : null

    if (additionalInfo?.challengeName === AUTH_STEP.CAPTCHA) {
      return Promise.reject(new Error(AuthError.Captcha))
    }

    return result
  } catch (error) {
    console.error(`send captcha custom challenge error: ${error}`)
    if (error.message.includes('Email is blocked')) {
      throw new Error(AuthError.SignInBlockedDomain)
    }

    if (error.message.includes('Try later')) {
      throw new Error(AuthError.TryLater)
    }
  }
}

export interface AuthUser {
  captchaResponse?: UserInfo
  authType?: AUTH
}

const newUserKey = [QueryKeys.IsNewUser]

const setNewUserFlag = () => {
  queryClient.setQueryData(newUserKey, () => {
    queryClient.setQueryDefaults(newUserKey, {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    })
    return true
  })
}

export const signIn = async ({
  username,
  token,
  authType,
}: {
  username: string
  token: ICaptchaResult
  authType: AUTH
}): Promise<void> | never => {
  try {
    const signInResponse = await authSignIn({
      username,
      authFlowType: 'CUSTOM_WITHOUT_SRP',
    })

    queryClient.removeQueries({ queryKey: [newUserKey] })

    if (
      signInResponse?.nextStep?.signInStep ===
      AUTH_STEP.CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE
    ) {
      try {
        const captchaResponse = await sendCaptchaCustomChallenge(
          signInResponse,
          token,
          authType
        )

        queryClient.setQueryData(['captchaResponse'], {
          captchaResponse: { ...captchaResponse, username },
          authType,
        })
      } catch (err) {
        console.error(`captcha response error: ${err}`)
      }
    }
  } catch (error) {
    console.error(`auth sign in error: ${error}`)
    if (error?.toString().includes(AuthError.Captcha)) {
      throw new Error(AuthError.Captcha)
    }
    if (error?.toString().includes(AuthError.DisabledUser)) {
      throw new Error(AuthError.DisabledUser)
    }
    if (error?.toString().includes(AuthError.UserNotFound)) {
      if (authType === AUTH_TYPE.PHONE_NUMBER) {
        throw new Error(AuthError.UserNotFound)
      }

      try {
        await signUp({
          username,
          token,
        })
        const data: { refUsername?: string } = queryClient.getQueryData([
          'referral',
        ])
        setNewUserFlag()
        Tracking.triggerEvent(TrackingEvent.SignUpStarted, {
          refUsername: data?.refUsername,
          authMethod: authType,
        })
        return signIn({
          username,
          token,
          authType,
        })
      } catch (e) {
        throw new Error(e)
      }
    }
    if (
      error.message.includes(AuthError.SignInBlockedDomain) ||
      error.message.includes(AuthError.TryLater)
    ) {
      throw error
    }
  }
}

export const sendOTP = async ({ code, refUsername, redirectUrl }) => {
  try {
    const response = await confirmSignIn({
      challengeResponse: code,
      options: {
        clientMetadata: {
          refUsername,
          redirectUrl,
        },
      },
    })
    if (appConfig.kinesis) {
      try {
        recordStartSession()
        recordPageView()
      } catch (e) {
        console.error(`configure auto track error: ${e}`)
      }
    }
    return response
  } catch (error) {
    console.error(`send otp error: ${error}`)
  }
}

export const verifyAge = async () => {
  const { username } = await getCurrentUser()
  const data = await post({
    path: `/profile/${username}/consent`,
    params: {
      ageConsent: true,
    },
  })

  return data as Partial<UserInfo>
}

export const getEmailForAppleUserId = async (args: {
  appleUserId: string
  token: string
}) => {
  const data = await get({
    path: '/profile/email-for-apple-user',
    params: {
      q: args.appleUserId,
    },
    headers: {
      Authorization: `Bearer ${args.token}`,
    },
    authenticate: false,
  })

  return data?.email as string
}

import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  redeem as stakesRedeem,
  reStake as stakesReStake,
} from '@/services/stake'
import { UserInfo } from '@/services/user'
import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'
import { WALLET } from '@/utils/route'

import { QueryKeys, TransactionsType } from '@/enums'

import { useTransactions, useWallet } from '../wallet'

export const useStake = ({ userInfo }: { userInfo: UserInfo }) => {
  const { t } = useTranslation('common')

  const navigate = useNavigate()
  const [presentToast] = useIonToast()

  const { refetchWallet } = useWallet({
    userInfo,
    enabled: false,
  })

  const { refetchFirstTransactionsPage: refetchFirstStakeTransactionsPage } =
    useTransactions({
      userInfo,
      enabled: false,
      transactionsType: TransactionsType.Stake,
    })

  const {
    mutate: redeem,
    isPending: isRedeemPending,
    error: redeemError,
  } = useMutation({
    mutationKey: [QueryKeys.StakesRedeem],
    mutationFn: stakesRedeem,
    onSuccess: () => {
      presentToast({
        header: t('wallet.stake.toasts.successHeader'),
        message: t('wallet.stake.toasts.successRedeemedMessage'),
        position: 'top',
        duration: 3000,
      })
      queryClient.invalidateQueries({ queryKey: ['account'], exact: true })
      navigate(WALLET, { replace: true })
    },
  })

  const {
    mutate: reStake,
    isPending: isReStakePending,
    error: reStakeError,
  } = useMutation({
    mutationKey: [QueryKeys.StakesReStake],
    mutationFn: stakesReStake,
    onSuccess: () => {
      presentToast({
        header: t('wallet.stake.toasts.successHeader'),
        message: t('wallet.stake.toasts.successReStakeMessage'),
        position: 'top',
        duration: 3000,
      })
      refetchWallet()
      refetchFirstStakeTransactionsPage()
      queryClient.invalidateQueries({ queryKey: ['account'], exact: true })
    },
  })

  return {
    redeem,
    reStake,
    isReStakePending,
    isRedeemPending,
    redeemError: redeemError as AxiosError,
    reStakeError: reStakeError as AxiosError,
  }
}

import { FC } from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@/components/atoms/Button'
import { UserName } from '@/components/atoms/UserName'

interface StoxSectionTemplateProps {
  title: string
  subTitle?: string | undefined
  buttonText?: string
  onClick?: () => void
  userName?: string
}
export const StoxSectionTemplate: FC<StoxSectionTemplateProps> = ({
  title,
  subTitle,
  buttonText,
  onClick,
  userName,
}) => {
  return (
    <div className="absolute top-12 inset-x-0 px-2">
      <h1 className="text-[1.5rem]">
        <Trans
          components={{
            primary: <span className="text-primary" />,
            userLink: <UserName className="inline font-bold" name={userName} />,
          }}
        >
          {title}
        </Trans>
      </h1>
      {subTitle && (
        <p className="pt-5 text-[1rem] text-cool-gray-200 text-sm">
          <Trans>{subTitle}</Trans>
        </p>
      )}
      {buttonText && (
        <Button onClick={onClick} className="mt-7 mx-auto">
          <span className="text-sm px-6">{buttonText}</span>
        </Button>
      )}
    </div>
  )
}

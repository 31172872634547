import { FC, memo } from 'react'

type ActivityItemPreviewImageProps = {
  previewImageUrl: string
  className?: string
}

const ActivityItemPreviewImageComponent: FC<ActivityItemPreviewImageProps> = ({
  previewImageUrl,
  className = '',
}) => (
  <img
    className={`w-[48px] h-auto aspect-square object-cover ml-4 border-cool-gray-600 border-[1px] ${className}`}
    src={previewImageUrl}
  />
)

export const ActivityItemPreviewImage = memo(ActivityItemPreviewImageComponent)

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getCurrentUser } from 'aws-amplify/auth'

import { get } from '@/services/awsAmplify'

export interface IUserTokens {
  getStreamToken: string
  oneSignalUserIdToken: string
}

export const useToken = (
  options?: Omit<UseQueryOptions<IUserTokens>, 'queryKey'>
) =>
  useQuery<IUserTokens>({
    queryKey: ['getToken'],
    queryFn: getToken,
    ...options,
    gcTime: Infinity,
    staleTime: Infinity,
    retry: true,
  })

export const getToken = async () => {
  const { username } = await getCurrentUser()
  return get({
    path: `/profile/${username}/tokens`,
  }).then((res) => {
    if (!res.getStreamToken || !res.oneSignalUserIdToken) {
      throw new Error('Tokens are not available yet')
    }

    return res
  })
}

import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonicSafeString, IonToast, ToastButton } from '@ionic/react'
import cx from 'classnames'
import { close } from 'ionicons/icons'

import { useNavigate } from '@/lib/routing'
import { WALLET_SEARCH_XP } from '@/utils/route'

import { IonToastCustomEvent, OverlayEventDetail } from '@/interfaces'

type OpenAppToastReminderProps = {
  streakDay: number
  streakDuration: number
  shouldShowOpenAppReminder: boolean
}

const OpenAppToastReminderComponent: FC<OpenAppToastReminderProps> = ({
  streakDay,
  streakDuration,
  shouldShowOpenAppReminder,
}) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const toastRef = useRef<HTMLIonToastElement>()
  const [isOpen, setIsOpen] = useState(false)

  const isCompleted = streakDay >= streakDuration

  useEffect(() => {
    if (shouldShowOpenAppReminder && streakDay && streakDay !== 1)
      setIsOpen(true)
  }, [shouldShowOpenAppReminder, streakDay])

  const toastTitle = useMemo(
    () =>
      isCompleted
        ? t('XPWallet.openAppStreakReminder.streakCompleteTitle')
        : null,
    [isCompleted, t]
  )

  const toastMessage = useMemo(
    () =>
      isCompleted
        ? t('XPWallet.openAppStreakReminder.streakCompleteMessage')
        : t('XPWallet.openAppStreakReminder.streakInProgress', {
            count: streakDuration - streakDay,
          }),
    [isCompleted, streakDuration, streakDay, t]
  )

  const handleOnClick = () => {
    toastRef.current.dismiss()
  }

  const handleOnWillDismiss = (
    role: IonToastCustomEvent<OverlayEventDetail>['detail']['role']
  ) => {
    if (role === 'cancel' || role === 'timeout') return

    navigate(WALLET_SEARCH_XP)
    setIsOpen(false)
  }

  const toastButtons: ToastButton[] = [
    {
      icon: close,
      side: 'end',
      role: 'cancel',
    },
  ]

  return (
    <IonToast
      ref={toastRef}
      isOpen={isOpen}
      buttons={toastButtons}
      message={new IonicSafeString(toastMessage)}
      position="top"
      color="primary"
      cssClass={cx('open-app-reward', {
        'open-app-reward-completed': isCompleted,
      })}
      duration={8000}
      onClick={handleOnClick}
      header={toastTitle}
      onWillDismiss={(e) => handleOnWillDismiss(e.detail.role)}
    />
  )
}

export const OpenAppToastReminder = memo(OpenAppToastReminderComponent)

import { put } from '@/services/awsAmplify'

export const reportUser = ({ userId }: { userId: UUID }) => {
  return put({
    path: `/profile/${userId}/report`,
  })
}
export const reportVideo = ({
  contestId,
  activityId,
  userId,
}: {
  contestId: UUID
  activityId: UUID
  userId: UUID
}) => {
  return put({
    path: `/contest/${contestId}/report-video`,
    params: {
      activityId,
      userId,
    },
  })
}

export const reportPost = ({ activityId }: { activityId: string }) => {
  return put({
    path: `/report/${activityId}`,
    params: {},
  })
}

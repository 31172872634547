import { memo } from 'react'
import { IonRefresher, IonRefresherContent } from '@ionic/react'
import { refreshOutline } from 'ionicons/icons'

import { IRefresher, RefresherEventDetail } from 'interfaces'

function createDoRefresh<T>(refetch: () => Promise<T>) {
  return async (event: CustomEvent<RefresherEventDetail>) => {
    await refetch().then(() => {
      event.detail.complete()
    })
  }
}

const RefresherComponent = ({ refetch, disabled, className }: IRefresher) => {
  if (!refetch) return null

  return (
    <IonRefresher
      slot="fixed"
      disabled={disabled}
      onIonRefresh={createDoRefresh(refetch)}
      className={className}
    >
      <IonRefresherContent
        pullingIcon={refreshOutline}
        refreshingSpinner="dots"
      />
    </IonRefresher>
  )
}

export const Refresher = memo(RefresherComponent)

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { createTransaction as _createTransaction } from '@/services/wallet'
import {
  CreateTransactionData,
  CreateTransactionVariables,
} from '@/services/wallet/createTransaction'

import { QueryKeys } from '@/enums'

import { useErrorBoundary } from '../../useErrorBoundary'
import { useUserInfo } from '../../useUserInfo'

type UseCreateTransaction = {
  userId: UUID
  options?: UseMutationOptions<
    CreateTransactionData,
    unknown,
    CreateTransactionVariables
  >
}

type AxiosErrorData = {
  message?: string
}

export const useCreateTransaction = ({
  userId,
  options = {},
}: UseCreateTransaction) => {
  const [present] = useIonToast()
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const { data: authUser } = useUserInfo()
  const { handleError } = useErrorBoundary()

  const { data, mutate, isPending, reset, error } = useMutation({
    mutationFn: _createTransaction,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.Wallet, data?.walletId],
      })
    },
    onError: (error) => {
      const errorData = (error as AxiosError<AxiosErrorData>)?.response?.data

      if (errorData?.message) {
        present({
          message: errorData.message,
          duration: 1500,
          position: 'top',
        })
      } else {
        handleError(error as AxiosError<AxiosErrorData>)
      }
    },
    ...options,
  })

  const createTransaction = useCallback(
    async ({
      value,
      type,
    }: {
      value: string
      type: 'tip' | 'chat' | 'subscription'
    }) => {
      if (!(userId && authUser)) return

      let errorMessage: string
      const isNumber = !!value && !isNaN(+value)

      if (!isNumber) errorMessage = t(`${type}.errors.invalidNumber`)
      if (value === '0' || value.startsWith('-')) {
        errorMessage = t(`${type}.errors.negativeNumber`)
      }

      if (errorMessage) {
        return present({
          message: errorMessage,
          duration: 1500,
          position: 'top',
        })
      }

      mutate({
        walletId: authUser?.id,
        transactionData: {
          amount: value,
          type,
          destinationWalletId: userId,
        },
      })
    },
    [userId, authUser, t, mutate, present]
  )

  return {
    createTransaction,
    createTransactionData: data,
    isLoadingTransactionData: isPending,
    resetTransactionData: reset,
    error: (error as AxiosError)?.response?.data,
  }
}

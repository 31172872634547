import { memo } from 'react'

const DiamondComponent = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        d="M9.00021 15.5C8.83362 15.5 8.67796 15.4283 8.57236 15.3029L0.127333 5.2127C-0.0419864 5.01111 -0.0419864 4.72351 0.124602 4.52192V4.52013L3.5383 0.686358C3.64389 0.568092 3.79592 0.5 3.95522 0.5H14.0443C14.2045 0.5 14.3565 0.568092 14.4612 0.686358L17.8649 4.50938C18.0415 4.70917 18.046 5.00573 17.8722 5.2127L9.42715 15.302C9.32246 15.4274 9.16589 15.4991 8.99839 15.4991L9.00021 15.5ZM9.00021 13.3219L11.8504 5.4116H6.15L9.00021 13.3219ZM7.42445 12.2137L4.97387 5.4116H1.7304L7.42354 12.2137H7.42445ZM10.5769 12.2137L16.27 5.4116H13.0275L10.5769 12.2137ZM16.2218 4.31944L13.793 1.59127H10.2792L12.8754 4.31944H16.2209H16.2218ZM11.3561 4.31944L9.00021 1.84393L6.6443 4.31944H11.3561ZM5.12498 4.31944L7.72121 1.59127H4.20738L1.77865 4.31944H5.12498Z"
        fill="white"
      />
    </svg>
  )
}

export const Diamond = memo(DiamondComponent)

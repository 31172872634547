import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { Pagination, Virtual } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { LazyImg } from '@/components/molecules/LazyImg'
import { TapToLikeWrapper } from '@/components/molecules/TapToLikeWrapper'
import { VideoContainer } from '@/components/molecules/VideoContainer'

import { usePlaybackState } from '@/lib/hooks/usePlaybackState'
import { useTrackViewedActivity } from '@/lib/hooks/useTrackViewedActivity'
import { MediaType } from '@/lib/hooks/useTrackViewedActivity/useTrackViewedActivity'
import { TrackingEvent } from '@/lib/tracking/enums'

import { IMedia } from '@/interfaces'

interface IPostFeedSlider {
  initialSlide?: number
  paddingBottom: string
  media: IMedia[]
  containerRatio: number
  isLikeActive: boolean
  inView: boolean
  isActiveActivity: boolean
  containerHeight: number
  handleDoubleClick: () => void
  activityId: UUID
  itemId: UUID
  mediaContainerRef: (node?: Element | null) => void
  isScrollingFast: boolean
  isZooming: boolean
  setIsZooming: Dispatch<SetStateAction<boolean>>
  foreignId: string
  recommendationId?: string
  isSubscriptionContent?: boolean
}

export const PostFeedSlider = ({
  initialSlide = 0,
  paddingBottom,
  media,
  containerRatio,
  handleDoubleClick,
  isLikeActive,
  inView,
  isActiveActivity,
  containerHeight,
  activityId,
  itemId,
  mediaContainerRef,
  isScrollingFast,
  isZooming,
  setIsZooming,
  foreignId,
  recommendationId,
  isSubscriptionContent,
}: IPostFeedSlider) => {
  const { isPlaybackEnabled } = usePlaybackState()
  const [index, setIndex] = useState<number>(initialSlide)
  const [videoLoadedMilliseconds, setVideoLoadedMilliseconds] = useState(0)

  const { onSlideChange } = useTrackViewedActivity({
    inViewport: inView,
    eventName: TrackingEvent.FeedItemViewed,
    activityId,
    itemId,
    eventProperties: {
      ...(media?.[index]?.type === 'video'
        ? { videoLoadedDuration: videoLoadedMilliseconds }
        : null),
      mediaType:
        media?.[index]?.type === 'video' ? MediaType.Video : MediaType.Image,
    },
    contentType: 'post',
    foreignId,
    recommendationId,
    isSubscriptionContent,
  })

  const isCurrentSlide = useCallback(
    (sliderIndex: number) => {
      return index === sliderIndex
    },
    [index]
  )

  const renderImage = useCallback(
    (src: string) => {
      return (
        <div className="bg-gradient-to-b from-cool-gray-800 from-0% via-black via-50% to-cool-gray-800 to-100%">
          <LazyImg
            isLazy={false}
            src={src}
            imageAspectRatio={containerRatio}
            setIsZooming={setIsZooming}
          />
        </div>
      )
    },
    [containerRatio, setIsZooming]
  )

  const onVideoLoadedMilliseconds = (milliseconds: number) =>
    setVideoLoadedMilliseconds(milliseconds)

  const renderVideo = useCallback(
    ({ video, videoPreview, slideIndex }) => {
      return (
        <VideoContainer
          video={video}
          videoPreview={videoPreview}
          isPlaying={
            isPlaybackEnabled && isCurrentSlide(slideIndex) && isActiveActivity
          }
          isVideoReset
          isProgressBarVisible
          videoContainerHeight={containerHeight}
          videoAllowedToLoad={!isScrollingFast}
          onVideoLoadedMilliseconds={onVideoLoadedMilliseconds}
        />
      )
    },
    [
      containerHeight,
      isActiveActivity,
      isCurrentSlide,
      isPlaybackEnabled,
      isScrollingFast,
    ]
  )

  return (
    <div ref={mediaContainerRef}>
      <Swiper
        style={{ overflowY: 'visible' }}
        enabled={!isZooming}
        modules={[Virtual, Pagination]}
        initialSlide={initialSlide}
        direction="horizontal"
        className="feed"
        onSlideChange={(e) => {
          !isNaN(e.snapIndex) && setIndex(e.snapIndex)
          onSlideChange({
            currentIndex: e.snapIndex,
            prevIndex: index,
            eventProperties: {
              ...(media[index]?.type === MediaType.Video
                ? { videoDuration: videoLoadedMilliseconds }
                : null),
              mediaType:
                media[index]?.type === 'video'
                  ? MediaType.Video
                  : MediaType.Image,
            },
            contentType: 'post',
          })
        }}
        slidesPerView={1}
        preventInteractionOnTransition
        virtual={{ addSlidesAfter: 1, addSlidesBefore: 1 }}
        pagination={!isZooming && { clickable: false }}
      >
        {media?.map((item, index: number) => (
          <SwiperSlide
            style={{
              paddingBottom,
            }}
            key={index}
            virtualIndex={index + 1}
            className="bg-cool-gray-950 cursor-pointer relative"
          >
            <TapToLikeWrapper
              handleDoubleClick={handleDoubleClick}
              isLikeActive={isLikeActive}
            >
              {item?.type === MediaType.Video
                ? renderVideo({
                    video: item.videoUrl,
                    videoPreview: item.imageUrl,
                    slideIndex: index,
                  })
                : renderImage(item?.imageUrl)}
            </TapToLikeWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Currency } from '@/components/atoms/Currency'
import { StakeButton } from '@/components/molecules/StakeButton'

import { toTokenCentsString } from '@/utils/token'

interface IHeaderComponent {
  stakedBalance: string
}

const HeaderComponent: FC<IHeaderComponent> = ({ stakedBalance }) => {
  const { t } = useTranslation('common')

  return (
    <div className="flex flex-row items-center justify-between px-8 pb-3">
      <div className="flex flex-col">
        <span className="font-bold">
          {t('wallet.stakeVault.lockedInStaking')}
        </span>

        <span className="text-sm font-normal">
          {stakedBalance && (
            <span title={toTokenCentsString(stakedBalance)}>
              <Currency value={stakedBalance} />
            </span>
          )}
        </span>
      </div>
      <StakeButton text={t('wallet.stake.stakeMore')} />
    </div>
  )
}

export const Header = memo(HeaderComponent)

import React, { FC, useCallback, useMemo } from 'react'
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import {
  BaseImage,
  ModalGalleryProps,
  useTranslationContext,
} from 'stream-chat-react'

import { Zoomable } from '@/components/molecules/Zoomable'

const onError: React.ReactEventHandler<HTMLImageElement> = (e) => {
  // Prevent having alt attribute on img as the img takes the height of the alt text
  // instead of the CSS / element width & height when the CSS mask (fallback) is applied.
  ;(e.target as HTMLImageElement).alt = ''
}

type CustomModalGalleryProps = ModalGalleryProps & {
  toggleModal: (selectedIndex?: number) => void
}

export const CustomModalGallery: FC<CustomModalGalleryProps> = ({
  images,
  index,
  toggleModal,
}) => {
  const { t } = useTranslationContext('ModalGallery')

  const formattedArray = useMemo(
    () =>
      images.map((image) => {
        const imageSrc = image.image_url || image.thumb_url || ''
        return {
          original: imageSrc,
          originalAlt: t('User uploaded content'),
          source: imageSrc,
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [images]
  )

  const renderItem = useCallback(
    ({ original, originalAlt }: ReactImageGalleryItem) => (
      <Zoomable>
        <BaseImage
          alt={originalAlt}
          className="image-gallery-image"
          onError={onError}
          src={original}
          onClick={() => toggleModal(index)}
        />
      </Zoomable>
    ),
    [index, toggleModal]
  )

  return (
    <ImageGallery
      items={formattedArray}
      renderItem={renderItem}
      showIndex={true}
      showPlayButton={false}
      showThumbnails={false}
      startIndex={index}
      showFullscreenButton={false}
    />
  )
}

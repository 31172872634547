import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { App } from '@capacitor/app'
import debounce from 'lodash/debounce'

import { MainTitle } from '@components/molecules/MainTitle'
import { Entry } from '@components/templates/entry'
import { InfiniteScrollLoader } from '@/components/atoms/InfiniteScrollLoader'
import { Loading } from '@/components/atoms/Loading'
import { InviteFriendButton } from '@/components/molecules/InviteFriendButton'
import { NoResults } from '@/components/molecules/NoResults'
import { ExplorerForm } from '@/components/organisms/ExplorerForm'
import { UsersListItem } from '@/components/organisms/UsersListItem'
import { VirtualList } from '@/components/organisms/VirtualList'
import { BannersPromoWidget } from '@/components/pages/DiscoverFeed/components/BannersPromoWidget'
import { CreatorsPromoWidget } from '@/components/pages/DiscoverFeed/components/CreatorsPromoWidget'
import { TrendingFeed } from '@/components/pages/DiscoverFeed/components/TrendingFeed'
import { SelectedCategoryComponent } from './components'
import { CategoryFeed } from './components/CategoryFeed'
import { CreatorsSlider } from './components/CreatorsSlider'

import { Category } from '@/services/categories'
import { useFindUsers } from '@/lib/hooks/useFindUsers'
import { useHandleVirtuosoScrollToTop } from '@/lib/hooks/useHandleVirtuosoScrollToTop'
import { useLocation } from '@/lib/routing'
import { Tracking, TrackingEvent } from '@/lib/tracking'

import { Pages, PromoPoolName } from '@/enums'
import { IPromoPool } from '@/interfaces'

import { useHandleHardwareBackPress } from './hooks/useHandleHardwareBackPress'
import { usePromoPools } from './hooks/usePromoPools'
import { useTopCreators } from './hooks/useTopCreators'

App.addListener('resume', () => {
  const pathname = window.location.pathname
  pathname === `/${Pages.Discover}` &&
    Tracking.triggerEvent(TrackingEvent.Discover)
})

export const DiscoverFeed: FC = () => {
  const location = useLocation()
  const { pathname = '' } = location
  useEffect(() => {
    pathname === `/${Pages.Discover}` &&
      Tracking.triggerEvent(TrackingEvent.Discover)
  }, [pathname])
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedCategory, setSelectedCategory] = useState<Category>(null)
  const { t } = useTranslation('common')
  const { virtuosoRef, scrollToTop } = useHandleVirtuosoScrollToTop()
  useHandleHardwareBackPress({ selectedCategory, setSelectedCategory })

  const {
    data: pools,
    refetch: poolsRefetch,
    isLoading: isPoolsLoading,
  } = usePromoPools()

  const creatorsPool = pools.find(
    (pool: IPromoPool) => pool.poolName === PromoPoolName.Creators
  )
  const bannersPool = pools.find(
    (pool: IPromoPool) => pool.poolName === PromoPoolName.Banners
  )

  const {
    topCreators,
    refetch: refetchTopCreators,
    isTopCreatorsLoading,
  } = useTopCreators()

  const {
    data: usersData,
    fetchNextPage: fetchUsersDataNextPage,
    hasNextPage: hasUsersDataNextPage,
    isLoading: isUsersListLoading,
  } = useFindUsers(
    {
      q: searchQuery,
    },
    {
      enabled: !!searchQuery,
    }
  )

  const Header = useCallback(() => {
    if (searchQuery ? null : isPoolsLoading || isTopCreatorsLoading) {
      return <Loading />
    }

    const showBannersPool = bannersPool && bannersPool.active
    const showCreatorsPool = creatorsPool && creatorsPool.active

    if (!showBannersPool && !showCreatorsPool) {
      return <></>
    }

    return (
      <div className="pb-[0.875rem]">
        {bannersPool && bannersPool.active && (
          <BannersPromoWidget pool={bannersPool} />
        )}
        <CreatorsSlider users={topCreators} />
        {creatorsPool && creatorsPool.active && (
          <CreatorsPromoWidget pool={creatorsPool} />
        )}
      </div>
    )
  }, [
    searchQuery,
    isPoolsLoading,
    isTopCreatorsLoading,
    bannersPool,
    topCreators,
    creatorsPool,
  ])

  const inviteLink = useCallback(
    () => (
      <div className="p-4 pb-2">
        <InviteFriendButton
          buttonLabel={t('profile.invite.buttonLabel')}
          className="p-0 white w-full !text-sm !h-6"
        />
      </div>
    ),
    [t]
  )

  const userResultsListFooter = useCallback(
    () => <InfiniteScrollLoader hasNext={hasUsersDataNextPage} />,
    [hasUsersDataNextPage]
  )
  const userResultsListHeader = useCallback(
    () => (usersData?.length ? inviteLink() : null),
    [usersData, inviteLink]
  )

  const userResultsListItemContent = useCallback(
    (index) => <UsersListItem item={usersData[index]} />,
    [usersData]
  )

  const userResultsList = useCallback(
    () => (
      <>
        {!usersData?.length && !isUsersListLoading && (
          <div className="mx-auto max-w-[40rem]">
            {inviteLink()}
            <NoResults />
          </div>
        )}

        <VirtualList
          className="h-full mx-auto max-w-[40rem] ion-content-scroll-host"
          data={usersData}
          endReached={() => fetchUsersDataNextPage()}
          components={{
            Footer: userResultsListFooter,
            Header: userResultsListHeader,
          }}
          itemContent={userResultsListItemContent}
          virtualListRef={virtuosoRef}
        />
      </>
    ),
    [
      usersData,
      isUsersListLoading,
      inviteLink,
      userResultsListFooter,
      userResultsListHeader,
      userResultsListItemContent,
      fetchUsersDataNextPage,
      virtuosoRef,
    ]
  )

  const refetchPromoData = useCallback(async () => {
    await Promise.all([poolsRefetch(), refetchTopCreators()])
  }, [poolsRefetch, refetchTopCreators])

  const results = useCallback(() => {
    if (selectedCategory) {
      return <CategoryFeed category={selectedCategory} />
    }
    if (searchQuery) {
      return isUsersListLoading ? <Loading /> : userResultsList()
    }

    return (
      <TrendingFeed
        feedGroup="trending"
        header={Header}
        virtuosoRef={virtuosoRef}
        refetch={refetchPromoData}
      />
    )
  }, [
    refetchPromoData,
    selectedCategory,
    searchQuery,
    Header,
    virtuosoRef,
    isUsersListLoading,
    userResultsList,
  ])

  useEffect(() => {
    if (!pools?.length) {
      poolsRefetch()
    }
    refetchTopCreators()
  }, [pools, poolsRefetch, refetchTopCreators])

  const onSubmit = debounce(async (data) => {
    setSearchQuery(data.q)
  }, 500)

  return (
    <Entry
      header={
        <div className="flex flex-row justify-between items-center pr-2">
          <MainTitle onClick={scrollToTop}>
            {t('common.head.title.discover')}
          </MainTitle>
        </div>
      }
      headTitle={t('common.head.title.discover')}
      isRefetchActive={false}
      subHeader={
        <div className="bg-dark">
          {!selectedCategory && (
            <ExplorerForm
              className="mx-auto max-w-[40rem]"
              searchQueryValue={searchQuery}
              onSubmit={onSubmit}
            />
          )}
          <SelectedCategoryComponent
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </div>
      }
      onActiveNavIconPress={scrollToTop}
      hasData={!!usersData?.length}
      isPaddingEnabled={false}
    >
      {results()}
    </Entry>
  )
}

import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/Button'
import { Socials } from '@/components/pages/Socials/enums'
import { useSocials } from '@/components/pages/Socials/hooks/useSocials'
import { LaunchButton } from '@/components/pages/TokenLaunch/components/LaunchButton'

import { useCreatorTokenConfig } from '@/lib/hooks/useLaunchToken'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

export const SocialsActionButton: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { data: tokenConfig } = useCreatorTokenConfig()
  const { data: authUser } = useUserInfo()
  const { socialsList } = useSocials({ userId: authUser?.id })

  const {
    twitter: twitterThresholds,
    instagram: instagramThresholds,
    tiktok: tiktokThresholds,
  } = tokenConfig?.socialsFollowersThresholds || {}

  const getFollowerCount = useCallback(
    (social) =>
      socialsList?.items?.find((item) => item.provider === social)
        ?.followerCount || 0,
    [socialsList?.items]
  )

  const isEligibleLaunch = useMemo(() => {
    const twitterFollowerCount = Number(getFollowerCount(Socials.Twitter))
    const instagramFollowerCount = Number(getFollowerCount(Socials.Instagram))
    const tiktokFollowerCount = Number(getFollowerCount(Socials.Instagram))
    const twitterThreshold = Number(twitterThresholds)
    const instagramThreshold = Number(instagramThresholds)
    const tiktokThreshold = Number(tiktokThresholds)
    return (
      twitterFollowerCount >= twitterThreshold ||
      instagramFollowerCount >= instagramThreshold ||
      tiktokFollowerCount >= tiktokThreshold
    )
  }, [
    getFollowerCount,
    twitterThresholds,
    instagramThresholds,
    tiktokThresholds,
  ])

  return (
    <div className="flex justify-center mt-8">
      {isEligibleLaunch ? (
        <LaunchButton />
      ) : (
        <Button
          isDisabled={socialsList?.items?.length === 0}
          className="min-w-[120px]"
          onClick={() => navigate(Routes.TOKEN_LAUNCH)}
          type="primary"
        >
          <span className="text-xxs xs:text-xs">
            {t('tokenSocialsConnect.nextStep')}
          </span>
        </Button>
      )}
    </div>
  )
}

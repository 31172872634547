import { useRef, useState } from 'react'

import { useFetchMentions } from './useFetchMentions'

export const useMentions = () => {
  const [mentionsCount, setMentionsCount] = useState<number>(0)
  const {
    fetchMentions,
    mentions,
    isLoading: isLoadingMentions,
    onBlur,
  } = useFetchMentions()
  const mentionsContainerRef = useRef<HTMLDivElement>()

  return {
    mentionsContainerRef,
    fetchMentions,
    mentions,
    isLoadingMentions,
    mentionsCount,
    setMentionsCount,
    onBlur,
  }
}

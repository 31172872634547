import { useQuery } from '@tanstack/react-query'

import { getSubscribersList } from '@/services/subscription'

import { QueryKeys } from '@/enums'

export const useSubscribersList = () => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: [QueryKeys.SubscribersList],
    queryFn: getSubscribersList,
    enabled: true,
  })

  return {
    subscribers: data?.subscribers ?? [],
    refetch,
    isLoading,
  }
}

import { useMemo, useRef } from 'react'
import debounce from 'lodash/debounce'

export const useDebouncedFunction = (callback, delay) => {
  const callbackRef = useRef<(...args) => void>()
  callbackRef.current = callback

  return useMemo(
    () => debounce((...args) => callbackRef.current(...args), delay),
    [delay]
  )
}

import { del, post } from '@/services/awsAmplify'
import { getMentionsFromMarkdown } from '@/utils/getMentionsFromMarkdown'
import { replaceMentionsWithUsernames } from '@/utils/replaceMentionsWithUsernames'

import { IMention } from '@/interfaces'

import { MediaType } from '@/plugins/ugc-composer'

type CreatePostResponseType = {
  id: UUID
  userId: UUID
  createdAt: string
  updatedAt: string
  media: { type: MediaType }[]
  mentions: IMention[]
  text: string
  isDiscoverable: boolean
  blockedByRecognitionService: boolean
}

export const createPost = async ({
  text,
  media,
  isSubscriptionContent,
}: {
  text: string
  media: { type: MediaType }[]
  isSubscriptionContent: boolean
}) => {
  const strippedCaption = replaceMentionsWithUsernames(text)
  const mentions = getMentionsFromMarkdown(text)

  return (await post({
    path: '/posts',
    params: {
      text: strippedCaption,
      mentions,
      media: media,
      isSubscriptionContent,
    },
  })) as unknown as CreatePostResponseType
}

export const deletePost = async (postId: UUID) => {
  return await del({ path: `/posts/${postId}` })
}

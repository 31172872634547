import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { IonIcon, IonPopover } from '@ionic/react'
import cx from 'classnames'
import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons'

interface PopoverProps {
  isOpen: boolean
  type?: 'danger' | 'success'
  children: ReactNode
  onDismiss?: (e) => void
  onConfirm?: () => void
  className?: string
  hasIcon?: boolean
  actionText?: string
  confirmText?: string
  headerMessage?: string | ReactNode
  dismissOnSelect?: boolean
  onConfirmClassName?: string
  onDismissClassName?: string
  headerClassName?: string
  arrow?: boolean
  popoverIcon?: string | JSX.Element
  disabled?: boolean
}

export const Popover: FC<PopoverProps> = ({
  isOpen,
  children,
  type = 'danger',
  onDismiss,
  onConfirm,
  className,
  hasIcon = true,
  popoverIcon,
  actionText,
  confirmText,
  headerMessage,
  headerClassName,
  dismissOnSelect,
  onConfirmClassName,
  onDismissClassName,
  arrow = false,
  disabled = false,
}) => {
  const { t } = useTranslation('common')

  return (
    <IonPopover
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      dismissOnSelect={dismissOnSelect}
      backdropDismiss={!disabled}
      className={cx('popover-light', {
        [className]: !!className,
      })}
      arrow={arrow}
    >
      <div
        className="flex flex-col items-center p-4 text-center"
        data-test="popover"
      >
        {popoverIcon && (
          <div className="notranslate mb-3 w-9 h-9">{popoverIcon}</div>
        )}
        {hasIcon && (
          <IonIcon
            icon={
              type === 'success' ? checkmarkCircleOutline : alertCircleOutline
            }
            color={type}
            className="text-5xl mb-3"
          />
        )}
        {headerMessage && (
          <h3
            className={cx('text-xl mb-5', {
              [headerClassName]: !!headerClassName,
            })}
          >
            {headerMessage}
          </h3>
        )}
        {children}
      </div>
      <div className="flex border-t" data-test="popover-reply">
        <button
          disabled={disabled}
          className={cx('w-full p-3 text-base', {
            [onDismissClassName]: !!onDismissClassName,
          })}
          onClick={onDismiss}
        >
          {actionText || t('okay')}
        </button>
        {onConfirm && (
          <button
            disabled={disabled}
            className={cx('w-full p-3 font-bold text-base	', {
              [onConfirmClassName]: !!onConfirmClassName,
            })}
            onClick={onConfirm}
          >
            {confirmText}
          </button>
        )}
      </div>
    </IonPopover>
  )
}

import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { appConfig } from '@/services/config'

import { useUserInfo } from '../useUserInfo'

export const useGetAvatar = () => {
  const queryClient = useQueryClient()

  const { data: authUser } = useUserInfo()
  const cachedAvatar = queryClient.getQueryData<string>(['cached_user_avatar'])

  const getAvatar = useCallback(
    (userId: UUID) => {
      const authUserId = authUser?.id
      const timestamp = +new Date(authUser?.updatedAt)

      if (!userId && !authUserId) return

      if (userId !== authUserId) {
        return `${appConfig.cdn}/users/${userId}/avatar/x1.jpeg`
      }

      if (cachedAvatar) {
        return cachedAvatar
      }

      return `${appConfig.cdn}/users/${authUserId}/avatar/x1.jpeg?${timestamp}`
    },
    [cachedAvatar, authUser?.id, authUser?.updatedAt]
  )

  return getAvatar
}

import { memo } from 'react'
import { IonIcon, isPlatform } from '@ionic/react'
import { ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons'

const EllipsisComponent = ({ className }: { className?: string }) => {
  return (
    <IonIcon
      className={`text-white ${className}`}
      slot="icon-only"
      icon={isPlatform('android') ? ellipsisVertical : ellipsisHorizontal}
    ></IonIcon>
  )
}

export const Ellipsis = memo(EllipsisComponent)

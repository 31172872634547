import { useCallback, useEffect, useState } from 'react'
import isNil from 'lodash/isNil'
import throttle from 'lodash/throttle'

import { ContestantJoinedFeedActivity } from '@/components/organisms/FeedActivity/ContestantJoinedFeedActivity'
import { ContestJoinedFeedActivity } from '@/components/organisms/FeedActivity/ContestJoinedFeedActivity'
import { ConversationActivity } from '@/components/organisms/FeedActivity/ConversationActivity'
import { PostFeedActivity } from '@/components/organisms/FeedActivity/PostFeedActivity'

import { IContestDiscoveryFeedActivityType } from '@/services/contestDiscoveryFeed'
import { neverReturn } from '@/utils/utils'

import { ActivityFeedVerb } from '@/enums'

export enum ScrollDirection {
  directionUp = 'up',
  directionDown = 'down',
}

export const useActivity = ({
  feed,
  isAtTop,
  isFeedFetching,
  isFeedEnabled = false,
}) => {
  const [isScrollingFast, setIsScrollingFast] = useState(false)
  const [activitiesInViewPort, setActivitiesInViewPort] = useState([])
  const [activeActivityId, setActiveActivityId] = useState(null)
  const [scrollDirection, setScrollDirection] = useState(
    ScrollDirection.directionDown
  )
  const [lastScrollTop, setLastScrollTop] = useState(0)

  const handleScroll = throttle((scrollTop) => {
    const top = scrollTop.target.scrollTop
    const direction =
      top > lastScrollTop
        ? ScrollDirection.directionDown
        : ScrollDirection.directionUp
    setScrollDirection(direction)
    setLastScrollTop(top)
  }, 1000)

  const onActiveActivityChanged = throttle((id) => {
    setActiveActivityId(id)
  }, 1000)

  useEffect(() => {
    // we ignore anything that happens within the first 100ms of mounting,
    // because any high-velocity scrolling is due to state restoration, NOT user action
    const timer = setTimeout(() => {
      setIsScrollingFast(false)
    }, 100)

    return () => clearTimeout(timer)
  }, [])

  const setIsScrollingTooFastEnter = useCallback((velocity) => {
    const isScrollingTooFast = Math.abs(velocity) > 1500
    setIsScrollingFast(isScrollingTooFast)

    return isScrollingTooFast
  }, [])

  const setIsScrollingTooFastExit = useCallback((velocity) => {
    return Math.abs(velocity) < 1500
  }, [])

  const scrollSeekConfigurationEnter = throttle(
    (velocity) => setIsScrollingTooFastEnter(velocity),
    100
  )

  const scrollSeekConfigurationExit = throttle(
    (velocity) => setIsScrollingTooFastExit(velocity),
    100
  )

  const updateActivitiesInViewPort = useCallback(
    (activityId: string, isAdding: boolean) => {
      setActivitiesInViewPort((prevState) => {
        if (prevState.includes(activityId) === isAdding) {
          return prevState
        }

        if (isAdding) {
          if (scrollDirection === ScrollDirection.directionDown) {
            if (isAtTop) {
              return [activityId, ...prevState]
            }
            return [activityId, ...prevState.slice(0, -1)]
          } else {
            return [...prevState.slice(0, -1), activityId]
          }
        } else {
          return prevState.filter((id) => id !== activityId)
        }
      })
    },
    [scrollDirection, isAtTop]
  )

  const inViewport = useCallback(
    (activityId) => {
      updateActivitiesInViewPort(activityId, true)
    },
    [updateActivitiesInViewPort]
  )

  const outViewport = useCallback(
    (activityId) => {
      updateActivitiesInViewPort(activityId, false)
    },
    [updateActivitiesInViewPort]
  )

  const renderActivity = useCallback(
    (activity, activeActivityId: string, index: number) => {
      switch (activity?.verb) {
        case ActivityFeedVerb.ContestCreate:
        case ActivityFeedVerb.ContestSubmission:
        case ActivityFeedVerb.ContestVoting:
          return (
            <ContestJoinedFeedActivity
              {...activity}
              contestId={String(activity.contestId)}
              videoThumbnail={String(
                activity?.object?.data?.creatorVideoThumbnail
              )}
              video={activity?.video}
              videoPreview={activity?.videoPreview}
              inViewport={inViewport}
              outViewport={outViewport}
              isActiveActivity={
                activeActivityId === activity?.id && isFeedEnabled
              }
              index={index}
              isScrollingFast={isScrollingFast}
            />
          )
        case ActivityFeedVerb.ContestParticipate:
          return (
            <ContestantJoinedFeedActivity
              {...(activity as IContestDiscoveryFeedActivityType)}
              contestId={String(activity.contestId)}
              videoThumbnail={String(activity.videoThumbnail)}
              video={activity?.video}
              videoPreview={activity?.videoPreview}
              inViewport={inViewport}
              outViewport={outViewport}
              isActiveActivity={
                activeActivityId === activity?.id && isFeedEnabled
              }
              index={index}
              isScrollingFast={isScrollingFast}
            />
          )
        case ActivityFeedVerb.Post:
          return (
            <PostFeedActivity
              {...activity}
              inViewport={inViewport}
              outViewport={outViewport}
              isActiveActivity={
                activeActivityId === activity?.id && isFeedEnabled
              }
              index={index}
              isScrollingFast={isScrollingFast}
            />
          )
        case ActivityFeedVerb.RepliedToConversation:
        case ActivityFeedVerb.StartedConversation:
          return (
            activity.object.data && (
              <ConversationActivity
                {...activity}
                inViewport={inViewport}
                outViewport={outViewport}
                isActiveActivity={
                  activeActivityId === activity?.id && isFeedEnabled
                }
                index={index}
                isScrollingFast={isScrollingFast}
              />
            )
          )
        default:
          neverReturn()
      }
    },
    [inViewport, outViewport, isFeedEnabled, isScrollingFast]
  )

  useEffect(() => {
    if (activitiesInViewPort.length) {
      onActiveActivityChanged(activitiesInViewPort?.[0])
    } else if (isAtTop) {
      setActiveActivityId(feed?.[0]?.id)
    }

    return () => {
      setActiveActivityId(null)
    }
  }, [isAtTop, activitiesInViewPort, feed, onActiveActivityChanged])

  const resetActiveActivity = useCallback(() => {
    setTimeout(() => {
      setActiveActivityId(null)
      setActivitiesInViewPort(
        [activitiesInViewPort?.[0]?.activityId].filter((id) => !isNil(id))
      )
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isFeedFetching) resetActiveActivity()
  }, [isFeedFetching, resetActiveActivity])

  return {
    activitiesInViewPort,
    inViewport,
    outViewport,
    activeActivityId,
    handleScroll,
    renderActivity,
    scrollSeekConfigurationEnter,
    scrollSeekConfigurationExit,
  }
}

import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Arbitrum } from '@/components/atoms/assets/Arbitrum'
import { Ethereum } from '@/components/atoms/assets/Ethereum'
import { Dropdown } from '@/components/atoms/Dropdown'

import { NetworkStandard } from '@/enums'
import { Network } from './enums'

import { Label } from './Label'

export const networkOptionsData = [
  {
    value: Network.Ethereum,
    suffix: NetworkStandard.ERC20,
    icon: <Ethereum />,
  },
  {
    value: Network.Arbitrum,
    icon: <Arbitrum />,
  },
]

interface NetworkDropdownProps {
  onSelect: (value: string) => void
  value?: string
}

export const NetworkDropdown: FC<NetworkDropdownProps> = ({
  onSelect,
  value,
}) => {
  const { t } = useTranslation('common')

  const networkOptions = useMemo(() => {
    return networkOptionsData.map((item) => ({
      label: <Label label={item.value} suffix={item.suffix} icon={item.icon} />,
      value: item.value,
    }))
  }, [])

  return (
    <div className="flex flex-row justify-between items-center mb-4">
      <span className="text-xs text-cool-gray-300 font-medium uppercase">
        {t('wallet.network')}
      </span>
      <Dropdown
        options={networkOptions}
        onSelect={(e) => onSelect(e.value)}
        value={value}
        defaultLabel={t('wallet.selectNetwork')}
      />
    </div>
  )
}

import { FC, memo } from 'react'

type ChatProps = {
  isActive?: boolean
}

const ChatComponent: FC<ChatProps> = ({ isActive = false }) => {
  const fill = isActive ? '#fff' : '#4A5E80'

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.2551 23.1468H9.0051V22.3968H8.2551V23.1468ZM8.2551 28H7.5051V29.2509L8.60846 28.6615L8.2551 28ZM17.341 23.1468V22.3968H17.1533L16.9877 22.4853L17.341 23.1468ZM3.75 9C3.75 6.65279 5.65279 4.75 8 4.75V3.25C4.82436 3.25 2.25 5.82437 2.25 9H3.75ZM3.75 18.1468V9H2.25V18.1468H3.75ZM8 22.3968C5.65279 22.3968 3.75 20.494 3.75 18.1468H2.25C2.25 21.3225 4.82436 23.8968 8 23.8968V22.3968ZM8.2551 22.3968H8V23.8968H8.2551V22.3968ZM9.0051 28V23.1468H7.5051V28H9.0051ZM16.9877 22.4853L7.90175 27.3385L8.60846 28.6615L17.6944 23.8084L16.9877 22.4853ZM24.1376 22.3968H17.341V23.8968H24.1376V22.3968ZM28.3876 18.1468C28.3876 20.494 26.4848 22.3968 24.1376 22.3968V23.8968C27.3132 23.8968 29.8876 21.3224 29.8876 18.1468H28.3876ZM28.3876 9V18.1468H29.8876V9H28.3876ZM24.1376 4.75C26.4848 4.75 28.3876 6.65279 28.3876 9H29.8876C29.8876 5.82436 27.3132 3.25 24.1376 3.25V4.75ZM8 4.75H24.1376V3.25H8V4.75Z"
        fill={fill}
      />
      <g clipPath="url(#clip0_1460_14376)">
        <path
          d="M18.0061 17.5997C18.6786 17.5766 19.2005 18.0589 19.607 18.6107C19.6221 18.6293 19.6422 18.6478 19.6572 18.6664C20.1792 19.1487 20.5305 19.1626 22.3422 19.1626C22.533 19.1626 22.6032 19.0745 22.5982 18.8844C22.5932 18.467 22.5882 18.198 22.5982 17.7806C22.6032 17.5395 22.5229 17.4467 22.287 17.4513C21.6647 17.4653 19.4213 17.4653 19.4213 17.4653C18.7689 17.4838 18.5431 16.983 18.3423 16.7928L18.1315 16.5795C17.6297 16.0462 17.0174 15.972 16.3047 15.972C15.5469 15.972 15.2859 15.972 14.5281 15.972C13.3788 15.972 12.084 15.9673 10.9347 15.972V14.1077C12.6009 14.1123 13.8756 14.1077 15.5419 14.1077C15.8179 14.1077 16.0488 14.1865 16.2445 14.372C16.4252 14.5436 16.6159 14.9656 16.7965 15.1372C17.2683 15.5778 17.8455 15.8282 18.518 15.8328C19.6221 15.8467 21.2231 15.8328 22.3272 15.8421C22.533 15.8421 22.6032 15.7633 22.5982 15.5824C22.5932 15.23 22.5982 14.6224 22.5982 14.27C22.5982 13.9593 22.5932 13.9546 22.2569 13.9546C20.6961 13.9546 19.1403 13.9546 17.5795 13.9546C16.0186 13.9546 16.0337 13.6625 15.7125 13.3054C15.1655 12.6932 15.1002 12.4706 14.4829 12.4706C13.6197 12.4706 11.8029 12.4706 10.9397 12.4799V10.5831C11.6674 10.5924 12.1442 10.6156 12.8719 10.6156C13.4039 10.6156 13.7301 11.237 13.9208 11.4271C14.1416 11.6544 14.3474 11.8955 14.5833 12.1089C14.8694 12.3732 15.5067 12.3268 16.0989 12.3268C18.1817 12.3361 20.2645 12.3268 22.3473 12.3361C22.538 12.3361 22.6133 12.2619 22.6082 12.0671C22.6032 11.6683 22.6082 11.2695 22.6082 10.866C22.6082 10.4857 22.5982 10.4764 22.2419 10.4764C19.7827 10.4764 17.3285 10.4811 14.8694 10.4764C13.5193 10.4764 13.9007 10.3466 12.8769 9.40053C12.4553 9.02488 11.6774 9.05271 10.9347 9.05271C10.759 9.05271 10.5984 9.05271 10.4328 9.05271H9.52942V17.6229"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1460_14376">
          <rect
            width="13.0688"
            height="10.1053"
            fill={fill}
            transform="translate(9.53442 9.05273)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Chat = memo(ChatComponent)

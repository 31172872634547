import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonTitle } from '@ionic/react'

import { BackButton } from '@components/atoms/BackButton'
import { Title } from '@components/atoms/Title'
import { Loading } from '@/components/atoms/Loading'
import { Main } from '@/components/templates/main'

import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useLocation, useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

export const SecuritySettings: FC = () => {
  const { t } = useTranslation('common')
  const { data: userInfo, isLoading, refetch } = useUserInfo()
  const location = useLocation()
  const navigate = useNavigate()
  const redirectBackTo = location.state?.redirectBackTo

  const header = useMemo(
    () => (
      <>
        <IonButtons slot="start">
          <BackButton defaultHref={Routes.PROFILE} />
        </IonButtons>
        <IonTitle>
          <Title className="text-center my-0" level={3}>
            {t('securitySettings.header')}
          </Title>
        </IonTitle>
      </>
    ),
    [t]
  )

  return (
    <Main
      header={header}
      contentClassName="px-8"
      refetch={refetch}
      isPaddingEnabled={false}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <picture>
            <source srcSet="2fa/security-settings.png 261w, 2fa/security-settings@2x.png 522w, 2fa/security-settings@3x.png 783w" />
            <img
              src="2fa/security-settings.png"
              alt="security-settings"
              className="w-[80%] md:w-[60%] mx-auto mt-7 mb-11"
            />
          </picture>
          <span className="mb-10">{t('securitySettings.description')}</span>
          <div className="flex justify-between">
            <span className="font-bold">
              {t('securitySettings.authenticatorApp')}
            </span>
            {userInfo.setup2FA ? (
              <span
                className="text-sm text-danger font-bold cursor-pointer"
                onClick={() => navigate(Routes.REMOVE_2FA)}
              >
                {t('securitySettings.remove')}
              </span>
            ) : (
              <span
                className="text-sm text-primary font-bold cursor-pointer"
                onClick={() =>
                  navigate(Routes.SETUP_2FA, { state: { redirectBackTo } })
                }
              >
                {t('securitySettings.configure')}
              </span>
            )}
          </div>
        </>
      )}
    </Main>
  )
}

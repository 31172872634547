import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { isAddress } from 'web3-validator'

import {
  estimateWithdraw,
  executeWithdraw,
  IWithdrawEstimation,
} from '@/services/wallet/withdraw'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'
import { WALLET } from '@/utils/route'
import { toTitleCase } from '@/utils/toTitleCase'

import { QueryKeys, TransactionsType } from '@/enums'

const REFETCH_INTERVAL = 1000 * 60

export const useWithdraw = ({
  amount,
  externalWalletId,
  selectedNetwork,
}: {
  amount: number
  externalWalletId: string
  selectedNetwork: string
}) => {
  const navigate = useNavigate()
  const { data: userInfo } = useUserInfo()
  const { t } = useTranslation('common')
  const [presentToast] = useIonToast()
  const [countdown, setCountdown] = useState<number>(null)
  const intervalRef = useRef<NodeJS.Timeout>(null)

  const {
    data: estimation,
    error: estimationError,
    isFetching,
    refetch,
  } = useQuery<IWithdrawEstimation, AxiosError<string>>({
    queryKey: [QueryKeys.EstimateWithdraw, amount, selectedNetwork],
    queryFn: () =>
      estimateWithdraw({ amount, externalWalletId, selectedNetwork }),
    enabled: !!amount && isAddress(externalWalletId) && !!selectedNetwork,
    staleTime: 0,
    gcTime: 0,
  })

  useEffect(() => {
    if ((!amount || !isAddress(externalWalletId)) && !isFetching) return
    clearInterval(intervalRef.current)

    intervalRef.current = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 0))
    }, 1000)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [refetch, amount, externalWalletId, countdown, isFetching])

  useEffect(() => {
    if (!amount || !isAddress(externalWalletId)) {
      queryClient.removeQueries({ queryKey: [QueryKeys.EstimateWithdraw] })
      return
    }

    if (!isFetching) {
      setCountdown(
        () => estimation?.expiresInSeconds || REFETCH_INTERVAL / 1000
      )
    }
  }, [
    refetch,
    isFetching,
    amount,
    externalWalletId,
    estimation?.expiresInSeconds,
  ])

  useEffect(() => {
    if (countdown === 0) {
      refetch()
    }
  }, [countdown, refetch])

  const {
    mutate: mutateExecuteWithdraw,
    isPending,
    error: executeWithdrawError,
  } = useMutation<void, AxiosError<string>, { authenticatorCode: string }>({
    mutationFn: ({ authenticatorCode }: { authenticatorCode: string }) =>
      executeWithdraw({
        estimationId: estimation?.estimationId,
        authenticatorCode,
      }),
    mutationKey: [QueryKeys.ExecuteWithdraw],
    onSuccess: () => {
      presentToast({
        message: t('withdraw.tokensSuccessfullySent', {
          network: selectedNetwork ? toTitleCase(selectedNetwork) : '',
        }),
        position: 'top',
        duration: 5000,
      })
      queryClient.removeQueries({ queryKey: [QueryKeys.EstimateWithdraw] })
      queryClient.refetchQueries({
        queryKey: [QueryKeys.Wallet, userInfo?.id],
        exact: true,
      })
      queryClient.refetchQueries({
        queryKey: [QueryKeys.Transactions, TransactionsType.All, userInfo?.id],
        exact: true,
      })
      navigate(WALLET, {
        replace: true,
      })
    },
  })

  return {
    countdown,
    estimation,
    estimationError,
    isFetching,
    mutateExecuteWithdraw,
    executeWithdrawError,
    isPending,
  }
}

import { FC, memo } from 'react'
import { IonSpinner } from '@ionic/react'

interface IInfiniteScrollLoaderProps {
  hasNext: boolean
}

const InfiniteScrollLoaderComponent: FC<IInfiniteScrollLoaderProps> = ({
  hasNext,
}) =>
  hasNext ? (
    <div className="flex justify-center p-2">
      <IonSpinner color="main" />
    </div>
  ) : null

export const InfiniteScrollLoader = memo(InfiniteScrollLoaderComponent)

import { useCallback, useEffect, useState } from 'react'
import formatISO from 'date-fns/formatISO'
import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'

import { Tracking, TrackingEvent } from '@/lib/tracking'
import { EventPropertyMap } from '@/lib/tracking/interface'

import { OwnReaction } from '@/enums'

import { useReaction } from '../useReaction'
import { useUserInfo } from '../useUserInfo'

export enum MediaType {
  Video = 'video',
  Image = 'image',
}

export type EventName = TrackingEvent.FeedItemViewed

type UseTrackViewedActivity<Event extends EventName> = {
  inViewport: boolean
  activityId: UUID
  eventName: Event
  eventProperties: EventPropertyMap[Event]
  contentType:
    | 'conversation'
    | 'reply'
    | 'post'
    | 'challenge'
    | 'challengeParticipant'
  itemId: string
  currentIndex?: number
  foreignId?: string
  recommendationId?: string
  isSubscriptionContent?: boolean
  own_reactions: OwnReaction[]
  reaction_counts: Record<string, number>
}

export const useTrackViewedActivity = <Event extends EventName>({
  inViewport,
  activityId,
  itemId,
  eventName,
  eventProperties,
  contentType,
  currentIndex = 0,
  foreignId,
  recommendationId,
  isSubscriptionContent,
  own_reactions,
  reaction_counts,
}: UseTrackViewedActivity<Event>) => {
  const [startTime, setStartTime] = useState<number>(null)
  const { data: authUser } = useUserInfo()
  const [sliderIndex, setSliderIndex] = useState<number>(0)
  const { reaction } = useReaction(activityId, {
    own_reactions,
    reaction_counts,
  })
  const hasOwnLike = reaction?.ownReactions?.some(
    (reaction) => reaction?.kind === 'like'
  )

  const isWatchTimeValid = useCallback(
    (watchTime: number, mediaType: MediaType) => {
      if (!watchTime) return false
      if (mediaType === MediaType.Video && watchTime < 3000) return false
      if (mediaType === MediaType.Image && watchTime < 1500) return false
      return true
    },
    []
  )

  const triggerTrackingEventHandler = useCallback(
    (properties, watchTime) => {
      if (!isWatchTimeValid(watchTime, eventProperties.mediaType)) return

      Tracking.triggerEvent(
        eventName ? eventName : TrackingEvent.FeedItemViewed,
        omitBy(properties, isUndefined)
      )
      if (!isSubscriptionContent) {
        Tracking.triggerEvent(TrackingEvent.PersonalizeView, {
          ...(watchTime ? { eventValue: watchTime } : {}),
          itemId: properties.activityId,
        })

        if (hasOwnLike) {
          Tracking.triggerEvent(TrackingEvent.PersonalizeLikedView, {
            ...(watchTime ? { eventValue: watchTime } : {}),
            itemId: properties.activityId,
          })
        }
      }
    },
    [
      hasOwnLike,
      eventName,
      eventProperties.mediaType,
      isWatchTimeValid,
      isSubscriptionContent,
    ]
  )

  useEffect(() => {
    if (inViewport) {
      setStartTime(new Date().getTime())
    } else {
      if (startTime) {
        const endTime = new Date().getTime()
        const watchTime = endTime - startTime
        const properties: EventPropertyMap[Event] = {
          ...eventProperties,
          index: currentIndex || sliderIndex,
          userId: authUser.id,
          activityId,
          itemId,
          contentType,
          timestamp: formatISO(new Date()),
          watchTime,
          foreignId,
        }

        triggerTrackingEventHandler(properties, watchTime)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewport, recommendationId])

  const onSlideChange = ({
    currentIndex,
    eventProperties,
    prevIndex,
    contentType,
  }) => {
    setSliderIndex(currentIndex)
    if (startTime) {
      const endTime = new Date().getTime()
      const watchTime = endTime - startTime
      const properties: EventPropertyMap[Event] = {
        ...eventProperties,
        index: prevIndex,
        userId: authUser.id,
        activityId,
        itemId,
        contentType,
        timestamp: formatISO(new Date()),
        watchTime,
        foreignId,
      }

      triggerTrackingEventHandler(properties, watchTime)
    }

    setStartTime(new Date().getTime())
  }

  return {
    onSlideChange,
  }
}

import amplitude from 'amplitude-js'

import { appConfig } from '@/services/config'

import { Platform } from '@/enums'

import { ITrackingDriver } from '../driver'
import { TrackingEvent } from '../enums'
import { EventName, EventPropertyMap } from '../interface'

const instance = amplitude.getInstance()
if (appConfig.amplitudeKey) {
  instance.init(appConfig.amplitudeKey)
}

export const amplitudeSetUserId = (userId: string | null) => {
  if (userId) {
    instance.setUserId(userId)
  }
}

export const amplitudeTriggerEvent = <Event extends EventName>(
  eventName: Event,
  eventProperties?: EventPropertyMap[Event]
) => {
  if (!appConfig.amplitudeKey) return
  instance.logEvent(eventName, {
    ...eventProperties,
    appPlatform: Platform.Web,
  })
}

export const amplitudeIdentifyEvent = <T>(obj: T) => {
  const identifyEvent = new amplitude.Identify()
  Object.keys(obj).forEach((key) => {
    identifyEvent.set(key, obj[key])
  })
  amplitude.identify(identifyEvent)
}

export class AmplitudeDriver implements ITrackingDriver {
  instance = amplitude.getInstance()
  events: TrackingEvent[]

  constructor(amplitudeKey: string, events: TrackingEvent[]) {
    this.instance = amplitude.getInstance()
    this.instance.init(amplitudeKey)
    this.events = events
  }

  public triggerEvent<Event extends EventName>(
    eventName: Event,
    data?: EventPropertyMap[Event]
  ) {
    this.instance.logEvent(eventName, {
      ...data,
      appPlatform: Platform.Web,
    })
  }

  public isTrackable<Event extends EventName>(eventName: Event): boolean {
    return this.events.includes(eventName as TrackingEvent)
  }
}

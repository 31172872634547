import {
  CSSProperties,
  memo,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { FixedSizeList as List } from 'react-window'
import {
  IonHeader,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react'

import { mappedCountryTelData } from '@services/phoneCodeService'

import {
  IonSearchbarCustomEvent,
  SearchbarInputEventDetail,
} from '@/interfaces'

export type ListEntryProps = {
  name: string
  iso2: string
  dialCode: string
  onClick: (dialCode: string) => void
}

interface IAllListsProps {
  width: number
  height: number
  onSelect: (dialCode: string) => void
  searchRef: MutableRefObject<
    (evt: IonSearchbarCustomEvent<SearchbarInputEventDetail>) => void
  >
}

interface IListEntryProps {
  index: number
  style: CSSProperties
}

interface IPhoneNumberPrefixProps {
  onSelect: (dialCode: string) => void
}

const AllLists = ({ width, height, onSelect, searchRef }: IAllListsProps) => {
  const [phoneCodeData, setPhoneCodeData] = useState(mappedCountryTelData)

  useEffect(() => {
    const test = (value: string, query: string) =>
      value.toLowerCase().indexOf(query) > -1

    searchRef.current = function handleInput(event) {
      const query = event.target.value.toLowerCase()
      setPhoneCodeData(
        mappedCountryTelData.filter(([name, iso2, dialCode]) =>
          query
            .split(' ')
            .every((q) => test(name, q) || test(iso2, q) || test(dialCode, q))
        )
      )
    }
  }, [searchRef])

  const ListEntry = ({ index, style }: IListEntryProps) => {
    const [name, , dialCode] = phoneCodeData[index]
    return (
      <IonItem
        button
        onClick={() => onSelect(`+${dialCode}`)}
        className="list-entry"
        key={name + dialCode}
        style={style}
      >
        <IonLabel>
          {name}: +{dialCode}
        </IonLabel>
      </IonItem>
    )
  }

  return (
    <List
      width={width}
      height={height}
      itemSize={46}
      itemCount={phoneCodeData.length}
    >
      {ListEntry}
    </List>
  )
}

const PhoneNumberPrefixComponent = ({ onSelect }: IPhoneNumberPrefixProps) => {
  const { t } = useTranslation('common')
  const searchHandlerRef = useRef(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    (event: IonSearchbarCustomEvent<SearchbarInputEventDetail>) => {}
  )
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const wrapper = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let attempts = 0
    const interval = setInterval(() => {
      attempts += 1

      if (attempts > 10) {
        clearInterval(interval)
      }

      const { offsetWidth = 0, offsetHeight = 0 } = wrapper.current ?? {}
      setWidth(offsetWidth)
      setHeight(offsetHeight)

      if (offsetWidth && offsetHeight) {
        clearInterval(interval)
      }
    }, 100)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const handler = () => {
      setWidth(0)
      setHeight(0)

      setTimeout(() => {
        const { offsetWidth = 0, offsetHeight = 0 } = wrapper.current ?? {}
        setWidth(offsetWidth)
        setHeight(offsetHeight)
      }, 100)
    }

    window.addEventListener('resize', handler)

    return () => window.removeEventListener('resize', handler)
  }, [])

  return (
    <div className={'w-full h-full flex flex-col'}>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{t('phoneNumberCountryPrefix')}</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            onIonInput={(event) => searchHandlerRef.current(event)}
          />
        </IonToolbar>
      </IonHeader>
      <div className={'flex-grow'} ref={wrapper}>
        <AllLists
          width={width}
          height={height}
          onSelect={onSelect}
          searchRef={searchHandlerRef}
        />
      </div>
    </div>
  )
}

export const PhoneNumberPrefix = memo(PhoneNumberPrefixComponent)

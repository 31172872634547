import { useTranslation } from 'react-i18next'
import { IonIcon } from '@ionic/react'
import { alertCircle } from 'ionicons/icons'

import { toTitleCase } from '@/utils/toTitleCase'

export const ImportantWarning = ({
  selectedNetwork,
}: {
  selectedNetwork: string
}) => {
  const { t } = useTranslation('common')

  return (
    <div className="mb-8">
      <div className="flex flex-row mb-3">
        <IonIcon
          icon={alertCircle}
          color="danger"
          style={{ fontSize: '22px' }}
        />
        <span className="ml-2 font-bold">{t('withdraw.warningTitle')}</span>
      </div>
      <span className="text-sm">
        {t('withdraw.warningDescription', {
          network: selectedNetwork ? `${toTitleCase(selectedNetwork)} ` : '',
        })}
      </span>
    </div>
  )
}

import { memo } from 'react'
import { IonIcon } from '@ionic/react'
import { closeOutline, searchOutline } from 'ionicons/icons'

const SearchIconComponent = ({ value }: { value?: boolean }) => {
  return (
    <IonIcon
      icon={value ? closeOutline : searchOutline}
      slot="start"
      className="w-5 h-5"
    />
  )
}

export const SearchIcon = memo(
  SearchIconComponent,
  (prev, next) => !(prev.value !== next.value)
)

import { FC } from 'react'
import { IonButton } from '@ionic/react'

import { UGC } from '@/components/atoms/assets/UGC'

import { Tracking, TrackingEvent } from '@/lib/tracking'

export const UGCButton: FC = () => {
  return (
    <IonButton
      className="large"
      color="plain"
      onClick={() => {
        Tracking.triggerEvent(TrackingEvent.AddContentStarted)
      }}
    >
      <UGC className="min-w-[3.5rem]" />
    </IonButton>
  )
}

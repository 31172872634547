import { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { UserName } from '@/components/atoms/UserName'
import { IEnrichedUser } from '@/components/pages/Activity/interfaces'

import { useUserInfo } from '@/lib/hooks/useUserInfo'

import { SubscriptionButtonWithTip } from '../../SubscriptionButtonWithTip'

export type IPostFeedSubscriptionContentProps = {
  amount?: number
  actor: IEnrichedUser
  activityId?: string
}

export const PostFeedSubscriptionContent: FC<
  IPostFeedSubscriptionContentProps
> = ({ actor, amount, activityId }) => {
  const { t } = useTranslation('common')
  const { data: authUser } = useUserInfo()

  const computedUserId = useMemo(
    () => actor?.id || authUser?.id,
    [actor?.id, authUser?.id]
  )

  const { data: userInfo } = useUserInfo({ userId: computedUserId })

  return (
    <div className="post-feed-payment-bg flex flex-col justify-center items-center text-center">
      <img className="mb-8 mt-11" src="/lock.png" />
      <span className="font-bold text-lg mb-7">
        <Trans
          i18nKey="profile.subscription.subscribePostTitle"
          components={{
            userLink: (
              <UserName
                className="inline font-bold"
                userId={actor?.id}
                name={actor?.data?.name as string}
              />
            ),
          }}
        >
          {t('profile.subscription.subscribePostTitle')}
        </Trans>
      </span>
      <div className="mb-8">
        <SubscriptionButtonWithTip
          activityId={activityId}
          amount={amount}
          userInfo={userInfo}
        />
      </div>
    </div>
  )
}

import React from 'react'

import { getThumbnailImage } from '@/utils/url'

import { IReply } from '@/interfaces'

interface IGridProps {
  containerHeight?: number
  object: {
    data?: {
      imageUrl: string
      replies?: IReply[]
    }
  }
  slideSwitch: (index: number) => void
}

export const Grid: React.FC<IGridProps> = ({
  containerHeight,
  object,
  slideSwitch,
}) => {
  return (
    <div
      className="overflow-auto h-[inherit]"
      style={{
        height: containerHeight || 'auto',
      }}
    >
      <div className="grid gap-1 grid-cols-3">
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation()
            slideSwitch(0)
          }}
        >
          <img
            src={getThumbnailImage(object.data?.imageUrl)}
            loading="lazy"
            className="object-cover w-full h-full"
            alt="Thumbnail"
          />
        </div>
        {object.data?.replies?.map((item: IReply, index: number) => (
          <div
            className="cursor-pointer"
            key={'grid' + item.id}
            onClick={(e) => {
              e.stopPropagation()
              slideSwitch(index + 1)
            }}
          >
            <img
              src={getThumbnailImage(item.imageUrl)}
              loading="lazy"
              className="object-cover w-full h-full"
              alt={`Reply ${index + 1}`}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

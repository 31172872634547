import { FC, memo } from 'react'
import { Components, GroupedVirtuoso } from 'react-virtuoso'
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query'

interface IGroupedVirtualListProps {
  className: string
  endReached: (
    options?: FetchNextPageOptions | number
  ) =>
    | Promise<InfiniteQueryObserverResult<unknown, unknown>>
    | ReturnType<typeof setInterval>
    | void
  components: Components
  itemContent: (index: number) => JSX.Element
  virtualKey?: string
  overscan?: number | { main: number; reverse: number }
  increaseViewportBy?: number | { top: number; bottom: number }
  atTopStateChange?: (atTop: boolean) => void
  groupCounts: number[]
  groupContent: (index: number) => JSX.Element
}

const GroupedVirtualListComponent: FC<IGroupedVirtualListProps> = ({
  className,
  endReached,
  components,
  itemContent,
  virtualKey,
  overscan = { main: 1, reverse: 1 },
  increaseViewportBy = 0,
  atTopStateChange,
  groupCounts,
  groupContent,
}) => {
  return (
    <GroupedVirtuoso
      key={virtualKey}
      className={className}
      endReached={endReached}
      overscan={overscan}
      increaseViewportBy={increaseViewportBy}
      components={components}
      itemContent={itemContent}
      atTopStateChange={atTopStateChange}
      groupCounts={groupCounts}
      groupContent={groupContent}
    />
  )
}

export const GroupedVirtualList = memo(GroupedVirtualListComponent)

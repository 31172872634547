import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query'

import { getLikes } from '@/services/likes'
import { queryClient } from '@/lib/queryClient'

type User = Awaited<ReturnType<typeof getLikes>>['users'][number]

export const useGetLikes = (activityId: string) => {
  const { data, isLoading, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ['getLikes', activityId],
      queryFn: ({ pageParam: startAt }) =>
        getLikes({ activity: activityId, startAt }),
      getNextPageParam: (lastPage) => lastPage?.lastKey ?? undefined,
      initialPageParam: '',
    })

  const clearData = () => {
    if (data?.pages?.length) {
      queryClient.setQueryData<InfiniteData<unknown>>(
        ['getLikes', activityId],
        (data) => ({
          pages: [data?.pages?.[0]],
          pageParams: [data?.pageParams?.[0]],
        })
      )
    }
  }

  const refetchData = async () => {
    clearData()
    await refetch()
  }

  return {
    refetch,
    isLoading,
    refetchData,
    data:
      data?.pages.reduce((acc, page) => {
        return [...acc, ...page.users]
      }, <readonly User[]>[]) ?? <readonly User[]>[],
    fetchNextPage,
    hasNextPage,
  }
}

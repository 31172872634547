import { FC, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonTitle, useIonToast } from '@ionic/react'

import { BackButton } from '@components/atoms/BackButton'
import { Main } from '@components/templates/main'
import { Loading } from '@/components/atoms/Loading'
import { CommentsListItem } from '@/components/organisms/CommentsListItem'
import { CommentsListParticipantItem } from '@/components/organisms/CommentsListParticipantItem'
import { ConversationActivity } from '@/components/organisms/FeedActivity/ConversationActivity'
import {
  PostFeedActivity,
  PostFeedActivityWithSubscription,
} from '@/components/organisms/FeedActivity/PostFeedActivity'
import { TimelineError } from '@/components/organisms/TimelineError'
import { NotFound } from '@/components/pages/NotFound'

import { useActivityDetails } from '@/lib/hooks/useActivityDetails'
import { useCommentsSection } from '@/lib/hooks/useCommentsSection'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useLocation, useParams } from '@/lib/routing'

import { Verb } from '@/enums'

import { CommentsInputFooter } from './CommentsInputFooter'
import CommentsSection from './CommentsSection'

export const CommentsPage: FC = () => {
  const commentsRef = useRef<HTMLSpanElement>()

  const { search } = useLocation()

  const [presentToast] = useIonToast()

  const { t } = useTranslation('common')
  const searchParams = new URLSearchParams(search)
  const replyObjectId = searchParams.get('replyObjectId')
  const createdAt = searchParams.get('createdAt')

  const { activityId } = useParams<{ activityId: string }>()

  const { data: authUser } = useUserInfo()

  const {
    isLoading,
    activity,
    hasError,
    refetch: refetchActivity,
    postFeedActivityErrorData: { status, postFeedActivity, price },
    isFetching,
  } = useActivityDetails({
    activityId,
    replyObjectId,
    createdAt,
  })

  const { refetchFirstPage: refetchComments } = useCommentsSection({
    activityId,
    replyObjectId,
    createdAt,
  })

  const handleRefetch = () =>
    Promise.all([refetchActivity(), refetchComments()])

  useEffect(() => {
    if (activity && hasError) {
      presentToast({
        message: t('timeline.errors.comment'),
        position: 'top',
        duration: 3000,
      })
    }
  }, [presentToast, t, hasError, activity])

  const isContestCreatorActivity =
    activity?.actor?.id === activity?.object?.data?.userId

  const Header = useMemo(
    () => (
      <>
        <IonButtons slot="secondary">
          <BackButton />
        </IonButtons>
        <IonTitle class="text-center">
          {!isLoading && (
            <>
              {!activity?.object?.error && status !== 402 && (
                <span className="block text-sm">
                  {t('comments.title', {
                    count: activity?.reaction_counts?.['COMMENT'] || 0,
                  })}
                </span>
              )}
            </>
          )}
        </IonTitle>
      </>
    ),
    [activity?.object?.error, activity?.reaction_counts, isLoading, status, t]
  )

  const isReferenceExisting = (activity) => !activity?.object?.error

  const CommentsContent = useMemo(() => {
    if (isFetching && !activity) {
      return <Loading />
    }

    if (hasError && status === 402) {
      return (
        <PostFeedActivityWithSubscription
          activityId={activityId}
          amount={price}
          {...postFeedActivity}
        />
      )
    }

    if (hasError && !activity) {
      return <TimelineError translation={'timeline.errors.comment'} />
    }

    if (!isReferenceExisting(activity)) {
      return <NotFound />
    }

    return (
      <div>
        {isContestCreatorActivity && (
          <CommentsListItem
            className="border-zinc-800 border-b-[0.0625rem] pb-2"
            activity={activity}
            hideActions={true}
          />
        )}
        {activity?.verb === Verb.ContestParticipate && (
          <CommentsListParticipantItem
            className="border-zinc-800 border-b-[0.0625rem] pb-2"
            activity={activity}
          />
        )}
        {activity?.verb === Verb.Post && (
          <PostFeedActivity commentView {...activity} />
        )}
        {[Verb.StartedConversation, Verb.RepliedToConversation].includes(
          activity?.verb
        ) && <ConversationActivity commentView {...activity} />}
        <CommentsSection
          userInfo={authUser}
          activityId={activityId}
          commentsRef={commentsRef}
        />
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetching,
    activity,
    hasError,
    status,
    isContestCreatorActivity,
    history,
    authUser,
    activityId,
    price,
  ])

  return (
    <Main
      headTitle={t('common.head.title.comments')}
      refetch={handleRefetch}
      header={Header}
      footer={
        <CommentsInputFooter
          activityId={activityId}
          activity={activity}
          search={search}
          commentsRef={commentsRef}
        />
      }
      headerClassName="touch-none"
      isPaddingEnabled={false}
    >
      {CommentsContent}
    </Main>
  )
}

import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  useIonToast,
} from '@ionic/react'
import { AxiosError } from 'axios'
import cx from 'classnames'

import { Entry } from '@components/templates/entry'
import { Loading } from '@/components/atoms/Loading'
import { TimelineError } from '@/components/organisms/TimelineError'
import { FeedVirtualList } from './components/FeedVirtualList'

import { useAccountInfo } from '@/services/account'
import { useFeed } from '@/lib/hooks/useFeed'
import { useHandleVirtuosoScrollToTop } from '@/lib/hooks/useHandleVirtuosoScrollToTop'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useVirtualList } from '@/lib/hooks/useVirtualList'
import { useWallet } from '@/lib/hooks/wallet'
import { queryClient } from '@/lib/queryClient'
import { useLocation } from '@/lib/routing'
import {
  amplitudeIdentifyEvent,
  IIdentifyEvent,
  Tracking,
  TrackingEvent,
} from '@/lib/tracking'

import { Platform } from '@enums'
import { Pages, QueryKeys } from '@enums'
import { IonSegmentCustomEvent, SegmentChangeEventDetail } from '@/interfaces'

import { Header } from './Header'

export const Feed: FC = () => {
  const { t } = useTranslation('common')
  const [presentToast] = useIonToast()
  const [isFeedEnabled, setIsFeedEnabled] = useState(true)
  const [tabIndex, setTabIndex] = useState(0)
  const [hasExclusiveFeedPriority] = useState(
    !!queryClient.getQueryData<boolean>([QueryKeys.IsNewUser])
  )
  const location = useLocation()
  const { pathname = '' } = location
  const { data: accountInfoData, refetch: refetchAccountInfo } =
    useAccountInfo(undefined)
  const { data: userInfo } = useUserInfo()
  const { wallet } = useWallet({ userInfo })

  const ranking = useMemo(
    () => (Math.random() > 0.5 ? 'popularity' : 'popularity_2'),
    []
  )
  const {
    feedData: timelineFeed,
    hasNextPage: hasNextTimelineFeedPage,
    fetchNextPage: fetchNextTimelineFeedPage,
    isFetchingNextPage: isFetchingNextTimelineFeedPage,
    refetchFirstPage: refetchFirstTimelineFeedPage,
    isFetching: isFetchingTimelineFeed,
    hasFeedError: hasTimelineFeedError,
  } = useFeed({
    feedGroup: 'timeline',
    feedId: undefined,
    cacheKey: [QueryKeys.TimelineFeed],
    ranking,
  })

  const {
    feedData: exclusiveFeed,
    hasNextPage: hasNextExclusiveFeedPage,
    fetchNextPage: fetchNextExclusiveFeedPage,
    isFetchingNextPage: isFetchingNextExclusiveFeedPage,
    error: exclusiveFeedError,
    refetchFirstPage: refetchFirstExclusiveFeedPage,
    isLoading: isFetchingExclusiveFeed,
  } = useFeed({
    feedGroup: 'exclusive',
    feedId: undefined,
    cacheKey: [QueryKeys.ExclusiveFeed],
    ranking: undefined,
  })

  const { isAtTop, atTopStateChange, onEndReached } = useVirtualList({
    hasNextPage: hasNextTimelineFeedPage,
    fetchNextPage: fetchNextTimelineFeedPage,
    fetchNextSubscriptionPage: fetchNextExclusiveFeedPage,
    hasNextSubscriptionPage: hasNextExclusiveFeedPage,
    sectionIndex: tabIndex,
    subscriptionFeedError: exclusiveFeedError as AxiosError,
  })

  const { virtuosoRef: timelineVirtuosoRef, scrollToTop: timelineScrollToTop } =
    useHandleVirtuosoScrollToTop()
  const {
    virtuosoRef: exclusiveVirtuosoRef,
    scrollToTop: exclusiveScrollToTop,
  } = useHandleVirtuosoScrollToTop()

  const handleScrollToTop = useCallback(() => {
    tabIndex === 0 ? timelineScrollToTop() : exclusiveScrollToTop()
  }, [exclusiveScrollToTop, tabIndex, timelineScrollToTop])

  const handleRefetchAll = async () => {
    switch (tabIndex) {
      case 0:
        await Promise.all([
          refetchAccountInfo(),
          refetchFirstTimelineFeedPage(),
          !exclusiveFeed?.length ? refetchFirstExclusiveFeedPage() : undefined,
        ])
        break
      case 1:
        await Promise.all([
          refetchAccountInfo(),
          refetchFirstExclusiveFeedPage(),
        ])
        break
    }
  }
  // When routing changes or accountInfoData or userInfo, update user attributes
  useEffect(() => {
    if (!(userInfo && accountInfoData && pathname === `/${Pages.Feed}`)) return

    amplitudeIdentifyEvent<IIdentifyEvent>({
      userId: userInfo.id || '-',
      followerCount: userInfo.followerCount || 0,
      followingCount: userInfo.followingCount || 0,
      userName: userInfo.username || '-',
      refUserName: userInfo?.refUsername || '-',
      userTotalXP: accountInfoData.xp || 0,
      isAmbassador: userInfo?.isAmbassador || false,
      badge: userInfo?.badge || '-',
      appPlatform: Platform.Web,
    })
  }, [pathname, accountInfoData, userInfo])

  useEffect(() => {
    pathname === `/${Pages.Feed}` && Tracking.triggerEvent(TrackingEvent.Feed)
  }, [pathname])

  useEffect(() => {
    setIsFeedEnabled(true)

    return () => setIsFeedEnabled(false)
  }, [])

  const renderFeedContent = useCallback(() => {
    if (!timelineFeed?.length) {
      if (hasTimelineFeedError) {
        return <TimelineError translation={'timeline.errors.feed'} />
      }

      if (isFetchingTimelineFeed || isFetchingExclusiveFeed) {
        return <Loading />
      }
    }

    const primaryFeed = hasExclusiveFeedPriority ? exclusiveFeed : timelineFeed
    const secondaryFeed = hasExclusiveFeedPriority
      ? timelineFeed
      : exclusiveFeed

    const primaryFetching = hasExclusiveFeedPriority
      ? isFetchingExclusiveFeed
      : isFetchingTimelineFeed
    const secondaryFetching = hasExclusiveFeedPriority
      ? isFetchingTimelineFeed
      : isFetchingExclusiveFeed

    const primaryFetchingNextPage = hasExclusiveFeedPriority
      ? isFetchingNextExclusiveFeedPage
      : isFetchingNextTimelineFeedPage
    const secondaryFetchingNextPage = hasExclusiveFeedPriority
      ? isFetchingNextTimelineFeedPage
      : isFetchingNextExclusiveFeedPage

    const primaryVirtuosoRef = hasExclusiveFeedPriority
      ? exclusiveVirtuosoRef
      : timelineVirtuosoRef
    const secondaryVirtuosoRef = hasExclusiveFeedPriority
      ? timelineVirtuosoRef
      : exclusiveVirtuosoRef

    return (
      <>
        <FeedVirtualList
          className={cx({ hidden: tabIndex !== 0 })}
          feed={primaryFeed}
          isFetching={primaryFetching}
          isFetchingNextPage={primaryFetchingNextPage}
          isFeedEnabled={isFeedEnabled}
          virtuosoRef={primaryVirtuosoRef}
          isAtTop={isAtTop}
          atTopStateChange={atTopStateChange}
          onEndReached={onEndReached}
        />
        <FeedVirtualList
          className={cx({ hidden: tabIndex !== 1 })}
          feed={secondaryFeed}
          isFetching={secondaryFetching}
          isFetchingNextPage={secondaryFetchingNextPage}
          isFeedEnabled={isFeedEnabled}
          virtuosoRef={secondaryVirtuosoRef}
          isAtTop={isAtTop}
          atTopStateChange={atTopStateChange}
          onEndReached={onEndReached}
        />
      </>
    )
  }, [
    timelineFeed,
    tabIndex,
    isFetchingTimelineFeed,
    isFetchingNextTimelineFeedPage,
    isFeedEnabled,
    timelineVirtuosoRef,
    isAtTop,
    atTopStateChange,
    onEndReached,
    exclusiveFeed,
    isFetchingExclusiveFeed,
    isFetchingNextExclusiveFeedPage,
    exclusiveVirtuosoRef,
    hasTimelineFeedError,
    hasExclusiveFeedPriority,
  ])

  const header = useMemo(
    () => (
      <Header
        wallet={wallet}
        scrollToTop={
          tabIndex === 0 ? timelineScrollToTop : exclusiveScrollToTop
        }
      />
    ),
    [exclusiveScrollToTop, tabIndex, timelineScrollToTop, wallet]
  )

  const subHeader = useMemo(() => {
    if (!exclusiveFeed?.length) return null

    return (
      <IonSegment
        mode="md"
        value={String(tabIndex)}
        onIonChange={(e: IonSegmentCustomEvent<SegmentChangeEventDetail>) => {
          const indexValue = Number(e.detail.value)
          setTabIndex(indexValue)
        }}
      >
        <IonSegmentButton value="0">
          <IonLabel>
            {t(
              `common.head.title.${
                hasExclusiveFeedPriority ? 'exclusiveFeed' : 'feed'
              }`
            )}
          </IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="1">
          <IonLabel>
            {t(
              `common.head.title.${
                hasExclusiveFeedPriority ? 'feed' : 'exclusiveFeed'
              }`
            )}
          </IonLabel>
        </IonSegmentButton>
      </IonSegment>
    )
  }, [exclusiveFeed?.length, t, tabIndex, hasExclusiveFeedPriority])

  useEffect(() => {
    if (timelineFeed?.length && hasTimelineFeedError) {
      presentToast({
        message: t('timeline.errors.feed'),
        position: 'top',
        duration: 3000,
      })
    }
  }, [
    hasTimelineFeedError,
    isFetchingTimelineFeed,
    timelineFeed,
    presentToast,
    t,
  ])

  return (
    <>
      <Entry
        headTitle={t('common.head.title.feed')}
        refetch={handleRefetchAll}
        header={header}
        subHeader={subHeader}
        isRefetchActive={isAtTop}
        onActiveNavIconPress={handleScrollToTop}
        hasData={!!timelineFeed?.length}
        isPaddingEnabled={false}
      >
        {renderFeedContent()}
      </Entry>
    </>
  )
}

import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonButton, IonButtons, IonIcon, IonTitle } from '@ionic/react'
import { createOutline } from 'ionicons/icons'
import { DefaultGenerics } from 'stream-chat'
import { ChannelFilters } from 'stream-chat/dist/types'
import {
  ChannelList,
  Chat,
  InfiniteScroll,
  InfiniteScrollProps,
} from 'stream-chat-react'

import { BackButton } from '@/components/atoms/BackButton'
import { Loading } from '@/components/atoms/Loading'
import { ChannelListItem } from '@/components/atoms/Messaging/ChannelListItem'
import { Title } from '@/components/atoms/Title'
import { Main } from '@/components/templates/main'

import { useConnectUser } from '@/lib/hooks/useChatClient'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

export const Channels: FC = () => {
  const navigate = useNavigate()
  const [initialState, setInitialState] = useState<{
    isLoading: boolean
    hasChannels: boolean
  }>({ isLoading: true, hasChannels: true })
  const [, setChannelHandler] = useState<{ unsubscribe: () => void }>(null)
  const { t } = useTranslation('common')
  const { data: authUser } = useUserInfo()

  const filters: ChannelFilters<DefaultGenerics> = useMemo(() => {
    return {
      members: { $in: [authUser.id] },
      $or: [
        // explanation: https://support.getstream.io/hc/en-us/articles/5274621107991-How-to-use-queryChannels-filter-to-return-empty-channels-only-to-the-channel-creator
        { last_message_at: { $gte: '2020-10-10T16:01:49.27673Z' } },
        { created_by_id: authUser.id },
      ],
    }
  }, [authUser])

  const options = { limit: 20, messagesLimit: 1, state: true, presence: true }
  const { chatClient, isLoading, errorCode } = useConnectUser()

  useEffect(() => {
    if (isLoading) return

    if (!chatClient) {
      setInitialState((prev) => ({
        ...prev,
        isLoading: false,
      }))
      return
    }

    const handler = chatClient.on((event) => {
      if (event.type === 'channels.queried' && initialState.isLoading) {
        setInitialState({
          hasChannels: !!event.queriedChannels?.channels?.length,
          isLoading: false,
        })
      }
    })

    setChannelHandler(handler)

    return () => {
      setChannelHandler((handler) => {
        handler?.unsubscribe()
        return null
      })
    }
  }, [isLoading, chatClient, initialState.isLoading])

  useEffect(() => {
    if (initialState.isLoading || initialState.hasChannels) {
      return
    }

    navigate(Routes.MESSAGES_FIND_USERS, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState])

  const header = useMemo(
    () => (
      <>
        <IonButtons slot="secondary">
          <BackButton defaultHref={Routes.FEED} />
        </IonButtons>
        <IonTitle>
          {!initialState.isLoading && (
            <Title level={3}>
              {initialState.hasChannels && t('messaging.inbox')}
            </Title>
          )}
        </IonTitle>
        <IonButtons slot="primary">
          {initialState.hasChannels && (
            <IonButton
              mode="md"
              className="round plain ion-color"
              onClick={() => navigate(Routes.MESSAGES_FIND_USERS)}
            >
              <IonIcon icon={createOutline} color="success" />
            </IonButton>
          )}
        </IonButtons>
      </>
    ),
    [initialState, t, navigate]
  )

  const Paginator = useCallback(
    (props: InfiniteScrollProps) => <InfiniteScroll {...props} />,
    []
  )

  return (
    <Main header={header} contentClassName="h-full" isPaddingEnabled={false}>
      {!!chatClient && (
        <Chat client={chatClient} theme="str-chat__theme-dark">
          <ChannelList
            EmptyStateIndicator={Loading}
            LoadingIndicator={Loading}
            Paginator={Paginator}
            filters={filters}
            sort={[{ last_message_at: -1 }]}
            options={options}
            Preview={(props) => {
              return (
                <ChannelListItem
                  key={props.displayTitle}
                  lastMessage={props?.lastMessage}
                  latestMessage={props?.latestMessage}
                  messageDeliveryStatus={props?.messageDeliveryStatus}
                  channel={props?.channel}
                  authUser={authUser}
                />
              )
            }}
          />
        </Chat>
      )}
      {initialState.isLoading && !chatClient && <Loading />}
      {!!errorCode && (
        <span className="mt-40 px-4 text-center">
          <Trans>{t('messaging.errorMessage')}</Trans>
        </span>
      )}
    </Main>
  )
}

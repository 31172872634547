import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import {
  Channel,
  ChannelFilters,
  ChannelOptions,
  ChannelSort,
  DefaultGenerics,
  ExtendableGenerics,
  StreamChat,
} from 'stream-chat'

import { appConfig } from '@/services/config'
import { createChannel } from '@/services/messaging'
import { useToken } from '@/services/token'
import { useShowErrorToast } from '@/lib/hooks/useShowErrorToast'
import { Routes } from '@/router/routes'

import { useNavigate } from '../routing'
import { useErrorBoundary } from './useErrorBoundary'
import { useUserInfo } from './useUserInfo'

export const useConnectUser = () => {
  const apiKey = appConfig.getstreamApiKey
  const { data: authUser } = useUserInfo()
  const userToConnect = useMemo(() => {
    return {
      id: authUser?.id,
      name: authUser?.username,
      image: authUser?.avatar,
    }
  }, [authUser])
  const { data: tokens } = useToken()
  const [isLoading, setIsLoading] = useState(true)
  const [chatClient, setChatClient] = useState(null)
  const [error, setError] = useState(null)

  const client = useMemo(
    () =>
      StreamChat.getInstance(apiKey, {
        enableInsights: true,
        enableWSFallback: true,
      }),
    [apiKey]
  )

  const disconnectUser = useCallback(async () => {
    await client.disconnectUser()
    await client.closeConnection()
  }, [client])

  const getChatClient = useCallback(async () => {
    try {
      setIsLoading(true)
      if (!tokens?.getStreamToken || !userToConnect.id) {
        setIsLoading(false)
      }

      await client.connectUser(userToConnect, tokens.getStreamToken)
      setChatClient(client)
    } catch (error) {
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }, [tokens?.getStreamToken, client, userToConnect])

  useEffect(() => {
    if (tokens?.getStreamToken) getChatClient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens?.getStreamToken])

  const errorCode = useMemo(() => {
    try {
      const parseError = JSON.parse(error?.message)
      return parseError?.StatusCode
    } catch (error) {
      return undefined
    }
  }, [error])

  return {
    chatClient,
    disconnectUser,
    isLoading,
    errorCode: errorCode || 0,
    error,
  }
}

export const useGetChannels = <
  SCG extends ExtendableGenerics = DefaultGenerics
>({
  chatClient,
  options,
  filters,
  tryToCreateChannel = false,
}: {
  chatClient: StreamChat<SCG>
  options: ChannelOptions
  filters: ChannelFilters<SCG>
  tryToCreateChannel?: boolean
}) => {
  const navigate = useNavigate()
  const sort: ChannelSort<SCG> = useMemo(() => ({ last_message_at: -1 }), [])
  const { t } = useTranslation('common')
  const { handleError } = useErrorBoundary()
  const { showErrorToast } = useShowErrorToast()

  const getChannels = useCallback(async (): Promise<Channel<SCG>[]> => {
    if (!chatClient) return []
    const result = await chatClient?.queryChannels(filters, [sort], {
      ...options,
      state: true,
    })

    if (result?.length) {
      return result
    }

    if (tryToCreateChannel) {
      await createChannel(filters?.members?.['$eq'][0])
      const createdChannels = await getChannels()
      if (createdChannels.length) {
        return createdChannels
      }
      throw new Error('Failed to create channel')
    }

    return []
  }, [chatClient, filters, options, sort, tryToCreateChannel])

  const {
    data: channels,
    isLoading: isChannelLoading,
    refetch,
    error,
    isError,
  } = useQuery({
    queryKey: ['$queryChannels', filters],
    queryFn: getChannels,
    enabled: !!chatClient && !!filters,
    gcTime: 0,
  })

  useEffect(() => {
    if (isError) {
      handleError(error, () => {
        history.length > 1
          ? navigate(-1)
          : navigate(Routes.ROOT, { replace: true })
      })
    }
  }, [error, handleError, isError, navigate, showErrorToast, t])

  return { channels, isChannelLoading, refetchChannels: refetch }
}

import { memo } from 'react'

interface ILogoProps {
  className?: string
}

const LogoComponent = ({ className = 'fill-primary' }: ILogoProps) => {
  return (
    <svg
      viewBox="0 0 92.357 73.358"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M 0.021 0.027 L 0.021 60.627 L 63.411 60.427 C 68.201 60.427 71.861 61.637 74.731 65.847 C 74.841 65.997 74.991 66.117 75.101 66.267 C 79.181 71.547 84.351 73.747 90.511 73.267 C 91.871 73.177 92.351 72.607 92.331 71.147 L 92.331 61.587 C 92.331 59.757 91.811 59.017 90.131 59.057 L 76.981 59.157 C 76.981 59.157 69.361 58.307 65.771 53.987 L 64.291 52.337 C 61.142 48.477 56.402 46.267 51.421 46.337 L 35.331 46.337 C 27.201 46.337 18.061 46.337 9.931 46.337 L 9.931 36.967 C 21.701 36.967 34.241 36.967 46.011 36.967 C 47.888 36.881 49.711 37.61 51.011 38.967 C 52.281 40.297 53.631 41.557 54.921 42.857 C 58.089 46.214 62.485 48.138 67.101 48.187 C 74.891 48.297 82.681 48.187 90.461 48.247 C 91.911 48.247 92.401 47.627 92.381 46.247 C 92.381 43.557 92.381 40.867 92.381 38.177 C 92.381 35.787 92.381 35.747 89.981 35.747 L 56.871 35.747 C 48.871 35.747 49.871 36.287 44.231 30.557 C 40.387 26.386 34.896 24.127 29.231 24.387 C 23.121 24.567 16.031 24.387 9.921 24.447 L 9.921 14.307 L 26.181 14.307 C 28.037 14.275 29.808 15.084 31.001 16.507 C 32.571 18.227 34.071 20.037 35.671 21.717 C 38.671 24.887 42.211 26.917 46.371 26.947 C 61.081 27.027 75.791 26.947 90.501 27.017 C 91.851 27.017 92.371 26.437 92.351 24.947 C 92.351 21.887 92.351 18.837 92.351 15.777 C 92.351 12.877 92.281 12.777 89.751 12.777 C 72.381 12.777 55.021 12.777 37.651 12.777 C 28.121 12.777 30.331 13.917 23.561 6.237 C 20.177 2.247 15.192 -0.03 9.961 0.027 L 0.021 0.027 Z" />
    </svg>
  )
}

export const Logo = memo(LogoComponent)

import { memo } from 'react'

const WithdrawComponent = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 6.26511V9.75159C1.5 11.4084 2.84315 12.7516 4.5 12.7516H10.5C12.1569 12.7516 13.5 11.4084 13.5 9.75159V6.26511"
        stroke="#CCFF00"
        strokeWidth="1.2"
      />
      <path
        d="M7.66213 9.83272V1.72461M7.66213 1.72461L5.06754 4.96785M7.66213 1.72461L10.2567 4.96785"
        stroke="#CCFF00"
        strokeWidth="1.2"
      />
    </svg>
  )
}

export const Withdraw = memo(WithdrawComponent)

import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButton, IonButtons, IonIcon, IonTitle } from '@ionic/react'

import { Main } from '@components/templates/main'
import { BackButton } from '@/components/atoms/BackButton'
import { InfiniteScrollLoader } from '@/components/atoms/InfiniteScrollLoader'
import { Loading } from '@/components/atoms/Loading'
import { Title } from '@/components/atoms/Title'
import { NoResults } from '@/components/molecules/NoResults'
import { UsersListItem } from '@/components/organisms/UsersListItem'
import { VirtualList } from '@/components/organisms/VirtualList'

import { useGetLikes } from '@/lib/hooks/useGetLikes'
import { useHandleVirtuosoScrollToTop } from '@/lib/hooks/useHandleVirtuosoScrollToTop'
import { useParams } from '@/lib/routing'
import { Routes } from '@/router/routes'

export const LikesPage: FC = () => {
  const { t } = useTranslation('common')
  const [isRefetchActive, setRefetchActive] = useState(true)

  const { activityId } = useParams<{ activityId: UUID }>()
  const {
    data: usersData,
    hasNextPage: hasUsersDataNextPage,
    fetchNextPage: fetchUsersDataNextPage,
    isLoading: isUsersListLoading,
    refetch: refetchUsersData,
  } = useGetLikes(activityId)

  const { virtuosoRef } = useHandleVirtuosoScrollToTop()
  const header = (
    <>
      <IonButtons slot="start">
        <BackButton className="text-white" defaultHref={Routes.FEED} />
      </IonButtons>
      <IonTitle>
        <Title level={2}>{t('common.head.title.likes')}</Title>
      </IonTitle>
      <IonButtons slot="end">
        <IonButton>
          <IonIcon slot="icon-only" />
        </IonButton>
      </IonButtons>
    </>
  )

  return (
    <Main
      headTitle={t('common.head.title.likes')}
      header={header}
      contentClassName="h-full"
      refetch={refetchUsersData}
      isRefetchActive={isRefetchActive}
      isPaddingEnabled={false}
    >
      {isUsersListLoading ? (
        <Loading />
      ) : (
        <>
          {!usersData?.length && !isUsersListLoading && (
            <div className="mx-auto max-w-[40rem]">
              <NoResults />
            </div>
          )}
          <VirtualList
            atTopStateChange={(isAtTop: boolean) => {
              setRefetchActive(isAtTop)
            }}
            className="h-full max-w-[40rem] ion-content-scroll-host"
            data={usersData}
            endReached={() => fetchUsersDataNextPage()}
            components={{
              Footer: () => (
                <InfiniteScrollLoader hasNext={hasUsersDataNextPage} />
              ),
            }}
            itemContent={(index) => <UsersListItem item={usersData[index]} />}
            virtualListRef={virtuosoRef}
          />
        </>
      )}
    </Main>
  )
}

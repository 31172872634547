import { useEffect } from 'react'
import { UseFormSetError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'
import { AxiosError } from 'axios'

import { appConfig } from '@/services/config'
import { StakeNetworkError } from '@/services/stake/stakesEstimate'

import { useShowErrorToast } from '../useShowErrorToast'

type UseHandleErrors = {
  (params: {
    estimateError?: AxiosError
    createStakeError?: AxiosError
    redeemError?: AxiosError
    reStakeError?: AxiosError
    setError?: UseFormSetError<{
      amount: string
    }>
  }): void
}

export const useHandleErrors: UseHandleErrors = ({
  estimateError,
  createStakeError,
  redeemError,
  reStakeError,
  setError,
}) => {
  const { t } = useTranslation('common')
  const [presentToast] = useIonToast()
  const { showErrorToast } = useShowErrorToast()

  useEffect(() => {
    const error =
      estimateError || createStakeError || redeemError || reStakeError
    if (!error) return

    const translationKey = StakeNetworkError[error.response?.data]

    if (translationKey === StakeNetworkError.STAKE_POOL_EXHAUSTED) {
      presentToast({
        header: t('wallet.stake.toasts.stakingFullySubscribedHeader'),
        message: t('wallet.stake.toasts.stakingFullySubscribedMessage'),
        position: 'top',
        duration: 3000,
      })
      return
    }

    const errorConditions = [
      StakeNetworkError.STAKE_INCORRECT_STATUS,
      StakeNetworkError.WALLET_DOES_NOT_BELONG_TO_ACTOR,
      StakeNetworkError.STAKE_DOES_NOT_BELONG_TO_ACTOR,
      StakeNetworkError.STAKE_NOT_FOUND,
    ]

    if (errorConditions.includes(translationKey)) {
      presentToast({
        message: t(`wallet.stake.errors.${translationKey as string}`),
        position: 'top',
        duration: 3000,
      })
      return
    }

    if (translationKey) {
      const message = t(`wallet.stake.errors.${translationKey as string}`, {
        currency: appConfig.currency,
      })

      if (setError) {
        setError('amount', {
          type: 'custom',
          message,
        })
      } else {
        showErrorToast({ message })
      }
      return
    }

    const message = t('wallet.stake.errors.generalError')

    if (setError) {
      setError('amount', { message })
    } else {
      showErrorToast({ message })
    }
  }, [
    estimateError,
    createStakeError,
    setError,
    t,
    presentToast,
    redeemError,
    reStakeError,
    showErrorToast,
  ])
}

import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonButtons, IonTitle, isPlatform } from '@ionic/react'
import queryString from 'query-string'
import { ChannelState } from 'stream-chat'
import {
  Channel,
  Chat,
  MessageInput,
  MessageList,
  SendButtonProps,
  Window,
} from 'stream-chat-react'

import { SendIcon } from '@/components/atoms/assets/SendIcon'
import { BackButton } from '@/components/atoms/BackButton'
import { Loading } from '@/components/atoms/Loading'
import { Title } from '@/components/atoms/Title'
import { UserName } from '@/components/atoms/UserName'
import { ReportButton } from '@/components/molecules/ReportButton'
import { Main } from '@/components/templates/main'

import { useConnectUser, useGetChannels } from '@/lib/hooks/useChatClient'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Link, useLocation, useParams } from '@/lib/routing'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { Routes } from '@/router/routes'

import { Pages, ReportButtonType } from '@/enums'

import { CustomAttachment } from './CustomAttachment'
import { customReactionOptions } from './customReactionOptions'
import { MessageAvatar } from './MessagesAvatar'
import { MessageStatus } from './MessageStatus'

export const Messages: FC = () => {
  const { search } = useLocation()
  const [isPresent, setIsPresent] = useState(false)
  const { page = '' } = queryString.parse(search) as { page: string }
  const [companion, setCompanion] = useState<{ name?: string; id?: UUID }>({})
  const { userId } = useParams<{ userId: string }>()
  const { data: authUser } = useUserInfo()
  const { t } = useTranslation('common')

  /**
   * Refer to the link for more information on the different types of filters:
   * https://support.getstream.io/hc/en-us/articles/360057461213-Filters-for-queryChannels-Chat
   */
  const filters = useMemo(
    () => ({
      members: { $eq: [userId, authUser.id] },
      type: 'messaging',
    }),
    [userId, authUser.id]
  )
  const options = { limit: 1, messagesLimit: 1, state: true, presence: true }

  const { chatClient, errorCode } = useConnectUser()
  const { channels, isChannelLoading } = useGetChannels({
    chatClient,
    options,
    filters,
    tryToCreateChannel: true,
  })

  const companionUserId = useMemo(() => {
    if (!channels) return null
    return Object.keys(channels?.[0].state?.members)?.filter(
      (member) => member !== authUser.id
    )?.[0]
  }, [channels, authUser.id])

  useEffect(() => {
    if (!channels?.length || !companionUserId) return

    const channelState = channels[0].state as ChannelState

    setCompanion({
      name: channelState.members?.[companionUserId].user.name,
      id: companionUserId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels])

  const header = useMemo(
    () => (
      <>
        <IonButtons slot="secondary">
          <BackButton defaultHref={Routes.MESSAGES} />
        </IonButtons>
        <IonTitle>
          <Title level={3}>
            <Link to={`/@${companion?.name}`}>
              <UserName className="font-bold" name={companion?.name} />
              {isPresent && (
                <div className="text-xs text-primary">{t('common.online')}</div>
              )}
            </Link>
          </Title>
        </IonTitle>
        <IonButtons slot="primary">
          <ReportButton
            type={ReportButtonType.Messaging}
            confirmationLabel={t('okay')}
            userId={userId}
            isDisabled={!channels?.length}
          />
        </IonButtons>
      </>
    ),
    [companion?.name, t, userId, channels?.length, isPresent]
  )

  useEffect(() => {
    if (!chatClient || !channels || !companionUserId) return

    setIsPresent(
      () => channels?.[0].state?.members?.[companionUserId]?.user?.online
    )
    const handler = chatClient.on('user.presence.changed', (event) => {
      if (event.user.id === companionUserId) {
        setIsPresent(event?.user?.online)
      }
    })

    return () => {
      handler.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatClient, channels?.length])

  const ChatSendButton = useCallback(
    ({ sendMessage, ...props }: SendButtonProps) => (
      <button
        {...props}
        className="str-chat__send-button"
        onClick={sendMessage}
        type="button"
        onMouseDown={(e) => {
          e.preventDefault()
          page === Pages.Feed &&
            Tracking.triggerEvent(TrackingEvent.MessageSent)
        }}
      >
        <SendIcon />
      </button>
    ),
    [page]
  )

  return (
    <Main
      contentClassName="h-full overflow-auto"
      header={header}
      isPaddingEnabled={false}
    >
      {isChannelLoading && <Loading />}
      {!!chatClient && !!channels?.length && !isChannelLoading && (
        <Chat client={chatClient} theme="str-chat__theme-dark">
          <Channel
            Avatar={MessageAvatar}
            channel={channels?.[0]}
            EmojiPicker={() => null}
            SendButton={ChatSendButton}
            MessageStatus={MessageStatus}
            Attachment={CustomAttachment}
            reactionOptions={customReactionOptions}
          >
            <Window>
              <MessageList
                messageActions={['delete', 'edit', 'react']}
                customMessageActions={{
                  'Copy text': (message) => {
                    navigator.clipboard.writeText(message?.text || '')
                  },
                }}
              />
              <MessageInput
                grow
                disableMentions
                maxRows={3}
                shouldSubmit={isPlatform('desktop') ? undefined : () => null}
                additionalTextareaProps={{
                  onBlur: () => {
                    if (window.scrollY > 0)
                      window.scrollTo({ top: 0, behavior: 'smooth' })
                  },
                }}
              />
            </Window>
          </Channel>
        </Chat>
      )}
      {!!errorCode && (
        <span className="mt-40 px-4 text-center">
          <Trans>{t('messaging.errorMessage')}</Trans>
        </span>
      )}
    </Main>
  )
}

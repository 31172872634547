import { useCallback, useEffect, useState } from 'react'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getOnboarding } from '@/services/onboarding'

import { pageStates } from '@/enums'

export const OnboardingQueryKey = 'onboarding'

export type OnboardingData = {
  id: string
  items?: {
    title: string
    label: string
    subtitle: string
    image: string
  }[]
  cta?: {
    title: string
  }
}

export const useOnboarding = (
  options?: Omit<UseQueryOptions<OnboardingData>, 'queryKey'>
) => {
  // pageState 0 Loading 1 The first time to load 2 Not loading for the first time
  const [pageState, setPageState] = useState(pageStates.Loading)

  const { data, isLoading, error, isFetched } = useQuery<OnboardingData>({
    queryKey: [OnboardingQueryKey],
    queryFn: getOnboarding,
    ...options,
  })

  const getOnboardingPreferences = useCallback(() => {
    const onboardingStatus = localStorage.getItem('onboardingStatus')

    try {
      return JSON.parse(onboardingStatus || '[]')
    } catch {
      return []
    }
  }, [])

  const getOnboardingState = useCallback(async () => {
    if (!data) return true
    const status = getOnboardingPreferences()

    const pageStatesFlag = status.includes(data?.id)
      ? pageStates.NonFirstLoaded
      : pageStates.FirstLoaded

    setPageState(pageStatesFlag)
  }, [data, getOnboardingPreferences])

  const finishOnboarding = useCallback(async () => {
    const status = getOnboardingPreferences()

    localStorage.setItem(
      'onboardingStatus',
      JSON.stringify([...status, data?.id])
    )
    setPageState(pageStates.NonFirstLoaded)
  }, [data?.id, getOnboardingPreferences])

  useEffect(() => {
    getOnboardingState()
  }, [data?.id, getOnboardingState])

  return {
    finishOnboarding,
    pageState,
    data,
    isLoading,
    isFetched,
    error,
  }
}

import { useMutation } from '@tanstack/react-query'

import { deleteConversation, deleteReply } from '@/services/conversation'
import { CustomStreamClient } from '@/services/customStreamClient'
import { getActivityByForeignIdTimes } from '@/services/getstream'
import { queryClient } from '@/lib/queryClient'

import { QueryKeys } from '@/enums'

import { ResponseType } from './useDailyLimitToast'
import { useErrorBoundary } from './useErrorBoundary'
import { useFileUpload } from './useFileUpload'
import { useInvalidateQueries } from './useInvalidateQueries'

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const useConversation = () => {
  const { invalidateQueriesTimeout } = useInvalidateQueries()
  const { progressPercentage, isUploading } = useFileUpload()
  const { handleError } = useErrorBoundary()

  const {
    mutateAsync: onDeleteConversation,
    isPending: isDeleteConversationMutationLoading,
    isError: onDeleteConversationMutationError,
  } = useMutation({
    mutationKey: ['deleteConversation'],
    mutationFn: deleteConversation,
    onError: (error: { response: ResponseType }) => handleError(error),
    onSettled: () => {
      invalidateQueriesTimeout()
    },
  })

  const {
    mutateAsync: onDeleteReply,
    isPending: isDeleteReplyLoading,
    isError: deleteReplyError,
  } = useMutation({
    mutationKey: ['onDeleteReply'],
    mutationFn: deleteReply,
    onError: (error: { response: ResponseType }) => handleError(error),
    onSettled: () => {
      invalidateQueriesTimeout()
    },
  })

  const getActivityId = async (
    client: CustomStreamClient,
    id: UUID,
    date: string
  ) => {
    const [activity] = await getActivityByForeignIdTimes(client, [
      {
        foreignID: id,
        time: date,
      },
    ])

    queryClient.setQueryData([QueryKeys.ActivityData, activity?.id], activity)

    return activity?.id
  }

  return {
    onDeleteReply,
    onDeleteConversation,
    isDeleteReplyLoading,
    deleteReplyError,
    isDeleteConversationMutationLoading,
    onDeleteConversationMutationError,
    getActivityId,
    isUploading,
    progressPercentage,
  }
}

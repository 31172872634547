import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { IonButton, IonButtons, IonIcon, IonTitle } from '@ionic/react'
import cx from 'classnames'
import { chevronBack } from 'ionicons/icons'
import { useDebounceValue } from 'usehooks-ts'

import { Title } from '@/components/atoms/Title'
import WarningAlert, {
  WarningAlertRef,
} from '@/components/molecules/WarningAlert'
import { Main } from '@/components/templates/main'
import {
  Amount,
  CountdownButton,
  EditWalletAddress,
  ImportantWarning,
  TransferFees,
  WalletAddress,
} from './components'
import { ConfirmWithdrawalCode } from './components/ConfirmWithdrawalCode'

import { appConfig } from '@/services/config'
import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'

import { QueryKeys } from '@/enums'

import { NetworkDropdown } from '../Wallet/components/NetworkDropdown'
import { Network } from '../Wallet/components/NetworkDropdown/enums'
import { useWithdraw } from './hooks'
import { useHandleErrors } from './hooks/useHandleErrors'

export type FormValues = {
  amount: string
  externalWalletId: string
  authenticatorCode: string
}

export const Withdraw = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const warningRef = useRef<WarningAlertRef | null>(null)
  const [lossOfFundsWarningPresented, setLossOfFundsWarningPresented] =
    useState(false)
  const [isSummary, setIsSummary] = useState(false)
  const [selectedNetwork, setSelectedNetwork] = useState<string>()
  const methods = useForm({
    defaultValues: {
      amount: '',
      externalWalletId: '',
      authenticatorCode: '',
    },
    mode: 'all',
  })
  const {
    handleSubmit,
    watch,
    reset,
    trigger,
    setError,
    formState: { isValid },
  } = methods
  const [amount, externalWalletId, authenticatorCode] = watch([
    'amount',
    'externalWalletId',
    'authenticatorCode',
  ])

  const [debouncedAmount] = useDebounceValue(amount, 500)

  const {
    estimation,
    estimationError,
    isFetching,
    isPending,
    countdown,
    mutateExecuteWithdraw,
    executeWithdrawError,
  } = useWithdraw({
    externalWalletId,
    amount: Number(debouncedAmount),
    selectedNetwork,
  })

  useHandleErrors({
    estimation,
    estimationError,
    executeWithdrawError,
    selectedNetwork,
    setError,
  })

  useEffect(() => {
    if (selectedNetwork === Network.Arbitrum && !lossOfFundsWarningPresented) {
      setLossOfFundsWarningPresented(true)
      warningRef?.current?.open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNetwork])

  const handleGoBack = useCallback(() => {
    if (isSummary) {
      setIsSummary(false)
      trigger()
      return
    }

    navigate(-1)
  }, [navigate, isSummary, trigger])

  const handleContinue = useCallback(() => {
    setIsSummary(true)
    trigger()
  }, [trigger])

  const handleExecuteWithdraw = useCallback(() => {
    mutateExecuteWithdraw({ authenticatorCode })
  }, [mutateExecuteWithdraw, authenticatorCode])

  useEffect(() => {
    return () => reset()
  }, [reset])

  const header = useMemo(
    () => (
      <>
        <IonButtons slot="start">
          <IonButton onClick={handleGoBack}>
            <IonIcon
              icon={chevronBack}
              slot="icon-only"
              className="ion-color ion-color-success"
            />
          </IonButton>
        </IonButtons>
        <IonTitle className="px-2">
          <Title level={2}>
            {t('withdraw.pageTitle', { currency: appConfig.currency })}
          </Title>
        </IonTitle>
        <IonButtons slot="end">
          <IonButton>
            <IonIcon slot="icon-only" />
          </IonButton>
        </IonButtons>
      </>
    ),
    [handleGoBack, t]
  )

  const backButtonListener = useCallback(() => handleGoBack, [handleGoBack])

  useEffect(() => {
    queryClient.removeQueries({ queryKey: [QueryKeys.EstimateWithdraw] })
    document.addEventListener('ionBackButton', backButtonListener)

    return () => {
      document.removeEventListener('ionBackButton', backButtonListener)
    }
  }, [backButtonListener])

  return (
    <Main
      headTitle={t('XPWallet.title')}
      header={header}
      isPaddingEnabled={false}
    >
      <WarningAlert
        ref={warningRef}
        title={t('withdraw.warningTitle')}
        description={
          <Trans
            i18nKey="activity.item.followMany"
            values={{
              currency: appConfig.currency,
            }}
          >
            {t('withdraw.warningDescriptionAlert', {
              currency: appConfig.currency,
            })}
          </Trans>
        }
        acknowledgeText={t('withdraw.warningAcknowledgeAlert')}
      />

      <div className="px-8 py-4">
        {!isSummary && (
          <NetworkDropdown
            value={selectedNetwork}
            onSelect={setSelectedNetwork}
          />
        )}
        <FormProvider {...methods}>
          <div
            className={cx({
              'opacity-50 pointer-events-none': !selectedNetwork,
            })}
          >
            <Amount estimation={estimation} isSummary={isSummary} />
            {isSummary && <WalletAddress walletAddress={externalWalletId} />}
            <TransferFees
              selectedNetwork={selectedNetwork}
              estimation={estimation}
              isFetching={isFetching}
              isSummary={isSummary}
            />
            {!isSummary ? <EditWalletAddress /> : <ConfirmWithdrawalCode />}
          </div>
          {!isSummary && <ImportantWarning selectedNetwork={selectedNetwork} />}
          <CountdownButton
            title={
              !isSummary
                ? t('withdraw.continue')
                : t('withdraw.withdrawAmount', { currency: appConfig.currency })
            }
            isSummary={isSummary}
            isExecutable={!estimationError && !!estimation?.executable}
            isValid={isValid}
            isFetching={isFetching || isPending}
            countdown={!isPending && !isFetching ? countdown : null}
            onClick={
              !isSummary ? handleContinue : handleSubmit(handleExecuteWithdraw)
            }
          />
        </FormProvider>
      </div>
    </Main>
  )
}

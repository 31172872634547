import { FC, MutableRefObject, useCallback } from 'react'
import { VirtuosoHandle } from 'react-virtuoso'

import { InfiniteScrollLoader } from '@/components/atoms/InfiniteScrollLoader'
import { VirtualList } from '@/components/organisms/VirtualList'

import { useActivity } from '@/lib/hooks/useActivity'

type FeedVirtualListProps = {
  className?: string
  virtuosoRef: MutableRefObject<VirtuosoHandle>
  feed: unknown
  isFetching: boolean
  isFetchingNextPage: boolean
  isFeedEnabled: boolean
  isAtTop: boolean
  atTopStateChange: (isAtTop: boolean) => void
  onEndReached: (e) => NodeJS.Timeout
}

export const FeedVirtualList: FC<FeedVirtualListProps> = ({
  className = '',
  virtuosoRef,
  feed,
  isFetching,
  isFetchingNextPage,
  isFeedEnabled,
  isAtTop,
  atTopStateChange,
  onEndReached,
}) => {
  const {
    activeActivityId,
    handleScroll,
    renderActivity,
    scrollSeekConfigurationExit,
    scrollSeekConfigurationEnter,
  } = useActivity({
    feed,
    isAtTop,
    isFeedFetching: isFetching,
    isFeedEnabled,
  })

  const itemContent = useCallback(
    (index, item) => renderActivity(item, activeActivityId, index),
    [activeActivityId, renderActivity]
  )

  const Footer = useCallback(() => {
    return <InfiniteScrollLoader hasNext={!!isFetchingNextPage} />
  }, [isFetchingNextPage])

  return (
    <VirtualList
      atTopStateChange={atTopStateChange}
      className={`h-full mx-auto max-w-[40rem] ${className}`}
      data={feed}
      endReached={onEndReached}
      onScroll={handleScroll}
      overscan={300}
      components={{
        Footer,
      }}
      itemContent={itemContent}
      virtualListRef={virtuosoRef}
      scrollSeekConfiguration={{
        enter: scrollSeekConfigurationEnter,
        exit: scrollSeekConfigurationExit,
      }}
    />
  )
}

import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Virtual } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'

import { Avatar } from '@/components/atoms/Avatar'
import { UserName } from '@/components/atoms/UserName'
import { UserRelationIndicator } from '@/components/atoms/UserRelationIndicator'
import { LazyImg } from '@/components/molecules/LazyImg'
import { TapToLikeWrapper } from '@/components/molecules/TapToLikeWrapper'
import { VideoContainer } from '@/components/molecules/VideoContainer'

import { usePlaybackState } from '@/lib/hooks/usePlaybackState'
import { useTrackViewedActivity } from '@/lib/hooks/useTrackViewedActivity/useTrackViewedActivity'
import { Link } from '@/lib/routing'
import { Context } from '@/lib/store'
import { TrackingEvent } from '@/lib/tracking'
import { getSliderImage } from '@/utils/url'

import { MediaType, UserRelationIndicatorType } from '@/enums'
import { IConversation, IReply } from '@/interfaces'

interface IConversationActivitySlider {
  startedConversationId: UUID
  initialSlide?: number
  setReplyIndex: (index: number) => void
  replyIndex?: number
  index: number
  conversationReplyId: UUID
  conversation: IConversation
  handleDoubleClick: () => void
  isCurrentSlide: (index: number) => boolean
  isActiveActivity: boolean
  containerHeight: number
  isScrollingFast: boolean
  activityId: UUID
  paddingBottom: string
  isLikeActive: boolean
  isTouchStarted: boolean
  isTouchMoved: boolean
  setIsTouchStarted: (isTouchStarted: boolean) => void
  setIsTouchMoved: (isTouchMoved: boolean) => void
  inView: boolean
  commentView: boolean
  isZooming: boolean
  setIsZooming: Dispatch<SetStateAction<boolean>>
  foreignId?: string
  recommendationId?: string
}

export const ConversationActivitySlider: FC<IConversationActivitySlider> = ({
  startedConversationId,
  initialSlide,
  setReplyIndex,
  replyIndex,
  index,
  conversationReplyId,
  conversation,
  handleDoubleClick,
  isCurrentSlide,
  isActiveActivity,
  containerHeight,
  isScrollingFast,
  activityId,
  paddingBottom,
  isLikeActive,
  isTouchStarted,
  isTouchMoved,
  setIsTouchStarted,
  setIsTouchMoved,
  inView,
  commentView,
  isZooming,
  setIsZooming,
  foreignId,
  recommendationId,
}) => {
  const { isPlaybackEnabled } = usePlaybackState()
  const sliderRef = useRef<SwiperRef>(null)
  const [videoLoadedMilliseconds, setVideoLoadedMilliseconds] = useState(0)
  const { state } = useContext(Context)

  const onVideoLoadedMilliseconds = (milliseconds: number) =>
    setVideoLoadedMilliseconds(milliseconds)

  const { onSlideChange } = useTrackViewedActivity({
    inViewport: inView,
    eventName: TrackingEvent.FeedItemViewed,
    activityId,
    itemId: conversation?.id,
    eventProperties: {
      conversationId: !conversationReplyId ? activityId : startedConversationId,
      replyId: conversationReplyId,
      ...(conversation?.mediaType === MediaType.Video
        ? { videoLoadedDuration: videoLoadedMilliseconds }
        : null),
      mediaType:
        conversation?.mediaType === MediaType.Image
          ? MediaType.Image
          : MediaType.Video,
    },
    contentType: conversationReplyId ? 'reply' : 'conversation',
    currentIndex: replyIndex || 0,
    foreignId,
    recommendationId,
  })

  const renderVideo = ({ video, videoPreview, slideIndex, hasReplies }) => (
    <VideoContainer
      video={video}
      hasReplies={hasReplies}
      videoPreview={videoPreview}
      isPlaying={
        isPlaybackEnabled && isCurrentSlide(slideIndex) && isActiveActivity
      }
      isVideoReset
      isProgressBarVisible
      videoContainerHeight={containerHeight}
      videoAllowedToLoad={!isScrollingFast}
      onVideoLoadedMilliseconds={onVideoLoadedMilliseconds}
    />
  )

  const renderImage = (src: string) => {
    return (
      <LazyImg
        isLazy={false}
        src={getSliderImage(src)}
        imageAspectRatio={conversation?.imageAspectRatio}
        setIsZooming={setIsZooming}
      />
    )
  }

  useEffect(() => {
    if (sliderRef.current && !commentView) {
      sliderRef.current.swiper.slideTo(initialSlide || 0, 0)
    }
  }, [initialSlide, commentView])

  return (
    <Swiper
      key={String(state?.isVideoPlaybackAllowed)}
      style={{ overflowY: 'visible' }}
      ref={sliderRef}
      enabled={!isZooming}
      modules={[Virtual]}
      initialSlide={initialSlide}
      direction="horizontal"
      onSlideChange={(e) => {
        if (!isNaN(e.snapIndex)) setReplyIndex(e.snapIndex)
        onSlideChange({
          currentIndex: e.snapIndex,
          prevIndex: replyIndex || index,
          eventProperties: {
            replyId: conversationReplyId,
            ...(conversation?.mediaType === MediaType.Video
              ? { videoLoadedDuration: videoLoadedMilliseconds }
              : null),
            mediaType:
              conversation?.mediaType === MediaType.Video
                ? MediaType.Video
                : MediaType.Image,
          },
          contentType: e.snapIndex > 0 ? 'reply' : 'conversation',
        })
      }}
      slidesPerView={1}
      preventInteractionOnTransition
      virtual
      onTouchStart={() => {
        setIsTouchStarted(true)
      }}
      onTouchEnd={() => {
        setIsTouchStarted(false)
        setIsTouchMoved(false)
      }}
      onSliderMove={() => {
        setIsTouchMoved(true)
      }}
    >
      <SwiperSlide
        style={{
          paddingBottom,
        }}
        key={activityId}
        virtualIndex={0}
        className="bg-cool-gray-950 relative"
      >
        <TapToLikeWrapper
          handleDoubleClick={handleDoubleClick}
          isLikeActive={isLikeActive}
        >
          {conversation?.mediaType === 'video'
            ? renderVideo({
                video: conversation?.videoUrl,
                videoPreview: conversation?.imageUrl,
                slideIndex: 0,
                hasReplies: !!conversation?.replies?.length,
              })
            : renderImage(conversation?.imageUrl)}
        </TapToLikeWrapper>
      </SwiperSlide>
      {conversation?.replies?.map((item: IReply, index: number) => (
        <SwiperSlide
          style={{
            paddingBottom,
          }}
          key={item.id}
          virtualIndex={index + 1}
          className="bg-cool-gray-950 cursor-pointer relative"
        >
          <TapToLikeWrapper
            handleDoubleClick={handleDoubleClick}
            isLikeActive={isLikeActive}
          >
            <header
              className="absolute top-0 inset-x-0 z-10 bg-gradient-to-b from-cool-gray-950/60"
              style={{
                WebkitTransform: 'translate3d(0,0,0)',
              }}
            >
              <div className="pb-3 pr-0 pl-6">
                <div className="flex ml-8 pt-3 relative">
                  <UserRelationIndicator
                    color="white"
                    hasOpacity={true}
                    className="!-mt-6 mb-[0.5rem] absolute"
                    style={{
                      height: isTouchStarted && isTouchMoved ? 0 : '1rem',
                      bottom: 0,
                      transition: isTouchStarted
                        ? 'height 100ms'
                        : 'height 1000ms',
                    }}
                  />
                </div>
                <div className="flex items-center">
                  <div>
                    <UserRelationIndicator
                      className="mb-2"
                      color="white"
                      hasOpacity={true}
                      elementType={UserRelationIndicatorType.curvedRight}
                    />
                  </div>
                  <Link
                    to={`/@${item.username}`}
                    className="flex-none mr-2 self-start"
                  >
                    <Avatar
                      username={item?.username}
                      size="small"
                      userId={item.userId}
                      isModerator={item?.isModerator}
                      isAmbassador={item?.isAmbassador}
                      badge={item?.badge}
                    />
                  </Link>
                  <div className="flex-auto">
                    <span className="flex text-sm whitespace-nowrap">
                      <strong>
                        <Link to={`/@${item.username}`}>
                          <UserName name={item.username} />
                        </Link>
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
            </header>
            {conversation?.mediaType === MediaType.Video
              ? renderVideo({
                  video: item.videoUrl,
                  videoPreview: item.imageUrl,
                  slideIndex: index + 1,
                  hasReplies: true,
                })
              : renderImage(item.imageUrl)}
          </TapToLikeWrapper>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

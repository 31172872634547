import { FC, memo } from 'react'
import { IonIcon, isPlatform } from '@ionic/react'
import { shareOutline, shareSocialOutline } from 'ionicons/icons'

interface ShareProps {
  className?: string
  color?: string
}

const ShareComponent: FC<ShareProps> = ({ className, color = 'plain' }) => {
  return (
    <IonIcon
      color={color}
      icon={isPlatform('ios') ? shareOutline : shareSocialOutline}
      slot="icon-only"
      className={className}
    />
  )
}

export const Share = memo(ShareComponent)

import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'

import { CustomStreamClient } from '@/services/customStreamClient'

export function createDoFeedRequest() {
  return async <T>(
    client: CustomStreamClient,
    feedGroup: string,
    feedId?: string,
    options?: unknown
  ) => {
    try {
      const response = await client
        ?.feed(feedGroup, feedId)
        .get(omitBy(options, isUndefined))
      return {
        ...response,
        results: response?.results as T[],
      }
    } catch (error) {
      console.log(error)
      throw new Error(JSON.stringify(error))
    }
  }
}

import { registerPlugin } from '@capacitor/core'

import type { UgcComposerPlugin } from './definitions'

const UgcComposer = registerPlugin<UgcComposerPlugin>('UgcComposer', {
  web: () => import('./web.js').then((m) => new m.UgcComposerWeb()),
})

export * from './definitions'
export { UgcComposer }
export { ugcComposer } from './ugc-composer'

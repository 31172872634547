import { FC, memo, useEffect, useRef } from 'react'

import { appConfig } from '@/services/config'
import { Routes } from '@/router/routes'

interface CaptchaConfigProps {
  validate?: (args: ICaptchaResult) => void
}

export interface ICaptchaResult {
  captcha_id: string
  gen_time: string
  lot_number: string
  pass_token: string
  captcha_output: string
}

interface ISuccessCaptcha {
  (): void
}

interface ICaptchaObject {
  getValidate: () => ICaptchaResult
  appendTo: (container: HTMLElement) => ICaptchaObject
  onError: (error: unknown) => ICaptchaObject
  onSuccess: (arg: ISuccessCaptcha) => ICaptchaObject
}

const GeeCaptchaComponent: FC<CaptchaConfigProps> = ({ validate }) => {
  const captchaContainer = useRef()
  const config = Object.freeze({
    captchaId: appConfig.geeTestSignUpId,
    language: 'eng',
    product: 'float',
    protocol: 'https://',
  })

  const handler = (captchaObj: ICaptchaObject) => {
    captchaObj
      .appendTo(captchaContainer.current)
      .onError((error: unknown) => {
        console.log(error)
      })
      .onSuccess(() => {
        validate(captchaObj.getValidate())
      })
  }

  useEffect(() => {
    if (
      window.location.pathname !== Routes.AUTH_EMAIL &&
      window.location.pathname !== Routes.AUTH_PHONE &&
      window.location.pathname !== Routes.AUTH_CONFIRM
    )
      return

    const elements = document.querySelectorAll('div.geetest_captcha')
    elements.forEach(function (element) {
      element.parentNode.removeChild(element)
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any)?.initGeetest4(config, handler)
  }, [window.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={captchaContainer}
      className={` ${
        // Use "hideHeight" if the product is "bind"
        config.product === 'float' ? '' : 'hideHeight'
      } grid justify-center`}
    >
      <div className="captcha"></div>
    </div>
  )
}

export const GeeCaptcha = memo(GeeCaptchaComponent)

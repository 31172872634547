import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Popover } from '@/components/molecules/Popover'

import { FollowStatus } from '@enums'

interface IBlockUserAlertProps {
  isOpen: boolean
  onDismiss?: () => void
  onConfirm?: () => void
  status?: FollowStatus
  username?: string
}

export const BlockUserAlert: FC<IBlockUserAlertProps> = ({
  isOpen,
  onDismiss,
  onConfirm,
  status,
  username,
}) => {
  const { t } = useTranslation('common')
  const blockKey = status === FollowStatus.Blocking ? 'unblock' : 'block'
  return (
    <Popover
      hasIcon={false}
      isOpen={isOpen}
      onDismiss={onDismiss}
      onConfirm={onConfirm}
      onConfirmClassName={
        status === FollowStatus.Blocking
          ? 'normal-case text-blue-500 font-bold'
          : 'normal-case text-red-500 font-bold'
      }
      onDismissClassName="normal-case"
      headerMessage={t(`profile.${blockKey}.userTitle`, {
        username,
      })}
      actionText={t('profile.cancel')}
      confirmText={t(`followStatus.${blockKey}`)}
      headerClassName={`font-bold ${
        status === FollowStatus.Blocking ? '' : 'text-red-500'
      }`}
    >
      {t(`profile.${blockKey}.userDescription`)}
    </Popover>
  )
}

import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActionSheetButton,
  IonActionSheet,
  IonButton,
  isPlatform,
} from '@ionic/react'

import { Ellipsis } from '@/components/atoms/assets/Ellipsis'

import { useConversation } from '@/lib/hooks/useConversation'
import { useGetstreamClient } from '@/lib/hooks/useGetstreamClient'
import { usePost } from '@/lib/hooks/usePost'

import { ReportButtonType } from '@/enums'
import { IConversation } from '@/interfaces'

import { Popover } from '../Popover'
interface IUserActivityButton {
  activityId: string
  type: ReportButtonType
  userId: UUID
  conversation?: {
    data: IConversation
  }
  replyId?: UUID
  isDeletePostActive?: boolean
  id?: string
  onSuccess?: () => void
  isOwnReply?: boolean
}

export const UserActivityButton: FC<IUserActivityButton> = ({
  activityId,
  userId,
  type,
  conversation,
  replyId,
  isDeletePostActive = false,
  id,
  onSuccess,
  isOwnReply,
}) => {
  const { t } = useTranslation('common')
  const { client } = useGetstreamClient()
  const { onDeleteReply, onDeleteConversation } = useConversation()
  const { onDeletePost } = usePost()
  const [showReportActionSheet, setShowReportActionSheet] = useState(false)
  const initialPopover = {
    isVisible: false,
    message: null,
    onConfirm: null,
  }

  const [popover, setPopover] = useState(initialPopover)
  const [conversationDeleteReplyPopover, setDeleteConversationReplyPopover] =
    useState(initialPopover)
  const [conversationDeletePopover, setDeleteConversationPopover] =
    useState(initialPopover)

  const onConfirm = async () => {
    setPopover(initialPopover)
    if (isDeletePostActive) {
      await onDeletePost(id)
    } else {
      const feed = client?.feed('user', userId)
      await feed.removeActivity(activityId)
    }
    onSuccess()
  }

  const onConfirmDeleteConversationReply = async () => {
    setDeleteConversationReplyPopover(initialPopover)
    await onDeleteReply({ conversationId: conversation.data.id, replyId })
    onSuccess()
  }

  const onConfirmDeleteConversation = async () => {
    setDeleteConversationPopover(initialPopover)
    await onDeleteConversation(conversation.data.id)
    onSuccess()
  }

  const onDeleteUserActivity = async (message: string) => {
    setPopover({
      isVisible: true,
      message,
      onConfirm,
    })
  }

  const onDeleteConversationReplyActivity = async (message: string) => {
    setDeleteConversationReplyPopover({
      isVisible: true,
      message,
      onConfirm: onConfirmDeleteConversationReply,
    })
  }

  const onDeleteConversationActivity = async (message: string) => {
    setDeleteConversationPopover({
      isVisible: true,
      message,
      onConfirm: onConfirmDeleteConversation,
    })
  }

  const deleteUserActivityItem = () => ({
    text: t('deleteUserActivity.buttonLabel'),
    role: 'destructive',
    data: {
      type: 'delete',
    },
    handler: () =>
      onDeleteUserActivity(t('deleteUserActivity.confirmationMessage')),
  })

  const deleteConversationReplyActivityItem = () => ({
    text: t('deleteUserActivity.buttonLabel'),
    role: 'destructive',
    data: {
      type: 'delete',
    },
    handler: () =>
      onDeleteConversationReplyActivity(
        t('UGC.conversation.deleteReplyConfirmationMessage')
      ),
  })

  const deleteConversationActivityItem = () => ({
    text: t('deleteUserActivity.buttonLabel'),
    role: 'destructive',
    data: {
      type: 'delete',
    },
    handler: () =>
      onDeleteConversationActivity(
        t('UGC.conversation.deleteConfirmationMessage')
      ),
  })

  const actionSheetButtonsHandler = () => {
    switch (type) {
      case ReportButtonType.Post:
        return [deleteUserActivityItem()]
      case ReportButtonType.Conversation:
        return [deleteConversationActivityItem()]
      case ReportButtonType.ConversationReply:
        return [
          ...(conversation?.data?.replies.length && isOwnReply
            ? [deleteConversationReplyActivityItem()]
            : []),
        ]
      default:
        return []
    }
  }

  const actionSheetButtons = (): ActionSheetButton[] => [
    ...actionSheetButtonsHandler(),
    {
      text: t('report.cancel'),
      role: 'cancel',
    },
  ]

  return (
    <>
      <Popover
        type="danger"
        isOpen={popover.isVisible}
        onDismiss={() => {
          setPopover(initialPopover)
        }}
        onConfirm={popover.onConfirm}
        onDismissClassName="normal-case order-2"
        onConfirmClassName="normal-case text-red-500 order-1"
        actionText={t('no')}
        confirmText={t('yes')}
      >
        {popover.message}
      </Popover>
      <Popover
        type="danger"
        isOpen={conversationDeleteReplyPopover.isVisible}
        onDismiss={() => {
          setDeleteConversationReplyPopover(initialPopover)
        }}
        onConfirm={conversationDeleteReplyPopover.onConfirm}
        onDismissClassName="normal-case order-2"
        onConfirmClassName="normal-case text-red-500 order-1"
        actionText={t('no')}
        confirmText={t('yes')}
      >
        {conversationDeleteReplyPopover.message}
      </Popover>
      <Popover
        type="danger"
        isOpen={conversationDeletePopover.isVisible}
        onDismiss={() => {
          setDeleteConversationPopover(initialPopover)
        }}
        onConfirm={conversationDeletePopover.onConfirm}
        onDismissClassName="normal-case order-2"
        onConfirmClassName="normal-case text-red-500 order-1"
        actionText={t('no')}
        confirmText={t('yes')}
      >
        {conversationDeletePopover.message}
      </Popover>
      <IonButton
        mode="md"
        disabled={false}
        color="plain"
        onClick={(e) => {
          e.stopPropagation()
          setShowReportActionSheet(true)
        }}
      >
        <Ellipsis />
      </IonButton>
      <IonActionSheet
        isOpen={showReportActionSheet}
        mode={isPlatform('ios') ? 'ios' : 'md'}
        onDidDismiss={() => setShowReportActionSheet(false)}
        buttons={actionSheetButtons()}
      />
    </>
  )
}

import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonSpinner,
  IonTitle,
} from '@ionic/react'
import { useDebounceValue } from 'usehooks-ts'

import { BackButton } from '@/components/atoms/BackButton'
import { Button } from '@/components/atoms/Button'
import { Title } from '@/components/atoms/Title'
import { Main } from '@/components/templates/main'
import { StakeForm, StakingDetails } from './components'

import { appConfig } from '@/services/config'
import {
  useCreateStake,
  useHandleErrors,
  useStakeRules,
  useStakesEstimate,
} from '@/lib/hooks/stake'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useLocation } from '@/lib/routing'
import { WALLET } from '@/utils/route'

export const Stake = () => {
  const { t } = useTranslation('common')
  const location = useLocation()
  const { search = '' } = location
  const searchParams = new URLSearchParams(search)
  const initialAmount = searchParams.get('amount')

  const methods = useForm({
    defaultValues: { amount: initialAmount || '' },
    mode: 'all',
  })
  const {
    handleSubmit,
    watch,
    setError,
    formState: { isValid },
  } = methods
  const amount = watch('amount')
  const [debouncedAmount] = useDebounceValue(amount, 500)

  const { latestRule } = useStakeRules()
  const { estimate, estimateError, isFetchingEstimate } = useStakesEstimate({
    amount: debouncedAmount,
    ruleId: latestRule?.ruleId,
  })
  const { data: userInfo } = useUserInfo()

  const { createStake, isPending, createStakeError } = useCreateStake()
  useHandleErrors({ estimateError, createStakeError, setError })

  const onSubmit = ({ amount: debouncedAmount }: { amount: string }) => {
    createStake({ amount: debouncedAmount, ruleId: latestRule?.ruleId })
  }

  const header = useMemo(
    () => (
      <>
        <IonButtons slot="start">
          <BackButton defaultHref={WALLET} />
        </IonButtons>
        <IonTitle className="px-2">
          <Title level={2}>
            {t('wallet.stake.pageTitle', { currency: appConfig.currency })}
          </Title>
        </IonTitle>
        <IonButtons slot="end">
          <IonButton>
            <IonIcon slot="icon-only" />
          </IonButton>
        </IonButtons>
      </>
    ),
    [t]
  )

  return (
    <Main
      contentClassName="px-7 py-4"
      headTitle={t('XPWallet.title')}
      header={header}
      isPaddingEnabled={false}
    >
      <FormProvider {...methods}>
        <StakeForm userInfo={userInfo} rule={latestRule} estimate={estimate} />
        <StakingDetails
          rule={latestRule}
          estimate={estimate}
          isFetchingEstimate={isFetchingEstimate}
        />
        <Button
          className="w-[9.25rem] self-center"
          type={isValid ? 'primary' : 'secondary'}
          onClick={handleSubmit(onSubmit)}
          size="small"
          isDisabled={!isValid || isPending || isFetchingEstimate}
        >
          {isPending ? (
            <IonSpinner color="light" className="h-5" />
          ) : (
            t('wallet.stake.stakeCTA', {
              token: appConfig.currency,
            })
          )}
        </Button>
      </FormProvider>
    </Main>
  )
}

import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import { QueryKeys } from '@/enums'

import { useConversation } from '../useConversation'
import { useGetstreamClient } from '../useGetstreamClient'

interface IUseConversationDetails {
  activityId: UUID | 'conversation'
  replyObjectId?: UUID
  createdAt: string
  enabled?: boolean
}

export const useConversationId = ({
  activityId,
  replyObjectId,
  createdAt,
  enabled = true,
}: IUseConversationDetails) => {
  const [conversationId, setConversationId] = useState<UUID | undefined>(
    undefined
  )
  const { client } = useGetstreamClient()
  const { getActivityId } = useConversation()

  const queryKey = useMemo(
    () =>
      [QueryKeys.ConversationId, activityId, replyObjectId, createdAt].filter(
        (item) => !!item
      ),
    [activityId, replyObjectId, createdAt]
  )

  const queryEnabled =
    enabled &&
    !!client &&
    (activityId !== 'conversation' || !!(replyObjectId && createdAt))

  const { data } = useQuery({
    queryKey,
    queryFn: async () => {
      let startedConversationId: UUID = ''
      if (activityId === 'conversation') {
        startedConversationId = await getActivityId(
          client,
          replyObjectId || '',
          createdAt
        )
      }

      return activityId === 'conversation' ? startedConversationId : activityId
    },
    enabled: queryEnabled,
    gcTime: Infinity,
    staleTime: Infinity,
  })

  useEffect(() => {
    if (data) setConversationId(data)
  }, [data])

  return { conversationId }
}

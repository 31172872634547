import { useIonModal } from '@ionic/react'

import { TermsAndConditions } from './components/TermsAndConditions'

import { OverlayEventDetail } from '@/interfaces'

export const useTermsAndConditionsModal = ({ onConfirm }) => {
  const [present, dismiss] = useIonModal(TermsAndConditions, {
    onDismiss: (role: 'cancel' | 'confirm') => {
      if (role === 'confirm') {
        onConfirm()
      }
      dismiss(null, role)
    },
  } as OverlayEventDetail)

  return {
    presentModal: present,
    dismissModal: dismiss,
  }
}

import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IonCheckbox, IonItem, IonLabel, IonSpinner } from '@ionic/react'

export type ConsentFormProps = {
  onChange?: ({ consent, terms }: { consent: boolean; terms: boolean }) => void
  isLoading?: boolean
}

const ConsentForm: FC<ConsentFormProps> = ({ onChange, isLoading }) => {
  const { t } = useTranslation('common')

  const { control, getValues } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      consent: false,
      terms: false,
    },
  })

  return (
    <>
      <IonItem className="custom-item">
        <Controller
          control={control}
          name="consent"
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <>
              <IonLabel className="text-xl !text-black">
                {t('registration.consent.confirm')}
              </IonLabel>
              <IonCheckbox
                color="medium"
                className="verify-age-checkbox"
                checked={field.value}
                onIonChange={(e) => {
                  field.onChange(e.detail.checked)
                  onChange?.(getValues())
                }}
              />
            </>
          )}
        />
      </IonItem>
      <br />
      <IonItem className="custom-item">
        <Controller
          control={control}
          name="terms"
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <>
              <IonLabel className="text-xl !text-black">
                {t('registration.consent.terms')}
              </IonLabel>
              <IonCheckbox
                color="medium"
                className="verify-age-checkbox"
                checked={field.value}
                onIonChange={(e) => {
                  field.onChange(e.detail.checked)
                  onChange?.(getValues())
                }}
              />
            </>
          )}
        />
      </IonItem>
      <div className="mt-4 flex justify-center">
        {isLoading && <IonSpinner color="main" />}
      </div>
    </>
  )
}

export default ConsentForm

import { Trans, useTranslation } from 'react-i18next'

import { InfoIcon } from '@/components/atoms/assets/InfoIcon'

import { useAccountInfo } from '@/services/account'
import { appConfig } from '@/services/config'

import { AirdropInfoModal } from './AirdropInfoModal'

export const NextAirdropSchedule = () => {
  const { t } = useTranslation('common')
  const { data: accountInfo } = useAccountInfo()
  const xpThreshold = null
  const missingXP = Math.max(0, Math.ceil(xpThreshold - accountInfo?.xp))

  return (
    <>
      <div className="relative pb-2">
        <picture>
          <source srcSet="/vault/community-reward-background.png 388w, /vault/community-reward-background@2x.png 776w, /vault/community-reward-background@3x.png 1164w" />
          <img
            src="/vault/community-reward-background.png"
            alt="next-community-reward"
          />
        </picture>
        <div className="absolute top-[36%] left-[56%] -translate-y-1/2 -translate-x-1/2 flex flex-col">
          <span className="text-[3vw] sm:text-xl w-[55vw] sm:w-[22rem]">
            {t('wallet.airdropSchedule.header', {
              currency: appConfig.currency,
            })}
          </span>
          <span className="text-[3vw] sm:text-xl leading-tight text-cool-gray-200">
            {t('wallet.airdropSchedule.condition')}
          </span>
          {!!accountInfo?.xp && !!xpThreshold && (
            <span className="text-[3vw] sm:text-xl">
              <Trans
                i18nKey={'wallet.airdropSchedule.missingXP'}
                components={{ primary: <span className="text-primary" /> }}
                count={missingXP}
              >
                {t('wallet.airdropSchedule.missingXP', { count: missingXP })}
              </Trans>
            </span>
          )}
        </div>
        <div
          id="open-cr-info-modal"
          className="absolute top-[12%] right-[4%] cursor-pointer"
        >
          <InfoIcon className="w-[5vw] sm:w-8" />
        </div>
      </div>
      <AirdropInfoModal />
    </>
  )
}

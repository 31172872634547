import { del, get, post } from '@/services/awsAmplify'

type Provider = 'instagram' | 'tiktok' | 'twitter'

export interface IUserInfoItem {
  provider: Provider
  userId: UUID
  userName: string
  providerUserId: UUID
  providerUserName: string
  followerCount: string
}

export interface IUserInfoItemList {
  items: IUserInfoItem[]
}

export const disconnect = async ({
  userId,
  provider,
}: {
  userId: string
  provider: Provider
}): Promise<string> => {
  return del({
    path: `/socials/${userId}`,
    queryParams: {
      provider,
    },
  }) as unknown as Promise<string>
}

export const connect = ({
  userId,
  code,
  provider,
}: {
  userId: string
  code: string
  provider: Provider
}): Promise<IUserInfoItem> => {
  return post({
    path: `/socials/${userId}`,
    params: {
      code,
      provider,
    },
  }) as unknown as Promise<IUserInfoItem>
}

export const socials = async ({
  userId,
}: {
  userId: string
}): Promise<IUserInfoItemList> => {
  return get({
    path: `/socials/${userId}`,
  }) as unknown as IUserInfoItemList
}

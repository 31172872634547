import { IMention } from '@/interfaces'

export const replaceUsernamesWithMentions = (
  text: string,
  mentions?: IMention[]
) => {
  let replacedText = text

  mentions?.forEach((mention) => {
    const username = mention.username
    const id = mention.id

    const regex = new RegExp(`(?:^|[\\s\\n])@${username}(?=\\b)`, 'ig')

    const mentionLink = `[@${username}](user:${id})`

    replacedText = replacedText.replace(regex, (match) => {
      if (match.startsWith('\n')) return `\n${mentionLink}`
      if (match.startsWith(' ')) return ` ${mentionLink}`

      return mentionLink
    })
  })

  return replacedText
}

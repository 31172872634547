import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonContent, IonTitle } from '@ionic/react'

import { BackButton } from '@components/atoms/BackButton'
import { Title } from '@components/atoms/Title'
import { Loading } from '@/components/atoms/Loading'
import { SubscriptionListItem } from '@/components/organisms/SubscriptionListItem'
import { VirtualList } from '@/components/organisms/VirtualList'
import { Main } from '@/components/templates/main'

import { SubscriptionType } from '@/services/subscription'
import { useSubscriptionsList } from '@/lib/hooks/subscription/useSubscriptionsList'
import { Routes } from '@/router/routes'

import { CancelSubscriptionButton } from './CancelSubscriptionButton'

export const MySubscriptions: FC = () => {
  const { t } = useTranslation('common')
  const {
    subscriptions,
    refetch: refetchSubscriptions,
    isLoading,
  } = useSubscriptionsList()
  const [isRefetchActive, setRefetchActive] = useState(true)

  useEffect(() => {
    refetchSubscriptions()
  }, [refetchSubscriptions])

  const header = useMemo(
    () => (
      <>
        <IonButtons slot="start">
          <BackButton defaultHref={Routes.PROFILE} />
        </IonButtons>
        <IonTitle>
          <Title className="text-center my-0" level={3}>
            {t('profile.mySubscriptions')}
          </Title>
        </IonTitle>
      </>
    ),
    [t]
  )

  return (
    <Main
      isRefetchActive={isRefetchActive}
      refetch={refetchSubscriptions}
      header={header}
      isPaddingEnabled={false}
    >
      <IonContent>
        <SubscriptionsList
          subscriptions={subscriptions}
          isLoading={isLoading}
          setRefetchActive={setRefetchActive}
        />
      </IonContent>
    </Main>
  )
}

const SubscriptionsList = ({
  subscriptions,
  isLoading,
  setRefetchActive,
}: {
  subscriptions: SubscriptionType[]
  isLoading: boolean
  setRefetchActive: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation('common')

  if (isLoading) {
    return <Loading />
  }

  if (subscriptions?.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="text-[5rem]">🦥</div>
        <p>{t('profile.empty')}</p>
      </div>
    )
  }

  return (
    <VirtualList
      atTopStateChange={(isAtTop: boolean) => {
        setRefetchActive(isAtTop)
      }}
      components={{
        Header: () => <></>,
      }}
      className="w-full"
      data={subscriptions}
      itemContent={(index) => {
        return (
          <div className="px-4 w-full flex flex-col">
            <SubscriptionListItem
              key={subscriptions[index]?.id}
              subscription={subscriptions[index]}
              additionalButton={
                <CancelSubscriptionButton
                  userId={subscriptions[index]?.id}
                  username={subscriptions[index]?.username}
                  status={subscriptions[index]?.status}
                />
              }
            />
          </div>
        )
      }}
      endReached={null}
    />
  )
}

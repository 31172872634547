import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ActionButton } from '@/components/atoms/ActionButton'

import { SubscriptionStatus } from '@/enums'

import { CancelSubscriptionModal } from './CancelSubscriptionModal'

type CancelSubscriptionButtonProps = {
  userId: UUID
  username: string
  status: SubscriptionStatus
}

export const CancelSubscriptionButton: FC<CancelSubscriptionButtonProps> = ({
  userId,
  username,
  status,
}) => {
  const { t } = useTranslation('common')
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div className="mt-2">
      <ActionButton
        color="primary"
        fill="outline"
        isPending={false}
        isDisabled={status === SubscriptionStatus.Canceled}
        handleClick={() => setIsModalOpen(true)}
        buttonText={t(
          `subscriptions.item.${
            status === SubscriptionStatus.Active ? 'cancel' : 'canceled'
          }`
        )}
      />
      <CancelSubscriptionModal
        userId={userId}
        username={username}
        isStatusActive={status === SubscriptionStatus.Active}
        isOpen={isModalOpen}
        dismissModal={() => setIsModalOpen(false)}
      />
    </div>
  )
}

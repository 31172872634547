import { FC, useMemo } from 'react'
import cx from 'classnames'

import { generatePlaceholders } from '@/components/pages/UserProfile/utils'

import { GridItem } from '..'

interface GridPlaceholderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activities: any[]
  hasBackground?: boolean
}

export const GridPlaceholder: FC<GridPlaceholderProps> = ({
  activities,
  hasBackground,
}) => {
  const array = Array.from(Array(9))
  const renderItem = useMemo(
    () =>
      generatePlaceholders(array).map((item, index) => {
        if (!activities[index]?.id)
          return (
            <div
              key={index}
              className="w-1/3 h-[44.44vw] max-h-[284px] flex flex-none overflow-hidden border-[1px] box-border border-cool-gray-900 bg-cool-gray-900 z-10"
            />
          )
        return (
          <div
            key={activities[index]?.id}
            className="w-1/3 h-[44.44vw] max-h-[284px] flex flex-none overflow-hidden border-[1px] box-border border-cool-gray-900"
          >
            <GridItem activity={activities[index]} />
          </div>
        )
      }),
    [activities, array]
  )

  return (
    <section
      className={cx('absolute flex flex-wrap w-full top-0 flex-row', {
        'bg-cool-gray-900': hasBackground,
        '-z-[1]': !hasBackground,
      })}
    >
      {renderItem}
    </section>
  )
}

import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import {
  IonButtons,
  IonLabel,
  IonSpinner,
  IonTitle,
  isPlatform,
} from '@ionic/react'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

import { AvatarInput, useAvatarInput } from '@components/atoms/Avatar'
import { BackButton } from '@components/atoms/BackButton'
import { Button } from '@components/atoms/Button'
import { InputField } from '@components/atoms/InputField'
import { Title } from '@components/atoms/Title'
import { Main } from '@components/templates/main'
import { InstagramIcon } from '@/components/atoms/assets/InstagramIcon'
import { TiktokIcon } from '@/components/atoms/assets/TiktokIcon'
import { TwitterIcon } from '@/components/atoms/assets/TwitterIcon'
import { DeleteAccountButton } from '@/components/molecules/DeleteAccountButton'
import { EmailNotVerifiedLabel } from '@/components/molecules/EmailNotVerifiedLabel'
import { Popover } from '@/components/molecules/Popover'

import { emailValidationRetry } from '@services/email-validation'
import { updateProfile } from '@services/updateProfile'
import { errorBoundary } from '@/services/errorBoundary'
import { UserInfo } from '@/services/user'
import { queryClient } from '@lib/queryClient'
import { useGetAvatar } from '@/lib/hooks/useGetAvatar'
import { useShowErrorToast } from '@/lib/hooks/useShowErrorToast'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import setFormErrorMessages from '@utils/setFormErrorMessages'
import { emailPattern } from '@utils/validation'
import { validateNameWithoutBlacklistedWords } from '@/utils/validateNameWithoutBlacklistedWords'

import { IUser } from '@/interfaces'

import { SocialsTemplate } from './SocialsTemplate'

export const EditProfile = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isChangeEmailPopoverOpen, setIsChangeEmailPopoverOpen] =
    useState(false)
  const { showErrorToast } = useShowErrorToast()

  const { data: user } = useUserInfo()

  const getAvatar = useGetAvatar()

  const { setCameraImage, takePicture, uploading, avatarError } =
    useAvatarInput()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      username: `@${user?.username}`,
      displayName: user?.displayName,
      email: user?.email,
      bio: user?.bio,
    },
  })
  const username = watch('username')
  const displayName = watch('displayName')
  const email = watch('email')
  const bio = watch('bio')

  useEffect(() => {
    if (user) {
      setValue('username', user?.username)
      setValue('displayName', user?.displayName || '')
      setValue('email', user?.email || '')
      setValue('bio', user?.bio || '')
    }
  }, [user, setValue])

  useEffect(() => {
    trigger(['displayName'])
  }, [trigger, displayName])

  const { mutate: mutateEmailValidationRetry } = useMutation({
    mutationKey: ['emailValidationRetry'],
    mutationFn: emailValidationRetry,
    onSuccess: async () => setIsPopoverOpen(true),
    onError: (error: AxiosError) => setFormErrorMessages({ error, setError }),
  })

  const { mutate, isPending } = useMutation({
    mutationKey: ['updateProfile'],
    mutationFn: updateProfile,
    onSuccess: async (data: UserInfo) => {
      if (data.pendingEmail && data.pendingEmail !== user.email) {
        setIsChangeEmailPopoverOpen(true)
      }

      const currentUserData = queryClient.getQueryData<IUser>(['user'])
      await queryClient.setQueryData(['user', currentUserData.id], {
        ...currentUserData,
        ...data,
      })

      if (currentUserData.id === data.id) {
        await queryClient.setQueryData(['user'], {
          ...currentUserData,
          ...data,
        })
      }

      if (user?.isEmailValidated) {
        navigate(Routes.PROFILE)
      }
    },
    onError: (error: AxiosError) => {
      errorBoundary(error)
      setFormErrorMessages({
        error,
        setError,
        t,
        unhandledErrorCallback: () =>
          showErrorToast({
            header: t('profile.error.defaultError.header'),
            message: t('profile.error.defaultError.message'),
          }),
      })
    },
  })

  const onSubmit = async (data) => {
    const userData = {
      ...data,
      username: data.username.replace(/^@/, ''),
    }

    mutate({ userData: omitBy(userData, isNil) })
  }

  const validationMessages = {
    required: t('required'),
    minLength: t('minLength', { length: 2 }),
    maxLength: t('maxLength', { length: 24 }),
    pattern: t('emailPattern'),
    validateNameWithoutBlacklistedWords: t('nameWithoutBlacklistedWords'),
  }

  useEffect(() => {
    return () => setCameraImage(undefined)
  }, [setCameraImage])

  const onEmailValidationRetry = async () => {
    if (email !== user?.email) {
      await handleSubmit(onSubmit)()
    }
    if (!errors.email) {
      mutateEmailValidationRetry()
    }
  }

  const header = (
    <div className="flex justify-between items-center">
      <Popover
        hasIcon={false}
        isOpen={isPopoverOpen}
        onDismiss={() => setIsPopoverOpen(false)}
        headerMessage={t('profile.emailVerification.header')}
      >
        {t('profile.emailVerification.message')}
      </Popover>
      <Popover
        hasIcon={false}
        isOpen={isChangeEmailPopoverOpen}
        onDismiss={() => setIsChangeEmailPopoverOpen(false)}
        headerMessage={
          <div className="text-color-danger">
            {t('profile.emailVerification.changeEmailWarningTitle')}
          </div>
        }
      >
        {t('profile.emailVerification.changeEmailWarning')}
      </Popover>
      <IonButtons slot="secondary">
        <BackButton
          className="text-white"
          defaultHref={`/@${user?.username}`}
        />
      </IonButtons>
      <IonTitle>
        <Title level={2}>{t('profile.edit')}</Title>
      </IonTitle>
      <Button
        onClick={handleSubmit(onSubmit)}
        isDisabled={
          (username.replace(/^@/, '') === user?.username || !username) &&
          (user?.displayName
            ? displayName === user?.displayName
            : !displayName) &&
          (user?.email ? email === user?.email : !email) &&
          (user?.bio ? bio === user?.bio : !bio)
        }
      >
        {isPending ? <IonSpinner color="light" /> : t('save')}
      </Button>
    </div>
  )

  const socials = [
    {
      name: t('registration.profile.instagram'),
      icon: <InstagramIcon isActive />,
      route: Routes.SOCIALS_CONNECT_INSTAGRAM,
    },
    {
      name: t('registration.profile.twitter'),
      icon: <TwitterIcon isActive />,
      route: Routes.SOCIALS_CONNECT_TWITTER,
    },
    {
      name: t('registration.profile.tiktok'),
      icon: <TiktokIcon isActive />,
      route: Routes.SOCIALS_CONNECT_TIKTOK,
    },
  ]

  return (
    <Main header={header} isPaddingEnabled={false}>
      <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-10 max-w-md mx-8 sm:mx-auto py-8">
          <AvatarInput
            hasIcon
            url={getAvatar(user.id)}
            onClick={takePicture}
            uploading={uploading}
            isModerator={user?.isModerator}
            isAmbassador={user?.isAmbassador}
            badge={user?.badge}
          />
          {avatarError && (
            <IonLabel color="danger" className="text-center block -mt-6">
              {avatarError}
            </IonLabel>
          )}
          <div>
            <Controller
              control={control}
              name="username"
              rules={{ minLength: 2, maxLength: 30, required: true }}
              render={({ field }) => (
                <InputField
                  isReadOnly
                  label={t('registration.profile.userName')}
                  value={field.value}
                  role="presentation"
                  autocomplete="off"
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="displayName"
              rules={{
                maxLength: 24,
                minLength: 2,
                validate: {
                  validateNameWithoutBlacklistedWords,
                },
              }}
              render={({ field }) => (
                <InputField
                  data-test="display-name"
                  label={t('registration.profile.displayName')}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  role="presentation"
                  autocomplete="off"
                />
              )}
            />
            {errors?.displayName && (
              <IonLabel color="danger" className="text-center block mt-2">
                {validationMessages[errors?.displayName?.type] ??
                  errors?.displayName?.message}
              </IonLabel>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name="bio"
              rules={{ maxLength: 120 }}
              render={({ field }) => (
                <InputField
                  label={t('registration.profile.bio')}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  role="presentation"
                  autocomplete="off"
                  isTextarea
                  rows={isPlatform('desktop') ? 3 : 4}
                />
              )}
            />
            {errors?.bio && (
              <IonLabel color="danger" className="text-center block mt-2">
                {(errors?.bio?.type === 'maxLength' &&
                  t('maxLength', { length: 120 })) ??
                  errors?.bio?.message}
              </IonLabel>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
                pattern: emailPattern,
              }}
              render={({ field }) => (
                <InputField
                  label={t('registration.profile.email')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value)
                  }}
                  role="presentation"
                  autocomplete="off"
                />
              )}
            />
            {!user?.isEmailValidated && (
              <div className="mt-2">
                <EmailNotVerifiedLabel onClick={onEmailValidationRetry} />
              </div>
            )}
            {user.pendingEmail && !errors?.email && (
              <IonLabel color="warning" className="text-center block mt-2">
                <Trans
                  i18nKey="profile.emailVerification.unconfirmedEmailWarning"
                  components={{ strong: <strong /> }}
                  values={{
                    email: user.pendingEmail,
                  }}
                />
              </IonLabel>
            )}
            {errors?.email && (
              <IonLabel color="danger" className="text-center block mt-2">
                {errors?.email?.type === 'server'
                  ? errors?.email.message
                  : errors?.email?.type === 'required'
                  ? validationMessages.required
                  : validationMessages.pattern}
              </IonLabel>
            )}
            {!!socials.length && (
              <>
                <div className="font-semibold text-xs uppercase flex align-left text-cool-gray-300 sc-ion-label-md-h sc-ion-label-md-s md pt-9">
                  {t('registration.profile.socialLinks')}
                </div>
                {socials.map((social) => (
                  <SocialsTemplate
                    key={social.name}
                    name={social.name}
                    route={social.route}
                    Icon={social.icon}
                  />
                ))}
              </>
            )}
            <div className="pt-16">
              <DeleteAccountButton />
            </div>
          </div>
        </div>
      </form>
    </Main>
  )
}

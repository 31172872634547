import { FC } from 'react'
import { IonIcon } from '@ionic/react'
import { alertCircle } from 'ionicons/icons'

interface WarningBannerProps {
  title: string
  message: string
}

export const WarningBanner: FC<WarningBannerProps> = ({ title, message }) => {
  return (
    <div className="bg-white rounded-2xl flex flex-col gap-2 pt-3 pb-3 px-3 text-black text-center ">
      <IonIcon icon={alertCircle} className="w-5 h-5 mx-auto" />
      <span className="font-bold">{title}</span>
      <span>{message}</span>
    </div>
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { get } from '@/services/awsAmplify'

import { QueryKeys } from '@/enums'

type GetChatPaywallData = {
  enabled: boolean
  price?: number
}

const getChatPaywall = async ({ queryKey }): Promise<GetChatPaywallData> => {
  const userId = queryKey[1]

  return get({
    path: `/chat/paywall/${userId}`,
  })
}

export const useChatPaywall = (
  userId: UUID,
  options?: Omit<UseQueryOptions<GetChatPaywallData>, 'queryKey'>
) =>
  useQuery({
    queryKey: [QueryKeys.ChatPaywall, userId],
    queryFn: getChatPaywall,
    enabled: !!userId,
    ...options,
  })

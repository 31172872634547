import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonTitle } from '@ionic/react'

import { BackButton } from '@components/atoms/BackButton'
import { Button } from '@components/atoms/Button'
import { Title } from '@components/atoms/Title'
import { WarningBanner } from '@components/atoms/WarningBanner'
import { Main } from '@/components/templates/main'

import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { WALLET } from '@/utils/route'

export const LivenessInstructions: FC = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const header = (
    <>
      <IonButtons slot="start">
        <BackButton defaultHref={WALLET} />
      </IonButtons>
      <IonTitle>
        <Title className="text-center my-0" level={3}>
          {t('livenessCheck.header')}
        </Title>
      </IonTitle>
    </>
  )

  return (
    <Main header={header} className="px-5" isPaddingEnabled={false}>
      <WarningBanner
        title={t('livenessCheck.instructions.warning.title')}
        message={t('livenessCheck.instructions.warning.message')}
      />
      <Title className="text-center my-3 mt-5 sm:my-5 sm:mt-7" level={1}>
        {t('livenessCheck.instructions.title')}
      </Title>

      <ol className="list-decimal pl-4 flex flex-col gap-2 sm:gap-5 mb-[6.25rem]">
        <li>{t('livenessCheck.instructions.step1')}</li>
        <div className="flex justify-center gap-14">
          <img
            src="/liveness-wrong.png"
            srcSet="/liveness-wrong@2x.png 2x"
            alt="liveness-wrong"
          />
          <img
            src="/liveness-correct.png"
            srcSet="/liveness-correct@2x.png 2x"
            alt="liveness-correct"
          />
        </div>
        <li>{t('livenessCheck.instructions.step2')}</li>
        <li>{t('livenessCheck.instructions.step3')}</li>
        <li>{t('livenessCheck.instructions.step4')}</li>
      </ol>

      <div className="flex items-center bg-cool-gray-900 fixed bottom-0 left-0 right-0">
        <Button
          className="w-32 mt-7 mb-10 mx-auto"
          onClick={() => {
            navigate(Routes.FACE_LIVENESS_CHECK, { replace: true })
          }}
        >
          {t('livenessCheck.instructions.begin')}
        </Button>
      </div>
    </Main>
  )
}

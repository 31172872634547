import { BaseSyntheticEvent, FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IonSpinner } from '@ionic/react'

import { Button } from '@/components/atoms/Button'

type CountdownButtonProps = {
  title: string
  isSummary: boolean
  isExecutable: boolean
  isValid: boolean
  isFetching: boolean
  onClick: (e?: BaseSyntheticEvent<object>) => Promise<void> | void
  countdown: number
}

export const CountdownButton: FC<CountdownButtonProps> = ({
  title,
  isSummary,
  isExecutable,
  isValid,
  isFetching,
  onClick,
  countdown,
}) => {
  const { t } = useTranslation('common')

  const isDisabled = useMemo(() => {
    if (isFetching || !countdown) return true
    if (!isSummary) return !isExecutable

    return !isValid
  }, [countdown, isExecutable, isFetching, isSummary, isValid])

  return (
    <div className="flex flex-col items-center">
      <Button
        type={isValid && isExecutable ? 'primary' : 'secondary'}
        onClick={onClick}
        size="small"
        isDisabled={isDisabled}
      >
        <div className="flex min-w-[9.25rem] justify-center items-center text-sm font-bold">
          <span className="mr-1">{title}</span>
          {!!countdown && <span>({countdown}s)</span>}
          {isFetching && <IonSpinner className="h-5" color="medium" />}
        </div>
      </Button>
      <span className="text-xs text-cool-gray-200 font-medium mt-4">
        {t('withdraw.updatesEverySeconds')}
      </span>
    </div>
  )
}

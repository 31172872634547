import { useQuery } from '@tanstack/react-query'

import { getStakeRules } from '@/services/stake'
import { IStakeRulesItem } from '@/services/stake/getStakeRules'

import { QueryKeys } from '@/enums'

export const useStakeRules = () => {
  const { data } = useQuery({
    queryKey: [QueryKeys.StakeRules],
    queryFn: getStakeRules,
  })

  const latestRule: IStakeRulesItem = data?.items?.[0] // assuming the first element is the most recent

  return { latestRule }
}

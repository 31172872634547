import { memo } from 'react'

export const NotificationsComponent = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.025 13C7.02333 11.8145 7.25623 10.6403 7.71028 9.5452C8.16433 8.45007 8.83056 7.45559 9.67059 6.61904C10.5106 5.78249 11.5079 5.1204 12.6049 4.67091C13.7019 4.22142 14.877 3.99341 16.0625 4C21.0125 4.0375 24.975 8.15 24.975 13.1125V14C24.975 18.475 25.9125 21.075 26.7375 22.5C26.8251 22.6518 26.8713 22.8239 26.8715 22.9991C26.8716 23.1744 26.8257 23.3466 26.7384 23.4985C26.651 23.6504 26.5253 23.7767 26.3738 23.8647C26.2222 23.9527 26.0502 23.9994 25.875 24H6.125C5.94976 23.9994 5.77776 23.9527 5.62623 23.8647C5.4747 23.7767 5.34897 23.6504 5.26161 23.4985C5.17426 23.3466 5.12837 23.1744 5.12852 22.9991C5.12868 22.8239 5.17488 22.6518 5.2625 22.5C6.0875 21.075 7.025 18.475 7.025 14V13Z"
      className="stroke-white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 24V25C12 26.0609 12.4214 27.0783 13.1716 27.8284C13.9217 28.5786 14.9391 29 16 29C17.0609 29 18.0783 28.5786 18.8284 27.8284C19.5786 27.0783 20 26.0609 20 25V24"
      className="stroke-white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const NotificationsOutlineComponent = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.9189 12.7272C6.91721 11.5536 7.15286 10.3912 7.61228 9.30702C8.0717 8.22285 8.7458 7.23832 9.59577 6.41014C10.4457 5.58196 11.4548 4.9265 12.5647 4.4815C13.6747 4.03651 14.8637 3.81078 16.0632 3.81731C21.0718 3.85443 25.0811 7.92578 25.0811 12.8386V13.7172C25.0811 18.1475 26.0297 20.7214 26.8644 22.1322C26.9531 22.2824 26.9998 22.4528 27 22.6263C27.0002 22.7998 26.9537 22.9703 26.8653 23.1207C26.7769 23.2711 26.6497 23.3961 26.4964 23.4832C26.3431 23.5704 26.1691 23.6166 25.9917 23.6172H6.00826C5.83095 23.6166 5.65692 23.5704 5.5036 23.4832C5.35028 23.3961 5.22305 23.2711 5.13467 23.1207C5.04628 22.9703 4.99984 22.7998 5 22.6263C5.00016 22.4528 5.04691 22.2824 5.13556 22.1322C5.97031 20.7214 6.9189 18.1475 6.9189 13.7172V12.7272Z"
      className="fill-white stroke-white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.85 24.9172V24.8548H19.825V24.9172H11.85ZM11.995 26.9172C12.1773 27.5746 12.527 28.1804 13.0179 28.6713C13.7657 29.4191 14.78 29.8392 15.8375 29.8392C16.8951 29.8392 17.9093 29.4191 18.6571 28.6713C19.148 28.1804 19.4977 27.5746 19.68 26.9172H11.995Z"
      className="fill-white stroke-white"
    />
  </svg>
)

export const Notifications = memo(NotificationsComponent)
export const NotificationsOutline = memo(NotificationsOutlineComponent)

import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { appConfig } from '@/services/config'
import { stakes } from '@/services/stake'
import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { toTokenString } from '@/utils/token'

import { QueryKeys } from '@/enums'

export const useCreateStake = () => {
  const { t } = useTranslation('common')

  const navigate = useNavigate()
  const [presentToast] = useIonToast()

  const { mutate, isPending, error } = useMutation({
    mutationKey: [QueryKeys.StakesCreate],
    mutationFn: stakes,
    onSuccess: (data) => {
      presentToast({
        header: t('wallet.stake.toasts.successHeader'),
        message: t('wallet.stake.toasts.successMessage', {
          amount: toTokenString(data?.amount),
          currency: appConfig.currency,
        }),
        position: 'top',
        duration: 3000,
      })
      queryClient.invalidateQueries({ queryKey: ['account'], exact: true })
      navigate(Routes.STAKE_VAULT, { replace: true })
    },
  })

  return {
    createStake: mutate,
    isPending,
    createStakeError: error as AxiosError,
  }
}

import { useMemo } from 'react'
import { useInView } from 'react-intersection-observer'

import { calculateImageSpace } from '@/utils/utils'

interface IUseMediaContainer {
  ratio?: number
  index: number
}

export const useMediaContainer = ({ ratio, index }: IUseMediaContainer) => {
  const paddingBottom = useMemo(() => calculateImageSpace(ratio), [ratio])

  const { ref, inView } = useInView({
    threshold: ratio > 1 ? 0.8 : 0.4,
    initialInView: index === 0,
  })

  return {
    inView,
    paddingBottom,
    mediaContainerRef: ref,
  }
}

import { get } from '@/services/awsAmplify'

import { FollowStatus } from '@enums'

export const getLikes = (input: { activity: string; startAt: string }) => {
  const params = Object.assign(
    { limit: 25 },
    input.startAt ? { startAt: input.startAt } : {}
  )

  return <
    Promise<{
      users: ReadonlyArray<{
        deactivated: boolean
        displayName: string
        avatar: string
        isAmbassador: false
        username: string
        id: string
        followStatus: FollowStatus
      }>
      lastKey?: string
    }>
  >get({
    path: `/profile/likes/${input.activity}`,
    params,
  })
}

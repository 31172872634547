import { useEffect } from 'react'
import { UseFormSetError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { appConfig } from '@/services/config'
import {
  IWithdrawEstimation,
  WithdrawErrorMessage,
  WithdrawNetworkError,
} from '@/services/wallet/withdraw'
import { toTokenString } from '@/utils/token'
import { toTitleCase } from '@/utils/toTitleCase'

type UseHandleErrors = {
  (params: {
    estimation: IWithdrawEstimation
    estimationError: AxiosError<string>
    executeWithdrawError: AxiosError<string>
    selectedNetwork: string
    setError: UseFormSetError<{
      amount: string
      externalWalletId: string
      authenticatorCode: string
    }>
  }): void
}

export const useHandleErrors: UseHandleErrors = ({
  estimation,
  estimationError,
  executeWithdrawError,
  selectedNetwork,
  setError,
}) => {
  const { t } = useTranslation('common')

  useEffect(() => {
    const networkError = estimationError || executeWithdrawError

    if (!(estimation?.errorMessage || networkError)) return

    const translationKey =
      WithdrawErrorMessage[estimation?.errorMessage] ||
      WithdrawNetworkError[networkError?.response?.data]
    const maxDailyAmount = estimation?.maxDailyAmount
      ? toTokenString(estimation?.maxDailyAmount)
      : ''
    const maxDailyCount = estimation?.maxDailyCount || ''
    const maxAmount = estimation?.maxAmount
      ? toTokenString(estimation?.maxAmount)
      : ''
    const minAmount = estimation?.minAmount
      ? toTokenString(estimation?.minAmount)
      : ''
    const network = selectedNetwork ? `${toTitleCase(selectedNetwork)} ` : ''

    if (translationKey) {
      setError('amount', {
        message: t(`withdraw.errors.${translationKey}`, {
          maxDailyAmount,
          maxDailyCount,
          maxAmount,
          minAmount,
          currency: appConfig.currency,
          network,
        }),
      })
      return
    }

    setError('amount', { message: t('withdraw.errors.generalError') })
  }, [
    estimation?.errorMessage,
    estimation?.maxAmount,
    estimation?.maxDailyAmount,
    estimation?.maxDailyCount,
    estimation?.minAmount,
    estimationError,
    executeWithdrawError,
    selectedNetwork,
    setError,
    t,
  ])
}

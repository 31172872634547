import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonIcon } from '@ionic/react'
import { cloudOfflineOutline } from 'ionicons/icons'

import { useNetwork } from '@/lib/hooks/useNetwork'

export const OfflineIndicator: FC = () => {
  const { isOnline } = useNetwork()
  const { t } = useTranslation('common')
  if (isOnline) return null
  return (
    <div className="text-center bg-red-900 p-2 flex justify-center items-center mb-3 text-white">
      {t('offline')} &nbsp;
      <IonIcon slot="icon-only" icon={cloudOfflineOutline} />
    </div>
  )
}

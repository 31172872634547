import { FC } from 'react'
import { IonButton, IonButtons, IonTitle } from '@ionic/react'

import { Share } from '@/components/atoms/assets/Share'
import { Title } from '@/components/atoms/Title'

import { useRedirect } from '../hooks/useRedirect'

interface HeaderProps {
  userId: string
}

export const Header: FC<HeaderProps> = ({ userId }) => {
  const { redirectToLogin } = useRedirect()

  return (
    <>
      <IonButtons className="pr-[0.0625rem]" slot="primary">
        <IonButton color="primary" onClick={redirectToLogin}>
          <Share className="cursor-pointer" color="white" />
        </IonButton>
      </IonButtons>
      <IonTitle>
        <Title level={3} className="text-ellipsis overflow-hidden font-bold">
          {userId}
        </Title>
      </IonTitle>
    </>
  )
}

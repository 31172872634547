import { QueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'

import { newrelicNoticeError } from '@/lib/tracking'

export const customHydrate = (queryClient: QueryClient, dehydratedState) => {
  try {
    dehydratedState.clientState?.queries?.forEach((dehydratedQuery) => {
      if (!isEmpty(dehydratedQuery.queryDefaults)) {
        queryClient.setQueryDefaults(dehydratedQuery.queryKey, {
          ...dehydratedQuery.queryDefaults,
        })
      }

      delete dehydratedQuery.options
    })

    return dehydratedState
  } catch (error) {
    newrelicNoticeError({ error })
  }
}

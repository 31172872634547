import { FC, MutableRefObject, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IonSpinner, isPlatform } from '@ionic/react'
import cx from 'classnames'
import { useDebounceValue } from 'usehooks-ts'

import { Currency } from '@/components/atoms/Currency'
import { CurrencyInput } from '@/components/atoms/CurrencyInput'
import { PaymentFormButton } from '@/components/organisms/PaymentFormButton'

import { appConfig } from '@/services/config'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useTransactionFee, useWallet } from '@/lib/hooks/wallet'
import { toTokenString } from '@/utils/token'

type TippingFormProps = {
  inputRef: MutableRefObject<HTMLInputElement>
  initialAmount?: number
  createTransaction: ({
    value,
    type,
  }: {
    value: string
    type: 'tip'
  }) => Promise<void>
  isLoadingTransactionData: boolean
  onBuyMoreCurrency: () => void
}

export const TippingForm: FC<TippingFormProps> = ({
  inputRef,
  initialAmount,
  createTransaction,
  isLoadingTransactionData,
  onBuyMoreCurrency,
}) => {
  const { data: authUser } = useUserInfo()
  const { t } = useTranslation('common')
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { isValid },
  } = useForm({
    defaultValues: { amount: initialAmount ? String(initialAmount) : '' },
    mode: 'all',
  })

  const amount = watch('amount')
  const [debouncedAmount] = useDebounceValue(amount, 500)

  const { wallet, isFetchingWallet } = useWallet({
    userInfo: authUser,
  })

  const {
    feeData,
    getFee,
    isLoadingFee,
    error: transactionFeeError,
  } = useTransactionFee()

  const fee = feeData?.fee ? <Currency value={feeData.fee} /> : undefined
  const total = feeData?.total ? <Currency value={feeData.total} /> : undefined
  const balance = useMemo(() => {
    if (feeData?.newBalance) {
      return <Currency value={feeData.newBalance} />
    }

    if (wallet?.balance) {
      return <Currency value={wallet.balance} />
    }
  }, [feeData?.newBalance, wallet?.balance])

  const executable = feeData?.executable

  const renderMinMaxAmountError = (key: string, value: string) => {
    return (
      <div
        className="cursor-pointer"
        onClick={() => {
          const newValue = toTokenString(value)
          setValue('amount', newValue)
          newValue && getFee(newValue)
        }}
      >
        {t(`tip.errors.${key}`, {
          amount: value ? toTokenString(value) : 0,
          currency: appConfig.currency,
        })}
      </div>
    )
  }

  const errorMapping = {
    INVALID_TRANSACTION_TYPE: t('transaction.errors.invalidTransactionType'),
    SAME_SOURCE_DESTINATION: t('transaction.errors.sameSourceDestination'),
    NON_POSITIVE_AMOUNT: t('transaction.errors.nonPositiveAmount'),
    SOURCE_USER_NOT_FOUND: t('transaction.errors.sourceUserNotFound'),
    SOURCE_USER_DEACTIVATED: t('transaction.errors.sourceUserDeactivated'),
    DESTINATION_USER_NOT_FOUND: t('transaction.errors.destinationUserNotFound'),
    DESTINATION_USER_DEACTIVATED: t(
      'transaction.errors.destinationUserDeactivated'
    ),
    SOURCE_WALLET_NOT_FOUND: t('transaction.errors.sourceWalletNotFound'),
    SOURCE_WALLET_INSUFFICIENT_FOUNDS: t(
      'transaction.errors.insufficientFunds'
    ),
    INVALID_MIN_AMOUNT: renderMinMaxAmountError(
      'invalidMinAmount',
      feeData?.minAmount
    ),
    INVALID_MAX_AMOUNT: renderMinMaxAmountError(
      'invalidMaxAmount',
      feeData?.maxAmount
    ),
  }

  const parseError = (error, transactionFeeError) => {
    if (error) {
      return t('tip.errors.somethingWentWrong')
    }
    if (transactionFeeError) {
      return errorMapping[transactionFeeError]
    }

    return
  }

  const error = parseError(transactionFeeError, feeData?.errorMessage)

  useEffect(() => {
    if (debouncedAmount) {
      getFee(debouncedAmount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAmount, getFee])

  return (
    <>
      <div className="flex flex-col text-white mb-3">
        <div className="flex flex-row justify-between items-end mt-2">
          <span className="text-lg font-bold">
            {t('tip.labels.tippingAmount')}
          </span>
          <Controller
            control={control}
            name="amount"
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <CurrencyInput
                inputRef={inputRef}
                value={field.value}
                className="focus:outline-none w-1/2 bg-transparent text-right text-[1.75rem] font-bold placeholder-white"
                autoFocus={!isPlatform('ios')}
                onChange={field.onChange}
                onBlur={() => window.scrollTo({ top: 0 })}
              />
            )}
          />
        </div>
        <div className="flex flex-row justify-between mt-5 text-sm">
          <span>{t('tip.labels.fee')}</span>
          {isLoadingFee ? (
            <IonSpinner className="h-5" color="medium" name="dots" />
          ) : fee ? (
            <span>{fee}</span>
          ) : (
            '-'
          )}
        </div>
        <div className="flex flex-row justify-between mt-2 text-primary font-bold">
          <span>{t('tip.labels.total')}</span>
          {isLoadingFee ? (
            <IonSpinner className="h-5" color="main" name="dots" />
          ) : total ? (
            <span>{total}</span>
          ) : (
            '-'
          )}
        </div>
        <div className="flex flex-row justify-between mt-4 text-sm">
          <span>{t('tip.labels.yourWalletBalance')}</span>
          {isFetchingWallet || isLoadingFee ? (
            <IonSpinner className="h-5" color="medium" name="dots" />
          ) : balance ? (
            <span
              className={cx('', {
                'text-color-danger': !!error,
              })}
            >
              {balance}
            </span>
          ) : (
            '-'
          )}
        </div>
      </div>
      {error && (
        <div className="flex flex-col items-center my-3 text-color-danger">
          {error}
        </div>
      )}
      <div className="flex flex-col items-center mt-3">
        <PaymentFormButton
          onClick={handleSubmit(({ amount }) =>
            createTransaction({ value: amount, type: 'tip' })
          )}
          isLoading={isLoadingFee || isLoadingTransactionData}
          isDisabled={!isValid || isLoadingTransactionData || !executable}
          translation={t('tip.actions.send')}
          error={feeData?.errorMessage}
          onBuyMoreCurrency={onBuyMoreCurrency}
        />
        <span className="text-xs my-3 font-medium text-cool-gray-200">
          {t('tip.info')}
        </span>
      </div>
    </>
  )
}

import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'

import { SearchIcon } from '@/components/atoms/assets/SearchIcon'

interface ISearchInput {
  value?: string
  onSubmit: ({ q }) => void
  onChange: (text: string) => void
  reset: () => void
  placeholder?: string
}

export const SearchInput: FC<ISearchInput> = ({
  value,
  onSubmit,
  onChange,
  reset,
  placeholder,
}) => {
  const { t } = useTranslation('common')
  const searchInput = useRef(null)

  return (
    <>
      <input
        ref={searchInput}
        type="search"
        className="block py-2 pl-4 pr-10 w-full text-sm text-zinc-50 bg-cool-gray-800 rounded-full border-cool-gray-800 focus:outline-none"
        placeholder={placeholder || t('common.search')}
        onChange={debounce((e) => onChange(e.target.value), 500)}
      />
      <button
        type="button"
        className="text-white absolute right-0 bottom-0 font-medium rounded-full text-sm px-2 py-2 focus:outline-none flex align-middle"
        onClick={() => {
          if (value) {
            reset()
            onSubmit({ q: '' })
            searchInput.current.value = ''
          }
        }}
      >
        <SearchIcon value={!!value} />
      </button>
    </>
  )
}

import { FC, memo } from 'react'

type TwitterLogoProps = {
  className?: string
}

const TwitterLogoComponent: FC<TwitterLogoProps> = ({ className = '' }) => {
  return (
    <picture>
      <source
        srcSet={`/social_icons/twitter-color@2x.png 56w, /social_icons/twitter-color@3x.png 84w`}
      />
      <img
        className={`w-12 h-12 ${className}`}
        src={`/social_icons/twitter-color@2x.png`}
        alt="Twitter-active"
      />
    </picture>
  )
}

export const TwitterLogo = memo(TwitterLogoComponent)

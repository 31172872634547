import { FC, useEffect, useRef, useState } from 'react'

export type Option = {
  label: JSX.Element
  value: string
  icon?: JSX.Element
}

interface DropdownProps {
  options: Option[]
  onSelect: (option: Option) => void
  defaultLabel?: string
  value?: string
}

export const Dropdown: FC<DropdownProps> = ({
  options,
  onSelect,
  defaultLabel = 'Select an option',
  value,
}) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const dropdownRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !(dropdownRef.current as HTMLElement)?.contains(event.target)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (value) {
      const selected = options.find((option) => option.value === value)
      setSelectedOption(selected)
    }
  }, [value, options])

  const handleSelect = (option) => {
    setSelectedOption(option)
    setIsOpen(false)
    onSelect(option)
  }

  return (
    <div ref={dropdownRef} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex items-center w-full h-[2.5rem] justify-center rounded-full bg-cool-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cool-gray-700"
      >
        {selectedOption?.icon ? (
          <span className="mx-1">{selectedOption.icon}</span>
        ) : null}
        {selectedOption ? (
          <span className="notranslate flex items-center">
            {selectedOption.label}
          </span>
        ) : (
          <span className="notranslate">{defaultLabel}</span>
        )}
        <svg
          className="-mr-1 h-5 w-5 text-cool-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="origin-top-right absolute z-10 right-0 mt-2 rounded-md shadow-lg bg-cool-gray-800 ring-1 ring-cool-gray-700 ring-opacity-4 overflow-y-auto max-h-60">
          <div
            className="py-1 w-full"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map((option) => (
              <button
                key={option.value}
                onClick={() => handleSelect(option)}
                className="flex items-center w-full whitespace-nowrap text-left px-4 py-2 text-sm text-white hover:bg-cool-gray-700"
                role="menuitem"
              >
                {option.icon && (
                  <span className="block mr-2">{option.icon}</span>
                )}
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Dropdown

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

import { appConfig } from '@/services/config'

interface IParams<T> {
  event: string
  value: T
}

export const useGtm = () => {
  const sendDataToGTM = useGTMDispatch()

  const trackEvent = <T>(params: IParams<T>) => {
    return appConfig.gtmId ? sendDataToGTM(params) : false
  }

  return {
    trackEvent,
  }
}

import StreamAnalytics from 'stream-analytics'

import { appConfig } from '@/services/config'

import { ITrackingDriver } from '../driver'
import { TrackingEvent } from '../enums'
import { EventName, EventPropertyMap, IEngagement } from '../interface'

let client: StreamAnalytics | null = null

if (appConfig.getstreamApiKey && appConfig.getstreamAnalyticsToken) {
  client = new StreamAnalytics({
    apiKey: appConfig.getstreamApiKey,
    token: appConfig.getstreamAnalyticsToken,
  })
}

export const getStreamSetUserInfo = (id: string, alias: string) => {
  if (id && alias && client) {
    client.setUser({ id, alias })
  }
}

export const removeGetStreamUserInfo = () => {
  if (client) {
    client.setUser({ id: '', alias: '' })
  }
}

export class GetStreamDriver implements ITrackingDriver {
  events: TrackingEvent[]

  constructor(events: TrackingEvent[]) {
    this.events = events
  }

  public triggerEvent<Event extends EventName>(
    eventName: Event,
    data: EventPropertyMap[Event]
  ) {
    const engagement = {
      label: eventName,
      content: {
        foreign_id: data && 'foreignId' in data ? data.foreignId : '',
      },
      position: data && 'index' in data ? Number(data.index) : 0,
      feed_id: `timeline:${
        data && 'activityId' in data ? data.activityId : ''
      }`,
      location: window.location.pathname || '',
      user_data: {
        id: data && 'userId' in data ? data.userId : '',
      },
    }

    this.trackEngagement(engagement)
  }

  private async trackEngagement(eventData: IEngagement) {
    try {
      await client.trackEngagement(eventData)
    } catch (error) {
      console.error(`ERROR: GetStream track engagement: ${error}`)
    }
  }

  public isTrackable<Event extends EventName>(eventName: Event): boolean {
    return this.events.includes(eventName as TrackingEvent)
  }
}

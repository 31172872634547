import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'
import { useMutation } from '@tanstack/react-query'

import { Socials } from '@/components/pages/Socials/enums'

import { post } from '@/services/awsAmplify/api'
import { disconnect, IUserInfoItem } from '@/services/socials'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

import { useSocials } from '../../hooks/useSocials'

export const useTiktok = ({ code, state }: { code: string; state: string }) => {
  const { data: userInfo } = useUserInfo()

  const {
    userId,
    socialsList,
    refetchSocialsList,
    socialsListError,
    isSocialsListLoading,
    presetSocialProvider,
  } = useSocials({ userId: userInfo?.id })
  const navigate = useNavigate()
  const [presentToast] = useIonToast()
  const { t } = useTranslation('common')

  const {
    mutate: mutateConnect,
    isPending: isConnectPending,
    error: connectError,
  } = useMutation({
    mutationFn: () =>
      post({
        path: '/socials/tiktok/callback',
        params: { code, state },
      }) as unknown as Promise<IUserInfoItem>,
    onSuccess: (data) => {
      refetchSocialsList()
      presetSocialProvider(data)
      presentToast({
        message: t('registration.profile.tiktokConnectSuccessMessage'),
        position: 'top',
        duration: 3000,
      })
      navigate(Routes.PROFILE)
    },
    onError: () => {
      navigate({ search: '' }, { replace: true })
    },
  })

  const { mutate: mutateDisconnect, isPending: isDisconnectPending } =
    useMutation({
      mutationFn: () => disconnect({ userId, provider: Socials.Tiktok }),
      onSuccess: () => {
        refetchSocialsList()
        presentToast({
          message: t('registration.profile.tiktokDisconnectSuccessMessage'),
          position: 'top',
          duration: 3000,
        })
      },
      onError: () => {
        presentToast({
          message: t('registration.profile.tiktokDisconnectFailedMessage'),
          position: 'top',
          duration: 3000,
        })
      },
    })

  useEffect(() => {
    if (code) {
      mutateConnect()
    }
  }, [mutateConnect, code, state])

  useEffect(() => {
    if (socialsListError) {
      presentToast({
        message: t('registration.profile.tiktokConnectFailedMessage'),
        position: 'top',
        duration: 3000,
      })
    }
  }, [socialsListError, presentToast, t])

  return {
    connectionInfo: socialsList?.items?.filter(
      (item: IUserInfoItem) => item.provider === Socials.Tiktok
    )[0],
    mutateConnect,
    isConnectPending,
    connectError,
    mutateDisconnect,
    isDisconnectPending,
    refetchSocialsList,
    socialsListError,
    isSocialsListLoading,
  }
}

import { FC, memo } from 'react'

import { AnimateCounter } from '@/components/atoms/AnimateCounter'

export interface BronzeBadgeLargeComponentProps {
  className?: string
  xp: number
  label?: string
}

const BronzeBadgeLargeComponent: FC<BronzeBadgeLargeComponentProps> = ({
  className = '',
  xp = 0,
  label = 'Bronze',
}) => {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 169 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M159.174 147.763L92.2416 186.749C87.0741 189.766 80.6619 189.766 75.4944 186.749L8.58106 147.763C3.47015 144.785 0.320618 139.317 0.320618 133.398V55.1803C0.320618 49.2607 3.47015 43.7936 8.58106 40.8149L74.1365 2.63931C80.1527 -0.867195 87.6022 -0.867195 93.6372 2.63931L159.193 40.8149C164.304 43.7936 167.453 49.2607 167.453 55.1803V133.398C167.453 139.317 164.304 144.785 159.193 147.763H159.174Z"
        fill="#FEEBFF"
      />
      <path
        d="M156.665 141.787L88.3 180.811C85.5465 182.376 82.1518 182.376 79.3983 180.811L11.0328 141.787C8.27932 140.222 6.58197 137.3 6.58197 134.152V56.104C6.58197 52.9557 8.27932 50.0525 11.0328 48.4689L79.3983 9.4638C82.1518 7.89907 85.5465 7.89907 88.3 9.4638L156.665 48.4878C159.419 50.0525 161.116 52.9746 161.116 56.1229V134.19C161.116 137.338 159.419 140.241 156.665 141.825V141.787Z"
        fill="#FFFDF3"
      />
      <path
        d="M84 189.483C81.1711 189.483 78.3422 188.748 75.815 187.296L8.16614 148.216C3.13067 145.312 0 139.883 0 134.077V55.8966C0 50.0902 3.13067 44.6608 8.16614 41.7575L75.8339 2.67701C80.8693 -0.245078 87.1495 -0.245078 92.185 2.67701L159.853 41.7575C164.888 44.6608 168.019 50.0902 168.019 55.8966V134.077C168.019 139.883 164.888 145.312 159.853 148.216L92.185 187.296C89.6578 188.748 86.8289 189.483 84 189.483ZM84 10.1236C82.8496 10.1236 81.6803 10.4252 80.643 11.0097L12.9942 50.0902C10.9385 51.2779 9.65604 53.5024 9.65604 55.8778V134.058C9.65604 136.433 10.9385 138.658 12.9942 139.845L80.6619 178.926C82.7364 180.113 85.3013 180.113 87.357 178.926L155.025 139.845C157.08 138.658 158.363 136.433 158.363 134.058V55.8966C158.363 53.5213 157.08 51.2967 155.025 50.109L87.357 11.0285C86.3197 10.4252 85.1693 10.1425 84 10.1425V10.1236Z"
        fill="url(#paint0_linear_1149_5935)"
      />
      <path
        d="M159.411 143.135L89.0037 183.34C86.1706 184.956 82.6592 184.956 79.8262 183.34L9.4186 143.135C6.58553 141.519 4.82983 138.528 4.82983 135.277V54.8849C4.82983 51.654 6.58553 48.6426 9.4186 47.0272L79.8262 6.82124C82.6592 5.20583 86.1706 5.20583 89.0037 6.82124L159.411 47.0272C162.244 48.6426 164 51.6341 164 54.8849V135.277C164 138.508 162.244 141.519 159.411 143.135Z"
        fill="#FFCFB5"
      />
      <path
        d="M150.673 143.972L92.535 177.836C87.5672 180.728 81.4222 180.728 76.4544 177.836L18.3168 143.972C13.4088 141.12 10.3962 135.855 10.3962 130.171V62.1842C10.3962 56.5003 13.4088 51.2552 18.3168 48.3834L76.4544 14.5194C81.4222 11.6276 87.5672 11.6276 92.535 14.5194L150.673 48.3834C155.581 51.2353 158.593 56.5003 158.593 62.1842V130.171C158.593 135.855 155.581 141.1 150.673 143.972Z"
        fill="url(#paint1_linear_1149_5935)"
      />
      <g opacity="0.39">
        <path
          d="M84.5346 178.574C83.4972 178.574 82.4597 178.295 81.542 177.756L15.5436 138.946C13.748 137.889 12.6307 135.935 12.6307 133.841V56.1812C12.6307 54.0872 13.748 52.1327 15.5436 51.0757L81.522 12.2658C83.3775 11.1689 85.6719 11.1689 87.5273 12.2658L153.526 51.0757C155.321 52.1327 156.439 54.0872 156.439 56.1812V133.861C156.439 135.955 155.321 137.889 153.526 138.966L87.5273 177.776C86.6096 178.315 85.5721 178.594 84.5346 178.594V178.574ZM20.0526 132.983L84.5346 170.896L149.017 132.983V57.0388L84.5346 19.1064L20.0526 57.0388V133.003V132.983Z"
          fill="url(#paint2_linear_1149_5935)"
        />
      </g>
      <path
        d="M84.1755 184.218C83.1979 184.218 82.2003 183.959 81.3225 183.46L8.66044 141.778C6.88479 140.761 5.78748 138.867 5.78748 136.812V53.4889C5.78748 51.4546 6.88479 49.5401 8.66044 48.523L81.3225 6.8412C83.0782 5.82409 85.2529 5.82409 87.0285 6.8412L159.691 48.523C161.466 49.5401 162.564 51.4347 162.564 53.4889V136.832C162.564 138.867 161.466 140.781 159.691 141.798L87.0285 183.48C86.1507 183.979 85.1731 184.238 84.1755 184.238V184.218ZM12.2716 136.374L84.1755 177.617L156.079 136.374V53.9276L84.1755 12.6846L12.2716 53.9276V136.374Z"
        fill="url(#paint3_linear_1149_5935)"
      />
      <mask
        id="mask0_1149_5935"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="11"
        y="4"
        width="146"
        height="167"
      >
        <path
          d="M84.5 170.5C84.5 170.5 78.2279 167.253 75.6515 165.758L19.5 132.5C14.4506 129.573 11 124.673 11 118.879V53.1182C11 47.3238 14.1346 41.9029 19.184 38.9764L75.6515 6.23885C80.8044 3.25372 87.203 3.25372 92.3522 6.23885L148.816 38.9764C153.865 41.9029 157 47.3238 157 53.1182V118.879C157 124.677 153.862 130.094 148.816 133.021L92.5 165.758C89.9236 167.253 84.5 170.5 84.5 170.5Z"
          fill="#844B07"
        />
      </mask>
      <g mask="url(#mask0_1149_5935)">
        <g filter="url(#filter0_i_1149_5935)">
          <rect
            x="-15"
            y="139"
            width="200"
            height="143"
            rx="71.5"
            fill="#844B07"
            fillOpacity="0.3"
          />
        </g>
      </g>
      <text
        x="84"
        y="155"
        fontSize="13"
        fontWeight="bold"
        textAnchor="middle"
        fill="white"
      >
        {label}
      </text>
      <path
        d="M70 35V54.321L91.1485 54.2585C92.7479 54.2585 93.9674 54.6436 94.9263 55.9865C94.961 56.0352 95.011 56.072 95.0485 56.1179C96.4104 57.8046 98.1339 58.506 100.192 58.3653C100.645 58.3341 100.804 58.1521 100.797 57.687L100.8 54.6399C100.815 54.0572 100.628 53.8209 100.066 53.832L95.6794 53.8669C95.6794 53.8669 93.1374 53.5957 91.9371 52.2161L91.4457 51.6921C90.2675 50.3943 88.8325 49.7637 87.1504 49.7674C85.3605 49.7711 83.5707 49.7683 81.7808 49.7692C79.0667 49.7692 76.0188 49.7582 73.3047 49.7803V46.7911C77.2306 46.8049 81.4163 46.8012 85.3432 46.8003C85.9914 46.8003 86.5435 46.9933 86.9975 47.4465C87.4216 47.8693 87.8708 48.2691 88.3026 48.6865C89.4202 49.7656 90.7763 50.3658 92.3661 50.386C94.9638 50.42 97.5626 50.3906 100.16 50.4035C100.643 50.4053 100.807 50.2058 100.798 49.7738C100.782 48.9162 100.793 48.0577 100.791 47.1992C100.789 46.4363 100.779 46.4253 99.992 46.4253C96.3161 46.4253 92.6402 46.4235 88.9633 46.4253C86.3011 46.4271 86.6147 46.5972 84.7469 44.7699C83.3677 43.4205 81.7481 42.7459 79.7476 42.8019C77.7096 42.8589 75.3446 42.8084 73.3037 42.8231V39.5545H78.7223C79.3667 39.5444 79.8842 39.7907 80.3285 40.2558C80.8527 40.8046 81.3538 41.379 81.8875 41.9168C82.8907 42.9278 84.0689 43.5749 85.4577 43.5823C90.3656 43.608 95.2735 43.5924 100.181 43.6053C100.631 43.6062 100.806 43.4214 100.798 42.9453C100.783 41.971 100.792 40.9967 100.791 40.0223C100.791 39.0977 100.77 39.0738 99.9237 39.0738C94.1309 39.0738 88.3372 39.0866 82.5444 39.0692C79.3638 39.06 80.1006 39.4304 77.8442 36.9827C76.6103 35.6425 75.0455 35.0018 73.3066 35.0018L70 35Z"
        fill="#BA5E2B"
      />
      <text
        x="84"
        y="105"
        fontSize={xp > 1e5 ? 32 : 36}
        fontWeight="bold"
        textAnchor="middle"
        fill="white"
      >
        <AnimateCounter start={0} end={xp} duration={1500} />
      </text>
      <path
        d="M72.1445 129.351H75.0098L77.7783 124.904H77.8662L80.6523 129.351H83.6758L79.5713 123.014V122.979L83.7285 116.669H80.7666L78.0859 121.239H77.9893L75.291 116.669H72.1709L76.1787 122.935V122.962L72.1445 129.351ZM85.1611 129.351H87.8154V125.352H90.2588C92.9307 125.352 94.7148 123.63 94.7148 121.019V121.002C94.7148 118.391 92.9307 116.669 90.2588 116.669H85.1611V129.351ZM89.6084 118.769C91.1289 118.769 92.0254 119.569 92.0254 121.01V121.028C92.0254 122.469 91.1289 123.278 89.6084 123.278H87.8154V118.769H89.6084Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_i_1149_5935"
          x="-15"
          y="139"
          width="210"
          height="145"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="15" dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1149_5935"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1149_5935"
          x1="41.9623"
          y1="22.2078"
          x2="125.989"
          y2="167.775"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8B097" />
          <stop offset="0.31" stopColor="#E99C79" />
          <stop offset="0.64" stopColor="#D47B56" />
          <stop offset="1" stopColor="#B85C29" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1149_5935"
          x1="78.4296"
          y1="46.8078"
          x2="96.3121"
          y2="192.496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3B785" />
          <stop offset="0.27" stopColor="#ECA973" />
          <stop offset="0.62" stopColor="#D58049" />
          <stop offset="1" stopColor="#D07741" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1149_5935"
          x1="84.5346"
          y1="11.4481"
          x2="84.5346"
          y2="178.574"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E89E67" />
          <stop offset="0.73" stopColor="#B95A2E" />
          <stop offset="1" stopColor="#B86434" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1149_5935"
          x1="45.0912"
          y1="27.4826"
          x2="123.214"
          y2="162.845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CF7B55" />
          <stop offset="0.23" stopColor="#E4895C" />
          <stop offset="0.45" stopColor="#EB9565" />
          <stop offset="0.8" stopColor="#F2A475" />
          <stop offset="1" stopColor="#FFC8A9" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const BronzeBadgeLarge = memo(BronzeBadgeLargeComponent)

import { memo } from 'react'

export const SliderIconComponent = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 0H0.666667C0.298477 0 0 0.255837 0 0.571429V11.4286C0 11.7442 0.298477 12 0.666667 12H6.25V0ZM7.75 12H13.3333C13.7015 12 14 11.7442 14 11.4286V0.571429C14 0.255837 13.7015 0 13.3333 0H7.75V12Z"
        className="fill-cool-gray-400"
      />
    </svg>
  )
}

export const SliderIcon = memo(SliderIconComponent)

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonIcon, IonModal, IonSpinner } from '@ionic/react'
import { alertCircleOutline } from 'ionicons/icons'

import { useSubscriptionsList } from '@/lib/hooks/subscription/useSubscriptionsList'

type CancelSubscriptionModalProps = {
  isOpen: boolean
  userId: UUID
  username: string
  isStatusActive: boolean
  dismissModal: () => void
}

export const CancelSubscriptionModal: FC<CancelSubscriptionModalProps> = ({
  isOpen,
  userId,
  username,
  isStatusActive,
  dismissModal,
}) => {
  const { t } = useTranslation('common')

  const { mutateDeleteSubscription, isDeleteLoading } = useSubscriptionsList()

  const ModalBody = () => {
    if (isDeleteLoading) return <IonSpinner color="dark" className="h-28" />

    return isStatusActive ? (
      <>
        <IonIcon
          icon={alertCircleOutline}
          color="danger"
          className="text-5xl mb-3"
        />
        <span>
          {t('subscriptions.cancellationModal.areYouSure', { username })}
        </span>
      </>
    ) : (
      <span>{t('subscriptions.cancellationModal.success', { username })}</span>
    )
  }

  const ModalFooter = () => {
    if (isDeleteLoading) return null

    return (
      <div className="flex w-full border-t">
        {isStatusActive ? (
          <>
            <button
              className={'w-full p-3 text-red-500'}
              onClick={() => mutateDeleteSubscription({ userId })}
              disabled={isDeleteLoading}
            >
              {t('subscriptions.cancellationModal.yes')}
            </button>
            <button
              className={'w-full p-3'}
              onClick={dismissModal}
              disabled={isDeleteLoading}
            >
              {t('subscriptions.cancellationModal.no')}
            </button>
          </>
        ) : (
          <button className={'w-full p-3'} onClick={dismissModal}>
            {t('subscriptions.cancellationModal.okay')}
          </button>
        )}
      </div>
    )
  }

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={dismissModal}
      className="cancel-subscription-modal"
    >
      <div className="flex flex-col items-center gap-2 px-2 py-4 text-center">
        <ModalBody />
      </div>

      <ModalFooter />
    </IonModal>
  )
}

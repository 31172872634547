import { FC, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isPlatform } from '@ionic/react'
import cx from 'classnames'

import { Currency } from '@/components/atoms/Currency'
import { CurrencyInput } from '@/components/atoms/CurrencyInput'

import { appConfig } from '@/services/config'
import { IStakeRulesItem } from '@/services/stake/getStakeRules'
import { IStakesEstimate } from '@/services/stake/stakesEstimate'
import { UserInfo } from '@/services/user'
import { useWallet } from '@/lib/hooks/wallet'
import { tokenToString } from '@/utils/token'

type StakeFormType = {
  userInfo: UserInfo
  rule: IStakeRulesItem
  estimate: IStakesEstimate
}

export const StakeForm: FC<StakeFormType> = ({ userInfo, rule, estimate }) => {
  const { t } = useTranslation('common')
  const [lastMaxUsed, setLastMaxUsed] = useState(Date.now())
  const {
    control,
    formState: { errors },
  } = useFormContext<{ amount: string }>()
  const hasAmountError = !!errors.amount

  const { wallet } = useWallet({
    userInfo,
  })
  const walletBalance = tokenToString(wallet?.balance ?? '0')

  const validationMessages = useMemo(() => {
    return {
      required: t('required'),
      min: t('wallet.stake.errors.insufficientStakeAmount', {
        amount: rule?.minStakeAmountInRpk,
        currency: appConfig.currency,
      }),
      max: t('wallet.stake.errors.insufficientFunds'),
      custom: t(errors.amount?.message, {
        amount: Number(rule?.minStakeAmountInRpk),
      }),
    }
  }, [errors.amount, rule?.minStakeAmountInRpk, t])

  return (
    <>
      <div className="flex justify-between items-center font-bold">
        <span className="text-white text-[4.5vw] sm:text-2xl mt-1">
          {t('wallet.stake.amount')}
        </span>
        <div className="flex justify-end items-center">
          <Controller
            key={lastMaxUsed}
            control={control}
            name="amount"
            rules={{
              required: true,
              min: rule?.minStakeAmountInRpk,
              max: Number(walletBalance),
            }}
            render={({ field }) => (
              <>
                <CurrencyInput
                  value={field.value}
                  className={cx(
                    'focus:outline-none w-full pl-2 bg-transparent text-right text-[7.25vw] sm:text-4xl',
                    {
                      'text-primary placeholder-primary': !hasAmountError,
                      'text-danger placeholder-danger': hasAmountError,
                    }
                  )}
                  autoFocus={!isPlatform('ios')}
                  onChange={(e) => {
                    field.onChange(e.target.value)
                  }}
                />
                <span
                  className="cursor-pointer uppercase ml-2 mt-1"
                  onClick={() => {
                    field.onChange(walletBalance)
                    setLastMaxUsed(Date.now())
                  }}
                >
                  {t('wallet.stake.max')}
                </span>
              </>
            )}
          />
        </div>
      </div>
      <span className="h-6 text-right text-danger">
        {hasAmountError && validationMessages[errors.amount?.type]}
      </span>
      <div className="flex justify-between text-[3vw] sm:text-base text-cool-gray-200 mb-6">
        <span>{t('wallet.stake.minimumStakingAmount')}</span>
        <span>
          <Currency value={rule?.minStakeAmountInRpk} />
        </span>
      </div>
      <div className="flex justify-between text-[3.5vw] sm:text-lg text-cool-gray-200 mb-7">
        <span>{t('wallet.stake.vaultBalanceAfterStaking')}</span>
        <span>
          <Currency
            value={estimate?.mainWalletBalance || wallet?.balance || '0'}
          />
        </span>
      </div>
    </>
  )
}

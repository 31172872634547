import { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { ChevronRight } from '@/components/atoms/assets/ChevronRight'
import { Currency } from '@/components/atoms/Currency'

import { appConfig } from '@/services/config'
import { useStakeRules } from '@/lib/hooks/stake'
import { Routes } from '@/router/routes'
import { toTokenCentsString } from '@/utils/token'

type StakingVaultButtonProps = {
  stakedAmount: string
}

export const StakingVaultButton: FC<StakingVaultButtonProps> = ({
  stakedAmount = '0',
}) => {
  const { latestRule } = useStakeRules()
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const isStaking = useMemo(() => stakedAmount !== '0', [stakedAmount])

  return (
    <div
      className="w-full flex justify-between items-center text-[3.75vw] sm:text-[1.5rem] font-normal cursor-pointer"
      onClick={() => navigate(Routes.STAKE_VAULT)}
    >
      <div className="flex items-center">
        <span>
          <Trans
            i18nKey={
              isStaking
                ? 'wallet.stake.yourStakingVault'
                : 'wallet.stake.gainAPYByStaking'
            }
            values={{
              apy: latestRule?.apyInPercentage,
              currency: appConfig.currency,
            }}
          >
            {isStaking
              ? t('wallet.stake.yourStakingVault')
              : t('wallet.stake.gainAPYByStaking', {
                  apy: latestRule?.apyInPercentage,
                  currency: appConfig.currency,
                })}
          </Trans>
        </span>
        <ChevronRight className="w-[3vw] sm:w-[1.25rem] h-[3vw] sm:h-[1.25rem] ml-1" />
      </div>
      {isStaking && (
        <span title={toTokenCentsString(stakedAmount)}>
          <Currency value={stakedAmount} isHighlighted />
        </span>
      )}
    </div>
  )
}

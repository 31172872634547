import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/Button'
import { Currency } from '@/components/atoms/Currency'
import {
  DepositCurrency,
  DepositCurrencyRef,
} from '@/components/organisms/DepositCurrency'

import { appConfig } from '@/services/config'
import { toTokenCentsString } from '@/utils/token'

type BalanceComponentType = {
  balance: string
}

export const BalanceComponent: FC<BalanceComponentType> = ({ balance }) => {
  const { t } = useTranslation()

  const depositCurrencyRef = useRef<DepositCurrencyRef>()
  const openDepositFlow = () => depositCurrencyRef.current.openModal()

  return (
    <div className="px-4 pt-14 pb-10">
      <span className="pt-2">{t('tokenLaunch.yourWalletBalance')}</span>

      <div className="flex flex-wrap items-center justify-between">
        <h4
          className="py-2 mr-5 text-3xl font-bold"
          title={toTokenCentsString(balance)}
        >
          <Currency value={balance} />
        </h4>
        <Button
          className="min-w-[5.5rem]"
          fill="outline"
          onClick={openDepositFlow}
        >
          {t('tokenLaunch.getCurrency', { currency: appConfig.currency })}
        </Button>
        <DepositCurrency
          title={t('tokenLaunch.getCurrency', { currency: appConfig.currency })}
          ref={depositCurrencyRef}
        />
      </div>
    </div>
  )
}

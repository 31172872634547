import { post } from '@/services/awsAmplify'

import { TransactionsType } from '@/enums'

export interface IStakes {
  transactionId: string
  walletId: UUID
  source: {
    id: UUID
    username: string
  }
  sourceWalletId: string
  type: TransactionsType.Stake
  amount: string
  createdAt: string
  stakeRuleId: string
  stakeApyInPercentage: number
  stakeDate: string
  stakeReleaseDate: string
}
export const stakes = ({
  amount,
  ruleId,
}: {
  amount: string
  ruleId: string
}): Promise<IStakes> =>
  post({
    path: '/stakes',
    params: {
      amount,
      ruleId,
    },
  }) as unknown as Promise<IStakes>

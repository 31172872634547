class Clipboard {
  static async write(value: string) {
    try {
      if (navigator.clipboard && navigator?.clipboard?.writeText) {
        await navigator.clipboard.writeText(value)
      }
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }

  static async read() {
    try {
      if (navigator.clipboard && navigator.clipboard.readText) {
        return navigator.clipboard.readText()
      }
    } catch (err) {
      console.error('Failed to paste from clipboard: ', err)
    }
  }
}

export default Clipboard

import { FC } from 'react'
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'

interface IInfiniteScrollProps {
  disabled?: boolean
  onRequest: () => void
  className?: string
  threshold?: string
}

export const InfiniteScroll: FC<IInfiniteScrollProps> = ({
  disabled = false,
  onRequest,
  className,
  threshold = '15%',
}) => {
  return (
    <IonInfiniteScroll
      className={className}
      onIonInfinite={async (e) => {
        await onRequest()
        e.target.complete()
      }}
      threshold={threshold}
      disabled={disabled}
    >
      <IonInfiniteScrollContent loadingSpinner="circular"></IonInfiniteScrollContent>
    </IonInfiniteScroll>
  )
}

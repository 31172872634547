import { memo } from 'react'

const MultipleVideosComponent = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.81649 4.91919H18.1836C19.0966 4.91919 19.8367 5.66977 19.8367 6.59566V16.6545C19.8367 17.5804 19.0966 18.331 18.1836 18.331H5.81649C4.90348 18.331 4.16333 17.5804 4.16333 16.6545V6.59566C4.16333 5.66977 4.90348 4.91919 5.81649 4.91919ZM10.2083 15.38C10.022 15.38 9.85003 15.3371 9.70673 15.2511C9.4058 15.0791 9.20517 14.7782 9.13352 14.3913C8.86125 12.9726 8.86125 10.2929 9.13352 8.9745C9.20517 8.58759 9.42013 8.28666 9.70673 8.12903C9.86436 8.04305 10.0363 8.00006 10.2226 8.00006C10.3802 8.00006 10.5235 8.02872 10.6812 8.08604C12.0855 8.61625 14.364 10.0206 15.3671 10.7944C15.668 11.0237 15.8257 11.3246 15.84 11.6542C15.84 11.9981 15.6824 12.3277 15.3814 12.5857C14.407 13.3882 12.2575 14.6922 10.6955 15.2941C10.5379 15.3514 10.3802 15.38 10.2226 15.38H10.2083Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75 20.5C7.75 21.6046 8.64543 22.5 9.75 22.5H21.75C22.8546 22.5 23.75 21.6046 23.75 20.5V12.5C23.75 11.3954 22.8546 10.5 21.75 10.5V12C22.0261 12 22.25 12.2239 22.25 12.5V20.5C22.25 20.7761 22.0261 21 21.75 21H9.75C9.47386 21 9.25 20.7761 9.25 20.5H7.75Z"
      fill="white"
    />
  </svg>
)

export const MultipleVideos = memo(MultipleVideosComponent)

import {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { IonModal, isPlatform } from '@ionic/react'

type ModalProps = {
  children: ReactNode
}

export interface ModalRef {
  closeModal: () => void
  openModal: () => void
}

export const Modal = forwardRef((props: ModalProps, ref) => {
  const { children } = props
  const [isOpen, setIsOpen] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const isDesktop = isPlatform('desktop')

  const dismissModal = useCallback(() => setIsOpen(false), [])
  const openModal = useCallback(() => setIsOpen(true), [])

  useImperativeHandle(ref, () => ({
    closeModal: () => dismissModal(),
    openModal: () => openModal(),
  }))

  return (
    <IonModal
      isOpen={isOpen}
      className="bottom-sheet"
      initialBreakpoint={isDesktop ? undefined : 1}
      breakpoints={isDesktop ? undefined : [0, 1]}
      onDidPresent={() => inputRef.current?.focus()}
      onDidDismiss={dismissModal}
      onWillDismiss={() => window.scrollTo({ top: 0, behavior: 'instant' })}
    >
      <div className="flex flex-col items-center text-center sm:w-5/6 w-full px-8 pt-5 pb-5 sm:py-8">
        {children}
      </div>
    </IonModal>
  )
})

// Explicitly defining a display name for the component
Modal.displayName = 'Modal'

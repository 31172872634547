import { memo } from 'react'

const ExplorerComponent = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
      className="stroke-white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M18.125 18.125L21.6375 10.35L13.875 13.875L10.7 21.3L18.125 18.125Z"
      className="stroke-white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const ExplorerOutlineComponent = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill-white stroke-white"
      d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.9735 8.64968 3.98956 11.0251C3.00563 13.4006 2.74818 16.0144 3.24979 18.5362C3.7514 21.0579 4.98953 23.3743 6.80761 25.1924C8.62569 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5994 28.9944 20.9749 28.0104C23.3503 27.0265 25.3806 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9934 12.5542 27.6216 9.25145 25.1851 6.81491C22.7485 4.37837 19.4458 3.00661 16 3V3ZM22.5375 10.7625L19.0375 18.5375C18.9293 18.7626 18.7426 18.9404 18.5125 19.0375L11.0875 22.225C10.9641 22.274 10.8327 22.2994 10.7 22.3C10.4339 22.3015 10.178 22.1982 9.9875 22.0125C9.84631 21.8713 9.75061 21.6911 9.71274 21.4951C9.67487 21.299 9.69655 21.0961 9.775 20.9125L12.9625 13.4875C13.0596 13.2574 13.2374 13.0707 13.4625 12.9625L21.2125 9.4375C21.3996 9.35526 21.6071 9.33155 21.8079 9.36947C22.0087 9.4074 22.1933 9.50519 22.3375 9.65C22.4823 9.79168 22.5793 9.97494 22.6152 10.1743C22.651 10.3737 22.6239 10.5793 22.5375 10.7625V10.7625Z"
    />
  </svg>
)

export const Explorer = memo(ExplorerComponent)
export const ExplorerOutline = memo(ExplorerOutlineComponent)

import { Routes } from '@/router/routes'

export const errorBoundary = (error: unknown): void => {
  if (
    typeof error === 'string' &&
    (error as string).includes('No current user')
  ) {
    window.location.assign(Routes.LOGIN)
  }
}

import { FC, useContext, useEffect } from 'react'

import { Loading } from '@/components/atoms/Loading'
import { Main } from '@/components/templates/main'
import { Error } from './components/Error'
import { PostContent } from './components/PostContent'
import { PostHeader } from './components/PostHeader'

import { usePublicPost } from '@/lib/hooks/usePublicPost'
import { useParams } from '@/lib/routing'
import { Context } from '@/lib/store'
import { ActionType } from '@/lib/store/reducer'

export const PublicPost: FC = () => {
  const { activityId } = useParams<{ activityId: string }>()
  const { dispatch } = useContext(Context)

  const { userInfo, isLoading, isError } = usePublicPost({ activityId })

  useEffect(() => {
    dispatch({
      type: ActionType.SIGN_IN_REDIRECT,
      payload: window.location.pathname + window.location.search,
    })
  }, [dispatch])

  if (isError) return <Error />

  return (
    <Main header={userInfo && <PostHeader userInfo={userInfo} />}>
      {isLoading ? (
        <Loading />
      ) : (
        <PostContent media={userInfo.postImages} username={userInfo.name} />
      )}
    </Main>
  )
}

import { post } from '@/services/awsAmplify'

export enum FileStorageAction {
  Get = 'GET',
  Put = 'PUT',
  Delete = 'DELETE',
}

export class FileStorageClass {
  async put(key: string, object: Blob) {
    const { url } = (await post({
      path: '/storage/sign',
      params: {
        contentType: object.type,
        filePath: key,
        action: FileStorageAction.Put,
      },
    })) as { url: string }

    return fetch(url, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': object.type,
        'Access-Control-Allow-Origin': '*',
      },
      body: object,
    }).then((r) => r.text())
  }

  async get(key: string) {
    const { url } = (await post({
      path: '/storage/sign',
      params: {
        filePath: key,
        action: FileStorageAction.Get,
      },
    })) as { url: string }

    return url
  }
}

export const FileStorage = new FileStorageClass()

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'

import { useHandleCopy } from '@/lib/hooks/useHandleCopy'

type CopyWalletAddressProps = {
  walletAddress: string
  prefix?: string
}

export const CopyWalletAddress: FC<CopyWalletAddressProps> = ({
  walletAddress,
  prefix,
}) => {
  const { t } = useTranslation('common')
  const handleCopy = useHandleCopy()

  return (
    <div className="flex flex-col mt-2 mb-6">
      {walletAddress && (
        <QRCode
          value={walletAddress}
          className="sm:w-1/2 h-auto mt-2 mb-8 mx-auto border-white border-[1rem]"
        />
      )}
      <span className="text-primary font-bold mb-2 first-letter:capitalize">
        {t('deposit.depositAddress', { prefix: prefix ? `${prefix} ` : '' })}
      </span>
      <div className="flex items-center">
        <span className="flex flex-1 min-h-[1.5rem] text-sm break-all">
          {walletAddress}
        </span>
        <span
          className="text-[0.6875rem] font-medium uppercase cursor-pointer ml-6"
          onClick={() => handleCopy(walletAddress)}
        >
          {t('copy')}
        </span>
      </div>
    </div>
  )
}

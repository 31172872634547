import { useEffect, useState } from 'react'

import { useConnectUser, useGetChannels } from '@/lib/hooks/useChatClient'

import { useUserInfo } from './useUserInfo'

export const useChatButton = (userId?: UUID) => {
  const { data: authUser } = useUserInfo()

  const { chatClient } = useConnectUser()

  const [unreadCount, setUnreadCount] = useState(0)

  const filters = userId
    ? {
        $and: [
          { members: { $in: [userId] } },
          { members: { $in: [authUser?.id] } },
        ],
        type: 'messaging',
      }
    : null

  const options = { limit: 1, messagesLimit: 1 }
  const { channels } = useGetChannels({
    chatClient,
    options,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    filters,
  })

  useEffect(() => {
    if (!chatClient) return
    if (userId) {
      if ((channels as [])?.length) {
        setUnreadCount(channels[0]?.state?.unreadCount)
        const client = chatClient.on((event) => {
          switch (event.type) {
            case 'message.new':
              setUnreadCount(event?.unread_count)
              break
            case 'message.read':
              setUnreadCount(0)
              break
          }
        })
        return () => client.unsubscribe()
      }
    } else {
      setUnreadCount(Number(chatClient?.user?.total_unread_count))
      const client = chatClient.on((event) => {
        switch (event.type) {
          case 'message.new':
          case 'notification.message_new':
            setUnreadCount(event?.total_unread_count)
            break
          case 'message.read':
          case 'notification.mark_read':
            setUnreadCount(0)
            break
        }
      })
      return () => client.unsubscribe()
    }
  }, [chatClient, channels, userId])

  return {
    unreadCount,
  }
}

import { useCallback, useRef } from 'react'

interface UseRunDoubleClick {
  (
    onDoubleClick: () => void | Promise<void>,
    onClick?: () => void | Promise<void>
  ): { onClickCapture: () => void }
}

// A hook created for implementing a double-click behavior, where you want
// to trigger one function on a double-click event and another function on
// a single-click event.
export const useRunDoubleClick: UseRunDoubleClick = (
  onDoubleClick,
  onClick
) => {
  const lastTouchRef = useRef(0)
  const clickTimeoutRef = useRef(null)

  const onClickCapture = useCallback(() => {
    const now = Date.now()
    const timeSinceLastTouch = now - lastTouchRef.current

    if (timeSinceLastTouch <= 300) {
      onDoubleClick?.()
      clearTimeout(clickTimeoutRef.current)
    } else if (onClick) {
      clickTimeoutRef.current = setTimeout(() => {
        onClick?.()
      }, 300)
    }

    lastTouchRef.current = now
  }, [onDoubleClick, onClick])

  return { onClickCapture }
}

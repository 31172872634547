import { IonContent, IonPage, IonSpinner } from '@ionic/react'

const LoadingPage = () => (
  <IonPage>
    <IonContent fullscreen>
      <div className="flex w-full h-full max-w-none justify-center pb-20 flex-col">
        <div className="relative flex justify-center items-center flex-col">
          <img className="max-w-[24.375rem]" src="/logo.svg" alt="logo" />
          <IonSpinner name="dots" color="main" />
        </div>
      </div>
    </IonContent>
  </IonPage>
)

export default LoadingPage

import { useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/Button'

import { useRedirect } from '@/lib/hooks/useRedirect'

export const Error = () => {
  const { t } = useTranslation('common')
  const { redirectToLogin } = useRedirect()

  return (
    <div className="flex flex-col items-center justify-center h-full gap-2">
      <div className="text-[5rem]">🦥</div>
      <p>{t('publicPost.error.message')}</p>
      <Button className="mb-[5rem]" size="large" onClick={redirectToLogin}>
        {t('publicPost.error.backButton')}
      </Button>
    </div>
  )
}

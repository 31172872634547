import { get } from '@/services/awsAmplify'

export const getPublicProfile = ({ userId }: { userId: string }) =>
  get({
    path: `/public/${userId}`,
  })

export const getPublicPost = ({ activityId }: { activityId: string }) =>
  get({
    path: `/public/posts/${activityId}`,
  })

import { get } from '@/services/awsAmplify'

export interface TopSupporterResponse {
  supporters: ReadonlyArray<TopSupporter>
  // eslint-disable-next-line republik/no-rpk-variable
  entryAmountRPK?: number
}

export interface TopSupporter {
  id: string
  username: string
  isAmbassador: boolean
  displayName?: string
  // eslint-disable-next-line republik/no-rpk-variable
  totalRPK?: number
}

export const getTopSupporters = (input: { userId: string }) => {
  return <Promise<TopSupporterResponse>>get({
    path: `/top-supporters/${input.userId}`,
  })
}

import { memo } from 'react'
import { IonChip } from '@ionic/react'

export const ChipComponent = ({
  className,
  children,
  color,
}: {
  className?: string
  children
  color?: string
}) => (
  <IonChip color={color} className={className}>
    {children}
  </IonChip>
)

export const Chip = memo(ChipComponent)

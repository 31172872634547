import { useEffect, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { stakesEstimate } from '@/services/stake'
import { IStakesEstimate } from '@/services/stake/stakesEstimate'
import { queryClient } from '@/lib/queryClient'

import { QueryKeys } from '@/enums'

export const useStakesEstimate = ({ amount, ruleId }) => {
  const queryKey = useMemo(
    () => [QueryKeys.StakesEstimate, amount, ruleId],
    [amount, ruleId]
  )

  const {
    data: estimate,
    error: estimateError,
    isFetching: isFetchingEstimate,
  } = useQuery<IStakesEstimate, AxiosError>({
    queryKey,
    queryFn: () => stakesEstimate({ amount, ruleId }),
    enabled: !!amount && !!ruleId,
    staleTime: 0,
    gcTime: 0,
  })

  useEffect(() => {
    if (!amount) {
      queryClient.removeQueries({ queryKey })
      return
    }
  }, [amount, queryKey])

  return {
    estimate,
    estimateError,
    isFetchingEstimate,
  }
}

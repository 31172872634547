import { FC } from 'react'

interface LabelProps {
  label: string
  suffix: string
  icon?: JSX.Element
}

export const Label: FC<LabelProps> = ({ label, suffix, icon }) => (
  <>
    {icon && <span className="mr-2">{icon}</span>}
    <span className="capitalize">{label}</span>
    {!!suffix && (
      <span className="text-cool-gray-300 text-xs ml-1">({suffix})</span>
    )}
  </>
)

import { memo } from 'react'

const DepositComponent = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.52466 5.0406V8.52709C1.52466 10.1839 2.8678 11.5271 4.52466 11.5271H10.5247C12.1815 11.5271 13.5247 10.1839 13.5247 8.52709V5.0406"
        stroke="#CCFF00"
        strokeWidth="1.2"
      />
      <path
        d="M7.6869 0.500016L7.6869 8.60812M7.6869 8.60812L10.2815 5.36488M7.6869 8.60812L5.0923 5.36488"
        stroke="#CCFF00"
        strokeWidth="1.2"
      />
    </svg>
  )
}

export const Deposit = memo(DepositComponent)

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const Footer: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation('common')

  return (
    <footer className={`text-gray-400 text-center text-xs ${className || ''}`}>
      {t('registration.footer.note')}{' '}
      <a
        href="https://www.republik.gg/terms-of-service"
        target="_blank"
        className="underline"
        rel="noreferrer"
      >
        {t('registration.footer.terms')}
      </a>
      ,{' '}
      <a
        href="https://www.republik.gg/community-guidelines"
        target="_blank"
        className="underline"
        rel="noreferrer"
      >
        {t('registration.footer.guidelines')}
      </a>
      <p>
        <a
          href="https://www.republik.gg/privacy-policy"
          target="_blank"
          className="underline"
          rel="noreferrer"
        >
          {t('registration.footer.privacyPolicy')}
        </a>
      </p>
    </footer>
  )
}

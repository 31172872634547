import { FC, useMemo } from 'react'
import LinesEllipsis from 'react-lines-ellipsis'

import { Link } from '@/lib/routing'

import { ActivityFeedVerb } from '@/enums'

import { getActivityThumbnail } from '../../../../utils'
import { GridItemIcon } from './GridItemIcon'

type GridItemProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activity: any
}

export const GridItem: FC<GridItemProps> = ({ activity }) => {
  const imageSrc = useMemo(() => getActivityThumbnail(activity), [activity])
  const altText: string = useMemo(
    () => activity?.object?.data?.title || activity.text || '',
    [activity]
  )

  const activityUrl = useMemo(() => {
    switch (activity.verb) {
      case ActivityFeedVerb.StartedConversation:
      case ActivityFeedVerb.RepliedToConversation:
        return `/comments/conversation?replyObjectId=${activity?.object?.data?.id}&createdAt=${activity?.object?.data?.createdAt}&replyId=${activity?.replyId}`
      default:
        return `/comments/${activity.id}?page=&replyId=`
    }
  }, [activity])

  const isContestParticipate =
    activity.verb === ActivityFeedVerb.ContestParticipate

  const content = useMemo(() => {
    return (
      <>
        {imageSrc ? (
          <div
            className="absolute w-full h-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${imageSrc})`,
            }}
          />
        ) : (
          <LinesEllipsis
            className="text-left text-sm py-2"
            text={altText}
            maxLine="5"
            ellipsis="..."
            basedOn="letters"
          />
        )}
        <div className="absolute z-1 right-1 bottom-2">
          <GridItemIcon activity={activity} />
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-7 bg-gradient-to-b from-transparent to-black opacity-50" />
      </>
    )
  }, [imageSrc, altText, activity])

  if (isContestParticipate) {
    return <div className="relative w-full h-full">{content}</div>
  }

  return (
    <Link
      className="relative w-full h-full cursor-pointer"
      to={
        activity.verb === ActivityFeedVerb.ContestParticipate ? '' : activityUrl
      }
    >
      {content}
    </Link>
  )
}

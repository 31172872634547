import { useTranslation } from 'react-i18next'

import { Diamond } from '@/components/atoms/assets/Diamond'
import { Button } from '@/components/atoms/Button'

import { useNavigate } from '@/lib/routing'

import { ProfileHashTabs } from '@/enums'

interface UnlockExclusiveContentButtonProps {
  userName?: string
}

export function UnlockExclusiveContentButton({
  userName,
}: UnlockExclusiveContentButtonProps) {
  const navigate = useNavigate()

  const { t } = useTranslation()

  return (
    <Button
      className="gradient rounded-full !text-sm sm:!text-xl h-[2.75rem] sm:h-[4rem]"
      size="large"
      onClick={() => navigate(`/@${userName}${ProfileHashTabs.SUBSCRIPTION}`)}
      type="tertiary"
    >
      <div className="flex justify-center items-center px-6 py-2 sm:px-8">
        <Diamond className="mr-2 sm:h-8 sm:w-8" />
        {t('unlockExclusiveContent')}
      </div>
    </Button>
  )
}

import { getCurrentUser } from 'aws-amplify/auth'

import { UserInfo } from '@/services/user'
import { queryClient } from '@/lib/queryClient'
import { Routes } from '@/router/routes'

export const authGuard = async () => {
  try {
    await getCurrentUser()
  } catch (error) {
    const user = queryClient.getQueryData<UserInfo>(['user'])

    if (user?.username) {
      window.location.assign(Routes.LOGOUT)
    }
  }
}

import { forwardRef, ReactElement, useImperativeHandle, useState } from 'react'
import { IonIcon, IonPopover } from '@ionic/react'
import { alertCircleOutline } from 'ionicons/icons'

export interface WarningAlertProps {
  title: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  description: ReactElement<any, any>
  acknowledgeText: string
}

export interface WarningAlertRef {
  open: () => void
}

const WarningAlert = forwardRef<WarningAlertRef, WarningAlertProps>(
  (props, ref) => {
    const [isOpen, setIsOpen] = useState(false)

    useImperativeHandle(ref, () => ({
      open() {
        setIsOpen(true)
      },
    }))

    const handleDismiss = () => setIsOpen(false)

    return (
      <IonPopover
        isOpen={isOpen}
        onDidDismiss={handleDismiss}
        className="warning-popover"
        showBackdrop
        backdropDismiss={false}
      >
        <div
          className="flex flex-col items-center px-3 py-4 text-center"
          data-test="popover"
        >
          <IonIcon
            icon={alertCircleOutline}
            color="danger"
            className="text-5xl mb-3"
          />
          <h3 className="mb-5">{props.title}</h3>
          <span>{props.description}</span>
        </div>
        <div className="flex border-t" data-test="popover-reply">
          <button className="w-full p-3" onClick={handleDismiss}>
            {props.acknowledgeText}
          </button>
        </div>
      </IonPopover>
    )
  }
)

// Explicitly defining a display name for the component
WarningAlert.displayName = 'WarningAlert'

export default WarningAlert

import { useCallback, useEffect, useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import reject from 'lodash/reject'
import { parse } from 'query-string'

import { IActivityItem } from '@/components/pages/Activity/interfaces'

import { appConfig } from '@services/config'
import { get } from '@/services/awsAmplify'
import { ApiError } from '@/services/awsAmplify/api'
import { createDoFeedRequest } from '@lib/getstream'
import { flattenPages } from '@/utils/flattenPages'

import { AllowedActivityFeedVerbs, QueryKeys } from '@/enums'

import { queryClient } from '../queryClient'
import { useGetstreamClient } from './useGetstreamClient'
import { useNetwork } from './useNetwork'
import { useUserInfo } from './useUserInfo'

interface IFeedProps {
  feedGroup: string
  feedId: string
  cacheKey: [QueryKeys, ...string[]]
  ranking: 'popularity' | 'popularity_2'
  normalizeConversationReplies?: boolean
  enabled?: boolean
  userId?: UUID
}

type ActivityItemWithRecommendedUsers = IActivityItem & {
  recommended_users?: string[]
}

export const useFeed = ({
  feedGroup,
  feedId,
  cacheKey,
  ranking,
  enabled = true,
  userId,
}: IFeedProps) => {
  const { isOnline } = useNetwork()
  const doFeedRequest = createDoFeedRequest()
  const { client } = useGetstreamClient()
  const { data: userInfo } = useUserInfo()

  const removeRecommendations = (
    activities: ReadonlyArray<ActivityItemWithRecommendedUsers>
  ) =>
    reject(activities, (activity) =>
      activity?.recommended_users?.includes(userInfo?.id)
    )
  const isSubscription = feedGroup === 'subscription'
  const isExclusive = feedGroup === 'exclusive'

  const nextPageParam =
    ranking || isSubscription || isExclusive ? 'offset' : 'id_lt'
  const nextPageDefault = ranking ? 0 : ''

  const {
    refetch,
    data,
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isSuccess,
    isFetched,
    error,
    isError,
    isFetchedAfterMount,
  } = useInfiniteQuery({
    queryKey: cacheKey,
    queryFn: async ({ pageParam }) => {
      const params = {
        [nextPageParam]: pageParam || nextPageDefault,
        limit: 50,
        reactions: isSubscription ? {} : { counts: true, own: true },
        ...(ranking && { ranking }),
      }

      let result

      switch (feedGroup) {
        case 'timeline':
          result = await get({
            path: '/feeds/home',
            params,
          })
          break
        case 'trending':
          result = await get({
            path: '/feeds/trending',
            params,
          })
          break
        case 'subscription':
          if (!userId) return
          result = await get({
            path: `/subscription/feed/${userId}`,
            params,
          })
          break
        case 'exclusive':
          result = await get({
            path: '/subscription/exclusive/feed',
            params,
          })
          break
        default:
          result = await doFeedRequest<IActivityItem>(
            client,
            feedGroup,
            feedId,
            params
          )
          break
      }
      let data = result?.results || []
      data = data.filter(
        (activity) => activity.object && typeof activity.object === 'object'
      )
      if (appConfig.disableRecommendations) {
        data = removeRecommendations(data)
      }
      return {
        data,
        next: result?.next ? parse(result?.next)?.[nextPageParam] : undefined,
      }
    },
    enabled: !isOnline || (!!client && enabled),
    getNextPageParam: (lastPage) => lastPage?.next,
    initialPageParam: '',
  })

  const refetchFirstPage = useCallback(async () => {
    queryClient.setQueryData<{
      pages: unknown[]
      pageParams: (string | undefined)[]
    }>(cacheKey, (data) => ({
      pages: data?.pages?.slice(0, 1) || [],
      pageParams: data?.pageParams?.slice(0, 1) || [],
    }))
    await refetch()
  }, [cacheKey, refetch])

  useEffect(() => {
    if (error) {
      const status = (error as unknown as ApiError)?.response?.status

      if (feedGroup === 'subscription' && (status === 404 || status === 402)) {
        return
      }

      console.error(`Failed to get user feed ${feedGroup}:${feedId}`)
    }
  }, [error, feedGroup, feedId])

  const feedData = useMemo(() => {
    return flattenPages(data, 'data').filter((item) =>
      Object.values(AllowedActivityFeedVerbs).includes(item.verb)
    )
  }, [data])

  return {
    error,
    feedData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading: isLoading && isOnline,
    isSuccess,
    refetchFirstPage,
    refetch,
    isFetching,
    isFetched,
    hasFeedError: isError && isOnline,
    isOnline,
    isFetchedAfterMount,
  }
}

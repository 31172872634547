import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react'
import ReactPlayer from 'react-player/file'
import { IonIcon, isPlatform } from '@ionic/react'
import cx from 'classnames'
import { play } from 'ionicons/icons'
import throttle from 'lodash/throttle'

import { ProgressBar } from '@/components/atoms/ProgressBar'

interface VideoPlayerProps {
  url: string
  style?: CSSProperties
  className?: string
  videoPreview?: string
  progressHandler?: (e: boolean) => void
  muted?: boolean
  playing?: boolean
  videoProgress?: number
  isProgressBarVisible?: boolean
  onClick?: (e) => void
  isVideoReset?: boolean
  videoContainerHeight?: number
  onError?: () => void
}

export const VideoPlayer: FC<VideoPlayerProps> = ({
  url,
  style,
  className,
  videoPreview,
  progressHandler,
  muted = false,
  playing = true,
  videoProgress,
  isProgressBarVisible = false,
  onClick,
  isVideoReset = false,
  videoContainerHeight = 'auto',
  onError,
}) => {
  const videoRef = useRef<ReactPlayer>(null)
  const [playedPercentage, setPlayedPercentage] = useState<number>(0)
  const [isAllowedToPlay, setIsAllowedToPlay] = useState(true)
  const forceHLS = useMemo(
    () =>
      url?.split('.')?.slice(-1)?.[0] === 'm3u8' &&
      !document
        .createElement('video')
        .canPlayType('application/vnd.apple.mpegurl'),
    [url]
  )
  const containerStyle = useMemo(() => {
    return { ...style }
  }, [style])

  if (videoProgress && videoRef.current) {
    videoRef.current.seekTo(Number(videoProgress))
  }

  const onProgress = throttle((e) => {
    progressHandler && progressHandler(e)
    setPlayedPercentage(Math.floor(e.played * 100))
  }, 100)

  useEffect(() => {
    if (!isVideoReset) return
    playing && videoRef.current.seekTo(0)
  }, [playing, isVideoReset])

  return (
    <>
      <div
        className={cx(className, { 'cursor-pointer': !!onClick })}
        style={containerStyle}
        onClick={onClick}
      >
        <ReactPlayer
          ref={videoRef}
          autoPlay
          controls={false}
          playing={playing}
          preload="auto"
          light={videoPreview}
          progressInterval={200}
          onProgress={onProgress}
          onPlay={() => setIsAllowedToPlay(true)}
          onError={(error) => {
            onError?.()
            if (error?.name === 'NotAllowedError') setIsAllowedToPlay(false)
          }}
          loop
          muted={muted}
          url={url}
          width="100%"
          height={videoContainerHeight}
          playsinline
          config={{
            forceHLS,
            hlsOptions: {
              maxBufferLength: 1,
              maxBufferSize: 0.2 * 1000 * 1000,
            },
          }}
        />
        {isProgressBarVisible && (
          <div className="absolute bottom-0 w-full">
            <ProgressBar progress={playedPercentage} />
          </div>
        )}
      </div>
      {isPlatform('ios') && !isAllowedToPlay && (
        <div
          className="flex justify-center items-center absolute z-30 left-0 right-0 top-0 bottom-0 bg-black bg-opacity-50"
          onClick={(e) => {
            setIsAllowedToPlay(true)
            e.stopPropagation()
            videoRef.current.getInternalPlayer().play()
          }}
        >
          <IonIcon icon={play} className="w-20 h-20" />
        </div>
      )}
    </>
  )
}

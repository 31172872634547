import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from '@components/atoms/Avatar'
import { Currency } from '@/components/atoms/Currency'
import { UserName } from '@/components/atoms/UserName'

import { SubscriptionType } from '@/services/subscription'
import { formatDate } from '@/utils/date'

import { DateFormat, SubscriptionStatus } from '@/enums'

type SubscriptionItemProps = {
  subscription: SubscriptionType
  additionalButton?: ReactNode
}

export const SubscriptionListItem: FC<SubscriptionItemProps> = ({
  subscription,
  additionalButton,
}) => {
  const { t } = useTranslation('common')

  const { username, id, startDate, nextPaymentDate, status, price } =
    subscription

  return (
    <div className="bg-cool-gray-800 rounded-[0.3125rem] my-1.5 px-4 py-5">
      <div className="flex items-center">
        <div className="mb-auto">
          <Avatar size="large" userId={id} username={username} clickable />
        </div>
        <div className="flex justify-between ml-4 grow">
          <div className="flex flex-col gap-[0.38rem]">
            <UserName name={username} userId={id} className="font-bold" />
            <DateDetail
              title={t('subscriptions.item.started')}
              date={startDate}
            />
            <DateDetail
              title={t(
                `subscriptions.item.${
                  status === SubscriptionStatus.Active
                    ? 'nextPayment'
                    : 'expiringOn'
                }`
              )}
              date={nextPaymentDate}
            />
            {additionalButton}
          </div>

          <div className="flex flex-col">
            <span className="font-bold" title={String(price)}>
              <Currency value={price} isHighlighted />
            </span>
            <span>{t('subscriptions.item.perMonth')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

const DateDetail = ({ title, date }: { title: string; date: string }) => {
  return (
    <span className="flex gap-1 text-xs">
      <span className="text-cool-gray-200">{title}:</span>
      <span className="text-white">
        {formatDate(+new Date(date), DateFormat.LocalizedDate)}
      </span>
    </span>
  )
}

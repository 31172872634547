import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { IonLabel, IonSpinner } from '@ionic/react'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { useMutation, useQuery } from '@tanstack/react-query'
import { jwtDecode } from 'jwt-decode'

import { errorBoundary } from '@/services/errorBoundary'
import {
  googleCredentialResponse,
  googleUserBasicProfile,
  ssoSignIn,
} from '@/services/sso-signin'
import { getUserInfo } from '@/services/user'
import { setWebPushNotificationsExternalUserId } from '@/services/webPushNotifications'
import { useAmplitudeIdentifyUser } from '@/lib/hooks/useAmplitudeIdentifyUser'
import { useVisualViewport } from '@/lib/hooks/useVisualViewport'
import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'
import { newrelicNoticeError } from '@/lib/tracking'
import { getStreamSetUserInfo } from '@/lib/tracking/platform/getstream'
import { Routes } from '@/router/routes'

import { SignInMethods } from '@/enums'

import {
  trackingSigninCompleted,
  trackingSigninMethodSelected,
  trackingSignupSubmitted,
} from './TrackingSSO'

const SignInWithGoogle: FC = () => {
  const navigate = useNavigate()
  const [credentialResponse, setCredentialResponse] =
    useState<CredentialResponse | null>()
  const user = useMemo(() => {
    if (!credentialResponse?.credential) return
    return jwtDecode(credentialResponse.credential)
  }, [credentialResponse])

  const { amplitudeIdentifyUser } = useAmplitudeIdentifyUser()

  const {
    refetch: getUserData,
    error,
    isError,
  } = useQuery({
    queryKey: ['user'],
    queryFn: getUserInfo,
    retry: 5,
    enabled: false,
  })

  const { mutate, isPending } = useMutation({
    mutationKey: ['signInWithGoogle'],
    mutationFn: signInWithGoogle,
    onMutate: () => trackingSigninMethodSelected(SignInMethods.Google),
    onSuccess: async (data) => {
      trackingSigninCompleted(SignInMethods.Google)
      const user = await getUserData()
      if (data?._tag === 'NewRegistration') {
        await trackingSignupSubmitted(SignInMethods.Google)
      }
      onSuccess(user?.data)
    },
    onError: (e) => {
      errorBoundary(e)
      console.error(`Error signing in with Google ${e}`)
    },
  })

  const setupPushNotification = (userId: string) => {
    setWebPushNotificationsExternalUserId(userId)
  }

  async function signInWithGoogle() {
    const data: { refUsername?: string; redirectUrl?: string } =
      queryClient.getQueryData(['referral'])

    const { email } = user as googleUserBasicProfile
    const { credential } = credentialResponse as googleCredentialResponse

    return ssoSignIn(
      'google',
      {
        username: email.toLowerCase(),
        authToken: credential,
      },
      {
        refUsername: data?.refUsername,
        email: email.toLowerCase(),
      }
    )
  }

  const onSuccess = useCallback(
    (user) => {
      amplitudeIdentifyUser(user)
      setupPushNotification(user.id)
      if (user.username) {
        getStreamSetUserInfo(user.id, user.username)
        navigate(Routes.FEED, { replace: true })
      } else {
        navigate(Routes.AUTH_CONSENT, {
          replace: true,
          state: { direction: 'none' },
        })
      }
    },
    [amplitudeIdentifyUser, navigate]
  )

  useEffect(() => {
    if (isError) errorBoundary(error)
  }, [error, isError])

  useEffect(() => {
    if (user && credentialResponse) {
      mutate()
    }
  }, [credentialResponse, mutate, user])

  const {
    visualViewport: { width: viewportWidth },
  } = useVisualViewport()

  const X_PADDING = 24
  const SIGN_IN_BUTTON_MAX_WIDTH = 336

  const computeGoogleButtonWidth = useMemo(
    () => Math.min(viewportWidth - 2 * X_PADDING, SIGN_IN_BUTTON_MAX_WIDTH),
    [viewportWidth]
  )

  return (
    <>
      {isPending ? (
        <IonLabel className="w-full cursor-pointer flex flex-row justify-center text-sm font-robotomedium bg-white text-black text-opacity-[.54] font-semibold py-2 px-4 rounded-full">
          <IonSpinner color="light" />
        </IonLabel>
      ) : (
        <div className={`w-auto h-[40px] rounded-full overflow-hidden`}>
          <GoogleLogin
            shape="pill"
            useOneTap
            ux_mode="popup"
            width={computeGoogleButtonWidth}
            text="continue_with"
            onSuccess={(credentialResponse) => {
              setCredentialResponse(credentialResponse)
            }}
            onError={() => {
              newrelicNoticeError({
                error: 'Google Authentication failed',
                customAttributes: {
                  location: window.location.href,
                  user: user,
                  credentialResponse: credentialResponse,
                },
              })
            }}
          />
        </div>
      )}
    </>
  )
}

export default SignInWithGoogle

import { useMemo } from 'react'
import { IonSpinner } from '@ionic/react'
import { AxiosError } from 'axios'

import { Button } from '@/components/atoms/Button'

import { BuyMorePRKButton } from '../BuyMorePRKButton'

type IPaymentFormButtonProps = {
  onClick: () => Promise<void>
  isLoading: boolean
  isDisabled: boolean
  translation: string
  error: unknown | AxiosError | null
  onBuyMoreCurrency?: () => void
}

export const PaymentFormButton = ({
  onClick,
  isLoading,
  isDisabled,
  translation,
  error,
  onBuyMoreCurrency,
}: IPaymentFormButtonProps) => {
  const isInsufficientFoundsError = useMemo(
    () => error === 'SOURCE_WALLET_INSUFFICIENT_FOUNDS',
    [error]
  )

  return isInsufficientFoundsError ? (
    <BuyMorePRKButton onClick={onBuyMoreCurrency} />
  ) : (
    <Button
      onClick={onClick}
      type={isLoading ? 'secondary' : 'primary'}
      className="w-[9.25rem]"
      size="small"
      isDisabled={isDisabled}
    >
      {isLoading ? <IonSpinner className="h-5" color="main" /> : translation}
    </Button>
  )
}

import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButton } from '@ionic/react'
import { uniqueId } from 'lodash'

import {
  DepositCurrency,
  DepositCurrencyRef,
} from '@/components/organisms/DepositCurrency'

import { appConfig } from '@/services/config'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useWallet } from '@/lib/hooks/wallet'

import { TippingModal } from '../TippingModal'

type SendTipButtonProps = {
  className?: string
  userId: UUID
  initialAmount?: number
}

export const SendTipButton: FC<SendTipButtonProps> = ({
  className = '',
  userId,
  initialAmount,
}) => {
  const { t } = useTranslation('common')
  const currency = appConfig.currency
  const { data: authUser } = useUserInfo()
  const { isZeroBalance } = useWallet({
    userInfo: authUser,
  })
  const depositCurrencyRef = useRef<DepositCurrencyRef>()
  const uuid = useMemo(() => uniqueId(), [])
  const [modalId, setModalId] = useState('')

  useEffect(() => {
    if (!isZeroBalance) {
      setModalId(`send-tip-modal-${uuid}`)
    }
  }, [isZeroBalance, uuid])

  return (
    <>
      <IonButton
        id={modalId}
        className={`m-0 normal-case tracking-normal ${className}`}
        size="small"
        fill="outline"
        color="primary"
        onClick={() => {
          if (isZeroBalance) {
            depositCurrencyRef.current.openModal()
            return
          }
        }}
      >
        {t('tip.actions.sendTip')}
      </IonButton>
      <TippingModal
        userId={userId}
        initialAmount={initialAmount}
        modalId={modalId}
      />
      <DepositCurrency
        title={t('depositBottomSheet.topUpCurrencyToTip', {
          currency,
        })}
        ref={depositCurrencyRef}
      />
    </>
  )
}

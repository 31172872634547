import { FC, ReactNode } from 'react'
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import cx from 'classnames'
import isFunction from 'lodash/isFunction'

import { Refresher } from '@components/atoms/Refresher'
import { OfflineIndicator } from '@/components/atoms/OfflineIndicator'

import { isInStandaloneMode } from '@/utils/isInStandaloneMode'

import { IRefresher } from 'interfaces'

import { useHeadTitle } from '../useHeadTitle'
interface IClassNames {
  [className: string]: string
}

export interface IMainTemplateProps extends IRefresher {
  children: ReactNode
  header?: ReactNode
  contentClassName?: string
  headerClassName?: string
  refetch?: () => Promise<unknown>
  headTitle?: string
  footer?: ReactNode
  style?: IClassNames
  subHeader?: ReactNode
  isRefetchActive?: boolean
  isPaddingEnabled?: boolean
}

export const Main: FC<IMainTemplateProps> = ({
  children,
  header,
  refetch,
  contentClassName = '',
  headerClassName = '',
  headTitle,
  footer = null,
  style = null,
  subHeader,
  isRefetchActive = true,
  isPaddingEnabled = true,
}) => {
  useHeadTitle(headTitle)

  return (
    <IonPage
      style={style}
      className={cx('touch-none transition-[padding]', {
        'pb-[0.875rem]': isInStandaloneMode() && isPlatform('ios'),
      })}
    >
      <IonHeader
        className={`${headerClassName} mx-auto max-w-[40rem]`}
        role="none"
      >
        <IonToolbar mode="ios">{header}</IonToolbar>
        <OfflineIndicator />
        {subHeader}
      </IonHeader>
      <IonContent>
        {isFunction(refetch) && (
          <Refresher
            disabled={!isRefetchActive}
            refetch={refetch}
            className="bg-cool-gray-900"
          />
        )}
        <section
          className={cx(
            `mx-auto max-w-[40rem] min-h-full flex flex-col content-start ${contentClassName}`,
            { 'px-0 md:px-8': isPaddingEnabled }
          )}
        >
          {children}
        </section>
      </IonContent>
      {footer && (
        <IonFooter className="mx-auto w-full max-w-[40rem] order-1">
          {footer}
        </IonFooter>
      )}
    </IonPage>
  )
}

import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { App } from '@capacitor/app'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useAccountInfo } from '@/services/account'
import { errorBoundary } from '@/services/errorBoundary'
import { createOpenAppReward, OpenAppRewardData } from '@/services/rewards'
import { OpenAppStreakQueryKey } from '@/lib/hooks/useOpenAppStreak'
import { Context } from '@/lib/store'

import { ShouldShowStreakRemindersStorageKey } from '../OpenAppStreakComponent'
import { OpenAppToastReminder } from './OpenAppToastReminder'

export const OpenAppRewardQueryKey = 'open-app-reward'

export const OpenAppReward: FC = ({ children }) => {
  const { state } = useContext(Context)
  const queryClient = useQueryClient()
  const [rewardData, setRewardData] = useState<OpenAppRewardData>()

  const shouldShowOpenAppReminder = useMemo(() => {
    if (typeof state.shouldShowStreakReminders === 'boolean') {
      return state.shouldShowStreakReminders
    }

    const storedValue = localStorage.getItem(
      ShouldShowStreakRemindersStorageKey
    )

    return !storedValue || storedValue === 'true'
  }, [state.shouldShowStreakReminders])

  const { mutate: createOpenAppRewardMutation } = useMutation({
    mutationKey: ['createOpenAppReward'],
    mutationFn: createOpenAppReward,
    onError: errorBoundary,
    onSuccess: (data) => {
      setRewardData(data)
      queryClient.invalidateQueries({ queryKey: [OpenAppStreakQueryKey] })
    },
  })

  const { data: account } = useAccountInfo(undefined, {
    enabled: false,
  })

  useEffect(() => {
    if (account) {
      createOpenAppRewardMutation(account.id)
    }
    const handleAppStateChange = (newState) => {
      if (newState.isActive && account) {
        createOpenAppRewardMutation(account.id)
      }
    }

    const subscription = App.addListener('appStateChange', handleAppStateChange)

    return () => {
      subscription.remove()
    }
  }, [account, createOpenAppRewardMutation])

  return (
    <>
      <OpenAppToastReminder
        streakDay={rewardData?.day}
        streakDuration={rewardData?.of}
        shouldShowOpenAppReminder={shouldShowOpenAppReminder}
      />
      {children}
    </>
  )
}

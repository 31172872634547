import { useMemo } from 'react'
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { ReactionAPIResponse } from 'getstream'
import { parse } from 'query-string'

import { IActivityItem } from '@/components/pages/Activity/interfaces'

import { addComment, deleteComment } from '@/services/comments'
import { flattenPages } from '@/utils/flattenPages'

import { QueryKeys } from '@/enums'
import { IUser } from '@/interfaces'

import { useConversationId } from './useConversationId'
import { ResponseType } from './useDailyLimitToast'
import { useErrorBoundary } from './useErrorBoundary'
import { useGetstreamClient } from './useGetstreamClient'

export interface IReaction extends ReactionAPIResponse {
  user: IUser
}

interface IUseCommentsSection {
  activityId: UUID | 'conversation'
  limit?: number
  replyObjectId: UUID
  createdAt: string
  enabled?: boolean
}

export const useCommentsSection = ({
  activityId,
  limit = 25,
  replyObjectId,
  createdAt,
  enabled = true,
}: IUseCommentsSection) => {
  const { client } = useGetstreamClient()
  const queryClient = useQueryClient()

  const { handleError } = useErrorBoundary()

  const { conversationId } = useConversationId({
    activityId,
    replyObjectId,
    createdAt,
  })

  const { data, fetchNextPage, hasNextPage, refetch, error } = useInfiniteQuery(
    {
      queryKey: [QueryKeys.CommentSection, conversationId],
      queryFn: async ({ pageParam }) => {
        const result = await client.reactions.filter({
          activity_id: conversationId,
          limit,
          id_lt: pageParam || '',
          kind: 'COMMENT',
        })

        return {
          data: result?.results,
          next: parse(result.next)['id_lt'],
        }
      },
      initialPageParam: '',
      enabled: !!client && !!conversationId && enabled,
      getNextPageParam: (lastPage) => lastPage?.next,
    }
  )

  const commentsData = useMemo(() => {
    return flattenPages(data, 'data')
  }, [data])

  const refetchFirstPage = async () => {
    queryClient.setQueryData<{
      pages: unknown[]
      pageParams: (string | undefined)[]
    }>([QueryKeys.CommentSection, conversationId], (prevData) => ({
      pages: prevData?.pages?.slice(0, 1) || [],
      pageParams: prevData?.pageParams?.slice(0, 1) || [],
    }))
    await refetch()
  }

  const { mutate: deleteCommentMutation, isPending: isDeleteCommentLoading } =
    useMutation({
      mutationFn: deleteComment,
      onSuccess: async ({ commentsCount }) => {
        refetchFirstPage()

        queryClient.setQueryData<IActivityItem>(
          [QueryKeys.ActivityDetails, conversationId],
          (prevData) => {
            return {
              ...prevData,
              reaction_counts: {
                ...prevData?.reaction_counts,
                COMMENT: commentsCount,
              },
            }
          }
        )
      },
      onError: (error: { response: ResponseType }) => handleError(error),
    })

  const onDeleteComment = (commentId: UUID): void => {
    deleteCommentMutation({ activityId: conversationId, commentId })
  }

  const { mutateAsync, isPending: isAddCommentLoading } = useMutation({
    mutationKey: [QueryKeys.CommentSection, conversationId],
    mutationFn: addComment,
    onSuccess: async () => {
      refetchFirstPage()

      queryClient.setQueryData<IActivityItem>(
        [QueryKeys.ActivityDetails, conversationId],
        (prevData) => {
          const prevCommentCount = prevData?.reaction_counts?.COMMENT ?? 0
          return {
            ...prevData,
            reaction_counts: {
              ...prevData?.reaction_counts,
              COMMENT: prevCommentCount + 1,
            },
          }
        }
      )
    },
    onError: (error: { response: ResponseType }) => handleError(error),
  })

  const onAddComment = async (
    comment: string,
    mentions: UUID[],
    reactionTargetId: UUID
  ): Promise<void> => {
    try {
      await mutateAsync({
        activityId: conversationId,
        comment,
        mentions,
        reactionTargetId,
      })
    } catch (e) {
      console.warn(e?.response?.data)
    }
  }

  const comments = (commentsData ?? []) as IReaction[]
  const fetchedCommentsCount = comments?.length

  return {
    conversationId,
    comments,
    fetchedCommentsCount,
    hasNextPage,
    isAddCommentLoading,
    isDeleteCommentLoading,
    fetchNextPage,
    onDeleteComment,
    onAddComment,
    refetch,
    refetchFirstPage,
    hasCommentsError: !!error,
  }
}

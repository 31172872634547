import { FC, memo } from 'react'
import { IonIcon } from '@ionic/react'
import { chevronForward } from 'ionicons/icons'

import { Button } from '@/components/atoms/Button'

import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

import { QueryKeys } from '@/enums'

export const SocialsTemplateComponent: FC<{
  name: string
  route: string
  Icon: React.ReactNode
}> = ({ name = '', route = '', Icon = null }) => {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => {
        navigate(route)
        queryClient.setQueryData([QueryKeys.SocialBackUrl], Routes.PROFILE_EDIT)
      }}
      className="pt-1 flex justify-between items-center p-0 cursor-pointer"
    >
      <Button className="plain !text-sm" fill="clear" type="danger">
        <span className="ml-1">{Icon}</span>
        <span className="ml-2 text-base text-white">{name}</span>
      </Button>
      <IonIcon icon={chevronForward} />
    </div>
  )
}

export const SocialsTemplate = memo(SocialsTemplateComponent)

import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonTitle, useIonToast } from '@ionic/react'
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness'

import { BackButton } from '@components/atoms/BackButton'
import { Loading } from '@/components/atoms/Loading'
import { Title } from '@/components/atoms/Title'
import { Main } from '@/components/templates/main'

import { appConfig } from '@/services/config'
import { isLive } from '@/services/faceliveness'
import { useLiveness } from '@/lib/hooks/liveness'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { WALLET } from '@/utils/route'

import { IUser } from '@/interfaces'

export const LivenessCheck: FC = () => {
  const { data: authUser } = useUserInfo()
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const [presentToast] = useIonToast()

  const { isLoading, sessionId, isError } = useLiveness()

  const setUserCompletedLivenessCheck = (): void => {
    queryClient.setQueryData(['user'], (authUserData: IUser) => ({
      ...authUserData,
      completedFaceLivenessCheck: true,
    }))
    queryClient.invalidateQueries({ queryKey: ['user'] })
  }

  const handleAnalysisComplete = async () => {
    const result = await isLive({ sessionId })

    if (result.isLive) {
      setUserCompletedLivenessCheck()

      presentToast({
        message: t('livenessCheck.verificationSucceeded.message'),
        position: 'top',
        cssClass: 'liveness-succeeded',
        duration: 3000,
      })

      navigate(WALLET, { replace: true })
    } else {
      navigate(Routes.FACE_LIVENESS_FAILED, { replace: true })
    }
  }

  const header = useMemo(
    () => (
      <>
        <IonButtons slot="start">
          <BackButton defaultHref={Routes.FACE_LIVENESS_INSTRUCTIONS} />
        </IonButtons>
        <IonTitle>
          <Title className="text-center my-0" level={3}>
            {t('livenessCheck.header')}
          </Title>
        </IonTitle>
      </>
    ),
    [t]
  )

  useEffect(() => {
    if (authUser?.completedFaceLivenessCheck) {
      navigate(WALLET, { replace: true })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isError) {
      navigate(WALLET, { replace: true })
    }
  }, [navigate, isError])

  if (isError) return

  return (
    <Main header={header} isPaddingEnabled={false}>
      {isLoading ? (
        <Loading />
      ) : (
        <FaceLivenessDetector
          sessionId={sessionId}
          region={appConfig.faceLivenessRegion}
          onAnalysisComplete={handleAnalysisComplete}
          displayText={t('livenessCheck.text', { returnObjects: true })}
          onUserCancel={() =>
            navigate(Routes.FACE_LIVENESS_INSTRUCTIONS, { replace: true })
          }
          onError={(livenessError) => console.error(livenessError)}
        />
      )}
    </Main>
  )
}

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { validateEmail } from '@services/email-validation'
import { errorBoundary } from '@/services/errorBoundary'
import { useRouterQuery } from '@lib/hooks/useRouterQuery'

import { ErrorCodes } from '@/enums'

import { Logo } from '../atoms/assets/Logo'
import { Title } from '../atoms/Title'

enum ValidationStatus {
  Error,
  TokenNotFound,
  Successful,
}

export const EmailValidation = () => {
  const { t } = useTranslation('common')
  const [validationStatus, setValidationStatus] = useState<ValidationStatus>()
  const query = useRouterQuery()
  const token = query.get('token')

  const { mutate: mutateValidateEmail, isPending } = useMutation({
    mutationKey: ['validateEmail'],
    mutationFn: validateEmail,
    onSuccess: () => setValidationStatus(ValidationStatus.Successful),
    onError: (error: AxiosError) => {
      errorBoundary(error)
      if (error.response?.status === ErrorCodes.NotFound) {
        setValidationStatus(ValidationStatus.TokenNotFound)
        return
      }

      setValidationStatus(ValidationStatus.Error)
    },
  })

  useEffect(() => {
    mutateValidateEmail({ token })
  }, [mutateValidateEmail, token])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>RepubliK</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="h-full w-full max-w-[40rem] m-auto flex flex-col items-center">
          <div className={'w-3/4 my-9'}>
            <Logo />
          </div>
          {isPending ? (
            <IonSpinner name="dots" color="main" />
          ) : (
            <Title level={2}>
              {validationStatus === ValidationStatus.Successful
                ? t('registration.email.verified')
                : t('registration.email.verificationError')}
            </Title>
          )}
        </div>
      </IonContent>
    </IonPage>
  )
}

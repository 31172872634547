import { useCallback, useEffect, useState } from 'react'
import { AxiosError } from 'axios'

export const useVirtualList = ({
  hasNextPage,
  fetchNextPage,
  fetchNextSubscriptionPage,
  hasNextSubscriptionPage,
  sectionIndex,
  subscriptionFeedError,
}: {
  hasNextPage: boolean
  fetchNextPage: () => void
  fetchNextSubscriptionPage?: () => void
  hasNextSubscriptionPage?: boolean
  sectionIndex?: number
  subscriptionFeedError?: AxiosError | null | unknown
}) => {
  const [isAtTop, setIsAtTop] = useState(true)
  const [endReachedOffset, setEndReachedOffset] = useState(0)

  useEffect(() => {
    if (
      (sectionIndex === 0 || !sectionIndex) &&
      hasNextPage &&
      endReachedOffset !== 0
    ) {
      fetchNextPage()
    }
    if (
      sectionIndex === 1 &&
      !subscriptionFeedError &&
      hasNextSubscriptionPage &&
      endReachedOffset !== 0
    ) {
      fetchNextSubscriptionPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endReachedOffset, hasNextPage])

  const atTopStateChange = useCallback(
    (isAtTop: boolean) => {
      setIsAtTop(isAtTop)
      setEndReachedOffset(0)
    },
    [setIsAtTop, setEndReachedOffset]
  )

  const onEndReached = useCallback((e) => {
    return setTimeout(() => {
      setEndReachedOffset(e)
    }, 200)
  }, [])

  return { isAtTop, atTopStateChange, onEndReached }
}

import { useCallback } from 'react'
import { AxiosError } from 'axios'

import { errorBoundary } from '@/services/errorBoundary'

import { ResponseType, useDailyLimitToast } from './useDailyLimitToast'

export const useErrorBoundary = () => {
  const { handleDisplayToast } = useDailyLimitToast()

  const handleError = useCallback(
    (
      error: { response: ResponseType } | string | AxiosError<never> | Error,
      callback: () => void = undefined
    ) => {
      if (typeof error === 'object' && (error as AxiosError)?.response)
        handleDisplayToast((error as AxiosError)?.response)

      callback?.()
      errorBoundary(error)
    },
    [handleDisplayToast]
  )

  return {
    handleError,
  }
}

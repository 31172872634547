import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { UserInfo } from '@/services/user'
import { getWallet } from '@/services/wallet'
import { tokenToString } from '@/utils/token'
import { compactNumbers } from '@/utils/utils'

import { QueryKeys } from '@/enums'

export type FeeResult = {
  fee: string
  amount: string
  total: string
  newBalance: string
  executable: boolean
  errorMessage?: string
  maxAmount: string
  minAmount: string
}

export const useWallet = ({
  userInfo,
  enabled,
}: {
  userInfo: UserInfo
  enabled?: boolean
}) => {
  const {
    data: wallet,
    refetch: refetchWallet,
    isFetching: isFetchingWallet,
    isError: hasWalletError,
  } = useQuery({
    queryKey: [QueryKeys.Wallet, userInfo?.id],
    queryFn: async () => getWallet(userInfo?.id),
    enabled,
  })

  const isZeroBalance = useMemo(() => {
    const tokenValue = Number(tokenToString(wallet?.totalBalance ?? '0'))

    return compactNumbers(Math.floor(tokenValue)) === '0'
  }, [wallet?.totalBalance])

  return {
    wallet,
    isFetchingWallet,
    isZeroBalance,
    refetchWallet,
    hasWalletError,
  }
}

import { dehydrate, QueryClient } from '@tanstack/react-query'

import { newrelicNoticeError } from '@/lib/tracking'

export const customDehydrate = (queryClient: QueryClient) => {
  try {
    const dehydratedState = dehydrate(queryClient)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dehydratedState.queries.forEach((query: any) => {
      query.queryDefaults = queryClient.getQueryDefaults(query.queryKey)
    })

    return dehydratedState
  } catch (error) {
    newrelicNoticeError({ error })
  }
}

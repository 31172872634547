import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Currency } from '@/components/atoms/Currency'

import { IStakeVaultTransactionItem } from '@/services/stake/getStakeTransactions'
import { IStakeTransactionItem } from '@/services/wallet/getTransactions'
import { toTokenCentsString } from '@/utils/token'
interface StakeItemAmountProps {
  item: IStakeVaultTransactionItem | IStakeTransactionItem
  isStakeVault?: boolean
}

export const HistoryListStakeItemAmount: FC<StakeItemAmountProps> = ({
  item,
  isStakeVault,
}) => {
  const { t } = useTranslation('common')
  const isReceived = BigInt(item?.amount) > 0n

  const itemTitle = isStakeVault
    ? 'wallet.stakeVault.stakedAmount'
    : isReceived
    ? 'wallet.transactionHistory.stakeDistribution'
    : 'wallet.transactionHistory.stakeLocked'

  const amountClass = isStakeVault ? 'text-base' : ' text-sm'

  return (
    <div className="flex">
      <span className="text-sm">
        <Trans t={t} i18nKey={itemTitle} />
      </span>
      <div className="flex justify-end items-end flex-1 font-bold">
        <span className={amountClass} title={toTokenCentsString(item?.amount)}>
          <Currency value={item?.amount} isHighlighted />
        </span>
      </div>
    </div>
  )
}

import { FC, memo } from 'react'

export interface HeartComponentProps {
  className?: string
}

const HeartComponent: FC<HeartComponentProps> = ({ className }) => {
  return (
    <svg
      width="120"
      height="111"
      viewBox="0 0 120 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        className="fill-danger"
        d="M89.6221 0.000244141C69.4848 0.000244141 59.5588 20.0536 59.5588 20.0536C59.5588 20.0536 49.6329 0.000244141 29.4956 0.000244141C13.1302 0.000244141 0.170672 13.8308 0.00317127 30.3342C-0.338033 64.591 26.9056 88.9528 56.7672 109.426C57.5904 109.992 58.5631 110.294 59.5588 110.294C60.5546 110.294 61.5273 109.992 62.3505 109.426C92.209 88.9528 119.453 64.591 119.115 30.3342C118.947 13.8308 105.987 0.000244141 89.6221 0.000244141Z"
      />
    </svg>
  )
}

export const Heart = memo(HeartComponent)

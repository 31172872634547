import { useQuery } from '@tanstack/react-query'

export const useCachedAvatar = () => {
  const { data: cachedAvatar } = useQuery<string>({
    queryKey: ['cached_user_avatar'],
    queryFn: () => '',
    enabled: false,
  })

  return cachedAvatar
}

import { useQuery } from '@tanstack/react-query'

import { get } from '@/services/awsAmplify'

import { QueryKeys } from '@/enums'

export type ChatStatus = 'LOCKED' | 'UNLOCKED' | 'FREE'

export type GetChatData = {
  status: ChatStatus
  price?: number
}

const getChat = async ({ queryKey }): Promise<GetChatData> => {
  const userId = queryKey[1]

  return get({
    path: `/chat/status/${userId}`,
  })
}

interface UseChatParams {
  userId: UUID
  enabled?: boolean
}

export const useChat = ({ userId, enabled }: UseChatParams) =>
  useQuery({
    queryKey: [QueryKeys.ChatStatus, userId],
    queryFn: getChat,
    enabled: !!userId && enabled,
  })

import { confirmSignIn } from 'aws-amplify/auth'

import { authSignIn, authSignOut, post } from '@/services/awsAmplify'
import { assertNever as shouldNeverReachHere } from '@/utils/utils'

import { AuthError } from '@/enums'

export async function ssoSignIn<T extends SocialMedia>(
  viaSocialMedia: T,
  credentials: Credentials,
  signUpMetadata: SignUpMetadata
) {
  try {
    const response = await ensureSignIn(
      viaSocialMedia,
      credentials,
      signUpMetadata
    )
    const challengeResponse = JSON.stringify(response?.response)

    try {
      await confirmSignIn({
        challengeResponse,
        options: {
          clientMetadata: {
            ssoProviderName: viaSocialMedia,
            ssoToken: credentials?.authToken,
            ...signUpMetadata,
          },
        },
      })

      return response
    } catch (error) {
      console.error(`confirmSignIn sso signing in: ${error}`)
    }
  } catch (error) {
    console.error(`ensureSignIn sso signing in: ${error}`)
  }
}

async function ensureSignIn(
  viaSocialMedia: SocialMedia,
  credentials: Credentials,
  signUpMetadata: SignUpMetadata
) {
  const response = await signIn(credentials?.username)
  switch (response?._tag) {
    case 'Success':
      return response
    case 'UserNotFound':
      return await signUp(viaSocialMedia, credentials, signUpMetadata)
    default:
      shouldNeverReachHere(response)
  }
}

async function signIn(username: string) {
  try {
    await authSignOut({
      global: false,
    }).catch((error) => {
      console.error(`Failed to sign out ${error}`)
    })

    const signInResponse = await authSignIn({
      username,
      authFlowType: 'CUSTOM_WITHOUT_SRP',
    }).then((response) => new Success(response))
    return signInResponse
  } catch (error) {
    if (error?.toString().includes(AuthError.UserNotFound)) {
      return new UserNotFound()
    }
    throw error
  }
}

async function signUp(
  viaSocialMedia: SocialMedia,
  credentials: Credentials,
  signUpMetadata: SignUpMetadata
) {
  await post({
    endpoint: 'notAuthenticated',
    path: '/users',
    params: {
      ssoUserId: signUpMetadata?.userId,
      email: signUpMetadata?.email,
      metadata: {
        ssoProviderName: viaSocialMedia,
        ssoToken: credentials?.authToken,
        ...signUpMetadata,
      },
    },
    headers: {
      Authorization: `Bearer empty`,
    },
  })
  const response = await authSignIn({
    username: credentials?.username,
    authFlowType: 'CUSTOM_WITHOUT_SRP',
  })
  return new NewRegistration(response)
}

type SocialMedia = 'google' | 'facebook' | 'apple'
interface Credentials {
  authToken: string
  username: string
}

export interface SignUpMetadata {
  userId?: string
  refUsername: string
  email: string
  [k: string]: unknown
}

class Success {
  readonly _tag = 'Success'
  constructor(readonly response: unknown) {}
}

class UserNotFound {
  readonly _tag = 'UserNotFound'
}

class NewRegistration {
  readonly _tag = 'NewRegistration'
  constructor(readonly response: unknown) {}
}

export const FacebookAuthError = {
  'LoginManager.logIn failed': 'facebookLoginFailed',
  'Request failed with status code 403': 'facebookLoginFailed',
  'PreSignUp failed with error Response code 400 (Bad Request).':
    'facebookLoginFailed',
  'Email is undefined': 'noEmailFacebookSignin',
}

export interface googleUserBasicProfile {
  aud: string
  azp: string
  email: string
  email_verified: boolean
  exp: number
  family_name: string
  given_name: string
  iat: number
  iss: string
  jti: string
  name: string
  nbf: number
  picture: string
  sub: string
}

export interface googleCredentialResponse {
  clientId: string
  credential: string
  select_by: string
}

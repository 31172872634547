import { FC, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IonSpinner } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import cx from 'classnames'

import { Category } from '@/services/categories'
import {
  SubscribedCategoriesQueryKey,
  useSubscribeCategory,
  useSubscribedCategories,
  useUnsubscribeCategory,
} from '@/lib/hooks/categories'

type SubscribeCategoryButtonProps = {
  categoryId: Category['id']
}

const SubscribeCategoryButtonComponent: FC<SubscribeCategoryButtonProps> = ({
  categoryId,
}) => {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const { data: subscribedCategories, error } = useSubscribedCategories()
  const isSubscribed = useMemo(
    () => subscribedCategories?.subscriptions?.includes(categoryId),
    [subscribedCategories, categoryId]
  )

  const { mutate: subscribeCategory, isPending: isLoadingSubscribe } =
    useSubscribeCategory({
      onSuccess: (data) => {
        queryClient.setQueryData([SubscribedCategoriesQueryKey], data)
      },
    })
  const { mutate: unsubscribeCategory, isPending: isLoadingUnsubscribe } =
    useUnsubscribeCategory({
      onSuccess: (data) => {
        queryClient.setQueryData([SubscribedCategoriesQueryKey], data)
      },
    })
  const isLoading = isLoadingSubscribe || isLoadingUnsubscribe

  const handleOnClick = useCallback(() => {
    isSubscribed
      ? unsubscribeCategory(categoryId)
      : subscribeCategory([categoryId])
  }, [isSubscribed, categoryId, subscribeCategory, unsubscribeCategory])

  if (!subscribedCategories || error) return null

  return (
    <div
      className={cx(
        `h-[1.25rem] w-[7rem] flex justify-center items-center rounded-full border-[1px] border-cool-gray-200 text-sm cursor-pointer`,
        {
          'cursor-default': isLoading,
          'font-bold bg-cool-gray-200 text-cool-gray-900': !isSubscribed,
          'font-semibold text-cool-gray-200': isSubscribed,
        }
      )}
      onClick={isLoading ? undefined : handleOnClick}
    >
      {isLoading ? (
        <IonSpinner
          className="h-[0.875rem]"
          color={isSubscribed ? 'medium' : 'dark'}
        />
      ) : isSubscribed ? (
        t('discover.subscribed')
      ) : (
        t('discover.subscribe')
      )}
    </div>
  )
}

export const SubscribeCategoryButton = memo(SubscribeCategoryButtonComponent)

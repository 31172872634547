import { memo } from 'react'

const SingleVideoComponent = () => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.81643 0.918945H15.1835C16.0965 0.918945 16.8367 1.66953 16.8367 2.59542V12.6542C16.8367 13.5801 16.0965 14.3307 15.1835 14.3307H2.81643C1.90342 14.3307 1.16327 13.5801 1.16327 12.6542V2.59542C1.16327 1.66953 1.90342 0.918945 2.81643 0.918945ZM7.20828 11.38C7.02199 11.38 6.85003 11.337 6.70673 11.251C6.4058 11.079 6.20517 10.7781 6.13352 10.3912C5.86125 8.97253 5.86125 6.29281 6.13352 4.97444C6.20517 4.58753 6.42013 4.2866 6.70673 4.12897C6.86436 4.04299 7.03632 4 7.22261 4C7.38024 4 7.52354 4.02866 7.68117 4.08598C9.08552 4.61619 11.364 6.02054 12.3671 6.79436C12.668 7.02364 12.8257 7.32457 12.84 7.65416C12.84 7.99809 12.6824 8.32768 12.3814 8.58562C11.407 9.3881 9.25748 10.6921 7.6955 11.294C7.53787 11.3513 7.38024 11.38 7.22261 11.38H7.20828Z"
      fill="white"
    />
  </svg>
)

export const SingleVideo = memo(SingleVideoComponent)

import { createContext, Dispatch, useReducer } from 'react'

import Reducer, { IState } from './reducer'

interface contextState {
  state: IState
  dispatch: Dispatch<unknown>
}

const initialState = {
  user: null,
  muteVideo: true,
  hasNotifications: {
    init: true,
    value: false,
  },
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
  desktopUrl: '',
  isVideoPlaybackAllowed: true,
  uploadProgress: {},
}

// eslint-disable-next-line react/prop-types
const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState)

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  )
}

export const Context = createContext({} as contextState)

export default Store

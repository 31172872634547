import { FC, memo, useMemo } from 'react'
import cx from 'classnames'

import { appConfig } from '@/services/config'
import { useWhiteList } from '@/lib/hooks/useWhiteList'

const OriginChipComponent: FC<{
  className?: string
  hasVoteLabel?: boolean
  origin: string
}> = ({ className, hasVoteLabel, origin }) => {
  const { isWhitelistedUser } = useWhiteList()

  const label = useMemo(() => {
    if (appConfig.hideRecommendationsChip || !isWhitelistedUser) return

    return origin ? origin.split(':')[0] : 'USER CONTENT'
  }, [isWhitelistedUser, origin])

  if (!label) {
    return null
  }

  return (
    <span
      className={cx(
        'text-xxs leading-none uppercase border bg-white text-black font-normal px-1.5 py-0.5 rounded-full',
        className,
        { 'mt-1': !hasVoteLabel }
      )}
    >
      {label}
    </span>
  )
}

export const OriginChip = memo(OriginChipComponent)

import { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonContent, IonTitle, useIonToast } from '@ionic/react'

import { BackButton } from '@components/atoms/BackButton'
import { Title } from '@components/atoms/Title'
import { Loading } from '@/components/atoms/Loading'
import { TimelineError } from '@/components/organisms/TimelineError'
import { Main } from '@/components/templates/main'
import { Header } from './components/Header'
import { StakingVaultHistory } from './components/StakingVaultHistory'
import { XPMultipliers } from './components/XPMultipliers'

import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useVirtualList } from '@/lib/hooks/useVirtualList'
import { useTransactions, useWallet } from '@/lib/hooks/wallet'
import { WALLET } from '@/utils/route'

import { TransactionsType } from '@/enums'

export const StakeVault: FC = () => {
  const { t } = useTranslation('common')
  const { data: authUser } = useUserInfo()
  const [presentToast] = useIonToast()

  const { wallet, refetchWallet } = useWallet({
    userInfo: authUser,
  })

  const {
    transactions,
    hasGetTransactionsNextPage,
    fetchTransactionsNextPage,
    refetchFirstTransactionsPage,
    isGetTransactionsLoading,
    hasTransactionsError,
    isSuccess,
  } = useTransactions({
    userInfo: authUser,
    transactionsType: TransactionsType.Stake,
  })

  const refetchStake = useCallback(async () => {
    await Promise.all([refetchWallet(), refetchFirstTransactionsPage()])
  }, [refetchWallet, refetchFirstTransactionsPage])

  useEffect(() => {
    if (transactions?.length && hasTransactionsError) {
      presentToast({
        message: t('timeline.errors.stakeVault'),
        position: 'top',
        duration: 3000,
      })
    }
  }, [hasTransactionsError, transactions, presentToast, t])

  const { isAtTop, atTopStateChange, onEndReached } = useVirtualList({
    hasNextPage: hasGetTransactionsNextPage,
    fetchNextPage: fetchTransactionsNextPage,
  })

  const header = (
    <>
      <IonButtons slot="start">
        <BackButton defaultHref={WALLET} />
      </IonButtons>
      <IonTitle>
        <Title className="text-center my-0" level={3}>
          {t('wallet.stakeVault.stakeVault')}
        </Title>
      </IonTitle>
    </>
  )

  useEffect(() => {
    refetchWallet()
    refetchFirstTransactionsPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderContent = useCallback(() => {
    if (isGetTransactionsLoading) return <Loading />

    if (hasTransactionsError) {
      return <TimelineError translation={'timeline.errors.stakeVault'} />
    }

    if (isSuccess && transactions?.length === 0) return <XPMultipliers />

    return (
      <>
        <Header stakedBalance={wallet?.stakedBalance} />
        <IonContent>
          <StakingVaultHistory
            transactions={transactions}
            atTopStateChange={atTopStateChange}
            onEndReached={onEndReached}
          />
        </IonContent>
      </>
    )
  }, [
    atTopStateChange,
    hasTransactionsError,
    isGetTransactionsLoading,
    isSuccess,
    onEndReached,
    transactions,
    wallet?.stakedBalance,
  ])

  return (
    <Main
      refetch={refetchStake}
      isRefetchActive={isAtTop}
      header={header}
      isPaddingEnabled={false}
    >
      {renderContent()}
    </Main>
  )
}

import { useCallback, useMemo } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Reaction } from 'getstream/lib/reaction'

import { IActivityItem } from '@/components/pages/Activity/interfaces'

import { postReaction } from '@/services/reactions'
import { useGetstreamClient } from '@/lib/hooks/useGetstreamClient'

import { CachedReaction, OwnReaction } from '@/enums'
import { QueryKeys } from '@/enums'

import { ResponseType } from '../useDailyLimitToast'
import { useErrorBoundary } from '../useErrorBoundary'

export const useReaction = (reactionActivityId: UUID, activity) => {
  const feedKeys: QueryKeys[] = useMemo(
    () => [
      QueryKeys.TimelineFeed,
      QueryKeys.TrendingFeed,
      QueryKeys.SubscriptionFeed,
      QueryKeys.ExclusiveFeed,
    ],
    []
  )
  const { client } = useGetstreamClient()
  const queryClient = useQueryClient()
  const { handleError } = useErrorBoundary()
  const reactionQueryKey = useMemo(
    () => [QueryKeys.Reaction, reactionActivityId],
    [reactionActivityId]
  )
  const reaction = useMemo(() => {
    const cachedReactions =
      queryClient.getQueryData<CachedReaction>(reactionQueryKey)

    const reactionsCount = activity.reaction_counts?.like || 0

    const ownReactions = activity.own_reactions?.like?.filter(
      (reaction) => !!reaction.id
    )

    return (
      cachedReactions || {
        reactionsCount,
        ownReactions: ownReactions || [],
        isLoading: false,
      }
    )
  }, [activity, queryClient, reactionQueryKey])

  const { mutate: mutatePostReaction } = useMutation({
    mutationFn: postReaction,
    onMutate: () => {
      queryClient.setQueryData<CachedReaction>(reactionQueryKey, () => {
        return reaction
      })

      addReactionInCache({
        ownReactions: [{}],
      })
    },
    onSuccess: (reaction) => {
      addReactionInCache(reaction as OwnReaction)
    },
    onError: (error: { response: ResponseType }) =>
      handleError(error, () => removeReactionInCache(undefined)),
  })

  const { mutate: mutateDeleteReaction } = useMutation({
    mutationFn: ({ reactionId }: { reactionId: UUID }) =>
      client.reactions.delete(reactionId),
    onMutate: ({ reactionId }) => {
      queryClient.setQueryData<CachedReaction>(reactionQueryKey, () => reaction)
      removeReactionInCache(reactionId)
      return reaction
    },
    onError: (error: { response: ResponseType }, _variables, context) =>
      handleError(error, () => addReactionInCache(context)),
  })

  const addReactionInFeedsCache = useCallback(
    (reaction) => {
      feedKeys.forEach((feedKey) => {
        const queryData: { pages: { data: unknown[] }[] } =
          queryClient.getQueryData([feedKey])

        return queryData?.pages?.map((page) => {
          return page?.data?.map((activity: IActivityItem) => {
            if (activity.id === reactionActivityId) {
              activity.reaction_counts.like += 1
              activity.own_reactions.like = [reaction] as Reaction[]
            }
            return activity
          })
        })
      })
    },
    [reactionActivityId, queryClient, feedKeys]
  )

  const removeReactionInFeedsCache = useCallback(
    (reactionId) => {
      feedKeys.forEach((feedKey) => {
        const queryData: { pages: { data: unknown[] }[] } =
          queryClient.getQueryData([feedKey])

        return queryData?.pages?.map((page) => {
          return page?.data?.map((activity: IActivityItem) => {
            if (activity.id === reactionActivityId) {
              activity.reaction_counts.like -= 1
              activity.own_reactions.like = activity.own_reactions.like.filter(
                (reaction) => reaction.id !== reactionId
              )
            }
            return activity
          })
        })
      })
    },
    [reactionActivityId, queryClient, feedKeys]
  )

  const addReactionInCache = useCallback(
    (reaction: OwnReaction) => {
      queryClient.setQueryData<CachedReaction>(
        reactionQueryKey,
        (cachedReaction) => {
          const updatedOwnReactions = [...cachedReaction.ownReactions]
          const lastOwnReaction =
            updatedOwnReactions[updatedOwnReactions.length - 1]

          if (lastOwnReaction && !lastOwnReaction.id) updatedOwnReactions.pop()

          return {
            reactionsCount:
              lastOwnReaction && !lastOwnReaction.id
                ? cachedReaction.reactionsCount
                : cachedReaction.reactionsCount + 1,
            ownReactions: [...updatedOwnReactions, reaction],
            isLoading: cachedReaction.isLoading,
          }
        }
      )

      addReactionInFeedsCache(reaction)
    },
    [reactionQueryKey, queryClient, addReactionInFeedsCache]
  )
  const removeReactionInCache = useCallback(
    (reactionId: UUID | undefined) => {
      queryClient.setQueryData<CachedReaction>(
        reactionQueryKey,
        (cachedReaction) => {
          return {
            reactionsCount: Math.max(cachedReaction.reactionsCount - 1, 0),
            ownReactions: cachedReaction.ownReactions.filter(
              (reaction) => reaction.id !== reactionId
            ),
            isLoading: cachedReaction.isLoading,
          }
        }
      )

      removeReactionInFeedsCache(reactionId)
    },
    [reactionQueryKey, queryClient, removeReactionInFeedsCache]
  )

  return {
    addReaction: mutatePostReaction,
    deleteReaction: mutateDeleteReaction,
    reaction,
    addReactionInCache,
    removeReactionInCache,
  }
}

import { FC, memo } from 'react'
import cx from 'classnames'

type StreakProgressCircleProps = {
  number: number
  isChecked: boolean
}

const StreakProgressCircleComponent: FC<StreakProgressCircleProps> = ({
  number,
  isChecked,
}) => {
  return (
    <div
      className={cx(
        'flex w-[10vw] max-w-[4rem] h-[10vw] max-h-[4rem] justify-center items-center pt-full rounded-full relative',
        { 'bg-cool-gray-500': !isChecked, 'bg-white': isChecked }
      )}
    >
      {!isChecked ? (
        <p className="text-[3vw] sm:text-xl">{number}</p>
      ) : (
        <svg
          width="60%"
          height="60%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.25 6.75L9.75 17.25L4.5 12"
            stroke="#0E1117"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  )
}

export const StreakProgressCircle = memo(StreakProgressCircleComponent)

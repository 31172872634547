import { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SearchInput } from '@/components/molecules/SearchInput'

interface IExplorerForm {
  onSubmit: (data) => Promise<void>
  className?: string
  searchQueryValue?: string
}

export const ExplorerForm: FC<IExplorerForm> = ({
  onSubmit,
  className,
  searchQueryValue,
}) => {
  const { t } = useTranslation('common')
  const defaultValues = {
    q: '',
  }

  const { control, reset, handleSubmit, getValues, setValue } = useForm({
    defaultValues,
    mode: 'onChange',
  })

  useEffect(() => {
    setValue('q', searchQueryValue)
  }, [searchQueryValue, setValue])

  const isSearchQueryValid = (data) => {
    if (data === '' || data?.length > 2) {
      onSubmit({ q: data })
    }
    return true
  }

  return (
    <form
      className={className}
      onSubmit={handleSubmit(async () => await onSubmit({ q: getValues('q') }))}
    >
      <div className="px-3 md:px-0 pb-3 bg-dark">
        <Controller
          control={control}
          name="q"
          rules={{
            validate: {
              isSearchQueryValid,
            },
          }}
          render={({ field }) => (
            <div className="relative">
              <SearchInput
                reset={reset}
                onChange={field.onChange}
                onSubmit={onSubmit}
                value={field.value}
                placeholder={t('common.searchExplore')}
              />
            </div>
          )}
        />
      </div>
    </form>
  )
}

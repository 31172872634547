import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  getSubscribedCategories,
  ISubscribedCategoriesResult,
} from '@/services/categories'

export const SubscribedCategoriesQueryKey = 'subscribed_categories'

export const useSubscribedCategories = (
  options?: Omit<
    UseQueryOptions<unknown, unknown, ISubscribedCategoriesResult>,
    'queryKey'
  >
) =>
  useQuery({
    queryKey: [SubscribedCategoriesQueryKey],
    queryFn: getSubscribedCategories,
    ...options,
  })

import { post } from '@/services/awsAmplify'

export interface IStakesEstimate {
  apyInPercentage: number
  durationInDays: number
  minStakeAmountInRpk: number
  amount: bigint
  stakeDate: string
  releaseDate: string
  mainWalletBalance: string
}

export enum StakeNetworkError {
  INSUFFICIENT_FUNDS = 'insufficientFunds',
  INSUFFICIENT_STAKE_AMOUNT = 'insufficientStakeAmount',
  NON_POSITIVE_AMOUNT = 'nonPositiveAmount',
  STAKE_POOL_EXHAUSTED = 'stakePoolExhausted',
  STAKE_RULE_DEACTIVATED = 'stakeRuleDeactivated',
  STAKE_RULE_NOT_FOUND = 'stakeRuleNotFound',
  USER_DEACTIVATED = 'userDeactivated',
  USER_NOT_FOUND = 'userNotFound',
  USER_NOT_KYC_VERIFIED = 'userNotKYCVerified',
  WALLET_NOT_FOUND = 'walletNotFound',
  WALLET_DOES_NOT_BELONG_TO_ACTOR = 'walletDoesNotBelongToActor',
  STAKE_INCORRECT_STATUS = 'stakeIncorrectStatus',
  STAKE_DOES_NOT_BELONG_TO_ACTOR = 'stakeDoesNotBelongToActor',
  STAKE_NOT_FOUND = 'stakeNotFound',
}

export const stakesEstimate = ({
  amount,
  ruleId,
}: {
  amount: string
  ruleId: string
}): Promise<IStakesEstimate> =>
  post({
    path: '/stakes/estimate',
    params: {
      amount,
      ruleId,
    },
  }) as unknown as Promise<IStakesEstimate>

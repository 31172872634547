import { FC } from 'react'

import { MultipleImages } from '@/components/atoms/assets/MultipleImages'
import { MultipleVideos } from '@/components/atoms/assets/MultipleVideos'
import { SingleVideo } from '@/components/atoms/assets/SingleVideo'
import { VotesLabel } from '@/components/atoms/VotesLabel'

import { MediaType } from '@/lib/hooks/useTrackViewedActivity/useTrackViewedActivity'

import { ActivityFeedVerb } from '@/enums'

type GridItemIconProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activity: any
}

export const GridItemIcon: FC<GridItemIconProps> = ({ activity }) => {
  switch (activity.verb) {
    case ActivityFeedVerb.ContestParticipate: {
      const count = activity?.votesCount

      return <VotesLabel small count={count} />
    }
    case ActivityFeedVerb.Post: {
      const mediaType = activity?.object?.data?.media.some(
        (media) => media.type === MediaType.Video
      )
        ? MediaType.Video
        : MediaType.Image

      const hasOneMedia =
        activity?.object?.data?.media?.length === 1 ||
        activity?.attachments?.images?.length === 1

      if (mediaType === MediaType.Image && !hasOneMedia)
        return <MultipleImages />

      if (mediaType === MediaType.Video) {
        return hasOneMedia ? <SingleVideo /> : <MultipleVideos />
      }

      return null
    }
    case ActivityFeedVerb.RepliedToConversation:
    case ActivityFeedVerb.StartedConversation: {
      let mediaType = activity?.object?.data?.mediaType
      if (!mediaType) {
        mediaType = activity?.object?.data?.replies?.some(
          (reply) => reply.mediaType === MediaType.Video
        )
          ? MediaType.Video
          : MediaType.Image
      }

      const hasReplies = !!activity?.object?.data?.replies?.length

      if (mediaType === MediaType.Image && hasReplies) return <MultipleImages />

      if (mediaType === MediaType.Video) {
        return hasReplies ? <MultipleVideos /> : <SingleVideo />
      }

      return null
    }
    default:
      return null
  }
}

import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BuyCurrencyPopup } from '@/components/organisms/BuyCurrencyPopup'

import { appConfig } from '@/services/config'
import { Tracking, TrackingEvent } from '@/lib/tracking'

const BuyCurrencyComponent = () => {
  const { t } = useTranslation('common')
  const [isBuyCurrencyConfirmationOpen, setBuyCurrencyConfirmationOpen] =
    useState(false)

  const onClick = useCallback(() => {
    setBuyCurrencyConfirmationOpen(true)
    Tracking.triggerEvent(TrackingEvent.BuyButtonClicked)
  }, [])

  return (
    <>
      <button
        className="bg-gradient-to-r from-[#7000F0] to-[#9603F1] text-white text-xs font-semibold px-3 py-1 rounded-full"
        onClick={onClick}
      >
        {t('buyCurrency', { currency: appConfig.currency })}
      </button>
      <BuyCurrencyPopup
        setBuyCurrencyConfirmationOpen={setBuyCurrencyConfirmationOpen}
        isBuyCurrencyConfirmationOpen={isBuyCurrencyConfirmationOpen}
      />
    </>
  )
}

export const BuyCurrency = memo(BuyCurrencyComponent)

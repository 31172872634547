import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { TwitterIcon } from '@/components/atoms/assets/TwitterIcon'

interface IConnectProps {
  authUrl: string
}

export const Connect: FC<IConnectProps> = ({ authUrl }) => {
  const { t } = useTranslation('common')

  return (
    <div>
      <div className="flex flex-col justify-center items-center text-center pl-14 pr-14">
        <span className="mt-12 text-2xl font-bold">
          {t('registration.profile.twitterAccountConnection')}
        </span>
        <span className="mt-10 text-base font-normal">
          {t('registration.profile.twitterConnectionDescribe')}
        </span>
        <div
          onClick={() => window.location.assign(authUrl)}
          className="flex mt-10 custom-item cursor-pointer 
        text-black twitter-button pt-2 pb-2 pl-6 pr-9"
        >
          <TwitterIcon isBlack />
          <span className="ml-1 font-semibold">
            {t('registration.profile.connectAccount')}
          </span>
        </div>
      </div>
    </div>
  )
}

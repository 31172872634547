import { memo } from 'react'

const PersonComponent = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 19.9917C20.4183 19.9917 24 16.4114 24 11.995C24 7.57857 20.4183 3.99834 16 3.99834C11.5817 3.99834 8 7.57857 8 11.995C8 16.4114 11.5817 19.9917 16 19.9917Z"
      className="stroke-white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M3.875 26.9888C5.10367 24.8611 6.87104 23.0942 8.99944 21.8657C11.1278 20.6373 13.5423 19.9906 16 19.9906C18.4577 19.9906 20.8722 20.6373 23.0006 21.8657C25.129 23.0942 26.8963 24.8611 28.125 26.9888"
      className="stroke-white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const PersonOutlineComponent = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.9875 26.5C27.1216 23.2857 24.1349 20.8732 20.6 19.725C22.2943 18.7175 23.611 17.1815 24.3478 15.3531C25.0845 13.5246 25.2006 11.5049 24.6782 9.60408C24.1557 7.70329 23.0236 6.0266 21.4558 4.8316C19.8881 3.63659 17.9713 2.98938 16 2.98938C14.0287 2.98938 12.1119 3.63659 10.5442 4.8316C8.97638 6.0266 7.8443 7.70329 7.32184 9.60408C6.79939 11.5049 6.91545 13.5246 7.6522 15.3531C8.38896 17.1815 9.70565 18.7175 11.4 19.725C7.86512 20.8732 4.87842 23.2857 3.0125 26.5C2.92089 26.6507 2.87244 26.8237 2.87244 27C2.87244 27.1764 2.92089 27.3493 3.0125 27.5C3.09797 27.6533 3.22321 27.7806 3.37501 27.8686C3.52681 27.9566 3.69954 28.002 3.875 28H28.125C28.3005 28.002 28.4732 27.9566 28.625 27.8686C28.7768 27.7806 28.902 27.6533 28.9875 27.5C29.0791 27.3493 29.1276 27.1764 29.1276 27C29.1276 26.8237 29.0791 26.6507 28.9875 26.5V26.5Z"
      className="fill-white stroke-white"
    />
  </svg>
)

export const Person = memo(PersonComponent)
export const PersonOutline = memo(PersonOutlineComponent)

import { useEffect, useRef, useState } from 'react'

export const AnimateCounter = ({
  start,
  end,
  duration,
}: {
  start: number
  end: number
  duration: number
}) => {
  const [value, setValue] = useState(start)
  const startTimestampRef = useRef<number | null>(null)

  useEffect(() => {
    const step = (timestamp: number) => {
      if (!startTimestampRef.current) startTimestampRef.current = timestamp
      const progress = Math.min(
        (timestamp - startTimestampRef.current) / duration,
        1
      )
      const animatedValue = Math.floor(progress * (end - start) + start)
      setValue(animatedValue)
      if (progress < 1) {
        window.requestAnimationFrame(step)
      }
    }

    window.requestAnimationFrame(step)

    return () => {
      startTimestampRef.current = null
    }
  }, [start, end, duration])

  return <>{end < 100 ? end : value}</>
}

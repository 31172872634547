import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonItem, IonLabel } from '@ionic/react'

import { InfoSection } from '@/components/molecules/InfoSection'

import { appConfig } from '@/services/config'

export const SocialsListDescription: FC = () => {
  const { t } = useTranslation('common')

  const { creatorToken } = appConfig

  return (
    <InfoSection
      title={t('tokenSocialsConnect.mediaAccountsInfoTitle')}
      subTitle={t('tokenSocialsConnect.mediaAccountsInfoSubTitle', {
        creatorToken,
      })}
    >
      <IonItem>
        <IonLabel className="ion-text-wrap">
          <span className="font-bold mr-1">{t('tokenSocialsConnect.tip')}</span>
          <span className="text-cool-gray-200 text-sm sm:text-base">
            {t('tokenSocialsConnect.tipInfo', { creatorToken })}
          </span>
        </IonLabel>
      </IonItem>
    </InfoSection>
  )
}

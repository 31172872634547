import { memo } from 'react'

const EditComponent = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill-white"
        d="M17.5 5.99221L14.0078 2.50002C13.8923 2.38333 13.7548 2.2907 13.6033 2.22748C13.4517 2.16426 13.2892 2.13171 13.125 2.13171C12.9608 2.13171 12.7983 2.16426 12.6467 2.22748C12.4952 2.2907 12.3577 2.38333 12.2422 2.50002L2.86719 11.875C2.74361 11.9963 2.64767 12.1429 2.58594 12.3047L2.57031 12.3438C2.52369 12.4768 2.49992 12.6168 2.5 12.7578V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.875C17.0408 17.5 17.1997 17.4342 17.3169 17.317C17.4342 17.1998 17.5 17.0408 17.5 16.875C17.5 16.7093 17.4342 16.5503 17.3169 16.4331C17.1997 16.3159 17.0408 16.25 16.875 16.25H9.00781L17.5 7.75783C17.7319 7.5225 17.8619 7.20539 17.8619 6.87502C17.8619 6.54464 17.7319 6.22753 17.5 5.99221V5.99221ZM11.25 5.25783L12.5547 6.56252L5.3125 13.8047L4.00781 12.5L11.25 5.25783ZM3.75 16.25V14.0078L5.99219 16.25H3.75ZM7.5 15.9922L6.19531 14.6875L13.4375 7.44533L14.7422 8.75002L7.5 15.9922Z"
        fillOpacity="0.25"
      />
    </svg>
  )
}

export const Edit = memo(EditComponent)

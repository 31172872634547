import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonItem } from '@ionic/react'
import throttle from 'lodash/throttle'

import { XPScoreHeader } from '@/components/atoms/XPScoreHeader'
import { InfoSection } from '@/components/molecules/InfoSection'
import { StakeButton } from '@/components/molecules/StakeButton'
import { OpenAppStreakComponent } from '@/components/organisms/OpenAppStreakComponent'
import { EarnXP } from './components/EarnXP'
import { XPMultiplierList } from './components/XPMultiplierList'

import { useAccountInfo } from '@/services/account'
import { appConfig } from '@/services/config'

interface XPWalletProps {
  setIsRefetchActive: (isRefetchActive: boolean) => void
}

export const XPWallet: FC<XPWalletProps> = ({
  setIsRefetchActive,
}: XPWalletProps) => {
  const { t } = useTranslation('common')

  const handleScroll = throttle((event) => {
    const scrollTop = event.target.scrollTop
    const isTop = scrollTop === 0
    setIsRefetchActive(isTop)
  }, 200)

  const {
    maxReward,
    hasHighestRewardMultiplier,
    data: accountInfo,
    refetch: refetchAccountInfo,
  } = useAccountInfo(undefined, {
    enabled: false,
  })

  return (
    <div
      className="pt-3 pb-2 h-full overflow-y-auto mx-auto max-w-[40rem]"
      onScroll={handleScroll}
    >
      <IonItem>
        <XPScoreHeader
          xp={accountInfo?.xp}
          badge={accountInfo?.badge}
          multiplier={accountInfo?.multiplier}
          hasHighestRewardMultiplier={hasHighestRewardMultiplier}
        />
      </IonItem>
      <OpenAppStreakComponent refetchAccountInfo={refetchAccountInfo} />
      <InfoSection
        title={t('XPWallet.whatIsXpQuestion')}
        subTitle={t('XPWallet.whatIsXpAnswer', {
          currency: appConfig.currency,
        })}
      >
        <IonItem>
          <EarnXP />
        </IonItem>
      </InfoSection>
      <InfoSection
        title={t('XPWallet.XPMultipliersQuestion', {
          currency: appConfig.currency,
        })}
        subTitle={
          maxReward?.xpMultiplier
            ? t('XPWallet.XPMultipliersAnswer', {
                currency: appConfig.currency,
                maxReward: `${Math.floor(maxReward.xpMultiplier / 100)}x`,
              })
            : null
        }
      >
        <IonItem className="mb-3">
          <StakeButton text={t('XPWallet.stakeNow')} />
        </IonItem>
        <IonItem className="mb-3">
          <XPMultiplierList
            multipliers={accountInfo?.stakedRpkForXpMultiplier}
          />
        </IonItem>
      </InfoSection>
    </div>
  )
}

import { FC, memo } from 'react'

export interface HexagonComponentProps {
  value: number
  className?: string
  suffix?: string
}

const HexagonComponent: FC<HexagonComponentProps> = ({
  value,
  className,
  suffix,
}) => {
  if (value > 20) {
    return (
      <svg
        className={className}
        width="39"
        height="44"
        viewBox="0 0 39 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="stroke-hexagon-outline-purple fill-hexagon-fill-purple"
          d="M17.7733 42.5345L17.7731 42.5344L2.68934 33.7512L2.68923 33.7511C1.64699 33.1444 1 32.0186 1 30.8212V13.1781C1 11.9807 1.64699 10.8549 2.68923 10.2481L2.68934 10.2481L17.7728 1.465C18.8385 0.844943 20.1635 0.845116 21.2278 1.46482C21.2279 1.46483 21.2279 1.46484 21.2279 1.46485L36.3106 10.2481L36.3108 10.2481C37.353 10.8549 38 11.9807 38 13.1781V30.8212C38 32.019 37.3525 33.1442 36.3106 33.7512C36.3106 33.7512 36.3105 33.7513 36.3105 33.7513L21.2278 42.5344L21.2277 42.5345C20.6955 42.8446 20.0986 43 19.5005 43C18.9024 43 18.3055 42.8446 17.7733 42.5345Z"
          strokeWidth="2"
        />
        <text
          className="text-sm fill-white font-bold"
          textAnchor="middle"
          x="50%"
          y={suffix ? '56%' : '61%'}
        >
          {value}
        </text>
        {suffix && (
          <text
            className="text-[0.35rem] fill-white"
            textAnchor="middle"
            x="51%"
            y="76%"
          >
            {suffix}
          </text>
        )}
      </svg>
    )
  }

  return (
    <svg
      className={className}
      width="30"
      height="34"
      viewBox="0 0 30 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke-hexagon-outline-purple fill-hexagon-fill-purple"
        d="M13.5371 33.1037L13.537 33.1037L1.9341 26.3166L1.93404 26.3166C1.04995 25.7996 0.5 24.8401 0.5 23.8164V10.183C0.5 9.15928 1.04995 8.19981 1.93404 7.68282L1.9341 7.68279L13.5369 0.895823C14.4399 0.368031 15.5617 0.368118 16.4637 0.895731C16.4637 0.895735 16.4638 0.89574 16.4638 0.895744L28.0659 7.68278L28.066 7.68282C28.9501 8.19981 29.5 9.15928 29.5 10.183V23.8164C29.5 24.8406 28.9495 25.7995 28.0659 26.3166C28.0659 26.3167 28.0658 26.3167 28.0658 26.3167L16.4637 33.1037L16.4637 33.1037C16.0124 33.3678 15.5066 33.5 15.0004 33.5C14.4942 33.5 13.9884 33.3678 13.5371 33.1037Z"
      />
      <text
        className="text-sm fill-white font-bold"
        textAnchor="middle"
        x={suffix ? '50%' : '51%'}
        y={suffix ? '60%' : '64%'}
      >
        {value}
      </text>
      {suffix && (
        <text
          className="text-[0.25rem] fill-white"
          textAnchor="middle"
          x="50%"
          y="78%"
        >
          {suffix}
        </text>
      )}
    </svg>
  )
}

export const Hexagon = memo(HexagonComponent)

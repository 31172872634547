import { FC, ForwardedRef, ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import omit from 'lodash/omit'

import { Loading } from '@/components/atoms/Loading'

import { Section } from '../../types'
import { GridPlaceholder } from '../grid/GridPlaceholder'
import { SlidePlaceholder } from '../SlidePlaceholder'

type RenderPostsSlideProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activities: any[]
  hasFeedError?: boolean
  isAuthUser: boolean
  forwardedRef: ForwardedRef<HTMLDivElement>
  children?: ReactNode
  assignedIndex: number
  section: Section
  sectionIndex: number
  isFetching: boolean
  isFetchingNextPage: boolean
}

export const RenderPostsSlide: FC<RenderPostsSlideProps> = (props) => {
  const { t } = useTranslation('common')

  const {
    activities,
    hasFeedError,
    isAuthUser,
    forwardedRef,
    assignedIndex,
    sectionIndex,
    isFetching,
    isFetchingNextPage,
  } = props

  if (!activities.length) {
    if (isFetching && !isFetchingNextPage) {
      return (
        <SlidePlaceholder>
          <Loading />
        </SlidePlaceholder>
      )
    }

    if (hasFeedError) {
      return (
        <div className="flex w-full h-[21.875rem] text-center justify-center items-center">
          <Trans i18nKey="profile.error.feed">{t('profile.error.feed')}</Trans>
        </div>
      )
    }

    return (
      <div className="flex w-full h-[21.875rem] flex-col text-center justify-center items-center">
        {isAuthUser ? (
          <>
            <h1 className="pb-6 text-[1.675rem]">
              <Trans i18nKey="profile.noContentTitle">
                {t('profile.noContentTitle')}
              </Trans>
            </h1>
            <p className="text-primary text-[1rem]">
              <Trans i18nKey="profile.noContentBody">
                {t('profile.noContentBody')}
              </Trans>
            </p>
          </>
        ) : (
          <>
            <div className="text-[5rem]">🦥</div>
            <p>{t('profile.empty')}</p>
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <section
        {...omit(props, [
          'activities',
          'hasFeedError',
          'isFeedFetching',
          'forwardedRef',
          'isAuthUser',
          'currentIndex',
          'assignedIndex',
          'sectionIndex',
          'section',
          'isFetching',
          'isFetchingNextPage',
        ])}
        ref={forwardedRef}
        className="flex flex-wrap h-full w-full"
      />
      <GridPlaceholder
        activities={activities.slice(0, 9)}
        hasBackground={sectionIndex !== assignedIndex}
      />
    </>
  )
}

import { useCallback, useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

import { get } from '@/services/awsAmplify'

import { QueryKeys } from '@/enums'

import { queryClient } from '../../queryClient'
import { useNetwork } from '../useNetwork'

import { Activity, ActivityTypeEnum } from '@/types/activity'

const allowedActivityNotificationTypes = [
  ActivityTypeEnum.UserGreeting,
  ActivityTypeEnum.RewardAccountEmailVerified,
  ActivityTypeEnum.RewardAccountPhoneVerified,
  ActivityTypeEnum.RewardGoalLikesGiven,
  ActivityTypeEnum.RewardMilestoneFollowerCount,
  ActivityTypeEnum.RewardContestContestantJoined,
  ActivityTypeEnum.RewardMilestoneReferralRpkReached,
  ActivityTypeEnum.RewardGoalVotesPerDayRefilled,
  ActivityTypeEnum.RewardGoalAppOpenedDaily,
  ActivityTypeEnum.RewardInviteAccepted,
  ActivityTypeEnum.UserFollowedAggregated,
  ActivityTypeEnum.RewardCustom,
  ActivityTypeEnum.RewardGoalAppOpenedStreak,
  ActivityTypeEnum.MentionedInConversation,
  ActivityTypeEnum.MentionedInPost,
  ActivityTypeEnum.MentionedInComment,
  ActivityTypeEnum.ReactionLikeConversation,
  ActivityTypeEnum.ReactionLikePost,
  ActivityTypeEnum.ReactionCommentPost,
  ActivityTypeEnum.ReactionCommentConversation,
  ActivityTypeEnum.ReactionCommentParticipant,
  ActivityTypeEnum.ReactionCommentChallenge,
  ActivityTypeEnum.UserFollow,
  ActivityTypeEnum.RewardCommunityContribution,
  ActivityTypeEnum.RewardCommunityModeration,
  ActivityTypeEnum.SubscriptionCancelledInsufficientFunds,
]

export const useActivityNotificationsFeed = () => {
  const { isOnline } = useNetwork()
  const queryKey = useMemo(() => [QueryKeys.Notifications], [])

  const { data, isLoading, fetchNextPage, hasNextPage, refetch, isFetching } =
    useInfiniteQuery({
      queryKey,
      queryFn: async ({ pageParam }) => {
        const params = omitBy({ next: pageParam || null }, isNil)

        const result = await get({
          path: '/notification-stream',
          params,
        })

        const data: Activity[] = result?.results || []

        return {
          data,
          next: result?.next || undefined,
        }
      },
      getNextPageParam: (lastPage) => lastPage?.next,
      initialPageParam: '',
    })

  const refetchFirstPage = useCallback(async () => {
    queryClient.setQueryData<{
      pages: unknown[]
      pageParams: (string | undefined)[]
    }>(queryKey, (data) => ({
      pages: data?.pages?.slice(0, 1) || [],
      pageParams: data?.pageParams?.slice(0, 1) || [],
    }))

    await refetch()
  }, [queryKey, refetch])

  const feed = useMemo(() => {
    return data?.pages
      .flatMap((page) => page.data)
      .filter((item) =>
        allowedActivityNotificationTypes.includes(item.type as ActivityTypeEnum)
      )
  }, [data])

  return {
    notifications: feed,
    isLoading: isLoading && isOnline,
    fetchNextPage,
    refetchFirstPage,
    hasNextPage,
    isFetching,
  }
}

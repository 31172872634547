import { IActivityItem } from '@/components/pages/Activity/interfaces'

import { queryClient } from '@/lib/queryClient'

import { QueryKeys } from '@/enums'
import { IContest } from '@/interfaces'

type FeedCache = {
  pages: { data: IActivityItem[]; next?: string }[]
  pageParams: (string | undefined)[]
}

type ContestCache = {
  pages: {
    results: {
      participants: IContest['participants']
    }
    lastKey?: string
    startAtCondition: Record<string, unknown>
  }[]
  pageParams: (string | undefined)[]
}

export const useReporting = () => {
  const removeActivityFromTimelineFeed = (activityId: UUID) => {
    if (activityId.length === 0) {
      return
    }

    queryClient.setQueryData<FeedCache>(
      [QueryKeys.TimelineFeed],
      (currentData) =>
        removeActivityFromCurrentData({ data: currentData, activityId })
    )
  }

  const removeActivityFromCurrentData = ({
    data: prevData,
    activityId,
  }: {
    data: FeedCache
    activityId: UUID
  }) => {
    return {
      ...prevData,
      pages: prevData?.pages?.map((page) => ({
        ...page,
        data: page.data?.filter((item) => item.id !== activityId),
      })),
    }
  }

  const removeContestFromContestDetail = ({
    activityId,
    contestId,
    userId,
  }: {
    activityId: UUID
    contestId: UUID
    userId: UUID
  }) => {
    queryClient.removeQueries({
      queryKey: [QueryKeys.ContestDetails, contestId, userId],
    })
    queryClient.setQueryData<ContestCache>(
      [QueryKeys.ContestDetails, contestId],
      (prevData) => {
        return {
          ...prevData,
          pages: prevData.pages.map((page) => ({
            ...page,
            results: {
              ...page.results,
              participants: page.results?.participants?.filter(
                (item) => item.activityId !== activityId
              ),
            },
          })),
        }
      }
    )
  }

  const removeUserFromCurrentData = ({
    data: prevData,
    userId,
  }: {
    data: FeedCache
    userId: UUID
  }) => {
    return {
      ...prevData,
      pages: prevData.pages.map((page) => ({
        ...page,
        data: page.data?.filter((item) => item.actor.id !== userId),
      })),
    }
  }

  const removeUserFromCategoryFeed = ({
    categoryId,
    userId,
  }: {
    categoryId?: string
    userId: UUID
  }) => {
    if (userId.length === 0) {
      return
    }

    if (categoryId && categoryId.length > 0) {
      queryClient.setQueryData<FeedCache>(
        [QueryKeys.CategoryFeed, categoryId],
        (data) => {
          return removeUserFromCurrentData({ data, userId })
        }
      )
      return
    }

    queryClient.setQueriesData<FeedCache>(
      {
        queryKey: [QueryKeys.CategoryFeed],
      },
      (data) => {
        return removeUserFromCurrentData({ data, userId })
      }
    )
  }

  const removeUserFromTimelineFeed = (userId: UUID) => {
    if (userId.length === 0) {
      return
    }

    queryClient.setQueryData<FeedCache>([QueryKeys.TimelineFeed], (data) =>
      removeUserFromCurrentData({ data, userId })
    )
  }

  const removeUserFromUserFeed = (userId: UUID) => {
    if (userId.length === 0) {
      return
    }
    queryClient.setQueryData<FeedCache>([QueryKeys.UserFeed, userId], (data) =>
      removeUserFromCurrentData({ data, userId })
    )
  }

  const removeUserFromFeeds = (userId: UUID) => {
    removeUserFromTimelineFeed(userId)
    removeUserFromCategoryFeed({ userId })
    removeUserFromUserFeed(userId)
  }

  return {
    removeActivityFromTimelineFeed,
    removeContestFromContestDetail,
    removeUserFromFeeds,
    removeUserFromCategoryFeed,
    removeUserFromTimelineFeed,
    removeUserFromUserFeed,
  }
}

import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Withdraw } from '@/components/atoms/assets/Withdraw'
import { Button } from '@/components/atoms/Button'

import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

type WithdrawButtonProps = {
  hasIcon?: boolean
}

const WithdrawButtonComponent: FC<WithdrawButtonProps> = ({ hasIcon }) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  return (
    <Button fill="outline" onClick={() => navigate(Routes.WITHDRAW)}>
      {hasIcon && <Withdraw className="h-3 xs:h-4 mr-1" />}
      <span className="text-xxs xs:text-xs">{t('wallet.withdraw')}</span>
    </Button>
  )
}

export const WithdrawButton = memo(WithdrawButtonComponent)

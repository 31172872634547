import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonItem } from '@ionic/react'

import { Avatar } from '@components/atoms/Avatar'
import { UserName } from '@components/atoms/UserName'

import { formatDistance } from '@utils/date'

import { FollowButton } from '../../FollowButton'

import { ActivityUserFollow } from '@/types/activity/user'
interface IActivityFollowItemProps {
  item: ActivityUserFollow
}

export const ActivityFollowItem: FC<IActivityFollowItemProps> = ({ item }) => {
  const { t } = useTranslation('common')

  const { data } = item

  return (
    <IonItem className="py-4 border-solid border-b-[0.0625rem] border-zinc-800">
      <span className="pr-4">
        <Avatar
          username={data.actor.username}
          userId={data.actor.id}
          isModerator={data.actor?.isModerator}
          isAmbassador={data.actor.isAmbassador}
          badge={data.actor.badge}
          clickable
        />
      </span>
      <div className="w-full flex justify-between items-center">
        <div className="flex flex-col justify-between">
          <div className="text-sm">
            <UserName
              className="font-bold inline"
              userId={data.actor.id}
              name={data.actor.username}
            />
            <Trans i18nKey="activity.item.followMany" values={{ count: 0 }}>
              {t('activity.item.followMany', { count: 0 })}
            </Trans>
          </div>
          <div className="text-xs text-zinc-600">
            {formatDistance(item.time)}
          </div>
        </div>
        <FollowButton
          userId={data.actor.id}
          followStatus={data.actor.followStatus}
          size="small"
          username={data.actor.username}
        />
      </div>
    </IonItem>
  )
}

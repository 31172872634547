import { get } from '@/services/awsAmplify'

export interface IStakeRulesItem {
  ruleId: string
  apyInPercentage: number
  durationInDays: number
  minStakeAmountInRpk: number
}

export interface IStakeRules {
  items: IStakeRulesItem[]
}
export const getStakeRules = async (): Promise<IStakeRules> => {
  return get({
    path: '/stake-rules',
  })
}

import {
  CSSProperties,
  FC,
  memo,
  ReactElement,
  useEffect,
  useState,
} from 'react'
import { IonInput, IonItem, IonLabel, IonTextarea } from '@ionic/react'
import cx from 'classnames'

import { Edit } from '../assets/Edit'
import { CountryCodeSelect } from './CountryCodeSelect'

interface InputFieldProps {
  prefix?: string
  type?: 'tel' | 'text' | 'number' | 'email'
  textAlign?: 'left' | 'center' | 'right'
  placeholder?: string
  width?: string
  onChange?: ({ value }: { value: string }) => void
  value?: string
  style?: CSSProperties
  prefixPosition?: '' | 'floating' | 'fixed' | 'stacked'
  label?: string | ReactElement
  isReadOnly?: boolean
  hasEditSuffix?: boolean
  labelSuffix?: JSX.Element
  role?: string
  autocomplete?: 'on' | 'off'
  isTextarea?: boolean
  isDefault?: boolean
  min?: string
  isPhoneSelect?: boolean
  name?: string
  className?: string
  isBordered?: boolean
  isValid?: boolean
  rows?: number
}

const InputFieldComponent: FC<InputFieldProps> = ({
  prefix,
  type = 'text',
  textAlign = 'left',
  placeholder = '',
  width = '100%',
  value = '',
  onChange,
  style = '',
  prefixPosition = '',
  label,
  isReadOnly = false,
  hasEditSuffix = false,
  labelSuffix = '',
  isTextarea = false,
  isDefault = false,
  isPhoneSelect = false,
  min,
  className = '',
  isBordered = true,
  isValid = true,
  ...props
}) => {
  const [fieldValue, setFieldValue] = useState(value)
  const [telPrefixFieldValue, setTelPrefixFieldValue] = useState('+65')

  const handleChange = (e) => {
    setFieldValue(e.detail.value)
    handleValue(telPrefixFieldValue, e.detail.value)
  }

  const handleCountryCodeSelect = (value: string) => {
    setTelPrefixFieldValue(value)
    handleValue(value, fieldValue)
  }

  const handleValue = (telPrefixFieldValue: string, value: string) => {
    if (typeof onChange === 'function') {
      const data = {
        value,
      }
      if (isPhoneSelect) {
        Object.assign(data, { code: telPrefixFieldValue })
      }
      onChange(data)
    }
  }

  useEffect(() => {
    setFieldValue(value)
  }, [value])

  const FormTag = !isTextarea ? IonInput : IonTextarea

  return !isDefault ? (
    <div
      className="flex items-center w-full"
      style={{
        width,
        ...(style as CSSProperties),
      }}
    >
      {isPhoneSelect ? (
        <div
          className="font-bold text-xl country-select-container"
          data-test="country-code-select"
        >
          <CountryCodeSelect
            onChange={handleCountryCodeSelect}
            defaultValue={telPrefixFieldValue}
          />
        </div>
      ) : (
        prefix &&
        (prefixPosition ? (
          <IonLabel
            position={prefixPosition}
            className="font-semibold text-xs align-center"
          >
            {prefix}
          </IonLabel>
        ) : (
          <IonLabel className="font-semibold text-xs">{prefix}</IonLabel>
        ))
      )}
      <label className="block w-full">
        {label && (
          <IonLabel className="font-semibold text-xs uppercase flex align-left text-cool-gray-300">
            {label}
            {labelSuffix}
          </IonLabel>
        )}
        <IonItem
          className={cx('form no-padding', {
            error: !isValid,
            'item-input-shrink': isPhoneSelect,
            'border-1': isBordered,
            '!border-0': !isBordered,
          })}
          color="transparent"
          data-test="verification-code-input"
        >
          <FormTag
            type={type}
            readonly={isReadOnly}
            legacy={true}
            className={cx(`no-padding font-bold text-base ${className}`, {
              'read-only': isReadOnly,
              'ml-4': hasEditSuffix,
            })}
            color="transparent"
            style={{ textAlign }}
            value={fieldValue}
            placeholder={placeholder}
            // event is triggered at autocomplete
            onInput={(evt) =>
              handleChange({ detail: { value: evt.currentTarget.value } })
            }
            onIonChange={handleChange}
            {...props}
          />
          {hasEditSuffix && (
            <div className="m-0" slot="end">
              <Edit />
            </div>
          )}
        </IonItem>
      </label>
    </div>
  ) : (
    <IonItem className="form no-padding">
      <IonLabel>
        {label}
        {labelSuffix}
      </IonLabel>
      <FormTag
        className="no-padding"
        style={{ textAlign }}
        type={type}
        readonly={isReadOnly}
        value={fieldValue}
        placeholder={placeholder}
        onInput={(evt) =>
          handleChange({ detail: { value: evt.currentTarget.value } })
        }
        onIonChange={handleChange}
        min={min}
        {...props}
      />
    </IonItem>
  )
}

export const InputField = memo(InputFieldComponent)

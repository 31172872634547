import { get } from '@/services/awsAmplify'

import { StakeStatus } from '@/enums'

export interface IStakeVaultTransactionItem {
  id: UUID
  ruleId: string
  userId: UUID
  username: string
  amount: string
  apyInPercentage: number
  durationInDays: number
  stakeStatus: StakeStatus
  stakeDate: string
  releaseDate: string
  lastKey?: string
}

export interface IStakeVaultTransactions {
  items: IStakeVaultTransactionItem[]
  lastKey: string
}

export const getStakeTransactions = async ({
  pageParam,
}): Promise<IStakeVaultTransactions> => {
  return get({
    path: `/stakes`,
    params: pageParam,
  })
}

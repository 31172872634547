import { useMessageContext } from 'stream-chat-react'

import { Avatar } from '@/components/atoms/Avatar'

import { Badge } from '@/enums'

export const MessageAvatar = () => {
  const { message } = useMessageContext()

  return (
    <Avatar
      clickable={true}
      username={message.user?.name}
      userId={message.user.id}
      isModerator={message.user?.isModerator as boolean}
      isAmbassador={message.user?.isAmbassador as boolean}
      badge={message.user?.badge as Badge}
    />
  )
}

import { FC, memo } from 'react'

export interface SendIconComponentProps {
  className?: string
}

const SendIconComponent: FC<SendIconComponentProps> = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10ZM6 9H10V6L14 10L10 14V11H6V9Z"
        fill="white"
      />
    </svg>
  )
}

export const SendIcon = memo(SendIconComponent)

import { memo } from 'react'
import cx from 'classnames'

interface IEmptyAvatarBackgroundComponent {
  width?: number | string
  height?: number | string
  margin?: number
  isValid?: boolean
}

const EmptyAvatarBackgroundComponent = ({
  width = 120,
  height = 120,
  margin,
  isValid = true,
}: IEmptyAvatarBackgroundComponent) => {
  const tailwindMargin = `m-[${margin}px]`

  return (
    <div
      className={cx('rounded-full border-signal-red', {
        'border-4': !isValid,
        [tailwindMargin]: margin && isValid,
      })}
    >
      <svg
        data-testid="default-avatar"
        width={width}
        height={height}
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_836_5990)">
          <circle cx="60" cy="57" r="50" fill="black" />
          <circle cx="60" cy="57" r="49.5" stroke="white" />
        </g>
        <circle
          className="stroke-cool-gray-400 fill-cool-gray-600"
          cx="60"
          cy="60"
          r="59.5"
        />
        <path
          className="fill-cool-gray-700"
          d="M60 11.25C33.1195 11.25 11.25 33.1195 11.25 60C11.25 86.8805 33.1195 108.75 60 108.75C86.8805 108.75 108.75 86.8805 108.75 60C108.75 33.1195 86.8805 11.25 60 11.25ZM60.4688 33.75C63.8063 33.75 67.0689 34.7397 69.844 36.5939C72.6191 38.4482 74.782 41.0837 76.0592 44.1672C77.3364 47.2507 77.6706 50.6437 77.0195 53.9172C76.3684 57.1906 74.7612 60.1974 72.4012 62.5574C70.0412 64.9174 67.0343 66.5246 63.7609 67.1758C60.4875 67.8269 57.0945 67.4927 54.011 66.2155C50.9275 64.9382 48.2919 62.7753 46.4377 60.0003C44.5835 57.2252 43.5938 53.9626 43.5938 50.625C43.5938 46.1495 45.3716 41.8573 48.5363 38.6926C51.701 35.5279 55.9932 33.75 60.4688 33.75V33.75ZM60 101.25C54.3104 101.252 48.6822 100.075 43.471 97.7914C38.2597 95.508 33.5786 92.1687 29.7234 87.9844C31.7953 77.2547 50.4047 75 60 75C69.5953 75 88.2047 77.2547 90.2766 87.982C86.4219 92.1671 81.7409 95.5071 76.5296 97.7909C71.3182 100.075 65.6898 101.252 60 101.25V101.25Z"
        />
        <defs>
          <filter
            id="filter0_d_836_5990"
            x="6"
            y="7"
            width="108"
            height="108"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_836_5990"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_836_5990"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  )
}

export const EmptyAvatarBackground = memo(EmptyAvatarBackgroundComponent)

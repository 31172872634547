import { useCallback } from 'react'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getCurrentUser } from 'aws-amplify/auth'

import { get } from '@/services/awsAmplify'

import { Badge } from '@/enums'
export interface StakedRpkForXpMultiplierItem {
  staked: number
  xpMultiplier: number
}

export interface AccountInfo {
  id: UUID
  xp: number
  rpk?: number
  multiplier?: string
  badge?: Badge
  stakedRpkForXpMultiplier: StakedRpkForXpMultiplierItem[]
}

export const useAccountInfo = (userId?: UUID, opts?: { enabled?: boolean }) => {
  const query = useQuery({
    queryKey: ['account', userId].filter(Boolean),
    queryFn: getAccountInfo,
    ...opts,
    retry: 5,
  })
  let maxReward: StakedRpkForXpMultiplierItem = null
  let hasHighestRewardMultiplier = false
  const getHighestRewardEntry = useCallback((acc) => {
    if (!acc?.length) {
      return null
    }
    return acc.reduce(
      (
        acc: StakedRpkForXpMultiplierItem,
        current: StakedRpkForXpMultiplierItem
      ) => (current.xpMultiplier > acc.xpMultiplier ? current : acc),
      acc[0]
    )
  }, [])

  if (!query.isLoading) {
    maxReward = getHighestRewardEntry(query.data?.stakedRpkForXpMultiplier)
    hasHighestRewardMultiplier =
      parseInt(query.data?.multiplier) === maxReward?.xpMultiplier
  }

  return {
    ...query,
    maxReward,
    hasHighestRewardMultiplier,
  }
}

export const getAccountInfo = async ({
  queryKey,
}: UseQueryOptions): Promise<AccountInfo> => {
  const { username } = await getCurrentUser()
  const [, userId] = queryKey

  return get({
    path: `/accounts/${userId || username}`,
  })
}

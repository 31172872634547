import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { FreeMode, Virtual } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Avatar } from '@/components/atoms/Avatar'
import { UserName } from '@/components/atoms/UserName'

import { Link } from '@/lib/routing'

import { IPromoPool } from '@/interfaces'

import { usePromoPool } from '../../hooks/usePromoPools'

const CreatorsPromoWidgetComponent: FC<{ pool: IPromoPool }> = ({ pool }) => {
  const { data, isLoading, fetchNextPage, hasNextPage } = usePromoPool(pool)
  const { t } = useTranslation('common')

  if (data?.length < 10 || isLoading) {
    return null
  }

  return (
    <section className="px-3 py-3">
      <strong className="block pb-4 text-lg">
        {t('promoWidgets.creators.title')}
      </strong>
      <Swiper
        modules={[Virtual, FreeMode]}
        spaceBetween={4}
        onReachEnd={() => {
          if (!hasNextPage) return

          fetchNextPage()
        }}
        direction="horizontal"
        freeMode
        lazyPreloadPrevNext={8}
        virtual={{
          addSlidesAfter: 8,
          addSlidesBefore: 8,
        }}
        breakpoints={{
          320: {
            slidesPerView: 4.3,
          },
          640: {
            slidesPerView: 6.3,
          },
        }}
      >
        {data?.map((creator, index) => (
          <SwiperSlide key={creator.id} virtualIndex={index}>
            <Link
              className="text-center"
              to={`/@${creator?.metadata?.username}`}
            >
              <div className="w-20">
                <Avatar
                  username={creator?.metadata?.username}
                  className="w-14 h-14 mx-auto pb-1.5"
                  userId={creator.id}
                  isModerator={creator?.metadata?.isModerator}
                  isAmbassador={creator?.metadata?.isAmbassador}
                  badge={creator?.metadata?.badge}
                  lazy={false}
                  size="large"
                />
                <UserName
                  className="text-xs font-bold text-cool-gray-200"
                  name={creator.metadata.username}
                />
                <span className="text-xs block text-cool-gray-200 whitespace-nowrap text-ellipsis overflow-hidden">
                  {creator.metadata.displayName}
                </span>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}

export const CreatorsPromoWidget = memo(CreatorsPromoWidgetComponent)

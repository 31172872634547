import { Trans, useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/Button'

import { useAcceptableUsePolicy } from '@/lib/hooks/subscription/useAcceptableUsePolicy'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

import { useTermsAndConditionsModal } from '../../../../TermsAndConditionsModal'

export const MonetizeSubscription = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const { mutateAcceptableUsePolicy, acceptableUsePolicy } =
    useAcceptableUsePolicy()
  const { presentModal } = useTermsAndConditionsModal({
    onConfirm: () => {
      mutateAcceptableUsePolicy()
      navigate(Routes.CONFIGURE_SUBSCRIPTION)
    },
  })
  return (
    <div className="px-2">
      <h1 className="pb-4 text-[1.675rem]">
        <Trans i18nKey="profile.subscription.noSubscriptionContentTitle">
          {t('profile.subscription.noSubscriptionContentTitle')}
        </Trans>
      </h1>
      <p className="text-[1rem] text-sm">
        <Trans i18nKey="profile.subscription.noSubscriptionContentBody">
          {t('profile.subscription.noSubscriptionContentBody')}
        </Trans>
      </p>
      <Button
        onClick={() =>
          acceptableUsePolicy
            ? navigate(Routes.CONFIGURE_SUBSCRIPTION)
            : presentModal()
        }
        className="mt-7 mx-auto"
      >
        <span className="text-sm px-6">
          {t('profile.subscription.noSubscriptionButton')}
        </span>
      </Button>
    </div>
  )
}

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons } from '@ionic/react'

import { MarkdownEllipsis } from '@/components/atoms/MarkdownEllipsis'
import { ReportButton } from '@/components/molecules/ReportButton'
import { VideoContainer } from '@/components/molecules/VideoContainer'
import { FeedActivityHeader } from '@/components/organisms/FeedActivity/FeedActivityHeader'

import { IContestDiscoveryFeedActivityType } from 'services/contestDiscoveryFeed'
import { useImageSize } from '@/lib/hooks/useImageSize'
import { useMediaContainer } from '@/lib/hooks/useMediaContainer'
import { useTrackViewedActivity } from '@/lib/hooks/useTrackViewedActivity'
import { MediaType } from '@/lib/hooks/useTrackViewedActivity/useTrackViewedActivity'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { TrackingEvent } from '@/lib/tracking'
import { formatDayMonth, formatDistanceToNowDate } from '@/utils/date'
import { actorMarkdown } from '@/utils/utils'

import { ActivityFeedVerb, ReportButtonType } from '@enums'

export const ContestantJoinedFeedActivity: FC<
  IContestDiscoveryFeedActivityType
> = ({
  actor,
  object,
  contestId,
  id: videoId,
  video,
  videoPreview,
  verb,
  time,
  foreign_id,
  votesCount,
  description,
  origin,
  index,
  inViewport,
  outViewport,
  isActiveActivity,
  imageAspectRatio,
  isScrollingFast,
}) => {
  const contentContainerRef = useRef<HTMLDivElement>()
  const { ratio, getRatio } = useImageSize()
  const [videoLoadedMilliseconds, setVideoLoadedMilliseconds] = useState(0)

  useEffect(() => {
    if (imageAspectRatio) return
    getRatio(videoPreview)
  }, [videoPreview, imageAspectRatio, getRatio])

  const { data: authUser } = useUserInfo()
  const { t } = useTranslation('common')
  const participant = {
    actor,
    contestId,
    foreign_id,
    id: videoId,
  }

  const { inView, mediaContainerRef, paddingBottom } = useMediaContainer({
    ratio: imageAspectRatio ? imageAspectRatio : ratio,
    index,
  })

  useTrackViewedActivity({
    inViewport: inView,
    eventName: TrackingEvent.FeedItemViewed,
    activityId: contestId,
    itemId: videoId,
    eventProperties: {
      challengeId: contestId,
      challengeName: object?.data?.name,
      videoLoadedDuration: videoLoadedMilliseconds,
      mediaType: MediaType.Video,
      submissionId: videoId,
    },
    contentType: 'challengeParticipant',
    foreignId: foreign_id,
  })

  const renderVideo = useCallback(
    ({ video, videoPreview }) => (
      <VideoContainer
        className="-mx-2.5 !h-full"
        video={video}
        videoPreview={videoPreview}
        isPlaying={isActiveActivity}
        videoAllowedToLoad={!isScrollingFast}
        onVideoLoadedMilliseconds={onVideoLoadedMilliseconds}
      />
    ),
    [isActiveActivity, isScrollingFast]
  )

  useEffect(() => {
    if (inView) {
      inViewport(videoId)
      return
    }
    outViewport(videoId)
  }, [inView, isActiveActivity, videoId, inViewport, outViewport])

  const onVideoLoadedMilliseconds = (milliseconds: number) =>
    setVideoLoadedMilliseconds(milliseconds)

  return (
    <div ref={contentContainerRef} className="bg-grey-800 relative !z-0">
      <FeedActivityHeader
        actor={actor}
        name={actor?.data?.name}
        contestName={object?.data?.name}
        contestId={contestId}
        verb={verb}
        votesCount={votesCount}
        object={object}
        origin={origin}
      >
        <>
          <span className="whitespace-nowrap text-sm pr-2 text-cool-gray-300">
            {formatDayMonth(time)}
          </span>
          {verb === ActivityFeedVerb.ContestParticipate &&
            actor.id !== authUser?.id && (
              <IonButtons>
                <ReportButton
                  type={ReportButtonType.Post}
                  confirmationLabel={t('okay')}
                  contestId={participant?.contestId}
                  activityId={participant?.id}
                  userId={actor.id}
                  isDisabled={
                    verb !== ActivityFeedVerb.ContestParticipate &&
                    actor.id === authUser?.id
                  }
                />
              </IonButtons>
            )}
        </>
      </FeedActivityHeader>
      <div className="py-2">
        <div
          ref={mediaContainerRef}
          className="relative z-10 grow bg-cover bg-no-repeat mx-2.5"
          style={{ paddingBottom }}
        >
          <div className="absolute left-1/2 top-1/2 !w-full h-full object-contain -translate-y-1/2 -translate-x-1/2 rounded-[0.625rem] overflow-hidden">
            {renderVideo({
              video,
              videoPreview,
            })}
          </div>
        </div>
      </div>
      <div className="bg-dark px-2 pb-3">
        <div className="px-3 text-sm font-normal text-cool-gray-200 min-h-min wordWrap-break">
          {!!description && (
            <MarkdownEllipsis
              text={`${actorMarkdown({
                name: actor.data?.name,
                url: `/@${actor.data?.name}`,
                isAmbassador: actor.data?.isAmbassador,
                isModerator: actor.data?.isModerator,
              })} ${description}`}
            />
          )}
        </div>
        <div className="flex justify-between items-center p-3">
          <span className="block text-xs opacity-50">
            {formatDistanceToNowDate({
              timestamp: time,
              addSuffix: true,
              useUTC: true,
            })}
          </span>
        </div>
      </div>
    </div>
  )
}

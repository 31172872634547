import { useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

import { get } from '@/services/awsAmplify'
import { queryClient } from '@/lib/queryClient'

import { Badge, FollowStatus } from '@/enums'

interface IFindUserRelationsQuery {
  q: string
  lastKey?: string
  followers: boolean
}

export const useFindUserRelations = (
  id: UUID,
  query: IFindUserRelationsQuery
) => {
  const queryKey = useMemo(() => ['userRelations', id, query], [id, query])

  const {
    data,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) => getUserRelations(id, query, pageParam),
    getNextPageParam: (lastPage) => lastPage?.lastKey ?? undefined,
    initialPageParam: '',
  })

  const refetchFirstPage = async () => {
    queryClient.setQueryData<{
      pages: UserRelationsData[]
      pageParams: (string | undefined)[]
    }>(queryKey, (data) => ({
      pages: data?.pages?.slice(0, 1) || [],
      pageParams: data?.pageParams?.slice(0, 1) || [],
    }))
    await refetch()
  }

  return {
    data,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetchFirstPage,
  }
}

type IRelation = {
  displayName?: string
  avatar: string
  badge: Badge
  isAmbassador: boolean
  username: string
  id: UUID
  followStatus: FollowStatus
}

export type UserRelationsData = {
  lastKey?: string
  users: IRelation[]
}

export const getUserRelations = async (
  id: UUID,
  query: IFindUserRelationsQuery,
  pageParam: string
) => {
  const { q: _q, lastKey, followers } = query
  const q = _q.length < 2 ? '' : _q

  const params = omitBy(
    {
      q,
      lastKey,
      followers,
      startAt: pageParam,
    },
    isNil
  )

  return get({
    path: `/profile/${id}/relations`,
    params,
  }) as Promise<UserRelationsData>
}

import { AxiosError } from 'axios'
export interface ErrorResponse {
  context: {
    key: string
  }
  message: string
}
export type ResponseDataType = {
  error: string
}

const setFormErrorMessages = ({
  error,
  setError,
  t,
  unhandledErrorCallback,
}: {
  error: AxiosError
  setError: (arg0: string, arg1: unknown) => void
  t?: (arg0: string) => string
  unhandledErrorCallback?: () => void
}) => {
  const obj: Record<string, string> = {}
  const responseData = error?.response?.data

  if (typeof responseData === 'string') {
    const data = String(responseData)

    if (data.includes('Email is blocked')) {
      obj.email = t?.('profile.error.emailDomainBlocked') ?? ''
    } else if (data.includes('email')) {
      obj.email = data
    } else {
      console.error('Unhandled error response:', responseData)
    }
  } else if (Array.isArray(responseData)) {
    ;[...responseData].forEach((error: ErrorResponse) => {
      obj[error.context.key] = error.message
    })
  } else if ((responseData as ResponseDataType) instanceof Object) {
    if (error?.response?.status === 409) {
      obj.username = t?.('usernameIsTaken')
    } else {
      console.error('Unhandled error response:', responseData)
    }
  }

  if (Object.keys(obj).length === 0 && unhandledErrorCallback) {
    unhandledErrorCallback()
  }

  for (const [errorKey, errorMessage] of Object.entries(obj)) {
    setError(errorKey, {
      type: 'server',
      message: errorMessage,
    })
  }
}

export default setFormErrorMessages

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useHandleCopy } from '@/lib/hooks/useHandleCopy'

type CopySecretKeyProps = {
  secretCode: string
  qrCode: string
}

export const CopySecretKey: FC<CopySecretKeyProps> = ({
  secretCode,
  qrCode,
}) => {
  const { t } = useTranslation('common')
  const handleCopy = useHandleCopy()

  return (
    <div className="flex flex-col">
      <span className="text-[0.6875rem] text-cool-gray-300 font-medium uppercase mb-2">
        {t('setup2FA.secretCode')}
      </span>
      <div className="flex mb-4 items-center">
        <span className="flex flex-1 min-h-[1.5rem] border-b border-cool-gray-400 text-sm break-all">
          {secretCode}
        </span>
        <span
          className="text-[0.6875rem] font-medium uppercase cursor-pointer ml-6"
          onClick={() => handleCopy(secretCode)}
        >
          {t('copy')}
        </span>
      </div>
      {qrCode && <img src={qrCode} alt="qr-code" className="mt-2 mb-8 w-1/2" />}
    </div>
  )
}

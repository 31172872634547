import { FC, memo } from 'react'

import { useNavigate } from '@/lib/routing'

import { BackButtonIcon } from '../assets/BackButtonIcon'

interface IBackButtonProps {
  defaultHref?: string
  className?: string
}

export const BackButtonComponent: FC<IBackButtonProps> = ({
  defaultHref = '',
  className,
}) => {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => {
        defaultHref ? navigate(defaultHref) : navigate(-1)
      }}
      className={`px-2 cursor-pointer ${className}`}
    >
      <BackButtonIcon />
    </div>
  )
}

export const BackButton = memo(BackButtonComponent)

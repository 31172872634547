import type { ReactNode } from 'react'
import { FC } from 'react'

import { Title } from '@components/atoms/Title'

export interface IMainTitleProps {
  children: ReactNode
  className?: string
  onClick?: () => void | Promise<void>
}

export const MainTitle: FC<IMainTitleProps> = ({
  children,
  className = '',
  onClick,
}) => (
  <Title
    level={1}
    className={`flex items-center text-3xl pl-3 ${className}`}
    onClick={onClick}
  >
    {children}
  </Title>
)

import { FC } from 'react'

import { Button } from '@/components/atoms/Button'

type PostUserTeaserProps = {
  title: string
  subTitle: string
  buttonText: string
}

export const PostUserTeaser: FC<PostUserTeaserProps> = ({
  title,
  subTitle,
  buttonText,
}) => {
  return (
    <div className="absolute w-full h-full z-10 bg-gradient-to-b from-cool-gray-900/30 via-cool-gray-900/80 to-cool-gray-900 flex flex-col justify-center items-center">
      <img className="mt-6 mb-[0.88rem]" src="/logo-icon-only.svg" />
      <h1 className="text-[8vw] sm:text-5xl text-center mb-2">{title}</h1>
      <div className="font-bold text-center mb-[0.88rem] max-w-[300px]">
        {subTitle}
      </div>
      <Button size="large">{buttonText}</Button>
    </div>
  )
}

import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IonModal } from '@ionic/react'

import { appConfig } from '@/services/config'

type AirdropInfoModalProps = {
  onDismiss?: () => void
}

export const AirdropInfoModal: FC<AirdropInfoModalProps> = () => {
  const { t } = useTranslation('common')
  const modalRef = useRef<HTMLIonModalElement>(null)

  const dismissModal = () => modalRef.current?.dismiss()

  return (
    <IonModal
      ref={modalRef}
      trigger="open-cr-info-modal"
      className="cr-info-modal"
    >
      <div className="flex flex-col items-center py-4 px-6 text-center">
        <img src="/vault/popover-icons.png" className="w-1/2 my-4" />
        <span className="text-lg font-bold mb-3">
          {t('wallet.airdropSchedule.info.title', {
            currency: appConfig.currency,
          })}
        </span>
        <span className="mb-4">
          {t('wallet.airdropSchedule.info.description', {
            currency: appConfig.currency,
          })}
        </span>
        <span className="font-bold mb-6">
          {t('wallet.airdropSchedule.info.subTitle', {
            currency: appConfig.currency,
          })}
        </span>
        <span className="text-danger font-bold mb-4">
          {t('wallet.airdropSchedule.info.warning')}
        </span>
      </div>
      <div className="w-full border-t">
        <button className={'w-full p-3 uppercase'} onClick={dismissModal}>
          {t('wallet.airdropSchedule.info.ok')}
        </button>
      </div>
    </IonModal>
  )
}

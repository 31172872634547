import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { get } from '@/services/awsAmplify'

import { QueryKeys } from '@/enums'

export type GetDepositDataResult = {
  walletAddress: string
  minDepositAmount: string
}

const getDepositData = async ({ queryKey }): Promise<GetDepositDataResult> => {
  const network = queryKey[1]

  return get({
    path: '/fireblocks/wallet-address',
    params: {
      network,
    },
  })
}

export const useDeposit = (
  selectedNetwork: string,
  options?: Omit<UseQueryOptions<GetDepositDataResult>, 'queryKey'>
) =>
  useQuery({
    queryKey: [QueryKeys.DepositData, selectedNetwork],
    queryFn: getDepositData,
    ...options,
  })

import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { IonIcon } from '@ionic/react'
import { flash } from 'ionicons/icons'

import { compactNumbers } from '@/utils/utils'

interface IVotesLabelProps {
  count: number | null
  small?: boolean
}

const VotesLabelComponent: FC<IVotesLabelProps> = ({ count, small }) => {
  const { t } = useTranslation('common')
  if (!count) return null

  if (small) {
    return (
      <div className="text-md text-white flex">
        <div className="pr-1 items-center flex text-shadow-md">
          <span className="rounded-full flex w-5 h-5 float-left text-primary justify-center items-center">
            <IonIcon
              icon={flash}
              color="success"
              size="large"
              className="h-4"
            />
          </span>
          {compactNumbers(count)}
        </div>
      </div>
    )
  }

  return (
    <div className="text-[0.625rem] text-zinc-800 flex">
      <div className="bg-primary rounded-full pr-2 items-center flex">
        <span className="bg-dark rounded-full flex w-3 h-3 float-left ml-[0.125rem] text-primary justify-center items-center mr-1">
          <IonIcon icon={flash} color="success" className="h-2" />
        </span>
        {t('votes.label', {
          count,
          formattedCount: compactNumbers(count),
        })}
      </div>
    </div>
  )
}

export const VotesLabel = memo(
  VotesLabelComponent,
  (prev, next) => prev.small === next.small && prev.count === next.count
)

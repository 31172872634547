import { FC, memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import cx from 'classnames'

import { Link } from '@/lib/routing'
import { escapeMarkdownTags } from '@/utils/escapeMarkdownTags'
import { parseLink } from '@/utils/utils'

import { UserName } from '../UserName'

type MarkdownEllipsisComponentProps = {
  text: string
  overflowHidden?: boolean
}

const MarkdownEllipsisComponent: FC<MarkdownEllipsisComponentProps> = ({
  text,
  overflowHidden = true,
}) => {
  const { t } = useTranslation('common')
  const containerRef = useRef<HTMLDivElement>(null)
  const [captionText, setCaptionText] = useState(text)
  const [isTruncated, setIsTruncated] = useState(true)
  const [isButtonVisible, setIsButtonVisible] = useState(false)

  useEffect(() => {
    setCaptionText(text)
    setIsTruncated(true)
    setIsButtonVisible(false)
  }, [text])

  useEffect(() => {
    const container = containerRef.current
    if (container?.scrollHeight <= container?.clientHeight) {
      setIsTruncated(false)
      return
    }
    setIsButtonVisible(true)
  }, [captionText])

  const handleShowMore = useCallback(() => {
    setIsButtonVisible(false)
    setIsTruncated(false)
  }, [])

  const renderers = {
    a: ({ node, ...props }) => {
      const href = node?.properties?.href?.split('#')
      if (href?.[1] === 'actor') {
        const name = href?.[2]
        const isAmbassador = href?.[3] === 'true'
        const isModerator = href?.[4] === 'true'
        const className = href?.[5]

        return (
          <Link
            to={parseLink(href?.[0])}
            className={cx(`text-sm pr-1 inline-flex font-bold relative z-10`, {
              [className]: !!className,
            })}
          >
            <UserName
              name={name}
              isAmbassador={isAmbassador}
              isModerator={isModerator}
            />
          </Link>
        )
      }

      return (
        <Link
          to={parseLink(`/${props?.children?.[0]}`)}
          className="relative text-primary"
        >
          {props?.children[0]}
        </Link>
      )
    },
  }

  return (
    <div
      ref={containerRef}
      className={cx('relative transition-all text-left', {
        'truncate-lines-2 max-h-10': isTruncated,
        'overflow-hidden': overflowHidden,
      })}
      style={
        isTruncated
          ? {
              WebkitLineClamp: 2,
              lineClamp: 2,
            }
          : {}
      }
    >
      <div className="notranslate">
        <ReactMarkdown components={renderers} disallowedElements={['img']}>
          {escapeMarkdownTags(captionText)}
        </ReactMarkdown>
      </div>
      {isButtonVisible && (
        <span
          className="absolute right-0 bottom-0 z-10 bg-cool-gray-900 cursor-pointer"
          onClick={handleShowMore}
        >
          {`...`}{' '}
          <span className="text-white pl-1">{`${t('common.more')}`}</span>
        </span>
      )}
    </div>
  )
}

export const MarkdownEllipsis = memo(MarkdownEllipsisComponent)

import { memo } from 'react'

const LoadingComponent = () => (
  <div className="absolute top-1/2 left-1/2 transform -translate-x-7 -translate-y-7">
    <span className="relative flex w-14 h-14 justify-center items-center">
      <img
        src="/Stickers-05.png"
        alt="logo"
        className="animate-ping absolute inline-flex w-12 h-12 opacity-75"
      />
      <img
        src="/Stickers-05.png"
        alt="logo"
        className="relative inline-flex w-14 h-14"
      />
    </span>
  </div>
)

export const Loading = memo(LoadingComponent)

import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonItem } from '@ionic/react'

import { Avatar } from '@components/atoms/Avatar'

import { appConfig } from '@/services/config'
import { formatDistance } from '@utils/date'

import { ActivityTypeEnum } from '@/types/activity'
import {
  ActivityReward,
  ActivityRewardFollowerMilestoneCountData,
  RewardActivityBaseData,
} from '@/types/activity/reward'

interface IActivityRewardedItemProps {
  item: ActivityReward
}

interface ICommonValues
  extends RewardActivityBaseData,
    ActivityRewardFollowerMilestoneCountData {
  count?: number
}

const commonValues = (data: ICommonValues) => ({
  xp: data.xp,
  bonus: data.xpBonus,
  count: data.xpBonus,
  milestone: data.milestone,
})

const getTransComponent = (
  t: unknown,
  i18nKey: string,
  values: Record<number, RewardActivityBaseData>,
  children: React.ReactNode
) => (
  <Trans i18nKey={i18nKey} values={values}>
    {children}
  </Trans>
)

const typeMappings = {
  [ActivityTypeEnum.RewardGoalVotesPerDayReached]:
    'activity.item.rewards.daily_votes_consumed',
  [ActivityTypeEnum.RewardAccountEmailVerified]:
    'activity.item.rewards.verifying_email',
  [ActivityTypeEnum.RewardAccountPhoneVerified]:
    'activity.item.rewards.verifying_phone',
  [ActivityTypeEnum.RewardGoalAppOpenedDaily]:
    'activity.item.rewards.opened_app_reward',
  [ActivityTypeEnum.RewardGoalLikesReceived]:
    'activity.item.rewards.received_likes_reward',
  [ActivityTypeEnum.RewardGoalLikesGiven]:
    'activity.item.rewards.given_likes_reward',
  [ActivityTypeEnum.RewardContestContestantJoined]:
    'activity.item.rewards.contest',
  [ActivityTypeEnum.RewardGoalAppOpenedStreak]: 'activity.item.rewards.streak',
  [ActivityTypeEnum.RewardCommunityContribution]:
    'activity.item.rewards.community_contribution',
  [ActivityTypeEnum.RewardCommunityModeration]:
    'activity.item.rewards.community_moderation',
}

export const ActivityRewardedItem: FC<IActivityRewardedItemProps> = ({
  item,
}) => {
  const { t } = useTranslation('common')
  const { type, data } = item
  const translationKey = typeMappings[type]

  const activityComponent = getTransComponent(
    t,
    translationKey,
    commonValues(data as ICommonValues),
    t(translationKey, commonValues(data as ICommonValues))
  )

  return (
    <IonItem className="py-4 border-solid border-b-[0.0625rem] border-zinc-800">
      <span className="pr-4">
        <Avatar userId={appConfig.republikUserId} clickable isAmbassador />
      </span>
      <div className="w-full flex flex-col justify-between">
        <div className="text-sm">{activityComponent}</div>
        <div className="text-xs text-zinc-600">{formatDistance(item.time)}</div>
      </div>
    </IonItem>
  )
}

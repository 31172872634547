import {
  PersonalizeEventsClient,
  PutEventsCommand,
  PutEventsCommandInput,
} from '@aws-sdk/client-personalize-events'
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth'

import { appConfig } from '@/services/config'

import { ITrackingDriver } from '../driver'
import { TrackingEvent } from '../enums'
import { EventName, EventPropertyMap } from '../interface'
import {
  resolveCachedSession,
  updateCachedSession,
} from '../utils/cachedSession'

export class PersonalizeDriver implements ITrackingDriver {
  events: TrackingEvent[]
  private client: PersonalizeEventsClient | null = null

  constructor(events: TrackingEvent[]) {
    this.events = events
  }

  public triggerEvent<Event extends EventName>(
    eventName: Event,
    data: EventPropertyMap[Event]
  ) {
    this.trackEvent({ eventName, data })
  }

  private async trackEvent<Event extends EventName>(eventData: {
    eventName: Event
    data: EventPropertyMap[Event]
  }) {
    try {
      // Attempt to resolve the cached session and refresh credentials if needed
      const session = await fetchAuthSession()
      const { credentials } = session
      if (!credentials) {
        throw new Error('Unable to fetch or refresh credentials')
      }

      const { sessionId: cachedSessionId, userId: cachedUserId } =
        await resolveCachedSession()
      const { username } = await getCurrentUser()
      if (username) {
        updateCachedSession(username, cachedSessionId, cachedUserId)
      }
      const { sessionId: updatedSessionId } = await resolveCachedSession()

      this.client = new PersonalizeEventsClient({
        region: appConfig.region,
        credentials,
      })

      const params: PutEventsCommandInput = {
        userId: username,
        sessionId: updatedSessionId,
        trackingId: appConfig.personalize,
        eventList: [
          {
            sentAt: new Date(),
            eventType: eventData.eventName,
            ...eventData.data,
          },
        ],
      }

      const command = new PutEventsCommand(params)
      await this.client.send(command)
    } catch (error) {
      console.error(`ERROR: Personalize track event: ${error}`)
    }
  }

  public isTrackable<Event extends EventName>(eventName: Event): boolean {
    return this.events.includes(eventName as TrackingEvent)
  }
}

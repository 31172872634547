import { useCallback, useMemo, useRef } from 'react'

import {
  DepositCurrency,
  DepositCurrencyRef,
} from '@/components/organisms/DepositCurrency'

import { appConfig } from '@/services/config'
import { IWallet } from '@/services/wallet/getWallet'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { tokenToString } from '@/utils/token'
import { compactNumbers } from '@/utils/utils'

interface CurrencyBalanceButtonProps {
  wallet: IWallet
}

export function CurrencyBalanceButton({ wallet }: CurrencyBalanceButtonProps) {
  const depositCurrencyRef = useRef<DepositCurrencyRef>()
  const userOwnCurrency = useMemo(
    () => !!wallet?.totalBalance && wallet?.totalBalance !== '0',
    [wallet]
  )
  const navigate = useNavigate()

  const compactedValue = useMemo(() => {
    const value = wallet?.totalBalance ?? '0'
    const tokenValue = Number(tokenToString(value))

    return compactNumbers(Math.floor(tokenValue))
  }, [wallet])

  const handleOnClick = useCallback(() => {
    if (userOwnCurrency) {
      return navigate(Routes.VAULT)
    }

    depositCurrencyRef.current.openModal()
  }, [userOwnCurrency, navigate])

  return (
    <>
      <button className="cursor-pointer" onClick={handleOnClick}>
        <span className="font-bold text-base">{compactedValue}</span>{' '}
        <span className="font-bold text-primary">{appConfig.currency}</span>
      </button>
      <DepositCurrency ref={depositCurrencyRef} />
    </>
  )
}

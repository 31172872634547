import { FC, ReactNode } from 'react'

interface HistoryListItemDetailProps {
  title: string
  content: ReactNode
}

export const HistoryListItemDetail: FC<HistoryListItemDetailProps> = ({
  title,
  content,
}) => {
  return (
    <>
      <div className="flex mt-1">
        <div className="flex flex-1 items-end justify-start text-cool-gray-200 text-xs ">
          <span>{title}</span>
        </div>
        <div className="flex-1 flex justify-end items-end text-xs font-normal">
          {content}
        </div>
      </div>
    </>
  )
}

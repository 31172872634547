import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { IonIcon, IonPage } from '@ionic/react'
import cx from 'classnames'
import { checkmark, closeCircle } from 'ionicons/icons'

import { Title } from '@components/atoms/Title'
import { Hexagon } from '@/components/atoms/assets/Hexagon'
import { StreakCompletedModalBackground } from '@/components/atoms/assets/StreakCompletedModalBackground'

import { isIosNativeOrPWA } from '@/utils/utils'

type StreakCompletedModal = {
  onDismiss: () => void
  xp: number
}

const StreakCompletedModalComponent: FC<StreakCompletedModal> = ({
  onDismiss,
  xp,
}) => {
  const { t } = useTranslation('common')

  return (
    <IonPage>
      <div className="relative w-full h-full">
        <IonIcon
          className={cx(
            'absolute right-5 z-50 w-8 h-8 md:w-12 md:h-12 cursor-pointer',
            { 'top-16': isIosNativeOrPWA(), 'top-5': !isIosNativeOrPWA() }
          )}
          icon={closeCircle}
          onClick={onDismiss}
        />
        <span
          className={cx(
            'absolute right-6 z-40 w-6 h-6 md:w-10 md:h-10 flex items-center justify-center bg-black rounded-full',
            {
              'top-[4.25rem]': isIosNativeOrPWA(),
              'top-6': !isIosNativeOrPWA(),
            }
          )}
        />
        <StreakCompletedModalBackground />
        <div className="absolute w-full h-full flex items-center justify-center">
          <Title level={2} className="font-bold">
            <div className="text-center text-[5vw] sm:text-4xl">
              {t('XPWallet.streakComplete.title')}
            </div>
            <Hexagon
              className="w-[42vw] max-w-[12rem] h-[42vw] max-h-[12rem] mx-auto mt-[2.5vw] sm:mt-4 mb-[5vw] sm:mb-6"
              value={xp}
              suffix="XP"
            />
            <div className="flex flex-col items-center">
              <div className="text-primary text-[6vw] sm:text-5xl">
                {t('XPWallet.streakComplete.bonusName')}
              </div>
              <span className="flex items-center text-[5vw] sm:text-4xl mt-[1vw]">
                <IonIcon
                  icon={checkmark}
                  className="text-primary text-[6vw] sm:text-5xl"
                  slot="start"
                />
                {t('XPWallet.streakComplete.status')}
              </span>
            </div>
          </Title>
        </div>
      </div>
    </IonPage>
  )
}

export const StreakCompletedModal = memo(StreakCompletedModalComponent)

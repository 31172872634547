import { FC, memo } from 'react'

type InstagramLogoProps = {
  className?: string
}

const InstagramLogoComponent: FC<InstagramLogoProps> = ({ className = '' }) => {
  return (
    <picture>
      <source
        srcSet={`/social_icons/instagram-color@2x.png 56w, /social_icons/instagram-color@3x.png 84w`}
      />
      <img
        className={`w-12 h-12 ${className}`}
        src={`/social_icons/instagram-color@2x.png`}
        alt="Instagram-active"
      />
    </picture>
  )
}

export const InstagramLogo = memo(InstagramLogoComponent)

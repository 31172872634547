import { FC, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IonModal, isPlatform } from '@ionic/react'

import {
  DepositCurrency,
  DepositCurrencyRef,
} from '@/components/organisms/DepositCurrency'

import { appConfig } from '@/services/config'
import { useCreateTransaction } from '@/lib/hooks/wallet'
import { queryClient } from '@/lib/queryClient'

import { QueryKeys } from '@/enums'

import { Summary } from './Summary'
import { TippingForm } from './TippingForm'

type TippingModalProps = {
  modalId: string
  userId: UUID
  initialAmount?: number
}

export const TippingModal: FC<TippingModalProps> = ({
  modalId,
  userId,
  initialAmount,
}) => {
  const modalRef = useRef<HTMLIonModalElement>(null)
  const depositCurrencyRef = useRef<DepositCurrencyRef>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const isDesktop = isPlatform('desktop')
  const { t } = useTranslation()

  const {
    createTransactionData,
    createTransaction,
    isLoadingTransactionData,
    resetTransactionData,
  } = useCreateTransaction({ userId })
  const currency = appConfig.currency

  const dismissModal = useCallback(() => modalRef.current?.dismiss(), [])

  const handleBuyMoreCurrency = useCallback(() => {
    modalRef?.current?.dismiss()
    depositCurrencyRef?.current?.openModal()
  }, [])

  return (
    <>
      <IonModal
        ref={modalRef}
        trigger={modalId}
        className="tipping-modal"
        initialBreakpoint={isDesktop ? undefined : 1}
        breakpoints={isDesktop ? undefined : [0, 1]}
        onDidPresent={() => inputRef.current?.focus()}
        onDidDismiss={() => {
          if (createTransactionData) {
            queryClient.invalidateQueries({
              queryKey: [
                QueryKeys.Wallet,
                createTransactionData?.destinationWalletId,
              ],
            })
          }

          resetTransactionData()
        }}
        onWillDismiss={() => window.scrollTo({ top: 0, behavior: 'instant' })}
      >
        <div className="flex flex-col items-center text-center sm:w-3/4 px-8 pt-5 pb-5 sm:py-8">
          {createTransactionData ? (
            <Summary
              dismissModal={dismissModal}
              createTransactionData={createTransactionData}
            />
          ) : (
            <TippingForm
              inputRef={inputRef}
              createTransaction={createTransaction}
              onBuyMoreCurrency={handleBuyMoreCurrency}
              isLoadingTransactionData={isLoadingTransactionData}
              initialAmount={initialAmount}
            />
          )}
        </div>
      </IonModal>
      <DepositCurrency
        title={t('depositBottomSheet.topUpCurrency', { currency })}
        ref={depositCurrencyRef}
      />
    </>
  )
}

import { FC } from 'react'
import { IonLabel } from '@ionic/react'

import { sanitiseMarkdownString } from '@/utils/formValidators'

type CharacterCountProps = {
  currentInput: string
  maxLength: number
}

export const CharacterCount: FC<CharacterCountProps> = ({
  currentInput,
  maxLength,
}) => {
  const currentLength = sanitiseMarkdownString(currentInput).length

  return (
    <IonLabel
      className="text-right block mt-2 mb-1"
      color={currentLength <= maxLength ? 'primary' : 'danger'}
    >{`${currentLength}/${maxLength}`}</IonLabel>
  )
}

import { get, post } from '@/services/awsAmplify'

export type OpenAppStreakData = {
  day: number
  of: number
}

export type ClaimSurpriseBoxData = {
  reward: number
}

export const getOpenAppStreak = async () =>
  <Promise<OpenAppStreakData>>get({ path: '/opened-app-streak' })

export const claimSurpriseBox = async (): Promise<ClaimSurpriseBoxData> => {
  return (await post({
    path: '/claim-surprise-box',
  })) as unknown as ClaimSurpriseBoxData
}

import { FC, useContext, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonCheckbox, IonItem, IonSpinner, useIonModal } from '@ionic/react'
import cx from 'classnames'

import { Button } from '@/components/atoms/Button'
import { OpenAppStreakComponentBackground } from '@/components/atoms/OpenAppStreakComponentBackground'
import { StreakProgress } from '@/components/molecules/StreakProgress'

import { ClaimSurpriseBoxData } from '@/services/openAppStreak'
import { useClaimSurpriseBox } from '@/lib/hooks/useClaimSurpriseBox'
import { useOpenAppStreak } from '@/lib/hooks/useOpenAppStreak/useOpenAppStreak'
import { Context } from '@/lib/store'
import { ActionType } from '@/lib/store/reducer'

import { StreakCompletedModal } from '../StreakCompletedModal'

export const ShouldShowStreakRemindersStorageKey =
  'should-show-streak-reminders'

type OpenAppStreakComponentProps = {
  refetchAccountInfo: () => void
}

export const OpenAppStreakComponent: FC<OpenAppStreakComponentProps> = ({
  refetchAccountInfo,
}) => {
  const { t } = useTranslation('common')
  const { state, dispatch } = useContext(Context)
  const [reward, setReward] = useState<number>(0)

  const {
    data: openAppStreakData,
    refetch: refetchOpenAppStreak,
    isLoading: isLoadingStreak,
    error: openAppStreakError,
  } = useOpenAppStreak()

  const [openModal, closeModal] = useIonModal(StreakCompletedModal, {
    onDismiss: () => closeModal(),
    xp: reward,
  })

  const streakDay = openAppStreakData?.day || 1
  const streakDuration = openAppStreakData?.of || 7
  const streakDaysLeft = streakDuration - streakDay
  const isCompleted = streakDay >= streakDuration

  const { mutate: claimReward, isPending: isLoadingClaim } =
    useClaimSurpriseBox({
      onSuccess: (data: ClaimSurpriseBoxData) => {
        setReward(data.reward)
        openModal({ cssClass: 'full-screen-modal' })
        refetchAccountInfo()
        refetchOpenAppStreak()
      },
    })

  const isChecked = useMemo(() => {
    if (typeof state.shouldShowStreakReminders === 'boolean') {
      return state.shouldShowStreakReminders
    }

    const storedValue = localStorage.getItem(
      ShouldShowStreakRemindersStorageKey
    )

    return !storedValue || storedValue === 'true'
  }, [state.shouldShowStreakReminders])

  const handleCheckboxChange = async (checked: boolean) => {
    dispatch({ type: ActionType.SHOW_STREAK_REMINDERS, payload: checked })
    localStorage.setItem(
      ShouldShowStreakRemindersStorageKey,
      checked.toString()
    )
  }

  return (
    <IonItem className="no-ripple">
      <div
        className={cx('relative w-full mt-6 mb-4', {
          'h-[calc((100vw-2rem)*0.771)] max-h-[calc(38rem*0.771)]': isCompleted,
          'h-[calc((100vw-2rem)*0.66)] max-h-[calc(38rem*0.6)]': !isCompleted,
        })}
      >
        <OpenAppStreakComponentBackground isCompleted={isCompleted} />
        {isLoadingStreak ? (
          <div className="absolute transform left-1/2 top-1/2 -translate-x-2/4 -translate-y-2/4">
            <IonSpinner color="main" />
          </div>
        ) : openAppStreakError ? (
          <div className="absolute transform left-1/2 top-1/2 -translate-x-2/4 -translate-y-2/4 text-center text-danger">
            {t('XPWallet.errors.default')}
          </div>
        ) : (
          <div className="absolute h-full flex flex-col justify-between mx-[4%] pt-[5%] pb-[5%] text-white">
            <div
              className={cx(`flex flex-col grow justify-around`, {
                'flex-2': isCompleted,
                'flex-3': !isCompleted,
              })}
            >
              <div className="flex flex-row justify-between items-center font-bold">
                <p className="sm:w-[11.5rem] text-[4.5vw] sm:text-3xl">
                  {t('XPWallet.bonusStreak.streakBonus')}
                </p>
                <p className="text-[3.75vw] sm:text-2xl w-[33vw] max-w-xs text-right">
                  {t('XPWallet.bonusStreak.countDaysStreak', {
                    count: streakDuration,
                  })}
                </p>
              </div>
              <StreakProgress
                streakDay={streakDay}
                streakDuration={streakDuration}
              />
            </div>
            <div
              className={cx(`flex flex-col grow`, {
                'flex-3': isCompleted,
                'flex-2': !isCompleted,
              })}
            >
              {isCompleted ? (
                <div className="ml-[30%] sm:pt-3">
                  <p className="text-primary font-bold text-[4.75vw] sm:text-3xl sm:mb-2">
                    {t('XPWallet.bonusStreak.streakCompleted')}
                  </p>
                  {/* After increasing this font size confirm the component looks good on
                  Samsung Galaxy Fold, Google Pixel 6 Pro, or other long and narrow phone */}
                  <p className="text-white font-medium text-[3vw] sm:text-xl">
                    <Trans
                      i18nKey={'XPWallet.bonusStreak.surpriseBoxIsWaiting'}
                      components={{
                        primary: <span className="text-primary font-bold" />,
                      }}
                    >
                      {t('XPWallet.bonusStreak.surpriseBoxIsWaiting')}
                    </Trans>
                  </p>
                  <Button
                    className="mt-[6%] w-[40%] h-[31%]"
                    onClick={() => claimReward()}
                    isDisabled={isLoadingClaim}
                  >
                    <span className="items-center text-[3.75vw] sm:text-2xl">
                      {t('XPWallet.bonusStreak.claimReward')}
                    </span>
                  </Button>
                </div>
              ) : (
                <>
                  <p className="text-center text-[3.75vw] sm:text-2xl h-full">
                    <Trans
                      i18nKey={'XPWallet.bonusStreak.stayActive_other'}
                      components={{
                        primary: <span className="text-primary font-bold" />,
                      }}
                      count={streakDaysLeft}
                    >
                      {t('XPWallet.bonusStreak.stayActive_other', {
                        count: streakDaysLeft,
                      })}
                    </Trans>
                  </p>
                  <div className="flex justify-start items-center relative">
                    <IonCheckbox
                      className="checkbox-square"
                      labelPlacement="end"
                      justify="start"
                      checked={isChecked}
                      onIonChange={(e) =>
                        handleCheckboxChange(e.detail.checked)
                      }
                    >
                      <p className="text-[3.25vw] sm:text-xl">
                        {t('XPWallet.bonusStreak.showReminders')}
                      </p>
                    </IonCheckbox>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </IonItem>
  )
}

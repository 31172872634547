import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonSpinner } from '@ionic/react'

import { Button } from '@/components/atoms/Button'

import { appConfig } from '@/services/config'
import { useLaunchToken } from '@/lib/hooks/useLaunchToken'

type LaunchButtonType = {
  isDisabled?: boolean
}

export const LaunchButton: FC<LaunchButtonType> = ({ isDisabled = false }) => {
  const { t } = useTranslation()

  const { launchToken, isPending } = useLaunchToken()

  return (
    <div className="flex flex-col">
      <Button
        className="mt-2 mb-5 mx-auto min-w-[9rem]"
        isDisabled={isDisabled || isPending}
        onClick={() => launchToken()}
      >
        {isPending ? (
          <IonSpinner color="light" />
        ) : (
          t('tokenLaunch.mintYourToken', {
            creatorToken: appConfig.creatorToken,
          })
        )}
      </Button>
    </div>
  )
}

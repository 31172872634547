import { useMutation } from '@tanstack/react-query'

import { getFeeCalculation } from '@/services/wallet/getWallet'

import { useUserInfo } from '../../useUserInfo'

export const useUnlockPaymentFee = () => {
  const { data: authUser } = useUserInfo()

  const {
    data: feeData,
    mutate: getFee,
    isPending: isLoadingFee,
    reset: resetFeeData,
    error,
  } = useMutation({
    mutationFn: async (value: string) => getFeeCalculation(authUser.id, value),
  })

  return {
    error,
    feeData,
    getFee,
    isLoadingFee,
    resetFeeData,
  }
}

import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'

import { acceptableUsageAgreement } from '@/services/subscription'
import { UserInfo } from '@/services/user'
import { queryClient } from '@/lib/queryClient'

export const useAcceptableUsePolicy = () => {
  const userData = queryClient.getQueryData<UserInfo>(['user'])
  const [acceptableUsePolicy, setAcceptableUsePolicy] = useState(
    !!userData?.aup
  )
  const {
    mutate: mutateAcceptableUsePolicy,
    error: acceptableUsePolicyError,
    isPending: isAcceptableUsePolicyPending,
  } = useMutation({
    mutationFn: acceptableUsageAgreement,
    onSuccess: () => {
      setAcceptableUsePolicy(true)
      queryClient.invalidateQueries({ queryKey: ['user'] })
    },
  })

  useEffect(() => {
    setAcceptableUsePolicy(!!userData?.aup)
  }, [userData?.aup])

  return {
    acceptableUsePolicy,
    mutateAcceptableUsePolicy,
    acceptableUsePolicyError,
    isAcceptableUsePolicyPending,
  }
}

import { useTranslation } from 'react-i18next'

import { Title } from '../../atoms/Title'

export const NoResults = () => {
  const { t } = useTranslation('common')

  return (
    <div className="flex justify-center content-center mt-10 text-center">
      <div>
        <Title level={3}>{t('common.noResults')}</Title>
        <img src="/no-results.svg" alt="no results" className="w-[15.25rem]" />
      </div>
    </div>
  )
}

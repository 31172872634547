import OneSignal from 'react-onesignal'

import { appConfig } from './config'
import { getToken } from './token'

export const webPushNotificationsInit = async (): Promise<void> => {
  if (!appConfig.oneSignalAppId) return

  try {
    await OneSignal.init({
      appId: appConfig.oneSignalAppId,
      allowLocalhostAsSecureOrigin: true,
      serviceWorkerParam: {
        scope: '/push/onesignal/',
      },
      serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
      serviceWorkerUpdaterPath: 'push/onesignal/OneSignalSDKUpdaterWorker.js',
    })
    OneSignal.showSlidedownPrompt()
  } catch (e) {
    console.error(`push notification init error ${e}`)
  }
}

export const setWebPushNotificationsExternalUserId = async (id: UUID) => {
  if (!appConfig.oneSignalAppId) return

  try {
    const token = await getToken()
    return await OneSignal.setExternalUserId(id, token.oneSignalUserIdToken)
  } catch (e) {
    console.error(`push notification setExternalUserId error ${e}`)
  }
}

// ref: https://documentation.onesignal.com/v9.0/docs/identity-verification#removing-external-user-id
export const removeWebPushNotificationsExternalUserId = async (id: UUID) => {
  if (!appConfig.oneSignalAppId) return

  try {
    return await OneSignal.setExternalUserId(id, '')
  } catch (e) {
    console.error(`push notification removeExternalUserId error ${e}`)
  }
}

import { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/Button'

import { appConfig } from '@/services/config'

import { SectionsButtonsTab } from '../../UserProfile/components'
import { useProfileSections } from '../../UserProfile/hooks/useProfileSections'
import { generatePlaceholders } from '../../UserProfile/utils'
import { useRedirect } from '../hooks/useRedirect'

type PostsGridType = {
  username: string
  posts: string[]
  hasSubscription: boolean
  creatorTokenId: string
}

export const PostsGrid: FC<PostsGridType> = ({
  username,
  posts,
  hasSubscription,
  creatorTokenId,
}) => {
  const { creatorToken } = appConfig
  const { t } = useTranslation('common')

  const { redirectToLogin } = useRedirect()

  const subscriptionFeedError = useMemo(() => {
    return hasSubscription ? {} : { response: { status: 404 } }
  }, [hasSubscription])

  const { normalizedSections } = useProfileSections({
    subscriptionFeedError,
    isSubscriptionFeedLoading: false,
    isSubscriptionFeedFetching: false,
    username,
  })

  const array = Array.from(Array(6))
  const mappedItems = useMemo(
    () =>
      generatePlaceholders(array).map((item, index) => {
        if (!posts) return

        if (!posts[index])
          return (
            <div
              key={index}
              className="w-1/3 h-[44.44vw] max-h-[284px] flex flex-none overflow-hidden border-[1px] box-border border-cool-gray-900 bg-cool-gray-900 z-10"
            />
          )
        return (
          <div
            key={posts[index]}
            className="w-1/3 h-[44.44vw] max-h-[284px] flex flex-none overflow-hidden border-[1px] box-border border-cool-gray-900"
          >
            <GridItem postUrl={posts[index]} />
          </div>
        )
      }),
    [posts, array]
  )

  const publicNormalizedSections = useMemo(() => {
    if (creatorTokenId == null) {
      return normalizedSections.filter((item) => item.name !== creatorToken)
    }

    return normalizedSections.sort((a, b) => {
      if (a.name === 'posts') return -1
      if (b.name === 'posts') return 1
      if (a.name === creatorToken) return 1
      if (b.name === creatorToken) return -1
      return 0
    })
  }, [normalizedSections, creatorTokenId, creatorToken])

  const redirectToMarket = () => {
    window.open(`${appConfig.marketAppOrigin}/${username}`)
  }

  return (
    <>
      <SectionsButtonsTab
        sections={publicNormalizedSections}
        selectedSectionIndex={0}
        handleSectionClick={null}
        handleButtonClick={redirectToLogin}
      />

      <div
        className="relative"
        onClick={creatorTokenId != null ? redirectToMarket : redirectToLogin}
      >
        <section className="absolute w-full h-full flex flex-wrap">
          {mappedItems}
        </section>

        <div className="absolute w-full h-[90vw] max-h-[35.5rem] z-10 bg-gradient-to-b from-cool-gray-900/30 via-cool-gray-900/80 to-cool-gray-900 flex flex-col justify-center items-center">
          <img
            className="mt-6 mb-[0.88rem]"
            src="/logo-icon-only.svg"
            alt="logo"
          />
          <h1 className="text-[8vw] sm:text-5xl text-center mb-2">
            {creatorTokenId != null
              ? t('publicProfile.creatorToken.title', { creatorToken })
              : t('publicProfile.teaser.title')}
          </h1>
          <div className="font-bold text-center px-2 mb-[0.88rem]">
            <Trans>
              {creatorTokenId != null
                ? t('publicProfile.creatorToken.subtitle', {
                    username,
                    creatorToken,
                  })
                : t('publicProfile.teaser.subtitle', { username })}
            </Trans>
          </div>
          <Button
            className="mb-[5rem]"
            size="large"
            onClick={
              creatorTokenId != null ? redirectToMarket : redirectToLogin
            }
          >
            {creatorTokenId != null
              ? t('publicProfile.creatorToken.button', { creatorToken })
              : t('publicProfile.teaser.button')}
          </Button>
        </div>
      </div>
    </>
  )
}

const GridItem: FC<{ postUrl: string }> = ({ postUrl }) => {
  return (
    <div className="relative w-full h-full cursor-pointer">
      {postUrl && (
        <div className="w-1/3 h-[44.44vw] max-h-[284px] flex flex-none overflow-hidden border-[1px] box-border border-cool-gray-900">
          <div
            className="absolute w-full h-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${postUrl})`,
            }}
          />
        </div>
      )}
    </div>
  )
}

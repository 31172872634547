import { record } from 'aws-amplify/analytics/kinesis'
import { fetchAuthSession } from 'aws-amplify/auth'

import { appConfig } from '@services/config'

export async function recordTrack({ data = {} }) {
  const identityId = (await fetchAuthSession())?.identityId
  record({
    streamName: appConfig.kinesis,
    partitionKey: identityId,
    data,
  })
}

export async function sessionStartTrack() {
  const identityId = (await fetchAuthSession())?.identityId
  record({
    streamName: appConfig.kinesis,
    partitionKey: identityId,
    data: {
      name: '_session.start',
      data: {
        data: {
          eventName: '_session.start',
        },
      },
      immediate: true,
    },
  })
}

export async function sessionStopTrack() {
  const identityId = (await fetchAuthSession())?.identityId
  record({
    streamName: appConfig.kinesis,
    partitionKey: identityId,
    data: {
      name: '_session.stop',
      data: {
        data: {
          eventName: '_session.stop',
        },
      },
      immediate: true,
    },
  })
}

export async function pageViewTrack() {
  const identityId = (await fetchAuthSession())?.identityId
  record({
    streamName: appConfig.kinesis,
    partitionKey: identityId,
    data: {
      name: 'pageView',
      data: {
        eventName: 'pageView',
        appType: 'singlePage',
        url: window.location.origin + window.location.pathname,
      },
      immediate: true,
    },
  })
}

export {
  recordTrack as record,
  sessionStartTrack as recordStartSession,
  sessionStopTrack as recordStopSession,
  pageViewTrack as recordPageView,
}

import { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonItem } from '@ionic/react'

import { ActivityItemPreviewImage } from '@/components/atoms/ActivityItemPreviewImage'
import { Avatar } from '@/components/atoms/Avatar'
import { Button } from '@/components/atoms/Button'
import { UserName } from '@/components/atoms/UserName'

import { useNavigate } from '@/lib/routing'
import { formatDistance } from '@/utils/date'
import { getThumbnailImage } from '@/utils/url'

import { ActivityTypeEnum } from '@/types/activity'
import {
  ActivityMentioned,
  ActivityMentionedInCommentData,
  ActivityMentionedInConversationData,
  ActivityMentionedInPostData,
} from '@/types/activity/mentioned'

interface IActivityMentionItemProps {
  item: ActivityMentioned
}

export const ActivityMentionItem: FC<IActivityMentionItemProps> = ({
  item,
}) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const { type, data } = item

  const previewImageUrlMap = useMemo(() => {
    return {
      [ActivityTypeEnum.MentionedInConversation]: (
        data as ActivityMentionedInConversationData
      ).conversation?.previewImageUrl,
      [ActivityTypeEnum.MentionedInPost]: (data as ActivityMentionedInPostData)
        .post?.previewImageUrl,
      [ActivityTypeEnum.MentionedInComment]: (
        data as ActivityMentionedInCommentData
      ).previewImageUrl,
    }
  }, [data])

  const previewImageUrl = useMemo(() => {
    if (!previewImageUrlMap[type]) return ''
    return getThumbnailImage(previewImageUrlMap[type])
  }, [previewImageUrlMap, type])

  const i18nKey = 'activity.item.mention.' + type

  const handleOnClick = () => {
    const newPath = `/comments/${data.activityId}`

    navigate(newPath)
  }

  return (
    <IonItem className="py-4 border-solid border-b-[0.0625rem] border-zinc-800">
      <span className="pr-4">
        <Avatar
          username={item.data.actor.username}
          userId={item.data.actor.id}
          isModerator={item.data.actor.isModerator}
          isAmbassador={item.data.actor.isAmbassador}
          badge={item.data.actor.badge}
          clickable
        />
      </span>
      <div
        className="w-full flex justify-between items-center cursor-pointer"
        onClick={handleOnClick}
      >
        <div className="flex flex-col justify-between mr-1">
          <div className="text-sm">
            <Trans
              i18nKey={i18nKey}
              components={{
                userLink: (
                  <UserName
                    className="inline font-bold"
                    userId={item.data.actor.id}
                    name={item.data.actor.username}
                  />
                ),
              }}
            >
              {t(i18nKey)}
            </Trans>
          </div>
          <div className="text-xs text-zinc-600">
            {formatDistance(item.time)}
          </div>
        </div>
        {previewImageUrlMap[type] ? (
          <ActivityItemPreviewImage
            previewImageUrl={previewImageUrl}
            className="cursor-pointer"
          />
        ) : (
          <Button size="small" className="min-w-[5.5rem]">
            {t('activity.item.view')}
          </Button>
        )}
      </div>
    </IonItem>
  )
}

import { FC, useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isPlatform } from '@ionic/react'
import cx from 'classnames'

import { Currency } from '@/components/atoms/Currency'
import { CurrencyInput } from '@/components/atoms/CurrencyInput'

import {
  IWithdrawEstimation,
  WithdrawErrorMessage,
} from '@/services/wallet/withdraw'

import { FormValues } from '../../Withdraw'

type AmountProps = {
  estimation: IWithdrawEstimation
  isSummary?: boolean
}

export const Amount: FC<AmountProps> = ({ estimation, isSummary = false }) => {
  const { t } = useTranslation('common')
  const { control, watch } = useFormContext<FormValues>()
  const amount = watch('amount')

  const hasAmountError = useMemo(
    () =>
      [
        WithdrawErrorMessage.SOURCE_WALLET_INSUFFICIENT_FOUNDS,
        WithdrawErrorMessage.INVALID_MIN_AMOUNT,
        WithdrawErrorMessage.INVALID_MAX_AMOUNT,
        WithdrawErrorMessage.NON_POSITIVE_AMOUNT,
      ].includes(WithdrawErrorMessage[estimation?.errorMessage]),
    [estimation?.errorMessage]
  )

  const AmountInput = useCallback(
    () => (
      <div
        className={cx(
          'flex flex-row justify-between items-end mb-2 font-bold',
          { 'text-primary': !hasAmountError, 'text-danger': hasAmountError }
        )}
      >
        <span className="text-lg">{t('withdraw.amount')}</span>
        <Controller
          control={control}
          name="amount"
          rules={{ required: true }}
          render={({ field }) => (
            <CurrencyInput
              value={field.value}
              className="focus:outline-none w-1/2 bg-transparent text-right text-[1.85rem] font-bold placeholder-primary"
              autoFocus={!isPlatform('ios')}
              onChange={(e) => {
                field.onChange(e.target.value)
              }}
            />
          )}
        />
      </div>
    ),
    [control, hasAmountError, t]
  )

  const Amount = useCallback(
    () => (
      <div className="flex justify-center items-baseline text-primary font-bold mb-6">
        <span className="text-[2.5rem]">
          <Currency value={Number(amount)} />
        </span>
      </div>
    ),
    [amount]
  )

  return !isSummary ? <AmountInput /> : <Amount />
}

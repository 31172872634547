import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonTitle } from '@ionic/react'
import { useQuery } from '@tanstack/react-query'

import { BackButton } from '@/components/atoms/BackButton'
import { Loading } from '@/components/atoms/Loading'
import { Title } from '@/components/atoms/Title'
import { Main } from '@/components/templates/main'
import { Connect } from './components/Connect'
import { Disconnect } from './components/Disconnect'
import { Failed } from './components/Failed'

import { get } from '@/services/awsAmplify'
import { useRouterQuery } from '@/lib/hooks/useRouterQuery'
import { queryClient } from '@/lib/queryClient'

import { QueryKeys } from '@/enums'

import { useTiktok } from './hooks/useTiktok'

export const TiktokPage = () => {
  const { t } = useTranslation('common')
  const query = useRouterQuery()
  const code = query.get('code')

  const referrer = queryClient.getQueryData<string>([QueryKeys.SocialBackUrl])

  const { data, isLoading: isUrlLoading } = useQuery({
    queryKey: ['tiktok, login'],
    queryFn: () =>
      get({
        path: '/socials/tiktok/login',
      }) as unknown as Promise<{ url: string; state: string }>,
    gcTime: 0,
    staleTime: 0,
    enabled: !code,
    refetchOnMount: true,
  })

  const {
    connectionInfo,
    isConnectPending,
    isSocialsListLoading,
    connectError,
    mutateDisconnect,
  } = useTiktok({
    code,
    state: data?.state,
  })

  const header = useCallback(
    () => (
      <>
        <IonButtons slot="start">
          <BackButton className="text-white" defaultHref={referrer} />
        </IonButtons>
        <IonTitle>
          <Title level={2}>{t('registration.profile.tiktok')}</Title>
        </IonTitle>
      </>
    ),
    [referrer, t]
  )

  const Component = () => {
    if (isSocialsListLoading || isConnectPending || isUrlLoading) {
      return <Loading />
    }

    if (connectionInfo?.providerUserName) {
      return (
        <Disconnect
          userName={connectionInfo.providerUserName}
          handleDisconnection={mutateDisconnect}
        />
      )
    }

    if (connectError) {
      return <Failed authUrl={data.url} />
    }

    return <Connect authUrl={data.url} />
  }

  return (
    <Main header={header()} isPaddingEnabled={false}>
      {Component()}
    </Main>
  )
}

import { memo } from 'react'

export interface BackButtonIconProps {
  className?: string
}

const BackButtonIconComponent = ({ className }: BackButtonIconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 19.5L7.5 12L15 4.5"
        stroke="#CCFF00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const BackButtonIcon = memo(BackButtonIconComponent)

import cx from 'classnames'

import { Chat } from '@/components/atoms/assets/OldChat'

import { useChatButton } from '@/lib/hooks/useChatButton'
import { Link } from '@/lib/routing'

interface ChatButtonProps {
  userId?: UUID
  className?: string
  onClick?: () => void
  page?: string
}

export const ChatButton = ({
  userId,
  className,
  onClick,
  page,
}: ChatButtonProps) => {
  const { unreadCount } = useChatButton(userId)

  return (
    <Link
      className={cx('plain relative', className)}
      onClick={(e) => {
        e.stopPropagation()
        onClick?.()
      }}
      to={userId ? `/messages/${userId}` : `/messages?page=${page}`}
    >
      <Chat />
      {!!unreadCount && (
        <i className="absolute top-1 -right-0.5 w-2 h-2 bg-red-500 rounded-full" />
      )}
    </Link>
  )
}

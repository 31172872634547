import { FC, memo, useMemo } from 'react'
import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { appConfig } from '@/services/config'
import { Link } from '@/lib/routing'

import { IPromoPool } from '@/interfaces'

import { usePromoPool } from '../../hooks/usePromoPools'

const BannersPromoWidgetComponent: FC<{ pool: IPromoPool }> = ({ pool }) => {
  const { data, isLoading, fetchNextPage, hasNextPage } = usePromoPool(pool)

  const getCdnUrl = (url) => `${appConfig.cdn}${url}`

  const paginatorClassName = useMemo(
    () => `paginator-${Math.floor(Math.random() * 10000)}`,
    []
  )

  if (data?.length < 1 || isLoading) {
    return null
  }

  return (
    <section className="px-3 py-3">
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={4}
        onReachEnd={() => {
          if (!hasNextPage) return

          fetchNextPage()
        }}
        direction="horizontal"
        lazyPreloadPrevNext={2}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true, el: `.${paginatorClassName}` }}
      >
        {data?.map((banner, index) => {
          const linkProps = banner.url?.startsWith('/') // check for internal link
            ? {}
            : { target: '_blank', rel: 'noopener' }

          return (
            <SwiperSlide key={banner.id} virtualIndex={index}>
              <Link
                className="text-center block w-full"
                to={banner.url}
                {...linkProps}
              >
                <div className="relative w-full pb-bnr">
                  <img
                    src={getCdnUrl(banner.imageUrl)}
                    className="absolute !h-full !w-full object-cover"
                  />
                </div>
              </Link>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className={`${paginatorClassName} text-center swiper-banner`}></div>
    </section>
  )
}

export const BannersPromoWidget = memo(BannersPromoWidgetComponent)

import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/Button'
import { BuyCurrencyPopup } from '@/components/organisms/BuyCurrencyPopup'

const BuyButtonComponent = () => {
  const { t } = useTranslation('common')
  const [isBuyCurrencyConfirmationOpen, setBuyCurrencyConfirmationOpen] =
    useState(false)

  const buyCurrency = useCallback(() => {
    setBuyCurrencyConfirmationOpen(true)
  }, [])

  return (
    <>
      <Button className="min-w-[60px]" onClick={buyCurrency} type="primary">
        <span className="text-xxs xs:text-xs">{t('wallet.buy')}</span>
      </Button>
      <BuyCurrencyPopup
        setBuyCurrencyConfirmationOpen={setBuyCurrencyConfirmationOpen}
        isBuyCurrencyConfirmationOpen={isBuyCurrencyConfirmationOpen}
      />
    </>
  )
}

export const BuyButton = memo(BuyButtonComponent)

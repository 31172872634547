import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { get } from '@/services/awsAmplify'

type GetSharedMediaData = {
  code: number
  msg: string
  processed_time: number
  data: {
    aweme_id: string
    id: string
    region: string
    title: string
    cover: string
    ai_dynamic_cover: string
    origin_cover: string
    duration: number
    play: string
    wmplay: string
    size: number
    wm_size: number
    music: string
    music_info: {
      id: string
      title: string
      play: string
      cover: string
      author: string
      original: true
      duration: number
      album: string
    }
    play_count: number
    digg_count: number
    comment_count: number
    share_count: number
    download_count: number
    collect_count: number
    create_time: number
    anchors: null
    anchors_extras: string
    is_ad: false
    commerce_info: {
      adv_promotable: false
      auction_ad_invited: false
      branded_content_type: number
      with_comment_filter_words: false
    }
    commercial_video_info: string
    item_comment_settings: number
    author: {
      id: string
      unique_id: string
      nickname: string
      avatar: string
    }
    images?: string[]
  }
}

export const useGetSharedMedia = (
  url?: string,
  options?: Omit<UseQueryOptions<GetSharedMediaData>, 'queryKey'>
) => {
  return useQuery({
    queryKey: ['shared-content', url],
    queryFn: getSharedMedia,
    ...options,
  })
}

export const getSharedMedia = async ({ queryKey }) => {
  const url = queryKey[1]

  return get({
    path: '/public/autodownload',
    params: { url },
  })
}

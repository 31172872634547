import { FC, memo, useRef, useState } from 'react'
import { IonIcon } from '@ionic/react'
import { playCircleOutline } from 'ionicons/icons'

import { Loading } from '@/components/atoms/Loading'

interface IVideoThumbnailSelector {
  videoUrl: string
  thumbnailUrl: string
  muted?: boolean
}

export const VideoThumbnailSelectorComponent: FC<IVideoThumbnailSelector> = ({
  videoUrl,
  thumbnailUrl,
  muted = false,
}) => {
  const [isPlaying, setPlaying] = useState(false)
  const containerRef = useRef<HTMLVideoElement>(null)

  return videoUrl ? (
    <div className="w-full">
      <div className="relative flex justify-center items-center">
        {!isPlaying && !!thumbnailUrl && (
          <div
            className="absolute top-0 left-0 w-full h-full flex justify-center items-center"
            onClick={() => {
              setPlaying(true)
              containerRef.current.play()
            }}
          >
            <IonIcon
              className="absolute z-20 text-[3.75rem]"
              slot="icon-only"
              icon={playCircleOutline}
            />
            <img
              className="absolute top-0 left-0 w-fit z-10"
              src={thumbnailUrl}
              width="200"
              height="500"
            />
          </div>
        )}
        <video
          className="relative"
          ref={containerRef}
          autoPlay={!thumbnailUrl}
          loop
          muted={muted}
          playsInline
          controls={false}
          onClick={() => {
            if (thumbnailUrl) {
              setPlaying(false)
              containerRef.current.pause()
            }
          }}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </div>
    </div>
  ) : (
    <Loading />
  )
}

export const VideoThumbnailSelector = memo(VideoThumbnailSelectorComponent)

import { FC, MouseEventHandler, ReactNode } from 'react'
import cx from 'classnames'

interface SubscribeButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>
  children: ReactNode
  className?: string
  type?: 'default' | 'gradient'
  isDisabled?: boolean
}

export const SubscribeButton: FC<SubscribeButtonProps> = ({
  children,
  className,
  type = 'default',
  isDisabled = false,
  onClick,
}) => {
  return (
    <>
      <button
        onClick={onClick}
        className={cx(
          'h-8 text-xs text-black font-bold mx-auto flex items-center py-1 px-4 rounded-full bg-[#CCFF00]',
          className,
          {
            'text-white gradient': type === 'gradient',
            'opacity-50': isDisabled,
          }
        )}
        disabled={isDisabled}
      >
        {children}
      </button>
    </>
  )
}

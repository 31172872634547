import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'

import Clipboard from '@/utils/clipboard'

export const useHandleCopy = () => {
  const { t } = useTranslation('common')
  const [presentToast] = useIonToast()

  const handleCopy = useCallback(
    async (text: string) => {
      await Clipboard.write(text)

      presentToast({
        message: t('copied'),
        position: 'top',
        duration: 3000,
      })
    },
    [presentToast, t]
  )

  return handleCopy
}

/* eslint-disable @typescript-eslint/no-empty-function */
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from '@/components/atoms/Avatar'
import { Slide, Slider } from '@/components/molecules/Slider'

import { Link } from '@/lib/routing'

import { Badge } from '@/enums'

type User = {
  id: UUID
  username: string
  isAmbassador: boolean
  badge?: Badge
  xp: number
}

type CreatorsSliderProps = {
  users: User[]
}

export const CreatorsSlider: FC<CreatorsSliderProps> = ({ users }) => {
  const { t } = useTranslation('common')
  if (!users?.length) return null

  return (
    <div className="px-4 py-3 text-white">
      <div className="text-lg font-bold pb-4">
        {t('discover.topContentCreators', { count: users?.length })}
      </div>
      <Slider onCloseToTheEnd={() => {}}>
        {users.map(({ id, username, isAmbassador, badge }) => (
          <Slide key={id}>
            <Link
              className="flex flex-col w-[19.5vw] max-w-[5.6rem] text-center"
              to={`/@${username}`}
            >
              <Avatar
                className="pb-2 w-14 h-14 mx-auto"
                userId={id}
                username={username}
                size="large"
                isAmbassador={isAmbassador}
                badge={badge}
              />
              <p className="w-full text-xs font-bold truncate">@{username}</p>
            </Link>
          </Slide>
        ))}
      </Slider>
    </div>
  )
}

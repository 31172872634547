import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CodeInput } from '@/components/atoms/CodeInput'

import Clipboard from '@/utils/clipboard'

type AuthenticatorCodeProps = {
  codeLength: number
  code: string
  setCode: (value: string) => void
  error: string
}

export const AuthenticatorCode: FC<AuthenticatorCodeProps> = ({
  codeLength,
  code,
  setCode,
  error,
}) => {
  const { t } = useTranslation('common')

  const handlePaste = useCallback(async () => {
    const value = await Clipboard.read()
    setCode(value.trim().slice(0, codeLength))
  }, [codeLength, setCode])

  return (
    <>
      <div>
        <div className="text-[0.6875rem] text-cool-gray-300 font-medium uppercase mb-2">
          {t('setup2FA.authenticatorCode')}
        </div>
        <div className="flex justify-between items-center">
          <CodeInput codeLength={codeLength} value={code} onChange={setCode} />
          <span
            className="text-[0.6875rem] font-medium uppercase cursor-pointer ml-6"
            onClick={handlePaste}
          >
            {t('setup2FA.paste')}
          </span>
        </div>
      </div>
      {error && <span className="text-sm text-danger mt-1">{error}</span>}
    </>
  )
}

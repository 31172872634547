import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react'
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query'

import { UserRelationsListItem } from '@components/organisms/UserRelationsList/UserRelationsListItem'
import { InfiniteScrollLoader } from '@/components/atoms/InfiniteScrollLoader'
import { Loading } from '@/components/atoms/Loading'
import { VirtualList } from '@/components/organisms/VirtualList'

import { UserRelationsData } from '@/services/relations'
import { useUserInfo } from '@/lib/hooks/useUserInfo'

export interface IListProps {
  followers: boolean
  setRefetchActive: Dispatch<SetStateAction<boolean>>
  usersData: InfiniteData<UserRelationsData>
  isLoading: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<UserRelationsData, unknown>>
  isFetchingNextPage: boolean
  hasNextPage: boolean
  tabIndex: number
  onSegmentClick: (e) => void
}

export const UserRelationsList: FC<IListProps> = ({
  followers,
  setRefetchActive,
  usersData,
  isLoading,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
  tabIndex,
  onSegmentClick,
}) => {
  const { t } = useTranslation('common')

  const { data: userInfo } = useUserInfo()

  const users = useMemo(
    () => usersData?.pages.flatMap((page) => page.users),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersData?.pages?.length, followers]
  )

  const Item = useCallback(
    (index) => (
      <UserRelationsListItem
        key={users[index].id}
        user={users[index]}
        currentUserId={userInfo?.id}
      />
    ),
    [userInfo?.id, users]
  )

  const Footer = useCallback(
    () => <InfiniteScrollLoader hasNext={!!isFetchingNextPage} />,
    [isFetchingNextPage]
  )

  return (
    <div className="h-full">
      <IonSegment
        mode="md"
        value={String(tabIndex)}
        onIonChange={onSegmentClick}
      >
        <IonSegmentButton value="0">
          <IonLabel>{t('profile.following')}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="1">
          <IonLabel>{t('profile.followers')}</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      {!isLoading ? (
        usersData?.pages?.[0]?.users.length === 0 ? (
          <div className="my-8 mx-auto text-center">
            {t('profile.findUsers.emptyResultMessage')}
          </div>
        ) : (
          <VirtualList
            atTopStateChange={(isAtTop: boolean) => {
              setRefetchActive(isAtTop)
            }}
            virtualKey={String(followers)}
            className="mx-auto max-w-[40rem] ion-content-scroll-host"
            data={users}
            endReached={() => hasNextPage && fetchNextPage()}
            itemContent={Item}
            components={{
              Footer,
            }}
          />
        )
      ) : (
        <Loading />
      )}
    </div>
  )
}

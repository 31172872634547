import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonItem } from '@ionic/react'

import { Avatar } from '@components/atoms/Avatar'
import { UserName } from '@/components/atoms/UserName'

import { appConfig } from '@/services/config'
import { formatDistance } from '@utils/date'

import { SubscriptionCancelledInsufficientFunds } from '@/types/activity/user'

interface IActivitySubscriptionCancelledInsufficientFundsProps {
  item: SubscriptionCancelledInsufficientFunds
}

export const ActivitySubscriptionCancelledInsufficientFunds: FC<
  IActivitySubscriptionCancelledInsufficientFundsProps
> = ({ item }) => {
  const { t } = useTranslation('common')
  const { time, data } = item

  return (
    <IonItem className="py-4 border-solid border-b-[0.0625rem] border-zinc-800">
      <span className="pr-4">
        <Avatar userId={appConfig.republikUserId} clickable isAmbassador />
      </span>
      <div className="w-full flex flex-col justify-between">
        <div className="text-sm">
          <Trans
            i18nKey={'activity.item.subscriptionCancelled.insufficientFunds'}
            components={{
              userLink: (
                <UserName
                  className="inline font-bold"
                  userId={data?.creatorId}
                  name={data?.creatorUsername}
                />
              ),
            }}
          >
            {t('activity.item.subscriptionCancelled.insufficientFunds')}
          </Trans>
        </div>
        <div className="text-xs text-zinc-600">{formatDistance(time)}</div>
      </div>
    </IonItem>
  )
}

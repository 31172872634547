import { isPlatform } from '@ionic/react'
import { InfiniteData } from '@tanstack/react-query'
import orderBy from 'lodash/orderBy'

import { AUTH } from '@/services/auth'

import { IContest, IPromoPool } from '@/interfaces'

import { isInStandaloneMode } from './isInStandaloneMode'
import { userMentionsPattern } from './validation'

export interface IFollowHandler {
  aFollowsB: boolean
  bFollowsA: boolean
  id: string
  userAId: string
  userBId: string
}

export const normalizeFollowRelation = (
  item: IFollowHandler,
  currentId: string
) => {
  let aFollowsB = item.aFollowsB
  let bFollowsA = item.bFollowsA
  let userBId = item.userBId
  let userAId = item.userAId

  if (item.userAId !== currentId) {
    if (item.aFollowsB !== item.bFollowsA) {
      aFollowsB = !item.aFollowsB
      bFollowsA = !item.bFollowsA
    }
    userBId = item.userAId
    userAId = item.userBId
  }

  return {
    ...item,
    aFollowsB,
    bFollowsA,
    userBId,
    userAId,
  }
}

export const orderActivitiesByRank = (activities: IContest[]): IContest[] => {
  return orderBy(
    activities,
    [({ rank }) => rank || '', 'startDate'],
    ['desc', 'desc']
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uniqByKeepLast = (a: any[], key: (arg: any) => void) => {
  return [...new Map(a.map((x) => [key(x), x])).values()]
}

export const authTypeAttributes = (username: string, authType: AUTH) => {
  switch (authType) {
    case 'EMAIL':
      return { email: username }
    case 'PHONE_NUMBER':
      return { phone_number: username }
  }
}

export const authType = (hasEmail?: boolean): AUTH => {
  return hasEmail ? 'EMAIL' : 'PHONE_NUMBER'
}

export const isIosWebView = () => isPlatform('ios') && isPlatform('mobileweb')
export const isMobileWeb = () => isPlatform('mobileweb')

export const isIosNativeOrPWA = () => {
  if (isPlatform('ios') && isPlatform('mobileweb') && !isInStandaloneMode())
    // for ios mobile browser
    return false

  if (isPlatform('ios')) return true // for ios native and ios PWA

  return false
}

export const compactNumbers = (input: number): string => {
  const thresholds = [1_000_000_000_000, 1_000_000_000, 1_000_000, 1_000]

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1,
    notation: 'compact',
    compactDisplay: 'short',
  })

  for (const divisor of thresholds) {
    if (input >= divisor) {
      const adjusted = Math.floor(input / (divisor / 10)) * (divisor / 10)

      return formatter.format(adjusted)
    }
  }

  return formatter.format(input)
}

export const parseLink = (link: string) => {
  const param = link.split(':')

  switch (param[0]) {
    case 'user':
      return `/profile/${param[1]}`
    case 'http':
    case 'https':
      return link
    default:
      return param[0]
  }
}

export const getUserIdsFromMentions = (comment: string) => {
  let matchArray
  const userIds = []

  while ((matchArray = userMentionsPattern.exec(comment)) != null) {
    userIds.push(matchArray[3])
  }

  return userIds
}

export const getImageDimensions = async (
  file: File
): Promise<{ width: number; ratio: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const objectUrl = URL.createObjectURL(file)

    img.onload = async () => {
      const { naturalWidth: width, naturalHeight: height } = img
      resolve({
        width,
        height,
        ratio: width / height,
      })
      URL.revokeObjectURL(objectUrl)
    }
    img.onerror = () => {
      reject(new Error('Failed to load image'))
      URL.revokeObjectURL(objectUrl)
    }
    img.src = objectUrl
  })
}

export const calculateImageSpace = (ratio: number): string => {
  if (!ratio) return ''
  return `${Math.floor((1 / ratio) * 100)}%`
}

export const calculateContainerHeight = (
  ratio: number,
  containerWidth: number
): number => {
  if (!ratio || !containerWidth) return 0
  return Math.ceil(containerWidth / ratio)
}

export const normalizePages = <TData extends IPromoPool>(
  data: InfiniteData<TData>
) => {
  return [
    ...new Map(
      data?.pages
        .reduce((acc, page) => {
          return [...acc, ...page.items]
        }, [])
        .map((item) => [item.id, item])
    ).values(),
  ]
}

export const isValidUrl = (string: string) => {
  try {
    return Boolean(new URL(string))
  } catch (e) {
    return false
  }
}

export const stripSubdomain = (url: string) => {
  const parsedUrl = new URL(url)
  const hostname = parsedUrl.hostname
  const subdomains = hostname.split('.')

  if (subdomains[0] === 'www') {
    subdomains.shift()
    parsedUrl.hostname = subdomains.join('.')
  }

  return parsedUrl.host + parsedUrl.pathname
}

export const actorMarkdown = ({
  name,
  url,
  className = '',
  isAmbassador = false,
  isModerator = false,
}) => {
  return `[@${name}](${url}#actor#${name}#${isAmbassador}#${isModerator}#${className}) `
}

export const neverReturn = (): never => {
  throw new Error('Invalid item type')
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const assertNever = (a: never): never => {
  throw new Error('Should never happen')
}

export const getMaxViewportWidth = () => Math.min(window.innerWidth, 640)

export function safelyJsonStringify(a: unknown): string {
  try {
    let str: string
    if (a instanceof Error) {
      str = JSON.stringify(a, Object.getOwnPropertyNames(a))
    } else {
      str = JSON.stringify(a)
    }
    return str
  } catch (stringifyError) {
    return '<Failed to Json stringify>'
  }
}

export const safeCastUnknownToType = <T>(a: unknown): T | undefined => {
  if (typeof a === 'undefined') {
    return undefined
  }

  if (typeof a === 'object') {
    if (a === null) {
      return undefined
    }
  }

  return a as T
}

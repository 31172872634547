import { QueryClient } from '@tanstack/react-query'
import { decompress } from 'lz-string'

import { customHydrate } from './customHydrate'

export const deserialize = (queryClient: QueryClient, data) => {
  const reviver = (key: string, value) => {
    if ((key === 'gcTime' || key === 'staleTime') && value === 'Infinity') {
      return Infinity
    }

    return value
  }

  const dehydratedState = JSON.parse(decompress(data), reviver)
  const hydratedState = customHydrate(queryClient, dehydratedState)

  return hydratedState
}

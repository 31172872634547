import { memo } from 'react'

const DolarComponent = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="13"
      height="20"
      viewBox="0 0 13 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.87305 19.6284V17.7529C2.6499 17.5845 0.459961 15.9897 0.280273 13.3394L0.269043 13.1709H3.26758L3.29004 13.2944C3.48096 14.3276 4.24463 15.0688 5.87305 15.2148V11.1943L5.03076 11.0146C2.03223 10.3857 0.504883 8.99316 0.504883 6.6123V6.58984C0.504883 3.96191 2.67236 2.19873 5.87305 2.0415V0.166016H6.99609V2.05273C10.1069 2.24365 12.0947 3.83838 12.3193 6.43262L12.3418 6.62354H9.34326L9.3208 6.52246C9.15234 5.41064 8.33252 4.73682 6.99609 4.57959V8.47656L7.8833 8.65625C11.0054 9.31885 12.5552 10.5991 12.5552 13.0361V13.0586C12.5552 15.8999 10.2529 17.5732 6.99609 17.7529V19.6284H5.87305ZM3.63818 6.36523C3.63818 7.2749 4.25586 7.88135 5.87305 8.24072V4.56836C4.39062 4.70312 3.63818 5.44434 3.63818 6.34277V6.36523ZM9.42188 13.3506C9.42188 12.3848 8.86035 11.8345 6.99609 11.4302V15.2373C8.68066 15.1138 9.42188 14.3838 9.42188 13.373V13.3506Z"
        fill="white"
      />
    </svg>
  )
}

export const Dollar = memo(DolarComponent)

import { FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonSpinner } from '@ionic/react'

import { BanxaLogo } from '@/components/atoms/assets/BanxaLogo'
import { Popover } from '@/components/molecules/Popover'

import { appConfig } from '@/services/config'
import {
  DepositUrlError,
  depositUrlErrors,
  useGetDepositUrl,
} from '@/lib/hooks/useGetDepositUrl'

interface BuyRpkConfirmationPopupProps {
  isOpen: boolean
  onDismiss: () => void
  onConfirm: () => void
  onError: (error: DepositUrlError) => void
}

const BuyRpkConfirmationPopup: FC<BuyRpkConfirmationPopupProps> = ({
  isOpen,
  onDismiss,
  onConfirm,
  onError,
}) => {
  const { t } = useTranslation('common')
  const [fetchingDepositUrl, setFetchingDepositUrl] = useState(false)
  const { getDepositUrl } = useGetDepositUrl()

  const onConfirmClick = async () => {
    try {
      onConfirm()
      setFetchingDepositUrl(true)
      const data = await getDepositUrl()
      onDismiss()
      window.location = data?.[0]?.checkout_url
    } catch (error) {
      setFetchingDepositUrl(false)
      if (error?.response?.data && depositUrlErrors?.[error?.response?.data]) {
        return onError(depositUrlErrors?.[error?.response?.data])
      }

      onError(depositUrlErrors.UNKNOWN_ERROR)
    }
  }

  return (
    <Popover
      hasIcon={false}
      isOpen={isOpen}
      onDismiss={onDismiss}
      actionText="Cancel"
      confirmText="Confirm"
      onConfirm={onConfirmClick}
      disabled={fetchingDepositUrl}
    >
      <p className="pt-6 pb-6">
        <BanxaLogo />
      </p>
      {fetchingDepositUrl ? (
        <IonSpinner className="h-5" color="medium" name="dots" />
      ) : (
        <p className="p-2 text-base leading-5">
          <Trans
            i18nKey="wallet.buyCurrencyTip"
            values={{ currency: appConfig.currency }}
          >
            {t('wallet.buyCurrencyTip', { currency: appConfig.currency })}
          </Trans>
        </p>
      )}
    </Popover>
  )
}

export default BuyRpkConfirmationPopup

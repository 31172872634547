import { memo } from 'react'

interface IUGCProps {
  className?: string
}

const UGCComponent = ({ className }: IUGCProps) => {
  return (
    <svg
      className={className}
      width="56"
      height="44"
      viewBox="0 0 56 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C8.95431 0 0 8.9543 0 20V24C0 35.0457 8.95431 44 20 44H36C47.0457 44 56 35.0457 56 24V20C56 8.95431 47.0457 0 36 0H20ZM36.8003 24.101H30.101V30.7999C30.101 31.9823 29.1495 32.9338 27.9671 32.9338C26.7846 32.9338 25.8332 31.9824 25.8332 30.7999V24.101H19.1339C17.9515 24.101 17 23.1495 17 21.9671C17 20.7846 17.9515 19.8332 19.1339 19.8332H25.8328L25.8332 13.1339C25.8332 11.9515 26.7846 11 27.9671 11C29.1495 11 30.101 11.9515 30.101 13.1339V19.8328H36.7999C37.9823 19.8328 38.9338 20.7843 38.9338 21.9667C38.9342 23.1495 37.9774 24.101 36.8003 24.101Z"
        fill="#CCFF00"
      />
    </svg>
  )
}

export const UGC = memo(UGCComponent)

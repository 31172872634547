import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { IonModal, isPlatform } from '@ionic/react'

import { appConfig } from '@/services/config'
import { UserInfo } from '@/services/user'
import { useActivityDetails } from '@/lib/hooks/useActivityDetails'
import { useFeed } from '@/lib/hooks/useFeed'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useCreateTransaction } from '@/lib/hooks/wallet'

import { QueryKeys } from '@/enums'

import { DepositCurrency, DepositCurrencyRef } from '../../DepositCurrency'
import { PaymentForm } from '../PaymentForm'
import { Summary } from '../Summary'

type PaymentModalProps = {
  amount: number
  userInfo: UserInfo
  translation: Record<string, string>
  paymentType: 'subscription'
  activityId?: string
  onPaymentSuccess?: () => void
}

export interface PaymentModalRef {
  openModal: () => void
}

export const PaymentModal = forwardRef(
  (
    {
      amount,
      userInfo,
      translation,
      paymentType,
      activityId,
      onPaymentSuccess,
    }: PaymentModalProps,
    ref
  ) => {
    const { t } = useTranslation()
    const currency = appConfig.currency
    const isDesktop = isPlatform('desktop')
    const [isLoading, setIsLoading] = useState(false)
    const depositCurrencyRef = useRef<DepositCurrencyRef>(null)
    const [isOpen, setIsOpen] = useState(false)

    useImperativeHandle(ref, () => ({
      openModal: () => setIsOpen(true),
    }))

    const {
      createTransactionData,
      createTransaction,
      error: unlockPaymentError,
      isLoadingTransactionData,
      resetTransactionData,
    } = useCreateTransaction({ userId: userInfo?.id })

    const { data: authUser } = useUserInfo()

    const computedUserId = useMemo(
      () => userInfo?.id || authUser?.id,
      [authUser?.id, userInfo?.id]
    )

    const { feedData, refetchFirstPage: refetchFirstSubscriptionPage } =
      useFeed({
        feedGroup: 'subscription',
        feedId: computedUserId,
        cacheKey: [QueryKeys.SubscriptionFeed, computedUserId],
        enabled: false,
        ranking: undefined,
        userId: computedUserId,
      })

    const { activity, refetch: refetchPostFeedActivity } = useActivityDetails({
      activityId,
      enabled: false,
      replyObjectId: '',
      createdAt: '',
    })

    // Determine whether to subscribe on the comment page or the user profile page based on the activityId
    const hasActivityData = activityId ? !!activity : feedData?.length > 0

    const dismissModal = useCallback(() => {
      setIsOpen(false)
    }, [])

    const handleDismissModal = useCallback(() => {
      resetTransactionData()
      dismissModal()
    }, [dismissModal, resetTransactionData])

    const handleStart = useCallback(() => {
      handleDismissModal()
      onPaymentSuccess?.()
    }, [handleDismissModal, onPaymentSuccess])

    useEffect(() => {
      let interval = null
      if (createTransactionData) {
        interval = setInterval(() => {
          if (activityId) {
            refetchPostFeedActivity()
          } else {
            refetchFirstSubscriptionPage()
          }
        }, 1000)
      }

      if (hasActivityData) {
        clearInterval(interval)
      }
      return () => clearInterval(interval)
    }, [
      activityId,
      createTransactionData,
      refetchFirstSubscriptionPage,
      refetchPostFeedActivity,
      handleDismissModal,
      activity,
      hasActivityData,
    ])

    useEffect(() => {
      if (isLoadingTransactionData) {
        setIsLoading(true)
      }
    }, [isLoadingTransactionData])

    const handleBuyMoreCurrency = useCallback(() => {
      dismissModal()
      depositCurrencyRef?.current?.openModal()
    }, [dismissModal])

    return (
      <>
        <IonModal
          // ref={modalRef}
          isOpen={isOpen}
          className="payment-modal"
          onDidDismiss={dismissModal}
          initialBreakpoint={isDesktop ? undefined : 1}
          breakpoints={isDesktop ? undefined : [0, 1]}
        >
          <div className="w-full flex flex-col items-center text-center sm:w-3/4 px-8 pt-5 pb-5 sm:py-8">
            {createTransactionData && hasActivityData ? (
              <Summary
                unlockPaymentData={createTransactionData}
                unlockPaymentError={unlockPaymentError}
                handleDismissModal={handleDismissModal}
                handleStart={handleStart}
                translation={translation}
              />
            ) : (
              <PaymentForm
                amount={amount}
                unlockPayment={() =>
                  createTransaction({
                    value: String(amount),
                    type: paymentType,
                  })
                }
                isLoadingUnlockPaymentData={isLoading}
                translation={translation}
                onBuyMoreCurrency={handleBuyMoreCurrency}
                unlockPaymentError={unlockPaymentError}
              />
            )}
          </div>
        </IonModal>
        <DepositCurrency
          title={t('depositBottomSheet.topUpCurrency', { currency })}
          ref={depositCurrencyRef}
        />
      </>
    )
  }
)

PaymentModal.displayName = 'PaymentModal'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AuthenticatorCode } from '@/components/molecules/AuthenticatorCode'

import { FormValues } from '../../Withdraw'

const CODE_LENGTH = 6

export const ConfirmWithdrawalCode = () => {
  const { t } = useTranslation('common')

  const {
    control,
    formState: { errors },
  } = useFormContext<FormValues>()
  const error = errors.authenticatorCode?.message

  return (
    <div className="mt-12">
      <div className="text-white font-bold mb-6">
        {t('withdraw.confirmWithdrawalCode')}
      </div>
      <Controller
        control={control}
        name="authenticatorCode"
        rules={{
          required: true,
          minLength: CODE_LENGTH,
          maxLength: CODE_LENGTH,
        }}
        render={({ field }) => (
          <div className="mb-9">
            <AuthenticatorCode
              codeLength={CODE_LENGTH}
              code={field.value}
              setCode={field.onChange}
              error={error}
            />
          </div>
        )}
      />
    </div>
  )
}

import { useCallback, useContext, useEffect, useState } from 'react'

import { Context } from '@/lib/store'
import { ActionType } from '@/lib/store/reducer'
import { isIosWebView } from '@/utils/utils'

export const useVideo = () => {
  const [isVideoStartedPlaying, setVideoStartedPlaying] = useState(false)
  const [videoLoadedMilliseconds, setVideoLoadedMilliseconds] = useState(0)
  const { dispatch, state } = useContext(Context)

  const toggleMuteVideo = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch({
        type: ActionType.MUTE_VIDEO,
        payload: !state.muteVideo,
      })
    },
    [state?.muteVideo, dispatch]
  )

  const progressHandler = useCallback((e) => {
    if (e.loaded > 0) {
      setVideoStartedPlaying(true)
      setVideoLoadedMilliseconds(Math.ceil(e.loadedSeconds * 1000))
    }
  }, [])

  useEffect(() => {
    if (!state?.muteVideo && isIosWebView()) {
      dispatch({
        type: ActionType.MUTE_VIDEO,
        payload: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    videoLoadedMilliseconds,
    isVideoStartedPlaying,
    progressHandler,
    toggleMuteVideo,
    muteVideo: state?.muteVideo,
  }
}

import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { block, follow, unblock, unfollow, UserInfo } from '@services/user'

import { FollowStatus } from '@enums'

import { TOO_MANY_REQUESTS } from './useDailyLimitToast'
import { useErrorBoundary } from './useErrorBoundary'
import { useShowErrorToast } from './useShowErrorToast'
import { useUserInfo } from './useUserInfo'

export const useFollowButton = (userId: UUID, initialValue?: FollowStatus) => {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const { handleError } = useErrorBoundary()
  const { data: authUser } = useUserInfo()
  const { showErrorToast } = useShowErrorToast()

  const [initialStatus, setInitialStatus] = useState(initialValue)
  const initialStatusIsUndefined = initialStatus === undefined

  const userData = initialStatusIsUndefined
    ? queryClient.getQueryData<UserInfo>(['user', userId])
    : undefined

  const followStatusQueryEnabled =
    !initialStatusIsUndefined && !userData?.followStatus

  const getFollowStatus = () => Promise.resolve({ followStatus: initialStatus })

  const {
    data: followData,
    refetch,
    isLoading: queryLoading,
    isError: isQueryError,
    error: queryError,
  } = useQuery({
    queryKey: ['followStatus', userId],
    queryFn: getFollowStatus,
    enabled: followStatusQueryEnabled,
  })

  const masterStatus =
    followData?.followStatus || userData?.followStatus || initialStatus

  const onError = useCallback(
    (error: AxiosError) =>
      handleError(error, () => {
        if (error?.response?.status !== TOO_MANY_REQUESTS)
          showErrorToast({ header: t('errors.default') })
      }),
    [handleError, showErrorToast, t]
  )

  const {
    mutate: toggleFollowMutation,
    isPending: mutationLoading,
    error,
    isError: mutationError,
  } = useMutation({
    mutationFn: () =>
      (currentUserIsFollowingProfile(masterStatus) ? unfollow : follow)({
        userId,
      }),
    mutationKey: ['followStatus', userId],
    onError,
    onSuccess: (data) => {
      setInitialStatus(undefined)
      const delta = currentUserIsFollowingProfile(masterStatus) ? -1 : +1
      queryClient.setQueryData(['followStatus', userId], data)

      const userProfile = queryClient.getQueryData<UserInfo>(['user', userId])
      if (userProfile) {
        queryClient.setQueryData(['user', userId], {
          ...userProfile,
          followerCount: userProfile.followerCount + delta,
          followStatus: data.followStatus,
        })
      }

      if (authUser) {
        queryClient.setQueryData(['user', authUser.id], {
          ...authUser,
          followingCount: authUser.followingCount + delta,
          followStatus: data.followStatus,
        })
        queryClient.setQueryData(['user'], {
          ...authUser,
          followingCount: authUser.followingCount + delta,
          followStatus: data.followStatus,
        })
      }
    },
  })

  const {
    mutate: toggleBlockMutation,
    isPending: blockMutationLoading,
    isError: blockMutationError,
  } = useMutation({
    mutationFn: () =>
      (masterStatus === FollowStatus.Blocking ? unblock : block)({
        userId,
      }),
    mutationKey: ['followStatus', userId],
    onError,
    onSuccess: (data) => {
      setInitialStatus(undefined)
      queryClient.setQueryData(['followStatus', userId], data)

      const userProfile = queryClient.getQueryData<UserInfo>(['user', userId])
      if (userProfile) {
        queryClient.setQueryData(['user', userId], {
          ...userProfile,
          followStatus: data.followStatus,
        })
      }

      if (authUser) {
        queryClient.setQueryData(['user', authUser.id], {
          ...authUser,
          followStatus: data.followStatus,
        })
        queryClient.setQueryData(['user'], {
          ...authUser,
          followStatus: data.followStatus,
        })
      }
    },
  })

  useEffect(() => {
    if (isQueryError) {
      handleError(queryError)
    }
  }, [isQueryError, queryError, handleError])

  const isLoading =
    mutationLoading ||
    blockMutationLoading ||
    (followStatusQueryEnabled && queryLoading)
  const isError = isQueryError || mutationError || blockMutationError

  return {
    status: getButtonStatus(isLoading, masterStatus),
    toggleFollow: () => toggleFollowMutation(undefined),
    error,
    isError,
    refetch,
    toggleBlock: () => toggleBlockMutation(undefined),
  }
}

function currentUserIsFollowingProfile(status?: FollowStatus) {
  return status === FollowStatus.Following || status === FollowStatus.Friends
}

function getButtonStatus(isLoading: boolean, status?: FollowStatus) {
  if (isLoading) return FollowStatus.Loading

  switch (status) {
    case 'FOLLOWING':
      return FollowStatus.Following
    case 'FOLLOWED':
    case 'FOLLOW_BACK':
      return FollowStatus.FollowBack
    case 'FRIENDS':
      return FollowStatus.Friends
    case 'BLOCKING':
      return FollowStatus.Blocking
    case 'BLOCKED':
      return FollowStatus.Blocked
    default:
      return FollowStatus.Follow
  }
}

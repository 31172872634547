import { FC, memo } from 'react'

import { AnimateCounter } from '@/components/atoms/AnimateCounter'

export interface GoldenBadgeLargeComponentProps {
  className?: string
  xp: number
  label?: string
}

const GoldenBadgeLargeComponent: FC<GoldenBadgeLargeComponentProps> = ({
  className = '',
  xp = 0,
  label = 'Gold',
}) => {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 209 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M156.378 40.1179L207.505 21.7423C207.505 21.7423 212.772 37.4937 200.939 41.8298C189.105 46.1659 173.761 51.7591 173.761 51.7591L156.378 40.1179Z"
        fill="url(#paint0_linear_48_348)"
      />
      <path
        d="M156.378 40.1179L207.505 21.7423C207.505 21.7423 212.772 37.4937 200.939 41.8298C189.105 46.1659 173.761 51.7591 173.761 51.7591L156.378 40.1179Z"
        fill="url(#paint1_linear_48_348)"
      />
      <path
        d="M157.851 40.4596L206.943 22.7676C206.943 22.7676 211.504 36.6555 201.162 41.0095C190.974 45.301 173.532 50.9596 173.532 50.9596L157.848 40.4596H157.851Z"
        fill="url(#paint2_linear_48_348)"
      />
      <path
        d="M150.781 58.8175L201.906 40.4419C201.906 40.4419 207.172 56.1933 195.339 60.5294C183.506 64.8654 168.161 70.4587 168.161 70.4587L150.778 58.8175H150.781Z"
        fill="url(#paint3_linear_48_348)"
      />
      <path
        d="M152.251 59.1594L201.343 41.4673C201.343 41.4673 205.905 55.3552 195.562 59.7092C185.374 64.0007 167.932 69.6593 167.932 69.6593L152.248 59.1594H152.251Z"
        fill="url(#paint4_linear_48_348)"
      />
      <path
        d="M143.364 79.095L194.488 60.7194C194.488 60.7194 199.755 76.4707 187.921 80.8068C176.088 85.1429 160.743 90.7361 160.743 90.7361L143.361 79.095H143.364Z"
        fill="url(#paint5_linear_48_348)"
      />
      <path
        d="M144.836 79.4367L193.929 61.7446C193.929 61.7446 198.49 75.6326 188.147 79.9865C177.959 84.278 160.517 89.9366 160.517 89.9366L144.833 79.4367H144.836Z"
        fill="url(#paint6_linear_48_348)"
      />
      <path
        d="M136.974 98.3303L188.101 79.9547C188.101 79.9547 193.368 95.7061 181.534 100.042C169.701 104.378 154.357 109.971 154.357 109.971L136.974 98.3303Z"
        fill="url(#paint7_linear_48_348)"
      />
      <path
        d="M138.447 98.672L187.539 80.98C187.539 80.98 192.1 94.8679 181.758 99.2219C171.57 103.513 154.127 109.172 154.127 109.172L138.444 98.672H138.447Z"
        fill="url(#paint8_linear_48_348)"
      />
      <path
        d="M131.377 117.03L182.502 98.6543C182.502 98.6543 187.768 114.406 175.935 118.742C164.101 123.078 148.757 128.671 148.757 128.671L131.374 117.03H131.377Z"
        fill="url(#paint9_linear_48_348)"
      />
      <path
        d="M132.847 117.372L181.939 99.6797C181.939 99.6797 186.501 113.568 176.158 117.922C165.97 122.213 148.528 127.872 148.528 127.872L132.844 117.372H132.847Z"
        fill="url(#paint10_linear_48_348)"
      />
      <path
        d="M52.3041 40.1179L1.17677 21.7423C1.17677 21.7423 -4.0898 37.4937 7.74361 41.8298C19.577 46.1659 34.9215 51.7591 34.9215 51.7591L52.3041 40.1179Z"
        fill="url(#paint11_linear_48_348)"
      />
      <path
        d="M52.3041 40.1179L1.17677 21.7423C1.17677 21.7423 -4.0898 37.4937 7.74361 41.8298C19.577 46.1659 34.9215 51.7591 34.9215 51.7591L52.3041 40.1179Z"
        fill="url(#paint12_linear_48_348)"
      />
      <path
        d="M50.8313 40.4596L1.73917 22.7676C1.73917 22.7676 -2.82222 36.6555 7.52048 41.0095C17.7085 45.301 35.1506 50.9596 35.1506 50.9596L50.8343 40.4596H50.8313Z"
        fill="url(#paint13_linear_48_348)"
      />
      <path
        d="M57.9008 58.8175L6.77645 40.4419C6.77645 40.4419 1.50988 56.1933 13.3433 60.5294C25.1767 64.8654 40.5212 70.4587 40.5212 70.4587L57.9038 58.8175H57.9008Z"
        fill="url(#paint14_linear_48_348)"
      />
      <path
        d="M56.431 59.1594L7.33884 41.4673C7.33884 41.4673 2.77745 55.3552 13.1202 59.7092C23.3081 64.0007 40.7503 69.6593 40.7503 69.6593L56.434 59.1594H56.431Z"
        fill="url(#paint15_linear_48_348)"
      />
      <path
        d="M65.3187 79.095L14.1943 60.7194C14.1943 60.7194 8.92773 76.4707 20.7611 80.8068C32.5946 85.1429 47.939 90.7361 47.939 90.7361L65.3217 79.095H65.3187Z"
        fill="url(#paint16_linear_48_348)"
      />
      <path
        d="M63.8458 79.4367L14.7537 61.7446C14.7537 61.7446 10.1923 75.6326 20.535 79.9865C30.723 84.278 48.1651 89.9366 48.1651 89.9366L63.8488 79.4367H63.8458Z"
        fill="url(#paint17_linear_48_348)"
      />
      <path
        d="M71.7083 98.3303L20.5809 79.9547C20.5809 79.9547 15.3143 95.7061 27.1477 100.042C38.9811 104.378 54.3256 109.971 54.3256 109.971L71.7083 98.3303Z"
        fill="url(#paint18_linear_48_348)"
      />
      <path
        d="M70.2354 98.672L21.1433 80.98C21.1433 80.98 16.5819 94.8679 26.9246 99.2219C37.1126 103.513 54.5547 109.172 54.5547 109.172L70.2384 98.672H70.2354Z"
        fill="url(#paint19_linear_48_348)"
      />
      <path
        d="M77.3049 117.03L26.1806 98.6543C26.1806 98.6543 20.914 114.406 32.7474 118.742C44.5808 123.078 59.9253 128.671 59.9253 128.671L77.3079 117.03H77.3049Z"
        fill="url(#paint20_linear_48_348)"
      />
      <path
        d="M75.8351 117.372L26.743 99.6797C26.743 99.6797 22.1816 113.568 32.5243 117.922C42.7123 122.213 60.1544 127.872 60.1544 127.872L75.8381 117.372H75.8351Z"
        fill="url(#paint21_linear_48_348)"
      />
      <path
        d="M72.8656 176.247L53.3327 159.681L53.3043 90.4637L73.1779 90.4779L72.8656 176.247Z"
        fill="#FFEDAD"
      />
      <path
        d="M72.0138 174.004L54.3973 159.099L54.3689 92.5364L72.2835 92.5932L72.0138 174.004Z"
        fill="url(#paint22_linear_48_348)"
      />
      <path
        d="M134.9 177.013L154.418 160.447L154.461 91.2302L134.587 91.2586L134.9 177.013Z"
        fill="url(#paint23_linear_48_348)"
      />
      <path
        d="M135.751 174.785L153.368 159.865L153.396 93.3029L135.482 93.3738L135.751 174.785Z"
        fill="url(#paint24_linear_48_348)"
      />
      <path
        d="M133.778 90.8329V91.8975L74.9239 91.8123V90.7477L133.778 90.8329Z"
        fill="url(#paint25_linear_48_348)"
      />
      <path
        d="M76.0169 151.575L75.6904 178.092L102.804 195.68C103.258 196.064 103.925 196.064 104.394 195.695L132.884 177.808L132.501 149.02L103.428 160.916L76.0169 151.575Z"
        fill="#FFEDAD"
      />
      <path
        d="M77.0958 99.1939L76.8119 177.226L102.903 194.431C103.286 194.758 103.826 194.758 104.209 194.431L131.223 177.283L130.939 99.2507L77.0816 99.1655L77.0958 99.1939Z"
        fill="url(#paint26_linear_48_348)"
      />
      <path
        d="M173.796 134.082L110.364 170.702C106.361 173.024 101.404 173.024 97.4007 170.702L33.9692 134.082C29.9657 131.759 27.4874 127.478 27.4874 122.851V49.5928C27.4874 44.9655 29.9657 40.6847 33.9692 38.3623L97.4007 1.74189C101.404 -0.580467 106.361 -0.580467 110.364 1.74189L173.796 38.3623C177.799 40.6847 180.278 44.9655 180.278 49.5928V122.834C180.278 127.461 177.799 131.742 173.796 134.064V134.082Z"
        fill="#FEEBFF"
      />
      <path
        d="M170.434 128.882L108.371 164.012C105.564 165.589 102.149 165.589 99.3419 164.012L37.2795 128.882C34.7665 127.461 33.2067 124.827 33.2067 121.967V51.2221C33.2067 48.3624 34.7665 45.7281 37.2795 44.307L99.3419 9.19432C102.149 7.61719 105.564 7.61719 108.371 9.19432L170.434 44.3243C172.947 45.7454 174.507 48.3798 174.507 51.2394V121.985C174.507 124.844 172.947 127.479 170.434 128.9V128.882Z"
        fill="#FFF1AC"
      />
      <path
        d="M104.495 174.161C101.913 174.161 99.2204 172.123 96.9154 170.789L35.1649 135.174C30.5549 132.505 27.6779 131.472 27.6779 126.151V51.0141C27.6779 45.6935 30.5375 40.7195 35.1649 38.0679L96.9154 2.43527C101.525 -0.216378 107.245 -0.216378 111.855 2.43527L173.605 38.0505C178.215 40.7195 181.092 45.6762 181.092 50.9968V122.21C181.092 131.957 178.233 132.505 173.605 135.156L111.855 170.771C109.55 172.106 107.078 174.144 104.495 174.144V174.161ZM104.385 9.05572C103.293 9.05572 102.201 9.33302 101.213 9.90494L39.4803 45.5202C37.5219 46.6467 36.3088 48.7438 36.3088 51.0141V122.227C36.3088 124.48 37.5219 126.595 39.4803 127.721L101.231 163.337C103.189 164.463 105.616 164.463 107.557 163.337L169.307 127.721C171.265 126.595 172.479 124.498 172.479 122.227V51.0141C172.479 48.7611 171.265 46.6467 169.307 45.5202L107.557 9.90494C106.586 9.35035 105.494 9.05572 104.385 9.05572Z"
        fill="url(#paint27_linear_48_348)"
      />
      <path
        d="M171.685 129.083L108.598 164.831C106.05 166.275 102.922 166.275 100.375 164.831L37.2875 129.083C34.74 127.638 33.1853 124.975 33.1853 122.105V50.6089C33.1853 47.7205 34.7587 45.0572 37.2875 43.6317L100.393 7.88343C102.941 6.43925 106.069 6.43925 108.616 7.88343L171.703 43.6317C174.251 45.0759 175.806 47.7392 175.806 50.6089V122.105C175.806 124.994 174.232 127.657 171.703 129.083H171.685Z"
        fill="#FEEBFF"
      />
      <path
        d="M172.176 128.358L106.194 166.434C105.143 167.034 103.848 167.034 102.797 166.434L36.8152 128.358C35.764 127.758 35.1257 126.632 35.1257 125.412V49.2425C35.1257 48.0228 35.764 46.9156 36.8152 46.2963L102.797 8.22088C103.848 7.62038 105.143 7.62038 106.194 8.22088L172.176 46.2963C173.227 46.8968 173.865 48.0228 173.865 49.2425V125.412C173.865 126.632 173.227 127.739 172.176 128.358Z"
        fill="url(#paint28_linear_48_348)"
      />
      <g opacity="0.29">
        <path
          d="M104.495 169.775L34.1555 128.769V46.836L104.495 5.83008L174.835 46.836V128.789L104.495 169.795V169.775ZM41.2641 124.684L104.495 161.547L167.727 124.684V50.9012L104.495 14.0391L41.2641 50.9012V124.684Z"
          fill="url(#paint29_linear_48_348)"
        />
      </g>
      <path
        d="M104.495 172.667C103.668 172.667 102.859 172.455 102.127 172.03L32.6236 131.459C31.1796 130.609 30.2747 129.045 30.2747 127.365V46.2618C30.2747 44.5818 31.1796 43.0176 32.6236 42.168L102.127 1.61637C103.591 0.766713 105.4 0.766713 106.864 1.61637L176.367 42.1873C177.811 43.0369 178.716 44.6011 178.716 46.2811V127.423C178.716 129.103 177.811 130.667 176.367 131.517L106.864 172.068C106.132 172.493 105.304 172.706 104.495 172.706V172.667ZM38.1107 125.588L104.495 164.325L170.88 125.588V48.0576L104.495 9.32117L38.1107 48.0576V125.588Z"
        fill="url(#paint30_linear_48_348)"
      />
      <mask
        id="mask0_48_348"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="31"
        y="0"
        width="147"
        height="167"
      >
        <path
          d="M104.5 166.5C101.5 165 98.2281 163.253 95.6517 161.758L39.1842 129.021C34.1348 126.094 31.0002 120.673 31.0002 114.879V49.1182C31.0002 43.3238 34.1348 37.9029 39.1842 34.9764L95.6517 2.23885C100.805 -0.746283 107.203 -0.746283 112.352 2.23885L168.816 34.9764C173.866 37.9029 177 43.3238 177 49.1182V114.879C177 120.677 173.862 126.094 168.816 129.021L112.352 161.758C110.247 162.979 103.191 167.195 104.5 166.5Z"
          fill="#844B07"
        />
      </mask>
      <g mask="url(#mask0_48_348)">
        <g filter="url(#filter0_i_48_348)">
          <rect
            x="5.00018"
            y="136"
            width="200"
            height="143"
            rx="71.5"
            fill="#D38B3B"
          />
        </g>
      </g>
      <path
        d="M90.0002 30.0485V49.3694L111.149 49.3069C112.748 49.3069 113.968 49.6921 114.927 51.035C114.961 51.0837 115.011 51.1205 115.049 51.1664C116.411 52.8531 118.134 53.5544 120.192 53.4138C120.645 53.3825 120.804 53.2005 120.797 52.7354L120.8 49.6884C120.816 49.1056 120.628 48.8694 120.066 48.8804L115.68 48.9154C115.68 48.9154 113.138 48.6442 111.937 47.2645L111.446 46.7406C110.268 45.4427 108.833 44.8122 107.151 44.8159C105.361 44.8195 103.571 44.8168 101.781 44.8177C99.0669 44.8177 96.019 44.8067 93.3049 44.8287V41.8396C97.2309 41.8534 101.417 41.8497 105.343 41.8488C105.992 41.8488 106.544 42.0418 106.998 42.495C107.422 42.9178 107.871 43.3176 108.303 43.7349C109.42 44.814 110.777 45.4142 112.366 45.4345C114.964 45.4685 117.563 45.4391 120.161 45.4519C120.643 45.4538 120.807 45.2543 120.798 44.8223C120.782 43.9647 120.793 43.1062 120.791 42.2477C120.79 41.4848 120.779 41.4738 119.992 41.4738C116.316 41.4738 112.64 41.4719 108.964 41.4738C106.301 41.4756 106.615 41.6456 104.747 39.8183C103.368 38.469 101.748 37.7943 99.7478 37.8504C97.7098 37.9074 95.3448 37.8568 93.3039 37.8715V34.603H98.7226C99.367 34.5928 99.8844 34.8392 100.329 35.3043C100.853 35.853 101.354 36.4275 101.888 36.9652C102.891 37.9763 104.069 38.6234 105.458 38.6308C110.366 38.6565 115.274 38.6409 120.182 38.6537C120.631 38.6547 120.806 38.4699 120.798 37.9938C120.783 37.0195 120.792 36.0451 120.791 35.0708C120.791 34.1461 120.77 34.1222 119.924 34.1222C114.131 34.1222 108.337 34.1351 102.545 34.1176C99.3641 34.1084 100.101 34.4789 97.8445 32.0311C96.6105 30.691 95.0457 30.0503 93.3068 30.0503L90.0002 30.0485Z"
        fill="#C67E1B"
      />
      <mask
        id="mask1_48_348"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="37"
        y="47"
        width="145"
        height="163"
      >
        <path
          d="M109.002 210C106.155 210 103.307 209.257 100.766 207.772L45.0721 175.234C40.0919 172.325 37.0002 166.938 37.0002 161.179V95.8187C37.0002 90.0596 40.0919 84.6718 45.0721 81.7631L100.766 49.2252C105.848 46.2583 112.159 46.2583 117.238 49.2252L172.928 81.7631C177.909 84.6718 181 90.0596 181 95.8187V161.179C181 166.941 177.905 172.325 172.928 175.234L117.238 207.772C114.697 209.257 111.849 210 109.002 210Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask1_48_348)">
        <path
          d="M10.452 84.5459L13.2373 20.972C14.1901 -0.775694 31.9725 -17.9875 53.7397 -18.2308L153.011 -19.3406C181.588 -19.66 199.207 7.80003 181.532 30.2584C168.978 46.2096 152.408 63.9469 131.275 81.3347C99.3219 107.626 66.6351 117.256 41.9614 119.469C22.6949 121.197 9.60531 103.871 10.452 84.5459Z"
          fill="url(#paint31_linear_48_348)"
        />
      </g>
      <path
        d="M95.6399 151.146C93.1145 151.146 91.5677 149.5 91.5677 146.746V146.74C91.5677 144.033 93.1204 142.398 95.5989 142.398C97.6321 142.398 99.0149 143.582 99.2962 145.17L99.3138 145.252H97.5384L97.5149 145.193C97.2337 144.373 96.5599 143.898 95.6048 143.898C94.2102 143.898 93.3724 144.93 93.3724 146.723V146.729C93.3724 148.562 94.263 149.646 95.6692 149.646C96.8235 149.646 97.6145 148.961 97.6614 147.918L97.6673 147.795H95.7337V146.477H99.3899V147.449C99.3899 149.752 97.9954 151.146 95.6399 151.146ZM103.658 151.129C101.748 151.129 100.547 149.904 100.547 147.859V147.848C100.547 145.826 101.777 144.59 103.652 144.59C105.533 144.59 106.769 145.814 106.769 147.848V147.859C106.769 149.91 105.557 151.129 103.658 151.129ZM103.664 149.787C104.496 149.787 105.029 149.084 105.029 147.859V147.848C105.029 146.635 104.484 145.932 103.652 145.932C102.826 145.932 102.287 146.635 102.287 147.848V147.859C102.287 149.09 102.82 149.787 103.664 149.787ZM108.055 151V142.545H109.766V151H108.055ZM113.607 151.129C112.025 151.129 111.052 149.898 111.052 147.859V147.854C111.052 145.809 112.025 144.59 113.607 144.59C114.497 144.59 115.2 145.047 115.54 145.809H115.575V142.545H117.286V151H115.575V149.928H115.54C115.206 150.672 114.515 151.129 113.607 151.129ZM114.181 149.711C115.013 149.711 115.581 148.996 115.581 147.859V147.854C115.581 146.723 115.007 146.008 114.181 146.008C113.331 146.008 112.792 146.705 112.792 147.854V147.859C112.792 149.002 113.325 149.711 114.181 149.711Z"
        fill="white"
      >
        {label}
      </path>
      <text
        x="104"
        y="98"
        fontSize={xp > 1e5 ? 32 : 36}
        fontWeight="bold"
        textAnchor="middle"
        fill="#482A01"
      >
        <AnimateCounter start={0} end={xp} duration={1500} />
      </text>
      <path
        d="M93.6447 119L97.6788 112.61V112.584L93.671 106.318H96.7911L99.4894 110.888H99.5861L102.267 106.318H105.229L101.071 112.628V112.663L105.176 119H102.152L99.3663 114.553H99.2784L96.5099 119H93.6447ZM106.661 119V106.318H111.759C114.431 106.318 116.215 108.04 116.215 110.651V110.668C116.215 113.278 114.431 115.001 111.759 115.001H109.316V119H106.661ZM111.109 108.418H109.316V112.927H111.109C112.629 112.927 113.526 112.118 113.526 110.677V110.659C113.526 109.218 112.629 108.418 111.109 108.418Z"
        fill="#482A01"
      />
      <defs>
        <filter
          id="filter0_i_48_348"
          x="5.00018"
          y="136"
          width="210"
          height="145"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="15" dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_48_348"
          />
        </filter>
        <linearGradient
          id="paint0_linear_48_348"
          x1="208.672"
          y1="36.7507"
          x2="156.378"
          y2="36.7507"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C1C1C1" />
          <stop offset="0.45" stopColor="#9D9D9D" />
          <stop offset="0.82" stopColor="#8C8C8C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_48_348"
          x1="184.276"
          y1="21.2508"
          x2="190.228"
          y2="89.151"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_48_348"
          x1="184.573"
          y1="22.3059"
          x2="190.056"
          y2="86.0972"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_48_348"
          x1="178.676"
          y1="39.9503"
          x2="184.629"
          y2="107.851"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_48_348"
          x1="178.973"
          y1="41.0056"
          x2="184.456"
          y2="104.797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_48_348"
          x1="171.258"
          y1="60.2278"
          x2="177.211"
          y2="128.128"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_48_348"
          x1="171.558"
          y1="61.283"
          x2="177.041"
          y2="125.074"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_48_348"
          x1="164.871"
          y1="79.4632"
          x2="170.824"
          y2="147.363"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_48_348"
          x1="165.169"
          y1="80.5183"
          x2="170.651"
          y2="144.31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_48_348"
          x1="159.272"
          y1="98.1627"
          x2="165.224"
          y2="166.063"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_48_348"
          x1="159.569"
          y1="99.218"
          x2="165.052"
          y2="163.009"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_48_348"
          x1="0.0103894"
          y1="36.7507"
          x2="52.3041"
          y2="36.7507"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C1C1C1" />
          <stop offset="0.45" stopColor="#9D9D9D" />
          <stop offset="0.82" stopColor="#8C8C8C" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_48_348"
          x1="24.4065"
          y1="21.2508"
          x2="18.454"
          y2="89.151"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_48_348"
          x1="24.1094"
          y1="22.3059"
          x2="18.6266"
          y2="86.0972"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_48_348"
          x1="30.0062"
          y1="39.9503"
          x2="24.0537"
          y2="107.851"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_48_348"
          x1="29.7091"
          y1="41.0056"
          x2="24.2263"
          y2="104.797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_48_348"
          x1="37.4241"
          y1="60.2278"
          x2="31.4715"
          y2="128.128"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_48_348"
          x1="37.1239"
          y1="61.283"
          x2="31.6411"
          y2="125.074"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_48_348"
          x1="43.8106"
          y1="79.4632"
          x2="37.8581"
          y2="147.363"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_48_348"
          x1="43.5135"
          y1="80.5183"
          x2="38.0307"
          y2="144.31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_48_348"
          x1="49.4103"
          y1="98.1627"
          x2="43.4578"
          y2="166.063"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_48_348"
          x1="49.1132"
          y1="99.218"
          x2="43.6304"
          y2="163.009"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED97A" />
          <stop offset="0.32" stopColor="#EBA04C" />
          <stop offset="0.58" stopColor="#EEB332" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_48_348"
          x1="63.3262"
          y1="92.5364"
          x2="63.3262"
          y2="174.004"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.67" stopColor="#E47B2E" />
          <stop offset="0.76" stopColor="#F19534" />
          <stop offset="0.85" stopColor="#FBA939" />
          <stop offset="0.91" stopColor="#FFB13B" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_48_348"
          x1="134.587"
          y1="134.129"
          x2="154.461"
          y2="134.129"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.54" stopColor="#E47B2E" />
          <stop offset="0.68" stopColor="#F19534" />
          <stop offset="0.81" stopColor="#FBA939" />
          <stop offset="0.91" stopColor="#FFB13B" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_48_348"
          x1="144.439"
          y1="93.3029"
          x2="144.439"
          y2="174.785"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.54" stopColor="#E47B2E" />
          <stop offset="0.68" stopColor="#F19534" />
          <stop offset="0.81" stopColor="#FBA939" />
          <stop offset="0.91" stopColor="#FFB13B" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_48_348"
          x1="104.351"
          y1="90.7477"
          x2="104.351"
          y2="91.8975"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stopColor="#771351" />
          <stop offset="0.64" stopColor="#DD1936" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_48_348"
          x1="104.025"
          y1="99.1939"
          x2="104.025"
          y2="194.672"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.68" stopColor="#E47B2E" />
          <stop offset="0.76" stopColor="#F19534" />
          <stop offset="0.85" stopColor="#FBA939" />
          <stop offset="0.91" stopColor="#FFB13B" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_48_348"
          x1="66.0488"
          y1="20.2169"
          x2="142.721"
          y2="153.007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCE881" />
          <stop offset="0.31" stopColor="#FED65B" />
          <stop offset="0.64" stopColor="#FCC552" />
          <stop offset="1" stopColor="#F8C043" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_48_348"
          x1="97.1089"
          y1="27.1929"
          x2="114.049"
          y2="165.328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDB1" />
          <stop offset="0.27" stopColor="#FEDF6B" />
          <stop offset="0.62" stopColor="#FDC727" />
          <stop offset="0.93" stopColor="#F39D50" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_48_348"
          x1="34.1555"
          y1="87.8026"
          x2="174.835"
          y2="87.8026"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC961" />
          <stop offset="1" stopColor="#FFE98B" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_48_348"
          x1="67.1059"
          y1="21.8729"
          x2="142.199"
          y2="151.561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0B335" />
          <stop offset="0.23" stopColor="#EEB854" />
          <stop offset="0.45" stopColor="#EFB550" />
          <stop offset="0.8" stopColor="#FFD06C" />
          <stop offset="1" stopColor="#FBEBBB" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_48_348"
          x1="137.804"
          y1="162.292"
          x2="111.076"
          y2="24.9058"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF8F8" stopOpacity="0.46" />
          <stop offset="1" stopColor="#FFEBEB" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const GoldenBadgeLarge = memo(GoldenBadgeLargeComponent)

import { FC } from 'react'
import { IonItem } from '@ionic/react'

import { Avatar } from '@components/atoms/Avatar'

import { appConfig } from '@/services/config'
import { formatDistance } from '@/utils/date'

import { ActivityRewardCustom } from '@/types/activity/reward'

interface IActivityCustomRewardItemProps {
  item: ActivityRewardCustom
}

export const ActivityCustomRewardItem: FC<IActivityCustomRewardItemProps> = ({
  item,
}) => {
  const { time, data } = item

  return (
    <IonItem className="py-4 border-solid border-b-[0.0625rem] border-zinc-800">
      <span className="pr-4">
        <Avatar userId={appConfig.republikUserId} clickable isAmbassador />
      </span>
      <div className="w-full flex flex-col justify-between">
        <div className="text-sm">{data.message}</div>
        <div className="text-xs text-zinc-600">{formatDistance(time)}</div>
      </div>
    </IonItem>
  )
}

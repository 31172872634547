import { FC } from 'react'

interface IWalletHero {
  title?: string
  children: React.ReactNode
  footer?: React.ReactNode
  handleOnClick?: () => void
}

export const WalletHero: FC<IWalletHero> = ({
  title,
  children,
  footer,
  handleOnClick,
}) => {
  return (
    <div className="relative rounded-[1.25rem] overflow-hidden">
      <div className="absolute top-[25px] right-5 w-[3.125rem] h-[3.125rem] sm:w-[5rem] sm:h-[5rem]">
        <picture className="!w-full !h-full">
          <source srcSet="/vault/currency_logo.png 358w, /vault/currency_logo@2x.png 716w, /vault/currency_logo@3x.png 1074w" />
          <img src="/vault/currency_logo.png" alt="logo" />
        </picture>
      </div>
      <div className="px-6 py-4 bg-[url('/vault/Vault_panel.png')] sm:bg-[url('/vault/Vault_panel@2x.png')] lg:bg-[url('/vault/Vault_panel@3x.png')] bg-cover bg-center">
        <h2 className="pt-2 text-[4.5vw] sm:text-[1.75rem]">{title}</h2>
        <div className="pt-2">{children}</div>
      </div>
      {footer && (
        <div
          onClick={handleOnClick}
          className="flex cursor-pointer flex-col px-6 py-4 bg-[url('/vault/Vault_panel_background_2.png')] sm:bg-[url('/vault/Vault_panel_background_2@2x.png')] lg:bg-[url('/vault/Vault_panel_background_2@3x.png')] bg-cover"
        >
          {footer}
        </div>
      )}
    </div>
  )
}

import { del, post } from '@/services/awsAmplify'

interface IAddCommentParams {
  activityId: UUID
  comment: string
  mentions?: UUID[]
  reactionTargetId?: UUID
}

interface IDeleteCommentParams {
  activityId: UUID
  commentId: UUID
}

export const addComment = async ({
  activityId,
  comment,
  mentions,
  reactionTargetId,
}: IAddCommentParams) => {
  return await post({
    path: `/activity-comments/${activityId}`,
    params: {
      comment,
      mentions,
      reactionTargetId,
    },
  })
}

export const deleteComment = async ({
  activityId,
  commentId,
}: IDeleteCommentParams) => {
  return await del({
    path: `/activity-comments/${activityId}/comments/${commentId}`,
  })
}

import { FC, MutableRefObject, useCallback, useEffect, useState } from 'react'
import { IonModal, isPlatform } from '@ionic/react'
import queryString from 'query-string'

import { CommentsInput } from '@/components/organisms/CommentsInput'
import { IActivityItem } from '@/components/pages/Activity/interfaces'

import { useCommentsSection } from '@/lib/hooks/useCommentsSection'
import { useVisualViewport } from '@/lib/hooks/useVisualViewport'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { replaceMentionsWithUsernames } from '@/utils/replaceMentionsWithUsernames'
import {
  getUserIdsFromMentions,
  isIosWebView,
  isMobileWeb,
} from '@/utils/utils'

import { FeedSections, Pages } from '@/enums'

type CommentsInputFooterType = {
  activityId: UUID | 'conversation'
  activity: IActivityItem
  search: string
  commentsRef: MutableRefObject<HTMLSpanElement>
}

export const CommentsInputFooter: FC<CommentsInputFooterType> = ({
  activityId,
  activity,
  search,
  commentsRef,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true)
  const { page = '' } = queryString.parse(search) as { page: string }

  const searchParams = new URLSearchParams(search)
  const replyId = searchParams.get('replyId')
  const replyObjectId = searchParams.get('replyObjectId')
  const createdAt = searchParams.get('createdAt')

  const { channel = '' } = queryString.parse(search) as { channel: string }

  const isPost = channel === FeedSections.Post
  const isConversation = channel === FeedSections.Conversation

  const { conversationId, isAddCommentLoading, onAddComment } =
    useCommentsSection({
      activityId,
      replyObjectId,
      createdAt,
    })

  const [initialVisualViewportHeight, setInitialVisualViewportHeight] =
    useState(0)

  const {
    visualViewport: { height: visualViewportHeight },
  } = useVisualViewport()

  useEffect(() => {
    setInitialVisualViewportHeight(visualViewportHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isPlatform('ios') && isMobileWeb()) {
      window.scrollTo({
        top: initialVisualViewportHeight - visualViewportHeight,
        behavior: 'auto',
      })
    }
  }, [visualViewportHeight, initialVisualViewportHeight])

  const trackingFeed = () => {
    isConversation &&
      Tracking.triggerEvent(TrackingEvent.ConversationCommented, {
        conversationId: conversationId,
      })
    isPost &&
      Tracking.triggerEvent(TrackingEvent.PostCommented, {
        postId: activityId,
      })
  }

  const handleAddComment = async (comment: string) => {
    await onAddComment(
      replaceMentionsWithUsernames(comment),
      getUserIdsFromMentions(comment),
      replyId || conversationId
    )

    if (!isIosWebView() && commentsRef.current) {
      commentsRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    if (page === Pages.Feed) trackingFeed()
  }

  const backButtonListener = useCallback(() => {
    history.back()
  }, [])

  useEffect(() => {
    document.addEventListener('ionBackButton', backButtonListener)
    setIsModalOpen(true)

    return () => {
      document.removeEventListener('ionBackButton', backButtonListener)
      setIsModalOpen(false)
    }
  }, [backButtonListener])

  if (!activity) return null

  return (
    <>
      <div className="bg-cool-gray-900" style={{ height: 76 }} />
      <IonModal
        className="comments-input-modal"
        initialBreakpoint={1}
        breakpoints={[1]}
        isOpen={isModalOpen}
        handle={false}
        backdropBreakpoint={1}
        animated={false}
      >
        <CommentsInput
          isLoading={isAddCommentLoading}
          handleComment={handleAddComment}
        />
      </IonModal>
    </>
  )
}

import { FC } from 'react'

import { UserInfo } from '@/services/user'

import { Section } from '../UserProfileSections/types'
import { StoxSectionContent } from './StoxSectionContent'

interface StoxSectionProps {
  isAuthUser: boolean
  userInfo: UserInfo
  section: Section
  creatorTokenId?: string
}

export const StoxSection: FC<StoxSectionProps> = ({
  isAuthUser,
  userInfo,
  section,
  creatorTokenId,
}) => {
  return (
    <div className="relative w-[100vw] max-w-[40rem] h-full">
      <picture>
        <source
          srcSet={`/user_profile_sections/bg_${section.imageName}.png 332w, /user_profile_sections/bg_${section.imageName}@2x.png 664w, /user_profile_sections/bg_${section.imageName}@3x.png 996w`}
        />
        <img
          src={`/user_profile_sections/bg_${section.imageName}.png`}
          alt="user-profile-section-background"
        />
      </picture>
      <StoxSectionContent
        isAuthUser={isAuthUser}
        userInfo={userInfo}
        creatorTokenId={creatorTokenId}
      />
    </div>
  )
}

import { del } from '@/services/awsAmplify'

export const deleteConversation = async (conversationId: UUID) => {
  return await del({
    path: `/conversations/${conversationId}`,
  })
}

export const deleteReply = async (payload: {
  conversationId: UUID
  replyId: UUID
}) => {
  return await del({
    path: `/conversations/${payload.conversationId}/replies/${payload.replyId}`,
  })
}

import { get, post } from '@/services/awsAmplify'
import { safelyJsonStringify } from '@/utils/utils'

export const createSession = async (): Promise<string> => {
  return await post({
    path: '/kyc/face-liveness',
  })
    .then((res: { sessionId: string }) => {
      return <string>res.sessionId
    })
    .catch((e) => {
      console.error('=== ERROR (createSession) ===', safelyJsonStringify(e))
      throw e
    })
}

export const isLive = async (args: { sessionId: string }) => {
  return await get({ path: `/kyc/face-liveness/${args.sessionId}` })
    .then((res) => {
      return { isLive: <boolean>res.isLive, confidence: <number>res.confidence }
    })
    .catch((e) => {
      console.error('=== ERROR (isLive) ===', safelyJsonStringify(e))
      throw e
    })
}

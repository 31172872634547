import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Share } from '@/components/atoms/assets/Share'

import { appConfig } from '@/services/config'
import { useGetAvatar } from '@/lib/hooks/useGetAvatar'
import { useUserInfo } from '@/lib/hooks/useUserInfo'

import { ShareButton } from '../ShareButton'

interface InviteFriendButtonProps {
  buttonLabel?: string
  className?: string
  iconOnly?: boolean
  sharedUserId?: UUID
}

export const InviteFriendButton: FC<InviteFriendButtonProps> = ({
  buttonLabel,
  className,
  iconOnly,
  sharedUserId,
}) => {
  const { t } = useTranslation('common')
  const getAvatar = useGetAvatar()
  const { data: authUser } = useUserInfo()
  const userId = sharedUserId || '@' + authUser?.username

  const ButtonLabel = useCallback(() => {
    if (iconOnly) return <Share className="cursor-pointer" color="white" />

    return buttonLabel || t('XPWallet.missions.inviteFriend.actionText')
  }, [iconOnly, buttonLabel, t])

  return (
    <ShareButton
      className={`rounded-full overflow-hidden ${className}`}
      message={t('profile.invite.message')}
      subject={t('profile.invite.subject')}
      buttonLabel={<ButtonLabel />}
      url={`${appConfig.origin}/${userId}`}
      imageUrl={getAvatar(userId)}
      feature="referral"
      params={{ refUsername: String(authUser?.username) }}
      plain={!iconOnly}
    />
  )
}

import { FC, memo, useCallback, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SubscribeButton } from '@/components/molecules/SubscribeButton'

import { appConfig } from '@/services/config'
import { UserInfo } from '@/services/user'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useWallet } from '@/lib/hooks/wallet'

import { DepositCurrency, DepositCurrencyRef } from '../DepositCurrency'
import { PaymentModal } from '../Payment'
import { PaymentModalRef } from '../Payment/PaymentModal/PaymentModal'

interface ISubscriptionButtonWithTipComponentProps {
  userInfo: UserInfo
  amount: number
  activityId?: string
}
const SubscriptionButtonWithTipComponent: FC<
  ISubscriptionButtonWithTipComponentProps
> = ({ userInfo, amount, activityId }) => {
  const { t } = useTranslation('common')
  const currency = appConfig.currency
  const { data: authUser } = useUserInfo()
  const { isZeroBalance } = useWallet({
    userInfo: authUser,
  })
  const depositCurrencyRef = useRef<DepositCurrencyRef>()
  const paymentModalRef = useRef<PaymentModalRef>()

  const translation = {
    paymentType: t('paymentSubscription.unlockSubscription'),
    withUsername: t('paymentSubscription.withUsername', {
      username: userInfo?.username,
    }),
    price: t('paymentSubscription.labels.price'),
    republikFee: t('paymentSubscription.labels.republikFee'),
    totalPayment: t('paymentSubscription.labels.total'),
    yourWalletBalance: t('paymentSubscription.labels.yourWalletBalance'),
    pay: t('paymentSubscription.actions.pay'),
    info: t('paymentSubscription.info'),
    paymentSuccessful: t('paymentSubscription.paymentSuccessful'),
    paymentUnlockedWith: t('paymentSubscription.paymentUnlockedWith'),
    startToAction: t('paymentSubscription.actions.startToSubscribe'),
    errorSummaryTitle: t('unlockChat.errorSummaryTitle'),
    errorSummaryDetails: t('unlockChat.errorSummaryDetails'),
    close: t('unlockChat.actions.close'),
  }

  const onSubscribe = useCallback(
    (e) => {
      if (isZeroBalance) {
        return depositCurrencyRef.current.openModal()
      }

      e.stopPropagation()
      paymentModalRef.current.openModal()
      // setModalId(`send-subscribe-modal-${uuid}`)
    },
    [isZeroBalance]
  )

  return (
    <>
      <SubscribeButton onClick={onSubscribe}>
        <span className="text-sm xs:px-4">
          {t('profile.subscription.subscribeButton', {
            amount,
            currency,
          })}
        </span>
      </SubscribeButton>
      <p className="text-[1rem] text-xs mt-3">
        <Trans i18nKey="profile.subscription.subscribeTip">
          {t('profile.subscription.subscribeTip')}
        </Trans>
      </p>

      <PaymentModal
        activityId={activityId}
        ref={paymentModalRef}
        userInfo={userInfo}
        amount={amount}
        translation={translation}
        paymentType="subscription"
      />
      <DepositCurrency
        title={t('depositBottomSheet.topUpCurrencyToSubscribe', {
          currency,
        })}
        ref={depositCurrencyRef}
      />
    </>
  )
}

export const SubscriptionButtonWithTip = memo(
  SubscriptionButtonWithTipComponent
)

import { useMemo } from 'react'
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'

import {
  getFindUsers,
  GetFindUsersPaginated,
  IUser,
  UserSearchFollowStatus,
} from '@/services/findUsers'
import { queryClient } from '@/lib/queryClient'

import { FollowStatus } from '@/enums'
import { ISearchQuery } from '@/interfaces'

export const useFindUsers = (
  query: ISearchQuery,
  params?: Omit<
    UseInfiniteQueryOptions<GetFindUsersPaginated, Error>,
    'queryKey' | 'getNextPageParam' | 'initialPageParam'
  >
) => {
  const { data, isLoading, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ['findUsers', query.q],
      queryFn: ({ pageParam }) => getFindUsers(query, pageParam as string),
      getNextPageParam: () => undefined,
      initialPageParam: '',
      staleTime: 0,
      gcTime: 0,
      ...params,
    })

  const clearData = () => {
    if (data?.pages?.length) {
      queryClient.setQueryData<InfiniteData<unknown>>(
        ['findUsers', query.q],
        (data) => ({
          pages: [data?.pages?.[0]],
          pageParams: [data?.pageParams?.[0]],
        })
      )
    }
  }

  const refetchData = async () => {
    clearData()
    await refetch()
  }

  const userData = useMemo(() => {
    const followStatusOrder: UserSearchFollowStatus[] = [
      FollowStatus.Friends,
      FollowStatus.Followed,
      FollowStatus.Following,
      FollowStatus.Unknown,
    ]

    return followStatusOrder.flatMap((followStatus) =>
      (
        ((data?.pages[0] || { users: {} })?.users[followStatus] as unknown[]) ??
        []
      ).filter(Boolean)
    )
  }, [data]) as IUser[]

  return {
    isLoading,
    refetchData,
    data: userData,
    fetchNextPage,
    hasNextPage,
  }
}

import { omit } from 'lodash'

export interface IState {
  signInRedirectPath?: string
  desktopUrl?: string
  muteVideo?: boolean
  isVideoPlaybackAllowed?: boolean
  hasNotifications: {
    init: boolean
    value: boolean
  }
  serviceWorkerInitialized: boolean
  serviceWorkerUpdated: boolean
  serviceWorkerRegistration: ServiceWorkerRegistration | undefined
  uploadProgress: {
    [key: string]: {
      progress: number
      description: string
    }
  }
  shouldShowStreakReminders?: boolean
}

export enum ActionType {
  SIGN_IN_REDIRECT = 'SIGN_IN_REDIRECT',
  MUTE_VIDEO = 'MUTE_VIDEO',
  SET_VIDEO_PLAYBACK_ALLOWED = 'SET_VIDEO_PLAYBACK_ALLOWED',
  HAS_NOTIFICATIONS = 'HAS_NOTIFICATIONS',
  INIT_ACTIVITY = 'INIT_ACTIVITY',
  SW_INIT = 'SW_INIT',
  SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS',
  REMOVE_UPLOAD_PROGRESS = 'REMOVE_UPLOAD_PROGRESS',
  SHOW_STREAK_REMINDERS = 'SHOW_STREAK_REMINDERS',
}

const Reducer = (state: IState, action) => {
  switch (action.type) {
    case ActionType.REMOVE_UPLOAD_PROGRESS: {
      return {
        ...state,
        uploadProgress: omit(state.uploadProgress, action.payload.key),
      }
    }
    case ActionType.SET_UPLOAD_PROGRESS: {
      return {
        ...state,
        uploadProgress: {
          ...(state.uploadProgress || {}),
          [action.payload.key]: {
            progress: action.payload.progress,
            description: action.payload.description,
          },
        },
      }
    }
    case ActionType.SIGN_IN_REDIRECT: {
      return {
        ...state,
        signInRedirectPath: action.payload,
      }
    }
    case ActionType.MUTE_VIDEO: {
      return {
        ...state,
        muteVideo: action.payload,
      }
    }
    case ActionType.SET_VIDEO_PLAYBACK_ALLOWED: {
      return {
        ...state,
        isVideoPlaybackAllowed: action.payload,
      }
    }
    case ActionType.HAS_NOTIFICATIONS: {
      return {
        ...state,
        hasNotifications: action.payload,
      }
    }
    case ActionType.SW_INIT: {
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      }
    }
    case ActionType.SHOW_STREAK_REMINDERS: {
      return {
        ...state,
        shouldShowStreakReminders: action.payload,
      }
    }
    default:
      return state
  }
}

export default Reducer

import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { errorBoundary } from '@/services/errorBoundary'
import { createPost, deletePost } from '@/services/post'

import { newrelicNoticeError } from '../tracking'
import { useFileUpload } from './useFileUpload'
import { useInvalidateQueries } from './useInvalidateQueries'
import { useUserInfo } from './useUserInfo'

import { MediaType } from '@/plugins/ugc-composer'

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const usePost = ({ onFileUploadComplete = () => {} } = {}) => {
  const { data: userInfo } = useUserInfo()
  const { invalidateQueriesTimeout } = useInvalidateQueries()
  const { upload } = useFileUpload()

  const {
    mutate: onCreatePost,
    isPending: isCreatePostLoading,
    isError: isCreatePostError,
  } = useMutation({
    mutationFn: createPost,
    mutationKey: ['createPost'],
    onError: (e) => {
      return errorBoundary(e)
    },
  })

  const {
    mutateAsync: onDeletePost,
    isPending: isDeletePostLoading,
    isError: isDeletePostError,
  } = useMutation({
    mutationFn: deletePost,
    mutationKey: ['deletePost'],
    onError: errorBoundary,
    onSettled: () => {
      invalidateQueriesTimeout()
    },
  })

  const uploadMediaFiles = useCallback(
    async (
      postId: string,
      media: { type: MediaType; url: string }[],
      description?: string
    ) => {
      if (!media.length) return

      await Promise.all(
        media.map(({ type: mediaType, url }, index) =>
          fetch(url)
            .then((res) => res.blob())
            .then((file) => {
              const fileName =
                mediaType === 'image'
                  ? `users/${userInfo.id}/post/${postId}_${index}.${file.type
                      .split('/')
                      .at(-1)}`
                  : `users/${userInfo.id}/post/${postId}_${index}`
              return upload({
                fileName,
                file,
                description: `(${index + 1}) ${description}`,
              })
            })
            .catch((e) => {
              newrelicNoticeError({
                error: e.message,
                customAttributes: {
                  location: window.location.pathname,
                  username: userInfo?.username,
                },
              })
            })
        )
      )

      onFileUploadComplete()
    },
    [onFileUploadComplete, userInfo, upload]
  )

  return {
    onCreatePost,
    isCreatePostLoading,
    isCreatePostError,
    onDeletePost,
    isDeletePostLoading,
    isDeletePostError,
    uploadMediaFiles,
  }
}

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'

import { deserialize, serialize } from '@/utils/queryClient'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      gcTime: 1000 * 60,
      staleTime: Infinity,
      retry: 1,
      retryDelay: 500,
      networkMode: 'offlineFirst',
    },
  },
})
const persister = createSyncStoragePersister({
  key: 'cache',
  storage: window.localStorage,
  serialize: (data) => serialize(queryClient, data),
  deserialize: (data) => deserialize(queryClient, data),
})

export { queryClient, persister }

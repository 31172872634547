import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PostFeedSlider } from '@/components/organisms/FeedActivity/PostFeedActivity/PostFeedSlider'
import { getFirstAvailableRatio } from '@/components/organisms/FeedActivity/PostFeedActivity/utils'
import { PostUserTeaser } from '@/components/organisms/PostUserTeaser'

import { useMediaContainer } from '@/lib/hooks/useMediaContainer'
import { useRedirect } from '@/lib/hooks/useRedirect'
import { calculateContainerHeight, getMaxViewportWidth } from '@/utils/utils'

import { IMedia } from '@/interfaces'

type PostContentProps = {
  media: IMedia[]
  username: string
}

export const PostContent: FC<PostContentProps> = ({ media, username }) => {
  const { redirectToLogin } = useRedirect()
  const { t } = useTranslation('common')
  const containerRatio = useMemo(() => getFirstAvailableRatio(media), [media])
  const { paddingBottom } = useMediaContainer({
    ratio: containerRatio,
    index: 0,
  })
  const containerHeight = useMemo(
    () => calculateContainerHeight(containerRatio, getMaxViewportWidth()),
    [containerRatio]
  )

  return (
    <div
      onClick={redirectToLogin}
      className="relative h-full w-full bg-cover cursor-pointer"
    >
      <PostUserTeaser
        title={t('publicPost.teaser.title')}
        subTitle={t('publicPost.teaser.subtitle', { username })}
        buttonText={t('publicPost.teaser.button')}
      />
      <div className="w-full h-auto">
        <PostFeedSlider
          media={media}
          containerRatio={containerRatio}
          paddingBottom={paddingBottom}
          containerHeight={containerHeight}
          isActiveActivity={true}
          handleDoubleClick={null}
          inView={true}
          mediaContainerRef={null}
          isScrollingFast={false}
          isZooming={false}
          setIsZooming={() => null}
          isLikeActive={false}
          activityId={null}
          itemId={null}
          foreignId={null}
        />
      </div>
    </div>
  )
}

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonModal, isPlatform } from '@ionic/react'

import { appConfig } from '@/services/config'
import { UserInfo } from '@/services/user'
import { useChat } from '@/lib/hooks/chat/useChat'
import { useCreateTransaction } from '@/lib/hooks/wallet'
import { useNavigate } from '@/lib/routing'

import { DepositCurrency, DepositCurrencyRef } from '../../DepositCurrency'
import { Summary } from '../Summary'
import { UnlockChat } from '../UnlockChat'

type UnlockChatModalProps = {
  modalId: string
  amount: number
  userInfo: UserInfo
}

export const UnlockChatModal: FC<UnlockChatModalProps> = ({
  modalId,
  amount,
  userInfo,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const currency = appConfig.currency
  const isDesktop = isPlatform('desktop')
  const modalRef = useRef<HTMLIonModalElement>(null)
  const depositCurrencyRef = useRef<DepositCurrencyRef>(null)
  const [isLoading, setIsLoading] = useState(false)
  const {
    createTransactionData,
    createTransaction,
    error: unlockChatError,
    isLoadingTransactionData,
    resetTransactionData,
  } = useCreateTransaction({ userId: userInfo?.id })

  const { data: chatData, refetch: chatDataRefetch } = useChat({
    userId: userInfo.id,
    enabled: !!createTransactionData,
  })

  const dismissModal = useCallback(() => {
    modalRef?.current?.dismiss()
  }, [])

  const handleDismissModal = useCallback(() => {
    resetTransactionData()
    dismissModal()
  }, [dismissModal, resetTransactionData])

  const handleStartToChat = useCallback(() => {
    const userId = createTransactionData?.destination?.id

    handleDismissModal()
    if (userId) navigate(`/messages/${userId}`)
  }, [handleDismissModal, navigate, createTransactionData?.destination?.id])

  useEffect(() => {
    let interval = null
    if (createTransactionData) {
      interval = setInterval(() => {
        chatDataRefetch()
      }, 1000)
    }
    if (chatData?.status === 'UNLOCKED') {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [createTransactionData, chatData, chatDataRefetch])

  useEffect(() => {
    if (isLoadingTransactionData) {
      setIsLoading(true)
    }
  }, [isLoadingTransactionData])

  const handleBuyMoreCurrency = useCallback(() => {
    dismissModal()
    depositCurrencyRef?.current?.openModal()
  }, [dismissModal])

  return (
    <>
      <IonModal
        ref={modalRef}
        trigger={modalId}
        className="payment-modal"
        initialBreakpoint={isDesktop ? undefined : 1}
        breakpoints={isDesktop ? undefined : [0, 1]}
      >
        <div className="w-full flex flex-col items-center text-center sm:w-3/4 px-8 pt-5 pb-5 sm:py-8">
          {createTransactionData && chatData?.status === 'UNLOCKED' ? (
            <Summary
              unlockChatData={createTransactionData}
              unlockChatError={unlockChatError}
              handleDismissModal={handleDismissModal}
              handleStartToChat={handleStartToChat}
            />
          ) : (
            <UnlockChat
              amount={amount}
              userInfo={userInfo}
              unlockChat={() =>
                createTransaction({ value: String(amount), type: 'chat' })
              }
              onBuyMoreCurrency={handleBuyMoreCurrency}
              isLoadingUnlockChatData={isLoading}
            />
          )}
        </div>
      </IonModal>
      <DepositCurrency
        title={t('depositBottomSheet.topUpCurrency', { currency })}
        ref={depositCurrencyRef}
      />
    </>
  )
}

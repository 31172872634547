import { getCurrentUser } from 'aws-amplify/auth'
import { fetchAuthSession } from 'aws-amplify/auth'

import { del, get, post, put } from '@/services/awsAmplify'

import { Badge, FollowStatus } from '@enums'

export type FollowType = boolean

export interface UserInfo {
  avatar: string
  displayName?: string
  username: string
  bio?: string
  profileImage?: UrlString
  phoneNumber: string
  ageConsentTimestamp: ISODateString
  ageConsent: boolean
  completedFaceLivenessCheck: boolean
  setup2FA: boolean
  id: UUID
  type: 'PROFILE'
  email?: string
  pendingEmail?: string
  badge?: Badge
  isModerator?: boolean
  isAmbassador?: boolean
  followingCount: number
  followerCount: number
  followStatus: FollowStatus
  deactivated?: boolean
  isEmailValidated?: boolean
  refUsername?: string
  isPhoneValidated?: boolean
  normalizedEmail?: string
  updatedAt?: string
  createdAt?: string
  aup?: boolean
  creatorTokenId?: string
}

export const getUserInfo = async ({ queryKey }): Promise<UserInfo> => {
  const [, userId] = queryKey

  const { username } = await getCurrentUser()

  return get({
    path: `/profile/${userId || username}`,
  })
}

export interface IFollowStatusDTO {
  followingProfileId: UUID
  followedProfileId: UUID
  followStatus: FollowStatus
}

export interface IFollowParams {
  userId: string
}

export const follow = async ({
  userId,
}: IFollowParams): Promise<IFollowStatusDTO> => {
  const data = await post({
    path: `/profile/${userId}/followers`,
  })

  return data as unknown as IFollowStatusDTO
}

export const unfollow = async ({
  userId,
}: IFollowParams): Promise<IFollowStatusDTO> => {
  const data = await del({
    path: `/profile/${userId}/followers`,
  })

  return data as unknown as IFollowStatusDTO
}

export const block = async ({
  userId,
}: IFollowParams): Promise<IFollowStatusDTO> => {
  const data = await post({
    path: `/profile/${userId}/block`,
  })

  return data as unknown as IFollowStatusDTO
}

export const unblock = async ({
  userId,
}: IFollowParams): Promise<IFollowStatusDTO> => {
  const data = await del({
    path: `/profile/${userId}/unblock`,
  })

  return data as unknown as IFollowStatusDTO
}

export const deleteAccount = async (): Promise<UserInfo> => {
  const { username } = await getCurrentUser()
  return del({
    path: `/profile/${username}`,
  }) as unknown as UserInfo
}

export type Get2FASecretKeyResult = { secretKey: string; qrCode: string }

export const get2FASecretKey = async (): Promise<Get2FASecretKeyResult> => {
  const { accessToken } = (await fetchAuthSession()).tokens ?? {}

  const result = await post({
    path: '/2fa/secret-key',
    params: {
      accessToken: accessToken?.toString(),
    },
  })

  return result as unknown as Get2FASecretKeyResult
}

export const setup2FA = async ({ code }: { code: string }) => {
  const { accessToken } = (await fetchAuthSession()).tokens ?? {}

  const result = await post({
    path: '/2fa',
    params: {
      accessToken: accessToken?.toString(),
      code,
    },
  })

  return result
}

export const remove2FA = async (code: string) => {
  const { accessToken } = (await fetchAuthSession()).tokens ?? {}

  const result = await put({
    path: '/2fa',
    params: {
      accessToken: accessToken?.toString(),
      code,
    },
  })

  return result
}

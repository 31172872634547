import { FC, useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IonSpinner } from '@ionic/react'
import cx from 'classnames'

import { Currency } from '@/components/atoms/Currency'
import { networkOptionsData } from '@/components/pages/Wallet/components/NetworkDropdown/NetworkDropdown'

import {
  IWithdrawEstimation,
  WithdrawErrorMessage,
} from '@/services/wallet/withdraw'

import { FormValues } from '../../Withdraw'

type TransferFeesProps = {
  selectedNetwork: string
  estimation: IWithdrawEstimation
  isFetching: boolean
  isSummary?: boolean
}

export const TransferFees: FC<TransferFeesProps> = ({
  selectedNetwork,
  estimation,
  isFetching,
  isSummary = false,
}) => {
  const { t } = useTranslation('common')
  const {
    formState: { errors },
  } = useFormContext<FormValues>()

  const hasInsufficientFunds = useMemo(
    () =>
      WithdrawErrorMessage[estimation?.errorMessage] ===
      WithdrawErrorMessage.SOURCE_WALLET_INSUFFICIENT_FOUNDS,
    [estimation?.errorMessage]
  )

  const network = useMemo(
    () => networkOptionsData.find((option) => option.value === selectedNetwork),
    [selectedNetwork]
  )

  const NetworkFee = useCallback(
    () => (
      <div className="flex flex-row justify-between mb-1 text-sm">
        <span>
          {t('withdraw.networkFee', {
            suffix: network?.suffix ? ` (${network?.suffix})` : '',
          })}
        </span>
        <span className="text-white">
          {isFetching ? (
            <IonSpinner className="h-3" color="medium" name="dots" />
          ) : estimation?.amount && estimation?.networkFee ? (
            <Currency value={estimation.networkFee} />
          ) : (
            '-'
          )}
        </span>
      </div>
    ),
    [t, network?.suffix, isFetching, estimation?.amount, estimation?.networkFee]
  )

  const PlatformFee = useCallback(
    () => (
      <div className="flex flex-row justify-between mb-1 text-sm">
        <span>{t('withdraw.withdrawalFee')}</span>
        <span className="text-white">
          {isFetching ? (
            <IonSpinner className="h-3" color="medium" name="dots" />
          ) : estimation?.amount && estimation?.platformFee ? (
            <Currency value={estimation.platformFee} />
          ) : (
            '-'
          )}
        </span>
      </div>
    ),
    [isFetching, t, estimation]
  )

  const TotalAmountSent = useCallback(
    () => (
      <div className="flex flex-row justify-between mb-1 text-white text-sm font-bold">
        <span>{t('withdraw.totalAmountSent')}</span>
        <span className="text-white">
          {isFetching ? (
            <IonSpinner className="h-3" color="medium" name="dots" />
          ) : estimation?.amount && estimation?.totalAmount ? (
            <Currency value={estimation.totalAmount} />
          ) : (
            '-'
          )}
        </span>
      </div>
    ),
    [isFetching, t, estimation]
  )

  const VaultBalanceAfterSending = useCallback(
    () => (
      <div className="flex flex-row justify-between mb-1 text-white text-sm">
        <span>{t('withdraw.balanceAfterSending')}</span>
        <span className="text-white">
          {isFetching ? (
            <IonSpinner className="h-3" color="medium" name="dots" />
          ) : estimation?.amount && estimation?.newBalance ? (
            <span className={cx({ 'text-danger': hasInsufficientFunds })}>
              <Currency value={estimation.newBalance} />
            </span>
          ) : (
            '-'
          )}
        </span>
      </div>
    ),
    [
      t,
      isFetching,
      estimation?.amount,
      estimation?.newBalance,
      hasInsufficientFunds,
    ]
  )

  const TokenStandardInfo = useCallback(
    () => (
      <div className="flex flex-row justify-between mb-1 text-sm">
        <span>{t('withdraw.network')}</span>
        <span className="text-white">
          {!!network && (
            <span className="capitalize">
              {network.value}
              {network?.suffix ? ` (${network?.suffix})` : ''}
            </span>
          )}
        </span>
      </div>
    ),
    [network, t]
  )

  const ErrorMessage = useCallback(
    () =>
      errors.amount?.message ? (
        <span className="text-xs font-bold text-danger">
          {errors.amount?.message}
        </span>
      ) : null,
    [errors.amount?.message]
  )

  return (
    <div className="mb-8 text-cool-gray-200">
      {!isSummary ? (
        <>
          <NetworkFee />
          <PlatformFee />
          <TotalAmountSent />
          <VaultBalanceAfterSending />
        </>
      ) : (
        <>
          <TokenStandardInfo />
          <NetworkFee />
          <PlatformFee />
          <TotalAmountSent />
        </>
      )}
      <ErrorMessage />
    </div>
  )
}

import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'

import { Login } from '@components/templates/login'
import SetEmail, {
  EmailFieldValues,
} from '@/components/pages/Auth/SignInOptions/Email/SetEmail'

import { signIn } from '@services/auth'
import { errorBoundary } from '@/services/errorBoundary'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Navigate, useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

import { AuthError } from '@/enums'

const Email = () => {
  const navigate = useNavigate()
  const { data: authUser } = useUserInfo()
  const { control, handleSubmit, formState } = useForm<EmailFieldValues>({
    defaultValues: {
      email: '',
      geeCaptchaToken: null,
    },
  })

  const { mutate, isPending, error } = useMutation({
    mutationKey: ['signIn'],
    mutationFn: signIn,
    onSuccess: () => {
      navigate(Routes.AUTH_CONFIRM, {
        state: { referrer: 'email' },
      })
    },
    onError: (error: Error) => {
      errorBoundary(error)
      if (error.toString().includes('UsernameExistsException')) {
        navigate(Routes.AUTH_CONFIRM)
      }
    },
  })
  const { t } = useTranslation('common')
  const onSubmit = async ({ email, geeCaptchaToken }) => {
    if (!isPending) {
      mutate({
        username: email?.toLowerCase(),
        token: geeCaptchaToken,
        authType: 'EMAIL',
      })
    }
  }

  const title = (
    <Trans i18nKey="registration.email.title">
      {t('registration.email.title')}
    </Trans>
  )

  if (authUser?.username && !authUser?.deactivated) {
    return <Navigate to={Routes.FEED} />
  }

  return (
    <Login title={title} backButton>
      <form className="px-4" onSubmit={handleSubmit(onSubmit)}>
        <SetEmail
          formState={formState}
          isErrored={{
            email:
              error?.toString().includes('InvalidParameterException') ?? false,
            geeCaptchaToken:
              error?.toString().includes(AuthError.Captcha) ?? false,
            signInBlockedDomain:
              error?.message.includes(AuthError.SignInBlockedDomain) ?? false,
            signUpBlockedDomain:
              error?.message.includes(AuthError.SignUpBlockedDomain) ?? false,
            invalidCharacters:
              error?.message.includes(AuthError.InvalidEmailCharacters) ??
              false,
            signUpNotAvailable:
              error?.message.includes(AuthError.SignUpNotAvailable) ?? false,
            disabledUser:
              error?.message.includes(AuthError.DisabledUser) ?? false,
          }}
          isLoading={isPending}
          control={control}
          submit={handleSubmit(onSubmit)}
        />
      </form>
    </Login>
  )
}

export default Email

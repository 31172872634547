import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonButtons, IonTitle } from '@ionic/react'
import { AxiosError } from 'axios'

import { BackButton } from '@components/atoms/BackButton'
import { Title } from '@components/atoms/Title'
import { Loading } from '@/components/atoms/Loading'
import WarningAlert, {
  WarningAlertRef,
} from '@/components/molecules/WarningAlert'
import { Main } from '@/components/templates/main'
import {
  CopyWalletAddress,
  ImportantWarning,
  MinimumDepositValue,
} from './components'

import { appConfig } from '@/services/config'
import { useShowErrorToast } from '@/lib/hooks/useShowErrorToast'
import { useDeposit } from '@/lib/hooks/wallet/useDeposit'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { WALLET } from '@/utils/route'
import { toTokenString } from '@/utils/token'

import { NetworkDropdown } from '../Wallet/components/NetworkDropdown'
import { networkOptionsData } from '../Wallet/components/NetworkDropdown/NetworkDropdown'

enum DepositErrorMessage {
  USER_NOT_FOUND = 'userNotFound',
  USER_DEACTIVATED = 'userDeactivated',
  USER_NOT_KYC_VERIFIED = 'userNotKycVerified',
  TWO_FACTOR_SETUP_NOT_FOUND = 'twoFactorSetupNotFound',
  GLOBALLY_DEACTIVATED = 'globallyDeactivated',
}

export const Deposit: FC = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { showErrorToast } = useShowErrorToast()
  const [selectedNetwork, setSelectedNetwork] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const {
    data: addressData,
    isFetching: isFetchingAddressData,
    error: addressError,
    isSuccess,
  } = useDeposit(selectedNetwork, { enabled: !!selectedNetwork })
  const warningRef = useRef<WarningAlertRef | null>(null)
  const [depositWarningPresented, setDepositWarningPresented] = useState(false)

  const network = useMemo(
    () => networkOptionsData.find((option) => option.value === selectedNetwork),
    [selectedNetwork]
  )

  useEffect(() => {
    if (isSuccess && !depositWarningPresented) {
      setDepositWarningPresented(true)
      warningRef?.current?.open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  useEffect(() => {
    if (!addressError) return

    if (addressError) {
      const translationKey =
        DepositErrorMessage[(addressError as AxiosError)?.response?.data]
      if (translationKey) {
        showErrorToast({
          message: t(`deposit.errors.${translationKey}` as const),
        })
      }
    }

    setTimeout(() => {
      navigate(WALLET, {
        replace: true,
      })
    }, 500)
  }, [addressError, navigate, showErrorToast, t])

  useEffect(() => {
    if (!selectedNetwork) return

    setIsLoading(true)
    setTimeout(() => setIsLoading(false), 500)
  }, [selectedNetwork])

  const header = useMemo(
    () => (
      <div className="flex justify-between items-center">
        <IonButtons slot="start">
          <BackButton defaultHref={Routes.PROFILE} />
        </IonButtons>
        <IonTitle className="px-2">
          <Title className="text-center my-0" level={3}>
            {t('deposit.pageTitle', { currency: appConfig.currency })}
          </Title>
        </IonTitle>
      </div>
    ),
    [t]
  )

  return (
    <Main
      header={header}
      contentClassName="px-8 pb-12"
      headTitle={t('deposit.pageTitle', { currency: appConfig.currency })}
      isPaddingEnabled={false}
    >
      <NetworkDropdown onSelect={setSelectedNetwork} />
      <WarningAlert
        ref={warningRef}
        title={t('deposit.warningTitle')}
        description={
          <Trans
            i18nKey="deposit.warningDescription"
            values={{
              currency: appConfig.currency,
              minDepositAmount: addressData?.minDepositAmount
                ? toTokenString(addressData?.minDepositAmount)
                : 0,
            }}
          />
        }
        acknowledgeText={t('deposit.warningUnderstood')}
      />

      {isLoading || isFetchingAddressData || addressError ? (
        <Loading />
      ) : (
        <div>
          {!!selectedNetwork && (
            <>
              <CopyWalletAddress
                walletAddress={addressData?.walletAddress}
                prefix={network?.suffix}
              />
              <MinimumDepositValue value={addressData?.minDepositAmount} />
              <ImportantWarning
                minDepositAmount={addressData.minDepositAmount}
              />
            </>
          )}
        </div>
      )}
    </Main>
  )
}

import { useCallback, useContext, useState } from 'react'
import { uploadData } from 'aws-amplify/storage'

import { Context } from '../store'
import { ActionType } from '../store/reducer'

export interface IUserFileUploadProps {
  onComplete?: () => void | Promise<void>
}

export const useFileUpload = ({ onComplete }: IUserFileUploadProps = {}) => {
  const { dispatch } = useContext(Context)
  const [progressPercentage, setProgressPercentage] = useState<number>(null)
  const [complete, setComplete] = useState(null)
  const [isUploading, setUploading] = useState<boolean>(false)
  const [error, setError] = useState('')

  const onProgressHandler = useCallback(
    ({ fileName, progressPercentage, description }) => {
      dispatch({
        type: ActionType.SET_UPLOAD_PROGRESS,
        payload: {
          key: fileName,
          progress: progressPercentage,
          description,
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onCompleteHandler = useCallback(
    ({ fileName }) => {
      setComplete(fileName)
      setUploading(false)
      dispatch({
        type: ActionType.REMOVE_UPLOAD_PROGRESS,
        payload: {
          key: fileName,
        },
      })

      if (onComplete) {
        onComplete()
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onComplete]
  )

  const upload = useCallback(
    async ({
      fileName,
      file,
      metadata,
      description,
    }: {
      fileName: string
      file: Blob
      metadata?: { [key: string]: string }
      description?: string
    }) => {
      try {
        setUploading(true)
        onProgressHandler({ fileName, progressPercentage: 1, description })

        await uploadData({
          key: fileName,
          data: file,
          options: {
            metadata,
            accessLevel: 'private',
            onProgress: (progress) => {
              const calculatedPercentage = Math.floor(
                (progress?.transferredBytes / progress?.totalBytes) * 100
              )
              setProgressPercentage(calculatedPercentage)

              onProgressHandler({
                fileName,
                progressPercentage: calculatedPercentage,
                description,
              })
            },
          },
        }).result

        onCompleteHandler({
          fileName,
          progressPercentage: 100,
          description,
        })
      } catch (e) {
        dispatch({
          type: ActionType.REMOVE_UPLOAD_PROGRESS,
          payload: {
            key: fileName,
          },
        })
        setError(e)
        setUploading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCompleteHandler, onProgressHandler]
  )

  return {
    complete,
    error,
    progressPercentage,
    upload,
    isUploading,
  }
}

import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonItem } from '@ionic/react'

export const EarnXP: FC = () => {
  const { t } = useTranslation('common')

  return (
    <IonItem>
      <div className="flex items-center">
        <picture className="w-[30%]">
          <source srcSet="/vault/earn-xp-background.png 115w, /vault/earn-xp-background@2x.png 230w, /vault/earn-xp-background@2x.png 345w" />
          <img src="/vault/earn-xp-background.png" alt="Earn Xp" />
        </picture>
        <div className="w-[70%] ml-3 text-sm sm:text-base">
          <p className="text-primary text-2xl mb-2 font-bold">
            {t('XPWallet.earnXP')}
          </p>
          <Trans i18nKey="XPWallet.earnXPDescribe">
            {t('XPWallet.earnXPDescribe')}
          </Trans>
        </div>
      </div>
    </IonItem>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButton } from '@ionic/react'

import { Chat } from '@/components/atoms/assets/Chat'
import { Diamond } from '@/components/atoms/assets/Diamond'
import { Button } from '@/components/atoms/Button'

import { useRedirect } from '../hooks/useRedirect'

type ProfileButtonsType = {
  hasSubscription: boolean
  hasChatPaywall: boolean
}

export const ProfileButtons: FC<ProfileButtonsType> = ({
  hasSubscription,
  hasChatPaywall,
}) => {
  const { t } = useTranslation('common')
  const { redirectToLogin } = useRedirect()

  return (
    <div className="flex flex-row justify-start items-center pt-6 gap-2">
      {hasSubscription && (
        <Button
          size="small"
          type="tertiary"
          className="min-w-[5.5rem] gradient rounded-full"
          onClick={redirectToLogin}
        >
          <Diamond className="w-4 h-4 mr-2" />
          {t('discover.subscribe')}
        </Button>
      )}
      <Button
        size="small"
        color={hasSubscription ? 'primary' : ''}
        fill={hasSubscription ? 'outline' : 'clear'}
        className="min-w-[5.5rem]"
        onClick={redirectToLogin}
      >
        {t('followStatus.follow')}
      </Button>
      <IonButton
        className="m-0 normal-case tracking-normal"
        size="small"
        fill="outline"
        color="primary"
        onClick={redirectToLogin}
      >
        {t('tip.actions.sendTip')}
      </IonButton>
      <div className="flex flex-row cursor-pointer" onClick={redirectToLogin}>
        <Chat isActive />
        {hasChatPaywall && (
          <img
            className="relative w-3 h-[18px] -top-[2px] right-[7px]"
            src="/locked-icon-green.svg"
            alt="lock-icon"
          />
        )}
      </div>
    </div>
  )
}

import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { ITransactionItem } from '@/services/wallet/getTransactions'

type TransactionStatusProps = {
  item: ITransactionItem
}

enum Status {
  IN_PROGRESS,
  SUCCESS,
  FAILED,
  UNKNOWN,
}

const SuccessStatuses = ['COMPLETED']
const FailedStatuses = [
  'CANCELLING',
  'CANCELLED',
  'BLOCKED',
  'REJECTED',
  'FAILED',
]
const InProgressStatuses = [
  'SUBMITTED',
  'PENDING_AML_SCREENING',
  'PENDING_AUTHORIZATION',
  'QUEUED',
  'PENDING_SIGNATURE',
  'PENDING_3RD_PARTY_MANUAL_APPROVAL',
  'PENDING_3RD_PARTY',
  'BROADCASTING',
  'CONFIRMING',
]

export const TransactionStatus: FC<TransactionStatusProps> = ({ item }) => {
  const { t } = useTranslation('common')

  const status = useMemo(() => {
    if (!item.fbTransactionStatus) return null

    const withdrawStatus = item.fbTransactionStatus.split('#')[0]

    if (SuccessStatuses.includes(withdrawStatus))
      return [Status.SUCCESS, withdrawStatus.toLowerCase()]

    if (InProgressStatuses.includes(withdrawStatus))
      return [Status.IN_PROGRESS, withdrawStatus.toLowerCase()]

    if (FailedStatuses.includes(withdrawStatus))
      return [Status.FAILED, withdrawStatus.toLowerCase()]

    return [Status.UNKNOWN, item.fbTransactionStatus]
  }, [item.fbTransactionStatus])

  if (!status || item.type !== 'withdraw') return null

  return (
    <span
      className={cx('text-xs font-bold py-[0.125rem] px-3 my-2 rounded-full', {
        'bg-white text-black':
          status[0] === Status.IN_PROGRESS || status[0] === Status.UNKNOWN,
        'bg-primary text-black': status[0] === Status.SUCCESS,
        'bg-danger text-white': status[0] === Status.FAILED,
      })}
    >
      {status[0] === Status.UNKNOWN
        ? status[1]
        : t(`wallet.withdrawStatus.${status[1]}`)}
    </span>
  )
}

import { Component, ReactNode } from 'react'
import { t } from 'i18next'

import { newrelicNoticeError } from '@/lib/tracking'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    newrelicNoticeError({
      error: `Error Boundary: ${error}`,
      customAttributes: {
        location: window.location.pathname,
      },
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="w-full h-full flex justify-center flex-col items-center">
          <div className="mb-4">{t('errors.default')}</div>
        </div>
      )
    }
    return this.props.children
  }
}

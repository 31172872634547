import { ActivityConversation } from './activity/conversation'
import { ActivityReward } from './activity/reward'
import { ActivityUser } from './activity/user'
import { Actor } from './types'

export enum ActivityTypeEnum {
  UserFollowedAggregated = 'user.follow.aggregated',
  UserGreeting = 'user.greeting',
  UserFollow = 'user.follow',

  RewardCustom = 'reward.custom',
  RewardAccountPhoneVerified = 'reward.account.verified.phone',
  RewardAccountEmailVerified = 'reward.account.verified.email',

  RewardGoalVotesPerDayReached = 'reward.goal.votes.perDay.reached',
  RewardGoalVotesPerDayRefilled = 'reward.goal.votes.perDay.refilled',
  RewardGoalAppOpenedDaily = 'reward.goal.app.opened.daily',
  RewardGoalAppOpenedStreak = 'reward.goal.app.opened.streak',
  RewardGoalLikesReceived = 'reward.goal.likes.received',
  RewardGoalLikesGiven = 'reward.goal.likes.given',

  RewardMilestoneBadgeReached = 'reward.milestone.badge.reached',
  RewardMilestoneFollowerCount = 'reward.milestone.follower.count.reached',
  RewardMilestoneReferralRpkReached = 'reward.milestone.referral.rpk.reached',

  RewardReferralSuccessful = 'reward.referral.successful',
  RewardInviteAccepted = 'reward.invite.accepted',

  RewardContestContestantJoined = 'reward.contest.contestant.joined',

  RewardCommunityContribution = 'reward.community.contribution',
  RewardCommunityModeration = 'reward.community.moderation',

  MentionedInConversation = 'mentioned.conversation',
  MentionedInPost = 'mentioned.post',
  MentionedInComment = 'mentioned.comment',

  ConversationReply = 'conversation.reply',

  ReactionLikePost = 'reaction.like.post.aggregated',
  ReactionLikeConversation = 'reaction.like.conversation.aggregated',

  ReactionCommentChallenge = 'reaction.comment.challenge',
  ReactionCommentConversation = 'reaction.comment.conversation',
  ReactionCommentParticipant = 'reaction.comment.participant',
  ReactionCommentPost = 'reaction.comment.post',
  SubscriptionCancelledInsufficientFunds = 'subscription.cancelled.failed.insufficientFunds',
}

export type Activity = ActivityUser | ActivityReward | ActivityConversation

export interface ActivityOf<Type extends ActivityTypeEnum = ActivityTypeEnum> {
  id: string
  time: Date
  userId: string
  type: Type
}

export interface ActivityWithDataOf<
  Type extends ActivityTypeEnum = ActivityTypeEnum,
  ActivityData = Record<number, never>
> extends ActivityOf<Type> {
  data: ActivityData
}

export interface ActivityAggregated<Type extends string, AggregatedData> {
  type: Type
  data: AggregatedData
  time: string
  aggregatedCount: number
  firstActivity: {
    id: string
    time: Date
  }
  lastActivity: {
    id: string
    time: Date
  }
}

export type ActivityFollowsAggregated = ActivityAggregated<
  ActivityTypeEnum.UserFollowedAggregated,
  {
    count: number
    lastActors: Actor[]
  }
>

import { FC, memo } from 'react'

export interface SentIconComponentProps {
  className?: string
}

const SentIconComponent: FC<SentIconComponentProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse className="fill-secondary-200" cx="14" cy="14" rx="14" ry="14" />
      <path
        className="fill-black"
        d="M19 21C19.5523 21 20 20.5523 20 20L20 11C20 10.4477 19.5523 10 19 10C18.4477 10 18 10.4477 18 11L18 19L10 19C9.44772 19 9 19.4477 9 20C9 20.5523 9.44772 21 10 21L19 21ZM7.29289 9.70711L18.2929 20.7071L19.7071 19.2929L8.70711 8.29289L7.29289 9.70711Z"
      />
    </svg>
  )
}

export const SentIcon = memo(SentIconComponent)

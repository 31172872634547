import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getOpenAppStreak, OpenAppStreakData } from '@/services/openAppStreak'

export const OpenAppStreakQueryKey = 'open-app-streak'

export const useOpenAppStreak = (
  options?: Omit<UseQueryOptions<OpenAppStreakData>, 'queryKey'>
) =>
  useQuery({
    queryKey: [OpenAppStreakQueryKey],
    queryFn: getOpenAppStreak,
    ...options,
  })

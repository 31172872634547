import queryString from 'query-string'

export const normalizedHistorySearch = ({
  replyId,
  replyObjectId,
  createdAt,
  replyIndex,
  search: historySearch,
}: {
  replyId: string
  replyObjectId: UUID
  createdAt: string
  replyIndex: number
  search: string
}) => {
  const search = queryString.parse(historySearch)
  const normalizedSearch = replyId
    ? { ...search, replyObjectId, createdAt, replyId }
    : search

  // If the first picture is the original conversation
  const searchParams =
    replyIndex === 0 ? { ...normalizedSearch, replyId: '' } : normalizedSearch

  // Delete the replyId of the first picture in url
  if (searchParams?.replyId?.length <= 0) {
    delete searchParams?.replyId
  }

  return searchParams
}

import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BuyCurrencyConfirmationPopup from '@/components/molecules/BuyCurrencyConfirmationPopup'
import WarningAlert, {
  WarningAlertRef,
} from '@/components/molecules/WarningAlert'

import { DepositUrlError } from '@/lib/hooks/useGetDepositUrl'

interface IBuyCurrencyPopup {
  isBuyCurrencyConfirmationOpen: boolean
  setBuyCurrencyConfirmationOpen: (open: boolean) => void
}
export const BuyCurrencyPopup = ({
  isBuyCurrencyConfirmationOpen,
  setBuyCurrencyConfirmationOpen,
}: IBuyCurrencyPopup) => {
  const { t } = useTranslation()
  const warningRef = useRef<WarningAlertRef>()

  const [buyCurrencyError, setBuyCurrencyError] = useState<DepositUrlError>()

  const handleBuyRpkError = useCallback(
    (error: DepositUrlError) => {
      warningRef?.current?.open()
      setBuyCurrencyError(error)
      setBuyCurrencyConfirmationOpen(false)
    },
    [setBuyCurrencyConfirmationOpen]
  )

  return (
    <>
      <BuyCurrencyConfirmationPopup
        isOpen={isBuyCurrencyConfirmationOpen}
        onDismiss={() => setBuyCurrencyConfirmationOpen(false)}
        onConfirm={() => undefined}
        onError={handleBuyRpkError}
      />

      <WarningAlert
        ref={warningRef}
        title={t('wallet.buyRpk.error.title')}
        description={<span>{t(buyCurrencyError?.description)}</span>}
        acknowledgeText={t('warningAcknowledgeAlert')}
      />
    </>
  )
}

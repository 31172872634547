import { IMedia } from '@/interfaces'

export const getFirstAvailableRatio = (mediaArr: IMedia[]) => {
  if (!mediaArr) return 1

  for (const obj of mediaArr) {
    if (typeof obj.ratio === 'number') return obj.ratio
  }

  return 1
}

import { useCallback } from 'react'

import { UserInfo } from '@/services/user'
import {
  amplitudeIdentifyEvent,
  amplitudeSetUserId,
  IIdentifyEvent,
} from '@/lib/tracking'

import { Platform } from '@/enums'

export const useAmplitudeIdentifyUser = () => {
  const amplitudeIdentifyUser = useCallback((userInfo: UserInfo) => {
    amplitudeSetUserId(userInfo?.id)
    amplitudeIdentifyEvent<IIdentifyEvent>({
      userId: userInfo?.id || '-',
      followerCount: userInfo?.followerCount || 0,
      followingCount: userInfo?.followingCount || 0,
      userName: userInfo?.username || '-',
      refUserName: userInfo?.refUsername || '-',
      isAmbassador: userInfo?.isAmbassador || false,
      badge: userInfo?.badge || '-',
      appPlatform: Platform.Web,
    })
  }, [])

  return {
    amplitudeIdentifyUser,
  }
}

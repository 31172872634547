import { FC, memo, useCallback } from 'react'

import { Stake } from '@/components/atoms/assets/Stake'
import { Button } from '@/components/atoms/Button'

import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

type StakeButtonProps = {
  text: string
  onClick?: () => void
  className?: string
  fill?: 'outline' | 'clear'
  hasIcon?: boolean
}

const StakeButtonComponent: FC<StakeButtonProps> = ({
  text,
  onClick,
  className = '',
  fill,
  hasIcon = true,
}) => {
  const navigate = useNavigate()

  const navigateToStake = useCallback(() => {
    navigate(Routes.STAKE)
  }, [navigate])

  return (
    <Button
      className={className}
      type="primary"
      fill={fill}
      onClick={onClick ?? navigateToStake}
    >
      {hasIcon && <Stake className="-ml-1 h-6" />}
      <span className="text-xxs xs:text-xs">{text}</span>
    </Button>
  )
}

export const StakeButton = memo(StakeButtonComponent)

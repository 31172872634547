import { ReactElement, Suspense, useContext, useEffect } from 'react'
import { IonApp, setupIonicReact } from '@ionic/react'
import { App as CapacitorApp } from '@capacitor/app'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { useIsRestoring } from '@tanstack/react-query'

import { Activity } from '@components/pages/Activity'
import { EmailValidation } from '@components/pages/EmailValidation'
import { Feed } from '@components/pages/Feed'
import { FindUsers } from '@components/pages/FindUsers'
import { HomeOrLogin } from '@components/pages/HomeOrLogin'
import LoadingPage from '@components/pages/LoadingPage'
import {
  Remove2FA,
  SecuritySettings,
  Setup2FA,
} from '@components/pages/SecuritySettings'
import { InstagramPage } from '@components/pages/Socials/instagram'
import { UserProfile } from '@components/pages/UserProfile'
import { UserRelations } from '@components/pages/UserRelations'
import { DiscoverFeed } from '@/components/pages/DiscoverFeed'
import {
  RedirectToLogin,
  RedirectToLogout,
} from '@/components/pages/RedirectToAuth'
import { CommentsPage } from '@/components/pages/UGC/Comments'
import { Channels } from '@/components/pages/UGC/Messaging/Channels'
import { Messages } from '@/components/pages/UGC/Messaging/Messages'
import { PostComposer } from '@/components/pages/UGC/PostComposer/PostComposer'
import { EditProfile } from '@/components/pages/UGC/Profile/EditProfile'
import { WalletPageContainer } from '@/components/pages/WalletPageContainer'

import { appConfig } from '@services/config'
import '@/services/awsAmplify/config/amplify'
import { recordPageView, recordStartSession } from '@/services/awsAmplify'
import { webPushNotificationsInit } from '@/services/webPushNotifications'
import { useServiceWorkerUpdateContent } from '@/lib/hooks/useServiceWorkerUpdateContent'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { queryClient } from '@/lib/queryClient'
import { BrowserRouter, Navigate, ReactDomRoutes, Route } from '@/lib/routing'
import { Context } from '@/lib/store'
import { ActionType } from '@/lib/store/reducer'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { Routes } from '@/router/routes'
import { RoutingInterceptor } from '@/router/RoutingInterceptor'
import { isIosWebView } from '@/utils/utils'

import { Pages } from '@/enums'

import { ErrorBoundary } from '../organisms/ErrorBoundary'
import { OpenAppReward } from '../organisms/OpenAppReward'
import { Confirm } from '../pages/Auth/Confirm'
import { Consent } from '../pages/Auth/Consent'
import { SignUpOptions } from '../pages/Auth/SignInOptions'
import Email from '../pages/Auth/SignInOptions/Email/Email'
import PhoneNumber from '../pages/Auth/SignInOptions/SMS/PhoneNumber'
import { ConfigureChatPaywall } from '../pages/ConfigureChatPaywall'
import { ConfigureSubscription } from '../pages/ConfigureSubscription'
import { Deposit } from '../pages/Deposit'
import {
  LivenessCheck,
  LivenessFailed,
  LivenessInstructions,
} from '../pages/FaceLiveness'
import { MySubscribers } from '../pages/MySubscribers'
import { MySubscriptions } from '../pages/MySubscriptions'
import { NotFound } from '../pages/NotFound'
import { PriceBooster } from '../pages/PriceBooster'
import { PublicPost } from '../pages/PublicPost'
import { RedirectToUserProfile } from '../pages/RedirectToUserProfile'
import { ShareTarget } from '../pages/ShareTarget'
import { TiktokPage } from '../pages/Socials/tiktok/TiktokPage'
import { TwitterPage } from '../pages/Socials/twitter/TwitterPage'
import { Stake } from '../pages/Stake'
import { StakeVault } from '../pages/StakeVault'
import { TokenLaunch } from '../pages/TokenLaunch'
import { TokenSocialsConnect } from '../pages/TokenSocialsConnect'
import { TopSupportersPage } from '../pages/TopSupporters'
import { LikesPage } from '../pages/UGC/Likes/Likes'
import CreateProfile from '../pages/UGC/Profile/CreateProfile'
import { CreateProfileAvatar } from '../pages/UGC/Profile/CreateProfileAvatar'
import { Withdraw } from '../pages/Withdraw'
import { authGuard } from './authGuard'
import ConditionalProfile from './ConditionalProfile'

import '@styles/global.scss'

setupIonicReact({
  swipeBackEnabled: !isIosWebView(),
  innerHTMLTemplatesEnabled: true,
})

function toggleDarkTheme(shouldAdd) {
  document.body?.classList.toggle('dark', shouldAdd)
}

toggleDarkTheme(true)
webPushNotificationsInit()
/* 
 Listen for when the app or activity are resumed.
 ionic: https://capacitorjs.com/docs/apis/app#addlistenerresume-
*/
CapacitorApp.addListener('resume', () => {
  Tracking.triggerEvent(TrackingEvent.AppOpened)
  recordStartSession()
  recordPageView()
  const pathname = window.location.pathname
  pathname === `/${Pages.Feed}` && Tracking.triggerEvent(TrackingEvent.Feed)
})

export const App = () => {
  useServiceWorkerUpdateContent()

  const { dispatch, state } = useContext(Context)

  useEffect(() => {
    Tracking.triggerEvent(TrackingEvent.AppOpened)
  }, [dispatch])

  const gtmArgs = {
    id: appConfig.gtmId,
  }

  const { isFetched, data: userInfo } = useUserInfo()
  const isRestoring = useIsRestoring()

  const isAuthenticated = !!userInfo && !!userInfo?.username

  if (!isFetched || isRestoring) {
    return <LoadingPage />
  }

  const protectedRoute = (callback: ReactElement) => {
    authGuard()

    if (isAuthenticated) {
      const data: { refUsername?: string; redirectUrl?: string } =
        queryClient.getQueryData(['referral'])

      if (data?.refUsername) {
        queryClient.removeQueries({ queryKey: ['referral'] })
      }

      return callback
    }

    if (
      !state.signInRedirectPath &&
      window.location.pathname !== Routes.AUTH_SIGN_UP
    ) {
      dispatch({
        type: ActionType.SIGN_IN_REDIRECT,
        payload: window.location.pathname + window.location.search,
      })
    }

    return <Navigate to={Routes.AUTH_SIGN_UP} />
  }

  const publicRoute = (callback: ReactElement) => {
    if (!userInfo?.username || userInfo.deactivated) {
      return callback
    }
    return <Navigate to={Routes.LOGIN} />
  }

  const router = [
    {
      path: Routes.ROOT,
      element: <HomeOrLogin />,
      errorElement: <NotFound />,
    },

    {
      path: Routes.TOKEN_SOCIALS_CONNECT,
      element: protectedRoute(<TokenSocialsConnect />),
    },
    {
      path: Routes.TOKEN_LAUNCH,
      element: protectedRoute(<TokenLaunch />),
    },

    {
      path: Routes.LOGIN,
      element: <RedirectToLogin />,
    },
    {
      path: Routes.LOGOUT,
      element: <RedirectToLogout />,
    },
    {
      path: Routes.AUTH_EMAIL,
      element: <Email />,
    },
    {
      path: Routes.AUTH_CONFIRM,
      element: publicRoute(<Confirm />),
    },
    {
      path: Routes.AUTH_CONSENT,
      element: publicRoute(<Consent />),
    },
    {
      path: Routes.AUTH_CREATE_PROFILE,
      element: publicRoute(<CreateProfile />),
    },
    {
      path: Routes.AUTH_CREATE_PROFILE_AVATAR,
      element: publicRoute(<CreateProfileAvatar />),
    },
    {
      path: Routes.AUTH_PHONE,
      element: publicRoute(<PhoneNumber />),
    },
    {
      path: Routes.AUTH_SIGN_UP,
      element: publicRoute(<SignUpOptions />),
    },
    {
      path: Routes.FEED,
      element: protectedRoute(<Feed />),
    },
    {
      path: Routes.DISCOVER,
      element: protectedRoute(<DiscoverFeed />),
    },
    {
      path: Routes.ACTIVITY,
      element: protectedRoute(<Activity />),
    },
    {
      path: Routes.LIKES,
      element: protectedRoute(<LikesPage />),
    },
    {
      path: Routes.TOP_SUPPORTERS,
      element: protectedRoute(<TopSupportersPage />),
    },
    {
      path: Routes.MESSAGES,
      element: protectedRoute(<Channels />),
    },
    {
      path: Routes.MESSAGES_USER,
      element: protectedRoute(<Messages />),
    },
    {
      path: Routes.MESSAGES_FIND_USERS,
      element: protectedRoute(
        <FindUsers linkToMessages={true} title="messaging.newChat" />
      ),
    },
    {
      path: Routes.POST,
      element: protectedRoute(<PostComposer />),
    },
    {
      path: Routes.PROFILE,
      element: protectedRoute(<RedirectToUserProfile />),
    },
    {
      path: Routes.PROFILE_EDIT,
      element: protectedRoute(<EditProfile />),
    },
    {
      path: Routes.MY_SUBSCRIBERS,
      element: protectedRoute(<MySubscribers />),
    },
    {
      path: Routes.MY_SUBSCRIPTIONS,
      element: protectedRoute(<MySubscriptions />),
    },
    {
      path: Routes.SECURITY_SETTINGS,
      element: protectedRoute(<SecuritySettings />),
    },
    {
      path: Routes.SETUP_2FA,
      element: protectedRoute(<Setup2FA />),
    },
    {
      path: Routes.REMOVE_2FA,
      element: protectedRoute(<Remove2FA />),
    },
    {
      path: Routes.PROFILE_USER,
      element: protectedRoute(<UserProfile />),
    },
    {
      path: Routes.RELATIONS_USER,
      element: protectedRoute(<UserRelations />),
    },
    {
      path: Routes.VALIDATE_EMAIL,
      element: protectedRoute(<EmailValidation />),
    },
    {
      path: Routes.VAULT,
      element: protectedRoute(<WalletPageContainer />),
    },
    {
      path: Routes.WITHDRAW,
      element: protectedRoute(<Withdraw />),
    },
    {
      path: Routes.DEPOSIT,
      element: protectedRoute(<Deposit />),
    },
    {
      path: Routes.STAKE,
      element: protectedRoute(<Stake />),
    },
    {
      path: Routes.STAKE_VAULT,
      element: protectedRoute(<StakeVault />),
    },
    {
      path: Routes.CONFIGURE_SUBSCRIPTION,
      element: protectedRoute(<ConfigureSubscription />),
    },
    {
      path: Routes.FACE_LIVENESS_INSTRUCTIONS,
      element: protectedRoute(<LivenessInstructions />),
    },
    {
      path: Routes.FACE_LIVENESS_CHECK,
      element: protectedRoute(<LivenessCheck />),
    },
    {
      path: Routes.FACE_LIVENESS_FAILED,
      element: protectedRoute(<LivenessFailed />),
    },
    {
      path: Routes.SOCIALS_CONNECT_INSTAGRAM,
      element: protectedRoute(<InstagramPage />),
    },
    {
      path: Routes.SOCIALS_CONNECT_TWITTER,
      element: protectedRoute(<TwitterPage />),
    },
    {
      path: Routes.SOCIALS_CONNECT_TIKTOK,
      element: protectedRoute(<TiktokPage />),
    },
    {
      path: Routes.SHARE_TARGET,
      element: protectedRoute(<ShareTarget />),
    },
    {
      path: Routes.COMMENTS,
      element: isAuthenticated ? <CommentsPage /> : <PublicPost />,
    },
    {
      path: Routes.USER_SEGMENT,
      element: <ConditionalProfile isAuthenticated={isAuthenticated} />,
    },
    {
      path: Routes.CONFIGURE_CHAT_PAYWALL,
      element: protectedRoute(<ConfigureChatPaywall />),
    },
    {
      path: Routes.PRICE_BOOSTER,
      element: protectedRoute(<PriceBooster />),
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]

  return (
    <GTMProvider state={gtmArgs}>
      <IonApp>
        <Suspense fallback={<LoadingPage />}>
          <RoutingInterceptor>
            <ErrorBoundary>
              <BrowserRouter>
                <OpenAppReward />
                <ReactDomRoutes>
                  {router.map(({ path, element, errorElement }) => {
                    return (
                      <Route
                        key={path}
                        path={path}
                        element={element}
                        errorElement={errorElement}
                      />
                    )
                  })}
                </ReactDomRoutes>
              </BrowserRouter>
            </ErrorBoundary>
          </RoutingInterceptor>
        </Suspense>
      </IonApp>
    </GTMProvider>
  )
}

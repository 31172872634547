import { FC, memo, useState } from 'react'
import { IonContent, IonItem, IonModal } from '@ionic/react'

import { PhoneNumberPrefix } from '@components/atoms/InputField/PhoneNumberPrefix'

interface CountryCodeSelectProps {
  onChange?: (data: string) => void
  defaultValue?: string
}

const CountryCodeSelectComponent: FC<CountryCodeSelectProps> = ({
  onChange,
  defaultValue,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState(defaultValue)
  const handleToggleModal = () => {
    setIsOpen((open) => !open)
  }

  return (
    <>
      <div className="flex" onClick={handleToggleModal}>
        <IonItem>
          <div slot="start" className="flex m-0">
            <span className="font-bold text-base flex items-center justify-center">
              {value}
            </span>
            <span className="border-r-2 flex items-center pl-4"></span>
          </div>
        </IonItem>
      </div>
      <IonModal
        isOpen={isOpen}
        animated={false}
        canDismiss={true}
        onDidDismiss={() => setIsOpen(false)}
      >
        <IonContent>
          <PhoneNumberPrefix
            onSelect={(data) => {
              setValue(data)
              onChange(data)
              setIsOpen(false)
            }}
          />
        </IonContent>
      </IonModal>
    </>
  )
}

export const CountryCodeSelect = memo(CountryCodeSelectComponent)

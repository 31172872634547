import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'

import { TransactionHistory } from './components'

import { ITransactionItem } from '@/services/wallet/getTransactions'
import { IWallet } from '@/services/wallet/getWallet'
import { useLocation } from '@/lib/routing'

interface WalletProps {
  wallet: IWallet
  transactions: ITransactionItem[]
  setIsRefetchActive: Dispatch<SetStateAction<boolean>>
  isGetTransactionsLoading: boolean
  isGetTransactionsFetching: boolean
}

export const Wallet: FC<WalletProps> = ({
  setIsRefetchActive,
  wallet,
  transactions,
  isGetTransactionsLoading,
  isGetTransactionsFetching,
}) => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const orderStatus = params.get('orderStatus')
  const [presentToast] = useIonToast()
  const { t } = useTranslation()

  useEffect(() => {
    if (orderStatus === 'pending') {
      presentToast({
        message: t('wallet.buyRpk.success'),
        position: 'top',
        cssClass: 'liveness-succeeded',
        duration: 3000,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatus])

  return (
    <div className="flex flex-col h-full text-left w-full mx-auto max-w-[40rem]">
      <TransactionHistory
        balance={wallet?.balance}
        stakedBalance={wallet?.stakedBalance}
        accumulativeRevenueShareBalance={
          wallet?.accumulativeRevenueShareBalance
        }
        setIsRefetchActive={setIsRefetchActive}
        transactions={transactions}
        isGetTransactionsLoading={isGetTransactionsLoading}
        isGetTransactionsFetching={isGetTransactionsFetching}
      />
    </div>
  )
}

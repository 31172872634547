import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButton, IonButtons, IonIcon, IonTitle } from '@ionic/react'
import debounce from 'lodash/debounce'

import { BackButton } from '@components/atoms/BackButton'
import { Title } from '@components/atoms/Title'
import { ShareButton } from '@components/molecules/ShareButton'
import { Main } from '@components/templates/main'
import { InfiniteScrollLoader } from '@/components/atoms/InfiniteScrollLoader'
import { Loading } from '@/components/atoms/Loading'
import { NoResults } from '@/components/molecules/NoResults'
import { ExplorerForm } from '@/components/organisms/ExplorerForm'
import { UsersListItem } from '@/components/organisms/UsersListItem'
import { VirtualList } from '@/components/organisms/VirtualList'

import { appConfig } from '@services/config'
import { useFindUsers } from '@/lib/hooks/useFindUsers'
import { useGetAvatar } from '@/lib/hooks/useGetAvatar'
import { useUserInfo } from '@/lib/hooks/useUserInfo'

export const FindUsers = ({
  linkToMessages = false,
  title,
}: {
  linkToMessages?: boolean
  title?: string
}) => {
  const { t } = useTranslation('common')
  const [searchText, setSearchText] = useState('')
  const [isRefetchActive, setRefetchActive] = useState(true)
  const { data: authUser } = useUserInfo()
  const getAvatar = useGetAvatar()

  const {
    data: usersData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    refetchData,
  } = useFindUsers({
    q: searchText,
  })

  const handleSearch = debounce(async ({ q }) => {
    setSearchText(q)
  }, 500)

  const header = useMemo(
    () => (
      <>
        <IonButtons slot="start">
          <BackButton
            className="text-white"
            defaultHref={`/@${authUser?.username}`}
          />
        </IonButtons>
        <IonTitle>
          <Title level={2}>{t(title || 'profile.findUsers.title')}</Title>
        </IonTitle>
        <IonButtons slot="end">
          <IonButton>
            <IonIcon slot="icon-only" />
          </IonButton>
        </IonButtons>
      </>
    ),
    [t, title, authUser]
  )

  const subHeader = useCallback(
    () => (
      <>
        <ExplorerForm
          className="mx-auto max-w-[40rem]"
          onSubmit={handleSearch}
        />
        {!linkToMessages && (
          <ShareButton
            plain
            className="w-full py-0 m-0 text-lg"
            buttonLabel={t('profile.invite.buttonLabel')}
            subject={t('profile.invite.subject')}
            message={t('profile.invite.message')}
            url={`${appConfig.origin}/@${authUser?.username}`}
            imageUrl={getAvatar(authUser?.id)}
            params={{
              refUsername: String(authUser?.username),
            }}
            feature="referral"
          />
        )}
      </>
    ),
    [authUser, getAvatar, handleSearch, linkToMessages, t]
  )

  return (
    <Main
      contentClassName="p-0 h-full !block"
      header={header}
      subHeader={subHeader()}
      refetch={refetchData}
      isRefetchActive={isRefetchActive}
      isPaddingEnabled={false}
    >
      {!isLoading ? (
        usersData?.length === 0 ? (
          <NoResults />
        ) : (
          <>
            <VirtualList
              atTopStateChange={(isAtTop: boolean) => {
                setRefetchActive(isAtTop)
              }}
              className="h-full mx-auto max-w-[40rem] ion-content-scroll-host"
              data={usersData}
              endReached={() => fetchNextPage()}
              components={{
                Footer: () => <InfiniteScrollLoader hasNext={hasNextPage} />,
              }}
              itemContent={(index) => (
                <UsersListItem
                  item={usersData[index]}
                  linkToMessages={linkToMessages}
                />
              )}
            />
          </>
        )
      ) : (
        <Loading />
      )}
    </Main>
  )
}

/* eslint-disable no-constant-condition */
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IonContent,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  useIonToast,
} from '@ionic/react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { WalletHeader } from '@components/organisms/WalletHeader'
import { Wallet } from '@components/pages/Wallet'
import { XPWallet } from '@components/pages/XPWallet'
import { Loading } from '@/components/atoms/Loading'
import { Main } from '@/components/templates/main'

import { useAccountInfo } from '@/services/account'
import { appConfig } from '@/services/config'
import { useRouterQuery } from '@lib/hooks/useRouterQuery'
import { useOpenAppStreak } from '@/lib/hooks/useOpenAppStreak'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useTransactions, useWallet } from '@/lib/hooks/wallet'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { WALLET, WALLET_SEARCH_XP } from '@/utils/route'

import { WalletTabs } from '@enums'
import { IonSegmentCustomEvent, SegmentChangeEventDetail } from '@/interfaces'

export const WalletPageContainer: FC = () => {
  const { t } = useTranslation('common')
  const [presentToast] = useIonToast()

  const query = useRouterQuery()
  const navigate = useNavigate()

  const slider = useRef(null)

  const computedTabIndex = query.get(WalletTabs.xp) ? 1 : 0

  const [tabIndex, setTabIndex] = useState(computedTabIndex)

  const { data: accountInfo, refetch: refetchAccountInfo } =
    useAccountInfo(undefined)

  const { data: authUser } = useUserInfo()
  const { wallet, isFetchingWallet, refetchWallet, hasWalletError } = useWallet(
    { userInfo: authUser }
  )

  const {
    transactions,
    refetchFirstTransactionsPage,
    isGetTransactionsLoading,
    isGetTransactionsFetching,
    hasTransactionsError,
  } = useTransactions({ userInfo: authUser })

  const { refetch: refetchOpenAppStreak } = useOpenAppStreak({ enabled: false })

  const toast = useCallback(
    (tabName: 'transaction' | 'vault') => {
      presentToast({
        message: t(`timeline.errors.${tabName}`),
        position: 'top',
        duration: 3000,
      })
    },
    [presentToast, t]
  )

  useEffect(() => {
    if (computedTabIndex !== 0) return

    if (hasWalletError) {
      toast('vault')
      return
    }

    if (transactions?.length && hasTransactionsError) toast('transaction')
  }, [
    computedTabIndex,
    toast,
    hasTransactionsError,
    transactions?.length,
    hasWalletError,
  ])

  useEffect(() => {
    slideTo(computedTabIndex)
    setTabIndex(computedTabIndex)
  }, [query, accountInfo?.id, wallet?.id, computedTabIndex])

  const slideTo = (slideToIndex: number) => {
    return slider?.current?.swiper?.slideTo(slideToIndex)
  }

  const onSegmentClick = (
    e: IonSegmentCustomEvent<SegmentChangeEventDetail>
  ) => {
    const indexValue = Number(e.detail.value)
    tabIndex === 1
      ? navigate(WALLET, { replace: true })
      : navigate(WALLET_SEARCH_XP, { replace: true })
    slideTo(indexValue)
    setTabIndex(indexValue)
  }

  const handleSlideChange = async (event) => {
    setTabIndex(event.activeIndex)
  }

  const [isRefetchActive, setIsRefetchActive] = useState(true)

  const refetchWalletHandler = useCallback(async () => {
    switch (computedTabIndex) {
      case 0:
        if (authUser?.completedFaceLivenessCheck) {
          await Promise.all([refetchWallet(), refetchFirstTransactionsPage()])
        } else {
          await refetchWallet()
        }
        break
      case 1:
        await Promise.all([refetchAccountInfo(), refetchOpenAppStreak()])
        break
    }
  }, [
    computedTabIndex,
    authUser?.completedFaceLivenessCheck,
    refetchWallet,
    refetchFirstTransactionsPage,
    refetchAccountInfo,
    refetchOpenAppStreak,
  ])

  useEffect(() => {
    if (computedTabIndex === 0 && hasWalletError) return

    if (!isGetTransactionsFetching || !isGetTransactionsLoading)
      refetchWalletHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderSlider = useCallback(() => {
    return (
      <Swiper
        pagination={true}
        onSlideChange={(e) => handleSlideChange(e)}
        ref={slider}
        className="wallet-slides"
        slidesPerView={1}
        initialSlide={tabIndex}
      >
        <SwiperSlide>
          <Wallet
            wallet={wallet}
            transactions={transactions}
            setIsRefetchActive={setIsRefetchActive}
            isGetTransactionsLoading={isGetTransactionsLoading}
            isGetTransactionsFetching={isGetTransactionsFetching}
          />
        </SwiperSlide>

        <SwiperSlide>
          <XPWallet setIsRefetchActive={setIsRefetchActive} />
        </SwiperSlide>
      </Swiper>
    )
  }, [
    tabIndex,
    wallet,
    transactions,
    isGetTransactionsLoading,
    isGetTransactionsFetching,
  ])

  if (!accountInfo || (isFetchingWallet && !wallet)) {
    return <Loading />
  }

  return (
    <>
      {wallet ? (
        <Main
          refetch={refetchWalletHandler}
          isRefetchActive={isRefetchActive}
          header={
            <WalletHeader
              title={t('wallet.yourWallet')}
              backButtonDefaultHref={Routes.PROFILE}
            />
          }
          subHeader={
            <IonSegment
              mode="md"
              value={String(tabIndex)}
              onIonChange={onSegmentClick}
            >
              <IonSegmentButton value="0">
                <IonLabel>{appConfig.currency}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="1">
                <IonLabel>{t('XPWallet.tabName')}</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          }
        >
          <IonContent>{renderSlider()}</IonContent>
        </Main>
      ) : (
        <Main
          headTitle={t('XPWallet.title')}
          refetch={refetchWalletHandler}
          isRefetchActive={isRefetchActive}
          header={
            <WalletHeader
              title={t('XPWallet.title')}
              backButtonDefaultHref={Routes.PROFILE}
            />
          }
        >
          <XPWallet setIsRefetchActive={setIsRefetchActive} />
        </Main>
      )}
    </>
  )
}

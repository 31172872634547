import { FC } from 'react'

import { InstagramLogo } from '@/components/atoms/assets/InstagramLogo'
import { TiktokIcon } from '@/components/atoms/assets/TiktokIcon'
import { TwitterLogo } from '@/components/atoms/assets/TwitterLogo'
import { Socials } from '@/components/pages/Socials/enums'
import { useSocials } from '@/components/pages/Socials/hooks/useSocials'

import { IUserInfoItem } from '@/services/socials'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Routes } from '@/router/routes'

import { SocialsItem } from './SocialsItem'

export const SocialsList: FC = () => {
  const { data: authUser } = useUserInfo()
  const { socialsList } = useSocials({ userId: authUser?.id })
  const { items } = socialsList || {}

  const socialsListMap = [
    {
      name: Socials.Instagram,
      Icon: <InstagramLogo />,
      connectionInfo: items?.filter(
        (item: IUserInfoItem) => item.provider === Socials.Instagram
      )[0],
      redirectLink: Routes.SOCIALS_CONNECT_INSTAGRAM,
    },
    {
      name: Socials.Twitter,
      Icon: <TwitterLogo />,
      connectionInfo: items?.filter(
        (item: IUserInfoItem) => item.provider === Socials.Twitter
      )[0],
      redirectLink: Routes.SOCIALS_CONNECT_TWITTER,
    },
    {
      name: Socials.Tiktok,
      Icon: <TiktokIcon className="!w-12 !h-12" isActive />,
      connectionInfo: items?.filter(
        (item: IUserInfoItem) => item.provider === Socials.Tiktok
      )[0],
      redirectLink: Routes.SOCIALS_CONNECT_TIKTOK,
    },
  ]

  return (
    <div className="my-4">
      {socialsListMap.map(({ name, Icon, connectionInfo, redirectLink }) => (
        <SocialsItem
          key={name}
          providerUserName={connectionInfo?.providerUserName}
          followerCount={connectionInfo?.followerCount}
          Icon={Icon}
          redirectLink={redirectLink}
        />
      ))}
    </div>
  )
}

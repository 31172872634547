import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { get } from '@/services/awsAmplify'

import { Badge, QueryKeys } from '@/enums'

type GetTopCreatorsData = {
  id: UUID
  username: string
  isAmbassador: boolean
  xp: number
  badge?: Badge
}[]

const getTopCreators = async (): Promise<GetTopCreatorsData> => {
  const response = await get({
    path: '/top-contributors',
    endpoint: 'cached',
  })

  return response?.users.filter((item) => item !== null)
}

export const useTopCreators = (
  options?: Omit<UseQueryOptions<GetTopCreatorsData>, 'queryKey'>
) => {
  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: [QueryKeys.TopCreators],
    queryFn: getTopCreators,
    ...options,
  })

  return {
    refetch,
    isTopCreatorsFetching: isFetching,
    isTopCreatorsLoading: isLoading,
    topCreators: data,
  }
}

import { FC, memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const TimelineErrorComponent: FC<{ translation: string }> = ({
  translation,
}) => {
  const { t } = useTranslation('common')
  return (
    <div className="h-full w-full m-auto text-center">
      <Trans i18nKey={translation}>{t(translation)}</Trans>
    </div>
  )
}

export const TimelineError = memo(TimelineErrorComponent)

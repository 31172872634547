import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import { ActionButton } from '@/components/atoms/ActionButton'
import { Stake } from '@/components/atoms/assets/Stake'

import { IStakeVaultTransactionItem } from '@/services/stake/getStakeTransactions'
import { useHandleErrors } from '@/lib/hooks/stake'
import { useStake } from '@/lib/hooks/stake/useStake'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { formatDate } from '@/utils/date'

import { DateFormat, QueryKeys, StakeStatus } from '@/enums'

import { HistoryListItemDetail } from './HistoryListItemDetail'
import { HistoryListStakeItemAmount } from './HistoryListStakeItemAmount'

interface HistoryListStakeItemProps {
  isStakeVault?: boolean
  item: IStakeVaultTransactionItem
}

export const HistoryListStakeVaultItem: FC<HistoryListStakeItemProps> = ({
  isStakeVault = false,
  item,
}) => {
  const { t } = useTranslation('common')
  const { data: userInfo } = useUserInfo()
  const queryClient = useQueryClient()

  const isUnlocked =
    item?.stakeStatus === StakeStatus.Unlocked ||
    item?.stakeStatus === StakeStatus.Rewarded

  const { redeem, isRedeemPending, redeemError, reStakeError } = useStake({
    userInfo,
  })

  const handleRedeem = () => {
    redeem({ stakeId: item?.id })
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.Wallet, userInfo?.id],
    })
  }

  useHandleErrors({ redeemError, reStakeError })

  return (
    <div className="bg-cool-gray-800 rounded-[0.3125rem] my-1.5 p-4">
      <div className="flex items-center">
        <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-12">
          <Stake type={isStakeVault ? 'primary' : 'secondary'} />
        </div>
        <div className="flex-auto ml-3">
          <HistoryListStakeItemAmount isStakeVault={isStakeVault} item={item} />
          <HistoryListItemDetail
            title={t('wallet.stakeVault.apy')}
            content={`${item?.apyInPercentage}%`}
          />
          <HistoryListItemDetail
            title={
              isUnlocked
                ? t('wallet.stakeVault.unlockedOn')
                : t('wallet.stakeVault.lockInPeriodEndIn')
            }
            content={formatDate(
              +new Date(item?.releaseDate),
              DateFormat.LocalizedDate
            )}
          />
          {isUnlocked && (
            <div className="flex mt-2">
              <ActionButton
                fill="outline"
                isPending={isRedeemPending}
                handleClick={handleRedeem}
                buttonText={t('wallet.stakeVault.redeem')}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

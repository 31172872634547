import { FC } from 'react'

import { Avatar } from '@/components/atoms/Avatar'
import { OriginChip } from '@/components/atoms/OriginChip'
import { UserName } from '@/components/atoms/UserName'

import { safeCastUnknownToType } from '@/utils/utils'

import { Badge } from '@/enums'
import { IMedia } from '@/interfaces'

import { useRedirect } from '../../PublicUserProfile/hooks/useRedirect'

type ProfileHeaderType = {
  userInfo: {
    id: UUID
    name: string
    isAmbassador: boolean
    badge?: string
    postImages: IMedia[]
  }
}
export const PostHeader: FC<ProfileHeaderType> = ({ userInfo }) => {
  const { redirectToLogin } = useRedirect()

  return (
    <header className="flex p-3">
      <div onClick={redirectToLogin}>
        <Avatar
          userId={userInfo?.id}
          badge={userInfo?.badge as Badge}
          isAmbassador={userInfo?.isAmbassador}
        />
      </div>
      <div
        onClick={redirectToLogin}
        className="flex items-center font-bold ml-2.5"
      >
        <div className="cursor-pointer">
          <UserName name={safeCastUnknownToType<string>(userInfo?.name)} />
          <OriginChip origin={origin} />
        </div>
      </div>
    </header>
  )
}

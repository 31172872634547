import { IMention } from '@/interfaces'

import { usernameAndIdMentionsPattern } from '../validation'

export const getMentionsFromMarkdown = (markdown: string) => {
  const mentions: IMention[] = []
  let matchArray

  while ((matchArray = usernameAndIdMentionsPattern.exec(markdown))) {
    const username = matchArray[1]
    const id = matchArray[2]

    mentions.push({ username, id })
  }

  return mentions
}

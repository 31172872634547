import { memo } from 'react'

const StreakCompletedModalBackgroundComponent = () => {
  const bgStyles = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  } as React.CSSProperties

  return (
    <div className="absolute w-full h-full">
      <div
        style={{
          ...bgStyles,
          backgroundImage: 'url(/streak_bonus/streak-background.svg)',
        }}
      />
      <div
        style={{
          ...bgStyles,
          backgroundImage: 'url(/streak_bonus/streak-confetti.svg)',
        }}
      />
    </div>
  )
}

export const StreakCompletedModalBackground = memo(
  StreakCompletedModalBackgroundComponent
)

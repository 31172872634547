import { FC, memo } from 'react'

type TwitterIconProps = {
  className?: string
  isActive?: boolean
  isBlack?: boolean
}

const TwitterIconComponent: FC<TwitterIconProps> = ({
  className = '',
  isActive = false,
  isBlack = false,
}) => {
  const fileName = isBlack
    ? 'twitter-black'
    : isActive
    ? 'twitter-color'
    : 'twitter-white'

  return (
    <picture>
      <source
        srcSet={`/social_icons/${fileName}.png 28w, /social_icons/${fileName}@2x.png 56w, /social_icons/${fileName}@3x.png 84w`}
      />
      <img
        className={`w-6 h-6 ${className}`}
        src={`/social_icons/${fileName}.png`}
        alt="Twitter-active"
      />
    </picture>
  )
}

export const TwitterIcon = memo(TwitterIconComponent)

import { useEffect } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IonSpinner } from '@ionic/react'
import debounce from 'lodash/debounce'

import { Avatar } from '@components/atoms/Avatar'
import { Button } from '@/components/atoms/Button'
import { MentionsInput } from '@/components/molecules/MentionsInput'
import { MentionsSuggestionsBox } from '@/components/molecules/MentionsSuggestionsBox'

import { useMentions } from '@/lib/hooks/useMentions'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { getUserIdsFromMentions } from '@/utils/utils'

const MAX_CAPTION_LENGTH = 120

interface CommentsInputProps {
  handleComment: (e: string) => void
  className?: string
  isLoading?: boolean
}

interface CommentValue {
  comment: string
}

export const CommentsInput = ({
  handleComment,
  className = '',
  isLoading,
}: CommentsInputProps) => {
  const {
    mentionsContainerRef,
    fetchMentions,
    mentions,
    isLoadingMentions,
    mentionsCount,
    setMentionsCount,
    onBlur,
  } = useMentions()
  const { t } = useTranslation('common')
  const shouldHaveBorder = mentions.length || isLoadingMentions
  const { data: userInfo } = useUserInfo()

  const { control, reset, handleSubmit, watch } = useForm<CommentValue>({
    defaultValues: {
      comment: '',
    },
  })

  const { comment } = useWatch({
    control,
  })

  const onSubmit = async ({ comment }) => {
    if (!comment.trim()) return

    await handleComment(comment)
    reset()
  }

  useEffect(() => {
    const subscription = watch(
      debounce(
        (value) =>
          setMentionsCount(getUserIdsFromMentions(value.comment).length),
        500
      )
    )
    return () => subscription.unsubscribe()
  }, [setMentionsCount, watch])

  return (
    <>
      <MentionsSuggestionsBox
        className={shouldHaveBorder && `border-t-2 border-y-cool-gray-700`}
        suggestionsPortalHostRef={mentionsContainerRef}
        isLoadingMentions={isLoadingMentions}
      />
      <div
        className={`bg-cool-gray-900 p-4 align-top w-full relative max-w-full overflow-hidden border-t-[0.125rem] border-cool-gray-700 ${className}`}
      >
        <div className="float-left">
          <Avatar
            className="mr-3 my-[0.125rem]"
            userId={userInfo?.id}
            clickable
            isModerator={userInfo?.isModerator}
            isAmbassador={userInfo?.isAmbassador}
            badge={userInfo?.badge}
            username={userInfo?.username}
          />
        </div>
        <form
          className="bg-cool-gray-800 rounded p-2 relative overflow-hidden"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full pr-20">
            <Controller
              control={control}
              name="comment"
              rules={{ required: true, maxLength: 3e4 }}
              render={({ field }) => (
                <MentionsInput
                  field={field}
                  maxLength={MAX_CAPTION_LENGTH}
                  mentionsContainerRef={mentionsContainerRef}
                  fetchMentions={fetchMentions}
                  mentionsCount={mentionsCount}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
          <Button
            onClick={handleSubmit(onSubmit)}
            className="text-white p-0 m-0 plain-revert absolute bottom-2 right-2"
            size="large"
            isDisabled={!comment || isLoading}
          >
            {!isLoading ? (
              t('common.post')
            ) : (
              <IonSpinner
                name="dots"
                color="primary"
                slot="icon-only"
                className={'text-quaternary'}
              />
            )}
          </Button>
        </form>
      </div>
    </>
  )
}

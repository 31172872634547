import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Avatar } from '@/components/atoms/Avatar'
import { CategoryName } from '@/components/atoms/CategoryName'
import { OriginChip } from '@/components/atoms/OriginChip'
import { UserName } from '@/components/atoms/UserName'
import { UserRelationIndicator } from '@/components/atoms/UserRelationIndicator'
import { VotesLabel } from '@/components/atoms/VotesLabel'

import { Link } from '@/lib/routing'

import { ActivityFeedVerb } from '@enums'
import { IContest, IConversation, IUserWithoutMetadata } from '@/interfaces'
interface IFeedActivityHeaderProps {
  activityId?: string
  actor: IUserWithoutMetadata
  contestName?: string
  verb?: string | ActivityFeedVerb
  name?: string
  contest?: IContest
  votesCount?: number | undefined
  children: unknown
  object: { data: IConversation }
  origin: string
  isReactingOnTouch?: boolean
  isTouchStarted?: boolean
  currentSlide?: number
}

export const FeedActivityHeader: FC<IFeedActivityHeaderProps> = ({
  contestName,
  verb,
  actor,
  name,
  contest,
  votesCount,
  children,
  activityId,
  object,
  origin,
  isReactingOnTouch = false,
  isTouchStarted,
  currentSlide = 0,
}) => {
  const { t } = useTranslation('common')

  const contestParticipateHeader = (
    <header className="inset-x-0 z-10 pt-[0.375rem] pl-3 pb-3 pr-0">
      <div className="flex items-center justify-between">
        <Link to={`/@${actor.data?.name}`} className="flex-none mr-2">
          <Avatar
            username={actor.data?.name}
            userId={actor.id}
            isModerator={actor?.data?.isModerator}
            isAmbassador={actor?.data?.isAmbassador}
            badge={actor?.data?.badge}
          />
        </Link>
        <div className="grow">
          <div className="flex flex-wrap">
            <div className="text-sm flex items-center dark:text-white flex-wrap">
              <strong className="inline-block mr-1">
                <Link to={`/@${name}`}>
                  <UserName name={name} />
                </Link>
              </strong>
              <span className="inline-block mr-1">
                {t('contestantJoinedFeedActivityTitle')}
              </span>
              <Trans
                i18nkey="feedHeader.contestCreate"
                values={{ contest: <strong>{contestName}</strong> }}
              >
                {t('feedHeader.contestCreate', { contest: contestName })}
              </Trans>
            </div>
          </div>
          {!contest?.hideVotesCount && (
            <div className="flex pt-1">
              <VotesLabel count={votesCount} />
              <OriginChip
                className="mx-1"
                hasVoteLabel={true}
                origin={origin}
              />
            </div>
          )}
        </div>
        <div className="flex justify-end items-center">{children}</div>
      </div>
    </header>
  )

  const conversationHeader = (
    <header className="top-0 inset-x-0 z-10 pt-[0.375rem] pl-3 pb-3 pr-0">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Link
            to={`/@${actor.data?.name}`}
            className="flex-none mr-2 self-start relative"
          >
            <Avatar
              username={actor.data?.name}
              userId={actor.id}
              isModerator={actor?.data?.isModerator}
              isAmbassador={actor?.data?.isAmbassador}
              badge={actor?.data?.badge}
            />
            {isReactingOnTouch && (
              <div className="ml-11 absolute mt-[2.125rem]">
                <UserRelationIndicator
                  hasOpacity={true}
                  color="white"
                  className="!-mt-6 mb-[0.5rem] absolute mr-[0.25rem]"
                  style={{
                    height:
                      currentSlide === 0 ? 0 : isTouchStarted ? 0 : '2rem',
                    top: 0,
                    transition: isTouchStarted
                      ? 'height 100ms'
                      : 'height 1800ms',
                  }}
                />
              </div>
            )}
          </Link>
          <div className="relative">
            <div className="m-0 cursor-pointer leading-none capitalize text-sm">
              <Link
                className="cursor-pointer text-white"
                to={`/comments/${activityId}`}
              >
                <strong className="wordWrap-break max-w-[68vw] block">
                  {object?.data?.title}
                </strong>
              </Link>
            </div>
            <span className="flex text-xs whitespace-nowrap">
              <span className="inline-block mr-1 text-cool-gray-200">
                {t('contest.details.createdBy')}
              </span>
              <strong>
                <Link to={`/@${actor.data.name}`}>
                  <UserName
                    name={actor.data?.name}
                    className="text-cool-gray-200"
                  />
                </Link>
              </strong>
            </span>
            <CategoryName name={'conversations'} />
            <OriginChip
              className="mx-1"
              hasVoteLabel={votesCount > 0}
              origin={origin}
            />
          </div>
        </div>
        <div className="flex justify-start items-start">{children}</div>
      </div>
    </header>
  )

  const defaultHeader = (
    <header className="top-0 inset-x-0 z-10 pt-[0.375rem] pl-3 pb-3 pr-0">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Link
            to={`/@${actor.data?.name}`}
            className="flex-none mr-2 self-start"
          >
            <Avatar
              username={actor.data?.name}
              userId={actor.id}
              isModerator={actor?.data?.isModerator}
              isAmbassador={actor?.data?.isAmbassador}
              badge={actor?.data?.badge}
            />
          </Link>
          <div className="relative">
            <div className="m-0 leading-none capitalize">
              <strong>{contestName}</strong>
            </div>
            <span className="flex text-xs whitespace-nowrap">
              <span className="inline-block mr-1 text-cool-gray-200">
                {t('contest.details.createdBy')}
              </span>
              <strong>
                <Link to={`/@${name}`}>
                  <UserName name={name} className="text-cool-gray-200" />
                </Link>
              </strong>
            </span>
            <CategoryName name={contest?.category} />
            <OriginChip
              className="mx-1"
              hasVoteLabel={votesCount > 0}
              origin={origin}
            />
          </div>
        </div>
        <div className="flex justify-start items-start">{children}</div>
      </div>
    </header>
  )

  switch (verb) {
    case ActivityFeedVerb.ContestParticipate:
      return contestParticipateHeader
    case ActivityFeedVerb.StartedConversation:
    case ActivityFeedVerb.RepliedToConversation:
      return conversationHeader
    default:
      return defaultHeader
  }
}

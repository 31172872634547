import { memo } from 'react'

const ChatComponent = () => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill-primary-500"
        d="M5.02111 16.0621H5.77111V15.4463L5.16713 15.3265L5.02111 16.0621ZM5.02111 20H4.27111V21.2714L5.38388 20.6564L5.02111 20ZM11.9735 16.1579V15.4079H11.78L11.6107 15.5015L11.9735 16.1579ZM1.75 6C1.75 3.65279 3.65279 1.75 6 1.75V0.25C2.82436 0.25 0.25 2.82436 0.25 6H1.75ZM1.75 11.1579V6H0.25V11.1579H1.75ZM5.16713 15.3265C3.21864 14.9397 1.75 13.2195 1.75 11.1579H0.25C0.25 13.9491 2.23808 16.2744 4.8751 16.7978L5.16713 15.3265ZM5.77111 20V16.0621H4.27111V20H5.77111ZM11.6107 15.5015L4.65835 19.3436L5.38388 20.6564L12.3363 16.8143L11.6107 15.5015ZM16 15.4079H11.9735V16.9079H16V15.4079ZM20.25 11.1579C20.25 13.5051 18.3472 15.4079 16 15.4079V16.9079C19.1756 16.9079 21.75 14.3335 21.75 11.1579H20.25ZM20.25 6V11.1579H21.75V6H20.25ZM16 1.75C18.3472 1.75 20.25 3.65279 20.25 6H21.75C21.75 2.82436 19.1756 0.25 16 0.25V1.75ZM6 1.75H16V0.25H6V1.75Z"
      />
      <g clipPath="url(#clip0_14_5981)">
        <path
          className="fill-primary-500"
          d="M12.4824 11.7664C12.997 11.7481 13.3964 12.1299 13.7074 12.5668C13.7189 12.5815 13.7343 12.5961 13.7458 12.6108C14.1452 12.9927 14.414 13.0037 15.8004 13.0037C15.9463 13.0037 16.0001 12.9339 15.9962 12.7834C15.9924 12.453 15.9885 12.24 15.9962 11.9096C16.0001 11.7187 15.9386 11.6453 15.7581 11.6489C15.2819 11.6599 13.5653 11.6599 13.5653 11.6599C13.0661 11.6746 12.8933 11.2781 12.7397 11.1276L12.5784 10.9587C12.1944 10.5365 11.7259 10.4777 11.1805 10.4777C10.6007 10.4777 10.401 10.4777 9.8211 10.4777C8.94168 10.4777 7.9509 10.4741 7.07148 10.4777V9.00184C8.34645 9.00551 9.32187 9.00184 10.5968 9.00184C10.808 9.00184 10.9847 9.06425 11.1345 9.21111C11.2727 9.34695 11.4186 9.68105 11.5569 9.81689C11.9179 10.1657 12.3595 10.3639 12.8741 10.3676C13.7189 10.3786 14.944 10.3676 15.7888 10.3749C15.9463 10.3749 16.0001 10.3125 15.9962 10.1693C15.9924 9.89032 15.9962 9.40936 15.9962 9.13034C15.9962 8.88435 15.9924 8.88068 15.7351 8.88068C14.5408 8.88068 13.3503 8.88068 12.156 8.88068C10.9617 8.88068 10.9732 8.64938 10.7274 8.36668C10.3088 7.88206 10.2589 7.70583 9.78654 7.70583C9.12602 7.70583 7.73585 7.70583 7.07532 7.71317V6.21157C7.63216 6.21891 7.99698 6.23727 8.55382 6.23727C8.96089 6.23727 9.2105 6.72923 9.35643 6.87976C9.5254 7.05966 9.68285 7.25057 9.86334 7.41946C10.0822 7.62873 10.5699 7.59202 11.0231 7.59202C12.6168 7.59936 14.2105 7.59202 15.8042 7.59936C15.9501 7.59936 16.0077 7.54062 16.0039 7.38642C16.0001 7.07068 16.0039 6.75493 16.0039 6.43552C16.0039 6.13447 15.9962 6.12712 15.7236 6.12712C13.8418 6.12712 11.964 6.13079 10.0822 6.12712C9.04921 6.12712 9.34107 6.02432 8.55766 5.27536C8.23508 4.97797 7.63984 5 7.07148 5C6.93708 5 6.81419 5 6.68746 5H5.99622V11.7848"
        />
      </g>
      <defs>
        <clipPath id="clip0_14_5981">
          <rect width="10" height="8" fill="white" transform="translate(6 5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Chat = memo(ChatComponent)

import {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { isSafari } from 'react-device-detect'

import { localeMapper } from '@/lib/translation/i18n'
import { getLanguage } from '@/utils/getLanguage'

interface CurrencyInputProps {
  inputRef?: MutableRefObject<HTMLInputElement>
  placeholder?: string
  className?: string
  autoFocus?: boolean
  onChange?: (e) => void
  onBlur?: (e) => void
  value?: string
}

export const CurrencyInput: FC<CurrencyInputProps> = ({
  inputRef,
  placeholder = '0',
  className,
  autoFocus,
  onChange,
  onBlur,
  value,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [lastValue, setLastValue] = useState('')
  const invalidChars = ['-', '+', 'e', 'E', '^']

  const normalizeValue = (val) => val.replace(/,/g, '.')
  const language = getLanguage()

  const handleOnFocus = () => {
    if (isSafari) return
    setIsFocused(true)
    setInputValue(normalizeValue(lastValue))
  }

  const formattedValue = useCallback(
    (val: number) =>
      new Intl.NumberFormat(localeMapper[language], {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(val),
    [language]
  )

  const localizedPlaceholder = useMemo(() => {
    return formattedValue(Number(placeholder))
  }, [formattedValue, placeholder])

  const handleOnBlur = (e) => {
    if (window.scrollY > 0) window.scrollTo({ top: 0, behavior: 'smooth' })

    if (onBlur) onBlur(e)

    if (isSafari) return
    setIsFocused(false)
    const value = formattedValue(normalizeValue(e.target.value))

    setLastValue(inputValue)
    setInputValue(value)
  }

  const handleOnKeyDown = (e) => {
    if (isSafari) {
      inputValue.includes('.') && e.key === '.' && e.preventDefault()
      inputValue.includes('.') && e.key === ',' && e.preventDefault()
    }

    if (invalidChars.includes(e.key)) {
      e.preventDefault()
    }
  }

  const handleOnPaste = async (e) => {
    e.preventDefault()
    const clipboardText = await navigator.clipboard.readText()

    for (const char of clipboardText.split('')) {
      if (invalidChars.includes(char)) {
        return
      }
    }

    setInputValue(normalizeValue(clipboardText))
  }

  useEffect(() => {
    setInputValue(value)
    if (!lastValue) {
      setLastValue(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = normalizeValue(e.target.value)
    setInputValue(newValue)

    if (onChange) {
      onChange({ ...e, target: { ...e.target, value: newValue } })
    }
  }

  return (
    <input
      onCopy={(e) => {
        navigator.clipboard.writeText(lastValue)
        e.preventDefault()
      }}
      onPaste={handleOnPaste}
      className={className}
      ref={inputRef}
      onFocus={handleOnFocus}
      onKeyDown={handleOnKeyDown}
      onBlur={handleOnBlur}
      type={isSafari ? 'text' : isFocused ? 'number' : 'text'}
      inputMode="decimal"
      placeholder={localizedPlaceholder}
      value={inputValue}
      autoFocus={autoFocus}
      onChange={handleChange}
      step="0.01"
    />
  )
}

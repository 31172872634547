import { FC, memo } from 'react'

type ChevronRightProps = {
  className?: string
}

const ChevronRightComponent: FC<ChevronRightProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke-primary-500"
        d="M4.5 2.25L8.25 6L4.5 9.75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ChevronRight = memo(ChevronRightComponent)

import { UgcComposer } from '.'
import { IUgcComposerProps } from './definitions'

export const ugcComposer = async (options: IUgcComposerProps = {}) => {
  const result = await UgcComposer.openWizard(options)

  if ('url' in result.media) {
    return result
  }

  throw new Error('video editor error')
}

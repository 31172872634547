import { queryClient } from '@/lib/queryClient'
import { Tracking, TrackingEvent } from '@/lib/tracking'

import { SignInMethods } from '@/enums'

export const trackingSigninMethodSelected = (authMethod: string) => {
  Tracking.triggerEvent(TrackingEvent.SigninMethodSelected, {
    authMethod,
  })
}

export const trackingSigninCompleted = (authMethod: string) => {
  Tracking.triggerEvent(TrackingEvent.SigninCompleted, {
    authMethod,
  })
}

export const trackingSignupSubmitted = async (authMethod: SignInMethods) => {
  const data: { refUsername?: string; redirectUrl?: string } =
    queryClient.getQueryData(['referral'])

  Tracking.triggerEvent(TrackingEvent.SignUpStarted, {
    refUsername: data?.refUsername,
    authMethod,
  })
}

import { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonItem } from '@ionic/react'

import { Avatar } from '@components/atoms/Avatar'
import { ActivityItemPreviewImage } from '@/components/atoms/ActivityItemPreviewImage'
import { AvatarGroup } from '@/components/atoms/AvatarGroup'
import { Button } from '@/components/atoms/Button'
import { UserName } from '@/components/atoms/UserName'

import { useNavigate } from '@/lib/routing'
import { formatDistance } from '@utils/date'
import { getThumbnailImage } from '@/utils/url'

import { ActivityReactionLike } from '@/types/activity/reaction'

interface IActivityLikeItemProps {
  item: ActivityReactionLike
}

export const ActivityLikeItem: FC<IActivityLikeItemProps> = ({ item }) => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')

  const { data, type } = item

  const i18nKey = 'activity.item.reaction.' + type

  const handleOnClick = () => navigate(`/comments/${data.activityId}`)

  const previewImageUrl = useMemo(() => {
    return getThumbnailImage(data.previewImageUrl)
  }, [data])

  const lastActors = useMemo(() => {
    return [...item.data.lastActors].reverse()
  }, [item.data.lastActors])

  return (
    <IonItem className="py-4 border-solid border-b-[0.0625rem] border-zinc-800">
      <span className="pr-2 mr-1">
        <AvatarGroup>
          {lastActors.map((actor) => (
            <div key={actor.id} className="w-10 pt-3">
              <Avatar
                username={actor.username}
                userId={actor.id}
                isModerator={actor.isModerator}
                isAmbassador={actor.isAmbassador}
                badge={actor.badge}
                clickable
              />
            </div>
          ))}
        </AvatarGroup>
      </span>
      <div
        className="w-full flex justify-between items-center cursor-pointer"
        onClick={handleOnClick}
      >
        <div className="flex flex-col justify-between">
          <div className="text-sm">
            <Trans
              i18nKey={i18nKey}
              components={{
                userLink: (
                  <UserName
                    className="inline font-bold"
                    userId={lastActors[lastActors.length - 1].id}
                    name={lastActors[lastActors.length - 1].username}
                  />
                ),
              }}
              values={{ count: Number(data.count - 1) }}
            >
              {t(i18nKey, { count: Number(data.count - 1) })}
            </Trans>
          </div>
          <div className="text-xs text-zinc-600">
            {formatDistance(item.time)}
          </div>
        </div>
        {data.previewImageUrl ? (
          <ActivityItemPreviewImage previewImageUrl={previewImageUrl} />
        ) : (
          <Button size="small" className="min-w-[5.5rem]">
            {t('activity.item.view')}
          </Button>
        )}
      </div>
    </IonItem>
  )
}

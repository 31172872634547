import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { IUserInfoItem, IUserInfoItemList, socials } from '@/services/socials'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { queryClient } from '@/lib/queryClient'

import { QueryKeys } from '@/enums'

export const useSocials = ({
  userId,
  enabled = true,
}: {
  userId?: string
  enabled?: boolean
}) => {
  const { data: authUser } = useUserInfo()
  const socialUserId: string = userId ? userId : authUser?.id

  const {
    data: socialsList,
    refetch: refetchSocialsList,
    isLoading: isSocialsListLoading,
    error: socialsListError,
  } = useQuery<IUserInfoItemList, AxiosError>({
    queryKey: [QueryKeys.SocialsList, socialUserId],
    queryFn: () => socials({ userId: socialUserId }),
    enabled,
  })

  const presetSocialProvider = (data: IUserInfoItem) => {
    const itemsList = queryClient.getQueryData<IUserInfoItemList>([
      QueryKeys.SocialsList,
      socialUserId,
    ])

    if (itemsList) {
      queryClient.setQueryData<IUserInfoItemList>(
        [QueryKeys.SocialsList, socialUserId],
        {
          items: [...itemsList.items, data],
        }
      )
    }
  }

  return {
    userId,
    socialsList,
    refetchSocialsList,
    socialsListError,
    isSocialsListLoading,
    presetSocialProvider,
  }
}

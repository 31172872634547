import { post } from '@/services/awsAmplify'

export type OpenAppRewardData = {
  day?: number
  of?: number
}

export const createOpenAppReward = async (
  accountId: string
): Promise<OpenAppRewardData> => {
  return post({
    path: `/reward-open-app/${accountId}`,
  }) as unknown as OpenAppRewardData
}

import { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonItem } from '@ionic/react'

import { Avatar } from '@components/atoms/Avatar'
import { ActivityItemPreviewImage } from '@/components/atoms/ActivityItemPreviewImage'
import { Button } from '@/components/atoms/Button'
import { UserName } from '@/components/atoms/UserName'

import { useNavigate } from '@/lib/routing'
import { formatDistance } from '@utils/date'
import { getThumbnailImage } from '@/utils/url'

import { ActivityReactionComment } from '@/types/activity/reaction'

interface IActivityCommentItemProps {
  item: ActivityReactionComment
}

export const ActivityCommentItem: FC<IActivityCommentItemProps> = ({
  item,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { data } = item

  const previewImageUrl = useMemo(() => {
    return getThumbnailImage(data.previewImageUrl)
  }, [data.previewImageUrl])

  const handleOnClick = () => navigate(`/comments/${data.activityId}`)

  return (
    <IonItem className="py-4 border-solid border-b-[0.0625rem] border-zinc-800">
      <span className="pr-4">
        <Avatar
          userId={data.actor.id}
          isModerator={data.actor?.isModerator}
          isAmbassador={data.actor?.isAmbassador}
          badge={data.actor?.badge}
          clickable
          username={data.actor?.username}
        />
      </span>
      <div
        className="w-full flex justify-between items-center cursor-pointer"
        onClick={handleOnClick}
      >
        <div className="flex flex-col justify-between">
          <div className="text-sm">
            <Trans
              i18nKey={'activity.item.comment.message'}
              components={{
                userLink: (
                  <UserName
                    className="inline font-bold"
                    userId={data.actor.id}
                    name={data.actor.username}
                  />
                ),
              }}
              values={{
                comment: data.comment,
              }}
            >
              {t('activity.item.comment.message')}
            </Trans>
          </div>
          <div className="text-xs text-zinc-600">
            {formatDistance(item.time)}
          </div>
        </div>
        {data.previewImageUrl ? (
          <ActivityItemPreviewImage previewImageUrl={previewImageUrl} />
        ) : (
          <Button size="small" className="min-w-[5.5rem]">
            {t('activity.item.view')}
          </Button>
        )}
      </div>
    </IonItem>
  )
}

import { getCurrentUser } from 'aws-amplify/auth'

import { put } from '@/services/awsAmplify'

export const validateEmail = async ({ token }) => {
  return await put({
    endpoint: 'notAuthenticated',
    path: `/profile/email-validation/${token}`,
  })
}

export const emailValidationRetry = async () => {
  const { username } = await getCurrentUser()
  const data = await put({
    path: `/profile/${username}/email-validation-retry`,
  })

  return data
}

import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonButton, isPlatform } from '@ionic/react'
import cx from 'classnames'

import { HeartEmoji } from '@/components/atoms/assets/emojis'

import { useReaction } from '@/lib/hooks/useReaction'
import { useNavigate } from '@/lib/routing'
import { compactNumbers } from '@/utils/utils'

import { OwnReaction } from '@/enums'

interface IReactionButtonProps {
  reactionActivityId: string
  likesNumberPosition?: 'right' | 'bottom'
  className?: string
  onClick?: () => void
  own_reactions: OwnReaction[]
  reaction_counts: Record<string, number>
}

export const ReactionButton: FC<IReactionButtonProps> = ({
  likesNumberPosition = 'bottom',
  className = '',
  reactionActivityId,
  onClick,
  own_reactions,
  reaction_counts,
}) => {
  const navigate = useNavigate()

  const { t } = useTranslation('common')

  const { reaction } = useReaction(reactionActivityId, {
    own_reactions,
    reaction_counts,
  })

  const hasOwnReactions = reaction?.ownReactions?.length > 0

  if (!reaction) return null

  const icon = () => (
    <HeartEmoji
      isOutlined={!hasOwnReactions}
      slot="icon-only"
      className="items-center justify-center"
      color={hasOwnReactions ? 'danger' : 'cool-gray-200'}
    />
  )

  return (
    <div
      className={cx('text-center', {
        'items-center flex': likesNumberPosition === 'right',
      })}
    >
      <IonButton
        disabled={reaction?.isLoading}
        className={cx(className, 'flex items-center plain', {
          'flex-col': likesNumberPosition === 'bottom',
        })}
        onClick={onClick}
      >
        {icon()}
      </IonButton>
      {!!reaction.reactionsCount && (
        <div onClick={() => navigate(`/likes/${reactionActivityId}`)}>
          <p
            className={cx('cursor-pointer text-xs text-cool-gray-200', {
              '-ml-2 mr-2': likesNumberPosition === 'right',
              '-mt-2 mb-2 block': likesNumberPosition === 'bottom',
              '!-ml-1': isPlatform('ios'),
            })}
          >
            <Trans
              count={reaction.reactionsCount}
              i18nKey="common.countLikes"
              values={{
                countFormatted: compactNumbers(reaction.reactionsCount),
              }}
            >
              {t('common.countLikes', {
                count: reaction.reactionsCount,
                countFormatted: compactNumbers(reaction.reactionsCount),
              })}
            </Trans>
          </p>
        </div>
      )}
    </div>
  )
}

import { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import truncateEthAddress from 'truncate-eth-address'

import { ReceivedIcon } from '@/components/atoms/assets/ReceivedIcon'
import { SentIcon } from '@/components/atoms/assets/SentIcon'
import { Currency } from '@/components/atoms/Currency'
import { UserName } from '@/components/atoms/UserName'

import { appConfig } from '@/services/config'
import { ITransactionItem } from '@/services/wallet/getTransactions'
import { toTokenCentsString, toTokenString } from '@/utils/token'

import { TransactionStatus } from './TransactionStatus'

interface HistoryListDefaultItemProps {
  item: ITransactionItem
}

const timeFormatter = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
})

export const HistoryListDefaultItem: FC<HistoryListDefaultItemProps> = ({
  item,
}) => {
  const { t } = useTranslation('common')
  const isReceived = BigInt(item?.amount) >= 0n
  const createdAt = new Date(item?.createdAt)
  const time = isNaN(Number(createdAt)) ? '' : timeFormatter.format(createdAt)

  const url = useCallback(
    ({
      txHash,
      network,
    }: {
      txHash?: ITransactionItem['txHash']
      network?: ITransactionItem['network']
    }) => {
      const platform = network === 'arbitrum' ? 'arbiscan' : 'etherscan'

      return `https://${
        appConfig.stage !== 'production' ? `sepolia.` : ''
      }${platform}.io/tx/${txHash}`
    },
    []
  )

  const ExternalWalletTruncated = useCallback(
    ({
      txHash,
      network,
    }: {
      txHash?: ITransactionItem['txHash']
      network?: ITransactionItem['network']
    }) => (
      <span
        className="text-white font-bold cursor-pointer"
        onClick={() => {
          if (!txHash) return
          window.open(url({ txHash, network }))
        }}
      >
        {truncateEthAddress(String(item?.externalWalletId))}
      </span>
    ),
    [url, item?.externalWalletId]
  )

  const TXTruncated = useCallback(
    ({
      txHash,
      network,
    }: {
      txHash?: ITransactionItem['txHash']
      network?: ITransactionItem['network']
    }) => (
      <span
        className="text-white font-bold cursor-pointer"
        onClick={() => {
          if (!txHash) return
          window.open({
            url: url({ txHash, network }),
          })
        }}
      >
        {truncateEthAddress(String(item?.txHash))}
      </span>
    ),
    [url, item?.txHash]
  )

  const getTransKey = useMemo(() => {
    let i18nKey: string
    switch (item.type) {
      case 'withdraw':
        i18nKey = 'wallet.transactionHistory.withdraw'
        break
      case 'deposit':
        i18nKey = 'wallet.transactionHistory.deposit'
        break
      case 'chat':
        i18nKey = `wallet.transactionHistory.${
          isReceived ? 'chatPaymentReceived' : 'chatPaymentSent'
        }`
        break
      case 'subscription':
      case 'recurring_payment':
        i18nKey = `wallet.transactionHistory.${
          isReceived ? 'subscriptionFeeReceived' : 'subscriptionFeeSent'
        }`
        break
      case 'creator-token-fee':
        i18nKey = 'wallet.transactionHistory.creatorTokenFee'
        break
      case 'revert-creator-token-fee':
        i18nKey = 'wallet.transactionHistory.revertCreatorTokenFee'
        break
      default:
        i18nKey = `wallet.transactionHistory.${
          isReceived ? 'received' : 'sent'
        }`
    }
    return i18nKey
  }, [item, isReceived])

  return (
    <div className="bg-cool-gray-800 rounded-[0.3125rem] my-2 p-4">
      <div className="flex items-center">
        <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-12">
          {isReceived ? <ReceivedIcon /> : <SentIcon />}
        </div>
        <div className="flex-auto ml-3">
          <div className="flex">
            <span className="text-sm text-gray-white text-cool-gray-200">
              <Trans
                t={t}
                i18nKey={getTransKey}
                components={{
                  externalWalletId: (
                    <ExternalWalletTruncated
                      txHash={item?.txHash}
                      network={item?.network}
                    />
                  ),
                  txHash: (
                    <TXTruncated
                      txHash={item?.txHash}
                      network={item?.network}
                    />
                  ),
                  userLink: (
                    <UserName
                      className="inline font-bold"
                      userId={
                        isReceived ? item.source?.id : item.destination?.id
                      }
                      name={
                        isReceived
                          ? item.source?.username.includes('RepubliK')
                            ? item.source?.username.toLowerCase()
                            : item.source?.username
                          : item.destination?.username
                      }
                    />
                  ),
                }}
                values={{
                  type: t(`transaction.types.${item.type}`),
                }}
              />
            </span>
          </div>
          <div className="flex">
            <div className="text-cool-gray-200 text-xs flex-1 flex flex-col justify-center items-start">
              <TransactionStatus item={item} />
              <span>{time}</span>
            </div>
            <div className="flex-1 text-base font-bold flex flex-col items-end">
              <div className="flex flex-row">
                <span
                  className="text-sm"
                  title={toTokenCentsString(item?.amount)}
                >
                  <Currency value={item?.amount} hasSign isHighlighted />
                </span>
              </div>
              {(item?.fee || item?.totalFee) && (
                <div className="flex text-xs justify-end text-cool-gray-300">
                  {t('wallet.fee', {
                    amount: toTokenString(item?.fee || item?.totalFee, false),
                    count: item?.totalFee ? 1 : 0,
                    currency: appConfig.currency,
                  })}
                </div>
              )}
              {Number(item?.revenueShare) > 0 && (
                <div className="text-xs text-white">
                  <Trans
                    i18nKey="wallet.revenueShare"
                    components={{
                      amount: (
                        <Currency
                          value={item?.revenueShare}
                          hasSign
                          isHighlighted
                        />
                      ),
                    }}
                    values={{
                      count: item?.revenueShare ? 1 : 0,
                    }}
                  >
                    {t('wallet.revenueShare', {
                      count: item?.revenueShare ? 1 : 0,
                    })}
                  </Trans>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { Dispatch, FC, SetStateAction } from 'react'

import { CloseCircle } from '@/components/atoms/assets/CloseCircle'
import { SubscribeCategoryButton } from '@/components/molecules/SubscribeCategoryButton'

import { Category } from '@/services/categories'

type SelectedCategoryComponentProps = {
  selectedCategory: Category
  setSelectedCategory: Dispatch<SetStateAction<Category>>
}

export const SelectedCategoryComponent: FC<SelectedCategoryComponentProps> = ({
  selectedCategory,
  setSelectedCategory,
}) => {
  if (!selectedCategory) return

  return (
    <div className="flex flex-row justify-between items-center text-primary font-bold mb-2 ml-4 mr-3 md:ml-1 md:mr-0">
      <div className="flex flex-row items-center">
        <span className="mr-4">{selectedCategory?.name}</span>
        <SubscribeCategoryButton categoryId={selectedCategory.id} />
      </div>
      <CloseCircle
        className="w-6"
        color="white"
        outline
        onClick={() => setSelectedCategory(null)}
      />
    </div>
  )
}

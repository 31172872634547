import { FC } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

import { isValidUrl, stripSubdomain } from '@/utils/utils'
import { urlPattern } from '@/utils/validation'

type MarkdownUrlHighlighterProps = {
  className?: string
  text: string
}

export const MarkdownUrlHighlighter: FC<MarkdownUrlHighlighterProps> = ({
  className = '',
  text,
}) => {
  const parsedText = text?.replaceAll(urlPattern, '[$&]($&)') || ''

  return (
    <ReactMarkdown
      className={className}
      linkTarget="_blank"
      components={{
        a: ({ href, ...props }) => {
          const startWithWWW = href.startsWith('www')
          if (!isValidUrl(href) && !startWithWWW) {
            return <>{href}</>
          }
          return (
            <a
              className={'underline cursor-pointer'}
              onClick={() => {
                window.open(startWithWWW ? `http://${href}` : href)
              }}
              {...props}
            >
              {startWithWWW ? href : stripSubdomain(href)}
            </a>
          )
        },
      }}
    >
      {parsedText}
    </ReactMarkdown>
  )
}

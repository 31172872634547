import { FC } from 'react'
import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react'

import { BackButton } from '@components/atoms/BackButton'
import { Title } from '@components/atoms/Title'
import { OfflineIndicator } from '@/components/atoms/OfflineIndicator'

import { Routes } from '@/router/routes'

export interface ILoginTemplateProps {
  children: React.ReactNode
  backButton?: boolean
  title?: string | React.ReactNode
  toolbarClassName?: string
  ionContentClassName?: string
}

export const Login: FC<ILoginTemplateProps> = ({
  children,
  title,
  backButton = false,
  toolbarClassName,
  ionContentClassName = '',
}) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={toolbarClassName} color="dark" mode="ios">
          {backButton ? <BackButton defaultHref={Routes.AUTH_SIGN_UP} /> : null}
        </IonToolbar>
        <OfflineIndicator />
      </IonHeader>
      <IonContent fullscreen>
        <section
          className={`sm:max-w-sm mx-auto pt-40 px-6 min-h-full flex flex-col ${ionContentClassName}`}
        >
          {title && (
            <Title className="mb-12" level={1}>
              {title}
            </Title>
          )}

          {children}
        </section>
      </IonContent>
    </IonPage>
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { put } from '@/services/awsAmplify'

type UpdateChatPaywallParams = {
  enabled: boolean
  price?: number
}

type UpdateChatPaywallData = {
  enabled: boolean
  price?: number
}

const updateChatPaywall = async (params: UpdateChatPaywallParams) => {
  const response = await put({
    path: '/chat/paywall',
    params,
  })

  return response
}

export const useUpdateChatPaywall = (
  options?: UseMutationOptions<
    UpdateChatPaywallData,
    unknown,
    UpdateChatPaywallParams
  >
) =>
  useMutation({
    mutationFn: updateChatPaywall,
    ...options,
  })

import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { Logo } from '@/components/atoms/assets/Logo'
import { Title } from '@/components/atoms/Title'
import { Login } from '@/components/templates/login'

import { appConfig } from '@/services/config'

import SignInWithEmail from './Email/SignInWithEmail'
import ExistingPhoneLogin from './ExistingPhoneLogin'
import { LoginBackground } from './LoginBackground'
import SignInWithFacebook from './SSO/SignInWithFacebook'
import SignInWithGoogle from './SSO/SignInWithGoogle'

export const SignUpOptions: FC = () => {
  const { t } = useTranslation('common')

  return (
    <Login
      toolbarClassName="h-[0]"
      ionContentClassName="items-center justify-between !pt-0"
    >
      <div className="w-full max-w-[336px] flex flex-col items-center justify-center flex-1 z-10">
        <Logo className="w-[50px] h-[38px] fill-primary mb-2" />
        <Title className="mb-24 text-center" level={1}>
          <Trans i18nKey="registration.signUpOptions.title">
            {t('registration.signUpOptions.title')}
          </Trans>
        </Title>
        <span className="text-center mb-4">
          {t('registration.signUpOptions.description')}
        </span>
        {Boolean(appConfig.ssoVisible) && (
          <GoogleOAuthProvider clientId={appConfig.googleWebClientId}>
            <SignInWithGoogle />
          </GoogleOAuthProvider>
        )}
        {Boolean(appConfig.ssoVisible) && <SignInWithFacebook />}
        <SignInWithEmail />
      </div>
      <ExistingPhoneLogin />
      <LoginBackground />
    </Login>
  )
}

import { get } from '@/services/awsAmplify'

import { TransactionsType } from '@/enums'
export interface ITransactionItem {
  amount: string
  revenueShare: string
  createdAt: string
  source: {
    id: UUID
    username: string
  }
  destination: {
    id: UUID
    username: string
  }
  walletId: UUID
  description: string
  id: UUID
  transactionId: UUID
  type:
    | 'airdrop'
    | 'transfer'
    | 'tip'
    | 'withdraw'
    | 'deposit'
    | 'chat'
    | 'subscription'
    | 'recurring_payment'
    | 'revert-subscription'
    | 'revert-tip'
    | 'revert-chat'
    | 'creator-token-fee'
    | 'revert-creator-token-fee'
  fee: string
  externalWalletId?: string
  totalFee?: string
  fbTransactionStatus?: string
  fbTransactionId?: string
  network?: 'ethereum' | 'arbitrum'
  txHash?: string
  stakeApyInPercentage?: number
  stakeReleaseDate?: string
  reward?: string
}

export interface IStakeTransactionItem {
  destinationWalletId: UUID
  stakeDate: string
  stakeReleaseDate: string
  stakeRuleId: string
  amount: string
  createdAt: string
  destination: {
    id: UUID
    username: string
  }
  walletId: string
  stakeApyInPercentage: number
  transactionId: string
  type: TransactionsType.Stake | TransactionsType.StakeRedeem
  lastKey?: string
  stakeReward?: string
}

export interface ITransactions {
  items: ITransactionItem[] | IStakeTransactionItem[]
  lastKey: string
}

export const getTransactions = async ({
  userId,
  pageParam,
}): Promise<ITransactions> => {
  return get({
    path: `/wallets/${userId}/transactions`,
    params: pageParam,
  })
}

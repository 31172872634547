import { ITrackingDriver } from './driver'
import { EventName, EventPropertyMap } from './interface'

export class TrackingSystem {
  private static instance: TrackingSystem
  private drivers: ITrackingDriver[] = []

  private constructor(drivers: ITrackingDriver[] = []) {
    this.drivers = drivers
  }

  public static getInstance(drivers: ITrackingDriver[]): TrackingSystem {
    if (!TrackingSystem.instance) {
      TrackingSystem.instance = new TrackingSystem(drivers)
    }
    return TrackingSystem.instance
  }

  public triggerEvent<Event extends EventName>(
    eventName: Event,
    data?: EventPropertyMap[Event]
  ) {
    this.drivers.forEach((driver) => {
      if (driver.isTrackable(eventName)) {
        driver.triggerEvent(eventName, data)
      }
    })
  }
}

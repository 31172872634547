import { cacheName } from '@/sw'

function getImageRatio(url: string): Promise<number> {
  return new Promise((resolve) => {
    const image = new Image()

    image.onload = function () {
      resolve(image.naturalWidth / image.naturalHeight)
    }
    image.src = url
  })
}

export const getCachedMedia = async () => {
  try {
    const cache = await caches.open(cacheName)
    const requests = await cache.keys()
    const medias = await Promise.all(
      requests.reverse().map(async (request) => {
        const response = await cache.match(request)
        const blob = await response.blob()
        const url = URL.createObjectURL(blob)
        const mediaType = blob.type.split('/')[0] as 'image' | 'video'
        const ratio = mediaType === 'image' ? await getImageRatio(url) : null

        return Object.assign(
          {
            mimeType: blob.type,
            mediaType,
            url,
          },
          mediaType === 'image' ? { ratio } : {}
        )
      })
    )

    requests.forEach((request) => {
      cache.delete(request)
    })

    return medias
  } catch (error) {
    console.log('error reading cache', error)
  }
}

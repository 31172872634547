import { FC, memo } from 'react'

interface IPlusProps {
  className?: string
}

const PlusComponent: FC<IPlusProps> = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6" y="6" width="20" height="20" fill="black" />
      <path
        className="fill-primary-500"
        d="M16 0C7.16786 0 0 7.16786 0 16C0 24.8321 7.16786 32 16 32C24.8321 32 32 24.8321 32 16C32 7.16786 24.8321 0 16 0ZM22.4241 17.5521H17.5519V22.4241C17.5519 23.284 16.86 23.976 16 23.976C15.14 23.976 14.4481 23.284 14.4481 22.4241V17.5521H9.57586C8.71593 17.5521 8.02393 16.8602 8.02393 16.0002C8.02393 15.1402 8.71589 14.4483 9.57586 14.4483H14.4478L14.4481 9.57607C14.4481 8.71614 15.14 8.02414 16 8.02414C16.86 8.02414 17.5519 8.71611 17.5519 9.57607V14.448H22.4239C23.2838 14.448 23.9758 15.14 23.9758 15.9999C23.9761 16.8601 23.2802 17.5521 22.4241 17.5521Z"
      />
    </svg>
  )
}

export const Plus = memo(PlusComponent)

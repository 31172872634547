import { localeMapper } from '@/lib/translation/i18n'

import { getLanguage } from './getLanguage'

export const DECIMALS = 1000000000000000000n
export const TOKEN_STRING_MIN_LENGTH = DECIMALS.toString().length

export const toTokenString = (token: string, hasSign?: boolean): string => {
  const language = getLanguage()
  const bigintToken = BigInt(token)
  const sign = bigintToken < 1n ? '' : '+'
  const rawValue = Number((bigintToken * 100n) / DECIMALS) * 0.01
  return (
    (hasSign ? sign : '') +
    new Intl.NumberFormat(localeMapper[language]).format(rawValue)
  )
}

export const tokenToString = (token: string, fraction = 2): string => {
  const bigintToken = BigInt(token)
  const rawValue = Number((bigintToken * 100n) / DECIMALS) * 0.01
  return rawValue.toFixed(fraction)
}

export const toTokenCentsString = (token: string) => {
  const bigintToken = BigInt(token)
  const sign = bigintToken < 0n ? '-' : ''
  const value = bigintToken < 0n ? -bigintToken : bigintToken
  const str = value.toString().padStart(TOKEN_STRING_MIN_LENGTH, '0')
  return `${sign}${str}`
}

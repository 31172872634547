import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonItem } from '@ionic/react'

import { Avatar } from '@/components/atoms/Avatar'

import { appConfig } from '@/services/config'
import { formatDistance } from '@/utils/date'

import { ActivityRewardFollowerMilestoneCount } from '@/types/activity/reward'

interface IActivityRewardFollowerMilestoneItemProps {
  item: ActivityRewardFollowerMilestoneCount
}

export const ActivityRewardFollowerMilestoneItem: FC<
  IActivityRewardFollowerMilestoneItemProps
> = ({ item }: IActivityRewardFollowerMilestoneItemProps) => {
  const { t } = useTranslation('common')
  const { data } = item

  return (
    <IonItem className="py-4 border-solid border-b-[0.0625rem] border-zinc-800">
      <span className="pr-4">
        <Avatar userId={appConfig.republikUserId} clickable isAmbassador />
      </span>
      <div className="w-full flex flex-col justify-between">
        <div className="text-sm">
          <Trans count={data.milestone}>
            {data.xpBonus
              ? t(
                  'activity.item.rewards.follower_milestone_reached_reward_with_bonus',
                  {
                    xp: data.xp,
                    count: data.milestone,
                    bonus: data.xpBonus,
                  }
                )
              : t('activity.item.rewards.follower_milestone_reached_reward', {
                  xp: data.xp,
                  count: data.milestone,
                })}
          </Trans>
        </div>
        <div className="text-xs text-zinc-600">{formatDistance(item.time)}</div>
      </div>
    </IonItem>
  )
}

import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import { IonIcon, IonPage } from '@ionic/react'
import cx from 'classnames'
import { closeCircle } from 'ionicons/icons'

import { appConfig } from '@/services/config'
import { isIosNativeOrPWA } from '@/utils/utils'

import animationData from './stox_animation.json'

type MintConfirmationModalProps = {
  onDismiss: () => void
}

export const MintConfirmationModal: FC<MintConfirmationModalProps> = ({
  onDismiss,
}) => {
  const { t } = useTranslation('common')

  const { creatorToken } = appConfig

  return (
    <IonPage>
      <div className="relative w-full h-full" onClick={onDismiss}>
        <IonIcon
          className={cx(
            'absolute right-5 z-50 w-8 h-8 md:w-12 md:h-12 cursor-pointer',
            { 'top-16': isIosNativeOrPWA(), 'top-5': !isIosNativeOrPWA() }
          )}
          icon={closeCircle}
          onClick={onDismiss}
        />
        <span
          className={cx(
            'absolute right-6 z-40 w-6 h-6 md:w-10 md:h-10 flex items-center justify-center bg-black rounded-full',
            {
              'top-[4.25rem]': isIosNativeOrPWA(),
              'top-6': !isIosNativeOrPWA(),
            }
          )}
        />
        <div
          className="flex flex-col relative top-[10%] items-center justify-center"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className="pl-[10%] mb-[10%]">
            <Lottie
              className="cursor-wait"
              options={{
                loop: true,
                autoplay: true,
                animationData,
              }}
              isClickToPauseDisabled={true}
            />
          </div>
          <div className="absolute top-[85%] w-[80%] text-white font-bold text-center">
            <div className="text-[8vw] sm:text-5xl">
              <Trans
                i18nKey={'mintConfirmationModal.title'}
                components={{
                  primary: <span className="text-primary" />,
                }}
                values={{ creatorToken }}
              >
                {t('mintConfirmationModal.title', { creatorToken })}
              </Trans>
            </div>
            <div className="text-[4vw] sm:text-2xl mt-[7%]">
              {t('mintConfirmationModal.subtitle')}
            </div>
          </div>
        </div>
      </div>
    </IonPage>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react'
import cx from 'classnames'

import { Diamond } from '@/components/atoms/assets/Diamond'

import { appConfig } from '@/services/config'

import { IonSegmentCustomEvent, SegmentChangeEventDetail } from '@/interfaces'

import { Section } from '../UserProfileSections/types'

type SectionsButtonsTabProps = {
  sections: Section[]
  selectedSectionIndex: number
  handleSectionClick: (
    e: IonSegmentCustomEvent<SegmentChangeEventDetail>
  ) => void
  handleButtonClick?: () => void
}

export const SectionsButtonsTab: FC<SectionsButtonsTabProps> = ({
  sections,
  selectedSectionIndex,
  handleSectionClick,
  handleButtonClick,
}) => {
  const { creatorToken } = appConfig
  const { t } = useTranslation()
  const sectionButtons = sections.map((section: Section, index) => {
    if (section.name === 'subscription' || section.name === creatorToken) {
      return (
        <IonSegmentButton
          className="sections-buttons-tab"
          key={section.name}
          value={String(index)}
          onClick={handleButtonClick}
        >
          <IonLabel className="flex justify-center items-center">
            {t(`profile.sections.${section.name}.name`)}
            {section.name === 'subscription' && (
              <div className="ml-2 gradient rounded-full p-1">
                <Diamond className="h-4 w-4" />
              </div>
            )}
          </IonLabel>
        </IonSegmentButton>
      )
    }

    return (
      <IonSegmentButton
        className={cx('sections-buttons-tab', {
          'sections-buttons-tab-w-full': sections.length === 1,
        })}
        key={section.name}
        value={String(index)}
      >
        <IonLabel>{t(`profile.sections.${section.name}.name`)}</IonLabel>
      </IonSegmentButton>
    )
  })

  return (
    <IonSegment
      className="h-[3rem] border-none"
      mode="md"
      scrollable={true}
      value={selectedSectionIndex.toString()}
      onIonChange={handleSectionClick}
    >
      {sectionButtons}
    </IonSegment>
  )
}

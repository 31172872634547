import { record } from '@/services/awsAmplify'

import { ITrackingDriver } from '../driver'
import { TrackingEvent } from '../enums'
import { EventName, EventPropertyMap } from '../interface'

export class AWSKinesisDriver implements ITrackingDriver {
  events: TrackingEvent[]

  constructor(events: TrackingEvent[]) {
    this.events = events
  }

  public triggerEvent<Event extends EventName>(
    name: Event,
    data?: EventPropertyMap[Event]
  ) {
    record({
      data: {
        name,
        data,
        immediate: true,
      },
    })
  }

  public isTrackable<Event extends EventName>(eventName: Event): boolean {
    return this.events.includes(eventName as TrackingEvent)
  }
}

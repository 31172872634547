import { FC } from 'react'
import { Trans } from 'react-i18next'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Logo } from '@components/atoms/assets/Logo'

import { appConfig } from '@/services/config'
import { OnboardingData } from '@/lib/hooks/useOnboarding'

type OnboardingSwiperProps = {
  slidesData: OnboardingData['items']
}

export const OnboardingSwiper: FC<OnboardingSwiperProps> = ({ slidesData }) => {
  if (!slidesData) return null

  return (
    <div className="w-full h-[100dvh]">
      <Swiper
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        className="onboarding"
        loop
      >
        {slidesData?.map((d) => (
          <SwiperSlide key={d.title}>
            <div className="w-full h-[100dvh] flex flex-col bg-[#141820]">
              <img
                src={`${appConfig.origin}/${d.image}`}
                className="w-full h-full object-cover"
              />
              <div
                className={`slide-container absolute bottom-0 z-10 w-full flex flex-col items-center justify-end`}
              >
                <Logo className="fill-primary w-[20%] max-w-[4rem] mb-4" />
                <span className="mt-4 mb-2 bg-white rounded-21 text-xs text-black font-bold py-0.5 px-2.5 rounded-2xl">
                  {d.label?.toLocaleUpperCase()}
                </span>
                <div className="flex flex-col mx-6">
                  <p className="text-[6vw] sm:text-2xl mb-[1.5vw] mt-[1.5vw] font-semibold leading-tight">
                    <Trans i18nKey={d.title}>{d.title}</Trans>
                  </p>
                  <p className="text-[4vw] sm:text-sm font-normal">
                    {d.subtitle}
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

import { useMemo } from 'react'

import { appConfig } from '@/services/config'

import { useUserInfo } from '../useUserInfo'
import json from './whiteList.json'

export const useWhiteList = () => {
  const { data: authUser } = useUserInfo()

  const isWhitelistedUser = useMemo(() => {
    if (!authUser) return false
    if (appConfig.stage != 'production') return true
    return Boolean(json.whiteList.find((user) => user.userId === authUser.id))
  }, [authUser])

  return {
    isWhitelistedUser,
  }
}

import { ChangeEvent, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import { FileStorage } from '@/services/fileStorage'

type Photo = { dataUrl: string; format: string }

async function uploadImage(image: Photo) {
  if (!image?.dataUrl) {
    return
  }

  const path = `avatar/avatar.${image.format}`
  const blob = await fetch(image.dataUrl).then((res) => res.blob())

  if (!path || !blob) {
    return
  }

  return FileStorage.put(path, blob)
}

export const useAvatarInput = () => {
  const { t } = useTranslation('common')
  const [cameraImage, setCameraImage] = useState<Photo>()
  const [uploading, setUploading] = useState<boolean>(false)
  const [avatarError, setAvatarError] = useState<string>(null)
  const queryClient = useQueryClient()
  const errorTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const takePicture = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        setAvatarError(null)

        const file = e.target.files?.[0]
        if (!file) return

        const reader = new FileReader()

        let fileExtension = file.name.includes('.')
          ? file.name.split('.').pop()
          : null
        if (!fileExtension) fileExtension = file.type.split('/').pop()

        reader.onloadend = async () => {
          const base64Image = reader.result as string
          const image = { dataUrl: base64Image, format: fileExtension }

          setUploading(true)
          setCameraImage(image)
          await uploadImage(image)

          queryClient.setQueryData(['cached_user_avatar'], image.dataUrl)

          const oldTimeout = queryClient.getQueryData<number>([
            'cached_avatar_timeout',
          ])
          clearTimeout(oldTimeout)

          const newTimeout = setTimeout(() => {
            queryClient.removeQueries({ queryKey: ['cached_user_avatar'] })
            queryClient.removeQueries({ queryKey: ['cached_avatar_timeout'] })
          }, 120000) // 2 minutes

          queryClient.setQueryData(['cached_avatar_timeout'], newTimeout)
          setUploading(false)
        }

        reader.readAsDataURL(file)
      } catch (error) {
        console.log('Error while taking picture', error)
        setAvatarError(t('common.error.useJpgPngOnly'))

        if (errorTimeoutRef.current) clearTimeout(errorTimeoutRef.current)
        errorTimeoutRef.current = setTimeout(() => setAvatarError(null), 5000)
        setUploading(false)
      }
    },
    [queryClient, t]
  )

  return {
    setCameraImage,
    takePicture,
    uploading,
    url: cameraImage?.dataUrl,
    avatarError,
    setAvatarError,
  }
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonIcon, IonLabel } from '@ionic/react'
import { alertCircleOutline } from 'ionicons/icons'

export interface IEmailNotVerifiedLabelProps {
  onClick?: () => void
}

export const EmailNotVerifiedLabel: FC<IEmailNotVerifiedLabelProps> = ({
  onClick,
}) => {
  const { t } = useTranslation('common')

  return (
    <IonLabel color="success" className="text-xs items-start flex">
      <IonIcon className="h-4 w-4 mr-1" icon={alertCircleOutline} />
      {t('registration.email.notVerified')}
      {onClick && (
        <>
          ,
          <span onClick={onClick} className="underline ml-1 cursor-pointer">
            <strong>{t('registration.email.resendLink')}</strong>
          </span>
        </>
      )}
    </IonLabel>
  )
}

import { FC, useCallback } from 'react'
import cx from 'classnames'

import { appConfig } from '@/services/config'
import { numberFormat } from '@/utils/currency'
import { DECIMALS } from '@/utils/token'

interface CurrencyProps {
  value: string | number
  isHighlighted?: boolean
  hasSign?: boolean
}

export const Currency: FC<CurrencyProps> = ({
  value,
  isHighlighted = false,
  hasSign = false,
}) => {
  const currency = appConfig.currency

  const formattedValue = useCallback(() => {
    if (!value) return

    const bigintToken = BigInt(value)
    const sign = hasSign && bigintToken >= 0 ? '+' : ''
    const rawValue = Number((bigintToken * 100n) / DECIMALS) * 0.01

    const formattedValue = `${sign}${numberFormat(rawValue)}`

    if (hasSign) {
      return formattedValue
    }

    return formattedValue.replace('-', '')
  }, [value, hasSign])

  return (
    <>
      {typeof value === 'number'
        ? numberFormat(Number(value))
        : formattedValue()}
      <span className={cx('ml-1', { 'text-primary': isHighlighted })}>
        {currency}
      </span>
    </>
  )
}

import { FC } from 'react'

import { XPScoreBg } from '@/components/atoms/assets/XPScoreBg'
import { XPBadge } from './components/XPBadge'
import { XPMultiplier } from './components/XPMultiplier'

import { Badge } from '@/enums'

export interface XPScoreHeaderProps {
  xp: number
  badge?: Badge
  multiplier?: string
  hasHighestRewardMultiplier?: boolean
}

export const XPScoreHeader: FC<XPScoreHeaderProps> = ({
  xp,
  badge,
  multiplier,
  hasHighestRewardMultiplier,
}) => {
  return (
    <div className="relative w-full py-[5vw] md:py-[3.125rem]">
      <XPScoreBg className="bg-cool-gray-700 border border-cool-gray rounded-xl w-full h-full" />
      <div className="absolute top-0 left-0 h-full w-full flex items-center justify-between px-2 py-[10vw] md:py-[4.25rem]">
        <XPBadge xp={xp} badge={badge} />
        <XPMultiplier
          multiplier={multiplier}
          hasHighestRewardMultiplier={hasHighestRewardMultiplier}
        />
      </div>
    </div>
  )
}

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { UseFormHandleSubmit } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { IonLabel, IonSpinner } from '@ionic/react'
import { useMutation } from '@tanstack/react-query'
import cx from 'classnames'

import { Button } from '@components/atoms/Button'
import { InputField } from '@components/atoms/InputField'
import { GeeCaptcha, ICaptchaResult } from '@/components/atoms/GeeCaptcha'

import { signIn } from '@/services/auth'
import { appConfig } from '@/services/config'
import { errorBoundary } from '@/services/errorBoundary'

export interface EnterCodeProps {
  control?: Control<EnterFieldValue>
  submit?: ReturnType<UseFormHandleSubmit<FieldValues>>
  isDirty: boolean
  error: string
  setError: (error: string) => void
  isLoading: boolean
  captchaResponse: {
    username: string
  }
  authType
  isErrored: {
    code: boolean
    geeCaptchaToken: boolean
  }
}

export type EnterFieldValue = {
  code: string
  geeCaptchaToken: string
}

export const EnterCode: FC<EnterCodeProps> = ({
  control,
  submit,
  isDirty,
  error,
  setError,
  isLoading,
  authType,
  captchaResponse,
  isErrored,
}) => {
  const [attemptsLeft, setAttemptsLeft] = useState(2)
  const [time, setTime] = useState<number>(30)
  const { t } = useTranslation('common')
  const [captchaVisible, setCaptchaVisible] = useState<boolean>(false)
  const timer = useRef(null)

  const { mutate } = useMutation({
    mutationKey: ['signIn'],
    mutationFn: signIn,
    onSuccess: () => {
      setCaptchaVisible(false)
      startTimer()
    },
    onError: errorBoundary,
  })

  const startTimer = useCallback(async () => {
    setTime(30)
    clearInterval(timer.current)
    timer.current = setInterval(
      () =>
        setTime((prevTime) => {
          !prevTime && clearInterval(timer.current)

          return prevTime ? prevTime - 1 : prevTime
        }),
      1000
    )
  }, [])

  useEffect(() => {
    setCaptchaVisible(false)
    setAttemptsLeft(2)
    startTimer()

    return () => {
      clearInterval(timer.current)
    }
  }, [startTimer])

  const handleCaptcha = () => {
    if (time !== 0) return
    if (appConfig.geeTestSignUpId) {
      setCaptchaVisible(true)
      return
    }
    repeatSignIn({})
  }

  const repeatSignIn = ({
    geeCaptchaToken,
  }: {
    geeCaptchaToken?: ICaptchaResult
  }) => {
    mutate({
      username: captchaResponse.username,
      token: geeCaptchaToken,
      authType,
    })
    setAttemptsLeft(attemptsLeft - 1)
  }

  return (
    <>
      <Controller
        control={control}
        name="code"
        rules={{ required: true }}
        render={({ field }) => (
          <InputField
            value={field.value}
            textAlign="center"
            type="tel"
            onChange={(e) => {
              field.onChange(e?.value)
              setError('')
            }}
          />
        )}
      />
      {error && (
        <div className="mt-2 flex justify-center">
          <IonLabel color="danger">{error}</IonLabel>
        </div>
      )}
      <div className="text-gray-500 text-center pt-4">
        <p>
          {t('registration.confirm.verification', {
            email: captchaResponse.username,
          })}
        </p>
        <p>
          {attemptsLeft ? (
            `${t('registration.confirm.notReceiveCode')}${' '}`
          ) : (
            <Trans>{t('registration.confirm.codeWasResent')}</Trans>
          )}
          <span
            onClick={() => handleCaptcha()}
            className={cx('underline', 'block', {
              'cursor-not-allowed': time,
              'cursor-pointer': attemptsLeft && !time,
            })}
          >
            {attemptsLeft > 0 &&
              `${t('registration.confirm.resendCode')}
          ${
            attemptsLeft > 0 && time > 0
              ? t('registration.confirm.seconds', { time })
              : ''
          }`}
          </span>
        </p>
      </div>
      {appConfig.geeTestSignUpId && captchaVisible && (
        <>
          <br />
          <Controller
            control={control}
            name="geeCaptchaToken"
            render={() => (
              <GeeCaptcha
                validate={(e) => repeatSignIn({ geeCaptchaToken: e })}
              />
            )}
          />
          {isErrored['reCaptchaToken'] && (
            <div className="flex justify-center">
              <IonLabel color="danger" className="text-center mt-2">
                {t('registration.invalidToken')}
              </IonLabel>
            </div>
          )}
        </>
      )}
      <Button
        className="mt-8 flex mx-auto w-lg"
        size="large"
        onClick={() => submit?.()}
        isDisabled={!isDirty || !!error || isLoading}
      >
        {isLoading ? (
          <IonSpinner color="light" />
        ) : (
          t('registration.confirm.verify')
        )}
      </Button>
    </>
  )
}

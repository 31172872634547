import { get } from '@/services/awsAmplify'

import { IPromoPool } from '@/interfaces'

export const getPromoPools = async () => {
  return <Promise<{ items: readonly IPromoPool[] }>>(
    get({ path: `/promo-pools`, endpoint: 'cached' })
  )
}

export const getPromoPool = async (poolName: string, lastKey: string) => {
  const params = lastKey ? { id_lt: lastKey } : {}

  return <Promise<IPromoPool>>get({
    path: `/promo-pools/${poolName.toLowerCase()}`,
    params,
    endpoint: 'cached',
  })
}

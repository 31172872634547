import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StakeButton } from '@/components/molecules/StakeButton'

import { appConfig } from '@/services/config'

import { IconWithTooltip } from './IconWithTooltip'

interface XPMultiplierProps {
  multiplier?: string
  hasHighestRewardMultiplier: boolean
}

export const XPMultiplier: FC<XPMultiplierProps> = ({
  multiplier,
  hasHighestRewardMultiplier,
}) => {
  const { t } = useTranslation('common')

  return (
    <div className="w-[40%] flex flex-col justify-center items-center">
      {multiplier ? (
        <div className="text-center text-white text-[3vw] sm:text-xl flex flex-col sm:gap-1">
          <div className="font-bold">
            {t('XPWallet.xpMultiplierList.xpRewardMultiplier')}
          </div>

          <div className="flex flex-row justify-center ml-8">
            <div className="font-bold text-4xl">{multiplier}</div>
            <IconWithTooltip tooltipText={t('XPWallet.xpMultiplierInfoText')} />
          </div>
          {hasHighestRewardMultiplier ? (
            <div className="text-primary">
              <Trans t={t} i18nKey={'XPWallet.highestRewardMultiplierReached'}>
                {t('XPWallet.highestRewardMultiplierReached')}
              </Trans>
            </div>
          ) : (
            <div>
              {t('XPWallet.stakeForHigherMultiplier', {
                currency: appConfig.currency,
              })}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="text-center text-white max-w-[12rem] text-[3.75vw] sm:text-2xl">
            <Trans
              t={t}
              i18nKey={'XPWallet.stakeToGetMultiplier'}
              components={{
                iconWithTooltip: (
                  <IconWithTooltip
                    tooltipText={t('XPWallet.xpMultiplierInfoText')}
                  />
                ),
              }}
              values={{
                currency: appConfig.currency,
              }}
            >
              {t('XPWallet.stakeToGetMultiplier')}
            </Trans>
          </div>
        </div>
      )}

      {!hasHighestRewardMultiplier && (
        <StakeButton className="mt-2 sm:mt-5" text={t('wallet.stake.stake')} />
      )}
    </div>
  )
}

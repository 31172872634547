import { FC, memo } from 'react'

type InstagramIconProps = {
  className?: string
  isActive?: boolean
  isWhite?: boolean
}

const InstagramIconComponent: FC<InstagramIconProps> = ({
  className = '',
  isActive = false,
  isWhite = false,
}) => {
  const fileName = isWhite
    ? 'instagram-white'
    : isActive
    ? 'instagram-color'
    : 'instagram-gray'

  return (
    <picture>
      <source
        srcSet={`/social_icons/${fileName}.png 28w, /social_icons/${fileName}@2x.png 56w, /social_icons/${fileName}@3x.png 84w`}
      />
      <img
        className={`w-6 h-6 ${className}`}
        src={`/social_icons/${fileName}.png`}
        alt="Instagram-active"
      />
    </picture>
  )
}

export const InstagramIcon = memo(InstagramIconComponent)

import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { appConfig } from '@/services/config'
import {
  getSubscription,
  SubscriptionData,
  updateSubscription,
} from '@/services/subscription'
import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'

import { ProfileHashTabs, QueryKeys } from '@/enums'

import { useUserInfo } from '../useUserInfo'

export const useSubscription = () => {
  const navigate = useNavigate()
  const currency = appConfig.currency
  const { data: authUser } = useUserInfo()
  const [presentToast] = useIonToast()
  const { t } = useTranslation('common')

  const {
    refetch: refetchSubscription,
    data: subscription,
    isLoading: isLoadingSubscription,
    error: subscriptionError,
  } = useQuery({
    queryKey: [QueryKeys.Subscription, authUser?.id],
    queryFn: getSubscription,
    enabled: !!authUser?.id,
  })

  const {
    mutate: mutateSubscription,
    error: updateSubscriptionError,
    isPending: isUpdateSubscription,
  } = useMutation({
    mutationFn: updateSubscription,
    onSuccess: ({ price }: SubscriptionData) => {
      navigate(`/@${authUser?.username}${ProfileHashTabs.SUBSCRIPTION}`)
      queryClient.setQueryData([QueryKeys.Subscription, authUser?.id], {
        price,
      })
      presentToast({
        message: t('configureSubscription.successUpdateSubscription', {
          amount: price,
          currency,
        }),
        position: 'top',
        duration: 3000,
      })
    },
  })

  return {
    subscription,
    isLoadingSubscription,
    subscriptionError,
    mutateSubscription,
    updateSubscriptionError,
    isUpdateSubscription,
    refetchSubscription,
  }
}

import { memo } from 'react'

type CloseCircleProps = {
  color?: string
  backgroundColor?: string
  className?: string
  onClick: () => void
  outline?: boolean
}

const CloseCircleComponent = ({
  color = '#0E1117',
  backgroundColor = 'white',
  className = '',
  onClick,
  outline = false,
}: CloseCircleProps) => {
  return outline ? (
    <svg
      className={`cursor-pointer ${className}`}
      onClick={onClick}
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="14.9948"
        y1="9.0675"
        x2="9.06759"
        y2="14.9947"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="9.06759"
        y1="9.00488"
        x2="14.9948"
        y2="14.9321"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  ) : (
    <svg
      className={`cursor-pointer ${className}`}
      onClick={onClick}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        fill={backgroundColor}
      />
      <path
        d="M20 12L12 20"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20L12 12"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const CloseCircle = memo(CloseCircleComponent)

export const LoginBackground = () => {
  return (
    <div className="w-full absolute bottom-0">
      <picture>
        <source srcSet="/login-background.png 358w, /login-background@2x.png 716w, /login-background@3x.png 1074w" />
        <img className="w-full h-[252px]" src="/login-background.png" />
      </picture>
    </div>
  )
}

import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { getPublicPost } from '@/services/public'

import { QueryKeys } from '@/enums'

export const usePublicPost = ({ activityId }) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [QueryKeys.PublicPosts, activityId],
    queryFn: () => getPublicPost({ activityId }),
    refetchOnMount: true,
  })

  const userInfo = useMemo(() => {
    if (!data) return null
    const user = data?.actor?.data
    return {
      id: data?.actor.id,
      name: user?.name,
      badge: user?.badge,
      isAmbassador: user.isAmbassador,
      postImages: data?.object?.data?.media,
    }
  }, [data])

  return {
    userInfo,
    isLoading,
    isError,
  }
}

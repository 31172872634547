import { useTranslation } from 'react-i18next'

import { appConfig } from '@/services/config'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

import { FollowStatus } from '@/enums'

interface UseActionSheetButtonsProps {
  isAuthUser: boolean
  acceptableUsePolicy: boolean
  presentModal: () => void
  setIsLogoutPopoverVisible: (isVisible: boolean) => void
  setIsBlockPopoverVisible: (isVisible: boolean) => void
  onUnfollowUser: (message: string) => void
  reportUserMenuItem: () => void
}

export const useActionSheetButtons = ({
  isAuthUser,
  acceptableUsePolicy,
  presentModal,
  setIsLogoutPopoverVisible,
  setIsBlockPopoverVisible,
  onUnfollowUser,
  reportUserMenuItem,
}: UseActionSheetButtonsProps) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  return isAuthUser
    ? [
        {
          cssClass: 'edit-profile',
          text: t('profile.edit'),
          handler: () => navigate(Routes.PROFILE_EDIT),
        },
        {
          text: t('profile.configureSubscription'),
          handler: () =>
            acceptableUsePolicy
              ? navigate(Routes.CONFIGURE_SUBSCRIPTION)
              : presentModal(),
        },
        {
          text: t('profile.mySubscribers'),
          handler: () => navigate(Routes.MY_SUBSCRIBERS),
        },
        {
          text: t('profile.mySubscriptions'),
          handler: () => navigate(Routes.MY_SUBSCRIPTIONS),
        },

        {
          cssClass: 'security-settings',
          text: t('profile.securitySettings'),
          handler: () => navigate(Routes.SECURITY_SETTINGS),
        },
        ...(appConfig.faqLink
          ? [
              {
                text: t('profile.faq'),
                handler: () => window.open(appConfig.faqLink),
              },
            ]
          : []),
        {
          text: t('profile.configureChatPaywall'),
          handler: () => navigate(Routes.CONFIGURE_CHAT_PAYWALL),
        },
        {
          text: t('profile.logout'),
          role: 'destructive',
          handler: () => setIsLogoutPopoverVisible(true),
        },
        {
          text: t('report.cancel'),
          role: 'cancel',
        },
      ]
    : [
        {
          text: t('profile.blockUser'),
          role: 'destructive',
          data: {
            type: 'delete',
          },
          handler: () => setIsBlockPopoverVisible(true),
        },
        reportUserMenuItem(),
        ...(status === FollowStatus.Following || status === FollowStatus.Friends
          ? [
              {
                text: t('report.unFollowUser.buttonLabel'),
                data: {
                  type: 'delete',
                },
                handler: () =>
                  onUnfollowUser(t('report.unFollowUser.confirmationMessage')),
              },
            ]
          : []),
        {
          text: t('report.cancel'),
          role: 'cancel',
        },
      ]
}

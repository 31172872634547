import { ITrackingDriver } from '../driver'
import { TrackingEvent } from '../enums'
import { EventName, EventPropertyMap } from '../interface'

type DataLayer = {
  push: (...args: unknown[]) => boolean
}

export class GA4Driver implements ITrackingDriver {
  events: TrackingEvent[]

  constructor(events: TrackingEvent[]) {
    this.events = events
  }

  private dataLayer(): DataLayer | null {
    return typeof window !== 'undefined' && window.dataLayer
      ? (window.dataLayer as DataLayer)
      : null
  }

  public setUserProperty({
    name,
    value,
  }: {
    name: string
    value: string | number | boolean
  }) {
    this.dataLayer()?.push(['set', 'user_property', { [name]: value }])
  }

  public setUserId({ id }: { id: string }) {
    this.dataLayer()?.push(['set', { user_id: id }])
  }

  public triggerEvent<Event extends EventName>(
    eventName: Event,
    data?: EventPropertyMap[Event]
  ) {
    const params = data
      ? Object.entries(data).reduce(
          (acc, [name, value]) => ({ ...acc, [`rpk_${name}`]: value }),
          {}
        )
      : {}
    this.dataLayer()?.push({ event: `rpk.${eventName}`, ...params })
  }

  public isTrackable<Event extends EventName>(eventName: Event): boolean {
    return this.events.includes(eventName as TrackingEvent)
  }
}

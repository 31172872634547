import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Stake } from '@/components/atoms/assets/Stake'
import { Currency } from '@/components/atoms/Currency'
import { HistoryListItemDetail } from '@/components/pages/StakeVault/components/StakingVaultHistory/HistoryListItemDetail'
import { HistoryListStakeItemAmount } from '@/components/pages/StakeVault/components/StakingVaultHistory/HistoryListStakeItemAmount'

import { IStakeTransactionItem } from '@/services/wallet/getTransactions'
import { formatDate } from '@/utils/date'
import { toTokenCentsString } from '@/utils/token'

interface HistoryListStakeItemProps {
  item: IStakeTransactionItem
}

export const HistoryListStakeItem: FC<HistoryListStakeItemProps> = ({
  item,
}) => {
  const { t } = useTranslation('common')
  const isReceived = BigInt(item?.amount) > 0n

  return (
    <div className="bg-cool-gray-800 rounded-[0.3125rem] my-1.5 p-4">
      <div className="flex items-center">
        <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-12">
          <Stake type={isReceived ? 'primary' : 'secondary'} />
        </div>
        <div className="flex-auto ml-3">
          <HistoryListStakeItemAmount item={item} />
          {!isReceived && (
            <>
              <HistoryListItemDetail
                title={t('wallet.stakeVault.apy')}
                content={`${item?.stakeApyInPercentage}%`}
              />
              <HistoryListItemDetail
                title={t('wallet.stakeVault.lockInPeriodEndIn')}
                content={formatDate(+new Date(item?.stakeReleaseDate))}
              />
            </>
          )}
          {!!item?.stakeReward && (
            <HistoryListItemDetail
              title={t('wallet.includingReward')}
              content={
                <span
                  title={toTokenCentsString(
                    item?.stakeReward?.toString() || '0'
                  )}
                >
                  <Currency value={item?.stakeReward?.toString() || '0'} />
                </span>
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}

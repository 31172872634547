import { memo } from 'react'

const HomeComponent = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="stroke-white"
      d="M19 26V20C19 19.7348 18.8946 19.4804 18.7071 19.2929C18.5196 19.1053 18.2652 19 18 19H14C13.7348 19 13.4804 19.1053 13.2929 19.2929C13.1054 19.4804 13 19.7348 13 20V26C13 26.2652 12.8946 26.5196 12.7071 26.7071C12.5196 26.8946 12.2652 27 12 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V14.4375C5.00224 14.2991 5.03215 14.1626 5.08796 14.0359C5.14378 13.9092 5.22437 13.795 5.325 13.7L15.325 4.61249C15.5093 4.44384 15.7501 4.35031 16 4.35031C16.2499 4.35031 16.4907 4.44384 16.675 4.61249L26.675 13.7C26.7756 13.795 26.8562 13.9092 26.912 14.0359C26.9679 14.1626 26.9978 14.2991 27 14.4375V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H20C19.7348 27 19.4804 26.8946 19.2929 26.7071C19.1054 26.5196 19 26.2652 19 26Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const HomeOutlineComponent = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill-white stroke-white"
      d="M17.308 3.92127L17.3078 3.92113C16.9507 3.59436 16.4841 3.41315 16 3.41315C15.5159 3.41315 15.0494 3.59436 14.6922 3.92113L14.692 3.92127L4.69219 13.0086C4.69219 13.0086 4.69218 13.0086 4.69217 13.0087C4.49383 13.1901 4.33543 13.4108 4.22703 13.6568C4.11862 13.9028 4.0626 14.1687 4.06251 14.4375C4.06251 14.4375 4.06251 14.4375 4.06251 14.4375V25.95V25.951H4.0625C4.05462 26.4398 4.22714 26.9143 4.54707 27.2839C4.72852 27.4902 4.95202 27.6552 5.20255 27.768C5.45314 27.8807 5.72498 27.9385 5.99977 27.9375H6.00001H12C12.2486 27.9375 12.4871 27.8387 12.6629 27.6629C12.8387 27.4871 12.9375 27.2487 12.9375 27V21C12.9375 20.7182 13.0494 20.448 13.2487 20.2487C13.448 20.0495 13.7182 19.9375 14 19.9375H18C18.2818 19.9375 18.5521 20.0495 18.7513 20.2487C18.9506 20.448 19.0625 20.7182 19.0625 21V27C19.0625 27.2487 19.1613 27.4871 19.3371 27.6629C19.5129 27.8387 19.7514 27.9375 20 27.9375H26H26.0006C26.3216 27.9403 26.6381 27.8612 26.9201 27.7076C27.2273 27.5404 27.4838 27.2936 27.6628 26.9931C27.8418 26.6927 27.9367 26.3496 27.9375 25.9999V14.4375C27.9374 14.1687 27.8814 13.9028 27.773 13.6568C27.6646 13.4109 27.5062 13.1902 27.308 13.0088L17.308 3.92127ZM17.308 3.92127L27.3078 13.0086L17.308 3.92127Z"
      strokeWidth="0.125"
    />
  </svg>
)

export const Home = memo(HomeComponent)
export const HomeOutline = memo(HomeOutlineComponent)

import { signIn, signOut } from 'aws-amplify/auth'

export type AuthFlowType =
  | 'USER_SRP_AUTH'
  | 'CUSTOM_WITH_SRP'
  | 'CUSTOM_WITHOUT_SRP'
  | 'USER_PASSWORD_AUTH'

export async function authSignIn({
  username,
  authFlowType,
}: {
  username: string
  authFlowType: AuthFlowType
}) {
  return await signIn({
    username,
    options: {
      authFlowType,
    },
  })
}

export function authSignOut(options?: { global: boolean }) {
  return signOut(options)
}

import { memo } from 'react'
import { IonIcon } from '@ionic/react'
import { heart, heartOutline } from 'ionicons/icons'

interface IHeartEmoji {
  slot?: string
  className?: string
  isOutlined?: boolean
  color?: string
}

const HeartEmojiComponent = ({
  slot,
  className,
  isOutlined = false,
  color = 'plain',
}: IHeartEmoji) => {
  return (
    <IonIcon
      slot={slot}
      className={className}
      icon={isOutlined ? heartOutline : heart}
      color={color}
    />
  )
}

export const HeartEmoji = memo(
  HeartEmojiComponent,
  (prev, next) => !(prev.isOutlined !== next.isOutlined)
)

import { get } from '@/services/awsAmplify'
import { ChatStatus } from '@/lib/hooks/chat/useChat'

import { Badge, FollowStatus } from '@enums'
import { ISearchQuery } from '@/interfaces'

export interface IUser {
  displayName?: string
  avatar?: string
  username: string
  id: string
  followStatus?: FollowStatus
  avatarUrl?: string
  userId?: UUID
  isModerator?: boolean
  isAmbassador?: boolean
  badge?: Badge
  chat?: {
    status: ChatStatus
    price: number
  }
}

export type UserSearchFollowStatus = Extract<
  FollowStatus,
  | FollowStatus.Unknown
  | FollowStatus.Following
  | FollowStatus.Followed
  | FollowStatus.Friends
>

export type GetFindUsersPaginated = {
  lastKey?: string
  pages?: Record<'users' | 'lastKey', GetFindUsersResult>[]
}

type GetFindUsersResult = Record<Partial<UserSearchFollowStatus>, IUser[]>

export const getFindUsers = async (query: ISearchQuery, pageParam: string) => {
  let q = query.q
  if (query.q.length < 2) {
    q = ''
  }

  const params = Object.assign({ q }, pageParam ? { startAt: pageParam } : {})
  const result = (await get({
    path: '/users-search/autocomplete',
    params,
  })) as GetFindUsersResult

  return {
    users: result,
    lastKey: undefined,
  }
}

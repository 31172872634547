import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/Button'

import { useNavigate } from '@/lib/routing'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { Routes } from '@/router/routes'

import { SignInMethods } from '@/enums'

const SignInWithEmail: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')

  return (
    <Button
      fill="outline"
      color="primary"
      className="w-full z-10 h-[40px] mt-4"
      onClick={() => {
        navigate(Routes.AUTH_EMAIL)
        Tracking.triggerEvent(TrackingEvent.SigninMethodSelected, {
          authMethod: SignInMethods.Email,
        })
      }}
    >
      <p className="mt-0 mb-0 text-base">
        {t(`registration.signUpOptions.emailOption`)}
      </p>
    </Button>
  )
}

export default SignInWithEmail

import { useCallback, useState } from 'react'
import { sanitizeUrl } from '@braintree/sanitize-url'
import {
  BaseImage as DefaultBaseImage,
  ImageProps,
  Modal,
  useComponentContext,
} from 'stream-chat-react'

import { CustomModalGallery } from './CustomModalGallery'

export const CustomImage = (props: ImageProps) => {
  const {
    dimensions = {},
    fallback,
    image_url,
    thumb_url,
    innerRef,
    previewUrl,
    style,
  } = props

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { BaseImage = DefaultBaseImage } = useComponentContext('ImageComponent')
  const imageSrc = sanitizeUrl(previewUrl || image_url || thumb_url)

  const toggleModal = useCallback(
    () => setModalIsOpen((modalIsOpen) => !modalIsOpen),
    []
  )

  return (
    <>
      <BaseImage
        alt={fallback}
        className="str-chat__message-attachment--img"
        data-testid="image-test"
        onClick={toggleModal}
        src={imageSrc}
        style={style}
        tabIndex={0}
        title={fallback}
        {...dimensions}
        {...(innerRef && { ref: innerRef })}
      />
      <Modal onClose={toggleModal} open={modalIsOpen}>
        <CustomModalGallery
          images={[props]}
          index={0}
          toggleModal={toggleModal}
        />
      </Modal>
    </>
  )
}

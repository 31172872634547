import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/Button'
import { Currency } from '@/components/atoms/Currency'

import { CreateTransactionData } from '@/services/wallet/createTransaction'

type SummaryProps = {
  createTransactionData: CreateTransactionData
  dismissModal: () => void
}

export const Summary: FC<SummaryProps> = ({
  dismissModal,
  createTransactionData,
}) => {
  const { t } = useTranslation('common')
  const username = createTransactionData?.destination?.username || ''

  return (
    <div className="flex flex-col items-center text-white">
      <span className="mt-5">
        <Trans i18nKey="tip.tipHasBeenSent" values={{ username }}>
          {t('tip.tipHasBeenSent', { username })}
        </Trans>
      </span>
      <span className="text-[1.75rem] font-bold text-primary mt-4">
        <Currency
          value={String(
            BigInt(createTransactionData.amount) +
              BigInt(createTransactionData.fee)
          )}
        />
      </span>
      <span className="mt-3">
        {t('tip.labels.youWereCharged')}
        <span className="font-bold pl-1">
          <Currency value={createTransactionData?.amount} />
        </span>
      </span>
      <div className="mt-9 mb-5">
        <Button
          type="primary"
          className="w-[9.25rem]"
          size="small"
          onClick={dismissModal}
        >
          {t('tip.actions.close')}
        </Button>
      </div>
    </div>
  )
}

// Store all static RegExps in this file. Ensure each RegExp is compatible
// with older JS versions, especially those used in legacy browsers.
// Avoid features like negative lookbehind that may not be supported on older devices.
// To check backward compatibility, consider using external, online tools to
// avoid unexpected app crashes.

export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
export const usernamePattern = /^[a-z0-9_]{1}[a-z0-9._]*[a-z0-9_]{1}$/i
export const atUsernamePattern =
  /(?:^|\s)(@[a-z0-9_]{1}[a-z0-9._]*[a-z0-9_]{1})/i
export const multiplePeriodsPattern = /\.{2,}/
export const userMentionsPattern =
  /\[@[a-z0-9_]{1}[a-z0-9._]*[a-z0-9_]{1}](\((user:)([a-z0-9-]+)\))/g
export const urlPattern = /\b(?:(?:https?):\/\/|www\.)\S+\b/g
export const usernameAndIdMentionsPattern =
  /\[@([a-z0-9_]{1}[a-z0-9._]*[a-z0-9_]{1})\]\(user:([a-fA-F0-9-]+)\)/g
export const nameWithoutBlacklistedWordsPattern = /^(?:(?!republik|rpk).)*$/

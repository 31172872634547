import { useCallback, useEffect, useMemo, useRef } from 'react'
import ContentEditable from 'react-contenteditable'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isAddress } from 'web3-validator'

import Clipboard from '@/utils/clipboard'

import { FormValues } from '../../Withdraw'

export const EditWalletAddress = () => {
  const { t } = useTranslation('common')
  const {
    control,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext<FormValues>()
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const externalWalletId = watch('externalWalletId')

  useEffect(() => {
    if (!textareaRef.current) return

    textareaRef.current.style.height = 'auto'

    if (externalWalletId) {
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px'
    }
  }, [externalWalletId])

  const pasteFromClipboard = useCallback(async () => {
    const value = await Clipboard.read()

    setValue('externalWalletId', value)
    trigger('externalWalletId')
  }, [setValue, trigger])

  const errorMapping = useMemo(
    () => ({
      required: t('withdraw.errors.required'),
      isValidAddress: t('withdraw.errors.invalidWalletAddress'),
    }),
    [t]
  )

  return (
    <div className="mb-12">
      <div className="text-cool-gray-300 text-xs uppercase mb-2">
        {t('withdraw.walletAddress')}
      </div>
      <div className="flex flex-row">
        <Controller
          control={control}
          name="externalWalletId"
          rules={{
            required: true,
            validate: { isValidAddress: isAddress },
          }}
          render={({ field }) => (
            <ContentEditable
              html={field.value}
              className="flex flex-1 flex-grow focus:outline-none bg-transparent text-left text-sm border-b border-cool-gray-400 break-all"
              onChange={(e) => field.onChange(e.target.value)}
              tagName="div"
              onBlur={() => {
                if (window.scrollY > 0)
                  window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
              onPaste={(e) => {
                pasteFromClipboard()
                e.preventDefault()
              }}
            />
          )}
        />
        <span
          className="ml-2 text-white uppercase cursor-pointer"
          onClick={pasteFromClipboard}
        >
          {t('withdraw.paste')}
        </span>
      </div>
      {errors.externalWalletId && (
        <span className="text-danger text-xs font-bold">
          {errorMapping[errors.externalWalletId.type]}
        </span>
      )}
    </div>
  )
}

import { FC, MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { IonSpinner } from '@ionic/react'

interface MentionsSuggestionsBoxProps {
  className?: string
  suggestionsPortalHostRef: MutableRefObject<HTMLDivElement>
  isLoadingMentions: boolean
}

export const MentionsSuggestionsBox: FC<MentionsSuggestionsBoxProps> = ({
  className = '',
  suggestionsPortalHostRef,
  isLoadingMentions,
}) => {
  const { t } = useTranslation('common')

  return (
    <div
      ref={suggestionsPortalHostRef}
      className={`overflow-auto max-h-[40vh] ${className}`}
    >
      {isLoadingMentions && (
        <div className="flex h-[3rem] px-4 items-center">
          <IonSpinner name="circular" color="primary" />
          <span className="ml-3 text-sm text-white">
            {t('common.searching')}
          </span>
        </div>
      )}
    </div>
  )
}

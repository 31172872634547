import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons } from '@ionic/react'
import formatISO from 'date-fns/formatISO'
import { parse } from 'query-string'
import { useToggle } from 'usehooks-ts'

import { Share } from '@/components/atoms/assets/Share'
import { Chip } from '@/components/atoms/Chip'
import { Caption } from '@/components/molecules/Caption'
import { CommentsButton } from '@/components/molecules/CommentsButton'
import { ReactionButton } from '@/components/molecules/ReactionButton'
import { ReportButton } from '@/components/molecules/ReportButton'
import { ShareButton } from '@/components/molecules/ShareButton'
import { UserActivityButton } from '@/components/molecules/UserActivityButton'
import { ViewSwitchButton } from '@/components/molecules/ViewSwitchButton'
import { FeedActivityHeader } from '@/components/organisms/FeedActivity/FeedActivityHeader'
import { IActivityItem } from '@/components/pages/Activity/interfaces'

import { appConfig } from '@services/config'
import { useGetAvatar } from '@/lib/hooks/useGetAvatar'
import { useMediaContainer } from '@/lib/hooks/useMediaContainer'
import { useReaction } from '@/lib/hooks/useReaction'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useLocation, useNavigate } from '@/lib/routing'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { formatDistanceToNowDate } from '@/utils/date'
import { normalizedHistorySearch } from '@/utils/normalizedHistorySearch'
import { getSliderImage } from '@/utils/url'
import { safeCastUnknownToType } from '@/utils/utils'

import {
  ActivityFeedVerb,
  Badge,
  FeedSections,
  OwnReaction,
  Pages,
  ReportButtonType,
  Verb,
} from '@/enums'
import { IConversation, IUserWithoutMetadata } from '@/interfaces'

import { ConversationActivitySlider } from './ConversationActivitySlider'
import { Grid } from './Grid'
import { useConversationActivity } from './useConversationActivity'

export type IConversationActivityProps = IActivityItem & {
  commentView?: boolean
  startedConversationActor?: IActivityItem['actor']
  inViewport: (activityId: string) => void
  outViewport: (activityId: string) => void
  isActiveActivity: boolean
  index: number
  isScrollingFast?: boolean
  own_reactions: IActivityItem['own_reactions']
}

export const ConversationActivity: FC<IConversationActivityProps> = ({
  actor,
  object,
  id: activityId,
  verb,
  reaction_counts,
  replyId = '',
  commentView = false,
  startedConversationId,
  startedConversationActor,
  index,
  inViewport,
  outViewport,
  isActiveActivity = true,
  isScrollingFast,
  origin,
  foreign_id,
  recommendationId,
  own_reactions,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const query = parse(location.search)
  const isConversationReply = verb === Verb.RepliedToConversation
  const isReactionButtonVisible = useMemo(
    () => !isConversationReply || commentView,
    [commentView, isConversationReply]
  )

  const conversationObjectData = useMemo(
    () => (object.data || {}) as IConversation,
    [object.data]
  )

  const conversationReplyId = useMemo(
    () => (commentView ? replyId || (query.replyId as string) : replyId),
    [replyId, query.replyId, commentView]
  )
  const reactionActivityId = (startedConversationId || activityId) as string

  const [isTouchStarted, setIsTouchStarted] = useState(false)
  const [isTouchMoved, setIsTouchMoved] = useState(false)
  const [replyIndex, setReplyIndex] = useState<number>(null)
  const [isGridView, toggleGridView] = useToggle(false)
  const [isZooming, setIsZooming] = useState(false)

  const { initialSlide, containerHeight, handleShareClick, isCurrentSlide } =
    useConversationActivity({
      activityId,
      conversationReplyId,
      object,
      replyIndex,
    })

  useEffect(() => {
    setReplyIndex(initialSlide)
  }, [initialSlide, commentView, conversationReplyId])

  const { data: authUser } = useUserInfo()
  const { t } = useTranslation('common')
  const getAvatar = useGetAvatar()
  const { reaction, addReaction, deleteReaction } = useReaction(
    reactionActivityId,
    {
      own_reactions,
      reaction_counts,
    }
  )
  const hasOwnReactions = reaction?.ownReactions?.length > 0
  const isSubscriptionContent = object?.data?.isSubscriptionContent

  const slideSwitch = (index: number) => {
    setReplyIndex(index)
    toggleGridView()
  }

  useEffect(() => {
    if (!commentView) return
    const replyId = object?.data?.replies?.[replyIndex - 1]?.id
    const { search = '' } = location
    const searchParams = normalizedHistorySearch({
      replyId,
      replyObjectId: object?.data?.id,
      createdAt: object?.data?.createdAt,
      replyIndex,
      search,
    })
    navigate(
      {
        search: new URLSearchParams(
          searchParams as unknown as string
        ).toString(),
      },
      {
        replace: true,
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentView, replyIndex, object?.data, location?.search, navigate])

  const trackReaction = useCallback(() => {
    Tracking.triggerEvent(TrackingEvent.ConversationLiked, {
      conversationId: reactionActivityId,
    })
    Tracking.triggerEvent(TrackingEvent.PersonalizeLike, {
      ...(recommendationId ? { recommendationId } : {}),
      itemId: reactionActivityId,
      properties: JSON.stringify({
        ITEM_ID: reactionActivityId,
      }),
    })
  }, [reactionActivityId, recommendationId])

  const handleDoubleClick = useCallback(async () => {
    if (hasOwnReactions || reaction?.isLoading) return

    addReaction({ activityId: reactionActivityId })

    trackReaction()
  }, [
    hasOwnReactions,
    reaction?.isLoading,
    addReaction,
    reactionActivityId,
    trackReaction,
  ])

  const handleReactionClick = useCallback(async () => {
    if (hasOwnReactions) {
      const reactionId = reaction?.ownReactions?.[0]?.id as string | undefined

      deleteReaction({ reactionId })
      Tracking.triggerEvent(TrackingEvent.LikeDeleted, {
        activityId: reactionActivityId,
        contentId: object?.data?.id,
        contentType: conversationReplyId ? 'reply' : 'conversation',
        timestamp: formatISO(new Date()),
      })

      return
    }

    addReaction({ activityId: reactionActivityId })
    trackReaction()
  }, [
    hasOwnReactions,
    reaction?.ownReactions,
    reactionActivityId,
    addReaction,
    conversationReplyId,
    deleteReaction,
    object?.data?.id,
    trackReaction,
  ])

  const { inView, mediaContainerRef, paddingBottom } = useMediaContainer({
    ratio: conversationObjectData.imageAspectRatio,
    index,
  })

  const getActor = useCallback((): IUserWithoutMetadata => {
    switch (verb) {
      case Verb.StartedConversation:
        return {
          ...actor,
          data: {
            image: safeCastUnknownToType<string>(actor.data?.profileImage),
            name: safeCastUnknownToType<string>(actor.data?.name),
            isAmbassador: safeCastUnknownToType<boolean>(
              actor?.data?.isAmbassador
            ),
            isModerator: safeCastUnknownToType<boolean>(
              actor?.data?.isModerator
            ),
            badge: safeCastUnknownToType<Badge>(actor?.data?.badge),
          },
        }
      case Verb.RepliedToConversation:
        return {
          id: conversationObjectData.userId,
          data: {
            image: getAvatar(conversationObjectData.userId),
            name: conversationObjectData.username,
            isAmbassador: safeCastUnknownToType<boolean>(
              startedConversationActor?.data?.isAmbassador
            ),
            isModerator: safeCastUnknownToType<boolean>(
              startedConversationActor?.data?.isModerator
            ),
            badge: safeCastUnknownToType<Badge>(
              startedConversationActor?.data?.badge
            ),
          },
        }
    }
  }, [
    actor,
    conversationObjectData.userId,
    conversationObjectData.username,
    startedConversationActor?.data?.badge,
    startedConversationActor?.data?.isAmbassador,
    startedConversationActor?.data?.isModerator,
    verb,
    getAvatar,
  ])

  useEffect(() => {
    if (inView) {
      inViewport && inViewport(activityId)
      return
    }
    outViewport && outViewport(activityId)
  }, [inView, isActiveActivity, activityId, inViewport, outViewport])

  const currentSlide = useMemo(
    () => replyIndex ?? initialSlide,
    [replyIndex, initialSlide]
  )
  const computedReportType =
    verb === Verb.RepliedToConversation
      ? ReportButtonType.ConversationReply
      : ReportButtonType.Conversation

  const isOwnReply = useMemo(() => {
    return object.data?.replies?.[currentSlide - 1]?.userId === authUser?.id
  }, [authUser?.id, object.data?.replies, currentSlide])

  const isOwnConversation = useMemo(() => {
    return object.data?.userId === authUser?.id
  }, [authUser?.id, object.data?.userId])

  const isReply = useMemo(() => {
    return !!object.data?.replies?.[currentSlide - 1]
  }, [object.data?.replies, currentSlide])

  const onUserActivityButtonSuccess = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const renderActionButtons = useCallback(() => {
    if (isOwnConversation || isOwnReply) {
      return (
        <UserActivityButton
          type={
            isReply
              ? ReportButtonType.ConversationReply
              : ReportButtonType.Conversation
          }
          conversation={{ data: conversationObjectData }}
          activityId={activityId}
          userId={authUser?.id}
          replyId={conversationReplyId}
          onSuccess={onUserActivityButtonSuccess}
          isOwnConversation={isOwnConversation}
          isOwnReply={isOwnReply}
        />
      )
    }

    return (
      <ReportButton
        type={computedReportType}
        confirmationLabel={t('okay')}
        contestId={null}
        activityId={activityId}
        userId={actor.id}
        isDisabled={actor.id === authUser?.id}
      />
    )
  }, [
    computedReportType,
    isOwnConversation,
    isReply,
    t,
    activityId,
    actor.id,
    authUser?.id,
    isOwnReply,
    conversationObjectData,
    conversationReplyId,
    onUserActivityButtonSuccess,
  ])

  return (
    <div>
      <FeedActivityHeader
        activityId={`conversation?replyObjectId=${object?.data?.id}&createdAt=${object?.data?.createdAt}&replyId=${replyId}`}
        verb={verb}
        actor={getActor()}
        object={{ data: conversationObjectData }}
        isReactingOnTouch={true}
        isTouchStarted={
          replyIndex == null ? true : isTouchStarted && isTouchMoved
        }
        currentSlide={currentSlide}
        origin={origin}
      >
        <div className="relative !z-0">
          <IonButtons>{renderActionButtons()}</IonButtons>
        </div>
      </FeedActivityHeader>
      <div className="relative bg-cool-gray-950">
        {!!object.data?.replies.length && !isZooming && (
          <div className="p-3 absolute right-0 top-0 z-20 flex items-center">
            <Chip className="default h-6 mr-2">
              {!isGridView && `${replyIndex + 1} / `}
              {object.data?.replies.length + 1}
            </Chip>
            {object.data?.replies.length >= 6 && (
              <ViewSwitchButton
                type={isGridView ? 'grid' : 'slider'}
                onClick={() => {
                  toggleGridView()
                }}
              />
            )}
          </div>
        )}
        <div
          className="h-full w-full grow bg-cover"
          ref={mediaContainerRef}
          style={{ paddingBottom }}
        >
          <div className="absolute left-1/2 top-1/2 !w-full h-auto object-contain -translate-y-1/2 -translate-x-1/2 !z-10">
            {isGridView ? (
              <Grid
                containerHeight={containerHeight}
                object={{ data: conversationObjectData }}
                slideSwitch={slideSwitch}
              />
            ) : (
              <ConversationActivitySlider
                setReplyIndex={setReplyIndex}
                index={index}
                initialSlide={currentSlide}
                conversationReplyId={conversationReplyId}
                conversation={conversationObjectData}
                handleDoubleClick={handleDoubleClick}
                isCurrentSlide={isCurrentSlide}
                isActiveActivity={isActiveActivity}
                containerHeight={containerHeight}
                isScrollingFast={isScrollingFast}
                activityId={activityId}
                paddingBottom={paddingBottom}
                isLikeActive={!isReactionButtonVisible || hasOwnReactions}
                isTouchMoved={isTouchMoved}
                isTouchStarted={isTouchStarted}
                setIsTouchMoved={setIsTouchMoved}
                setIsTouchStarted={setIsTouchStarted}
                inView={inView}
                startedConversationId={reactionActivityId}
                commentView={commentView}
                isZooming={isZooming}
                setIsZooming={setIsZooming}
                foreignId={foreign_id}
                recommendationId={recommendationId}
              />
            )}
          </div>
        </div>
      </div>
      <div className="bg-dark px-3 pt-3 relative !z-0">
        <Caption
          actor={getActor()}
          object={{ data: conversationObjectData }}
          subCaption={
            isGridView ? null : object.data?.replies?.[currentSlide - 1]
          }
        />
      </div>
      <div className="flex justify-between items-center pl-3 pb-3 pt-2 relative !z-0">
        <span
          className={`block text-xs opacity-50 pb-3 ${
            object.data?.replies[currentSlide - 1] && !isGridView ? 'ml-8' : ''
          }`}
        >
          {formatDistanceToNowDate({
            timestamp:
              object.data?.replies[currentSlide - 1]?.createdAt && !isGridView
                ? object.data?.replies[currentSlide - 1]?.createdAt
                : object.created_at,
            addSuffix: true,
            useUTC: false,
          })}
        </span>
        <IonButtons className="items-start">
          {isReactionButtonVisible && (
            <ReactionButton
              onClick={handleReactionClick}
              likesNumberPosition="right"
              reactionActivityId={reactionActivityId}
              own_reactions={own_reactions as OwnReaction[]}
              reaction_counts={reaction_counts}
            />
          )}
          {!commentView && (
            <CommentsButton
              color="plain"
              activityId={`conversation?replyObjectId=${object?.data?.id}&createdAt=${object?.data?.createdAt}&replyId=${replyId}`}
              commentsNumber={reaction_counts?.COMMENT}
              commentsNumberPosition="right"
              page={Pages.Feed}
              channel={FeedSections.Conversation}
            />
          )}
          {!isSubscriptionContent && (
            <ShareButton
              className="flex justify-center !p-0 cursor-pointer"
              subject={conversationObjectData.title}
              message={t('conversation.startedBy', {
                userName: actor?.data?.name,
              })}
              url={`${appConfig.origin}/comments/${reactionActivityId}${
                conversationReplyId ? `?replyId=${conversationReplyId}` : ''
              }`}
              buttonLabel={<Share className="cursor-pointer" />}
              imageUrl={getSliderImage(conversationObjectData.imageUrl)}
              feature={ActivityFeedVerb.StartedConversation}
              params={{ refUsername: String(authUser?.username) }}
              onClickShare={handleShareClick}
            />
          )}
        </IonButtons>
      </div>
    </div>
  )
}

import { FC, memo, MutableRefObject } from 'react'
import {
  GridComponents,
  GridItemContent,
  ScrollSeekConfiguration,
  VirtuosoGrid,
  VirtuosoGridHandle,
} from 'react-virtuoso'

import { useNetwork } from '@/lib/hooks/useNetwork'

interface IVirtualGridProps {
  data: string[]
  className?: string
  itemClassName?: string
  endReached: (index: number) => void
  components: GridComponents
  itemContent: GridItemContent<string, number>
  virtuosoKey?: string
  overscan?: number | { main: number; reverse: number }
  virtuosoRef?: MutableRefObject<VirtuosoGridHandle>
  scrollSeekConfiguration?: ScrollSeekConfiguration | false
  atTopStateChange?: (atTop: boolean) => void
}

const VirtualGridComponent: FC<IVirtualGridProps> = ({
  data,
  className = '',
  itemClassName = '',
  endReached,
  components,
  itemContent,
  virtuosoKey,
  overscan = 0,
  virtuosoRef = null,
  scrollSeekConfiguration,
  atTopStateChange,
}) => {
  const { isOnline } = useNetwork()

  return (
    <VirtuosoGrid
      key={virtuosoKey}
      atTopStateChange={atTopStateChange}
      data={data}
      className={className}
      itemClassName={itemClassName}
      endReached={isOnline && endReached}
      overscan={overscan}
      components={components}
      itemContent={itemContent}
      ref={virtuosoRef}
      scrollSeekConfiguration={scrollSeekConfiguration}
    />
  )
}

export const VirtualGrid = memo(VirtualGridComponent)

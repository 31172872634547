import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons } from '@ionic/react'

import { MarkdownEllipsis } from '@/components/atoms/MarkdownEllipsis'
import { ReportButton } from '@/components/molecules/ReportButton'
import { VideoContainer } from '@/components/molecules/VideoContainer'
import { FeedActivityHeader } from '@/components/organisms/FeedActivity/FeedActivityHeader'

import { IContestDiscoveryFeedActivityType } from '@services/contestDiscoveryFeed'
import { useImageSize } from '@/lib/hooks/useImageSize'
import { useMediaContainer } from '@/lib/hooks/useMediaContainer'
import { useTrackViewedActivity } from '@/lib/hooks/useTrackViewedActivity'
import { MediaType } from '@/lib/hooks/useTrackViewedActivity/useTrackViewedActivity'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { TrackingEvent } from '@/lib/tracking'
import { formatDistanceToNowDate } from '@/utils/date'
import { actorMarkdown } from '@/utils/utils'

import { ActivityFeedVerb, ReportButtonType } from '@enums'

export const ContestJoinedFeedActivity: FC<
  IContestDiscoveryFeedActivityType
> = ({
  actor,
  contestId,
  id: videoId,
  object,
  verb,
  video,
  videoPreview,
  origin,
  index,
  inViewport,
  outViewport,
  isActiveActivity,
  isScrollingFast,
  foreign_id,
}) => {
  const contentContainerRef = useRef<HTMLDivElement>()
  const { ratio, getRatio } = useImageSize()
  const [videoLoadedMilliseconds, setVideoLoadedMilliseconds] = useState(0)

  useEffect(() => {
    if (object?.data?.imageAspectRatio) return
    getRatio(videoPreview)
  }, [videoPreview, object?.data?.imageAspectRatio, getRatio])

  const { data: authUser } = useUserInfo()
  const { t } = useTranslation('common')
  const { inView, mediaContainerRef, paddingBottom } = useMediaContainer({
    ratio: object?.data?.imageAspectRatio
      ? object?.data?.imageAspectRatio
      : ratio,
    index,
  })

  useTrackViewedActivity({
    inViewport: inView,
    eventName: TrackingEvent.FeedItemViewed,
    activityId: contestId,
    itemId: contestId,
    eventProperties: {
      challengeId: contestId,
      challengeName: object?.data?.name,
      videoLoadedDuration: videoLoadedMilliseconds,
      mediaType: MediaType.Video,
    },
    contentType: 'challenge',
    foreignId: foreign_id,
  })

  const renderVideo = useCallback(
    ({ video, videoPreview }) => (
      <VideoContainer
        className="-mx-2.5 !h-full"
        video={video}
        videoPreview={videoPreview}
        isPlaying={isActiveActivity}
        videoAllowedToLoad={!isScrollingFast}
        onVideoLoadedMilliseconds={onVideoLoadedMilliseconds}
      />
    ),
    [isActiveActivity, isScrollingFast]
  )

  useEffect(() => {
    if (inView) {
      inViewport(videoId)
      return
    }
    outViewport(videoId)
  }, [inView, isActiveActivity, videoId, inViewport, outViewport])

  const onVideoLoadedMilliseconds = (milliseconds: number) =>
    setVideoLoadedMilliseconds(milliseconds)

  return (
    <div
      ref={contentContainerRef}
      data-e2e-feed
      className="bg-grey-800 relative !z-0"
    >
      <FeedActivityHeader
        contestName={object?.data?.name}
        contestId={contestId}
        verb={verb}
        actor={actor}
        contest={object?.data}
        name={actor.data?.name}
        object={object}
        origin={origin}
      >
        {verb === ActivityFeedVerb.ContestCreate && actor.id !== authUser?.id && (
          <div>
            <IonButtons>
              <ReportButton
                type={ReportButtonType.Post}
                confirmationLabel={t('okay')}
                contestId={contestId}
                activityId={videoId}
                userId={actor.id}
                isDisabled={
                  verb !== ActivityFeedVerb.ContestCreate &&
                  actor.id === authUser?.id
                }
              />
            </IonButtons>
          </div>
        )}
      </FeedActivityHeader>
      <div className="py-2">
        <div
          ref={mediaContainerRef}
          className="relative z-10 grow bg-cover bg-no-repeat mx-2.5"
          style={{ paddingBottom }}
        >
          <div className="absolute left-1/2 top-1/2 !w-full h-full object-contain -translate-y-1/2 -translate-x-1/2 rounded-[0.625rem] overflow-hidden">
            {renderVideo({
              video,
              videoPreview,
            })}
          </div>
        </div>
      </div>
      <div className="bg-dark px-3 pb-3">
        <div className="text-sm font-normal text-cool-gray-200 min-h-min wordWrap-break">
          <MarkdownEllipsis
            text={`${actorMarkdown({
              name: actor.data?.name,
              url: `/@${actor.data?.name}`,
              isAmbassador: actor.data?.isAmbassador,
              isModerator: actor.data?.isModerator,
            })} ${object?.data?.description}`}
          />
        </div>
        <div className="flex justify-between items-center py-3">
          <span className="block text-xs opacity-50">
            {formatDistanceToNowDate({
              timestamp: String(object?.data?.startDate),
              addSuffix: true,
            })}
          </span>
        </div>
      </div>
    </div>
  )
}

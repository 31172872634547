import React, { FC } from 'react'
import cx from 'classnames'

interface ITimelineItem {
  title: string
  value: React.ReactElement
}
interface ITimelineContainer {
  content: ITimelineItem[]
}

export const TimelineContainer: FC<ITimelineContainer> = ({ content = [] }) => {
  return (
    <ol className="my-3 ml-1 relative border-s border-white">
      {content.map((item, index) => {
        return (
          <li
            key={index}
            className={cx('mb-2 mt-2 ms-3', {
              '!-mt-2': index === 0,
              '!-mb-2': index === content.length - 1,
            })}
          >
            <div className="absolute w-2 h-2 bg-white rounded-full mt-2 -start-[0.2813rem] border border-white"></div>
            <div className="flex flex-1 justify-between sm:text-base font-medium">
              <span className="text-cool-gray-200 ">{item.title}</span>
              <span className="notranslate">{item.value}</span>
            </div>
          </li>
        )
      })}
    </ol>
  )
}

import { FC } from 'react'

import { VirtualList } from '@/components/organisms/VirtualList'

import { IStakeVaultTransactionItem } from '@/services/stake/getStakeTransactions'

import { HistoryListStakeVaultItem } from './HistoryListStakeVaultItem'

interface StakingVaultHistoryProps {
  transactions: IStakeVaultTransactionItem[]
  atTopStateChange: (isAtTop: boolean) => void
  onEndReached: (e: unknown) => NodeJS.Timeout
}

export const StakingVaultHistory: FC<StakingVaultHistoryProps> = ({
  transactions,
  atTopStateChange,
  onEndReached,
}) => {
  return (
    <div className="flex flex-col h-full text-left w-full mx-auto max-w-[40rem]">
      <VirtualList
        components={{
          Header: () => <></>,
        }}
        className="w-full"
        data={transactions}
        itemContent={(index) => {
          return (
            <div className="px-4 w-full flex flex-col">
              <HistoryListStakeVaultItem
                isStakeVault
                key={transactions[index].stakeDate}
                item={transactions[index]}
              />
            </div>
          )
        }}
        atTopStateChange={atTopStateChange}
        endReached={onEndReached}
      />
    </div>
  )
}

import { useUserInfo } from '@/lib/hooks/useUserInfo'

import { Avatar } from './Avatar'

export const OwnAvatar = () => {
  const { data: authUser } = useUserInfo()

  return (
    <Avatar
      userId={authUser.id}
      username={authUser.username}
      isModerator={authUser.isModerator}
      isAmbassador={authUser.isAmbassador}
      badge={authUser.badge}
    />
  )
}

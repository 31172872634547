import { FC, useMemo } from 'react'
import { IonPopover } from '@ionic/react'
import uniqueId from 'lodash/uniqueId'

import { InfoIcon } from '@/components/atoms/assets/InfoIcon'

type IconWithTooltipType = {
  tooltipText: string
  className: string
}

export const IconWithTooltip: FC<IconWithTooltipType> = ({
  tooltipText,
  className,
}) => {
  const uuid = useMemo(() => uniqueId(), [])

  return (
    <>
      <div id={uuid} className="inline cursor-pointer">
        <InfoIcon className="inline w-5 h-5" />
      </div>
      <IonPopover
        className={className}
        arrow={false}
        trigger={uuid}
        triggerAction="click"
        size="cover"
        reference="trigger"
      >
        <span className="ion-padding text-sm">{tooltipText}</span>
      </IonPopover>
    </>
  )
}

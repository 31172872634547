import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons } from '@ionic/react'
import { Navigation, Pagination, Virtual } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ReportButton } from '@components/molecules/ReportButton'
import { UnlockExclusiveContentButton } from '@components/molecules/UnlockExclusiveContentButton'
import { UserActivityButton } from '@components/molecules/UserActivityButton'
import { Avatar } from '@/components/atoms/Avatar'
import { ExclusiveButton } from '@/components/atoms/ExclusiveButton'
import { UserName } from '@/components/atoms/UserName'
import { LazyImg } from '@/components/molecules/LazyImg'
import { TapToLikeWrapper } from '@/components/molecules/TapToLikeWrapper'
import { IActivityItem } from '@/components/pages/Activity/interfaces'
import { SendTipButton } from '@/components/pages/UserProfile/components'

import { useMediaContainer } from '@/lib/hooks/useMediaContainer'
import { useReaction } from '@/lib/hooks/useReaction'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Link, useNavigate } from '@/lib/routing'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import {
  calculateContainerHeight,
  getMaxViewportWidth,
  safeCastUnknownToType,
} from '@/utils/utils'
import { getFirstAvailableRatio } from './utils'

import { Badge, ReportButtonType } from '@enums'

import { PostFeedDetail } from './PostFeedDetail'
import { PostFeedHeader } from './PostFeedHeader'
import { PostFeedSlider } from './PostFeedSlider'

export type IPostFeedActivityProps = IActivityItem & {
  attachments?: {
    images?: string[]
  }
  text?: string
  commentView?: boolean
  inViewport: (activityId: string) => void
  outViewport: (activityId: string) => void
  isActiveActivity: boolean
  index: number
  isScrollingFast?: boolean
}

export const PostFeedActivity: FC<IPostFeedActivityProps> = ({
  actor,
  attachments,
  text,
  time,
  verb,
  reaction_counts,
  object,
  id,
  commentView,
  inViewport,
  outViewport,
  isActiveActivity = true,
  index,
  isScrollingFast,
  origin,
  foreign_id,
  recommendationId,
  isSubscriptionTeaser,
  own_reactions,
}) => {
  const { t } = useTranslation('common')
  const { data: authUser } = useUserInfo()
  const { reaction, addReaction, deleteReaction } = useReaction(id, {
    reaction_counts,
    own_reactions,
  })

  const hasOwnReactions = reaction?.ownReactions?.length > 0
  const [isZooming, setIsZooming] = useState(false)
  const containerRatio = useMemo(
    () => getFirstAvailableRatio(object?.data?.media),
    [object?.data?.media]
  )

  const isOwnContent = useMemo(
    () => actor?.id === authUser?.id,
    [actor?.id, authUser?.id]
  )

  const actorHasExclusiveContent = actor?.data?.hasExclusiveContent
  const navigate = useNavigate()

  const containerHeight = useMemo(
    () => calculateContainerHeight(containerRatio, getMaxViewportWidth()),
    [containerRatio]
  )

  const trackReaction = useCallback(() => {
    Tracking.triggerEvent(TrackingEvent.PostLiked, {
      postId: id,
    })
    Tracking.triggerEvent(TrackingEvent.PersonalizeLike, {
      ...(recommendationId ? { recommendationId } : {}),
      itemId: id,
      eventValue: 0,
      properties: JSON.stringify({
        ITEM_ID: id,
      }),
    })
  }, [id, recommendationId])

  const handleDoubleClick = useCallback(async () => {
    if (hasOwnReactions || reaction?.isLoading) return

    addReaction({ activityId: id })
    trackReaction()
  }, [addReaction, hasOwnReactions, id, reaction, trackReaction])

  const handleGoToComments = useCallback(() => {
    if (!window.location.href.includes(`/comments/${id}`)) {
      navigate(`/comments/${id}`)
    }
  }, [id, navigate])

  const { inView, mediaContainerRef, paddingBottom } = useMediaContainer({
    ratio: containerRatio,
    index,
  })

  const onUserActivityButtonSuccess = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const renderImage = useCallback(
    () =>
      attachments?.images?.length > 0 && (
        <div className="relative">
          <header className="w-full py-3 flex justify-start items-center">
            <Link to={`/@${actor?.data?.name}`} className="flex-none ml-3">
              <Avatar
                username={actor?.data?.name as string}
                userId={actor.id}
                isModerator={safeCastUnknownToType<boolean>(
                  actor?.data?.isModerator
                )}
                isAmbassador={safeCastUnknownToType<boolean>(
                  actor?.data?.isAmbassador
                )}
                badge={safeCastUnknownToType<Badge>(actor?.data?.badge)}
              />
            </Link>
            <div className="font-bold ml-2.5 grow">
              <Link to={`/@${actor?.data?.name}`}>
                <UserName
                  name={safeCastUnknownToType<string>(actor?.data?.name)}
                />
              </Link>
            </div>
            <div className="flex justify-end items-center">
              {!actorHasExclusiveContent && !isSubscriptionTeaser && (
                <ExclusiveButton username={actor?.data?.name as string} />
              )}
              <IonButtons>
                {actor?.id !== authUser?.id ? (
                  <ReportButton
                    type={ReportButtonType.Post}
                    confirmationLabel={t('okay')}
                    contestId={null}
                    activityId={id}
                    userId={actor?.id}
                    isDisabled={actor?.id === authUser?.id}
                  />
                ) : (
                  <UserActivityButton
                    type={ReportButtonType.Post}
                    activityId={id}
                    userId={authUser?.id}
                    onSuccess={onUserActivityButtonSuccess}
                  />
                )}
              </IonButtons>
            </div>
          </header>
          {attachments?.images?.length > 1 ? (
            <Swiper
              style={{ overflowY: 'visible' }}
              modules={[Navigation, Pagination, Virtual]}
              direction="horizontal"
              className="feed"
              navigation
              pagination={!isZooming && { clickable: false }}
              virtual
            >
              {attachments?.images?.map((image, index: number) => (
                <SwiperSlide
                  key={image}
                  className={index === 0 ? 'bg-cool-gray-900' : 'bg-black'}
                  virtualIndex={index}
                >
                  <TapToLikeWrapper
                    handleDoubleClick={handleDoubleClick}
                    handleSingleClick={handleGoToComments}
                    isLikeActive={hasOwnReactions}
                  >
                    <div className="cursor-pointer">
                      <LazyImg
                        isLazy={false}
                        src={image}
                        imageAspectRatio={containerRatio}
                        setIsZooming={setIsZooming}
                      />
                    </div>
                  </TapToLikeWrapper>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className="relative grow bg-no-repeat bg-center flex flex-col overflow-hidden cursor-pointer">
              <TapToLikeWrapper
                handleDoubleClick={handleDoubleClick}
                handleSingleClick={handleGoToComments}
                isLikeActive={hasOwnReactions}
              >
                <LazyImg
                  isLazy={false}
                  src={attachments?.images?.[0]}
                  imageAspectRatio={containerRatio}
                  setIsZooming={setIsZooming}
                />
              </TapToLikeWrapper>
            </div>
          )}
          {!isOwnContent && (
            <div className="absolute left-1 bottom-1 w-20 h-4 z-[9999] bg-red-500">
              <SendTipButton userId={actor?.id} initialAmount={1} />
            </div>
          )}
        </div>
      ),
    [
      attachments?.images,
      actor?.data?.name,
      actor?.data?.isModerator,
      actor?.data?.isAmbassador,
      actor?.data?.badge,
      actor?.id,
      actorHasExclusiveContent,
      isSubscriptionTeaser,
      authUser?.id,
      t,
      id,
      onUserActivityButtonSuccess,
      isZooming,
      handleDoubleClick,
      handleGoToComments,
      hasOwnReactions,
      containerRatio,
      isOwnContent,
    ]
  )

  const renderMedia = useCallback(() => {
    return (
      <div
        className="h-full w-full grow bg-cover relative"
        style={{ paddingBottom }}
      >
        {isSubscriptionTeaser && (
          <SubscriptionTeaserOverlay userName={actor?.data?.name} />
        )}

        <div className="absolute left-1/2 top-1/2 !w-full h-auto object-contain -translate-y-1/2 -translate-x-1/2">
          <PostFeedSlider
            media={object?.data?.media}
            containerRatio={containerRatio}
            isLikeActive={hasOwnReactions}
            handleDoubleClick={handleDoubleClick}
            inView={inView}
            paddingBottom={paddingBottom}
            isActiveActivity={isActiveActivity}
            containerHeight={containerHeight}
            mediaContainerRef={mediaContainerRef}
            activityId={id}
            itemId={object?.id}
            isScrollingFast={isScrollingFast}
            isZooming={isZooming}
            setIsZooming={setIsZooming}
            foreignId={foreign_id}
            recommendationId={recommendationId}
            isSubscriptionContent={object.data?.isSubscriptionContent}
          />
          {!isOwnContent && (
            <div className="flex absolute bottom-0 w-full h-20 z-10 items-end pb-4 pl-4 bg-gradient-to-b from-transparent to-black/60">
              <SendTipButton userId={actor?.id} initialAmount={1} />
            </div>
          )}
        </div>
      </div>
    )
  }, [
    paddingBottom,
    object,
    containerRatio,
    foreign_id,
    hasOwnReactions,
    handleDoubleClick,
    inView,
    isActiveActivity,
    containerHeight,
    mediaContainerRef,
    id,
    isScrollingFast,
    isZooming,
    recommendationId,
    isOwnContent,
    isSubscriptionTeaser,
    actor?.data?.name,
    actor?.id,
  ])

  const renderHeader =
    !attachments?.images?.length ||
    (object?.data?.media?.length && (
      <PostFeedHeader
        actor={actor}
        origin={origin}
        object={object}
        id={id}
        isSubscriptionTeaser={isSubscriptionTeaser}
        showExclusiveButton
      />
    ))

  useEffect(() => {
    if (inView) {
      inViewport && inViewport(id)
      return
    }

    outViewport && outViewport(id)
  }, [inView, isActiveActivity, id, inViewport, outViewport])

  return (
    <div>
      {renderHeader}
      <div className="relative">
        {typeof object === 'string' ? renderImage() : renderMedia()}
      </div>
      <PostFeedDetail
        actor={actor}
        attachments={attachments}
        text={text}
        time={time}
        verb={verb}
        reaction_counts={reaction_counts}
        own_reactions={own_reactions}
        object={object}
        id={id}
        commentView={commentView}
        recommendationId={recommendationId}
        showActionButtons
        hasOwnReactions={hasOwnReactions}
        reaction={reaction}
        addReaction={addReaction}
        deleteReaction={deleteReaction}
      />
    </div>
  )
}

interface SubscriptionTeaserOverlayProps {
  userName?: string | unknown
}

function SubscriptionTeaserOverlay({
  userName,
}: SubscriptionTeaserOverlayProps) {
  return (
    <div className="absolute inset-1/2 !z-10 flex justify-center items-center">
      <UnlockExclusiveContentButton userName={userName} />
    </div>
  )
}

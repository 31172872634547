export type ReactionOptions = Array<{
  Component: React.ComponentType
  type: string
  name?: string
}>

const LoveReaction = () => <>❤️</>
const ThumbsUpReaction = () => <>👍</>
const ThumbsDownReaction = () => <>👎</>
const JoyReaction = () => <>😂</>
const ScreamReaction = () => <>😱</>

export const customReactionOptions: ReactionOptions = [
  {
    type: 'love',
    Component: () => <LoveReaction />,
    name: 'Heart',
  },
  {
    type: 'like',
    Component: () => <ThumbsUpReaction />,
    name: 'Thumbs up',
  },
  {
    type: 'dislike',
    Component: () => <ThumbsDownReaction />,
    name: 'Thumbs down',
  },
  {
    type: 'haha',
    Component: () => <JoyReaction />,
    name: 'Joy',
  },
  {
    type: 'scream',
    Component: () => <ScreamReaction />,
    name: 'Scream',
  },
]

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Currency } from '@/components/atoms/Currency'

interface MinimumDepositValueProps {
  value: string
}

export const MinimumDepositValue: FC<MinimumDepositValueProps> = ({
  value = '0',
}) => {
  const { t } = useTranslation('common')

  return (
    <div className="flex justify-between items-center text-sm">
      <span>{t('deposit.minimumValue')}</span>
      <span className="font-bold">
        <Currency value={value} />
      </span>
    </div>
  )
}

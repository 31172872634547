import React, { FC } from 'react'
import { IonItem, IonLabel } from '@ionic/react'

interface InfoSectionProps {
  title: string
  subTitle: string
  children?: React.ReactNode
}

export const InfoSection: FC<InfoSectionProps> = ({
  title,
  subTitle,
  children,
}) => {
  return (
    <>
      <IonItem>
        <IonLabel className="ion-text-wrap">
          <div className="font-bold text-lg mb-2">{title}</div>
          <span className="text-cool-gray-200 text-sm sm:text-base">
            {subTitle}
          </span>
        </IonLabel>
      </IonItem>
      {children}
    </>
  )
}

import { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react'

import { BuyCurrencyPopup } from '../BuyCurrencyPopup'
import { DepositModal } from '../DepositModal'
import { DepositModalRef } from '../DepositModal/DepositModal'

interface DepositCurrencyProps {
  title?: string
}

export interface DepositCurrencyRef {
  openModal: () => void
}

const DepositCurrencyComponent = forwardRef(
  ({ title = '' }: DepositCurrencyProps, ref) => {
    const [isBuyCurrencyConfirmationOpen, setBuyCurrencyConfirmationOpen] =
      useState(false)

    const depositModalRef = useRef<DepositModalRef>()

    useImperativeHandle(ref, () => ({
      openModal: () => depositModalRef.current.openModal(),
    }))

    return (
      <>
        <DepositModal
          ref={depositModalRef}
          title={title}
          onBuyCurrency={() => setBuyCurrencyConfirmationOpen(true)}
        />

        <BuyCurrencyPopup
          setBuyCurrencyConfirmationOpen={setBuyCurrencyConfirmationOpen}
          isBuyCurrencyConfirmationOpen={isBuyCurrencyConfirmationOpen}
        />
      </>
    )
  }
)

DepositCurrencyComponent.displayName = 'DepositCurrency'

export const DepositCurrency = memo(DepositCurrencyComponent)

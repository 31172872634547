import { get } from '@/services/awsAmplify/api'

export const depositUrlErrors: {
  WALLET_NOT_FOUND: DepositUrlError
  USER_NOT_FOUND: DepositUrlError
  USER_DEACTIVATED: DepositUrlError
  GLOBALLY_DEACTIVATED: DepositUrlError
  UNKNOWN_ERROR: DepositUrlError
} = {
  WALLET_NOT_FOUND: {
    description: 'wallet.buyRpk.error.walletNotFound',
  },
  USER_NOT_FOUND: {
    description: 'wallet.buyRpk.error.userNotFound',
  },
  USER_DEACTIVATED: {
    description: 'wallet.buyRpk.error.userDeactivated',
  },
  GLOBALLY_DEACTIVATED: {
    description: 'wallet.buyRpk.error.globallyDeactivated',
  },
  UNKNOWN_ERROR: {
    description: 'wallet.buyRpk.error.unknownError',
  },
}

export interface DepositUrlError {
  description: string
}

export const useGetDepositUrl = () => {
  const getDepositUrl = () => get({ path: `/banxa/deposit-url` })

  return { getDepositUrl }
}

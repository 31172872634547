import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { getPublicProfile } from '@/services/public'

export const usePublicUserProfile = ({ userId }) => {
  const sanitizedUserId = userId.replace('@', '')

  const { data, isLoading, isError } = useQuery({
    queryKey: ['user', 'public', sanitizedUserId],
    queryFn: () => getPublicProfile({ userId: sanitizedUserId }),
    refetchOnMount: true,
  })

  const userInfo = useMemo(() => {
    if (!data) return null
    return {
      displayName: data.name,
      id: data.id,
      isModerator: data.isModerator,
      isAmbassador: data.isAmbassador,
      followerCount: data.followers,
      followingCount: data.following,
      hasSubscription: data.hasSubscription,
      hasConnectedInstagram: data.hasConnectedInstagram,
      hasChatPaywall: data.hasChatPaywall,
      bio: data.bio,
      posts: data.postImages,
      badge: data?.badge,
      creatorTokenId: data?.creatorTokenId,
    }
  }, [data])

  const wallet = useMemo(() => {
    return {
      totalBalance: data?.rpk,
      xp: data?.xp,
    }
  }, [data])

  return {
    userInfo,
    wallet,
    isLoading,
    isError,
  }
}

import { Routes } from '@/router/routes'

import { useNavigate } from '../routing'

export const useRedirect = () => {
  const navigate = useNavigate()

  const redirectToLogin = () => navigate(Routes.AUTH_SIGN_UP)

  return { redirectToLogin }
}

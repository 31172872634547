import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'

export type ActivityType =
  | 'FOLLOW'
  | 'UNFOLLOW'
  | 'CHAT_CHANNEL'
  | 'LIKE'
  | 'COMMENT'
  | 'POST'
  | 'CONVERSATION'

export type ResponseType = {
  status: number
  data: { message: string; type?: ActivityType; max: number }
  metadata?: Record<string, string | number>
}

export const TOO_MANY_REQUESTS = 429

const translationKeyMapper = {
  FOLLOW: 'follow',
  UNFOLLOW: 'unfollow',
  CHAT_CHANNEL: 'chat',
  LIKE: 'like',
  COMMENT: 'comment',
  POST: 'post',
  CONVERSATION: 'conversation',
  SETUP_2FA: 'setup2FA',
  REMOVE_2FA: 'remove2FA',
  TRANSACTION: 'transaction',
}

export const useDailyLimitToast = () => {
  const [presentToast] = useIonToast()
  const { t } = useTranslation('common')

  const handleDisplayToast = useCallback(
    (response: ResponseType) => {
      if (response?.status === TOO_MANY_REQUESTS && response?.metadata?.max) {
        const activity = response?.metadata?.type

        presentToast({
          position: 'top',
          color: 'primary',
          cssClass: 'daily-limit',
          header: t(`dailyLimits.${translationKeyMapper[activity]}.header`),
          message: t(`dailyLimits.${translationKeyMapper[activity]}.message`, {
            limit: response?.metadata?.max,
          }),
          duration: 3000,
        })
      }
    },
    [presentToast, t]
  )

  return { handleDisplayToast }
}

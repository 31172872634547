import { post } from '@/services/awsAmplify'

export type CreateTransactionVariables = {
  walletId: UUID
  transactionData: {
    destinationWalletId: string
    amount: string
    description?: string
    type: string
  }
}

export type CreateTransactionData = {
  transactionId: string
  walletId: UUID
  destination: {
    id: UUID
    username: string
  }
  destinationWalletId: UUID
  type: string
  amount: string
  description?: string
  createdAt: Date
  total: string
  fee: string
}

export const createTransaction = ({
  walletId,
  transactionData,
}: CreateTransactionVariables): Promise<CreateTransactionData> => {
  if (transactionData.type === 'subscription') {
    return subscribe({
      creatorId: transactionData.destinationWalletId,
      amount: transactionData.amount,
    }) as unknown as Promise<CreateTransactionData>
  } else if (transactionData.type === 'chat') {
    return unlockChat({
      userId: transactionData.destinationWalletId,
      amount: transactionData.amount,
    }) as unknown as Promise<CreateTransactionData>
  } else {
    return post({
      path: `/wallets/${walletId}/transactions`,
      params: {
        ...transactionData,
      },
    }) as unknown as Promise<CreateTransactionData>
  }
}

const subscribe = ({
  creatorId,
  amount,
}: {
  creatorId: string
  amount: string
}): Promise<CreateTransactionData> =>
  post({
    path: `/subscription/${creatorId}`,
    params: {
      amount,
    },
  }) as unknown as Promise<CreateTransactionData>

const unlockChat = ({
  userId,
  amount,
}: {
  userId: string
  amount: string
}): Promise<CreateTransactionData> =>
  post({
    path: `/chat/unlock/${userId}`,
    params: {
      amount,
    },
  }) as unknown as Promise<CreateTransactionData>

import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IonPopover, IonSpinner } from '@ionic/react'

import { InfoIcon } from '@/components/atoms/assets/InfoIcon'
import { TimelineContainer } from '@/components/atoms/TimelineContainer'

import { appConfig } from '@/services/config'
import { IStakeRulesItem } from '@/services/stake/getStakeRules'
import { IStakesEstimate } from '@/services/stake/stakesEstimate'
import { formatDate } from '@/utils/date'

type StakingDetailsType = {
  rule: IStakeRulesItem
  estimate: IStakesEstimate
  isFetchingEstimate: boolean
}

export const StakingDetails: FC<StakingDetailsType> = ({
  rule,
  estimate,
  isFetchingEstimate,
}) => {
  const { t } = useTranslation('common')

  const StakeDate = useCallback(
    (date: string) => (
      <>
        {isFetchingEstimate ? (
          <IonSpinner className="h-4" color="medium" name="dots" />
        ) : date ? (
          formatDate(+new Date(date))
        ) : (
          '-'
        )}
      </>
    ),
    [isFetchingEstimate]
  )

  const timelineContent = useMemo(() => {
    return [
      {
        title: t('wallet.stake.rpkStaked', { currency: appConfig.currency }),
        value: StakeDate(estimate?.stakeDate),
      },
      {
        title: t('wallet.stake.lockInPeriodEnds'),
        value: StakeDate(estimate?.releaseDate),
      },
      {
        title: t('wallet.stake.distribution'),
        value: StakeDate(estimate?.releaseDate),
      },
    ]
  }, [StakeDate, estimate?.releaseDate, estimate?.stakeDate, t])

  return (
    <>
      <div className="relative overflow-hidden rounded-[1.25rem] mb-8">
        <picture>
          <source srcSet="/vault/staking-background.png 332w, /vault/staking-background@2x.png 664w, /vault/staking-background@3x.png 996w" />
          <img src="/vault/staking-background.png" alt="discover" />
        </picture>
        <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col text-white px-6 py-7">
          <div className="h-[62%] flex flex-col justify-between sm:justify-around">
            <span className="text-[4.5vw] sm:text-3xl font-bold">
              {t('wallet.stake.stakingDetails')}
            </span>
            <div className="mb-7">
              <div className="flex justify-between items-center">
                <span className="flex text-[3.75vw] sm:text-xl">
                  {t('wallet.stake.APY')}
                  <span id="apy-tooltip" className="flex cursor-pointer ml-2">
                    <InfoIcon className="!w-[5vw] sm:!w-6" />
                  </span>
                </span>
                <span className="text-primary text-[4.5vw] sm:text-2xl font-bold">
                  {estimate?.apyInPercentage || rule?.apyInPercentage}%
                </span>
              </div>
              <div className="flex justify-between text-[3.75vw] sm:text-xl">
                <span>{t('wallet.stake.lockInPeriod')}</span>
                <span className="font-bold">
                  {t('wallet.stake.lockInPeriodTime', {
                    count: estimate?.durationInDays || rule?.durationInDays,
                  })}
                </span>
              </div>
            </div>
          </div>
          <TimelineContainer content={timelineContent} />
        </div>
      </div>

      <IonPopover
        className="stake-apy-info-popover"
        arrow={false}
        trigger="apy-tooltip"
        triggerAction="click"
        side="top"
        alignment="center"
        size="cover"
        reference="trigger"
      >
        <span className="ion-padding text-sm">
          {t('wallet.stake.apyInfoText')}
        </span>
      </IonPopover>
    </>
  )
}

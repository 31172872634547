import { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonIcon } from '@ionic/react'
import { closeCircleOutline } from 'ionicons/icons'

import { Button } from '@/components/atoms/Button'

import { CreateTransactionData } from '@/services/wallet/createTransaction'

type SummaryProps = {
  unlockChatData: CreateTransactionData
  unlockChatError: unknown
  handleDismissModal: () => void
  handleStartToChat: () => void
}

export const Summary: FC<SummaryProps> = ({
  unlockChatData,
  unlockChatError,
  handleDismissModal,
  handleStartToChat,
}) => {
  const { t } = useTranslation('common')
  const username = unlockChatData?.destination?.username || ''

  const SuccessSummary = useCallback(
    () => (
      <>
        <div className="flex flex-col mt-5 mx-2">
          <span className="text-[1.75rem] font-bold">
            {t('unlockChat.paymentSuccessful')}
          </span>
          <span className="mt-4">
            <Trans>{t('unlockChat.chatUnlockedWith', { username })}</Trans>
          </span>
        </div>
        <div className="mt-9 mb-5">
          <Button
            type="primary"
            className="w-[9.25rem]"
            size="small"
            onClick={handleStartToChat}
          >
            {t('unlockChat.actions.startToChat')}
          </Button>
        </div>
      </>
    ),
    [handleStartToChat, t, username]
  )

  const ErrorSummary = useCallback(
    () => (
      <>
        <IonIcon
          className="h-12 w-12 text-danger my-5"
          icon={closeCircleOutline}
        />
        <div className="flex flex-col font-bold">
          <span className="text-xl">{t('unlockChat.errorSummaryTitle')}</span>
          <span className="text-primary">
            {t('unlockChat.errorSummaryDetails')}
          </span>
        </div>
        <div className="mt-7 mb-7">
          <Button
            type="primary"
            className="w-[9.25rem]"
            size="small"
            onClick={handleDismissModal}
          >
            {t('unlockChat.actions.close')}
          </Button>
        </div>
      </>
    ),
    [handleDismissModal, t]
  )

  return (
    <div className="flex flex-col items-center text-white">
      {unlockChatData && <SuccessSummary />}
      {unlockChatError && <ErrorSummary />}
    </div>
  )
}

import { useCallback, useEffect } from 'react'
import { InfiniteData, useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { errorBoundary } from '@/services/errorBoundary'
import { getPromoPool, getPromoPools } from '@/services/promoPools'
import { useUserInfo } from '@lib/hooks/useUserInfo'
import { queryClient } from '@/lib/queryClient'
import { normalizePages } from '@/utils/utils'

import { IPromoPool } from '@/interfaces'

export const usePromoPools = ({
  enabled = true,
}: {
  enabled?: boolean
} = {}) => {
  const { data: authUser } = useUserInfo()
  const { data, refetch, isLoading, isError, error, isFetching } = useQuery({
    queryKey: ['promo-pools'],
    queryFn: getPromoPools,
    enabled: !!authUser?.id && enabled,
  })

  useEffect(() => {
    if (isError) {
      errorBoundary(error)
    }
  }, [isError, error])

  return {
    isLoading,
    isFetching,
    refetch,
    data: data?.items || [],
  }
}

export const usePromoPool = (pool: IPromoPool) => {
  const { data: authUser } = useUserInfo()
  const { poolName, lastKey } = pool
  const { data, isLoading, fetchNextPage, hasNextPage, isError, error } =
    useInfiniteQuery({
      queryKey: [`promo-pool-${poolName}`],
      queryFn: ({ pageParam }) => getPromoPool(poolName, pageParam as string),
      getNextPageParam: (lastPage: { lastKey: unknown }) => {
        return lastPage?.lastKey ?? undefined
      },
      initialData: {
        pages: [pool],
        pageParams: lastKey ? [lastKey] : [],
      },
      initialPageParam: lastKey ? [lastKey] : [],
      enabled: !!poolName && !!authUser?.id,
    })

  const clearData = useCallback(() => {
    queryClient.setQueryData<InfiniteData<unknown>>(
      [`promo-pool-${poolName}`],
      (data) => ({
        pages: [data?.pages?.[0]].filter(Boolean),
        pageParams: [data?.pageParams?.[0]].filter(Boolean),
      })
    )
  }, [poolName])

  useEffect(() => {
    if (isError) {
      errorBoundary(error)
    }
  }, [isError, error])

  return {
    isLoading,
    data: normalizePages(data as InfiniteData<IPromoPool, unknown>),
    fetchNextPage,
    hasNextPage,
    clearData,
  }
}

import { useTranslation } from 'react-i18next'

import { Tracking, TrackingEvent } from '@/lib/tracking'

interface ButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const Button = ({ onClick }: ButtonProps) => {
  const { t } = useTranslation('common')

  return (
    <button
      className="bg-gradient-to-r from-[#7000F0] to-[#9603F1] text-white text-xs font-semibold px-3 py-1 rounded-full"
      onClick={(e) => {
        onClick?.(e)
        Tracking.triggerEvent(TrackingEvent.EarnMoreClicked)
      }}
    >
      {t('earnMoreModal.buttonText')}
    </button>
  )
}

import { sanitiseMarkdownString } from './sanitiseMarkdownString'

export const validateMarkdownMaxLength =
  (maxLength: number) => (markdown: string) => {
    const plainText = sanitiseMarkdownString(markdown)

    const isValid = plainText.length <= maxLength

    return isValid
  }

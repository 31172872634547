import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router'

import { useUserInfo } from '@/lib/hooks/useUserInfo'

export const RedirectToUserProfile: FC = () => {
  const navigate = useNavigate()
  const { data: authUser } = useUserInfo()

  useEffect(() => {
    navigate(`/@${authUser.username}`, { replace: true })
  })

  return null
}

import { FC } from 'react'
import { IonPopover } from '@ionic/react'

import { InfoIcon } from '@/components/atoms/assets/InfoIcon'

type IconWithTooltipType = {
  tooltipText: string
}

export const IconWithTooltip: FC<IconWithTooltipType> = ({ tooltipText }) => {
  return (
    <>
      <div id="multiplier-tooltip" className="inline cursor-pointer">
        <InfoIcon className="inline w-6 h-6" />
      </div>
      <IonPopover
        className="xp-multiplier-info-popover"
        arrow={false}
        trigger="multiplier-tooltip"
        triggerAction="click"
        side="left"
        alignment="end"
        size="cover"
        reference="trigger"
      >
        <span className="ion-padding text-sm">{tooltipText}</span>
      </IonPopover>
    </>
  )
}

import { del, get, post } from '@/services/awsAmplify'

import { SubscriptionStatus } from '@/enums'

export type SubscriptionData = {
  price?: number
}

export type SubscriptionCancelledData = {
  canceled: boolean
}

export type SubscriptionType = {
  status: SubscriptionStatus
  id: UUID
  startDate: string
  username: string
  nextPaymentDate: string
  price: number
}

export type SubscribersListData = {
  subscribers: SubscriptionType[]
}

export interface SubscriptionFeedError {
  response?: {
    metadata?: {
      price?: number
    }
  }
}

export type SubscriptionsListData = {
  subscriptions: SubscriptionType[]
}

export const getSubscription = async ({
  queryKey,
}): Promise<SubscriptionData> => {
  const userId = queryKey[1]

  return get({
    path: `/subscription/price/${userId}`,
  })
}

export const getSubscribersList = async (): Promise<SubscribersListData> => {
  return get({
    path: '/subscribers',
  })
}

export const getSubscriptionsList =
  async (): Promise<SubscriptionsListData> => {
    return get({
      path: '/subscription',
    })
  }

export const deleteSubscription = async ({
  userId,
}): Promise<SubscriptionCancelledData> => {
  return del({
    path: `/subscription/${userId}`,
  })
}

export const updateSubscription = async (params: SubscriptionData) => {
  return await post({
    path: '/subscription/settings/price',
    params: {
      ...params,
    },
  })
}

export const acceptableUsageAgreement = async () => {
  return await post({
    path: '/aup/agree',
  })
}

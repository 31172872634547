import React from 'react'
import { IonIcon, IonSpinner } from '@ionic/react'
import { checkmarkDoneOutline, checkmarkOutline } from 'ionicons/icons'
import { useMessageContext } from 'stream-chat-react'
import { useChatContext } from 'stream-chat-react'

export type MessageStatusComponentProps = {
  messageType?: string
}

export const getIcon = ({ sending, deliveredAndRead, delivered }) => {
  if (sending)
    return (
      <IonSpinner name="dots" slot="icon-only" className="text-quaternary" />
    )
  if (deliveredAndRead)
    return <IonIcon icon={checkmarkDoneOutline} slot="start" />
  if (delivered) return <IonIcon icon={checkmarkOutline} slot="start" />
  return null
}

const MessageStatusComponent = (props: MessageStatusComponentProps) => {
  const { messageType = 'simple' } = props

  const { client } = useChatContext('MessageStatus')
  const { isMyMessage, lastReceivedId, message, readBy, threadList } =
    useMessageContext('MessageStatus')

  if (!isMyMessage() || message.type === 'error') return null

  const justReadByMe = readBy?.length === 1 && readBy[0].id === client.user?.id
  const rootClassName = `str-chat__message-${messageType}-status str-chat__message-status`

  const sending = message.status === 'sending'
  const delivered =
    message.status === 'received' &&
    message.id === lastReceivedId &&
    !threadList
  const deliveredAndRead = !!(readBy?.length && !threadList && !justReadByMe)

  return (
    <span className={rootClassName}>
      {getIcon({ sending, deliveredAndRead, delivered })}
    </span>
  )
}

export const MessageStatus = React.memo(
  MessageStatusComponent
) as typeof MessageStatusComponent

import { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonIcon, IonModal } from '@ionic/react'
import { closeCircleOutline } from 'ionicons/icons'

import { XPMultiplierList } from '@/components/pages/XPWallet/components/XPMultiplierList'

import { useAccountInfo } from '@/services/account'
import { appConfig } from '@/services/config'

import { Button } from './Button'

export const EarnMoreModal = () => {
  const modalRef = useRef<HTMLIonModalElement>()
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState(false)
  const dismissModal = () => setIsOpen(false)

  const { data: accountInfo } = useAccountInfo(undefined, {
    enabled: false,
  })

  return (
    <>
      <IonModal
        ref={modalRef}
        className="cr-info-modal"
        isOpen={isOpen}
        onDidDismiss={dismissModal}
      >
        <div className="flex flex-col relative pt-6 pb-1 px-4 text-white bg-cool-gray-800">
          <IonIcon
            className="absolute right-3 top-3 text-cool-gray-400 text-3xl xs:text-4xl cursor-pointer"
            icon={closeCircleOutline}
            onClick={dismissModal}
          />
          <div className="flex flex-col px-3 md:pl-6 md:pr-8">
            <span className="text-[6vw] xs:text-2xl font-bold mb-3">
              {t('earnMoreModal.title')}
            </span>
            <span className="mb-3 text-[4vw] xs:text-base">
              <Trans
                i18nKey="wallet.stakeVault.xpMultipliers.description"
                components={{
                  highlight: <span className="font-bold italic" />,
                }}
              >
                {t('earnMoreModal.description', {
                  currency: appConfig.currency,
                })}
              </Trans>
            </span>
          </div>
          <XPMultiplierList
            multipliers={accountInfo?.stakedRpkForXpMultiplier}
            onStakeButtonClicked={dismissModal}
          />
        </div>
      </IonModal>
      <Button onClick={() => setIsOpen(true)} />
    </>
  )
}

import { FC, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonSpinner, IonTitle, useIonToast } from '@ionic/react'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { BackButton } from '@components/atoms/BackButton'
import { Button } from '@components/atoms/Button'
import { Title } from '@components/atoms/Title'
import { AuthenticatorCode } from '@/components/molecules/AuthenticatorCode'
import { Main } from '@/components/templates/main'

import { remove2FA as _remove2FA } from '@/services/user'
import { useErrorBoundary } from '@/lib/hooks/useErrorBoundary'
import { queryClient } from '@/lib/queryClient'
import { useLocation, useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

export const Remove2FA: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const [presentToast] = useIonToast()
  const { handleError } = useErrorBoundary()
  const location = useLocation()
  const { search = '' } = location
  const searchParams = new URLSearchParams(search)
  const invalid = searchParams.get('invalid')

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    setError,
  } = useForm({
    defaultValues: {
      authenticatorCode: '',
    },
    mode: 'all',
  })

  const { mutate: remove2FA, isPending: isPendingVerify } = useMutation({
    mutationFn: _remove2FA,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] })
      presentToast({
        message: t('remove2FA.remove2FASuccess'),
        position: 'top',
        duration: 3000,
      })
      navigate(-1)
    },
    onError: (e: AxiosError) =>
      handleError(e, () => {
        if (e && e.response?.status === 401) {
          setError('authenticatorCode', { message: t('setup2FA.invalidCode') })
        }
      }),
  })

  useEffect(() => {
    if (invalid === 'true') {
      setError('authenticatorCode', { message: t('setup2FA.invalidCode') })
    }
  }, [invalid, setError, t])

  const header = useMemo(
    () => (
      <>
        <IonButtons slot="start">
          <BackButton defaultHref={Routes.SECURITY_SETTINGS} />
        </IonButtons>
        <IonTitle>
          <Title className="text-center my-0" level={3}>
            {t('remove2FA.header')}
          </Title>
        </IonTitle>
      </>
    ),
    [t]
  )

  return (
    <Main
      header={header}
      contentClassName="text-white px-8 pt-7"
      isPaddingEnabled={false}
    >
      <div className="flex justify-center">
        <picture>
          <source srcSet="2fa/remove-2fa.png 115w, 2fa/remove-2fa@2x.png 230w, 2fa/remove-2fa@3x.png 345w" />
          <img
            src="2fa/remove-2fa.png"
            alt="remove-2FA"
            className="w-[40%] md:w-[30%] mb-7 mx-auto"
          />
        </picture>
      </div>
      <span className="font-bold mb-6">{t('remove2FA.description')}</span>
      <Controller
        control={control}
        name="authenticatorCode"
        rules={{
          required: true,
          minLength: 6,
          maxLength: 6,
        }}
        render={({ field }) => (
          <AuthenticatorCode
            codeLength={6}
            code={field.value}
            setCode={field.onChange}
            error={errors.authenticatorCode?.message}
          />
        )}
      />
      <Button
        isDisabled={!isValid || isPendingVerify}
        onClick={handleSubmit(({ authenticatorCode }) =>
          remove2FA(authenticatorCode)
        )}
        className="mt-8 flex mx-auto w-lg"
        size="large"
      >
        {isPendingVerify ? <IonSpinner color="light" /> : t('setup2FA.submit')}
      </Button>
    </Main>
  )
}

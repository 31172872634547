import { useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/Button'

import { appConfig } from '@/services/config'

type BuyMorePRKButtonProps = {
  onClick?: () => void
}

export const BuyMorePRKButton = ({ onClick }: BuyMorePRKButtonProps) => {
  const { t } = useTranslation('common')
  const currency = appConfig.currency

  return (
    <>
      <Button
        type="primary"
        className="w-[9.25rem]"
        size="small"
        onClick={onClick}
      >
        {t('depositBottomSheet.buyMoreCurrency', { currency })}
      </Button>
    </>
  )
}

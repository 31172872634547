import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  ISubscribedCategoriesResult,
  unsubscribeCategory,
} from '@/services/categories'

export const useUnsubscribeCategory = (
  options?: UseMutationOptions<ISubscribedCategoriesResult, unknown, UUID>
) => useMutation({ mutationFn: unsubscribeCategory, ...options })

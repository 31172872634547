import { FC, useState } from 'react'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

interface ISliderProps {
  onCloseToTheEnd?: () => void
  onFirstScroll?: () => void
}

export const Slider: FC<ISliderProps> = ({
  children,
  onCloseToTheEnd,
  onFirstScroll,
}) => {
  const [isCloseToTheEnd, setIsCloseToTheEnd] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const loadMore = debounce(onCloseToTheEnd, 100, {
    leading: true,
    trailing: false,
  })

  const onScrollHandler = throttle((e) => {
    const { scrollLeft, scrollWidth, offsetWidth } = e.target as HTMLDivElement

    if (scrollWidth - (scrollLeft + offsetWidth) < offsetWidth * 2) {
      if (onCloseToTheEnd && !isCloseToTheEnd) {
        loadMore()
      }

      setIsCloseToTheEnd(true)
    } else {
      setIsCloseToTheEnd(false)
    }

    if (!isScrolled) {
      setIsScrolled(true)

      if (onFirstScroll) {
        onFirstScroll()
      }
    }
  }, 50)

  return (
    <div className="relative">
      <div className="relative w-full overflow-x-auto after:clear-both after:block after:content-['']">
        <div
          onScroll={onScrollHandler}
          className="relative overflow-x-auto flex gap-2 scroll-smooth"
          style={{ overflowAnchor: 'none' }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

import { FC, memo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonIcon } from '@ionic/react'
import { chevronForward } from 'ionicons/icons'

import { Button } from '@/components/atoms/Button'
import { Popover } from '@/components/molecules/Popover'

import { deleteAccount } from '@/services/user'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

export const DeleteAccountButtonComponent: FC = () => {
  const { t } = useTranslation('common')
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const navigate = useNavigate()

  const handleDeleteAccount = async () => {
    try {
      await deleteAccount()
      setIsPopoverOpen(false)
      navigate(Routes.LOGOUT, {
        replace: true,
        state: {
          direction: 'none',
        },
      })
    } catch (error) {
      setIsPopoverOpen(false)
      console.log(error)
    }
  }

  return (
    <>
      <Popover
        hasIcon
        isOpen={isPopoverOpen}
        onDismiss={() => setIsPopoverOpen(false)}
        onConfirm={handleDeleteAccount}
        onConfirmClassName="normal-case text-sm text-red-500 order-first"
        onDismissClassName="normal-case text-sm"
        actionText={t('profile.deleteAccount.decline')}
        confirmText={t('profile.deleteAccount.confirm')}
      >
        <strong>
          <Trans>{t('profile.deleteAccount.alert')}</Trans>
        </strong>
      </Popover>
      <Button
        onClick={() => setIsPopoverOpen(true)}
        className="plain !text-sm"
        fill="clear"
        type="danger"
      >
        {t('profile.deleteAccount.button')}
        <IonIcon icon={chevronForward} />
      </Button>
    </>
  )
}

export const DeleteAccountButton = memo(DeleteAccountButtonComponent)

import { StreamClient } from 'getstream'

import { formatGetstreamTime } from '@/utils/date'

import { ActivityFeedVerb } from '@/enums'

interface IForeignIdTimes {
  foreignID: UUID
  time: string
}

export const getActivityByForeignIdTimes = async (
  client: StreamClient,
  batch: IForeignIdTimes[]
) => {
  if (!batch.length) {
    return []
  }

  const foreignIDTimes = batch.map((i) => ({
    foreignID: `${ActivityFeedVerb.StartedConversation}:${i.foreignID}`,
    time: formatGetstreamTime(new Date(i.time)),
  }))

  const response = await client.getActivities({
    foreignIDTimes,
    reactions: { counts: true, own: true },
  })

  return response.results
}

import { localeMapper } from '@/lib/translation/i18n'

import { getLanguage } from '../getLanguage'

export const numberFormat = (value: number) => {
  const language = getLanguage()
  return new Intl.NumberFormat(localeMapper[language], {
    maximumFractionDigits: 2,
  }).format(value)
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from '@/lib/routing'

type FollowersFollowingButtonsProps = {
  userId: UUID
  followingCount: number
  followerCount: number
}

export const FollowersFollowingButtons: FC<FollowersFollowingButtonsProps> = ({
  userId,
  followingCount,
  followerCount,
}) => {
  const { t } = useTranslation('common')

  return (
    <div className="flex flex-row justify-start">
      <Link to={`/relations/${userId}?following=true`}>
        <span className="block text-xs sm:text-sm text-cool-gray-300">
          {t('profile.following')}
        </span>
        <p className="block text-white text-sm sm:text-lg leading-5">
          {followingCount ?? 0}
        </p>
      </Link>
      <Link className="px-4" to={`/relations/${userId}?followers=true`}>
        <span className="block text-xs sm:text-sm text-cool-gray-300">
          {t('profile.followers')}
        </span>
        <p className="block text-white text-sm sm:text-lg leading-5">
          {followerCount ?? 0}
        </p>
      </Link>
    </div>
  )
}

import { useTranslation } from 'react-i18next'
import { useIonModal } from '@ionic/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { MintConfirmationModal } from '@/components/molecules/MintConfirmationModal'

import {
  getCreatorTokenConfig,
  launchCreatorToken,
} from '@/services/wallet/token'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

import { ProfileHashTabs, QueryKeys } from '@/enums'

import { queryClient } from '../queryClient'
import { newrelicNoticeError } from '../tracking'
import { useShowErrorToast } from './useShowErrorToast'
import { useUserInfo } from './useUserInfo'
import { useWallet } from './wallet'

export const useLaunchToken = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { showErrorToast } = useShowErrorToast()

  const { data: userInfo, refetch: refetchUserInfo } = useUserInfo()

  const { refetchWallet } = useWallet({
    userInfo,
  })

  const [openModal, closeModal] = useIonModal(MintConfirmationModal, {
    onDismiss: () => closeModal(),
  })

  const { mutate: launchToken, isPending } = useMutation({
    mutationKey: [QueryKeys.LaunchToken],
    mutationFn: launchCreatorToken,
    onSuccess: () => {
      refetchUserInfo()
      refetchWallet()

      navigate(`${Routes.PROFILE}${ProfileHashTabs.STOX}`)
    },
    onMutate: () => {
      openModal({ cssClass: 'full-screen-modal mint-confirmation-modal' })
      queryClient.setQueryData([QueryKeys.LaunchTokenSuccess], true)
      setTimeout(() => {
        closeModal()
        navigate(`${Routes.PROFILE}${ProfileHashTabs.STOX}`)
      }, 3000)
    },
    onError: (e: AxiosError) => {
      const errorMessage = getErrorMessage(e?.response?.data)

      queryClient.setQueryData([QueryKeys.LaunchTokenSuccess], false)

      showErrorToast({
        header: t('tokenLaunch.errorHeader'),
        message: t(`tokenLaunch.${errorMessage}`),
      })

      newrelicNoticeError({
        error: `Launch Token Error: ${e}`,
        customAttributes: {
          location: window.location.pathname,
          id: userInfo.id,
          username: userInfo.username,
        },
      })
    },
  })

  return { launchToken, isPending }
}

const getErrorMessage = (error: string) => {
  switch (error) {
    case 'NO_CONNECTED_SOCIALS':
      return 'noConnectedSocials'
    case 'INSUFFICIENT_BALANCE':
      return 'insufficientBalance'
    case 'WALLET_NOT_FOUND':
      return 'walletNotFound'
    default:
      return 'somethingWentWrong'
  }
}

interface ICreatorTokenConfig {
  socialsFollowersThresholds: {
    instagram: number
    twitter: number
    tiktok: number
  }
  // eslint-disable-next-line republik/no-rpk-variable
  tokenIssueFeeAmountInRPK: number
}

export const useCreatorTokenConfig = () => {
  const { data: authUser } = useUserInfo()

  const { data, refetch, isLoading, error } = useQuery<
    ICreatorTokenConfig,
    AxiosError
  >({
    queryKey: [QueryKeys.CreatorTokenConfig, authUser?.id],
    queryFn: getCreatorTokenConfig,
    enabled: true,
  })

  return { data, refetch, isLoading, error }
}

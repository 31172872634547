import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { IActivityItem } from '@/components/pages/Activity/interfaces'

import { get } from '@/services/awsAmplify'
import { ApiError } from '@/services/awsAmplify/api'
import { queryClient } from '@/lib/queryClient'

import { QueryKeys } from '@/enums'

import { useConversationId } from './useConversationId'

interface IUseActivityDetails {
  activityId: UUID | 'conversation'
  replyObjectId: UUID
  createdAt: string
  enabled?: boolean
}

export const useActivityDetails = ({
  activityId,
  replyObjectId,
  createdAt,
  enabled = true,
}: IUseActivityDetails) => {
  const { conversationId } = useConversationId({
    activityId,
    replyObjectId,
    createdAt,
    enabled,
  })

  const fetchActivity = async () => {
    const response = await get({
      path: `/posts/activities/${conversationId}`,
      params: {},
    })
    return response?.activity
  }

  const { data, isLoading, error, refetch, isFetching } = useQuery({
    queryKey: [QueryKeys.ActivityDetails, conversationId],
    queryFn: async () => {
      const cachedActivity = queryClient.getQueryData([
        QueryKeys.ActivityData,
        conversationId,
      ])

      const activity = cachedActivity || (await fetchActivity())

      return activity as unknown as IActivityItem
    },
    enabled: !!conversationId && enabled,
  })

  const postFeedActivityErrorData = useMemo(() => {
    const response = (error as unknown as ApiError)?.response

    return {
      status: response?.status,
      postFeedActivity: response?.metadata?.activity,
      price: response?.metadata?.price,
    }
  }, [error])

  return {
    activity: data,
    isLoading,
    hasError: !!error,
    refetch,
    isFetching,
    postFeedActivityErrorData,
  }
}

import React, { FC, useState } from 'react'
import { sanitizeUrl } from '@braintree/sanitize-url'
import cx from 'classnames'
import type { Attachment } from 'stream-chat'
import {
  BaseImage as DefaultBaseImage,
  GalleryProps,
  Modal,
  useComponentContext,
  useTranslationContext,
} from 'stream-chat-react'

import { CustomModalGallery } from './CustomModalGallery'

const CustomGalleryComponent: FC<GalleryProps> = ({ images, innerRefs }) => {
  const [index, setIndex] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)

  const { BaseImage = DefaultBaseImage } = useComponentContext('Gallery')
  const { t } = useTranslationContext('Gallery')

  const imageFallbackTitle = t('User uploaded content')

  const countImagesDisplayedInPreview = 4
  const lastImageIndexInPreview = countImagesDisplayedInPreview - 1

  const toggleModal = (selectedIndex: number) => {
    if (modalOpen) {
      setModalOpen(false)
    } else {
      setIndex(selectedIndex)
      setModalOpen(true)
    }
  }

  const renderImages = images
    .slice(0, countImagesDisplayedInPreview)
    .map((image, i) =>
      i === lastImageIndexInPreview &&
      images.length > countImagesDisplayedInPreview ? (
        <button
          className="str-chat__gallery-placeholder"
          data-testid="gallery-image-last"
          key={`gallery-image-${i}`}
          onClick={() => toggleModal(i)}
          style={{
            backgroundImage: `url(${
              images[lastImageIndexInPreview].previewUrl ||
              images[lastImageIndexInPreview].image_url ||
              images[lastImageIndexInPreview].thumb_url
            })`,
            ...image.style,
          }}
          {...(innerRefs?.current && {
            ref: (r) => (innerRefs.current[i] = r),
          })}
        >
          <p>
            {t<string>('{{ imageCount }} more', {
              imageCount: images.length - countImagesDisplayedInPreview,
            })}
          </p>
        </button>
      ) : (
        <button
          className="str-chat__gallery-image"
          data-testid="gallery-image"
          key={`gallery-image-${i}`}
          onClick={() => toggleModal(i)}
        >
          <BaseImage
            alt={(image as Attachment)?.fallback || imageFallbackTitle}
            src={sanitizeUrl(
              image.previewUrl || image.image_url || image.thumb_url
            )}
            style={image.style}
            title={(image as Attachment)?.fallback || imageFallbackTitle}
            {...(innerRefs?.current && {
              ref: (r) => (innerRefs.current[i] = r),
            })}
          />
        </button>
      )
    )

  const className = cx('str-chat__gallery', {
    'str-chat__gallery--square': images.length > lastImageIndexInPreview,
    'str-chat__gallery-two-rows': images.length > 2,
  })

  return (
    <div className={className}>
      {renderImages}
      <Modal
        onClose={() => setModalOpen((modalOpen) => !modalOpen)}
        open={modalOpen}
      >
        <CustomModalGallery
          images={images}
          index={index}
          toggleModal={toggleModal}
        />
      </Modal>
    </div>
  )
}

/**
 * Displays images in a simple responsive grid with a light box to view the images.
 */
export const CustomGallery = React.memo(
  CustomGalleryComponent
) as typeof CustomGalleryComponent

import { FC } from 'react'

import { InstagramIcon } from '@/components/atoms/assets/InstagramIcon'
import { Avatar } from '@/components/atoms/Avatar'
import { MarkdownUrlHighlighter } from '@/components/molecules/MarkdownUrlHighlighter'

import { useVisualViewport } from '@/lib/hooks/useVisualViewport'

import { Badge } from '@/enums'

import { CurrencyButton } from '../../UserProfile/components/CurrencyButton'
import { FollowersFollowingButtons } from '../../UserProfile/components/FollowersFollowingButtons'
import { XPButton } from '../../UserProfile/components/XPButton'
import { useRedirect } from '../hooks/useRedirect'
import { ProfileButtons } from './ProfileButtons'

type ProfileHeaderType = {
  userInfo: {
    displayName: string
    id: UUID
    isModerator: boolean
    isAmbassador: boolean
    followerCount: number
    followingCount: number
    hasSubscription: boolean
    hasConnectedInstagram: boolean
    hasChatPaywall: boolean
    bio: string
    posts: string[]
    badge?: Badge
  }
  wallet: {
    totalBalance: string
    xp: number
  }
}

export const ProfileHeader: FC<ProfileHeaderType> = ({ userInfo, wallet }) => {
  const {
    visualViewport: { width: viewportWidth },
  } = useVisualViewport()

  const { redirectToLogin } = useRedirect()

  return (
    <div className="w-full max-w-[40rem] mx-auto bg-cool-gray-900">
      <div className="flex flex-col justify-between p-3 px-4">
        <div className="flex items-end pb-3">
          <div onClick={redirectToLogin}>
            <Avatar
              userId={userInfo?.id}
              badge={userInfo?.badge}
              size={viewportWidth < 640 ? 'xl' : 'xxl'}
              isModerator={userInfo?.isModerator}
              isAmbassador={userInfo?.isAmbassador}
            />
          </div>
          <div className="flex flex-col items-start ml-4">
            <div className="ml-4">
              <FollowersFollowingButtons
                userId={userInfo?.id}
                followingCount={userInfo?.followingCount}
                followerCount={userInfo?.followerCount}
              />
            </div>
            <div className="flex justify-center bg-cool-gray-800 rounded-full py-2 pl-3 pr-2 mb-1 mt-3">
              <CurrencyButton
                isViewingOwnProfile // set this to true, to trigger redirection behaviour
                value={wallet?.totalBalance ?? '0'}
              />
              <div className="ml-5 mr-4">
                <XPButton
                  isViewingOwnProfile // set this to true, to trigger redirection behaviour
                  badge={userInfo?.badge}
                  value={Math.floor(wallet?.xp || 0)}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`flex items-center ${
            userInfo?.displayName ? 'place-content-between' : 'justify-end'
          }`}
        >
          {!!userInfo?.displayName && (
            <span className="text-lg sm:text-xl font-bold py-2">
              {userInfo?.displayName}
            </span>
          )}
          {userInfo?.hasConnectedInstagram && (
            <div className="cursor-pointer" onClick={redirectToLogin}>
              <InstagramIcon isActive />
            </div>
          )}
        </div>

        {!!userInfo?.bio && (
          <MarkdownUrlHighlighter
            className="text-sm break-words"
            text={userInfo?.bio}
          />
        )}

        <ProfileButtons
          hasSubscription={userInfo?.hasSubscription}
          hasChatPaywall={userInfo?.hasChatPaywall}
        />
      </div>
    </div>
  )
}

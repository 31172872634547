import { FC, memo } from 'react'
import { IonSpinner } from '@ionic/react'

import { EmptyAvatarBackground } from '../assets/EmptyAvatarBackground'

interface EmptyAvatarProps {
  className?: string
  hasIcon?: boolean
  uploading?: boolean
  isValid?: boolean
  hasBorder?: boolean
}

const EmptyAvatarComponent: FC<EmptyAvatarProps> = ({
  className,
  hasIcon,
  uploading,
  isValid = true,
  hasBorder = false,
}) => {
  return (
    <div className={`${hasBorder ? 'pulsating-circle' : ''}`}>
      <div
        className={`w-full h-full absolute z-10 top-0 left-0 flex justify-center items-center ${className}`}
      >
        <EmptyAvatarBackground isValid={isValid} margin={4} />
        {uploading && <IonSpinner color="main" className="absolute" />}
      </div>
      {!uploading && hasIcon && (
        <svg
          viewBox="100 240 160 90.7832"
          xmlns="http://www.w3.org/2000/svg"
          className="w-14 h-14 z-10 absolute -bottom-3 right-2 click-animation"
        >
          <path
            d="M -105.353 -75.639 C -107.629 -77.65 -108.28 -80.739 -107.322 -83.376 L -107.767 -82.931 C -110.51 -80.188 -115.016 -79.91 -117.84 -82.569 C -120.762 -85.321 -120.814 -89.922 -117.996 -92.74 L -119.217 -91.519 C -121.96 -88.776 -126.466 -88.497 -129.29 -91.157 C -132.212 -93.909 -132.264 -98.51 -129.446 -101.327 L -148.111 -82.663 C -150.854 -79.92 -155.359 -79.642 -158.183 -82.301 C -161.106 -85.053 -161.158 -89.654 -158.34 -92.472 L -130.046 -120.765 C -135.483 -120.216 -141.007 -120.691 -146.265 -122.2 C -147.349 -122.511 -148.341 -123.094 -149.135 -123.888 C -151.252 -126.004 -151.745 -129.335 -150.139 -132.038 C -148.56 -134.696 -145.284 -135.751 -142.304 -134.927 C -130.173 -131.573 -117.428 -137.522 -106.474 -142.222 C -101.207 -144.481 -90.403 -147.058 -80.158 -136.813 L -74.127 -130.783 C -60.42 -117.075 -72.391 -99.217 -75.364 -96.244 L -76.526 -95.082 L -91.185 -80.423 L -95.469 -76.139 C -98.147 -73.461 -102.515 -73.131 -105.353 -75.639"
            style={{
              fill: '#fab005',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: '#e67700',
            }}
            strokeWidth="4"
            id="path-1"
            transform="matrix(1.3333332538604736, 0, 0, -1.3333332538604736, 340.2777303689072, 133.4074007625968)"
          />
        </svg>
      )}
    </div>
  )
}

export const EmptyAvatar = memo(EmptyAvatarComponent)

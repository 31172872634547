import { FC } from 'react'

import { UserName } from '@components/atoms/UserName'
import { FollowButton } from '@components/molecules/FollowButton'
import { ListItem } from '@components/molecules/ListItem'

import { IUser } from '@services/findUsers'
import { Link } from '@/lib/routing'
import { Routes } from '@/router/routes'

export interface IListProps {
  user: IUser
  currentUserId: string | undefined
}

export const UserRelationsListItem: FC<IListProps> = ({
  user,
  currentUserId,
}) => {
  return (
    <ListItem item={user}>
      <div className="w-full flex flex-wrap items-center justify-between pr-2">
        <Link
          className="max-w-[60%]"
          to={user.id !== currentUserId ? `/@${user.username}` : Routes.PROFILE}
          replace
        >
          <UserName
            className="block font-medium text-sm"
            name={user.username}
          />
          <span className="text-xs block text-ellipsis overflow-hidden">
            {user.displayName}
          </span>
        </Link>
        {user.id !== currentUserId && (
          <FollowButton
            userId={user.id}
            followStatus={user.followStatus}
            size="small"
            username={user.username}
          />
        )}
      </div>
    </ListItem>
  )
}

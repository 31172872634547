import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IonIcon, IonLabel } from '@ionic/react'
import { chevronForward } from 'ionicons/icons'

import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'

const ExistingPhoneLogin: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')

  return (
    <IonLabel
      className="mb-[44px] z-10 text-center cursor-pointer text-sm min-[400px]:text-base"
      onClick={() => navigate(Routes.AUTH_PHONE)}
    >
      <Trans
        t={t}
        i18nKey={'registration.signUpOptions.existingAccountLogIn'}
        components={{ underline: <span className="border-b-[0.0625rem]" /> }}
      />
      <IonIcon
        className="text-primary ml-1 align-middle text-sm"
        icon={chevronForward}
      />
    </IonLabel>
  )
}

export default ExistingPhoneLogin

import { useEffect, useState } from 'react'

export const usePlaybackState = () => {
  const [isPlaybackEnabled, setIsPlaybackEnabled] = useState(true)

  useEffect(() => {
    setIsPlaybackEnabled(true)

    return () => setIsPlaybackEnabled(false)
  }, [])

  return { isPlaybackEnabled }
}

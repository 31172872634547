import { FC, memo } from 'react'

import { IActivityItem } from '@/components/pages/Activity/interfaces'

import { PostFeedDetail } from './PostFeedDetail'
import { PostFeedHeader } from './PostFeedHeader'
import { PostFeedSubscriptionContent } from './PostFeedSubscriptionContent'

export type IPostFeedActivityWithSubscriptionProps = IActivityItem & {
  amount?: number
  text?: string
  activityId?: string
}

const PostFeedActivityWithSubscriptionComponent: FC<
  IPostFeedActivityWithSubscriptionProps
> = ({ actor, object, id, origin, time, amount, text, activityId }) => {
  return (
    <div>
      <PostFeedHeader actor={actor} origin={origin} object={object} id={id} />
      <PostFeedSubscriptionContent
        actor={actor}
        activityId={activityId}
        amount={amount}
      />
      <PostFeedDetail
        actor={actor}
        text={text}
        time={time}
        object={object}
        id={id}
      />
    </div>
  )
}
export const PostFeedActivityWithSubscription = memo(
  PostFeedActivityWithSubscriptionComponent
)

import { FC, memo, useMemo } from 'react'

type StakeComponentProps = {
  className?: string
  type?: 'primary' | 'secondary'
}

export const StakeComponent: FC<StakeComponentProps> = ({
  className = '',
  type = 'primary',
}) => {
  const strokeColor = useMemo(
    () => (type === 'primary' ? '#0E1117' : '#FFF'),
    [type]
  )

  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="14"
        cy="14"
        rx="14"
        ry="14"
        fill={type === 'primary' ? '#CCFF00' : '#8162D0'}
      />
      <path
        d="M14.0247 12.5328C17.877 12.5328 21 11.244 21 9.6541C21 8.06423 17.877 6.77539 14.0247 6.77539C10.1723 6.77539 7.04932 8.06423 7.04932 9.6541C7.04932 11.244 10.1723 12.5328 14.0247 12.5328Z"
        stroke={strokeColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 9.65366V12.9436C21 14.3063 17.8769 15.4111 14.0247 15.4111C10.1724 15.4111 7.04932 14.3063 7.04932 12.9436V9.65366"
        stroke={strokeColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12.533V15.823C21 17.1856 17.8769 18.2904 14.0247 18.2904C10.1724 18.2904 7.04932 17.1858 7.04932 15.823V12.533"
        stroke={strokeColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 15.4113V18.7013C21 20.0639 17.8769 21.1687 14.0247 21.1687C10.1724 21.1687 7.04932 20.0641 7.04932 18.7013V15.4113"
        stroke={strokeColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Stake = memo(StakeComponent)

import { FC } from 'react'
import { useQuery } from '@tanstack/react-query'

import { appConfig } from '@/services/config'

export const RoutingInterceptor: FC = ({ children }) => {
  const { pathname, search } = window.location
  const params = new URLSearchParams(search)
  const refUsername = params.get('ref_username')
  const redirectUrl = appConfig.origin + pathname + search

  useQuery({
    queryKey: ['referral'],
    queryFn: () => ({ refUsername, redirectUrl }),
    enabled: !!refUsername,
  })

  return <>{children}</>
}
